import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { useLoadYesterdayLinks } from "../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { updateNameValues } from "../../../../../Utils/StayUpToDate/RenderComponent";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
import Title from "antd/es/typography/Title";
import CarouselControlWrapper from "../../../../../components/CarouselControls/CarouselControlWrapper";
import CarouselFilterControl from "../../../../../components/CarouselControls/CarouselFilterControl";
import EditableText from "../../../../../components/EditModalComponents.tsx/EditableText";
import PostCardComponent from "../../../../../components/EditModalComponents.tsx/PostCardComponent";

const EditYesterdayLinks = (props: any) => {
  const {
    username,
    user_id,
    isYesterdayLinksEditModalOpen,
    setIsYesterdayLinksEditModalOpen,
    type,
    component,
    from,
    componentType,
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  const [isChange, setIsChange] = useState(false);
  const [activeFilter, setActiveFilter] = useState("both");
  const [isSoundOn, setIsSoundOn] = useState(component.content_sound || false);
  const [shape, setShape] = useState(component.content_shape || "rect");
  const [order, setOrder] = useState(component?.content_order || "DESC");

  const showSocials =
    activeFilter === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : activeFilter === "products"
      ? StaticVariables().PRODUCT_PLATFORMS
      : null;

  // const isShopLinks = (type === "query-content" )? false : true;

  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
    setOrder(component?.content_order || "DESC");
    setActiveFilter(component?.shoppable_content_filter || "both");
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      removePostIds,
      order,
      shape,
      isSoundOn,
      activeFilter,
      from
    );
    if (res === "true") {
      setIsYesterdayLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setIsChange(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      // setActiveFilter("both");
      setEdit(false);
      setEditIndex("");
      props.refetch();
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setEdit(false);
      setEditIndex("");
    }

    // props.setIsCustomLinksComponentOpen(false);
  };

  const handleCancel = () => {
    setIsYesterdayLinksEditModalOpen(false);
    props.setSelectedComponent("");

    // props.setIsCustomLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEdit(false);
    setEditIndex("");
  };

  // function handleRemovedPostIds(post_ids: any) {
  //   setRemovePostIds((prev: any) => {
  //     const idsArray = prev.split(",").filter(Boolean);
  //     idsArray.push(post_ids);
  //     return idsArray.join(","); // Convert array back to string
  //   });
  //   // setRemovePostIds((prev: any) => (prev ? [...prev, post_ids] : [post_ids]));
  //   // setPostIds(post_ids);
  // }
  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);
        return idsArray.join(","); // Convert array back to string
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(","); // Convert array back to string
      });
    }
  }

  return (
    <>
      <Modal
        open={isYesterdayLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>

          {errorMsg && <p className="errorMsg text-center">{errorMsg}</p>}
          {component?.display_name && component?.display_name !== "" && (
            <p className="text-center">
              Based on{" "}
              {component?.display_name &&
                component.display_name.split(",").join(" & ")}
              's Shoppable Content
            </p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            setIsChange={setIsChange}
            isChange={isChange}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            shape={shape}
            isSoundOn={isSoundOn}
            order={order}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            activeFilter={activeFilter}
            showSocials={showSocials}

            isShopLinks={true}
            name={name}
            setName={setName}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            componentType={componentType}
            selectedDays={component?.display_name?.split(",")}
          />
          <div className="carousel-controls-wrapper">
            <div className="addedContent">
              <Title level={3}>Carosuel Control</Title>
              <CarouselFilterControl
                type={type}
                setActiveFilter={setActiveFilter}
                activeFilter={activeFilter.toLowerCase()}
                setCurrentPage={() => {}}
                username={username}
                user_id={user_id}
                query={defaultQuery}
                POSTS_PER_PAGE={10}
                showSocials={showSocials}
                isShopLinks={true}
                post_ids={component?.post_ids?.split(",")}
                removePostIds={removePostIds}
                displayName={component?.display_name}
                title={component?.name}
                order={order}
              />
              <CarouselControlWrapper
                showShape={true}
                showSound={true}
                showFilter={false}
                showOrder={true}
                shape={shape}
                setShape={setShape}
                isSoundOn={isSoundOn}
                setIsSoundOn={setIsSoundOn}
                type={type}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                order={order}
                setOrder={setOrder}
                isActionLoading={isActionLoading}
                setIsActionLoading={setIsActionLoading}
                showSocials={showSocials}
              />
            </div>
          </div>
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            // yesterdayLinksData={yesterdayLinksData}
            // refetchLinks={refetchLinks}
            // yesterdayLinksLoading={yesterdayLinksLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    title,
    setIsChange,
    isChange,
    setEdit,
    edit,
    editIndex,
    setEditIndex,
    shape,
    isSoundOn,
    order,
    // yesterdayLinksData,
    // refetchLinks,
    // yesterdayLinksLoading
    isActionLoading,
    setIsActionLoading,
    activeFilter,
    showSocials,
    isShopLinks,
    name,
    setName,
    errorMsg,
    setErrorMsg,
    componentType,
    selectedDays
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  let numberOfDays: any;
  if (displayName === "This Week") {
    numberOfDays = [7];
  } else if (displayName === "Yesterday") {
    numberOfDays = [1];
  } else if (displayName === "This Month") {
    numberOfDays = [30];
  } else {
    numberOfDays = [];
  }
  let {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch,
  }: any = useLoadYesterdayLinks(
    username,
    user_id,
    numberOfDays,
    postIds,
    deletePostIds,
    title,
    isChange,
    order,
    
    selectedDays,
    componentType
  );

  // useEffect(()=>{
  //   if (showSocials) {
  //     data = yesterdayLinksData.filter((item: any) => showSocials.includes(item.platform))
  //   }  },[activeFilter])
  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      setDeletePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        idsArray.push(post_id);
        return idsArray.join(","); // Convert array back to string
      });
      await Promise.all([
        props.handleRemovedPostIds(post_id, "minus"),
        refetch(),
      ]);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      setIsActionLoading(false);
    }
  };
  const [value, setValue] = useState<string>("");

  const handleDone = async (post_id: string, newValue: string) => {
    await updateNameValues(username, type, post_id, user_id, newValue);
    setEdit(false);
    refetchDashboard();
    setEditIndex("");
    setIsChange(true);
    refetch();
    // PostIdDataRefetch();
  };

  // Separate the filtering logic in useMemo
  const filteredData = useMemo(() => {
    if (showSocials) {
      return (yesterdayLinksData || [])
        .filter((element: any) => element !== undefined)
        .filter((item: any) => showSocials.includes(item.platform));
    } else {
      return (yesterdayLinksData || [])
        .filter((element: any) => element !== undefined)
        .filter((obj: any) => {
          const deleteIdsArray = deletePostIds?.split(",") || [];
          return !deleteIdsArray.includes(obj.post_id);
        });
    }
  }, [yesterdayLinksData, deletePostIds, showSocials]);

  const hasFilteredData = filteredData.length > 0;
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  // useEffect(()=>{
  //   refetchLinks();
  // },[deletePostIds])
  return (
    <>
      <div className="addedContent">
        <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            if (!newValue.trim()) {
              setErrorMsg("Title cannot be empty");
              return;
            }
            setErrorMsg("");
            setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={isActionLoading && editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />

        {!yesterdayLinksLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              filteredData.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div
                    key={post.post_id}
                    className="feed-tab-post-container added-post"
                  >
                    <PostCardComponent
                      post={post}
                      shape={shape}
                      isSoundOn={isSoundOn}
                      isShopLinks={isShopLinks}
                      from="added"
                      onMinus={onMinus}
                    />
                    <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          setIsActionLoading(false);
                        }
                      }}
                      isLoading={isActionLoading && editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                {/* <p>No Added Data</p> */}
                <>
                  <div
                    // key={index}
                    // className="feed-tab-post-container"
                    className="feed-tab-post"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="feed-tab-post"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};
export default EditYesterdayLinks;

// {edit === true && editIndex === index ? (
//   <>
//     <Input
//       value={value || post.shop_title || post.product_name}
//       onChange={(e: any) => setValue(e.target.value)}
//     />{" "}
//     <HiCheck
//       onClick={() => {
//         handleDone(post.post_id);
//       }}
//     />
//   </>
// ) : (
//   <p
//     style={{
//       textAlign:
//         post?.content_title !== "" ||
//         post?.shop_title !== "" ||
//         post?.product_name !== ""
//           ? "left"
//           : "center",
//     }}
//   >
//     <ProductName type={type} post={post} />

//     <HiPencil
//       onClick={() => {
//         const name = getPostTitle(post, type);
//         setValue(name);
//         setEdit(true);
//         setEditIndex(index);
//       }}
//     />
//   </p>
// )}
