import { useEffect, useMemo, useState } from "react";
import { supabase } from "../../../../../../config/supabaseClient";
import { UpdateCombinedText } from "../../../../../../Utils/SupabaseServices/ContentDatabase";
import Skeleton from "react-loading-skeleton";
import React from "react";
import { Button, Carousel, Input } from "antd";
import VideoComponent from "../../../../../../components/VideoComponent/VideoComponent";
import { HiCheck, HiPencil } from "react-icons/hi";
import { getPostTitle } from "../../../../../../Utils/HelperFunction/HelperFunction";
import { updateNameValues } from "../../../../../../Utils/StayUpToDate/RenderComponent";
import EditableText from "../../../../../../components/EditModalComponents.tsx/EditableText";
import PostCardComponent from "../../../../../../components/EditModalComponents.tsx/PostCardComponent";

export const AddedTrendingProducts = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    title,
    numberOfClicks,
    query,
    customStartDate,
    customEndDate,
    days,
    setIsChanged,
    isChanged,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    isActionLoading,
    setIsActionLoading,
    data,
    isLoading,
    refetch,
    setErrorMsg,
    setName,
    errorMsg,
    name,
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  // useEffect(() => {
  //   refetch();
  // }, [customStartDate, customEndDate, refetch]);
  const onMinus = async (post_id: string) => {
    // setIsActionLoading(true);
    try {
      setDeletePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        idsArray.push(post_id);
        return idsArray.join(",");
      });
      // await Promise.all([
      props.handleRemovedPostIds(post_id, "minus");
      // refetch()
      // ]);
      // await new Promise(resolve => setTimeout(resolve, 1000));
    } finally {
      // setIsActionLoading(false);
    }
  };
  const [value, setValue] = useState<string>();

  const handleDone = async (post_id: string, newValue: string) => {
    await updateNameValues(username, type, post_id, user_id, newValue);
    setIsChanged(true);
    setEdit(false);
    setEditIndex("");
    refetch();
    refetchDashboard();
  };
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  const filteredData = useMemo(() => {
    return (data || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [data, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  return (
    <>
      <div className="addedContent">
        <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            // if (!newValue.trim()) {
            //   setErrorMsg("Title cannot be empty");
            //   return;
            // }
            setErrorMsg("");
            setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={isActionLoading && editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />
        {!isLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              filteredData.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div
                    key={index}
                    className="feed-tab-post-container added-post"
                  >
                    {/* <div className="feed-tab-media-wrapper">
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>

                      {post?.platform === "newsletter" ? (
                        <div
                          className="newsletter-media-carousel h-250"
                          style={
                            post?.platform === "newsletter"
                              ? {
                                textAlign: "center",
                              }
                              : {}
                          }
                        >
                          <h4 className="newsletter-label"> Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post?.post_date))}
                          </h4>
                        </div>
                      ) : post?.platform === "linkedin" && !post.media_url ? (
                        <div className="newsletter-media-carousel h-250 no-media-url">
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post?.post_date))}
                          </h4>
                        </div>
                      ) : post?.platform === "twitter" && !post.media_url ? (
                        <div className="newsletter-media-carousel h-250 no-media-url">
                          <h4 className="newsletter-label">X Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post?.post_date))}
                          </h4>
                        </div>
                      ) : (
                        <>
                          {post?.product_image ? (
                            <img
                              className="feed-tab-media-check mh-300"
                              src={post?.product_image}
                              alt="post"
                            />
                          ) : post?.media_url ? (
                            <>
                              {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <div className="h-255">
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[
                                                  index
                                                  ]
                                                  : "/assets/dummy_video.png"
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            className="feed-tab-media-check mt-300 mb-2"
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className="h-255">
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="feed-tab-media-check"
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <img
                              className="feed-tab-media-check"
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          )}
                        </>
                      )}
                    </div> */}
                    <PostCardComponent
                      post={post}
                      // shape={shape}
                      // isSoundOn={isSoundOn}
                      // isShopLinks={isShopLinks}
                      from="added"
                      onMinus={onMinus}
                    />{" "}
                    {/* {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value || post?.shop_title || post?.content_title || post?.product_name}
                          onChange={(e: any) => setValue(e.target.value)}
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: post?.shop_title !== "" ||
                            post?.product_name !== "" ||
                            post?.content_title !== "" ? "left" : "center",
                        }}
                      >
                        {getPostTitle(post, type)}
                        <HiPencil
                          onClick={() => {
                            const name = getPostTitle(post, type);
                            setValue(name);
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )} */}
                    <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          setIsActionLoading(false);
                        }
                      }}
                      isLoading={isActionLoading && editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <>
                  <div className="feed-tab-post">
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              <>
                <div className="feed-tab-post">
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};
