import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { updateComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import RemovedContentTable from "../../../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { getMaxTrendingCount } from "../../../../../../Utils/SupabaseServices/AnalyticsDatabse";
import CustomDateRange from "../../../../../../components/CustomDateRange/CustomDateRange";
import dayjs from "dayjs";
import { AddedContentTypePosts } from "./AddedContentTypePosts";
import { useLoadTypeContentData } from "../../../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import CarouselControlWrapper from "../../../../../../components/CarouselControls/CarouselControlWrapper";
import { Typography } from "antd";
const { Title } = Typography;
const EditContentTypePosts = (props: any) => {
  const {
    username,
    user_id,
    isContentTypePostsEditModalOpen,
    setIsContentTypePostsEditModalOpen,
    type,
    component,
    refetchDashboard,
    setSelectedComponent,
    platform,
    from
  } = props;
  const [name, setName] = useState(component.name || "");
  const [clickNumber, setClickNumber] = useState(""); // const [details, setDetails] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");

  const [activeTab, setActiveTab] = useState(2);
  const [localActiveTab, setLocalActiveTab] = useState(activeTab);
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false); // State for the custom date modal
  const [customStartDate, setCustomStartDate] = useState<any>("");
  const [customEndDate, setCustomEndDate] = useState<any>("");
  const [days, setDays] = useState<any>("");
  const [isChanged, setIsChanged] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(null);
  const [editPostIds, setEditPostIds] = useState<any>("");
  const [isEditDate, setIsEditDate] = useState(false);
  const [shape, setShape] = useState("");
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("");

  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setShape(component?.content_shape);
    setIsSoundOn(component?.content_sound);
    // setActiveFilter(component?.active_filter);
    // setCurrentPage(component?.current_page);
    setOrder(component?.content_order);
    // setRemovePostIds(component?.removed_postids);
    setEditPostIds(component?.removed_postids);
    const [numberOfClicks, startDate, endDate, activeKey] = (
      component?.details ?? ""
    ).split(",");
    setDays(activeKey);
    setClickNumber(numberOfClicks);
    if (activeKey === "7" || activeKey === "30" || activeKey === "90") {
      const startDate = dayjs().subtract(activeKey, "day").format("YYYY-MM-DD");
      const endDate = dayjs().format("YYYY-MM-DD");
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    } else {
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    }

    setActiveTab(+activeKey); // The unary + operator converts the string to a number
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    const numericValue = parseInt(value, 10); // or parseFloat(value) if dealing with decimals

    if (numericValue > maxCount) {
      setErrorMsg(`Number should not exceed ${maxCount}`);
    } else {
      setErrorMsg("");
      setIsButtonDisabled(!value);
    }
    setter(value); // Set the input value
    // setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const [maxCount, setMaxCount] = useState<any>();
  useEffect(() => {
    fetchMaxCount();
  }, [clickNumber]);
  async function fetchMaxCount() {
    const res = await getMaxTrendingCount(username, user_id);
    setMaxCount(res);
  }
  const handleOk = async () => {
    setRemovePostIds(editPostIds);
    setConfirmLoading(true);

    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      editPostIds,
      // removePostIds
      order,
      shape,
      isSoundOn,
      undefined,
      from
    );
    if (res === "true") {
      // refetch();
      refetchDashboard();
      setIsContentTypePostsEditModalOpen(false);
      setSelectedComponent("");
      setConfirmLoading(false);
      setIsChanged(true);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      setEditPostIds("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsContentTypePostsEditModalOpen(false);
    setSelectedComponent("");
    refetchDashboard();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEditPostIds("");
  };

  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      // setRemovePostIds((prev: any) => {
      //   const idsArray = prev?.split(",").filter(Boolean);
      //   idsArray.push(post_ids);

      //   return idsArray.join(",");
      // });
      setEditPostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);

        return idsArray.join(",");
      });
    } else if (type === "add") {
      // setRemovePostIds((prev: any) => {
      //   const idsArray = prev.split(",").filter(Boolean);
      //   const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
      //   return filteredArray.join(",");
      // });
      setEditPostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(",");
      });
    }
  }
  function handleEdit() {
    setIsEditDate(true);
    setLocalActiveTab(2);
    setSelectedDates(null);
    setCustomStartDate("");
    setCustomEndDate("");
  }
  function handleUpdateCancel() {
    setIsEditDate(false);
  }
  function handleUpdateOk() {
    let startDate: any;
    let endDate: any;

    if (localActiveTab !== 1 && localActiveTab !== 2) {
      endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
      startDate = dayjs().subtract(localActiveTab, "day").format("YYYY-MM-DD");
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    } else {
      if (localActiveTab === 2) {
        endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
        startDate = dayjs().subtract(100000, "day").format("YYYY-MM-DD");
        setCustomStartDate(startDate);
        setCustomEndDate(endDate);
      } else {
        startDate = dayjs(customStartDate)?.format("YYYY-MM-DD");
        endDate = dayjs(customEndDate)?.add(1, "day").format("YYYY-MM-DD");
        setCustomStartDate(startDate);
        setCustomEndDate(endDate);
      }
    }
    setActiveTab(localActiveTab);
    setIsEditDate(false);
  }

  const renderDate = useCallback(
    (startDate: string, endDate: string) => {
      if (activeTab != 2) {
        return (
          <p className="m-0">
            {dayjs(startDate).format("MMMM D, YYYY")} -{" "}
            {dayjs(endDate).subtract(1, "day").format("MMMM D, YYYY")}
          </p>
        );
      } else {
        return <p className="active-tab">Showing All data</p>;
      }
    },
    [activeTab] // This ensures the function updates only when activeTab changes
  );
  const { isLoading, data, refetch }: any = useLoadTypeContentData(
    username,
    user_id,
    platform,
    type,
    removePostIds,
    order
  );
  return (
    <>
      <Modal
        open={isContentTypePostsEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
      // okButtonProps={{
      //   disabled: !name,
      // }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
         

          {errorMsg && <p className="errorMsg">{errorMsg}</p>}

          {component?.query && component?.query !== "" && (
            <p className="text-center">Latest Instagram Reels</p>
          )}
          <AddedContentTypePosts
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={editPostIds}
            setRemovePostIds={setEditPostIds}
            type={"content"}
            displayName={component?.display_name}
            query={component?.query}
            title={component?.name}
            numberOfClicks={clickNumber}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            days={days}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            data={data}
            isLoading={isLoading}
            refetch={refetch}
            order={order}
            shape={shape}
            isSoundOn={isSoundOn}
            name={name}
            setName={setName}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
          />
          <div className="carousel-controls-wrapper">
          <div className="addedContent">
          <Title level={3}>Carosuel Control</Title>
          <CarouselControlWrapper
            showShape={true}
            showSound={true}
            showFilter={true}
            showOrder={platform === "featured" ? false : true}
            shape={shape}
            setShape={setShape}
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
            type={type}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            setCurrentPage={setCurrentPage}
            order={order}
            setOrder={setOrder}
          />
          </div>
          </div>
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={editPostIds}
            setRemovePostIds={setEditPostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            title={component?.name}
            numberOfClicks={clickNumber}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            days={days}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          />
        </div>
      </Modal>
      <Modal
        open={isEditDate}
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
        okText="Update"
      // confirmLoading={confirmLoading}
      // okButtonProps={{
      //   disabled: !name || !clickNumber || clickNumber > maxCount,
      // }}
      >
        <CustomDateRange
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setIsCustomDateModalOpen={setIsCustomDateModalOpen}
          isCustomDateModalOpen={isCustomDateModalOpen}
          setSelectedDates={setSelectedDates}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          selectedDates={selectedDates}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setLocalActiveTab={setLocalActiveTab}
          localActiveTab={localActiveTab}
          setDays={setDays}
        />
      </Modal>
    </>
  );
};

export default EditContentTypePosts;
