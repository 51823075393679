import React, { useEffect, useState } from "react";
import RouteList from "./RouteList";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { HiOutlineCog, HiOutlineLogout, HiOutlineMenu } from "react-icons/hi";
import { IoEyeOutline, IoClose } from "react-icons/io5";
import "./Sidebar.css";
import "./Menu.css";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "../../../Utils/HelperFunction/HelperFunction";
import { useAuth } from "../../../hooks/useAuth";
import { BsList } from "react-icons/bs";
import { useSidebarState } from '../../../context/SidebarStateContext';
import { useSidebar } from '../../../context/SidebarContext';
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { MdDashboardCustomize } from "react-icons/md";

interface SidebarProps {
  username?: string;
  pictureUrl?: string;
  email_verify?: string;
  userData?: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  username = "user",
  pictureUrl,
  email_verify,
  userData
}) => {
  const { closeSidebar, toggleSidebar } = useSidebar();
  const {
    handleClick,
    isEmail,
    isName,
    isPhone,
    isPhoneEmpty,
    tab,
    isEmailVerified,
    option
  } = useSidebarState();

  const location = useLocation();
  const isFollowerView = location.pathname.includes('follower');

  // State for user menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { signOut: signOutSupabase } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // State for mobile menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isOpen } = useSidebar();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to get user initials for avatar
  const getUserInitials = () => {
    if (!username) return "U";
    return username.charAt(0).toUpperCase();
  };

  // Handle menu open
  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle settings click
  const handleSettingsClick = () => {
    handleClick("Account");
    handleMenuClose();
  };


  const handleLogoutClick = async () => {
    handleMenuClose();
    await signOut();
    signOutSupabase();

    navigate("/");
  };
  const navigate = useNavigate();

  const goToFollower = () => {
    handleClick("Live Page Preview");
    closeSidebar()
    setTimeout(() => {
      navigate("/follower/" + username, { state: { from: "dashboard" } });
    }, 100);
  };

  const goToDashboard = () => {
    if (option === "Live Page Preview" || option === "") {
      handleClick("Landing Page");
    }
    navigate("/dashboard", { state: { from: "follower" } });
  };
  // Toggle mobile menu
  const toggleMobileMenu = (e: any) => {
    e.preventDefault()
    toggleSidebar()
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      closeSidebar()
    }
    setMobileMenuOpen(false);
  };

  const handleClose = () => {
    if (isFollowerView) {
      closeSidebar();
    }
  };
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <div className="logo-icon">
          <img
            className="homepage-icon"
            src="/assets/icon-black.png"
            alt="thesearchbubble logo"
          />
        </div>
        {isFollowerView && (
          <div className="header-nav-image header-nav-image-close" onClick={handleClose}>
            <IoClose size={24} />
          </div>
        )}
      </div>

      <div
        className={`sidebar-nav ${mobileMenuOpen || isOpen ? "mobile-menu-open" : ""}`}
      >
        {(!isEmail || !isName || !isPhone) ? null : (<RouteList
          handleClick={handleClick}
          isEmail={isEmail}
          isName={isName}
          isPhone={isPhone}
          isPhoneEmpty={isPhoneEmpty}
          tab={tab}
          isEmailVerified={isEmailVerified}
          option={option}
          email_verify={email_verify}
          pictureUrl={pictureUrl}
          username={username}
          handleDrawerClose={handleDrawerClose}
          goToFollower={goToFollower}
          goToDashboard={goToDashboard}
          userData={userData}
        />)}

      </div>

      {
        isMobile && !isFollowerView && (
          <div className="mobile-menu-toggle" onClick={(e) => toggleMobileMenu(e)}>
            <BsList className="hamburger-icon" />
            {pictureUrl ? (
              <Avatar src={pictureUrl} className="header-dashboard-img" />
            ) : (
              <Avatar className="user-avatar">{getUserInitials()}</Avatar>
            )}
          </div>
        ) 
      }

      <div className="sidebar-footer">
        <div className="sidebar-user" onClick={handleMenuOpen}>
          {pictureUrl ? (
            <Avatar src={pictureUrl} className="user-avatar" />
          ) : (
            <Avatar className="user-avatar">{getUserInitials()}</Avatar>
          )}
          <span className="username">{username}</span>
        </div>

        <Menu
          className="modern-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {isFollowerView ? (
            <MenuItem onClick={goToDashboard}>
              <MdDashboardCustomize className="menu-icon" />
              <span>Dashboard</span>
            </MenuItem>
          ) : (
            <MenuItem onClick={goToFollower}>
              <IoEyeOutline className="menu-icon" />
              <span>Live Page Preview</span>
            </MenuItem>
          )}
          <MenuItem onClick={handleLogoutClick}>
            <HiOutlineLogout className="menu-icon" />
            <span>Logout</span>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
