import { Button, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
// import '../../../../'
import { addComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import FeedCheckboxComponent from "./FeedCheckboxComponent";
import { ModalBackButton } from "../../../../components/EditModalComponents.tsx/ModalBackButton";

const AddCustomLinksComponent = (props: any) => {
  const {
    username,
    user_id,
    isCustomLinksComponentOpen,
    isLinksEditModalOpen,
    type,
    from
  } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [activeFilter, setActiveFilter] = useState("both");
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [shape, setShape] = useState("rect");
  const [order, setOrder] = useState("DESC");

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    if (!name.trim()) {
      setErrorMsg("Title cannot be empty");
      return;
    }
    setConfirmLoading(true);
    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      name,
      "links",
      postIds.toString(),
      order,
      shape,
      isSoundOn,
      activeFilter,
      from
    );
    if (res === "true") {
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      // setActiveFilter("both");
      props.refetch();
      setShape("rect");
      setOrder("DESC");
      props.setIsShoplinksModalOpen(false);
      props.setIsCustomLinksComponentOpen(false);
      setConfirmLoading(false);
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setActiveFilter("both");
    setShape("rect");
    setIsSoundOn(false);
    props.setIsShoplinksModalOpen(false);
    props.setIsCustomLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setActiveFilter("both");
    setShape("rect");
    setIsSoundOn(false);
    props.setIsCustomLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button onClick={handlePrevious} className="newbutton">
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isCustomLinksComponentOpen || isLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="custom-shop-content"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton
          handlePrevious={handlePrevious}
          title="Create a Custom Shoppable Carousel"
        />
       
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <FeedCheckboxComponent
          isShopLinks={true}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          componentName={name}
          post_ids={postIds}
          defaultQuery={defaultQuery}
          setDefaultQuery={setDefaultQuery}
          type="links"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          shape={shape}
          setShape={setShape}
          order={order}
          setOrder={setOrder}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          name={name}
          setName={setName}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      </Modal>
    </>
  );
};

export default AddCustomLinksComponent;
