import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { storeTwitterUser } from "../../../Utils/SupabaseServices/ContentDatabase";

const TwitterIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showTwitterModal, user_id, data, refetchData } = props;
  const [twitterUser, setTwitterUser] = useState(data?.twitter_user);
  const [errorTwitter, setErrorTwitter] = useState("");
  const [loadingTwitterConnect, setLoadingTwitterConnect] = useState(false);
  const [integrateTwitterMsg, setIntegrateTwitterMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setTwitterUser(data?.twitter_user);
    setShowIntegrationModal(false);
  };
  return (
    <>
      <Modal
        title="Connect to X"
        open={showTwitterModal}
        onOk={async () => {
          if (twitterUser && twitterUser?.length > 0) {
            setLoadingTwitterConnect(true);

            const res = await storeTwitterUser(user?.id as string, twitterUser, username);
            if (res.error) {
              setErrorTwitter(res.message);
            } else {
              setIntegrateTwitterMsg(
                "Your X data will be integrated in your feed within 24 hours!"
              );
              // props.setShowTwitterModal(false);
              props.onClose();
              refetchData()
              setShowIntegrationModal(true)
            }

            setLoadingTwitterConnect(false);
          } else {
            setErrorTwitter("Enter valid name")
          }
        }}
        onCancel={() => {
          setErrorTwitter("");
          setTwitterUser(data?.twitter_user);
          // props.setShowTwitterModal(false);
          props.onClose();

        }}
        okText="Connect"
        okButtonProps={{ disabled: !twitterUser, loading: loadingTwitterConnect }}

        cancelButtonProps={{ disabled: loadingTwitterConnect }}
      >
        <p>
          By connecting your X username, all your X data will be
          integrated and shown as posts in the feed of your main page. In the
          content tab you will be able to hide or delete the tweets you don't
          want to show.{" "}
        </p>
        {loadingTwitterConnect ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorTwitter && (
              <Alert
                type="error"
                message={`Error: ${errorTwitter}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.twitter_user ? (
              <>
                <p>
                  <b>Already connected X username: {data?.twitter_user}</b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your X username :</p>

            <Input
              placeholder="username"
              value={twitterUser || ""}
              onChange={(e) => {
                const value = e.target.value;
                setTwitterUser(value);
                if (!value) {
                  setErrorTwitter("Enter valid name");
                } else {
                  setErrorTwitter("");
                }
              }}
            />
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      <Modal
        title={twitterUser ? `${twitterUser}'s Tweets` : ""}
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integrateTwitterMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default TwitterIntegrationModal;
