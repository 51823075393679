import { Button, Carousel } from 'antd';
import React from 'react'
import VideoComponent from '../VideoComponent/VideoComponent';

const PostCardComponent = (props: any) => {
  const { post, shape, isSoundOn, isShopLinks, onMinus,from } = props;
  return (
    <div className="feed-tab-media-wrapper">
        {from === "added"?
    <Button
      onClick={() => onMinus(post.post_id)}
      className="carousel-content-remove-btn"
    >
      Remove
    </Button>
    : <Button
    onClick={() => onMinus(post.post_id)}
    className="carousel-content-add-btn"
  >
      Add
    </Button>}
    {post?.platform === "newsletter" ? (
      <div className={`${shape === "sq" ? 'square-wrapper' : ''}`}>
        <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-255'}`}>
        <h4> Newsletter</h4>
          <h4 className="mt-7 date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(post.post_date))}
          </h4>
        </div>
      </div>
    ) : post?.platform === "linkedin" && !post?.media_url ? (
      <div className={`${shape === "sq" ? 'square-wrapper' : ''}`}>
        <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-255'}`}>
        <h4>LinkedIn Post</h4>
          <h4 className="mt-7 date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(post.post_date))}
          </h4>
        </div>
      </div>
    ) : (
      <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-grid' : ''}`}>
        {(post?.product_image && isShopLinks) ? (
          <div
            className={`${post?.type === "FEED" || shape === "sq"
              ? "square-wrapper"
              : ""
              }`}
          >
            <img

              className={post?.type === "FEED" || shape === "sq"
                ? "feed-tab-media-check square-image"
                : "feed-tab-media-check"
              }
              src={post?.product_image}
              alt="post"
            />
          </div>
        ) :
          post?.media_url ? (
            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
              {post?.media_url.split(",").length > 1 &&
                post?.media_url
                  .split(",")
                  .every((url: any) => url.trim() !== "") ? (
                <Carousel
                  dotPosition="bottom"
                  className="feed-tab-media-carousel"
                >
                  {post?.media_url
                    .split(",")
                    .map((media_url: any, index: any) => (
                      <React.Fragment key={index}>
                        {media_url.includes("video") ? (
                          <VideoComponent
                            media_url={media_url}
                            thumbnail={
                              post.thumbnail &&
                                post.thumbnail.split(",")
                                  .length &&
                                post.thumbnail.split(",")[
                                index
                                ]
                                ? post.thumbnail.split(
                                  ","
                                )[index]
                                : "/assets/dummy_video.png"
                            }
                            content_shape={shape}
                            isSoundOn={isSoundOn}
                          />
                        ) : (
                          <img
                            className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                            src={media_url}
                            alt="post"
                          />
                        )}
                      </React.Fragment>
                    ))}
                </Carousel>
              ) : (
                <>
                  {post.media_url.includes("video") ? (
                    <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : 'h-255'}`}>
                      <VideoComponent
                        media_url={post.media_url}
                        thumbnail={post.thumbnail}
                        content_shape={shape}
                        isSoundOn={isSoundOn}
                      />
                    </div>
                  ) : (

                    <img
                      className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                      src={
                        post.media_url
                          ? post.media_url
                          : "/assets/dummy_video.png"
                      }
                      alt="post"
                    />

                  )}
                </>
              )}
            </div>
          ) : (
            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
              <img
                className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                src={
                  post?.thumbnail
                    ? post?.thumbnail
                    : "/assets/dummy_video.png"
                }
                alt="post"
              />
            </div>
          )}
      </div>
    )}
  </div>
  )
}

export default PostCardComponent