import axios from "axios";
import { supabase } from "../../config/supabaseClient";
import { UpdateCombinedText } from "./ContentDatabase";

export async function addToProductBank(
  name: any,
  url: any,
  image: any,
  user_id: any,
  query: any,
  username:any
) {
  function generatePostId() {
    // Generate a random number between 100000000000 and 999999999999
    // return (
    //   Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
    //   1000000000000000
    // );
    const sanitizedFileName = name
      .replace(/['",;:/?|\_=\-\\]/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9\-]/g, "");
    // const timestamp = Date.now();

    const timestamp = Date.now(); // Unique timestamp for every upload

    const post_id = `${sanitizedFileName}-${timestamp}`;
    return post_id;
  }

  // Example usage:
  let randomNumber = generatePostId();

  const { data, error } = await supabase.from("product_bank").insert([
    {
      name,
      url,
      image,
      query: `|${query}|`,
      user_id,
      product_id: randomNumber,
    },
  ]);

  if (error !== null) {
    let randomNumber = generatePostId();

    const { data, error } = await supabase.from("product_bank").insert([
      {
        name,
        url,
        image,
        query: `|${query}|`,
        user_id,
        product_id: randomNumber,
      },
    ]);
  }
  // return randomNumber;
  try {
    const embedUrl = `https://dev.thesearchbubble.com/embedProductSingle`;

   axios.post(embedUrl, {
    user: username,
    id:randomNumber
  });
    // return res.data.embedding;
  } catch (error) { }
  return randomNumber;
}

export async function updateProductBankQuery(
  ids: any,
  user_id: any,
  query: any,
  username:any
) {
  for (let i of ids) {
    if (!i.table_query?.replace(/\|/g, "")?.split(",").includes(query)) {
      i.table_query =
        i.table_query !== ""
          ? i.table_query + "," + "|" + query + "|"
          : "|" + query + "|";
    }
    const { data, error } = await supabase
      .from("product_bank")
      .update({ query: i.table_query })
      .eq("user_id", user_id)
      .eq("product_id", i.table_id)
      .eq("name", i.table_name);
     await supabase
      .from(username)
      .update({ metadata: i.table_query })
      .eq("user_id", user_id)
      .eq("post_id", i.table_id)
      // .eq("caption", i.table_name);
      UpdateCombinedText(
        username,
        user_id,
        i.table_id
      )
  }
}

export async function removeProductBankQuery(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  let updatedData = postData.table_query
    ?.replace(/\|/g, "") // Remove all pipes
    .split(",") // Split by comma
    .filter((item: any) => {
      return item !== query;
    })
    .map((item: any) => `|${item}|`)
    .join(",");
  const { data, error } = await supabase
    .from("product_bank")
    .update({ query: updatedData })
    .eq("user_id", user_id)
    .eq("product_id", id);
}

export async function removeProductBank(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  const { data, error } = await supabase
    .from("product_bank")
    .delete()
    .eq("user_id", user_id)
    // .eq("id", id)
    .eq("product_id", postData.table_id);
}

export async function removeProductBankName(
  id: any,
  user_id: any,
  postData: any,
  query: any
) {
  const { data, error } = await supabase
    .from("product_bank")
    .delete()
    .eq("user_id", user_id)
    // .eq("id", id)
    .eq("product_id", postData.table_product_id);
}
