import { Carousel } from "antd";
import React, { useState, useMemo, memo } from "react";
import "../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { useLoadYesterdayLinks } from "../../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import VideoComponent from "../../../../../../components/VideoComponent/VideoComponent";
import Skeleton from "react-loading-skeleton";
import { useLoadYesterdayContent } from "../../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayContent";



// Memoized skeleton component
const SkeletonStatBox = memo(() => (
  <div className="skeletonDiv">
    <Skeleton className="skeletonStyle" />
  </div>
));

// Format date once
const formatDate = (dateString: string) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    day: "numeric",
    month: "long",
  }).format(new Date(dateString));
};

// Render media content
const MediaContent = memo(({ post }: any ) => {
  // Check if we have multiple media URLs
  const hasMultipleMedia = post?.media_url && 
    post.media_url.split(",").length > 1 && 
    post.media_url.split(",").every((url: any) => url.trim() !== "");

  if (!post?.media_url) {
    return (
      <div>
        <img
          className={post?.type === "FEED" ? "feed-tab-media-check" : ""}
          src={post?.thumbnail || "/assets/dummy_video.png"}
          alt="post"
        />
      </div>
    );
  }

  if (hasMultipleMedia) {
    return (
      <Carousel dotPosition="bottom" className="feed-tab-media-carousel">
        {post.media_url.split(",").map((media_url: any, index: any) => (
          <React.Fragment key={index}>
            {media_url.includes("video") ? (
              <VideoComponent
                media_url={media_url}
                thumbnail={
                  post.thumbnail && 
                  post.thumbnail.split(",").length && 
                  post.thumbnail.split(",")[index]
                    ? post.thumbnail.split(",")[index]
                    : "/assets/dummy_video.png"
                }
              />
            ) : (
              <img
                className={post?.type === "FEED" ? "feed-tab-media-check" : ""}
                src={media_url}
                alt="post"
              />
            )}
          </React.Fragment>
        ))}
      </Carousel>
    );
  }
  
  return (
    <>
      {post.media_url.includes("video") ? (
        <div className={post?.type === "FEED" ? "h-255" : ""}>
          <VideoComponent
            media_url={post.media_url}
            thumbnail={post.thumbnail}
          />
        </div>
      ) : (
        <img
          className={post?.type === "FEED" ? "feed-tab-media-check" : ""}
          src={post.media_url || "/assets/dummy_video.png"}
          alt="post"
        />
      )}
    </>
  );
});

// Main component
const PreviewComponent = (props: any) => {
  const { username, user_id, displayName, type, title,selectedDays ,componentType} = props;
  const [postIds] = useState("");
  // Determine days number based on display name
  const numberOfDays = useMemo(() => {
    if (displayName.includes("This Week")) {
      return [7];
    } else if (displayName === "Yesterday") {
      return [1];
    } else if (displayName.includes("This Month")) {
      return [30];
    } else {
      
      return [];
    }
  }, [displayName]);

  // Data fetching for different content types
  const {
    isLoading: loading,
    data: previewData,
  } = useLoadYesterdayLinks(
    username,
    user_id,
    numberOfDays,
    postIds,
    "",
    displayName,
    false,
    type?.includes("This Week") ? "ASC" : "DESC",
    selectedDays,
    componentType
  );

  const {
    isLoading: yesterdayContentLoading,
    data: yesterdayContentData,
  } = useLoadYesterdayContent(
    username,
    user_id,
    numberOfDays,
    postIds,
    "",
    displayName,
    false,
    "ASC"
  );

  // Render loading skeleton
  const renderSkeleton = useMemo(() => (
    <div className="feed-tab-post">
      {[...Array(4)].map((_, index) => (
        <SkeletonStatBox key={index} />
      ))}
    </div>
  ), []);

  // Render post item
  const renderPostItem = (post: any) => (
    <React.Fragment key={post.post_id}>
      <div className="feed-tab-post-container added-post">
        <div className="feed-tab-media-wrapper">
          {post?.platform === "newsletter" || (post?.platform === "linkedin" && !post?.media_url) ? (
            <>
              <h4 className="mt-7">{formatDate(post.post_date)}</h4>
              <h4>{post.platform === "newsletter" ? "Newsletter" : "LinkedIn Post"}</h4>
            </>
          ) : (
            <MediaContent post={post} />
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <>
      {type && type.includes("Content") ? (
        <>
          {yesterdayContentData?.length > 0 ? (
            <>
              <div className="addedContent h-300px">
                <h5 className="addedContentTitle">{title}</h5>
                {!yesterdayContentLoading ? (
                  <div className="addedSvg m-7 h-255">
                    {yesterdayContentData?.map(renderPostItem)}
                  </div>
                ) : (
                  renderSkeleton
                )}
              </div>
            </>
          ) : (
            <p>No Added Content</p>
          )}
        </>
      ) : (
        <>
          {previewData?.length > 0 ? (
            <>
              <div className="addedContent h-300px">
                <h5 className="addedContentTitle">{title}</h5>
                {!loading ? (
                  <div className="addedSvg m-7 h-255">
                    {previewData?.map(renderPostItem)}
                  </div>
                ) : (
                  renderSkeleton
                )}
              </div>
            </>
          ) : (
            <p>No Added Content</p>
          )}
        </>
      )}
    </>
  );
};

export default memo(PreviewComponent);
