import { StaticVariables } from "../../Utils/StaticVariables";

const AuthTitle = (props: any) => {
	return (
		<div className='auth-title-div'>
			{props.isLogin ? (
				<>
					<p className="auth-title-div-p">{StaticVariables().AUTH_LOGIN_HEADING}</p>
					<p>{StaticVariables().AUTH_LOGIN_MSG}</p>
				</>
			) : (
				<>
					<h2>{StaticVariables().AUTH_SIGNUP_HEADING}</h2>
					<p>{StaticVariables().AUTH_SIGNUP_MSG}</p>
				</>
			)}
		</div>
	);
};

export default AuthTitle;
