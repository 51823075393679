import React, { useEffect } from "react";
import { StaticVariables } from "../../../Utils/StaticVariables";
import YesterdayLinksLoader from "../UpToDateComponents/YesterdayLinks/YesterdayLinksLoader";
import CustomContentDataLoader from "../UpToDateComponents/CustomContentData/CustomContentDataLoader";
import CustomLinkDataLoader from "../UpToDateComponents/CustomLinkData/CustomLinkDataLoader";
import QueryContentDataLoader from "../UpToDateComponents/QueryContentData/QueryContentDataLoader";
import QueryLinkDataLoader from "../UpToDateComponents/QueryLinkData/QueryLinkDataLoader";
import TrendingProductsDataLoader from "../UpToDateComponents/TrendingProductsData/TrendingProductsDataLoader";
import RenderIndividualUI from "./RenderIndividualUI";
import YesterdayContentLoader from "../UpToDateComponents/YesterdayContent/YesterdayContentLoader";
import FeaturedData from "../UpToDateComponents/FeaturedData/FeaturedData";

const dayMappings: { [key: string]: number[] } = StaticVariables().dayMappings;

export const IndividualComponentLoader = (props: any) => {
    const {
        componentId,
        user_id,
        username,
        brand_color,
        handleRedirect,
        isHandleNavigate,
        data
    } = props;


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [componentId]);

    const {
        display_name: displayName = "",
        name = "",
        type = "",
        post_ids: postIds = "",
    } = data || {};

    const numberOfDays =
        displayName === "Yesterday"
            ? [1]
            : displayName === "This Week" || displayName === "This Week Content"
                ? [7]
                : displayName === "This Month"
                    ? [30]
                    : dayMappings[displayName]
                        ? []
                        : null;

    const renderContent = () => {
        // Common props for all loaders
        const commonProps = {
            key: data?.id,
            user_id,
            username,
            title: name,
            postIds,
            handleRedirect,
            brand_color,
            isHandleNavigate,
            component: data,
            type: data?.type,
            RenderUI: RenderIndividualUI // Pass the RenderIndividualUI component
        };

        if (displayName === "This Week Content") {
            return (
                <YesterdayContentLoader
                    {...commonProps}
                    removedPostIds={data?.removed_postids}
                    isLinks={true}
                    numberOfDays={numberOfDays}
                />
            );
        } else if (dayMappings[displayName] || ["Yesterday", "This Week", "This Month"].includes(displayName)) {
            return (
                <YesterdayLinksLoader
                    {...commonProps}
                    numberOfDays={numberOfDays}
                    removedPostIds={data?.removed_postids}
                    isLinks={true}
                    componentType={dayMappings[displayName]?"daywise":""}
                />
            );
        }

        switch (type) {
            case "featured":
                return (
                    <FeaturedData
                        {...commonProps}
                        removedPostIds={data?.removed_postids}

                        // handleRedirect={handleRedirect}
                        isLinks={true}
                    />
                );
            case "content":
                return (
                    <CustomContentDataLoader
                        {...commonProps}
                        isLinks={false}
                    />
                );
            case "links":
                return (
                    <CustomLinkDataLoader
                        {...commonProps}
                        isLinks={true}
                    />
                );
            case "query-content":
                return (
                    <QueryContentDataLoader
                        {...commonProps}
                        query={data?.query}
                        removedPostIds={data?.removed_postids}
                        isLinks={false}
                    />
                );
            case "query-links":
                return (
                    <QueryLinkDataLoader
                        {...commonProps}
                        query={data?.query}
                        removedPostIds={data?.removed_postids}
                        isLinks={true}
                    />
                );
            case "trending products":
                return (
                    <TrendingProductsDataLoader
                        {...commonProps}
                        details={data?.details}
                        removedPostIds={data?.removed_postids}
                        isLinks={true}
                    />
                );
            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
}; 