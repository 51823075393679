import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useSidebarState } from './SidebarStateContext';
import { useLocation } from 'react-router-dom';
interface SidebarContextType {
    isOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const toggleSidebar = useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, []);

    const closeSidebar = useCallback(() => {
        setIsOpen(false);
    }, []);

    const { handleClick } = useSidebarState();

    useEffect(() => {
        const handleStorageChange = () => {
            const emailData = localStorage.getItem("email_verify");
            const phoneData = localStorage.getItem("phone_verify");
            if (location?.pathname?.includes("productbank")) {
                handleClick("Product Bank");
            } else {
                // Check if either email_verify or phone_verify is "false"
                if (emailData === "false" || phoneData === "false") {
                    // setOption("Account");
                } else if (!emailData && !phoneData) {
                    // Check if both email_verify and phone_verify are not present or have truthy values
                    // setOption("Analytics");
                }
            }
        };

        // Add event listener for storage changes
        window.addEventListener("storage", handleStorageChange);

        // Initial setup (run once)
        handleStorageChange();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [window]);

    return (
        <SidebarContext.Provider value={{ isOpen, toggleSidebar, closeSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
}; 