import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLoadQueryFeed } from "../../../../Screens/DashboardView/LandingPageCarousels/QueryBasedCarousel/useLoadQueryFeed";
import { updateCardImage, updateIsEmptyStatus } from "../../../../Utils/StayUpToDate/RenderComponent";
import DataSkeleton from "../DataSkeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import { isMobile } from "react-device-detect";
import { debounce } from "lodash";
import CustomPagination from "../../CustomPagination";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import { StaticVariables } from "../../../../Utils/StaticVariables";

const QueryLinkDataLoader = (props: any) => {
    const itemsPerPage = 3;
    const {
        user_id,
        username,
        title,
        postIds,
        handleRedirect,
        brand_color,
        isLinks,
        component,
        type,
        query,
        removedPostIds,
        RenderUI
    } = props;

    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<any>(null);
    const isButtonClicked = useRef(false);
    const [isLoading, setIsLoading] = useState(true);

    // const showSocials = StaticVariables().PLATFORMS;
    const showSocials = component.shoppable_content_filter === "both"
        ? StaticVariables().PLATFORMS
        : component.shoppable_content_filter === "content"
            ? StaticVariables().CONTENT_PLATFORMS
            : component.shoppable_content_filter === "products"
                ? StaticVariables().PRODUCT_PLATFORMS
                : StaticVariables().PLATFORMS;
    const contentOrder = "Default";

    const {
        isLoading: queryFeedLoading,
        data: queryFeed,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
    }: any = useLoadQueryFeed(
        username,
        user_id,
        query,
        showSocials,
        contentOrder,
        true,
        removedPostIds,
        title,
        component?.content_order
    );

    useEffect(() => {
        const checkEmptyStatus = async () => {
            if (!queryFeedLoading) {
                if (!queryFeed || queryFeed.length === 0) {
                    await updateIsEmptyStatus(title, user_id, component.id);
                    setIsLoading(false);
                } else {
                    await handleFirstImage(title, component?.id, queryFeed[0]);
                    setIsLoading(false);
                }
            }
        };
        checkEmptyStatus();
    }, [queryFeedLoading, queryFeed]);

    const handleNavigate = (component: any) => {
        if (isNavigable(component)) {
            navigate(`/${username}/${component.unique_id}`, {
                state: { data: component },
            });

            track("Carousel Click", TRACKING_TYPES.EVENT, {
                pathname: location.pathname,
                details: {
                    unique_id: component?.unique_id,
                    type: component?.type,
                    title: component.name,
                },
                user_id: user_id,
                title: "Stay Up To Date",
            });
        }
    };

    function handleFirstImage(name: any, id: any, firstElement: any) {
        const imageToSend =
            firstElement?.table_image ||
            (firstElement?.media_url?.includes("video")
                ? firstElement?.thumbnail
                : firstElement?.media_url);

        if (imageToSend) {
            updateCardImage(name, user_id, id, imageToSend);
        }
    }

    const handlePrevPage = () => {
        isButtonClicked.current = true;
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setTimeout(() => {
            isButtonClicked.current = false;
        }, 500);
    };

    const handleNextPage = () => {
        isButtonClicked.current = true;
        setCurrentIndex((prevIndex) =>
            Math.min(prevIndex + 1, queryFeed.length - itemsPerPage)
        );
        setTimeout(() => {
            isButtonClicked.current = false;
        }, 500);
    };

    const handleScroll = useCallback(
        debounce(() => {
            if (!isButtonClicked.current) {
                if (containerRef.current) {
                    const scrollLeft = containerRef.current.scrollLeft;
                    const itemWidth = containerRef.current.scrollWidth / queryFeed.length;
                    const newCurrentIndex = Math.round(scrollLeft / itemWidth);
                    setCurrentIndex(newCurrentIndex);
                }
            }
        }, 100),
        [queryFeed]
    );

    useEffect(() => {
        if (containerRef.current) {
            const itemWidth = containerRef.current.scrollWidth / queryFeed.length;
            const scrollPosition = itemWidth * currentIndex;
            containerRef.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    }, [currentIndex, queryFeed]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
            return () => {
                if (containerRef.current) {
                    containerRef.current.removeEventListener("scroll", handleScroll);
                }
            };
        }
    }, [queryFeed, handleScroll]);

    return (
        <>
            {RenderUI ? <RenderUI
                data={queryFeed}
                currentIndex={currentIndex}
                handleNavigate={handleNavigate}
                component={component}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                containerRef={containerRef}
                handleRedirect={handleRedirect}
                brand_color={brand_color}
                title={title}
                type={type}
                isLinks={isLinks}
            /> :
                (queryFeedLoading || isLoading) ? (
                    <DataSkeleton />
                ) :
                    (queryFeed && queryFeed.length === 0) ? null :
                        (
                            <>
                                {queryFeed && queryFeed.length > 0 ? (
                                    <div>
                                        <div className="mainDiv">
                                            <p
                                                className="component-name"
                                                style={param?.id ? { cursor: "auto" } : {}}
                                                onClick={(e) => handleNavigate(component)}
                                            >
                                                {title}
                                            </p>
                                            {!param?.id && (
                                                <CustomPagination
                                                    totalItems={queryFeed?.length}
                                                    currentIndex={currentIndex}
                                                    handlePrevPage={handlePrevPage}
                                                    handleNextPage={handleNextPage}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className={!param?.id ? "outer-container-reels" : ""}
                                            ref={containerRef}
                                        >
                                            <div
                                                className={
                                                    !param?.id
                                                        ? "links-grid-container-reels"
                                                        : isMobile
                                                            ? "item-grid creator-card-grid-wrp"
                                                            : "item-grid"
                                                }
                                            >
                                                {queryFeed &&
                                                    queryFeed.map((link: any, index: number) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    link.media_url
                                                                        ? "link-url"
                                                                        : !link.product_image ? "link-url youtube-link-position" : "link-url"

                                                                }
                                                            >
                                                                <>
                                                                    {
                                                                        link?.product_image ? (
                                                                            <>
                                                                                <ImagePostTag
                                                                                    data={link}
                                                                                    handleRedirect={handleRedirect}
                                                                                    title={title}
                                                                                    brand_color={brand_color}
                                                                                    isLinks={true}
                                                                                    isDashboard={true}
                                                                                    isHasMediaUrl={
                                                                                        link.type === "FEED" ? true : false
                                                                                    }
                                                                                    contentShape={component?.content_shape == "sq" ? true : false}
                                                                                    isSoundOn={component?.content_sound}
                                                                                />
                                                                            </>
                                                                        ) :
                                                                            link.media_url ? (
                                                                                link.media_url.split(",").length > 1 &&
                                                                                    link.media_url
                                                                                        .split(",")
                                                                                        .every((url: any) => url.trim() !== "") ? (
                                                                                    <>
                                                                                        <ImagePostCarouselTag
                                                                                            post={link}
                                                                                            handleRedirect={handleRedirect}
                                                                                            title={title}
                                                                                            brand_color={brand_color}
                                                                                            isLinks={isLinks}
                                                                                            isDashboard={false}
                                                                                            isHasMediaUrl={
                                                                                                link.type === "FEED" ? true : false
                                                                                            }
                                                                                            isYoutube={
                                                                                                link.platform == "youtube" && !link.product_image ? true : false
                                                                                            }
                                                                                            contentShape={component?.content_shape == "sq" ? true : false}
                                                                                            isSoundOn={component?.content_sound}
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {link.product_image &&
                                                                                            link.product_image !== "" ? (
                                                                                            <>
                                                                                                <ImagePostTag
                                                                                                    data={link}
                                                                                                    handleRedirect={handleRedirect}
                                                                                                    title={title}
                                                                                                    brand_color={brand_color}
                                                                                                    isLinks={isLinks}
                                                                                                    isDashboard={false}
                                                                                                    isHasMediaUrl={false}
                                                                                                    isYoutube={
                                                                                                        link.platform == "youtube" && !link.product_image
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                    contentShape={component?.content_shape == "sq" ? true : false}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {link.media_url.includes("video") ? (
                                                                                                    <>
                                                                                                        <VideoPostTag
                                                                                                            post={link}
                                                                                                            handleRedirect={handleRedirect}
                                                                                                            title={title}
                                                                                                            brand_color={brand_color}
                                                                                                            isLinks={isLinks}
                                                                                                            isDashboard={false}
                                                                                                            thumbnail={link.thumbnail}
                                                                                                            isHasMediaUrl={
                                                                                                                link.type === "FEED" ? true : false
                                                                                                            }
                                                                                                            isYoutube={
                                                                                                                link.platform == "youtube" && !link.product_image
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                            contentShape={component?.content_shape == "sq" ? true : false}
                                                                                                            isSoundOn={component?.content_sound}
                                                                                                            type={type}

                                                                                                        />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <ImagePostTag
                                                                                                            data={link}
                                                                                                            handleRedirect={handleRedirect}
                                                                                                            title={title}
                                                                                                            brand_color={brand_color}
                                                                                                            isLinks={isLinks}
                                                                                                            isDashboard={false}
                                                                                                            isHasMediaUrl={
                                                                                                                link.type === "FEED" ? true : false
                                                                                                            }
                                                                                                            isYoutube={
                                                                                                                link.platform == "youtube" && !link.product_image
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                            contentShape={component?.content_shape == "sq" ? true : false}
                                                                                                        />
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}

                                                                                    </>
                                                                                )
                                                                            ) : (
                                                                                <>
                                                                                    <ImagePostTag
                                                                                        data={link}
                                                                                        handleRedirect={handleRedirect}
                                                                                        title={title}
                                                                                        brand_color={brand_color}
                                                                                        isLinks={isLinks}
                                                                                        isDashboard={false}
                                                                                        isHasMediaUrl={
                                                                                            link.type === "FEED" ? true : false
                                                                                        }
                                                                                        isYoutube={
                                                                                            link.platform == "youtube" && !link.product_image ? true : false
                                                                                        }
                                                                                        contentShape={component?.content_shape == "sq" ? true : false}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                </>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
        </>
    );
};

export default QueryLinkDataLoader; 