import React, { useState } from "react";
import '../../../../../styles/StayUptoDateCSS/SubComponents.css'
import { useLocation, useParams } from "react-router-dom";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { useLoadTrendingProducts } from "../../../../../Utils/customHooks/UpToDateContent/useLoadTrendingProducts";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";

const TrendingProductsWrapper = (props: any) => {
  const { user_id, username, numberOfDays, isLinks, brand_color, component } =
    props;

  const {
    key,
    name: title,
    postIds,
    details,
    cta,
    query,
    removed_postids: removedPostIds,
    content_order,
    content_shape,
    content_sound,
    type
  } = component;

  const [numberOfClicks, startDate, endDate, days] = details.split(",");
  const param = useParams();
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const { isLoading: TrendingProductsLoading, data: TrendingProducts }: any =
    useLoadTrendingProducts(
      user_id,
      username,
      title,
      numberOfClicks,
      removedPostIds,
      startDate,
      endDate,
      days,
      false
    );

  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any,
    platform:string,
    // type:any,
    isLinks?: boolean
  ) {

    
   
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        platform:platform,
        // type:type,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    if(isLinks===true){
      track("Redirect to shop link", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url,platform:platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    } else if(isLinks===false){
      track("Redirect to original post", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url,platform:platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }

    updateRefClick(postId, user_id);
  }
  return (
    <>
      <div>
        {TrendingProductsLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {TrendingProducts && TrendingProducts.length ? (
              <div className="links-grid-container-subcomponents">
                {TrendingProducts &&
                  TrendingProducts.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                          <>
                            <ImagePostCarouselTag
                              post={link}
                              handleRedirect={handleRedirect}
                              title={title}
                              brand_color={brand_color}
                              isLinks={true}
                              isDashboard={true}
                              isHasMediaUrl={
                                link.type === "FEED" ? true : false
                              }
                              contentShape={content_shape == "sq" ? true : false}
                              contentSound={content_sound}
                            />
                          </>
                        ) : (
                          <>
                            {link.product_image && link.product_image !== "" ? (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleRedirect}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                  isHasMediaUrl={
                                    link.type === "FEED" ? true : false
                                  }
                                  contentShape={content_shape == "sq" ? true : false}
                                  contentSound={content_sound}
                                />
                              </>
                            ) : (
                              <>
                                {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      thumbnail={link.thumbnail}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      type={type}
                                      contentShape={content_shape == "sq" ? true : false}
                                      contentSound={content_sound}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      contentShape={content_shape == "sq" ? true : false}
                              contentSound={content_sound}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <ImagePostTag
                            data={link}
                            handleRedirect={handleRedirect}
                            title={title}
                            brand_color={brand_color}
                            isLinks={true}
                            isDashboard={true}
                            isHasMediaUrl={
                              link.type === "FEED" ? true : false
                            }
                            contentShape={content_shape == "sq" ? true : false}
                            contentSound={content_sound}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TrendingProductsWrapper;
