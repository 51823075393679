import { useQuery } from "react-query";
import {
  checkIpAddress,
  checkLandingPage,
  getAllCategories,
  getFeedControls,
  getFiltersAndHighligts,
  getStats,
  getUserAndCheckExistence,
} from "../HelperFunction/HelperFunction";
import { useLocation } from "react-router-dom";

export function useLoadProfileSetup(username: any, page: any) {
  async function fetchAllData() {
    const userData: any = await getUserAndCheckExistence(username);
    const {
      user_id,
      instagram_links,
      userExists,
      description,
      search_bar_text,
      search_bar_tags,
      social_links_order,
      is_fillout_on,
    } = userData;
    await checkLandingPage(user_id);
    const feedControls: any = await getFeedControls(page, user_id);

    // const filterAndHighlights = await getFiltersAndHighligts(user_id, username);
    // const categories: any = await getAllCategories(
    //   user_id,
    //   feedControls?.maxHashtags,
    //   page
    // );
    // const getStoriesStats = await getStats(user_id, username);
    // const isIpNew = await checkIpAddress(user_id);
    const combinedRes = {
      userData: userData,
      feedControls: feedControls,
      // activeFilters: filterAndHighlights.activeFiltersArray,
      // highlights: filterAndHighlights.highlightsArray,
      userExists: userExists,
      // topHashtags: categories.topHashtags,
      // customCategories: categories.customCategories,
      instagram_links: instagram_links,
      // storiesStats: getStoriesStats,
      description: description,
      search_bar_text: search_bar_text,
    };
    // let hashtagFilterArray: any = [];

    // if (feedControls?.showLinks) {
    //   if (instagram_links && instagram_links.length) {
    //     hashtagFilterArray.push({
    //       name: "My Links",
    //       queries: instagram_links ? instagram_links.split(",") : [],
    //     });
    //   }
    // }
    // if (feedControls?.showYesterdayStories) {
    //   if (
    //     combinedRes?.storiesStats &&
    //     combinedRes?.storiesStats?.yesterday === true
    //   ) {
    //     hashtagFilterArray.push({
    //       name: "Yesterday's Content",
    //       queries:
    //         combinedRes?.storiesStats?.yesterdayShop === true
    //           ? ["All", "Shoppable Content"]
    //           : [],
    //       default: "All",
    //     });
    //   }
    // }
    // if (feedControls?.showThisWeekStories) {
    //   if (
    //     combinedRes?.storiesStats &&
    //     combinedRes?.storiesStats?.week === true
    //   ) {
    //     hashtagFilterArray.push({
    //       name: "Last 7 Day’s Content",
    //       queries:
    //         combinedRes?.storiesStats?.weekShop === true
    //           ? ["All", "Shoppable Content"]
    //           : [],
    //       default: "All",
    //     });
    //   }
    // }
    // if (feedControls?.showShoppableLinks) {
    //   if (
    //     combinedRes?.storiesStats &&
    //     combinedRes?.storiesStats?.shopLinks === true
    //   ) {
    //     hashtagFilterArray.push({
    //       name: "Shoppable Content",
    //       queries: [],
    //     });
    //   }
    // }

    // if (feedControls?.showTopHashtags) {
    //   if (combinedRes.topHashtags && combinedRes.topHashtags.length) {
    //     hashtagFilterArray.push({
    //       name: "Top Hashtags",
    //       queries: combinedRes.topHashtags,
    //     });
    //   }
    // }

    // if (feedControls?.showCustomCategories) {
    //   if (combinedRes.customCategories && combinedRes.customCategories.length) {
    //     for (let category of combinedRes.customCategories) {
    //       if (category.value) {
    //         hashtagFilterArray.push({
    //           name: category.name,
    //           queries: category.queries ? category.queries.split(",") : [],
    //           default: category.default,
    //         });
    //       }
    //     }
    //   }
    // }

    // if (feedControls?.showFilters) {
    //   if (combinedRes.activeFilters && combinedRes.activeFilters.length) {
    //     hashtagFilterArray.push({
    //       name: "Content Type",
    //       queries: combinedRes.activeFilters,
    //     });
    //   }
    // }
    // if (feedControls?.showHighlights) {
    //   if (combinedRes.highlights && combinedRes.highlights.length) {
    //     hashtagFilterArray.push({
    //       name: "Instagram Highlights",
    //       queries: combinedRes.highlights,
    //     });
    //   }
    // }
    // if (hashtagFilterArray.length > 0) {
    //   hashtagFilterArray.unshift({
    //     name: combinedRes.feedControls.checkedType,
    //     queries: [],
    //   });
    // }
    return { combinedRes: combinedRes, 
      // hashtagFilterArray: hashtagFilterArray
     };
  }
  return useQuery(["fetchProfileSetup", username, page], fetchAllData, {
    refetchOnWindowFocus: true,
  });
}
