import AuthTerm from "../components/AuthTerm/authTerm";
import "../components/Footer/Footer.css";
import { useLocation } from "react-router-dom";
import track, { TRACKING_TYPES } from "./SupabaseServices/Track";
import { useEffect } from "react";

function Subscribe() {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  const utmSource = queryParams.get("utm_source");

  if (utmSource) {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      details: { source: utmSource },
    });
  } else {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
    });
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    script.onload = () => {
      const pricingTable = document.createElement("stripe-pricing-table");
      // -------dev mode-----------------------
      // pricingTable.setAttribute(
      //   "pricing-table-id",
      //   "prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
      // );
      // pricingTable.setAttribute(
      //   "publishable-key",
      //   "pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN"
      // );
      // -------dev mode-----------------------
      pricingTable.setAttribute(
        "pricing-table-id",
        "prctbl_1R24sdHoyGmWWOpVoAUJxJkK"
      );
      pricingTable.setAttribute(
        "publishable-key",
        "pk_test_51MavLUHoyGmWWOpVfkIMtmMb5oGGXCMNyg5j9CJgx1FAxnv54UUhLsazs4lyVK7c79u07lVbKDdtxoOXslGlm2r900TiOnwzCj"
      );
      const container = document.querySelector(".stripe-table");

      container?.appendChild(pricingTable);
    };

    script.onerror = () => {};

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="auth-container">
      <div className="navbar_container">
        <a href="/" className="navbar_logo-link w-nav-brand" aria-label="home">
          <div className="logo-text">
            <img
              className="homepage-icon"
              src="/assets/output-onlinepngtools-edited.png"
              alt="thesearchbubble logo"
            />
          </div>
        </a>
        <nav className="navbar_menu w-nav-menu">
          <div className="navbar_menu-link-wrapper">
            <a
              href="https://calendly.com/searchbubble/demo"
              target="_blank"
              className="navbar_link w-nav-link sign-up"
            >
              book a demo
            </a>
          </div>
        </nav>
      </div>
      dhfdfkdk
      <div className="stripe-table"></div>
      <div>
        <AuthTerm />
      </div>
    </div>
  );
}

export default Subscribe;
