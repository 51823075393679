import { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../components/Header/header";
import RouteList from "./RouteList";
import ImageSidebar from "../../../components/Header/ImageSidebar";
import { isMobile } from "react-device-detect";
import Account from "../Account/account";
import { fetchUser } from "../../../Utils/SupabaseServices/UserDatabase";
import AnalyticsTabs from "../Analytics/analyticsTab";
import { TabTitle } from "../../../Utils/GeneralFunctions";
import ControlsTab from "../Controls/controlsTab";
import DashboardSkeleton from "../../../components/skeleton/DashboardView/dashboardSkeleton";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import ManageLinks from "../ManageLinks/ManageLinks";
import StayUpToDateControls from "../StayUpToDate/StayUpToDateControls";
import ProductBank from "../ProductBank/ProductBank";
import FeedNew from "../Feed new/FeedNew";
import SocialIntegration from "../SocialIntegration/SocialIntegration";
import { useLoadProfileSetup } from "../../../Utils/customHooks/useLoadProfileSetup";
import "./Dashboard.css";
import Sidebar from "./Sidebar";
import { BannerSkeleton } from "../../../components/StayUpToDateComponent/BannerSkeleton";
import { SkeletonStatBox } from "../Analytics/Overview/OverviewSmoothCharts";
import EmbedSearch from "../EmbedSearch/EmbedSearch";
import SlidingSidebar from "../../../components/SlidingSidebar/SlidingSidebar";
import { useSidebarState } from "../../../context/SidebarStateContext";
import StayUpToDateSkeleton from "../../../components/skeleton/DashboardView/dashboardSkeleton";

const Dashboard = () => {
  const { username } = useParams();
  const { user } = useAuth();
  const user_id = user?.id || "";
  const location = useLocation();

  TabTitle(`${username} • Bubble`);
  const {
    handleClick,
    isEmail,
    isName,
    isPhone,
    isPhoneEmpty,
    tab,
    isEmailVerified,
    option,
    setIsEmail,
    setIsName,
    setIsPhone,
    setIsPhoneEmpty,
    setTab,
    setIsEmailVerified,
  } = useSidebarState();

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     const emailData = localStorage.getItem("email_verify");
  //     const phoneData = localStorage.getItem("phone_verify");
  //     if (location.pathname.includes("productbank")) {
  //       handleClick("Product Bank");
  //     } else {
  //       // Check if either email_verify or phone_verify is "false"
  //       if (emailData === "false" || phoneData === "false") {
  //         // setOption("Account");
  //       } else if (!emailData && !phoneData) {
  //         // Check if both email_verify and phone_verify are not present or have truthy values
  //         // setOption("Analytics");
  //       }
  //     }
  //   };

  //   // Add event listener for storage changes
  //   window.addEventListener("storage", handleStorageChange);

  //   // Initial setup (run once)
  //   handleStorageChange();

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, [window]);

  // const [isEmail, setIsEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [isName, setIsName] = useState(false);
  // const [isPhone, setIsPhone] = useState(false);
  // const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  // const [tab, setTab] = useState<any>();
  // const [isEmailVerified, setIsEmailVerified] = useState<any>();

  useEffect(() => {
    fetchEmail();
  }, [isEmail, isName]);

  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      title: "Dashboard",
      user_id: user_id,
    });
  }, []);

  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(username);
  const {
    isLoading,
    data: creatorData,
    error,
    refetch: refetchUserDetail,
  }: any = useLoadProfileSetup(username, "follower");

  const fetchEmail = useCallback(async () => {
    try {
      const userData = await fetchUser(username);
      if (!userData) return;

      const hasEmail = userData.email !== "";
      const hasName = userData.name !== "";

      if (hasEmail && hasName) {
        setIsName(true);
        setIsEmail(true);
        setIsPhone(true);
        setIsEmailVerified(true);
      } else {
        setIsEmail(hasEmail);
        setIsName(hasName);
        handleClick("Landing Page");
        setTab(0);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  }, [username]);

  return (
    <div className="dashboard">
      <div className="dashboard-layout">
        <Sidebar
          username={username}
          pictureUrl={userData?.picture}
          email_verify={userData?.email_verify}
          userData={userData}
        />

        <div className="content-container">
          <div className="row">
            <h1 className="dashboard-mobile-show">Dashboard</h1>
            {loading ? (
              ""
              // <StayUpToDateSkeleton />
              // <DashboardSkeleton />
            ) : (
              <>
                <div className="right-panel box">
                  {option ? (
                    option === "Account" ? (
                      <Account
                        username={username}
                        setIsEmail={setIsEmail}
                        setIsName={setIsName}
                        setIsPhone={setIsPhone}
                        setIsPhoneEmpty={setIsPhoneEmpty}
                        setIsEmailVerified={setIsEmailVerified}
                        subscription_type={userData?.subscription_type}
                      />
                    ) : option === "Analytics" ? (
                      <AnalyticsTabs
                        username={username}
                        user_id={user_id}
                        subscription_type={userData?.subscription_type}
                        brand_color={userData?.brand_color}
                        showSocials={
                          creatorData?.combinedRes?.userData?.showSocials
                        }
                      />
                    ) : option === "Connected Platforms" ? (
                      <SocialIntegration
                        username={username}
                        user_id={userData}
                      />
                    ) : option === "Analytics" ? (
                      <AnalyticsTabs
                        username={username}
                        user_id={user_id}
                        subscription_type={userData?.subscription_type}
                        brand_color={userData?.brand_color}
                        showSocials={
                          creatorData?.combinedRes?.userData?.showSocials
                        }
                      />
                    ) : option.includes("Controls") ? (
                      <ControlsTab
                        refetch={refetch}
                        subscription_type={userData?.subscription_type}
                      />
                    ) : option.includes("Content") ? (
                      <FeedNew
                        subscription_type={userData?.subscription_type}
                      />
                    ) : option.includes("Links") ? (
                      <ManageLinks username={username} />
                    ) : option.includes("Landing Page") ? (
                      <StayUpToDateControls
                        username={username}
                        showSocials={userData?.showSocials}
                        myLinks={userData?.myLinks}
                        subscription_type={userData?.subscription_type}
                        user_id={user_id}
                        brand_color={userData?.brand_color}
                        isFlowdeskOn={userData?.is_flowdesk_on}
                        flowdeskId={userData?.flowdeskId}
                        isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                        flowdeskPopupId={userData?.flowdeskPopupId}
                        isFilloutOn={userData?.is_fillout_on}
                        filloutId={userData?.filloutId}
                        isLandingPage={true}
                        refetchUser={refetch}
                        refetchUserDetail={refetchUserDetail}
                        creatorData={creatorData}
                        isLoading={isLoading}
                        from="landing-page"
                      />
                    ) : option.includes("Landing") ? (
                      <StayUpToDateControls
                        username={username}
                        showSocials={userData?.showSocials}
                        myLinks={userData?.myLinks}
                        subscription_type={userData?.subscription_type}
                        user_id={user_id}
                        brand_color={userData?.brand_color}
                        isFlowdeskOn={userData?.is_flowdesk_on}
                        flowdeskId={userData?.flowdeskId}
                        isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                        flowdeskPopupId={userData?.flowdeskPopupId}
                        isFilloutOn={userData?.is_fillout_on}
                        filloutId={userData?.filloutId}
                        isLandingPage={false}
                        refetchUser={refetch}
                        refetchUserDetail={refetchUserDetail}
                        creatorData={creatorData}
                        isLoading={isLoading}
                      />
                    ) : option.includes("Product Bank") ? (
                      <ProductBank
                        username={username}
                        showSocials={userData?.showSocials}
                        myLinks={userData?.myLinks}
                        subscription_type={userData?.subscription_type}
                        user_id={user_id}
                        brand_color={userData?.brand_color}
                      />
                    ) : option === "Embed Page" ? (
                      <StayUpToDateControls
                        username={username}
                        showSocials={userData?.showSocials}
                        myLinks={userData?.myLinks}
                        subscription_type={userData?.subscription_type}
                        user_id={user_id}
                        brand_color={userData?.brand_color}
                        isFlowdeskOn={userData?.is_flowdesk_on}
                        flowdeskId={userData?.flowdeskId}
                        isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                        flowdeskPopupId={userData?.flowdeskPopupId}
                        isFilloutOn={userData?.is_fillout_on}
                        filloutId={userData?.filloutId}
                        isLandingPage={false}
                        refetchUser={refetch}
                        refetchUserDetail={refetchUserDetail}
                        creatorData={creatorData}
                        isLoading={isLoading}
                        from="embed-search"
                      />
                    ) : (
                      // <EmbedSearch
                      // username={username}
                      // user_id={user_id}
                      // isFilloutOn={userData?.is_fillout_on}
                      // isLoading={isLoading}
                      // brand_color={userData?.brand_color}
                      // />
                      ""
                    )
                  ) : (
                    // : (
                    //           ""
                    //         )
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
