import React from 'react';
import '../../../styles/skeleton.css';
import StayUpToDateSkeleton from '../../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton';
import { BsSearch } from 'react-icons/bs';

const FrontendSkeleton = (props:any) => {
  const {from} = props;
  return (
    <div id="app-wrapper" className='frontend-skeleton'>
      {/* Profile Section */}
      {from==="embed-search" ?
      <>
      <div className={"search-container-div-landing search-container"}>
            <input
              //   ref={ref}
              type="text"
              id="searchInput"
              enterKeyHint="search"
              placeholder={"Search"}
              //   onInput={(e: any) => {
              //     runSearch(e, e.target.value);
              //     setQuery(e.target.value);
              //   }}
              //   onKeyUp={(e: any) => {
              //     if (e.key === "Enter") {
              //       runSearch(e, e.target.value);
              //       setQuery(e.target.value);
              //     }
              //   }}
              className="search-wrapper-input"
              autoComplete="off"
            />

            {/* {!isSearch ? ( */}
            <button
              className="search-wrapper-btn search-btn-width"
              // onClick={() => search(ref.current.value)}
            >
              <BsSearch />
            </button>
            {/* ) : (
          <button
            className="search-wrapper-btn search-btn-width"
            onClick={reset}
          >
            <BsX />
          </button>
        )} */}
          </div>
      </>
      
      : (
      <>
      <div className="profile-section">
        <div className="avatar-skeleton"></div>
        <div className="text-skeleton name-text"></div>
      </div>

      <div className="text-center">
        <div className="text-skeleton bio-text"></div>
      </div>

     

      <div className="social-icons">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="icon-skeleton"></div>
        ))}
      </div>
      </>
      )}

      {/* Product Cards */}
     
      <div className='uptodate-wrapper'>
      <StayUpToDateSkeleton />
        {/* <div className="product-grid">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="product-card">
              <div className="image-skeleton"></div>
              <div className="product-content">
                <div className="text-skeleton"></div>
                <div className="text-skeleton product-description"></div>
                <div className="button-skeleton"></div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default FrontendSkeleton;