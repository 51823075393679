import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import PuffLoader from "react-spinners/PuffLoader";
import "./PodcastData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit, BsLink, BsLink45Deg } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import DataSkeleton from "../DataSkeleton";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoLink } from "react-icons/go";
import { FaLink } from "react-icons/fa";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import { isMobile } from "react-device-detect";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";

const PodcastData = (props: any) => {
  let platform = props.platform || "podcast";
  let type = props.type || "PODCAST";
  const itemsPerPage = 3;

  const param = useParams();

  const {
    user_id,
    username,
    title,
    handleRedirect,
    brand_color,
    isHandleNavigate,
    component,
    removedPostIds
  } = props;
  const { isLoading: PodcastDataLoading, data: PodcastData }: any =
    useLoadTypeContentData(username, user_id, platform, type, removedPostIds, component?.content_order);

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  const navigate = useNavigate()
  const location = useLocation()
  const domLoaded = useDomLoaded();
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth =
            containerRef.current.scrollWidth / PodcastData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [PodcastData]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / PodcastData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, PodcastData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [PodcastData, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, PodcastData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });

      track("Carousel Click", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,

          title: component.name,
          // name: name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
  };

 
  const ImagePostTag = React.memo((props: any) => {
    const { data } = props;
    const { thumbnail, media_url, permalink, caption, post_id } = data;
    const getWrapperClassName = () => {
      const baseClass = "creator-feed-image-wrapper-pod"
      const classes = [baseClass];
  
      if (component?.content_shape == "sq") classes.push("square-wrapper");
      // else if (!data?.media_url) classes.push("not-available");
  
      return classes.join(" ");
    };
  
    const getImageClassName = () => {
      const baseClass = "creator-feed-image-wrapper-podcast"
      const classes = [baseClass];
  
      if (component?.content_shape == "sq") classes.push("square-image");
  
      return classes.join(" ");
    };
  
    return (
      <a
        href={permalink}
        target="_blank"
        rel="noopener noreferrer"
        className="podcast-link-tag"
        onClick={(e) => {
          e.preventDefault();
          handleRedirect(
            post_id,
            media_url.includes("video") ? thumbnail : media_url,
            "Podcast Content",
            caption,
            platform
          );
          if (permalink && permalink !== "") {
            window.open(permalink, "_blank");
          }
        }}
      >
        <div className="creator-feed-card-container">
          {!media_url ? (
            <div className="creator-feed-card-podcast not-available">
              <BsHourglassSplit className="hourglassSplit" />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <div
              className={getWrapperClassName()}
              style={{
                border: brand_color && `1px solid ${brand_color}`,
                backgroundColor: brand_color && brand_color,
              }}
            >
              <div className={getImageClassName()}>
                <Card.Img
                  variant="top"
                  src={media_url.includes("video") ? thumbnail : media_url}
                  className="creator-feed-card-podcast-image"
                  loading="eager"
                />
                <h4 className="caption">{caption}</h4>
              </div>
            </div>
          )}
        </div>
      </a>
    );
  });

  return (
    <div className="reels-wrapper">
      {PodcastDataLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          {PodcastData && PodcastData.length>0 ? (
            <>
              <div className="mainDiv">
                <p className="component-name" style={param?.id ? { cursor: "auto" } : {}} onClick={(e) => handleNavigate(component)}>
                  {title}
                  {/* {isHandleNavigate && <BsLink45Deg />} */}
                </p>
                {!param?.id ? (
                  <CustomPagination
                    totalItems={PodcastData?.length}
                    currentIndex={currentIndex}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                  />
                ) : null}
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                   className={!param?.id ? "links-grid-container-reels" : isMobile ? "item-grid creator-card-grid-wrp" : "item-grid"}
                >
                  {PodcastData &&
                    PodcastData.map((link: any, index: number) => (
                      <div key={index} className="link-url">
                        <ImagePostTag data={link} />
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default PodcastData;
