import React, { useState } from "react";
import { AiOutlineInstagram, AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import { supabase } from "../../../../config/supabaseClient";
import axios from "axios";
import "../Onboarding.css";
import { useInstagramVerificationMutation } from "../Utils/useInstagramVarification";

const ConnectInstagramStep = (props: any) => {
  const {
    username,
    setUsername,
    handleNext,
    buttonStyle,
    currentStep,
    instagramUsername,
    setInstagramUsername,
  } = props;
  const [usernameError, setUsernameError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const instagramVerification = useInstagramVerificationMutation();
  const [isLoading, setIsLoading] = useState(false);
  const handleConnectUsername = async () => {
    setIsLoading(true);
    try {
      setErrorMsg(""); // Clear any existing errors

      // Verify Instagram username using the mutation
      const profileSetupUrl = `https://dev.thesearchbubble.com/profilesetup-onboarding`;

      const data = JSON.stringify({
        // id: userData?.[0].user_id,
        name: username,
        from: "content",
        instaUsername: instagramUsername,
      });
      const config = {
        method: "post",
        url: profileSetupUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);

      if (response.data.success) {
        setUsernameError("");
        setErrorMsg("");
        // Handle success (e.g., navigate to another page or show a success message)
      } else {
        console.error("Profile setup failed:", response.data.message);
        setUsernameError("Please enter a valid Instagram username");
      }
      await supabase
        .from("user")
        .update({
          is_username_signup: true,
          onboarding_step: currentStep,
          instagram_username: instagramUsername,
          show_instagram: true,
          is_instagram_on: true,
        })
        .eq("username", username);

      handleNext();
    } catch (error: any) {
      setErrorMsg(
        error?.response?.data?.error ||
        "An error occurred while verifying your Instagram username. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="instagram-container">
      <div className="instagram-card">
        <div className="card-header">
          <div className="instagram-icon-wrapper">
            <AiOutlineInstagram className="instagram-icon" />
          </div>
          <h2 className="card-title">Connect Your Instagram</h2>
          <p className="card-description">
            Enter your Instagram username to get started
          </p>
        </div>

        <div className="input-wrapper">
          <span className="input-prefix">@</span>
          <input
            type="text"
            placeholder="username"
            value={instagramUsername}
            onChange={(e) => {
              setInstagramUsername(e.target.value);
              setUsernameError("");
              setErrorMsg("");
            }}
            className="instagram-input"
            style={{
              borderColor: errorMsg ? "#ef4444" : "#4b5563",
            }}
            disabled={instagramVerification.isLoading}
          />
        </div>

        {usernameError && <p className="error-message">{usernameError}</p>}
        {errorMsg && <p className="error-message">{errorMsg}</p>}

        <button
          type="button"
          onClick={handleConnectUsername}
          className="next-button"
          disabled={!instagramUsername || instagramVerification.isLoading}
        >
          {!isLoading ? (
            <>
              {instagramVerification.isLoading ? "Verifying..." : "Next"}
              <HiArrowRight className="arrow-icon" />
            </>
          ) : (
            <AiOutlineLoading3Quarters className="animate-spin" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ConnectInstagramStep;
