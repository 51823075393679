import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadPartialTableCount } from "../Feed/useLoadPartialTableCount";
import { useLoadFirstEmbedding } from "./useLoadFirstSimilarity";
import { useLoadEmbeddingCount } from "./useLoadEmbeddingCount";
import { useLoadPages } from "./useLoadPages";
import { useLoadNecData } from "./useLoadNecData";
import { useLoadEmbeddingPosts } from "./useLoadEmbeddingPosts";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import { Button, Col, Modal, Pagination, PaginationProps, Row } from "antd";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import TabOptions from "../Feed/TabOptions";
import SearchInput from "../../../components/SearchInput/SearchInput";
import BrokenLinkContent from "../Feed/BrokenLinkContent";
import ContentSearchTable from "./ContentSearchTable";
import { useLoadBrokenPages } from "./useLoadBrokenPages";
import { useLoadBrokenPosts } from "./useLoadBrokenPosts";
import { StaticVariables } from "../../../Utils/StaticVariables";
import {
  processPages,
  processPostIdPages,
} from "../../../Utils/SearchProcessLayers/SearchProcessLayer";
import { PlusOutlined } from "@ant-design/icons";
import CreateNewContentPost from "../StayUpToDate/StayUpToDateControlsComponents/ContentComponent/ContentPost/CreateNewContentPost";
import { useLoadPosts } from "./useLoadPosts";
import { useLoadPostIdsPosts } from "./useLoadPostIdsPosts";
import Skeleton from "react-loading-skeleton";
import {
  getFeedControls,
  getPlatformName,
} from "../../../Utils/HelperFunction/HelperFunction";
import { useLoadPostsByPostIds } from "./useLoadPostIdsData";
import "../../DashboardView/DetailDivStyles.css";

const POSTS_PER_PAGE = 10;

// Add this type definition at the top of the file
type PostType = {
  type: string;
  post_id: string;
  platform: string;
};

const FeedNew = (props: any) => {
  const { subscription_type, isLoading: refetchDataLoading } = props;
  const { user } = useAuth();
  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("active");
  const [isLimitError, setLimitError] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [currentBrokenPage, setCurrentBrokenPage] = useState(1);
  const [currentDataPage, setCurrentDataPage] = useState(1);
  const [pageSize, setPageSize] = useState(POSTS_PER_PAGE); // Default page size
  const [feedControls, setFeedControls] = useState<any>([]); // Add state for feedControls

  const handleOk = () => {
    setLimitError(false);
  };
  const handleCancel = () => {
    setLimitError(false);
  };
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  useEffect(() => {
    (async () => {
      const controls = await getFeedControls("follower", user_id);
      setFeedControls(controls);
    })();
  }, [user_id]);

  const [iscontentLoading, setIsContentLoading] = useState(false);
  const [isCreateContentModalOpen, setIsContentModalOpen] = useState(false);
  const [addContentModalShow, setAddContentModalShow] = useState(false);
  const [selectedProductFilter, setSelectedProductFilter] = useState("All");

  // const [isContentDataFullyLoading, setIsContentDataFullyLoading] = useState(true);
  const [allData, setAllData] = useState<any>([]);
  const [platformPostIds, setPlatformPostIds] = useState<{
    [key: string]: string[];
  }>({});
  const [platformLoading, setPlatformLoading] = useState(false);
  const [productFilterItems, setProductFilterItems] = useState<
    { key: string; label: string }[]
  >([]);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const controls = await getFeedControls("follower", user_id);
      setFeedControls(controls);
    })();
  }, [user_id]);

  const filterShowSocials = (showSocials: any) => {
    if (!showSocials) return showSocials;
    const excludedPlatforms = ["LTK", "Amazon", "ShopMy", "MISC"];
    return showSocials.filter(
      (social: string) => !excludedPlatforms.includes(social)
    );
  };
  const { data: brokenPages, isLoading: brokenPagesLoading } =
    useLoadBrokenPages(
      user_id || "",
      activeKey,
      pageSize,
      activeFilter,
      username
    );
  const {
    isLoading: brokenDataLoading,
    // isError,

    data: brokenData,
    hasNextPage: hasNextBrokenPage,
    fetchNextPage: fetchNextBrokenPage,
    // isFetching,
    isFetchingNextPage: isFetchingNextBrokenPage,
    refetch: refetchBrokenData,
  } = useLoadBrokenPosts(
    currentBrokenPage,
    activeKey,
    pageSize,
    brokenPages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    brokenPages?.postIds
  );
  const { value }: any = useLoadPartialTableCount(user_id, username);
  const {
    isLoading: pagesLoading,
    data: pages,
    refetch: refetchPageCount,
  } = useLoadPages(
    user_id || "",
    activeKey,
    pageSize,
    activeFilter,
    username,
    filterShowSocials(userData?.showSocials)
  );
  const { isLoading: pagesFirstEmbedLoading, data: firstEmbedding }: any =
    useLoadFirstEmbedding(
      username || "",
      user_id || "",
      activeFilter,
      filterShowSocials(userData?.showSocials),
      // StaticVariables().PLATFORMS,
      // ["instagram", "youtube", "tiktok", "podcast", "pinterest", "newsletter"],
      pages?.feedCount
    );


  const { isLoading: pagesEmbedLoading, data: pagesEmbed } =
    useLoadEmbeddingCount(
      user_id || "",
      activeKey,
      pageSize,
      activeFilter,
      username,
      firstEmbedding?.threshold,
      filterShowSocials(userData?.showSocials)
    );

  const {
    isLoading: necLoading,
    // isError,
    error: necError,
    data: necData,

    refetch: refetchNec,
  }: any = useLoadNecData(
    user_id || "",
    activeKey,
    pageSize,
    activeFilter,
    username
  );

  const isDataLoading =
    !pagesLoading &&
    !necLoading &&
    !pagesEmbedLoading &&
    !pagesFirstEmbedLoading;

  const mergedPostIds = useMemo(() => {
    if (isDataLoading) {
      return Array.from(
        new Set([
          ...(pages?.postIds || []),
          ...(necData?.postIds || []),
          ...(pagesEmbed?.postIds || []),
        ])
      );
    } else {
      return [];
    }
  }, [pages, necData, pagesEmbed]);

  // Add this function to process the platform filters
  const processContentFilters = (posts: PostType[]) => {
    const platformFilters: { [key: string]: Set<string> } = {};

    posts?.forEach((post) => {
      if (post.platform === "instagram") {
        // For Instagram, separate into "Post" and "Story" categories
        if (["FEED", "REELS"].includes(post.type)) {
          if (!platformFilters["instagram_posts"]) {
            platformFilters["instagram_posts"] = new Set();
          }
          platformFilters["instagram_posts"].add(post.post_id);
        } else if (["STORY", "HIGHLIGHTS"].includes(post.type)) {
          if (!platformFilters["instagram_stories"]) {
            platformFilters["instagram_stories"] = new Set();
          }
          platformFilters["instagram_stories"].add(post.post_id);
        }
      } else {
        // For other platforms, group by platform name
        const filterKey = post.platform.toLowerCase();
        if (!platformFilters[filterKey]) {
          platformFilters[filterKey] = new Set();
        }
        platformFilters[filterKey].add(post.post_id);
      }
    });

    // Convert Sets to arrays and create the final filter object
    return Object.entries(platformFilters).reduce((acc, [key, postIds]) => {
      acc[key] = Array.from(postIds);
      return acc;
    }, {} as { [key: string]: string[] });
  };

  // Add useMemo to get filtered post IDs based on selected filter
  const filteredPostIds = useMemo(() => {
    if (selectedProductFilter === "All") return null;
    return platformPostIds[selectedProductFilter] || [];
  }, [selectedProductFilter, platformPostIds]);
  // Modify the API call hooks to use filteredPostIds
  const { data: postIdData } = useLoadPostIdsPosts(
    currentDataPage,
    activeKey,
    pageSize,
    isDataLoading ? mergedPostIds.length : null,
    activeFilter,
    username,
    user_id || "",
    isDataLoading,
    mergedPostIds, // Use filtered IDs if available
    filterShowSocials(userData?.showSocials)
  );

  const {
    isLoading,
    // isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    // isFetching,
    isFetchingNextPage,
    refetch,
  } = useLoadPosts(
    currentDataPage,
    activeKey,
    pageSize,
    pages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pages?.postIds,
    filterShowSocials(userData?.showSocials)
  );

  const {
    isLoading: embedLoading,
    // isError,
    error: embedError,
    data: embedData,
    hasNextPage: hasEmbedNextPage,
    fetchNextPage: fetchEmbedNextPage,
    // isFetching,
    // isFetchingNextPage,
    refetch: refectchEmbed,
  }: any = useLoadEmbeddingPosts(
    currentDataPage,
    activeKey,
    pageSize,
    pagesEmbed?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pagesEmbed?.postIds,
    firstEmbedding?.threshold,
    filterShowSocials(userData?.showSocials),
    feedControls?.contentOrder
  );

  // Add this new hook usage near other API hooks
  const { data: filteredPostsData, isLoading: filteredPostsLoading } =
    useLoadPostsByPostIds(
      currentDataPage,
      activeKey,
      pageSize,
      Math.ceil((filteredPostIds?.length || 0) / pageSize), // Calculate total pages
      username,
      user_id,
      filteredPostIds || [], // Pass filtered post IDs
      filterShowSocials(userData?.showSocials)
    );

  useEffect(() => {
    refetchPageCount();
    setAllData(data);
  }, [data?.pages[0], embedData, necData]);

  let necDataIncluded = false;

  const loadingRef = useRef(true); // useRef for mutable reference

  const logProcessStartEnd = (fn: any, ...args: any) => {
    let result;
    try {
      result = fn(...args);

      loadingRef.current = false; // Set loading to false

      // setMergedDataLoading(result); // Set the result in state
    } catch (error) {
    } finally {
      loadingRef.current = false; // Set loading to false
    }
    return result;
  };
  // const mergedData=postIdData

  // Modify the mergedData calculation to use filtered data when a filter is selected
  const mergedData: any = useMemo(() => {
    if (selectedProductFilter !== "All" && filteredPostsData?.pages) {
      return filteredPostsData.pages[0];
    }

    if (pages && pages?.totalPages !== 0 && allData?.pages && !activeFilter) {
      return logProcessStartEnd(
        processPages,
        allData?.pages,
        pages?.feedCount,
        necData,
        necLoading,
        necDataIncluded,
        embedData?.pages
      );
    }

    if (
      mergedPostIds &&
      mergedPostIds.length > 0 &&
      postIdData?.pages?.some((page: any) => page?.length > 0)
    ) {
      return logProcessStartEnd(
        processPostIdPages,
        postIdData?.pages,
        mergedPostIds.length,
        necData,
        necLoading,
        necDataIncluded,
        embedData?.pages
      );
    }

    if (pagesEmbed && pagesEmbed?.totalPages != 0) {
      return logProcessStartEnd(
        processPostIdPages,
        embedData?.pages,
        pagesEmbed.feedCount,
        necData,
        necLoading,
        necDataIncluded,
        embedData?.pages[0]
      );
    }

    return "";
  }, [
    selectedProductFilter,
    filteredPostsData,
    pages,
    allData,
    activeFilter,
    mergedPostIds,
    postIdData,
    pagesEmbed,
    embedData,
    necData,
    necLoading,
  ]);

  const handleActiveFilter = () => {
    // Reset filter and state when tab changes
    setActiveFilter(null);
    setCurrentDataPage(1); 
    setCurrentBrokenPage(1);
    setSelectedProductFilter("All");
  };
  

  const handlePageChange = (page: any) => {
    setCurrentBrokenPage(page);
  };
  const handlePageDataChange = (page: any) => {
    setCurrentDataPage(page);
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageSize(pageSize);
  };

  const isBrokenDataFullyLoading = brokenDataLoading && brokenPagesLoading;

  // Update isContentDataFullyLoading to include filteredPostsLoading
  const isContentDataFullyLoading = useMemo(() => {
    return (
      isLoading ||
      pagesLoading ||
      pagesFirstEmbedLoading ||
      pagesEmbedLoading ||
      iscontentLoading ||
      postLoading ||
      embedLoading ||
      (selectedProductFilter !== "All" && filteredPostsLoading)
    );
  }, [
    isLoading,
    pagesLoading,
    pagesEmbedLoading,
    iscontentLoading,
    allData,
    postLoading,
    embedLoading,
    selectedProductFilter,
    filteredPostsLoading,
  ]);

  const totalUniquePostsCount = useMemo(() => {
    if (!(pages?.postIds.length > 0) && !(pagesEmbed?.postIds.length > 0))
      return (pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0);

    // Combine post IDs from both sources and create a Set to get unique values

    const uniquePostIds = new Set([
      ...(pages?.postIds || []),
      ...(pagesEmbed?.postIds || []),
      ...(necData?.postIds || []),
    ]);
    return uniquePostIds.size;
  }, [pages?.postIds, pagesEmbed?.postIds, necData?.postIds]);

  // Update the pagination total count calculation
  const paginationTotal = useMemo(() => {
    if (selectedProductFilter !== "All") {
      return filteredPostIds?.length || 0;
    }
    return mergedPostIds && mergedPostIds.length > 0
      ? mergedPostIds.length
      : totalUniquePostsCount;
  }, [
    selectedProductFilter,
    filteredPostIds,
    mergedPostIds,
    totalUniquePostsCount,
  ]);

  useEffect(() => {
    setPlatformLoading(true);
    if (
      pages?.productFilterItems ||
      pagesEmbed?.productFilterItems ||
      necData?.productFilterItems
    ) {
      // Merge all product filter items from different sources
      let allProductItems = [
        ...(pages?.productFilterItems || []),
        ...(pagesEmbed?.productFilterItems || []),
        ...(necData?.productFilterItems || []),
      ]
     

      // Create a map to track unique platforms and their content types
      const platformMap = new Map<string, Set<string>>();

      const filters = processContentFilters(allProductItems);
      setPlatformPostIds(filters);
      allProductItems.forEach((item: any) => {
        if (item.platform === "instagram") {
          // For Instagram, check content type
          if (["FEED", "REELS"].includes(item.type)) {
            if (!platformMap.has("instagram_posts")) {
              platformMap.set("instagram_posts", new Set());
            }
            platformMap.get("instagram_posts")?.add(item.post_id);
          } else if (["STORY", "HIGHLIGHTS"].includes(item.type)) {
            if (!platformMap.has("instagram_stories")) {
              platformMap.set("instagram_stories", new Set());
            }
            platformMap.get("instagram_stories")?.add(item.post_id);
          }
        } else if (!["MISC", "MISC-content"].includes(item.platform)) {
          // For other platforms
          if (!platformMap.has(item.platform)) {
            platformMap.set(item.platform, new Set());
          }
          platformMap.get(item.platform)?.add(item.post_id);
        }
      });

      const items = [
        { key: "All", label: "All" },
        ...Array.from(platformMap.keys()).map((platform) => ({
          key: platform,
          label: getPlatformName(
            platform === "instagram_posts"
              ? "Post"
              : platform === "instagram_stories"
              ? "Story"
              : platform
          ),
        })),
      ] as { key: string; label: string }[];
      setProductFilterItems(items);
      setPlatformLoading(false);
    }
  }, [
    pages?.productFilterItems,
    pagesEmbed?.productFilterItems,
    necData?.productFilterItems,
    activeFilter,
  ]);

  // Add the handleProductFilter function
  const handleProductFilter = (e: React.MouseEvent, key: string) => {
    setSelectedProductFilter(key);
    setCurrentDataPage(1);
    setCurrentBrokenPage(1);
  };
  return (
    <div>
      <div className="d-side">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().CONTENT_TITLE}
        </h1>

        <TabOptions
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          handleActiveFilter={handleActiveFilter}
          subscription_type={subscription_type}
          brokenLinksContent={brokenData}
          loadingRef={loadingRef}
          setActiveFilter={setActiveFilter}
          setCurrentDataPage={setCurrentDataPage}
          setCurrentBrokenPage={setCurrentBrokenPage}
          setSelectedProductFilter={setSelectedProductFilter}
        />
      </div>

      {/* Hide the detail-margin div when there's no data for any of the special tabs */}
      {(
        // For "active" tab, always show the div
        activeKey === "active" || 
        // For "active,ad" (Content Bank), only show if there's data
        (activeKey === "active,ad" && Array.isArray(mergedData) && mergedData.length > 0) ||
        // For "active,featured" (Featured), only show if there's data
        (activeKey === "active,featured" && Array.isArray(mergedData) && mergedData.length > 0) ||
        // For "archived", only show if there's data
        (activeKey === "archived" && Array.isArray(mergedData) && mergedData.length > 0) ||
        // For "broken_links", only show if there's data
        (activeKey === "broken_links" && brokenPages && typeof brokenPages.feedCount === 'number' && brokenPages.feedCount > 0)
      ) && (
        <div className={`${activeKey === "active" ? "detail-margin" : "detail-margin-simple"} ${activeKey === "broken_links" ? "broken_links" : ""}`}>
          <Row gutter={16} align="middle" wrap={false}>
            {(pages?.feedCount != 0 || pagesEmbed?.feedCount != 0) &&
              activeKey === "active" && (
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className="product-bank-add-btn-icon"
                    onClick={() => setAddContentModalShow(true)}
                    id="create-content-modal"
                  />
                </Col>
              )}
            <Col flex="auto">
              <SearchInput
                placeholder={
                  activeKey === "active,ad"
                    ? `Search all ads`
                    : activeKey === "active,featured"
                    ? `Search all featured content`
                    : activeKey === "broken_links"
                    ? `Search all broken links`
                    : `Search all ${activeKey} content`
                }
                id="search-feed"
                handleKeyUp={(e: any) => {
                  e.preventDefault();
                  if (e.keyCode === 13) {
                    const elem: any = document.getElementById("search-feed");
                    const searchVal = e.target.value.trim();
                    if (searchVal.length === 0) {
                      elem.blur();
                      setActiveFilter(null);
                      setCurrentBrokenPage(1);
                      setCurrentDataPage(1);
                      return;
                    }
                    elem.blur();
                    setActiveFilter(searchVal);
                    setCurrentBrokenPage(1);
                    setCurrentDataPage(1);
                    setSelectedProductFilter("All");
                  }
                }}
                isActiveFilter={Boolean(activeFilter)}
                onSearchClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  const searchVal = elem.value;
                  if (searchVal.length === 0) {
                    return;
                  }
                  elem.blur();
                  setActiveFilter(searchVal);
                  setCurrentBrokenPage(1);
                  setCurrentDataPage(1);
                  setSelectedProductFilter("All");
                }}
                onClearClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  elem.value = "";
                  setActiveFilter(null);
                  setCurrentBrokenPage(1);
                  setCurrentDataPage(1);
                  setSelectedProductFilter("All");
                }}
              />
            </Col>
          </Row>
          {(activeKey === "active" || 
             activeKey === "active,ad" || 
             activeKey === "active,featured" || 
             activeKey === "archived" || 
             activeKey === "broken_links") && (
            <div className="filter-section">
              {!isDataLoading || platformLoading ? (
                <div className="segmented-container">
                  {[...Array(4)].map((_, index) => (
                    <Skeleton
                      key={index}
                      height={40} // Adjust height to match the button size
                      width={100} // Adjust width to match the button size
                      borderRadius={10} // For round edges
                      style={{ marginBottom: "10px" }}
                    /> // Add spacing between skeletons/>
                  ))}
                </div>
              ) : (
                <>
                  {productFilterItems && productFilterItems.length > 1 && (
                    <>
                      <p>Filter By Platform</p>
                      <div className="segmented-container">
                        {productFilterItems &&
                          productFilterItems?.map(
                            (elem: { key: string; label: string }) => (
                              <React.Fragment key={elem.key}>
                                <Button
                                  data-testid={elem.key}
                                  onClick={(e) =>
                                    handleProductFilter(e, elem.key)
                                  }
                                  style={{
                                    background:
                                      selectedProductFilter === elem.key
                                        ? "#3b3b3b"
                                        : "white",
                                    color:
                                      selectedProductFilter === elem.key
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {elem.label}
                                </Button>
                              </React.Fragment>
                            )
                          )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}

      {activeKey === "broken_links" ? (
        <>
          {isBrokenDataFullyLoading ? (
            <ContentSkeleton />
          ) : (
            <>
              {brokenPages && typeof brokenPages.feedCount === 'number' && brokenPages.feedCount > 0 ? (
                <>
                  <BrokenLinkContent
                    username={username}
                    user_id={user_id}
                    activeKey={activeKey}
                    data={brokenData}
                    setLimitError={setLimitError}
                    filter={activeFilter}
                    refetchData={refetchBrokenData}
                    setIsContentLoading={setIsContentLoading}
                    subscription_type={subscription_type}
                    brokenDataLoading={brokenDataLoading && brokenPagesLoading}
                  />
                  {brokenPages?.feedCount && brokenPages?.feedCount > pageSize ? (
                    <Pagination
                      showQuickJumper
                      current={currentBrokenPage}
                      pageSize={pageSize}
                      total={brokenPages?.feedCount}
                      onChange={handlePageChange}
                      onShowSizeChange={onShowSizeChange}
                      className="pagination-data-change"
                    />
                  ) : null}
                </>
              ) : (
                <div className="full-width-container" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div className="empty-state-message" style={{ margin: '40px auto' }}>
                    <span className="empty-state-icon">🎉</span>
                    <p>Great job! Your page has no broken links.</p>
                    <p>We'll notify you if any broken links are detected in the future.</p>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          {isContentDataFullyLoading ? (
            <ContentSkeleton />
          ) : value ? (
            <>
              <ContentSearchTable
                username={username}
                user_id={user_id}
                activeKey={activeKey}
                data={mergedData}
                setLimitError={setLimitError}
                filter={activeFilter}
                refetchData={refetch}
                setIsContentLoading={setIsContentLoading}
                subscription_type={subscription_type}
                isLoading={isLoading}
                loadingRef={loadingRef.current}
                totalSize={
                  mergedPostIds && mergedPostIds.length > 0
                    ? mergedPostIds.length
                    : (pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0)
                }
              />
              {(pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0) &&
              totalUniquePostsCount > pageSize ? (
                <Pagination
                  showQuickJumper
                  current={currentDataPage}
                  pageSize={pageSize}
                  total={paginationTotal}
                  onChange={handlePageDataChange}
                  onShowSizeChange={onShowSizeChange}
                  className="pagination-data-change"
                />
              ) : null}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <Modal
        title="Only 2 posts can be added as ads."
        open={isLimitError}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
      ></Modal>
      <CreateNewContentPost
        user_id={user_id}
        username={username}
        isCreateContentModalOpen={addContentModalShow}
        setIsCreateContentModalOpen={setAddContentModalShow}
        // setIsModalOpen={setIsModalOpen}
        from_id="FeedNew"
        refetchData={refetch}
      />
    </div>
  );
};

export default FeedNew;
