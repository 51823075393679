import React, { useCallback, useState } from "react";
import { Button, Input, Upload, Modal } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { debounce } from "lodash";
import { supabase } from "../../../../../config/supabaseClient";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";
import { addToProductBank } from "../../../../../Utils/SupabaseServices/ProductBankDatabase";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { ModalBackButton } from "../../../../../components/EditModalComponents.tsx/ModalBackButton";

const CreateNewForm = ({
  username,
  user_id,
  isCreateFormModalOpen,
  setIsCreateFormModalOpen,
  setIsModalOpen,
  refetch,
  from_id,
  from,
}:any) => {
  const [embedCode, setEmbedCode] = useState<string>("");
  const [previewEmbedCode, setPreviewEmbedCode] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [name, setName] = useState<string>("");

  const handleInputChange = (value: string) => {
    setEmbedCode(value);
    setErrorMsg("");

    // Extract src from iframe if present
    if (value.toLowerCase().includes("<iframe")) {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(value, "text/html");
        const iframe = doc.querySelector("iframe");

        if (iframe) {
          let iframeSrc: string | null = iframe.getAttribute("src");

        //   if (iframeSrc) {
        //     iframeSrc = iframeSrc.replace(/^["']|["']$/g, ""); // Remove unnecessary quotes

        //     // ✅ Fixed: Properly formatted iframe with correct attribute handling
        //     const iframeCode = `<iframe 
        //         src="${iframeSrc}" 
        //         width="100%" 
        //         height="500" 
        //         frameborder="0">
        //       </iframe>`;

        //     setPreviewEmbedCode(iframeCode);
        //   } else {
            setPreviewEmbedCode(
                value
                  .replace(/“|”/g, '"') // Replace curly double quotes with standard double quotes
                  .replace(/‘|’/g, "'") // Replace curly single quotes with standard single quotes
              );
              
        //   }
          return;
        }
      } catch (error) {
        console.error("Error parsing iframe:", error);
      }
    }

    // If not an iframe or src extraction fails, use original input
    setPreviewEmbedCode(value);
  };

  const handleCreate = async () => {
    if (!embedCode) {
      setErrorMsg("Embed code is required!");
      return;
    }
    const res = await addComponent(
        name,
        previewEmbedCode,
        username,
        user_id,
        "false",
        "Check it out!",
        "",
        name,
        "embed-form",
        "",
        "DESC",
        "rect",
        false,
        undefined,
        from
      );
      if(res==="true"){
        refetch();
        setName("");
        setEmbedCode("");
        setPreviewEmbedCode("");
        setErrorMsg("");
        setIsCreateFormModalOpen(false);
      }
    // try {
    //   await addToProductBank("Embedded Content", "", embedCode, user_id, "", username);
    //   setTimeout(() => {
    //     refetchData();
    //   }, 5000);
    //   handleClear();
    //   setIsCreateFormModalOpen(false);
    // } catch (error) {
    //   console.error("Error adding embed:", error);
    // }
  };

  const handleClear = () => {
    setEmbedCode("");
    from_id && from_id !== "FeedNew" && setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateFormModalOpen(false);
    handleClear();
  };

  const handlePrevious = () => {
    setIsModalOpen(true);
    setIsCreateFormModalOpen(false);
  };

  return (
    <Modal
      // title={
      //   from_id && from_id === "FeedNew" ? (
      //     ""
      //   ) : (
      //     <div className="modal-title">
      //       <button onClick={handlePrevious} className="newbutton">
      //         <FaArrowLeft />
      //       </button>
      //     </div>
      //   )
      // }
      open={isCreateFormModalOpen}
      onCancel={handleCancel}
      onOk={handleCreate}
      okText="Add"
      okButtonProps={{ disabled: !embedCode || !name }}
      data-testid="create-product"
      width={600}
      centered
      className="add-to-page-modal"
      closeIcon={<span className="modal-close-icon">×</span>}
    >
      <ModalBackButton handlePrevious={handlePrevious}  />
      <div>
        <h5 className="title mb-15">Add Custom Code</h5>

        <div className="fieldGroup">
          <label className="bold-label">Name</label>
          <Input
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-15"
            id="embed-name-input"
          />
        </div>

        <div className="fieldGroup">
          <label className="bold-label">Embed Code</label>
          <Input.TextArea
            placeholder="Paste your HTML or iframe embed code here"
            value={embedCode}
            onChange={(e) => handleInputChange(e.target.value)}
            className="mb-15"
            rows={4}
            id="embed-code-input"
          />
        </div>
      </div>

      {/* ✅ Fixed: Preview Section */}
      <div className="mt-2">
        <label className="bold-label" style={{textDecoration:'underline'}}>Preview</label>
<br/>
{/* <br/> */}
        {name && (
          <div style={{ 
            marginBottom: "10px", 
            fontSize: "1rem", 
            fontWeight: "700" 
          }}>
            {name}
          </div>
        )}
        {/* <div style={{ marginBottom: "10px", fontSize: "12px", color: "#666" }}>
          Preview Content: {previewEmbedCode ? "Has content" : "Empty"}
        </div> */}
        <div
          style={{
            width: "100%",
            height: "500px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "8px",
            backgroundColor: "#f5f5f5",
          }}
        >
          {previewEmbedCode ? (
            <div
              style={{ width: "100%", height: "100%" }}
              dangerouslySetInnerHTML={{ __html: previewEmbedCode }} // ✅ Renders iframe properly
            />
          ) : (
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              No preview available
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateNewForm;
