import React from "react";
import {
  BsInstagram,
  BsLink45Deg,
  BsPinterest,
  BsYoutube,
} from "react-icons/bs";
import { IoMailOutline, IoLogoTiktok } from "react-icons/io5";
import { MdOutlinePodcasts } from "react-icons/md";
import { FaAmazon, FaLinkedin } from "react-icons/fa";
const PostInfoPermalink = (props: any) => {
  const { permalink, handleRedirect, post_id, media_url, platform,user_id } = props;

  const Icon =
    platform === "instagram"
      ? BsInstagram
      : platform === "newsletter"
      ? IoMailOutline
      : platform === "pinterest"
      ? BsPinterest
      : platform === "podcast"
      ? MdOutlinePodcasts
      : platform === "tiktok"
      ? IoLogoTiktok
      : platform === "linkedin"
      ? FaLinkedin
      : platform === "twitter"
      ? function TwitterIcon() {
          return (
            <img
              src="/assets/icons8-twitterx-30.png"
              alt="X (Twitter)"
              className="social-img"
              style={{
                width: "1em",
                height: "1em",
                filter: "brightness(1)",
                transition: "filter 0.2s",
              }}
              onMouseEnter={(e: any) => {
                e.target.style.filter = "brightness(0.6)";
              }}
              onMouseLeave={(e: any) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
          );
        }
      : platform === "youtube"
      ? BsYoutube
      : platform === "Amazon"
      ? FaAmazon
      : platform === "MISC-content"
      ? function MiscContentIcon() {
          return (
            <></>
            //   <img src="/assets/icons8-amazon-30.png" alt="Amazon" className="social-img" />
          );
        }
      : function MiscContentIcon() {
        return (
          <></>
          //   <img src="/assets/icons8-amazon-30.png" alt="Amazon" className="social-img" />
        );
      };

  return (
    <>
      {permalink && platform ? (
        <a href={permalink} target="_blank" rel="noreferrer">
          {platform === "twitter" ? (
            <Icon />
          ) : (
            <Icon
              className="bs-link"
              id={`${platform}-icon`}
              onMouseEnter={(e: any) => {
                e.target.style.color = "#888";
              }}
              onMouseLeave={(e: any) => {
                e.target.style.color = "#000";
              }}
              onClick={() => handleRedirect(post_id, media_url,platform,user_id)}
            />
          )}
        </a>
      ) : platform === "twitter" ? (
        <Icon />
      ) : (
        <Icon className="ml-6" id={`${platform}-icon`} />
      )}
    </>
  );
};

export default PostInfoPermalink;
