import React, { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiCheck, FiTrash2, FiX, FiPlus } from "react-icons/fi";
import { MdDragIndicator } from "react-icons/md";
import "./LineTemplate.css";
import { storeUserLinks } from "../../../../../../Utils/SupabaseServices/UserLinksDatabase";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../../Utils/uploadImage/UploadImage";
import { Upload, UploadProps } from "antd";
import { DeleteConfirmation } from "../LinksComponentDashboard";
import { Alert, Col, Input, Modal, Row, Spin, Popover } from "antd";
import { deleteUserLinks } from "../../../../../../Utils/SupabaseServices/UserLinksDatabase";

const LineTemplate = (props: any) => {
  const {
    card,
    onRemove,
    username,
    user_id,
    componentName,
    isDashboard,
    refetch,
    brand_color,
    setEditIndex,
    editIndex,
    templates,
    handleRedirect,
    from
  } = props;
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [validationErrorLink, setValidationErrorLink] = useState<string | null>(
    null
  );
  const [isNewCard, setIsNewCard] = useState(card.id === 0);

  const isEditing = editIndex === card.id;

  useEffect(() => {
    if (!isNewCard || card.link_name || card.link_url) {
      setTitle(card.link_name);
      setLink(card.link_url);
      setImageUrl(card.image_url);
    }
  }, [card, isNewCard]);

  useEffect(() => {
    // Reset values when editIndex changes
    if (editIndex !== card.id) {
      setTitle(card.link_name || "");
      setLink(card.link_url || "");
      setImageUrl(card.image_url);
      setValidationError(null);
      setValidationErrorLink(null);
    }
  }, [editIndex, card]);

  useEffect(() => {
    if (props.image_url && !imageUrl) {
      setImageUrl(props.image_url);
    }
  }, [props.image_url]);

  const handleSave = async (e: any) => {
    e.stopPropagation();

    if (!title || !title.trim()) {
      setValidationError("Title is required");
      return;
    }

    if (title.length > 250) {
      setValidationError("Title must be 250 characters or less");
      return;
    }

    if (!link || !link.trim()) {
      setValidationErrorLink("Link is required");
      return;
    }

    const duplicateTitle = templates.find(
      (template: any) =>
        template.link_name.toLowerCase() === title.trim().toLowerCase() &&
        template.id !== card.id
    );

    if (duplicateTitle) {
      setValidationError("A link with this title already exists");
      return;
    }

    try {
      const result = await storeUserLinks(
        card.id === 0 ? null : card.id,
        user_id,
        username,
        title.trim(),
        link.trim(),
        imageUrl,
        componentName,
        from
      );

      setValidationError(null);
      setValidationErrorLink(null);
      setIsNewCard(false);
      setEditIndex(undefined);
      refetch();
    } catch (error) {
      console.error("Error saving link:", error);
      setValidationError("Failed to save link. Please try again.");
    }
  };

  const handleDelete = async () => {
    try {
      if (card.id !== 0) {
        await deleteUserLinks(user_id, componentName, card.id);
      }
      onRemove(card.id);
      setShowDeleteConfirm(false);
      setEditIndex(undefined);
      refetch();
    } catch (error) {
      console.error("Error deleting link:", error);
    }
  };

  const debouncedUpload = useCallback(
    debounce(async (info: any) => {
      const { file } = info;

      if (uploading) return;
      setUploading(true);

      try {
        const response = await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setImageUrl,
          storageName: "mylinks",
        });

        if (response?.publicUrl) {
          setImageUrl(response.publicUrl);
          props.onChange?.({ image_url: response.publicUrl });
        }
      } finally {
        setUploading(false);
      }
    }, 300),
    []
  );

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  };
  const handleCancelSave = () => {
    refetch();
    setTitle(card.link_name);
    setLink(card.link_url);
    setImageUrl(card.image_url);
    setEditIndex(undefined);

    setValidationError("");
    setValidationErrorLink("");
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    // If this card is already being edited, do nothing
    if (editIndex === card.id) {
      return;
    }
    // Reset any validation errors
    setValidationError(null);
    setValidationErrorLink(null);
    // Reset values to original card values
    setTitle(card.link_name || "");
    setLink(card.link_url || "");
    setImageUrl(card.image_url);
    // Set the new editIndex
    setEditIndex(card.id);
  };

  return (
    <div className="card-link card-line w-100">
      {isDashboard ? (
        <div className="creator-card" style={{ backgroundColor: brand_color }}>
          <div className={`line-template-card ${imageUrl ? 'has-image' : ''}`}>
            <div
              className="line-template-card-content"
              onClick={(e) => {
                if (isDashboard && (!editIndex || editIndex !== card.id)) {
                  e.stopPropagation();
                  handleEditClick(e);
                }
              }}
            >
              {isDashboard && (
                <div className="line-template-actions">
                  {isEditing ? (
                    <>
                      <button
                        className="line-template-save-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSave(e);
                        }}
                      >
                        <FiCheck size={20} />
                      </button>
                      <button
                        className="line-template-save-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelSave();
                        }}
                      >
                        <FiX size={20} />
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="line-template-save-button">
                        <MdDragIndicator
                          size={20}
                          // className="drag-handle"
                          style={{ cursor: "grab" }}
                        />
                      </button>
                      <button
                        className="line-template-edit-button"
                        onClick={(e) => {
                          if (isDashboard) {
                            e.stopPropagation();
                            handleEditClick(e);
                          }
                        }}
                      >
                        <FiEdit2 size={20} />
                      </button>
                    </>
                  )}
                  {isEditing && (
                    <button
                      className="line-template-delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteConfirm(true);
                      }}
                    >
                      <FiTrash2 size={20} />
                    </button>
                  )}
                </div>
              )}

              {showDeleteConfirm && (
                <DeleteConfirmation
                  setShowDeleteConfirm={setShowDeleteConfirm}
                  setEditIndex={setEditIndex}
                  handleDelete={handleDelete}
                />
              )}

              <div className="line-image-wrapper">
                {imageUrl ? (
                  <div
                    className="line-template-logo-placeholder"
                  // style={{ border: "1px solid black" }}
                  >
                    {imageUrl ? (
                      <div className="line-template-logo-container">
                        <>
                          <img
                            src={imageUrl}
                            alt="logo"
                            className="line-template-logo"
                          />
                          {isEditing && (
                            <div
                              className="line-template-logo-overlay"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="line-template-logo-overlay-actions">
                                <FiTrash2
                                  size={20}
                                  className="line-template-overlay-icon remove"
                                  onClick={() => setImageUrl(null)}
                                />
                                <Upload
                                  {...uploadProps}
                                  multiple={false}
                                  maxCount={1}
                                  showUploadList={false}
                                >
                                  <FiPlus
                                    size={20}
                                    className="line-template-overlay-icon add"
                                  />
                                </Upload>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    ) : (
                      <div className="line-template-logo-overlay">
                        <Upload
                          {...uploadProps}
                          multiple={false}
                          maxCount={1}
                          showUploadList={false}
                        >
                          <div className="upload-area">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              className="line-template-icon"
                            >
                              <path
                                d="M12 4v16m-8-8h16"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </Upload>
                      </div>
                    )}
                  </div>
                ) : !isEditing ? (
                  title && link ? (
                    <></>
                  ) : (
                    <div
                      className="line-template-logo-placeholder"
                    // style={{ border: "1px solid black" }}
                    >
                      <Upload
                        {...uploadProps}
                        multiple={false}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <div className="upload-area">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            className="line-template-icon"
                          >
                            <path
                              d="M12 4v16m-8-8h16"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </Upload>
                    </div>
                  )
                ) : (
                  <div
                    className="line-template-logo-placeholder"
                  // onClick={(e) => e.stopPropagation()}
                  // style={{ border: "1px solid black" }}
                  >
                    <Upload
                      {...uploadProps}
                      multiple={false}
                      maxCount={1}
                      showUploadList={false}
                    >
                      <div className="upload-area">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          className="line-template-icon"
                        >
                          <path
                            d="M12 4v16m-8-8h16"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </Upload>
                  </div>
                )}
              </div>

              <div className={`line-template-left-section ${(isEditing && !imageUrl) ? 'has-image' : ''}`}>
                <div
                  className={
                    isEditing
                      ? `line-template-text-section w-100 line-title-center ${(isEditing && !imageUrl) && "input-section"}`
                      : "line-template-text-section w-100"
                  }
                >
                  {!!validationError && (
                    <Popover
                      content={
                        <span className="errorMsg">{validationError}</span>
                      }
                      open={!!validationError}
                      placement="top"
                    ></Popover>
                  )}
                  <div className="line-template-title-section">
                    {isEditing ? (
                      <Input
                        type="text"
                        value={title}
                        placeholder="Enter title"
                        onChange={(e) => {
                          setTitle(e.target.value);
                          if (validationError) setValidationError(""); // Clear error when typing
                        }}
                        className="line-template-title-input"
                        autoFocus
                      />
                    ) : (
                      <>
                        {title ? (
                          <div
                            className={
                              imageUrl
                                ? "line-template-text-center"
                                : "line-template-text-center"
                            }
                          >
                            <p className={`line-template-title ${!imageUrl ? 'no-image' : ''}`}>
                              {title}
                            </p>
                          </div>
                        ) : (
                          <div className="line-template-text-center">
                            <p className={`line-template-title title-placeholder ${!imageUrl ? 'no-image' : ''}`}>
                              Title goes here
                            </p>
                          </div>
                        )}
                      </>
                      // <h1 className="line-template-title">{title}</h1>
                    )}
                  </div>
                  {validationErrorLink && (
                    <Popover
                      content={
                        <span className="errorMsg">{validationErrorLink}</span>
                      }
                      open={!!validationErrorLink}
                      placement="top"
                    ></Popover>
                  )}
                  {
                    isEditing ? (
                      <div className="line-template-link-section">
                        <Input
                          type="text"
                          value={link}
                          placeholder="Enter Link"
                          onChange={(e) => {
                            setLink(e.target.value);
                            if (validationErrorLink) setValidationErrorLink(""); // Clear error when typing
                          }}
                          // onChange={(e) => setLink(e.target.value)}
                          className="line-template-link-input"
                        />
                      </div>
                    ) : null
                    // <p className="line-template-link">{link}</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <a href={card.link_url} target="_blank" rel="noopener noreferrer"
          onClick={() =>
            handleRedirect(
              card.id,
              card.image_url,
              componentName,
              card.link_name,
              "links-section"
              // true
            )
          }>
          <div
            className="creator-card"
            style={{ backgroundColor: brand_color }}
          >
            <div className={`line-template-card ${imageUrl ? 'has-image' : ''}`}>
              <div
                className="line-template-card-content"
                onClick={(e) => {
                  if (isDashboard && (!editIndex || editIndex !== card.id)) {
                    e.stopPropagation();
                    handleEditClick(e);
                  }
                }}
              >
                {isDashboard && (
                  <div className="line-template-actions">
                    {isEditing ? (
                      <>
                        <button
                          className="line-template-save-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSave(e);
                          }}
                        >
                          <FiCheck size={20} />
                        </button>
                        <button
                          className="line-template-save-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCancelSave();
                          }}
                        >
                          <FiX size={20} />
                        </button>
                      </>
                    ) : (
                      <button
                        className="line-template-edit-button"
                        onClick={(e) => {
                          if (isDashboard) {
                            e.stopPropagation();
                            handleEditClick(e);
                          }
                        }}
                      >
                        <FiEdit2 size={20} />
                      </button>
                    )}
                    {isEditing && (
                      <button
                        className="line-template-delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteConfirm(true);
                        }}
                      >
                        <FiTrash2 size={20} />
                      </button>
                    )}
                  </div>
                )}

                {showDeleteConfirm && (
                  <DeleteConfirmation
                    setShowDeleteConfirm={setShowDeleteConfirm}
                    setEditIndex={setEditIndex}
                    handleDelete={handleDelete}
                  />
                )}

                <div className="line-image-wrapper">
                  {imageUrl ? (
                    <div
                      className="line-template-logo-placeholder"
                    // style={{ border: "1px solid black" }}
                    >
                      {imageUrl ? (
                        <div className="line-template-logo-container">
                          <>
                            <img
                              src={imageUrl}
                              alt="logo"
                              className="line-template-logo"
                            />
                            {isEditing && (
                              <div
                                className="line-template-logo-overlay"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="line-template-logo-overlay-actions">
                                  <FiTrash2
                                    size={20}
                                    className="line-template-overlay-icon remove"
                                    onClick={() => setImageUrl(null)}
                                  />
                                  <Upload
                                    {...uploadProps}
                                    multiple={false}
                                    maxCount={1}
                                    showUploadList={false}
                                  >
                                    <FiPlus
                                      size={20}
                                      className="line-template-overlay-icon add"
                                    />
                                  </Upload>
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      ) : (
                        <div
                          className="line-template-logo-overlay"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Upload
                            {...uploadProps}
                            multiple={false}
                            maxCount={1}
                            showUploadList={false}
                          >
                            <div className="upload-area">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                className="line-template-icon"
                              >
                                <path
                                  d="M12 4v16m-8-8h16"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </div>
                          </Upload>
                        </div>
                      )}
                    </div>
                  ) : !isEditing ? (
                    title && link ? (
                      <></>
                    ) : (
                      <div
                        className="line-template-logo-placeholder"
                        onClick={(e) => e.stopPropagation()}
                      // style={{ border: "1px solid black" }}
                      >
                        <Upload
                          {...uploadProps}
                          multiple={false}
                          maxCount={1}
                          showUploadList={false}
                        >
                          <div className="upload-area">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              className="line-template-icon"
                            >
                              <path
                                d="M12 4v16m-8-8h16"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </Upload>
                      </div>
                    )
                  ) : (
                    <div
                      className="line-template-logo-placeholder"
                      onClick={(e) => e.stopPropagation()}
                    // style={{ border: "1px solid black" }}
                    >
                      <Upload
                        {...uploadProps}
                        multiple={false}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <div className="upload-area">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            className="line-template-icon"
                          >
                            <path
                              d="M12 4v16m-8-8h16"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </Upload>
                    </div>
                  )}
                </div>

                <div className={(isEditing && !imageUrl) ? "line-template-left-section has-image" : "line-template-left-section"}>
                  <div
                    className={
                      isEditing
                        ? `line-template-text-section w-100 line-title-center ${(isEditing && !imageUrl) && "input-section"}`
                        : "line-template-text-section w-100"
                    }
                  >
                    {!!validationError && (
                      <Popover
                        content={
                          <span className="errorMsg">{validationError}</span>
                        }
                        open={!!validationError}
                        placement="top"
                      ></Popover>
                    )}
                    <div className="line-template-title-section">
                      {isEditing ? (
                        <Input
                          type="text"
                          value={title}
                          placeholder="Enter title"
                          onChange={(e) => {
                            setTitle(e.target.value);
                            if (validationError) setValidationError(""); // Clear error when typing
                          }}
                          className="line-template-title-input"
                          autoFocus
                        />
                      ) : (
                        <>
                          {title ? (
                            <div
                              className={
                                imageUrl
                                  ? "line-template-text-center"
                                  : "line-template-text-center"
                              }
                            >
                              <p className={`line-template-title ${!imageUrl ? 'no-image' : ''}`}>
                                {title}
                              </p>
                            </div>
                          ) : (
                            <div className="line-template-text-center">
                              <p className={`line-template-title title-placeholder ${!imageUrl ? 'no-image' : ''}`}>
                                Title goes here
                              </p>
                            </div>
                          )}
                        </>
                        // <h1 className="line-template-title">{title}</h1>
                      )}
                    </div>
                    {validationErrorLink && (
                      <Popover
                        content={
                          <span className="errorMsg">
                            {validationErrorLink}
                          </span>
                        }
                        open={!!validationErrorLink}
                        placement="top"
                      ></Popover>
                    )}
                    {
                      isEditing ? (
                        <div className="line-template-link-section">
                          <Input
                            type="text"
                            value={link}
                            placeholder="Enter Link"
                            onChange={(e) => {
                              setLink(e.target.value);
                              if (validationErrorLink)
                                setValidationErrorLink(""); // Clear error when typing
                            }}
                            // onChange={(e) => setLink(e.target.value)}
                            className="line-template-link-input"
                          />
                        </div>
                      ) : null
                      // <p className="line-template-link">{link}</p>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default LineTemplate;
