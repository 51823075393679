import { Tooltip } from "antd";
import { useState } from "react";
import { BsFilesAlt, BsFillCheckCircleFill, BsLink45Deg } from "react-icons/bs";
import "./CopyToClipboard.css";
import { useLocation } from "react-router-dom";
import { MdIosShare } from "react-icons/md";
export default function CopyToClipboard({
	textToCopy,
	buttonText = "Live Page",
}: {
	textToCopy: string;
	buttonText?: string;
}) {
	const location = useLocation()
	const [copied, setCopied] = useState(false);
	const isFollowerView = location.pathname.includes('follower');
	const copyToClipboard = () => {
		navigator.clipboard.writeText(textToCopy).then(() => {
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		});
	};

	return (
		<Tooltip
			placement='bottom'
			open={copied}
			title={
				<div
					className="tooltip-style"
				>
					<BsFillCheckCircleFill />
					<p className="m-0">Copied!</p>
				</div>
			}
		>
			<div className={isFollowerView ? 'follower-share-icon copy-clipboard-btn-wrapper' : 'copy-clipboard-btn-wrapper'} onClick={copyToClipboard}>
				{!isFollowerView && <p className="m-0">{buttonText}</p>}
				{isFollowerView ? <MdIosShare /> : <BsLink45Deg />}
			</div>
		</Tooltip>
	);
}
