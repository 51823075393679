import React, { useEffect, useRef, useState } from "react";
import { Carousel, Button } from "antd";
import {
  BsFillPlayFill,
  BsHourglassSplit,
  BsInstagram,
  BsLink45Deg,
  BsTwitter,
} from "react-icons/bs";
import track, { handleTagged, TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../Utils/SupabaseServices/ContentDatabase";
import HoverVideoPlayer from "react-hover-video-player";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import "../../CreatorFeed/CreatorFeed.css";
import { RiShoppingBagLine } from "react-icons/ri";
import { isMobile } from "react-device-detect";
import PostInfo from "../PostInfo/PostInfo";

const PostCarousel = (props: any) => {
  const location = useLocation();

  const {
    post,
    username,
    user_id,
    isGlobalView,
    showAsAd,
    shop_link,
    shoppableContent,
    platform,
  } = props;
  const mediaUrls = post.media_url
    .split(",")
    .filter((url: any) => url.trim() !== "");
  const onChange = (currentSlide: number) => {};
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);
  function handleRedirect(postId: string, media_url: string, platform: any) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url, platform: platform },
      user_id: user_id,
    });
    updateRefClick(postId, user_id);
  }
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
  }
  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>{!hover && <BsFillPlayFill className="creator-feed-card-play-btn" />}</>
    );
  };

  const VideoTag = (props: any) => {
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const {
      media_url,

      thumbnail,
    } = props;
    const { showAsAd, post_date, permalink, post_id, username } = post;
    return (
      <>
        {!Boolean(media_url) ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <HoverVideoPlayer
              videoRef={videoRef}
              videoSrc={media_url}
              className="creator-feed-card-video hover-play"
              muted
              preload={"metadata" || "auto"}
              contextMenu="return false;"
              hoverOverlay={<div className="video-hover"></div>}
              pausedOverlay={
                <div className="pause-overlay">
                  <img
                    src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                    alt=""
                    className="creator-feed-card-video"
                    loading="eager"
                  />

                  <OverlayStatus
                    hover={hover}
                    showClickablePlayBtn={showClickablePlayBtn}
                  />
                </div>
              }
              loadingOverlay={
                <img
                  src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                  alt=""
                  className="creator-feed-card-video"
                  loading="eager"
                />
              }
              unloadVideoOnPaused
              onHoverStart={() => {
                setHover(true);
              }}
              onHoverEnd={() => {
                setHover(false);
                setCustomPause(false);
              }}
              disableDefaultEventHandling={customPause}
            />
          </div>
        )}
      </>
    );
  };

  const ImagePostTag = React.memo((props: any) => {
    const { media_url } = props;
    const { showAsAd, post_date, permalink, post_id, username } = post;

    return (
      <>
        {media_url === "" ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              style={{ fontSize: "1.6rem", marginBottom: "15px" }}
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <Card.Img
              variant="top"
              src={media_url}
              className="creator-feed-card-video-carousel"
              loading="eager"
              style={{
                backgroundColor: "#f1f1f1",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const ImagePostTagCarousel = React.memo((props: any) => {
    const { media_url, isVideo } = props;
    const { showAsAd, post_date, permalink, post_id, username } = post;

    return (
      <>
        {media_url === "" ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              style={{ fontSize: "1.6rem", marginBottom: "15px" }}
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <Card.Img
              variant="top"
              src={media_url}
              className="creator-feed-card-video-carousel-image"
              loading="eager"
              style={{
                backgroundColor: "#f1f1f1",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const RefTag = ({
    media_url,
    showAsAd,
    post_date,
    permalink,
    post_id,
    username,
    shop_link,
    tagged_link,
    shoppableContent,
    external_link,
  }: any) => {
    return (
      // <div className="creator-feed-content-info" style={{ marginTop: "3.3%" }}>
      //   {showAsAd !== true ? (
      //     <>
      //       <p className="creator-feed-content-date">
      //         {new Intl.DateTimeFormat("en-US", {
      //           year: "numeric",
      //           day: "numeric",
      //           month: "long",
      //         }).format(new Date(post_date))}
      //       </p>
      //       <div className="creator-feed-post-links">
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <BsLink45Deg
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {shop_link ? (
      //           <a
      //             href={shop_link}
      //             target={isMobile ? "_self" : "_blank"}
      //             rel="noreferrer"
      //           >
      //             {shoppableContent === "icon" ? (
      //               <RiShoppingBagLine
      //                 style={{
      //                   marginRight: "3px",
      //                   cursor: "pointer",
      //                   transition: "color 0.3s ease-in-out",
      //                   color: "#000",
      //                 }}
      //                 onMouseOver={(e: any) => {
      //                   e.target.style.color = "#888";
      //                 }}
      //                 onMouseLeave={(e: any) => {
      //                   e.target.style.color = "#000";
      //                 }}
      //                 onClick={() => {
      //                   handleShop(post_id, media_url);
      //                 }}
      //               />
      //             ) : shoppableContent === "text" ? (
      //               <Button
      //                 style={{
      //                   fontSize: "0.6rem",
      //                   backgroundColor: "#000",
      //                   marginRight: "3px",
      //                   cursor: "pointer",
      //                   transition: "color 0.3s ease-in-out",
      //                   color: "#fff",
      //                 }}
      //                 onMouseOver={(e: any) => {
      //                   e.target.style.color = "#fff";
      //                 }}
      //                 onMouseLeave={(e: any) => {
      //                   e.target.style.color = "#fff";
      //                 }}
      //                 onClick={() => {
      //                   handleShop(post_id, media_url);
      //                 }}
      //               >
      //                 Shop
      //               </Button>
      //             ) : (
      //               ""
      //             )}
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {platform === "twitter" ? (

      //           <img
      //           src="/assets/icons8-twitterx-30.png"
      //           className="social-img"
      //           onMouseEnter={(e: any) => {
      //             e.target.style.filter = "brightness(0) saturate(100%) invert(50%)";
      //           }}
      //           onMouseLeave={(e: any) => {
      //             e.target.style.filter = "brightness(0) saturate(100%) invert(0%)";
      //           }}
      //           style={{ marginLeft: "6px" }}
      //           onClick={() => handleRedirect(post_id, media_url)}
      //         />

      //         ) : (
      //           // <BsTwitter style={{ marginLeft: "6px" }} />
      //           <BsInstagram style={{ marginLeft: "6px" }} />
      //         )}

      //         {isGlobalView && username && (
      //           <p className="creator-feed-username">@{username}</p>
      //         )}
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       <p className="creator-feed-content-date">Sponsored</p>
      //       <div className="creator-feed-post-links">
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <BsLink45Deg
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {shop_link ? (
      //           <a href={shop_link} target="_blank" rel="noreferrer">
      //             {/* <FaShoppingBag
      //               onClick={() => handleShop(post_id, media_url)}
      //             /> */}
      //             {shoppableContent === "icon" ? (
      //               <RiShoppingBagLine
      //                 style={{
      //                   marginRight: "3px",
      //                   cursor: "pointer",
      //                   transition: "color 0.3s ease-in-out",
      //                   color: "#000",
      //                 }}
      //                 onMouseOver={(e: any) => {
      //                   e.target.style.color = "#888";
      //                 }}
      //                 onMouseLeave={(e: any) => {
      //                   e.target.style.color = "#000";
      //                 }}
      //                 onClick={() => {
      //                   handleShop(post_id, media_url);
      //                 }}
      //               />
      //             ) : shoppableContent === "text" ? (
      //               <Button
      //                 style={{
      //                   fontSize: "0.6rem",
      //                   backgroundColor: "#000",
      //                   marginRight: "3px",
      //                   cursor: "pointer",
      //                   transition: "color 0.3s ease-in-out",
      //                   color: "#fff",
      //                 }}
      //                 onMouseOver={(e: any) => {
      //                   e.target.style.color = "#fff";
      //                 }}
      //                 onMouseLeave={(e: any) => {
      //                   e.target.style.color = "#fff";
      //                 }}
      //                 onClick={() => {
      //                   handleShop(post_id, media_url);
      //                 }}
      //               >
      //                 Shop
      //               </Button>
      //             ) : (
      //               ""
      //             )}
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {platform === "twitter" ? (
      //            <img
      //            src="/assets/icons8-twitterx-30.png"
      //            className="social-img"
      //            onMouseEnter={(e: any) => {
      //              e.target.style.filter = "brightness(0) saturate(100%) invert(50%)";
      //            }}
      //            onMouseLeave={(e: any) => {
      //              e.target.style.filter = "brightness(0) saturate(100%) invert(0%)";
      //            }}
      //            style={{ marginLeft: "6px" }}
      //            onClick={() => handleRedirect(post_id, media_url)}
      //          />
      //         ) : (
      //           // <BsTwitter style={{ marginLeft: "6px" }} />
      //           <BsInstagram style={{ marginLeft: "6px" }} />
      //         )}

      //         {isGlobalView && username && (
      //           <p className="creator-feed-username">@{username}</p>
      //         )}
      //       </div>
      //     </>
      //   )}
      // </div>
      <PostInfo
        username={username}
        user_id={user_id}
        showAsAd={showAsAd}
        post_date={post_date}
        permalink={permalink}
        shop_link={shop_link}
        external_link={external_link}
        post_id={post_id}
        handleRedirect={handleRedirect}
        handleShop={handleShop}
        handleTagged={handleTagged}
        platform={platform}
        media_url={media_url}
        shoppableContent={shoppableContent}
        tagged_link={tagged_link}
      />
    );
  };
  return (
    <>
      {mediaUrls.some((media_url: string) => media_url.includes("video")) ? (
        <Carousel afterChange={onChange} dotPosition="bottom">
          {mediaUrls.map((media_url: string, index: number) => (
            <div key={index}>
              {media_url.includes("video") ? (
                <VideoTag
                  media_url={media_url}
                  thumbnail={
                    post.thumbnail &&
                    post.thumbnail.split(",").length &&
                    post.thumbnail.split(",")[index]
                      ? post.thumbnail.split(",")[index]
                      : "/assets/dummy_video.png"
                  }
                />
              ) : (
                <ImagePostTag media_url={media_url} isVideo={true} />
              )}
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel afterChange={onChange} dotPosition="bottom">
          {mediaUrls.map((media_url: any, index: any) => (
            <div key={index}>
              <ImagePostTagCarousel media_url={media_url} />
            </div>
          ))}
        </Carousel>
      )}

      <div>
        <RefTag
          showAsAd={showAsAd}
          post_date={post.post_date}
          permalink={post.permalink}
          post_id={post.post_id}
          username={username}
          shop_link={post.shop_link}
          tagged_link={post.tagged_link}
          shoppableContent={shoppableContent}
          external_link={post.external_link}
        />
      </div>
    </>
  );
};

export default PostCarousel;
