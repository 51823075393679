import { Button, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import MembersList from './MembersList'
import validator from "validator";
import { inviteTeamMember } from '../../../../Utils/SupabaseServices/UserDatabase';
import { useLoadUser } from '../../../../Utils/customHooks/useLoadUser';
import { useParams } from 'react-router-dom';
import { supabase } from '../../../../config/supabaseClient';

function AddMember() {
    // const { username } = useParams();
    const username = 'testAgain'
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    const {
        isLoading: userLoading,
        data: userData,
        refetch,
    }: any = useLoadUser(username);

    // useEffect(() => {
    //     const fetchCreators = async () => {
    //         const { data: creators, error: fetchError } = await supabase
    //             .rpc('find_team_member_by_token', { search_token: "e894c7d5-cdb7-46e6-ba14-39a7e39374c6" });
    //     }
    //     fetchCreators();
    // }, [userData])
    const handleAddTeam = async () => {
        if (!validator.isEmail(email)) {
            setError("Invalid Email");
            return;
        }
        if (email === userData?.email) {
            setError("You cannot invite yourself");
            return;
        }
        try {
            const result = await inviteTeamMember(userData!.user_id, email, "member");
            if (result.error) {
                setError(result?.message || "Failed to send invitation");
            } else {
                setEmail("");
                setError("");
                setIsAddMemberModalOpen(false);
                Modal.success({
                    content: 'Invitation sent successfully!',
                });
            }
        } catch (err: any) {
            setError(err.message || "Failed to send invitation");
        }
    };
    return (
        <div>
            <Button className="accountButton"
                onClick={() => setIsAddMemberModalOpen(true)}
            >
                <MdAdd />
                Add Members
            </Button>
            <MembersList />

            <Modal
                title="Add Team Member"
                open={isAddMemberModalOpen}
                onCancel={() => {
                    setIsAddMemberModalOpen(false);
                    setError("");
                    setEmail("");
                }}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setIsAddMemberModalOpen(false);
                        setError("");
                        setEmail("");
                    }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleAddTeam}>
                        Send Invitation
                    </Button>
                ]}
            >
                <div className="modal-content">
                    <Input
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => {
                            setError("");
                            setEmail(e.target.value);
                        }}
                        status={error ? "error" : ""}
                    />
                    {error && <div className="error-message">{error}</div>}
                </div>
            </Modal>
        </div>
    )
}

export default AddMember