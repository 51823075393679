import React, { useEffect, useState } from "react";
import { updatePhoneNo } from "../../../../Utils/SupabaseServices/UserDatabase";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { supabase } from "../../../../config/supabaseClient";
import { Button, Modal, Radio, RadioChangeEvent } from "antd";

const Phone = (props: any) => {
  const { user_id, username, userData, setIsPhone } = props;

  const [phone, setPhone] = useState(userData?.phoneNo || "");
  const [originalPhone, setOriginalPhone] = useState(userData?.phoneNo || "");
  const [editPhone, setEditPhone] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const [phoneCode, setPhoneCode] = useState<any>(userData.phone_code || "");
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(phone); // Store the original phone number

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalError, setModalError] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const [isPhoneVerify, setIsPhoneVerify] = useState<any>();
  const [isTabsLocked, setIsTabsLocked] = useState<any>();

  const [value, setValue] = useState(1);
  const [initialValue, setInitialValue] = useState(1); // Add this line to track initial value

  useEffect(() => {
    const handleStorageChange = () => {
      const data = localStorage.getItem("phone_verify");
      if (data === "false") {
        setIsTabsLocked(true);
      } else {
        setIsTabsLocked(false);
      }
     
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial setup (run once)
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window]);
  // useEffect(()=>{
  //   if(phoneNumber.startsWith("91")){
  //     setValue(91)
  //     setInitialValue(91) // Set initial value here
  //   }else{
  //     setValue(1)
  //     setInitialValue(1) // Set initial value here
  //   }
  // },[phoneNumber])
  const onChange = (e: RadioChangeEvent) => {
    setPhoneCode(e.target.value);
    setValue(e.target.value);
  };
  const [otp, setOTP] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  function handleUpdateValue(e: any, option: any) {
    if (option === "phone") {
      setPhone(e.target.value);
    }
  }
  const doneEdit = async (option: any) => {
    if (option === "phone") {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      
      if (phone && !phoneRegex.test(phone)) {
        setPhoneError("Please enter a valid phone number");
        return;
      }
      
      await updatePhoneNo(user_id, username, phone);
      setEditPhone(false);
      setIsPhone(true);
    }
  };
  async function sendPhoneNumberOTP() {
    setOTP("");
    setModalError("");
    setIsModalOpen(true);
    setModalContent(true);
    setModalTitle("Verify Phone Number");
    let phNumber = `${value}${phone}`;
    const { data, error } = await supabase.auth.signInWithOtp({
      phone: phNumber,
    });
    if (error !== null) {
      setModalError("Error in sending OTP. Try again after 60 seconds");
    }
  }

  async function verifyPhoneNumber() {
    let phNumber = `${value}${phone}`;

    await supabase.auth
      .verifyOtp({
        phone: phNumber,
        token: otp,
        type: "sms",
      })
      .then((res: any) => {
        if (res.error === null) {
          setIsModalOpen(false);
          setIsPhone(true);
          setIsPhoneVerify(true);
          localStorage.removeItem("phone_verify");
        } else {
          setModalError("Invalid OTP");
        }
      });
  }
  const cancelEdit = () => {
    setPhone(originalPhone);
    setEditPhone(false);
    
   setValue(phoneCode)
    // setValue(initialValue); // Reset the radio selection to initial value
  };
  return (
    <div className="account-tile">
      <div className="account-tile-header">
        <div className="account-tile-title">
          Phone <BsInfoCircle data-tooltip-id="phone" />
        </div>
      </div>
      <div className="account-tile-value">
        {!editPhone ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{userData?.phoneNo || "Add phone number"}</span>
            <Button
              className="accountButton"
              onClick={() => {
                setEditPhone(true);
                setOriginalPhone(phone);
              }}
              style={{
                marginLeft: '1rem',
                padding: '0.5rem 1rem'
              }}
            >
              Change
            </Button>
          </div>
        ) : (
          <div>
            <input
              type="tel"
              style={{ 
                width: '100%', 
                padding: '0.5rem', 
                borderRadius: '6px', 
                border: '1px solid #e5e7eb',
                marginBottom: '0.75rem'
              }}
              onChange={(e: any) => handleUpdateValue(e, "phone")}
              value={phone}
              placeholder="+1234567890"
            />
            <div className="account-button-group">
              <Button
                className="accountButton"
                onClick={() => {
                  doneEdit("phone");
                }}
              >
                Save
              </Button>
              <Button
                className="cancelButton"
                onClick={() => {
                  setEditPhone(false);
                  setPhone(originalPhone);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
      {phoneError && <p style={{ color: '#ef4444', fontSize: '0.875rem', marginTop: '0.5rem' }}>{phoneError}</p>}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
        footer={null}
      // footer={modalTitle==="Verify Phone Number"?null:ok}
      >
        {modalContent === true ? (
          <>
            <input
              type="text"
              className="otp-input"
              placeholder="Enter 6 digit OTP"
              onChange={(e: any) => setOTP(e.target.value)}
              value={otp}
            />
            <button className="modal-verify-btn" onClick={verifyPhoneNumber}>
              Verify
            </button>
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default Phone;
