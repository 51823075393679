import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import '../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css';
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { ModalBackButton } from "../../../../../../components/EditModalComponents.tsx/ModalBackButton";

const FeaturedContentComponent = (props: any) => {
  const { username, user_id, isFeaturedContentComponentOpen, from } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Featured",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "featured",
      "featured",
      "",
      "DESC",
      "rect",
      false,
      undefined,
      from
    );
    if (res === "true") {
      props.refetch();
      props.setIsContentModalOpen(false);
      props.setIsFeaturedContentComponentOpen(false);
      setConfirmLoading(false);

    }
  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
    props.setIsFeaturedContentComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsFeaturedContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button
        //       onClick={handlePrevious}
        //                     className="newbutton"
        //     >
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isFeaturedContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="add-featured"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton handlePrevious={handlePrevious} />
        <h5 className="title mb-20 nt-4">
          Your featured post will be shown.{" "}
        </h5>
      </Modal>
    </>
  );
};

export default FeaturedContentComponent;
