import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import StayUpToDateSkeleton from "../StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import { useLocation } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import { DragDropContext } from "react-beautiful-dnd";
import SubComponentsWrapper from "../LandingPageCarousels/SubComponentsWrapper/SubComponentsWrapper";
import { Modal, message, Switch, Button } from "antd";
import { deleteEmbedComponent, updateEmbedIndexValue } from "../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { BsSearch } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import CreatorLandingProfile from "../../../components/CreatorProfile/CreatorLandingProfile";
import { supabase } from "../../../config/supabaseClient";
import "../../../styles/skeleton.css"

function EmbedSearch(props: any) {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color,
    refatchStayUpToDate,
    showModal,
    refetchUser,
    isLoading,
    data,
    refetchUserDetail,
    isModalOpen,
    setIsModalOpen,
    from
  } = props;

  // Local state for components
  const [localData, setLocalData] = useState<any>();
  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [status, setStatus] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // Configuration states
  const [configModalVisible, setConfigModalVisible] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showNameDesc, setShowNameDesc] = useState(false);
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Preview states
  const [previewShowProfile, setPreviewShowProfile] = useState(false);
  const [previewShowNameDesc, setPreviewShowNameDesc] = useState(false);
  const [previewShowSocialIcons, setPreviewShowSocialIcons] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents]);

  useEffect(() => {
    refetchUserDetail();
    refetch();
    refetchUser();
  }, [status, StayUpToDateComponents]);

  // Load saved configuration on component mount
  useEffect(() => {
    loadSavedConfiguration();
  }, [user_id]);

  const loadSavedConfiguration = async () => {
    try {
      const { data: userData, error } = await supabase
        .from('user')
        .select('embed_configure')
        .eq('user_id', user_id)
        .single();

      if (error) throw error;

      if (userData?.embed_configure) {
        const config = userData.embed_configure;
        setShowProfile(config.showProfilePicture ?? false);
        setShowNameDesc(config.showNameAndDesc ?? false);
        setShowSocialIcons(config.showSocialLinks ?? false);
      }
    } catch (error) {
      console.error('Error loading configuration:', error);
    } finally {
      // Always set loading to false after data is loaded or error occurs
      setIsInitialLoading(false);
    }
  };

  const handleConfigureClick = () => {
    setPreviewShowProfile(showProfile);
    setPreviewShowNameDesc(showNameDesc);
    setPreviewShowSocialIcons(showSocialIcons);
    setConfigModalVisible(true);
  };

  const handleConfigModalCancel = () => {
    setConfigModalVisible(false);
  };

  const handleUpdateConfiguration = async () => {
    try {
      const { error } = await supabase
        .from('user')
        .update({
          embed_configure: {
            showProfilePicture: previewShowProfile,
            showNameAndDesc: previewShowNameDesc,
            showSocialLinks: previewShowSocialIcons
          }
        })
        .eq('user_id', user_id);

      if (error) throw error;

      // Update main view state
      setShowProfile(previewShowProfile);
      setShowNameDesc(previewShowNameDesc);
      setShowSocialIcons(previewShowSocialIcons);

      message.success('Configuration updated successfully');
      setConfigModalVisible(false);
    } catch (error) {
      console.error('Error updating configuration:', error);
      message.error('Failed to update configuration');
    }
  };

  // Render loading skeleton based on saved configuration
  const renderLoadingSkeleton = () => {
    return (
      <div className="frontend-skeleton">
        {showProfile && (
          <div className="profile-section">
            <div className="avatar-skeleton"></div>
          </div>
        )}

        {showNameDesc && (
          <>
            <div className="profile-section">
              <div className="text-skeleton name-text"></div>
            </div>
            <div className="text-center">
              <div className="text-skeleton bio-text"></div>
            </div>
          </>
        )}

        {showSocialIcons && (
          <div className="social-icons">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="icon-skeleton"></div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleDragUpdate = (update: any) => {
    if (!update.destination) return;

    const draggable = document.querySelector(".dragging");
    if (!draggable) return;

    const container = document.getElementById("landing-wrapper");
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const draggableRect = draggable.getBoundingClientRect();
    const viewportHeight = Math.min(containerRect.height, draggableRect.height); // Use 740px as max height

    // Calculate distances relative to viewport
    const distanceFromTop = draggableRect.top;
    const distanceFromBottom = viewportHeight - draggableRect.bottom;

    const scrollThreshold = containerRect.height * 0.3; // 30% of viewport height
    const baseScrollSpeed = 15;
    const maxScrollSpeed = 30;

    // Calculate scroll position and limits
    const currentScroll =
      window.pageYOffset || document.documentElement.scrollTop;
    const maxScroll = Math.max(
      0,
      document.documentElement.scrollHeight - viewportHeight
    );

    // Scroll up when near the top
    if (distanceFromTop < scrollThreshold) {
      // Only scroll up if we're not at the top
      if (currentScroll > 0) {
        const scrollSpeed = Math.min(
          maxScrollSpeed,
          baseScrollSpeed +
          (baseScrollSpeed * (scrollThreshold - distanceFromTop)) /
          scrollThreshold
        );
        window.scrollBy({
          top: -scrollSpeed,
          behavior: "auto",
        });
      }
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateEmbedIndexValue(user_id, newData);
  };
  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteEmbedComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch,
      username
    ).then(() => {
      setTimeout(() => {
        setLocalData(filteredData);
      }, 10);
      setTimeout(() => {
        setConfirmLoading(false);
        setOpenDeleteModal(false);
      }, 50);
    });
  };

  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }

  return (
    <div className="stay-up-to-date-wrapper landing-mt-53">
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}>
        <button
          onClick={() => copyEmbedCode(username)}
          style={{
            gap: "8px",
            padding: "8px 16px",
            backgroundColor: "transparent",
            color: "#333",
            border: "2px dashed #ccc",
            borderRadius: "5px",
            fontSize: "14px",
            fontWeight: "500",
            cursor: "pointer",
            marginBottom: "16px",
            zIndex: 10,
          }}
        >
          <FaLink style={{ marginRight: "5px" }} />
          Copy Embed Code
        </button>
        <button
          onClick={handleConfigureClick}
          style={{
            gap: "8px",
            padding: "8px 16px",
            backgroundColor: "transparent",
            color: "#333",
            border: "2px dashed #ccc",
            borderRadius: "5px",
            fontSize: "14px",
            fontWeight: "500",
            cursor: "pointer",
            marginBottom: "16px",
            zIndex: 10,
          }}
        >
          <FaLink style={{ marginRight: "5px" }} />
          Configure
        </button>
      </div>

      {/* Main Content */}
      {isInitialLoading ? (
        (showProfile || showNameDesc || showSocialIcons) && renderLoadingSkeleton()
      ) : (
        <CreatorLandingProfile
          userDetails={data?.combinedRes?.userData}
          showIcons={showSocialIcons && data?.combinedRes?.feedControls?.showIcons}
          liveView={false}
          username={username}
          showSocialLinks={showSocialIcons}
          socialLinks={showSocialIcons ? data?.combinedRes?.userData : null}
          description={showNameDesc ? data?.combinedRes?.description : ""}
          desiredOrder={showSocialIcons ? data?.combinedRes?.userData?.social_links_order?.split(",") : []}
          isUserDashboard={true}
          refetch={refetchUserDetail}
          refetchUser={refetchUser}
          showProfilePicture={showProfile}
          showNameAndDesc={showNameDesc}
          from="embed-search"
        />
      )}

      {StayUpToDateComponentsLoading ? (
        <div className="loader-container">
          <StayUpToDateSkeleton />
        </div>
      ) : (
        <>
          <div className={"search-container-div-landing search-container"}>
            <input
              type="text"
              id="searchInput"
              enterKeyHint="search"
              placeholder={`Search ${username} content`}
              className="search-wrapper-input"
              autoComplete="off"
            />
            <button className="search-wrapper-btn search-btn-width">
              <BsSearch />
            </button>
          </div>
          <div
            id="landing-wrapper"
            className="component-box mt-4 landing-wrapper"
          >
            <IoAddCircle className="button-icon my-2" onClick={showModal} />
            <DragDropContext
              onDragStart={(start) => {
                const draggedElement = document.querySelector(
                  `[data-rbd-draggable-id="${start.draggableId}"]`
                );
                draggedElement?.classList.add("dragging");
              }}
              onDragUpdate={handleDragUpdate}
              onDragEnd={(result) => {
                const draggedElement = document.querySelector(".dragging");
                draggedElement?.classList.remove("dragging");
                onDragEnd(result);
              }}
            >
              <Droppable
                droppableId="droppable"
                direction="vertical"
                type="droppable"
              >
                {(provided) => (
                  <div
                    id="landing-wrapper"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      touchAction: "none",
                    }}
                  >
                    {localData &&
                      localData.map((component: any, index: any) => {
                        return (
                          <Draggable
                            key={component.id?.toString()}
                            draggableId={component.id?.toString()}
                            index={index}
                            shouldRespectForcePress={true}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  userSelect: "none",
                                  WebkitUserSelect: "none",
                                  cursor: "pointer",
                                  zIndex: snapshot.isDragging ? 9999 : "auto",
                                }}
                                className={`component-box ${snapshot.isDragging ? "dragging" : ""
                                  }`}
                              >
                                <SubComponentsWrapper
                                  user_id={user_id}
                                  username={username}
                                  component={component}
                                  dragHandleProps={provided.dragHandleProps}
                                  refetch={refetch}
                                  brand_color={brand_color}
                                  handleDelete={handleDelete}
                                  refatchStayUpToDate={refatchStayUpToDate}
                                  setStatus={setStatus}
                                  selectedComponent={selectedComponent}
                                  setSelectedComponent={setSelectedComponent}
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  isEditModalOpen={isEditModalOpen}
                                  setIsEditModalOpen={setIsEditModalOpen}
                                  from={from}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            data-testid="delete-carousel"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
        </>
      )}

      {/* Configuration Modal */}
      <Modal
        title="Configure Embed View"
        open={configModalVisible}
        onCancel={handleConfigModalCancel}
        footer={[
          <Button key="cancel" onClick={handleConfigModalCancel}>
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            onClick={handleUpdateConfiguration}
          >
            Update Configuration
          </Button>
        ]}
        width={800}
      >
        <div className="config-container" style={{ padding: "20px 0" }}>
          <div className="config-options" style={{ marginBottom: "30px" }}>
            <div className="config-option" style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              padding: "15px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px"
            }}>
              <div>
                <h4 style={{ margin: "0 0 5px 0" }}>Show Profile Picture</h4>
                <p style={{ margin: 0, color: "#666", fontSize: "14px" }}>Display profile picture in the embed view</p>
              </div>
              <Switch checked={previewShowProfile} onChange={setPreviewShowProfile} />
            </div>
            
            <div className="config-option" style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              padding: "15px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px"
            }}>
              <div>
                <h4 style={{ margin: "0 0 5px 0" }}>Show Name and Description</h4>
                <p style={{ margin: 0, color: "#666", fontSize: "14px" }}>Display your name and description in the embed view</p>
              </div>
              <Switch checked={previewShowNameDesc} onChange={setPreviewShowNameDesc} />
            </div>

            <div className="config-option" style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px"
            }}>
              <div>
                <h4 style={{ margin: "0 0 5px 0" }}>Show Social Icons</h4>
                <p style={{ margin: 0, color: "#666", fontSize: "14px" }}>Display social media icons in the embed view</p>
              </div>
              <Switch checked={previewShowSocialIcons} onChange={setPreviewShowSocialIcons} />
            </div>
          </div>

          <div className="preview-container" style={{
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            padding: "20px",
            backgroundColor: "#fff"
          }}>
            <h3 style={{ marginBottom: "20px", color: "#333" }}>Preview</h3>
            {(previewShowProfile || previewShowNameDesc || previewShowSocialIcons) && (
              <CreatorLandingProfile
                userDetails={data?.combinedRes?.userData}
                showIcons={previewShowSocialIcons && data?.combinedRes?.feedControls?.showIcons}
                liveView={false}
                username={username}
                showSocialLinks={previewShowSocialIcons}
                socialLinks={previewShowSocialIcons ? data?.combinedRes?.userData : null}
                description={previewShowNameDesc ? data?.combinedRes?.description : ""}
                desiredOrder={previewShowSocialIcons ? data?.combinedRes?.userData?.social_links_order?.split(",") : []}
                isUserDashboard={true}
                refetch={refetchUserDetail}
                refetchUser={refetchUser}
                showProfilePicture={previewShowProfile}
                showNameAndDesc={previewShowNameDesc}
                from="embed-search"
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EmbedSearch;

export const copyEmbedCode = (username: any) => {
  // Create an iframe embed code for the user's page using the exact format specified
  const embedCode = `<iframe src="https://www.searchbubble.com/embed/${username}
" style="border:none;width:100%;height:100vh;margin-top:4%;" title="Iframe Example"></iframe>`;

  // Copy to clipboard
  navigator.clipboard
    .writeText(embedCode)
    .then(() => {
      message.success("Embed code copied to clipboard!");
    })
    .catch((err) => {
      message.error("Failed to copy embed code");
      console.error("Copy failed:", err);
    });
};
