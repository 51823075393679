import React from "react";
import "../UpToDateComponents/UpToDateLinks/UpToDateLinks.css";
import { StaticVariables } from "../../../Utils/StaticVariables";
import YesterdayLinksLoader from "../UpToDateComponents/YesterdayLinks/YesterdayLinksLoader";
import NewsletterData from "../UpToDateComponents/NewsletterData/NewsletterData";
import NewsletterPopupData from "../UpToDateComponents/NewsletterData/NewsletterPopupData";
import FormData from "../UpToDateComponents/FormData/FormData";
import ReelsData from "../UpToDateComponents/ReelsData/ReelsData";
import TiktokData from "../UpToDateComponents/TikTokData/TiktokData";
import InstagramStoryData from "../UpToDateComponents/InstagramStoryData/InstagramStoryData";
import PodcastData from "../UpToDateComponents/PodcastData/PodcastData";
import YoutubeData from "../UpToDateComponents/YoutubeData/YoutubeData";
import FeaturedData from "../UpToDateComponents/FeaturedData/FeaturedData";
import BannerData from "../UpToDateComponents/BannerData/BannerData";
import HighlightDataSection from "../UpToDateComponents/HighlightDataSection/HighlightDataSecton";
import EmbedFormComponentFrontend from "../../../Screens/DashboardView/StayUpToDate/LandingPageComponents/EmbedFormComponent/EmbedFormComponentFrontend";
import LinksComponentFrontend from "../../../Screens/DashboardView/StayUpToDate/LandingPageComponents/LinksComponent/LinksComponentFrontend";
import CustomContentDataLoader from "../UpToDateComponents/CustomContentData/CustomContentDataLoader";
import CustomLinkDataLoader from "../UpToDateComponents/CustomLinkData/CustomLinkDataLoader";
import QueryContentDataLoader from "../UpToDateComponents/QueryContentData/QueryContentDataLoader";
import QueryLinkDataLoader from "../UpToDateComponents/QueryLinkData/QueryLinkDataLoader";
import TrendingProductsDataLoader from "../UpToDateComponents/TrendingProductsData/TrendingProductsDataLoader";
import YesterdayContentLoader from "../UpToDateComponents/YesterdayContent/YesterdayContentLoader";

const dayMappings: { [key: string]: number[] } = StaticVariables().dayMappings;

function RenderComponentLoader(props: any) {
    const {
        componentId,
        user_id,
        username,
        brand_color,
        handleRedirect,
        isHandleNavigate,
        data,
        from
    } = props;

    const {
        display_name: displayName = "",
        name = "",
        type = "",
        post_ids: postIds = [],
    } = data || {};

    const numberOfDays =
        displayName === "Yesterday"
            ? [1]
            : displayName === "This Week" || displayName === "This Week Content"
                ? [7]
                : displayName === "This Month"
                    ? [30]
                    : dayMappings[displayName]
                        ? []
                        : null;

    const renderContent = () => {
        if (displayName === "This Week Content") {
            return (
                <YesterdayContentLoader
                    key={componentId}
                    user_id={user_id}
                    username={username}
                    numberOfDays={numberOfDays}
                    title={name}
                    isLinks={true}
                    handleRedirect={handleRedirect}
                    postIds={postIds}
                    brand_color={brand_color}
                    removedPostIds={data?.removed_postids}
                    isHandleNavigate={isHandleNavigate}
                    component={data}
                    type={data?.type}
                />
            );
        } else if (
            dayMappings[displayName] ||
            ["Yesterday", "This Week", "This Month"].includes(displayName)
        ) {
            return (
                <YesterdayLinksLoader
                    key={componentId}
                    user_id={user_id}
                    username={username}
                    numberOfDays={numberOfDays}
                    title={name}
                    isLinks={true}
                    handleRedirect={handleRedirect}
                    postIds={postIds}
                    brand_color={brand_color}
                    removedPostIds={data?.removed_postids}
                    isHandleNavigate={isHandleNavigate}
                    component={data}
                    type={data?.type}
                    componentType={dayMappings[displayName] ? "daywise" : ""}
                />
            );
        }
        switch (displayName) {
            case "Inline Newsletter":
                return (
                    <NewsletterData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        postIds={postIds}
                        isLinks={true}
                        component={data}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        flowdeskId={data.details.split(",")[0]}
                        isHandleNavigate={isHandleNavigate}
                    />
                );
            case "Popup Newsletter":
                return (
                    <NewsletterPopupData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        postIds={postIds}
                        isLinks={true}
                        component={data}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        flowdeskId={data.details.split(",")[0]}
                        isHandleNavigate={isHandleNavigate}
                    />
                );

            case "Form":
                return (
                    <FormData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        postIds={postIds}
                        isLinks={true}
                        component={data}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        filloutId={data.details}
                        isHandleNavigate={isHandleNavigate}
                    />
                );

            case "reels":
                return (
                    <ReelsData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                    />
                );
            case "tiktok":
                return (
                    <TiktokData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                    />
                );
            case "instagram story":
                return (
                    <InstagramStoryData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={15}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                    />
                );
            case "podcast":
                return (
                    <PodcastData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                    />
                );
            case "youtube":
                return (
                    <YoutubeData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                    />
                );
            case "featured":
                return (
                    <FeaturedData
                        key={data.id}
                        user_id={user_id}
                        username={username}
                        numberOfDays={7}
                        title={name}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        isHandleNavigate={isHandleNavigate}
                        component={data}
                        removedPostIds={data?.removed_postids}
                        // handleRedirect={handleRedirect}
                        isLinks={true}
                    />
                );
            case "videos":
            default:
                if (type === "content") {
                    return (
                        <CustomContentDataLoader
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            title={name}
                            postIds={postIds}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            isHandleNavigate={isHandleNavigate}
                            component={data}
                            type={data?.type}
                            isLinks={false}
                        />
                    );
                } else if (type === "links-section") {
                    return (
                        <LinksComponentFrontend
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            numberOfDays={7}
                            title={name}
                            component={data}
                            brand_color={brand_color}
                            // isLinks={true}
                            handleRedirect={handleRedirect}
                            from={from}
                        />
                    );
                } else if (type === "links") {
                    return (
                        <CustomLinkDataLoader
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            title={name}
                            postIds={postIds}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            isHandleNavigate={isHandleNavigate}
                            component={data}
                            type={data?.type}
                            isLinks={true}
                        />
                    );
                } else if (type === "query-content") {
                    return (
                        <QueryContentDataLoader
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            title={name}
                            postIds={postIds}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            query={data?.query}
                            removedPostIds={data?.removed_postids}
                            isHandleNavigate={isHandleNavigate}
                            component={data}
                            type={data?.type}
                            isLinks={false}
                        />
                    );
                } else if (type === "query-links") {
                    return (
                        <QueryLinkDataLoader
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            title={name}
                            postIds={postIds}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            query={data?.query}
                            removedPostIds={data?.removed_postids}
                            isHandleNavigate={isHandleNavigate}
                            component={data}
                            type={data?.type}
                            isLinks={true}
                        />
                    );
                } else if (type === "banner") {
                    return (
                        <BannerData
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            numberOfDays={7}
                            title={name}
                            postIds={postIds}
                            isLinks={true}
                            component={data}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            isHandleNavigate={isHandleNavigate}
                        />
                    );
                } else if (type === "highlight") {
                    return (
                        <HighlightDataSection
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            numberOfDays={7}
                            title={name}
                            postIds={postIds}
                            data={data}
                            handleRedirect={handleRedirect}
                            // handleShop={handleShop}
                            brand_color={brand_color}
                            component={data}
                            isHandleNavigate={isHandleNavigate}
                        />
                    );
                } else if (type === "trending products") {
                    return (
                        <TrendingProductsDataLoader
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            title={name}
                            postIds={postIds}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            details={data?.details}
                            removedPostIds={data?.removed_postids}
                            isHandleNavigate={isHandleNavigate}
                            component={data}
                            type={data?.type}
                            isLinks={true}
                        />
                    );
                } else if (type === "embed-form") {
                    return (
                        <EmbedFormComponentFrontend
                            key={data.id}
                            user_id={user_id}
                            username={username}
                            numberOfDays={7}
                            title={name}
                            postIds={postIds}
                            isLinks={true}
                            component={data}
                            handleRedirect={handleRedirect}
                            brand_color={brand_color}
                            flowdeskId={data.details.split(",")[0]}
                            isHandleNavigate={isHandleNavigate}
                        />
                    );
                }
                return null;
        }
    };

    return <>{renderContent()}</>;
}

export default RenderComponentLoader;
