import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Button } from "antd";

const Subscription = (props: any) => {
  const { userData } = props;
  
  return (
    <div className="account-tile subscription">
      <div className="account-tile-header">
        <div className="account-tile-title">
          Subscription <BsInfoCircle data-tooltip-id="subscription" />
        </div>
      </div>
      <div className="account-tile-value">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <span style={{ fontWeight: 600, color: '#111827' }}>{userData?.subscription || "Starter"}</span>
          <div style={{ 
            backgroundColor: 'rgba(36, 70, 240, 0.08)', 
            borderRadius: '6px', 
            padding: '0.75rem', 
            fontSize: '0.875rem' 
          }}>
            <p style={{ margin: 0, color: '#2446f0' }}>
              {userData?.subscription === "Premium" 
                ? "You're enjoying premium features!" 
                : "Upgrade to unlock more features."}
            </p>
          </div>
        </div>
      </div>
      <div className="account-tile-action" style={{ marginTop: '1rem' }}>
        <a
          href="https://payments.searchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            className="accountButton"
            id="manage-subscription"
            style={{ width: '100%' }}
          >
            Manage Subscription
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Subscription;
