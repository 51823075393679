import { Radio, Segmented, Typography } from 'antd';
import React, { useState } from 'react'
const { Title } = Typography;

const CarouselShapeControl = (props: any) => {
  const { shape, setShape } = props;
  const handleShapeChange = (value: any) => {
    setShape(value);
  };

  return (
    <div className='mb-3'>
      <Title level={4}>Content Shape</Title>
      {/* <Radio.Group onChange={handleShapeChange} value={shape}>
        <Radio value="rect">Rectangle</Radio>
        <Radio value="sq">Square</Radio>
      </Radio.Group> */}
      <Segmented
        options={[
          { value: "rect", label: "Rectangle" },
          { value: "sq", label: "Square" }
        ]}
        value={shape}
        onChange={handleShapeChange}
        className="custom-segmented-order"
      />
    </div>
  );
};
export default CarouselShapeControl