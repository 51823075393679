import React from "react";
import { isMobile } from "react-device-detect";
import { FiAtSign } from "react-icons/fi";

export const PostInfoTaggedLink = (props: any) => {
  const { tagged_link, post_id, media_url, handleTagged,platform,user_id } = props;

  return (
    <>
      {tagged_link ? (
        <a
          href={tagged_link}
          target={isMobile ? "_self" : "_blank"}
          rel="noreferrer"
        >
          <FiAtSign
            className="perma-links"
            onMouseEnter={(e: any) => {
              e.target.style.color = "#888";
            }}
            onMouseLeave={(e: any) => {
              e.target.style.color = "#000";
            }}
            onClick={() => handleTagged(post_id, media_url,platform,user_id)}
          />
        </a>
      ) : (
        ""
      )}
    </>
  );
};
