import { useEffect, useState } from "react";
import "./StayUpToDateComponentsWrapper.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Modal, Button } from "antd";
import {
  deleteComponent,
  updateIndexValue,
} from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import StayUpToDateSkeleton from "./StayUpToDateSkeleton";
import SubComponentsWrapper from "../../LandingPageCarousels/SubComponentsWrapper/SubComponentsWrapper";

const ITEMS_PER_PAGE = 5;

const ArchivedComponentsWrapper = (props: any) => {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color,
    refatchStayUpToDate,
  } = props;
  const [localData, setLocalData] = useState(StayUpToDateComponents);
  const [displayedItems, setDisplayedItems] = useState(ITEMS_PER_PAGE);

  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents, status]);

  useEffect(() => {
    refetch();
  }, [status, StayUpToDateComponents]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateIndexValue(user_id, newData);
  };

  const loadMore = () => {
    setDisplayedItems(prev => Math.min(prev + ITEMS_PER_PAGE, localData.length));
  };

  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }

  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch,
      username
    ).then(() => {
      setLocalData(filteredData);
      setConfirmLoading(false);
      setOpenDeleteModal(false);
      // Adjust displayed items if necessary after deletion
      if (displayedItems > filteredData.length) {
        setDisplayedItems(filteredData.length);
      }
    });
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  const paginatedData = localData?.slice(0, displayedItems);

  return (
    <div className="stay-up-to-date-wrapper">
      {StayUpToDateComponentsLoading ? (
        <div className="loader-container">
          <div className="component-box">
            <StayUpToDateSkeleton />
          </div>
        </div>
      ) : (
        <>
          {localData && localData.length > 0 ? (
            <>
              <DragDropContext
                onDragStart={() => {
                  document.documentElement.style.scrollBehavior = "unset";
                  document.body.style.scrollBehavior = "unset";
                }}
                onDragEnd={(result: any) => {
                  onDragEnd(result);
                }}
              >
                <Droppable droppableId="element">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {paginatedData &&
                        paginatedData.map((component: any, index: any) => (
                          <Draggable
                            key={component.index?.toString()}
                            draggableId={component.index?.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div key={component.id} className="component-box">
                                  <SubComponentsWrapper
                                    user_id={user_id}
                                    username={username}
                                    component={component}
                                    refetch={refetch}
                                    brand_color={brand_color}
                                    handleDelete={handleDelete}
                                    refatchStayUpToDate={refatchStayUpToDate}
                                    setStatus={setStatus}
                                    selectedComponent={selectedComponent}
                                    setSelectedComponent={setSelectedComponent}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    isEditModalOpen={isEditModalOpen}
                                    setIsEditModalOpen={setIsEditModalOpen}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {displayedItems < localData.length && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button
                    onClick={loadMore}
                    // type="primary"
                    className="join-button"
                    style={{ padding: "0.5rem 1.5rem" }}
                  >
                    <p className="join-button-text">See More</p>
                  </Button>
                </div>
              )}
            </>
          ) : null}
          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ArchivedComponentsWrapper;
