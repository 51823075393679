import {
  Alert,
  Button,
  Input,
  Modal,
  Spin,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { supabase } from "../../../config/supabaseClient";

const AmazonIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showAmazonModal, user_id, data, refetchData } = props;
  const [amazonLink, setAmazonLink] = useState(
    data?.amazon_link || ""
  );

  const [errorAmazon, setErrorAmazon] = useState("");
  const [loadingAmazonConnect, setLoadingAmazonConnect] = useState(false);
  const [showLinkInstructions, setShowLinkInstructions] = useState(false);

  const handleCancel = () => {
    props.onClose();
    setErrorAmazon("");
    setAmazonLink(data?.amazon_link || "");
  };

  async function connectAmazonAccount() {
    setLoadingAmazonConnect(true);

    if (amazonLink && amazonLink !== null && amazonLink !== "") {
      // Validate that the URL is from Amazon
      if (!amazonLink.toLowerCase().includes('amazon.com')) {
        setErrorAmazon("Please enter a valid Amazon URL");
        setLoadingAmazonConnect(false);
        return;
      }
      
      try {
        // Here you would typically call your backend service to store the Amazon link
        // For now, we'll simulate a successful API call
       const {data,error} = await supabase.from("user").update({
        social_amazon: amazonLink
       }).eq("user_id",user_id)
       props.onClose()
       refetchData()
      } catch (error) {
        console.error("Error connecting Amazon:", error);
        setErrorAmazon("Connection error. Please try again later.");
      } finally {
        setLoadingAmazonConnect(false);
      }
    } else {
      setErrorAmazon("Please enter your Amazon store link");
      setLoadingAmazonConnect(false);
    }
  }

  return (
    <>
      <Modal
        title="Connect to Amazon"
        open={showAmazonModal}
        data-testid="amazon-modal"
        onOk={async () => {
          await connectAmazonAccount();
        }}
        onCancel={handleCancel}
        okText="Connect"
        okButtonProps={{
          disabled: !amazonLink,
          loading: loadingAmazonConnect,
        }}
        cancelButtonProps={{ disabled: loadingAmazonConnect }}
      >
        <p>
          By connecting your Amazon store, your product links will be displayed
          in the feed of your main page. In the content tab you will be able to
          hide or delete the products you don't want to show.
        </p>
        {loadingAmazonConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorAmazon && (
              <Alert
                type="error"
                message={`Error: ${errorAmazon}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.amazon_link ? (
              <>
                <p>
                  <b>
                    Already connected Amazon link: {data?.amazon_link}
                  </b>
                </p>
                <p>New link will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your Amazon storefront URL:</p>
            <Input
              placeholder="Enter Amazon storefront link (e.g., https://www.amazon.com/shop/yourusername)"
              id="amazon-link"
              value={amazonLink}
              onChange={(e) => {
                const value = e.target.value;
                setAmazonLink(value);
                if (!value) {
                  setErrorAmazon("Please enter your Amazon storefront link");
                } else if (!value.toLowerCase().includes('amazon.com')) {
                  setErrorAmazon("Please enter a valid Amazon URL");
                } else {
                  setErrorAmazon("");
                }
              }}
            />

            
          </>
        )}
      </Modal>
    </>
  );
};

export default AmazonIntegrationModal;
