import React from 'react'
import { getPostTitle } from '../../Utils/HelperFunction/HelperFunction';

const ProductTitle = (props:any) => {
  const {post,type} = props;
  return (
    <p
    style={{
      textAlign: post?.shop_title !== "" ||
        post?.product_name !== "" ||
        post?.content_title !== "" ? "left" : "center",
    }}
    className='editable-text'
    
  >
    <span className='editable-text-span'>
    {getPostTitle(post, type)}
    </span>
  </p>
  )
}

export default ProductTitle