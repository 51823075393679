import React, { useEffect, useState } from "react";
import { useLoadPostIdsData } from "../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import { supabase } from "../../config/supabaseClient";
import { Button, Carousel, Input } from "antd";
import { HiCheck, HiPencil } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import VideoComponent from "../VideoComponent/VideoComponent";
import "./RemovedContent.css";
import { getPostTitle } from "../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../Utils/SupabaseServices/ContentDatabase";
import PostCardComponent from "./PostCardComponent";
import ProductTitle from "./ProductTitle";

const RemovedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    contentData,
    refetchLinks,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    isActionLoading,
    setIsActionLoading,
    order,
    handleFeed,
  } = props;

  const [localPosts, setLocalPosts] = useState<any[]>([]);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);

  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,
    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(username, user_id, deletePostIds?.split(","));
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  useEffect(() => {
    if (PostIdData) {
      const filteredPosts = PostIdData.filter(
        (element: any) => element !== undefined
      );

      if (order === "newest") {
        filteredPosts.sort(
          (a: any, b: any) =>
            new Date(b.post_date).getTime() - new Date(a.post_date).getTime()
        );
      } else if (order === "oldest") {
        filteredPosts.sort(
          (a: any, b: any) =>
            new Date(a.post_date).getTime() - new Date(b.post_date).getTime()
        );
      }

      setLocalPosts(filteredPosts);
    }
  }, [PostIdData, order]);

  const onAdd = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      // Update local state immediately
      setLocalPosts((prev) => prev.filter((post) => post.post_id !== post_id));

      // If the post was in removePostIds, remove it
      if (removePostIds?.includes(post_id)) {
        setDeletePostIds((prev: any) => {
          const idsArray = prev?.split(",").filter(Boolean);
          const filteredArray = idsArray?.filter(
            (elem: any) => elem !== post_id
          );
          return filteredArray.join(",");
        });
        await props.handleRemovedPostIds(post_id, "add");
      }
    } finally {
      setIsActionLoading(false);
    }
  };

  const [value, setValue] = useState<string>();



  const SkeletonStatBox = () => {
    return (
      <div className="skeleton-stat">
        <Skeleton className="skeleton-style" />
      </div>
    );
  };

  return (
    <>
      <div className="removed-content">
        <h5>Removed Content</h5>
        <div className="addedSvg">
          {localPosts.length > 0 ? (
            localPosts.map((post: any, index: any) => (
              <React.Fragment key={post.post_id}>
                <div className="feed-tab-post-container post-container">
                  {/* <div className="feed-tab-media-wrapper">
                    <Button
                      onClick={() => onAdd(post.post_id)}
                      className="carousel-content-add-btn"
                    >
                      Add
                    </Button>
                    {post?.platform == "newsletter" ||
                      (post?.platform == "linkedin" && !post?.media_url) ||
                      (post?.platform === "twitter" && !post?.media_url) ? (
                      <div
                        className="newsletter-media-carousel h-250"
                        style={
                          post?.platform === "newsletter" ||
                            post?.platform == "linkedin" ||
                            post?.platform == "twitter"
                            ? {
                              // backgroundColor: "#efeded",
                              textAlign: "center",
                            }
                            : {}
                        }
                      >
                        <h4 className="newsletter-label">
                          {" "}
                          {post?.platform === "newsletter"
                            ? "Newsletter"
                            : post?.platform === "linkedin"
                              ? "LinkedIn Post"
                              : "X Post"}
                        </h4>
                        <h4 className="mt-7 date">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(post?.post_date))}
                        </h4>
                      </div>
                    ) : (
                      <>
                        {post?.product_image ? (
                          <img
                            className="feed-tab-media-check mh-300"
                            src={post?.product_image}
                            alt="post"
                          />
                        ) : post?.media_url ? (
                          <>
                            {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {post?.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        <div className="h-255">
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              post.thumbnail &&
                                                post.thumbnail.split(",")
                                                  .length &&
                                                post.thumbnail.split(",")[index]
                                                ? post.thumbnail.split(",")[
                                                index
                                                ]
                                                : "/assets/dummy_video.png"
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          className="feed-tab-media-check mh-300 mb-2"
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {post.media_url.includes("video") ? (
                                  <div className="h-255">
                                    <VideoComponent
                                      media_url={post.media_url}
                                      thumbnail={
                                        post.thumbnail
                                          ? post.thumbnail
                                          : "/assets/dummy_video.png"
                                      }
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className="feed-tab-media-check"
                                    src={
                                      post.media_url
                                        ? post.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <img
                            className="feed-tab-media-check"
                            src={
                              post?.thumbnail
                                ? post?.thumbnail
                                : "/assets/dummy_video.png"
                            }
                            alt="post"
                          />
                        )}
                      </>
                    )}
                  </div> */}
                  <PostCardComponent
                    post={post}
                    // shape={shape}
                    // isSoundOn={isSoundOn}
                    // isShopLinks={isShopLinks}
                    onMinus={onAdd}
                    from="removed"
                  />
                 <ProductTitle post={post} type={type}/>
                </div>
              </React.Fragment>
            ))
          ) : (
            <div className="skeleton-stat-box">
              {[...Array(4)].map((_, index) => (
                <SkeletonStatBox key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RemovedContentTable;
