import { Button } from "antd";
import React from "react";
import { isMobile } from "react-device-detect";
import { RiShoppingBagLine } from "react-icons/ri";

const PostInfoShopLink = (props: any) => {
  const {
    shop_link,
 
    shoppableContent,
    post_id,
    media_url,
    handleShop,
    platform,
    user_id
  } = props;
  return (
    <>
      {shop_link ? (
        <a
          href={shop_link}
          target={isMobile ? "_self" : "_blank"}
          rel="noreferrer"
        >
          {shoppableContent === "icon" ? (
            <RiShoppingBagLine
              className="perma-links"
              onMouseEnter={(e: any) => {
                e.target.style.color = "#888";
              }}
              onMouseLeave={(e: any) => {
                e.target.style.color = "#000";
              }}
              onClick={() => {
                handleShop(post_id, media_url,platform,user_id);
              }}
            />
          ) : shoppableContent === "text" ? (
            <Button
              className="shoppable-btn"
              onMouseOver={(e: any) => {
                e.target.style.color = "#fff";
              }}
              onMouseLeave={(e: any) => {
                e.target.style.color = "#fff";
              }}
              onClick={() => {
                handleShop(post_id, media_url,platform,user_id);
              }}
            >
              Shop
            </Button>
          ) : (
            ""
          )}
        </a>
      ) : (
        ""
      )}
    </>
  );
};

export default PostInfoShopLink;
