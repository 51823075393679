import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit, BsVolumeMute, BsVolumeUp } from "react-icons/bs";
import { Card } from "react-bootstrap";
import "../StayUpToDateComponent/StayUpToDate.css";
import ProductName from "../ProductName/ProductName";
import ProductShopButton from "../ProductName/ProductShopButton";
import { redirectToUrl } from "../../Utils/StayUpToDate/RenderComponent";

const IndividualVideoPostTag = (props: any) => {
  const {
    post,
    title,
    handleRedirect,
    isLinks,
    brand_color,
    isYoutube,
    isHasMediaUrl,
    thumbnail,
    productName,
    type,
    contentShape,
    isSoundOn
  } = props;

  return (
    <VideoTag
      media_url={post?.media_url}
      post={post}
      thumbnail={thumbnail || "/assets/dummy_video.png"}
      brand_color={brand_color}
      isYoutube={isYoutube}
      handleRedirect={handleRedirect}
      title={title}
      isLinks={isLinks}
      isHasMediaUrl={isHasMediaUrl}
      productName={productName}
      type={type}
      contentShape={contentShape}
      isSoundOn={isSoundOn}
    />
  );
};

const VideoTag = (props: any) => {
  const {
    media_url,
    brand_color,
    post,
    isYoutube,
    thumbnail,
    title,
    isLinks,
    isHasMediaUrl,
    productName,
    type,
    contentShape,
    isSoundOn
  } = props;

  const videoRef: any = useRef();
  const [hover, setHover] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [customPause, setCustomPause] = useState(false);
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);

  useEffect(() => {
    videoRef.current.playsInline = true;
  }, []);

  const toggleMute = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from triggering parent events
    e.preventDefault();
    setIsMuted((prev) => !prev);
  };

  const getWrapperClassName = () => {
    const baseClass = "creator-feed-image-wrapper-links";
    const classes = [baseClass];

    if (contentShape) classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");
    else if (isHasMediaUrl) classes.push("media-feed-wrapper");
    return classes.join(" ");
  };

  const getVideoClassName = () => {
    const baseClass = "card-image-individual";
    const classes = [baseClass];

    if (contentShape) classes.push("square-video");
    else if (isYoutube) classes.push("youtube-feed-height");
    else if (isHasMediaUrl) classes.push("media-feed-height");

    return classes.join(" ");
  };

  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>
        {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
      </>
    );
  };

  // const redirectUrl = !isLinks
  //   ? post?.permalink || post?.shop_link || post?.table_url
  //   : post?.shop_link || post?.table_url || post?.permalink;
  const redirectUrl:any=redirectToUrl(post,isLinks)
  return (
    <Card className={getWrapperClassName()}>
      <a
        href={redirectUrl}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          props.handleRedirect(
            post?.post_id || post?.table_id,
            post?.media_url.includes("video")
              ? post?.product_image || post?.thumbnail
              : post?.product_image || post?.media_url,
            title,
            type === "content" || type === "query-content"
              ? post?.content_title || post?.product_name
              : post?.shop_title || post?.product_name || post?.table_name,
            post?.platform,
            isLinks
          );
          if (redirectUrl) {
            window.open(redirectUrl, "_blank");
          }
        }}
      >
        {!Boolean(media_url) ? (
          <div>
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few
              minutes.
            </p>
          </div>
        ) : (
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            muted={isSoundOn ? isMuted : true}
            className={`video-height-individual ${getVideoClassName()}`}
            style={{
              backgroundColor: brand_color,
              display: "block",
              marginBottom: "2%",
            }}
            videoClassName={getVideoClassName()}
            preload={"metapost"}
            contextMenu="return false;"
            hoverOverlay={
              <div className="video-hover">
                {isSoundOn && <div
                  className="sound-icon"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    zIndex: 10,
                  }}
                  onClick={toggleMute}
                >
                  {isMuted ? (
                    <BsVolumeMute size={24} color="white" />
                  ) : (
                    <BsVolumeUp size={24} color="white" />
                  )}
                </div>}
              </div>
            }
            pausedOverlay={
              <>
                <Card.Img
                  variant="top"
                  src={thumbnail || "/assets/dummy_video.png"}
                  className={getVideoClassName()}
                  loading="eager"
                  style={{
                    backgroundColor: brand_color,
                  }}
                />

                <OverlayStatus
                  hover={hover}
                  showClickablePlayBtn={showClickablePlayBtn}
                />
              </>

            }
            loadingOverlay={

              <Card.Img
                variant="top"
                src={thumbnail || "/assets/dummy_video.png"}
                className={getVideoClassName()}
                loading="eager"
                style={{
                  backgroundColor: brand_color,
                }}
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />
        )}
      </a>
      <ProductName type={type} post={post} />

      <ProductShopButton isLinks={isLinks} isIndividual={true} type={type} post={post} />
    </Card>
  );
};

export default IndividualVideoPostTag;
