import React, { useCallback, useEffect, useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./ReelsData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit, BsLink45Deg } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import CustomPagination from "../../CustomPagination";
import DataSkeleton from "../DataSkeleton";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import { isMobile } from "react-device-detect";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";

const ReelsData = (props: any) => {
  const { user_id, username, handleRedirect, brand_color, title, isHandleNavigate, component, removedPostIds } = props;
  const platform = "instagram";
  const type = "REELS";
  const itemsPerPage = 3;
  const { isLoading: ReelsDataLoading, data: ReelsData }: any =
    useLoadTypeContentData(username, user_id, platform, type, removedPostIds, component?.content_order);

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  const param = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const domLoaded = useDomLoaded();

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / ReelsData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [ReelsData]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / ReelsData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, ReelsData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [ReelsData, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, ReelsData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });

      track("Carousel Click", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,

          title: component.name,
          // name: name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
  };

  return (
    <>
      {ReelsDataLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          {ReelsData && ReelsData.length > 0 ? (
            <div className="reels-wrapper">
              <div
                className="mainDiv"
              >
                <p
                  className="component-name" style={param?.id ? { cursor: "auto" } : {}} onClick={(e) => handleNavigate(component)}
                >
                  Latest Reels

                </p>
                {!param?.id &&
                  <CustomPagination
                    totalItems={ReelsData?.length}
                    currentIndex={currentIndex}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                  />
                }
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                  className={!param?.id ? "links-grid-container-reels" : isMobile ? "item-grid creator-card-grid-wrp" : "item-grid"}
                >
                  {ReelsData &&
                    ReelsData.map((link: any, index: number) => (
                      <div
                        key={index}
                        className="link-url"
                      >
                        {link.media_url ? (
                          link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                            <>
                              <ImagePostCarouselTag
                                post={link}
                                handleRedirect={handleRedirect}
                                brand_color={brand_color}
                                isLinks={link?.shop_link ? true : false}
                                isDashboard={false}
                                contentShape={component?.content_shape == "sq" ? true : false}
                                contentSound={component?.content_sound}
                              />
                            </>
                          ) : (
                            <>
                              {link.product_image &&
                                link.product_image !== "" ? (
                                <>
                                  <ImagePostTag
                                    data={link}
                                    handleRedirect={handleRedirect}
                                    title={title}
                                    brand_color={brand_color}
                                    isLinks={link?.shop_link ? true : false}
                                    isDashboard={false}
                                    contentShape={component?.content_shape == "sq" ? true : false}
                                    contentSound={component?.content_sound}
                                  />
                                </>
                              ) : (
                                <>
                                  {link.media_url.includes("video") ? (
                                    <>
                                      <VideoPostTag
                                        post={link}
                                        handleRedirect={handleRedirect}
                                        title={title}
                                        brand_color={brand_color}
                                        thumbnail={link.thumbnail}
                                        isLinks={link?.shop_link ? true : false}
                                        isDashboard={false}
                                        type={type}
                                        contentShape={component?.content_shape == "sq" ? true : false}
                                        contentSound={component?.content_sound}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <ImagePostTag
                                        data={link}
                                        handleRedirect={handleRedirect}
                                        title={title}
                                        brand_color={brand_color}
                                        isLinks={link?.shop_link ? true : false}
                                        isDashboard={false}
                                        contentShape={component?.content_shape == "sq" ? true : false}
                                        contentSound={component?.content_sound}
                                      />
                                    </>
                                  )}
                                </>
                              )}

                            </>
                          )
                        ) : (
                          <>
                            <ImagePostTag
                              data={link}
                              handleRedirect={handleRedirect}
                              brand_color={brand_color}
                              isLinks={link?.shop_link ? true : false}
                              isDashboard={false}
                              contentShape={component?.content_shape == "sq" ? true : false}
                              contentSound={component?.content_sound}
                            />
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default ReelsData;
