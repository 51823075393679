import React from "react";
import { Button } from "antd";
import Card from "react-bootstrap/esm/Card";
import "../../../../../styles/StayUptoDateCSS/SubComponents.css";
import "../../../../../styles/StayUptoDateCSS/SubComponents.css";

import { useLocation, useParams } from "react-router-dom";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { useLoadCustomProduct } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
import ProductName from "../../../../../components/ProductName/ProductName";
import ProductShopButton from "../../../../../components/ProductName/ProductShopButton";
const ProductBankWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,

    postIds,
    brand_color,
    type,
  } = props;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const param = useParams();
  const { isLoading: CustomProductLoading, data: CustomProduct }: any =
    useLoadCustomProduct(user_id, username, title, false);

  function handleRedirect(
    postId: any,
    media_url: string,
    title: any,
    name: any,
    platform: string,
    // type:any,
    isLinks?: boolean
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        platform: platform,
        // type:type,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    if (isLinks === true) {
      track("Redirect to shop link", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    } else if (isLinks === false) {
      track("Redirect to original post", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
    updateRefClick(postId, user_id);
  }
  return (
    <>
      <div>
        {CustomProductLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomProduct && CustomProduct.length ? (
              <div className="links-grid-container-subcomponents">
                {CustomProduct &&
                  CustomProduct.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      <a
                        href={link?.table_url}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect(
                            link?.table_id,
                            link?.table_image,
                            title,
                            link?.shop_title || link?.table_name,
                            link?.platform,
                            isLinks
                          );
                          if (link?.table_url && link?.table_url !== "") {
                            window.open(link?.table_url, "_blank");
                          }
                        }}
                      >
                        <div className={"creator-feed-image-wrapper-links"}>
                          <Card.Img
                            variant="top"
                            src={link?.table_image}
                            className={"creator-feed-card-links-image"}
                            loading="eager"
                            style={{
                              backgroundColor: brand_color && brand_color,
                            }}
                          />
                        </div>
                        <ProductName type={type} post={link} title={title} />{" "}
                        <ProductShopButton
                          isLinks={isLinks}
                          isIndividual={false}
                          type={type}
                          post={link}
                        />
                      </a>
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductBankWrapper;
