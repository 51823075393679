import { Modal, Input, Form, Button } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUpdateSocialLink from "./useUpdateSocialLink";
import { useLoadSocialLinks } from "../../Utils/customHooks/useLoadSocialLinks";
import { StaticCompnents } from "../../Utils/StaticComponent";
import { testLink } from "../../Utils/StayUpToDate/RenderComponent";
import { FaDiscord } from "react-icons/fa";

export type EditType = "name" | "description";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: { name: string; description: string }) => void;
  user_id: string;
  integrationModal: string;
  showLinkIndex: string;
  setShowLinkIndex: Dispatch<SetStateAction<string>>;
}

const AvailableSocialLinksModal: React.FC<ModalProps> = ({
  visible,
  onClose,
  onSubmit,
  user_id,
  integrationModal,
  setShowLinkIndex,
  showLinkIndex,
}) => {
  const { isLoading, data, refetch } = useLoadSocialLinks(user_id);
  const {
    LTKIcon,
    ShopMyIcon,
    AmazonIcon,
    InstagramIcon,
    TiktokIcon,
    // TwitterIcon,
    NewsletterIcon,
    PinterestIcon,
    PodcastIcon,
    YoutubeIcon,
    FacebookIcon,
    SpotifyIcon,
    SnapchatIcon,
    ApplePodcastIcon,
    ShopIcon,
    LinkedinIcon,
    LinkIcon,
  } = StaticCompnents();
  const icons = [
    {
      key: "instagram",
      name: "Instagram",
      icon: <InstagramIcon />,
      link: data?.data?.social_instagram,
    },
    {
      key: "tiktok",
      name: "TikTok",
      icon: <TiktokIcon />,
      link: data?.data?.social_tiktok,
    },
    {
      key: "youtube",
      name: "YouTube",
      icon: <YoutubeIcon />,
      link: data?.data?.social_youtube,
    },
    {
      key: "podcast",
      name: "Podcast",
      icon: <PodcastIcon />,
      link: data?.data?.social_podcast,
    },
    {
      key: "pinterest",
      name: "Pinterest",
      icon: <PinterestIcon />,
      link: data?.data?.social_pinterest,
    },
    {
      key: "newsletter",
      name: "Newsletter",
      icon: <NewsletterIcon />,
      link: data?.data?.social_newsletter,
    },
    {
      key: "facebook",
      name: "Facebook",
      icon: <FacebookIcon />,
      link: data?.data?.social_facebook,
    },
    {
      key: "twitter",
      name: "X",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-twitter-x"
          viewBox="0 0 16 16"
        >
          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
        </svg>
      ),
      link: data?.data?.social_twitter,
    },
    {
      key: "amazon",
      name: "Amazon Storefront",
      icon: <AmazonIcon />,
      link: data?.data?.social_amazon,
    },
    {
      key: "spotify",
      name: "Spotify",
      icon: <SpotifyIcon />,
      link: data?.data?.social_spotify,
    },
    {
      key: "shop",
      name: "Shop",
      icon: <ShopIcon />,

      link: data?.data?.social_shop,
    },
    {
      key: "applepodcast",
      name: "ApplePodcast",
      icon: <ApplePodcastIcon />,
      link: data?.data?.social_applepodcast,
    },
    {
      key: "ltk",
      name: "LTK",
      icon: <LTKIcon />,

      link: data?.data?.social_ltk,
    },
    {
      key: "shopmy",
      name: "ShopMy",
      icon: <ShopMyIcon />,

      link: data?.data?.social_shopmy,
    },
    {
      key: "snapchat",
      name: "Snapchat",
      icon: <SnapchatIcon />,
      link: data?.data?.social_snapchat,
    },
    {
      key: "linkedin",
      name: "LinkedIn",
      icon: <LinkedinIcon />,
      link: data?.data?.social_linkedin,
    },
    {
      key: "website",
      name: "Website",
      icon: <LinkIcon />,
      link: data?.data?.social_website,
    },
    {
      key: "discord",
      name: "Discord",
      icon: <FaDiscord />,
      link: data?.data?.social_discord,
    }
  ];

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [link, setLink] = useState("");
  const [newLink, setNewLink] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: updateSocialLink } = useUpdateSocialLink();

  function handleIconClick(icon: any, index: any) {
    setShowConfirmation(true);
    setShowLinkIndex(icon);
  }
  const handleOk = async () => {
    try {
      // setLink()
      setIsEdit(false)
      // setNewLink("")
      onClose();
    } catch (error) {
      console.error("Validation Failed:", error);
    }
  };

  const handleCancel = () => {
    // form.resetFields();
    setNewLink("")
    setIsEdit(false);
    setShowConfirmation(false);
    setLink("");
    onClose();
  };

  function handleAddLink(icon: any) {
    if (link && link.trim() !== "") {
      const trimmedLink = link.trim();
      const formattedLink = trimmedLink && !trimmedLink.startsWith('http://') && !trimmedLink.startsWith('https://')
        ? `https://${trimmedLink}`
        : trimmedLink;
      
      updateSocialLink({
        link: formattedLink,
        platform: icon,
        pageIndex: 1,
        index: 1,
        user_id,
      });
    }
    setShowConfirmation(false);
    setLink("");
    onClose();
  }

  function handleEditLink(icon: any) {
    const trimmedLink = newLink.trim();
    const formattedLink = trimmedLink && !trimmedLink.startsWith('http://') && !trimmedLink.startsWith('https://')
      ? `https://${trimmedLink}`
      : trimmedLink;
    
    const foundIcon = icons.find((icon) => icon.key === showLinkIndex);
    if (foundIcon) {
      foundIcon.link = formattedLink;
    }
    
    updateSocialLink({
      link: formattedLink,
      platform: icon,
      pageIndex: 1,
      index: 1,
      user_id,
    });
  }

  const sortedIcons = icons
    .slice() // Make a copy to avoid mutating the original array
    .sort((a, b) => {
      const indexA = data?.data?.social_links_order.indexOf(a.key);
      const indexB = data?.data?.social_links_order.indexOf(b.key);
      return indexA - indexB;
    });

  return (
    <Modal
      title={""}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Ok"
      //   footer={null}
    >
      <div className="account-social-link-connect-row  available-profile-icon">
        {integrationModal === "add-icons" &&
          icons
            .filter((icon) => !icon.link)
            .map((icon, index) => (
              <div key={icon.key} className="mb-4">
                <Button
                  className="social-icon-btn search-button"
                  style={{ background: "#f1f1f1", padding: "4px" }}
                  onClick={() => handleIconClick(icon.key, index)}
                >
                  {icon.icon}
                </Button>
              </div>
            ))}

        {integrationModal === "add-icons" && showConfirmation && (
          <div
            className="confirmation-dialog text-center mt-4"
            style={{ width: "100%" }}
          >
            <h5 className="icon-key-h5">
              Link your {icons.find((icon) => icon.key === showLinkIndex)?.name}{" "}
              below
            </h5>
            <Input
              value={link}
              onChange={(e: any) => setLink(e.target.value)}
              className="link-input"
            />
            <div className="confirmation-btns mt-25px text-center">
              <button
                className="confirm-btn cancel social-cancel-btn"
                onClick={() => {
                  setLink("");
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </button>
              <Button
                className="social-save-btn"
                onClick={() => {
                  const foundIcon = icons.find(
                    (icon) => icon.key === showLinkIndex
                  );
                  if (foundIcon) {
                    handleAddLink(foundIcon.key);
                  }
                }}
                disabled={link.length === 0}
              >
                Add
              </Button>
            </div>
          </div>
        )}

        {integrationModal === "edit-icons" && (
          <div
            className="confirmation-dialog text-center mt-4"
            style={{ width: "100%" }}
          >
            <h5 className="icon-key-h5">
              {icons.find((icon) => icon.key === showLinkIndex)?.name} Link
              
            </h5>
            {icons.find((icon) => icon.key === showLinkIndex)?.link ? (
            <Button
              onClick={() =>
                testLink(icons.find((icon) => icon.key === showLinkIndex)?.link)
              }
            >
              {" "}
              {icons.find((icon) => icon.key === showLinkIndex)?.icon}Test Link
            </Button>
            ):(
              <></>
            )}
            <p className="icon-key-p flex justify-between items-center">
              {isEdit ? (
                <>
                  <Input
                    value={newLink}
                    onChange={(e: any) => setNewLink(e.target.value)}
                    className="w-80"
                  />
                  <div className="flex items-center space-x-2">
                    <button
                      className="confirm-btn cancel social-cancel-btn"
                      onClick={() => {
                        setIsEdit(false);
                        setShowConfirmation(false);
                      }}
                    >
                      Cancel
                    </button>
                    <Button
                      className="social-save-btn"
                      onClick={() => {
                        const foundIcon = icons.find(
                          (icon) => icon.key === showLinkIndex
                        );
                        if (foundIcon) {
                          setIsEdit(false);
                          handleEditLink(foundIcon.key);
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </>
              ) : (
                <>
                <span className="flex-1">
                  {icons.find((icon) => icon.key === showLinkIndex)?.link || ""}
                </span>
                <br />
                {icons.find((icon) => icon.key === showLinkIndex)?.link ? (
                  <Button
                    className="social-edit-btn ml-4"
                    onClick={() => {
                      const foundIcon = icons.find((icon) => icon.key === showLinkIndex);
                      if (foundIcon) {
                        setIsEdit(true);
                        setNewLink(foundIcon.link);
                      }
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="social-save-btn ml-4"
                    onClick={() => {
                      setIsEdit(true);
                      setNewLink("");
                    }}
                  >
                    Add
                  </Button>
                )}
              </>
              )}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AvailableSocialLinksModal;


