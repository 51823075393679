import React, { useState } from "react";
import { Modal, Tabs } from "antd";
import { FaLock } from "react-icons/fa";
import DateSelector from "../../../components/DateSelector/DateSelector";

const AnalyticsTabOptions = (props: any) => {
  const { navTabs, subscription_type, activeKey, handleDateChange } = props;
  const { TabPane } = Tabs;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    return (
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
    );
  };
  
  const onChangeTab = (key: string) => {
    if (key === "Trending" && subscription_type === "basic") {
      setIsModalOpen(true);
      props.setActiveKey(activeKey)
    } else {
      props.setActiveKey(key);
    }
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  // Find the active tab's content
  const activeTabContent = navTabs.find((tab: any) => tab.key === activeKey)?.children;

  return (
    <>
      <div className="analytics-tabs-container">
        <div className="tabs-nav-section">
          <Tabs 
            defaultActiveKey="Overview" 
            activeKey={activeKey} 
            type="card" 
            onChange={onChangeTab}
            className="analytics-tabs"
            centered={false}
            tabPosition="top"
            renderTabBar={(props, DefaultTabBar) => (
              <DefaultTabBar {...props} />
            )}
          >
            {navTabs.map((tab: any) => (
              <TabPane
                key={tab.key}
                tab={
                  <div className="modal-title" style={{ display: 'flex', alignItems: 'center' }}>
                    {tab.isLocked && (
                      <FaLock style={{ marginRight: "4px", color: "grey" }} />
                    )}
                    <span style={{ color: tab.isLocked ? "grey" : "" }}>
                      {tab.label}
                    </span>
                  </div>
                }
              />
            ))}
          </Tabs>
        </div>

        {handleDateChange && (
          <div className="tab-date-selector-wrapper">
            <DateSelector handleChange={handleDateChange} />
          </div>
        )}
        
        <div className="tabs-content-section">
          {activeTabContent}
        </div>
      </div>

      <Modal
        title="Upgrade to Unlock Trending"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p style={{ marginTop: "2%", fontSize: "1rem" }}>
          Unlocking the Trending feature will allow you see analytics of you page in more detail with the top 25 searches under each category made on your page.
        </p>
        <div style={{ textAlign: "right" }}>
          <a
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="upgrade-subscription">Upgrade</button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default AnalyticsTabOptions;
