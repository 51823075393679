import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Carousel, Input, Pagination, Space } from "antd";
import { useLoadSearchFeedCount } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadSearchFeed } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import "../../../../styles/StayUptoDateCSS/FeedCheckboxComponent.css";
import { useLoadPostIdsData } from "../../../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselSearchbar from "../../../../components/CarouselControls/CarouselSearchbar";
import Title from "antd/es/typography/Title";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import CarouselControlWrapper from "../../../../components/CarouselControls/CarouselControlWrapper";
import LoadingPost from "../../../../components/skeleton/Views/LoadingPost";
import { updateNameValues } from "../../../../Utils/StayUpToDate/RenderComponent";
import { debounce } from "lodash";
import PostCardComponent from "../../../../components/EditModalComponents.tsx/PostCardComponent";
import EditableText from "../../../../components/EditModalComponents.tsx/EditableText";
import ProductTitle from "../../../../components/EditModalComponents.tsx/ProductTitle";

const POSTS_PER_PAGE = 10;

const contentOrder = "Default";
const FeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    type,
    activeFilter,
    setActiveFilter,
    isSoundOn,
    order,
    setOrder,
    shape,
    setShape,
    setIsSoundOn,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;

  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : activeFilter === "both"
        ? StaticVariables().PLATFORMS
        : activeFilter === "content"
          ? StaticVariables().CONTENT_PLATFORMS
          : activeFilter === "products"
            ? StaticVariables().PRODUCT_PLATFORMS
            : StaticVariables().PLATFORMS;

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [postIds, setPostIds] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    setPostIds(post_ids);
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [post_ids, defaultQuery]);

  const { data: pages }: any = useLoadSearchFeedCount(
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    activeFilter
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    activeFilter
  );

  const debouncedQueryUpdate = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
      setQuery(value);
      setDefaultQuery(value);
      setCurrentPage(1);
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    debouncedQueryUpdate(searchTerm);
    return () => debouncedQueryUpdate.cancel();;
  }, [searchTerm]);

  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

    // Separate the filtering logic in useMemo
  const filteredContentData = useMemo(() => {
    return ((data?.pages) || [])?.filter((element: any) => element !== undefined)?.filter((item: any) => StaticVariables().CONTENT_PLATFORMS.includes(item.platform));

  }, [data,postIds]);

  const filteredProducttData = useMemo(() => {
    return (data?.pages || [])?.filter((element: any) => element !== undefined)?.filter((item: any) => StaticVariables().PRODUCT_PLATFORMS.includes(item.platform));

  }, [data,postIds]);

  return (
    <>
      <div className="margin-table">
      <label className="bold-label">Preview</label>

        <AddedContentTable
          post_ids={postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          componentName={componentName}
          handlePostIds={props.handlePostIds}
          handleFeed={handleFeed}
          type={type}
          shape={shape}
          isSoundOn={isSoundOn}
          isActionLoading={isActionLoading}
          setIsActionLoading={setIsActionLoading}
          name={name}
          setName={setName}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
        <div className="carousel-controls-wrapper">
        <div className="addedContent">
        <Title level={3}>Carosuel Control</Title>
        <CarouselControlWrapper
          showShape={true}
          showSound={true}
          showFilter={type === "links"}
          showOrder={false}
          shape={shape}
          setShape={setShape}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          type={type}
          setCurrentPage={setCurrentPage}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          order={order}
          setOrder={setOrder}

          username={username}
          user_id={user_id}
          POSTS_PER_PAGE={POSTS_PER_PAGE}
          showSocials={showSocials}
          isShopLinks={isShopLinks}

          filteredContentData={filteredContentData}
          filteredProducttData={filteredProducttData}
        />
        </div>
        </div>
        <CarouselSearchbar
          isShopLinks={isShopLinks}
          query={query}
          setQuery={setQuery}
          setDefaultQuery={setDefaultQuery}
          setCurrentPage={setCurrentPage}
          setActiveFilter={setActiveFilter}
          refetch={refetch}
          postIds={postIds}
          setPostIds={setPostIds}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedQuery={setDebouncedQuery}
        />
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={postIds}
        query={query}
        isLoading={isLoading}
        isShopLinks={isShopLinks}
        type={type}
        isActionLoading={isActionLoading}
        setIsActionLoading={setIsActionLoading}
      />

      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    handleFeed,
    type,
    shape,
    isSoundOn,
    localPostData,
    setLocalPostData,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [localAddedPosts, setLocalAddedPosts] = useState<any[]>([]);

  // Load initial data
  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids]);

  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,
    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(username, user_id, postIds);

  // Update local posts when PostIdData changes
  useEffect(() => {
    if (PostIdData) {
      setLocalAddedPosts(PostIdData);
    }
  }, [PostIdData]);

  const onMinus = (post_id: string) => {
    // Update local state first for immediate UI update
    setLocalAddedPosts((prev) =>
      prev.filter((post) => post.post_id !== post_id)
    );

    // Then update parent state
    const updatedPostArray = postIds.filter(
      (postId: any) => postId !== post_id
    );
    props.setPostIds(updatedPostArray);
    setPostIds(updatedPostArray);
    handleFeed(updatedPostArray);
  };

  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  useEffect(() => {
    setEdit(false);
    setEditIndex("");
  }, [post_ids]);

  const handleDone = async (post_id: string, newValue: string) => {
    await updateNameValues(username, type, post_id, user_id, newValue);

    setEdit(false);
    setEditIndex("");
    PostIdDataRefetch();
  };

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  return (
    <>
      <div className="addedContent">
      <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            if (!newValue.trim()) {
              setErrorMsg("Title cannot be empty");
              return;
            }
            setErrorMsg("");
            // setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              // setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />
        <div className="addedSvg">
          {localAddedPosts.length > 0 ? (
            localAddedPosts.map((post: any, index: any) => (
              <React.Fragment key={index}>
                <div key={index} className="feed-tab-post-container added-post">
                  {/* <div
                    className="feed-tab-media-wrapper"
                    style={
                      post?.platform === "newsletter" ||
                        post?.platform === "linkedin" ||
                        post?.platform === "twitter"
                        ? {
                          textAlign: "center",
                          height: "100%",
                          borderRadius: "1rem",
                        }
                        : {}
                    }
                  >
                    <Button
                      onClick={() => onMinus(post.post_id)}
                      className="carousel-content-remove-btn"
                    >
                      Remove
                    </Button>

                    {post?.platform === "newsletter" ? (
                      <div
                        className={`${post?.type === "FEED" || shape === "sq"
                          ? "square-wrapper"
                          : ""
                          } no-media-url`}
                      // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${post?.type === "FEED" || shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "linkedin" && !post?.media_url ? (
                      <div
                        className={`${post?.type === "FEED" || shape === "sq"
                          ? "square-wrapper"
                          : ""
                          } no-media-url`}
                      // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${post?.type === "FEED" || shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "twitter" && !post?.media_url ? (
                      <div
                        className={`${post?.type === "FEED" || shape === "sq"
                          ? "square-wrapper"
                          : ""
                          } no-media-url`}
                      // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${post?.type === "FEED" || shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">X Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${post?.type === "FEED" || shape === "sq"
                          ? "square-grid"
                          : ""
                          }`}
                      >
                        {post?.media_url ? (
                          <div
                            className={`${post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                              }`}
                          >
                            {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {post?.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        <VideoComponent
                                          media_url={media_url}
                                          thumbnail={
                                            post.thumbnail &&
                                              post.thumbnail.split(",").length &&
                                              post.thumbnail.split(",")[index]
                                              ? post.thumbnail.split(",")[index]
                                              : "/assets/dummy_video.png"
                                          }
                                          content_shape={shape}
                                          isSoundOn={isSoundOn}
                                        />
                                      ) : (
                                        <img
                                          className={`${post?.type === "FEED" ||
                                            shape === "sq"
                                            ? "feed-tab-media-check square-image mb-2"
                                            : "feed-tab-media-check mb-2"
                                            }`}
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {post.media_url.includes("video") ? (
                                  <div
                                    className={`${post?.type === "FEED" || shape === "sq"
                                      ? "square-wrapper"
                                      : "h-255"
                                      }`}
                                  >
                                    <VideoComponent
                                      media_url={post.media_url}
                                      thumbnail={post.thumbnail}
                                      content_shape={shape}
                                      isSoundOn={isSoundOn}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className={`${post?.type === "FEED" || shape === "sq"
                                      ? "feed-tab-media-check square-image"
                                      : "feed-tab-media-check"
                                      }`}
                                    src={
                                      post.media_url
                                        ? post.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`${post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                              }`}
                          >
                            <img
                              className={`${post?.type === "FEED" || shape === "sq"
                                ? "feed-tab-media-check square-image"
                                : "feed-tab-media-check"
                                }`}
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div> */}
                  <PostCardComponent
                    post={post}
                    type={type}
                    isShopLinks={false}
                    shape={shape}
                    isSoundOn={isSoundOn}
                    from="added"
                    onMinus={onMinus}
                  />
                  <>
                    {" "}
                    <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        // setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          // setIsActionLoading(false);
                        }
                      }}
                      isLoading={editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                  </>
                </div>
              </React.Fragment>
            ))
          ) : PostIdFeedLoading ? (
            // Only show loading when there are no posts
            <React.Fragment>
              <div className="feed-tab-post">
                {[...Array(4)].map((_, index) => (
                  <SkeletonStatBox key={index} />
                ))}
              </div>
            </React.Fragment>
          ) : (
            <p>No Added Data</p>
          )}
        </div>
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const {
    data,
    postIds,
    isLoading,
    query,
    isShopLinks,
    type,
    localPostData,
    setLocalPostData,
  } = props;
  const [checkedValues, setCheckedValues] = useState<any>([]);

  const onAdd = (post_id: any) => {
    if (!checkedValues.includes(post_id)) {
      const updatedCheckedValues = [post_id, ...checkedValues];
      setCheckedValues(updatedCheckedValues);
      props.handleFeed(updatedCheckedValues);
    }
  };

  const onMinus = (post_id: any) => {
    const updatedCheckedValues = checkedValues.filter(
      (postId: any) => postId !== post_id
    );
    setCheckedValues(updatedCheckedValues);
    props.handleFeed(updatedCheckedValues);
  };

  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds]);

  return (
    <>
      {!isLoading ? (
        <>
          {data?.pages ? (
            <div className="feed-tab-posts-container">
              {data.pages.map((group: any, pageIndex: number) => (
                <Fragment key={pageIndex}>
                  {group.map((image: any, index: number) => (
                    <div
                      key={index}
                      className="feed-tab-post-container feed-post-wrapper"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          image?.platform === "newsletter" ||
                            image?.platform === "linkedin" ||
                            image?.platform === "twitter"
                            ? {
                              textAlign: "center",
                              height: "100%",
                              borderRadius: "1rem",
                            }
                            : {}
                        }
                      >
                        {Array.isArray(checkedValues) &&
                          checkedValues?.includes(image.post_id) &&
                          postIds.includes(image.post_id) ? (
                          <Button
                            onClick={() => onMinus(image.post_id)}
                            className="carousel-content-remove-btn"
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onAdd(image.post_id)}
                            className="carousel-content-add-btn"
                          >
                            Add
                          </Button>
                        )}

                        {image?.platform === "newsletter" ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                          // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image?.platform === "linkedin" &&
                          !image?.media_url ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                          // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image?.platform === "twitter" &&
                          !image?.media_url ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                          // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label">X Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : (
                          <>
                            {image.media_url ? (
                              <>
                                {image.media_url.split(",").length > 1 &&
                                  image.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel newsletter-media-carousel"
                                  >
                                    {image.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  image.thumbnail &&
                                                    image.thumbnail.split(",")
                                                      .length &&
                                                    image.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    ? image.thumbnail.split(
                                                      ","
                                                    )[index]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mb-2"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {image.media_url.includes("video") ? (
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={image.media_url}
                                          thumbnail={image.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      // </div>
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          image.media_url
                                            ? image.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </div>
                      {/* <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                          ? image?.shop_title
                          : image?.product_name}{" "}
                      </p> */}

                      {/* <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                            ? image?.shop_title
                            : image?.product_name}{" "}
                        <HiPencil
                        // onClick={() => {
                        //   setValue(
                        //     type === "content" || type === "query-content"
                        //       ? post?.content_title
                        //         ? post?.content_title
                        //         : post?.product_name
                        //       : post?.shop_title
                        //       ? post?.shop_title
                        //       : post?.product_name
                        //   );
                        //   setEdit(true);
                        //   setEditIndex(index);
                        // }}
                        />
                      </p> */}
                      <ProductTitle post={image} type={type} />
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <>
              {isShopLinks ? (
                <p>No shoppable content present for '{query}'</p>
              ) : (
                <p>No content present for '{query}'</p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="feed-tab-posts-container">
          {[...Array(4)].map((_, index) => (
            <LoadingPost key={index} />
          ))}
        </div>
      )}
    </>
  );
};

// Add some CSS transitions for smooth animations
const styles = `
.feed-tab-post-container {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.feed-tab-post-container.removing {
  opacity: 0;
  transform: scale(0.9);
}

.feed-tab-post-container.adding {
  opacity: 0;
  transform: scale(1.1);
}

.feed-tab-post-container.added {
  opacity: 1;
  transform: scale(1);
}
`;

export default FeedCheckboxComponent;
