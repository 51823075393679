import { Alert, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import validator from "validator";
import { storeNewsletterChannel } from "../../../Utils/SupabaseServices/ContentDatabase";

const NewsletterIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showNewsletterModal, user_id, data ,refetchData} = props;
  const [newsletter, setNewsletter] = useState(data?.newsletter_email);
  const [websiteLink, setWebsiteLink] = useState(data?.newsletter_website_link || "");

  const [errorNewsletter, setErrorNewsletter] = useState("");
  const [loadingNewsletterConnect, setLoadingNewsletterConnect] =
    useState(false);
  const validateEmail = async (email: string) => {
    if (validator.isEmail(email)) {
      setLoadingNewsletterConnect(true);
      const { error, message } = await storeNewsletterChannel(
        user?.id as string,
        newsletter,
        username,
        websiteLink
      );
      if (error) {
        setErrorNewsletter(message);
      } else {
        refetchData();
        props.onClose();
        // props.setShowNewsletterModal(false);
      }
      setLoadingNewsletterConnect(false);
    } else {
      setErrorNewsletter("Enter valid email.");
    }
  };

  return (
    <>
      <Modal
        title="Connect to Newsletter"
        open={showNewsletterModal}
        onOk={() => {
          validateEmail(newsletter);
        }}
        onCancel={() => {
          setErrorNewsletter("");
          setNewsletter(data?.newsletter_email);
          setWebsiteLink(data?.newsletter_website_link || "");
          props.onClose();
          // props.setShowNewsletterModal(false);
        }}
        okText="Connect"
        okButtonProps={{
          disabled: !newsletter,
          loading: loadingNewsletterConnect,
        }}
        cancelButtonProps={{ disabled: loadingNewsletterConnect }}
      >
        <p>
          Please subscribe "index@thesearchbubble.com" to your newsletter list.
        </p>
        <p>
          By connecting your newsletter email, all your mails will be displayed
          as posts in the feed of your main page. In the content tab you will be
          able to hide or delete the mails you don't want to show.{" "}
        </p>
        {loadingNewsletterConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorNewsletter && (
              <Alert
                type="error"
                message={`Error: ${errorNewsletter}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.newsletter_email ? (
              <>
                <p>Already connected newsletter : {data?.newsletter_email}</p>
                <p>New email will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your newsletter email and website link:</p>

            <Input
              placeholder="Email address"
              value={newsletter}
              onChange={(e) => {
                const value = e.target.value;
                setNewsletter(value);
                if (!value) {
                  setErrorNewsletter("Enter valid email");
                } else {
                  setErrorNewsletter("");
                }
              }}
            />
            
            <Input
              placeholder="Website subscription link (optional)"
              value={websiteLink}
              className="mt-4"
              onChange={(e) => {
                const value = e.target.value;
                setWebsiteLink(value);
              }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default NewsletterIntegrationModal;
