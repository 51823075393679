import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { getPlatformName } from "../../../Utils/HelperFunction/HelperFunction";

export function useLoadProductBankPlatform(
  username: string,
  user_id: string,
  query_string: string,
  filter: string
) {
  const fetchProductBankPlateform = async () => {
    const { data, error } = await supabase.rpc("get_productbank_plateforrmm", {
      username: username,
      userid: user_id,
      filter: filter,
      query_string: null,
      query_tagg: null,
    });
    if (error) {
      throw new Error(error.message);
    }
    const filteredData = [...new Set(data)].filter(
      (platform: any) =>
        platform && platform !== "MISC" && platform !== "MISC-content"
    );

    const productFilterItems =
      filteredData.length > 1
        ? [
            { key: "All", label: "All" },
            ...filteredData.map((platform: any) => ({
              key: platform,
              label: getPlatformName(platform.toLowerCase()),
            })),
          ]
        : [];
    return productFilterItems;
  };

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["productbankplaform", filter],
    fetchProductBankPlateform
  );

  return { data, isLoading, isError, error, refetch };
}
