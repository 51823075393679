import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../hooks/useAuth";
import useUpdateStayUpToDate from "../../../useUpdateStayUpToDate";
// src/Screens/DashboardView/StayUpToDate/Utils/useUpdateStayUpToDate.tsx
const EditLinksWrapper = (props: any) => {
  const { user } = useAuth();
  const user_id = user?.id || "";
  const {
    isEditModalOpen,
    setIsEditModalOpen,
    component,
    setSelectedComponent,
    selectedComponent,
    refetch,
    refetchLinks,
    from
  } = props;
  const [newTitle, setNewTitle] = useState(selectedComponent?.name || "");

  // Update newTitle when selectedComponent changes
  useEffect(() => {
    setNewTitle(selectedComponent?.name || "");
  }, [selectedComponent]);
  const { mutate: updateComponents } = useUpdateStayUpToDate();

  const handleOk = () => {
    updateComponents({
      oldTitle: selectedComponent?.name,
      newTitle: newTitle,
      componentId: selectedComponent?.id,
      pageIndex: 1,
      index: 1,
      user_id,
      from
    });

    refetchLinks();
    setIsEditModalOpen(false);
    setSelectedComponent("");
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
    setSelectedComponent("");
  };

  return (
    <>
      <Modal
        title={selectedComponent?.name}
        open={isEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter new title"
          value={newTitle}
          onChange={(e: any) => setNewTitle(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default EditLinksWrapper;

// updateSocialLink({
//   link,
//   platform: icon,
//   pageIndex: 1,
//   index: 1,
//   user_id,
// });
