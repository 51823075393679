import React from "react";
import { useNavigate } from "react-router-dom";
const JoinButton = (props: any) => {
  const navigate = useNavigate();
  const { username } = props;
  function gotoSignUpPage() {
    const utmUrl = `/subscribe?source=${username}`;
    navigate(utmUrl);
  }
  return (
    <>
      <button className="join-button" onClick={gotoSignUpPage}>
        <p className="join-button-text">Are you a creator?</p>
        <div>
          <span>Join </span>
          <span
            className="join-button__placeholder"
            style={{ color: "#666666" }}
          >
            {username}
          </span>
          <span> on search bubble</span>
        </div>
      </button>
    </>
  );
};

export default JoinButton;
