import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin, Result, Typography } from "antd";
import "../../components/Footer/Footer.css";

const { Title, Paragraph } = Typography;

const PaymentStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
    
    if (sessionId) {
      verifyPayment(sessionId);
    } else {
      setIsVerifying(false);
      setError("No payment session found. Please try again.");
    }
  }, [location]);

  const verifyPayment = async (sessionId: any) => {
    try {
      setIsVerifying(true);
      // http://localhost:3001/verify-payment
      const response = await fetch("https://dev.thesearchbubble.com/verify-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          sessionId,
          source: 'pricing_table',
          environment: process.env.NODE_ENV
        }),
      });
      
      const data = await response.json();
      if (data.success) {
        navigate("/onboarding",{state:{sessionId:sessionId,data:data}});
      } else {
        setIsVerifying(false);
        setError(data.message || "Payment verification failed");
        // Give user time to see the error before redirecting
        setTimeout(() => {
          navigate("/payment-canceled");
        }, 3000);
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      setIsVerifying(false);
      setError("An error occurred while verifying your payment. Please contact support.");
    }
  };

  return (
    <div className="auth-container">
      <div className="navbar_container">
        <a href="/" className="navbar_logo-link w-nav-brand" aria-label="home">
          <div className="logo-text">
            <img
              className="homepage-icon"
              src="/assets/output-onlinepngtools-edited.png"
              alt="thesearchbubble logo"
            />
          </div>
        </a>
      </div>

      <div 
        style={{
          maxHeight: "85vh",
          margin: "0 auto",
          padding: "0px 20px",
          textAlign: "center",
          borderRadius: "8px",
          color: "white",
        }}
      // style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh"}}
      >
        {isVerifying ? (
          <div style={{ textAlign: "center", padding: "40px",backgroundColor: "#27282b", borderRadius: "8px" }}>
            <Spin size="large" />
            <Title level={3} style={{ marginTop: "20px",color: "white" }}>Verifying your payment</Title>
            <Paragraph style={{ marginTop: "10px", color: "#666" }}>
              Please wait while we process your transaction...
              <br/>
              You will be redirected to the onboarding page shortly.
            </Paragraph>
          </div>
        ) : error ? (
          <Result
            status="warning"
            title={<span style={{ color: "white" }}>Payment Verification Issue</span>}
            subTitle={<span style={{ color: "#f0f0f0" }}>{error}</span>}
            style={{ background: "#27282b", padding: "30px", borderRadius: "8px" }}
            extra={[
              <a key="try-again" href="/subscribe">
                <button 
                  style={{ 
                    padding: '10px 20px', 
                    background: '#1890ff', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '4px', 
                    cursor: 'pointer',
                    marginRight: '10px' 
                  }}
                >
                  Try Again
                </button>
              </a>,
              <a key="contact" href="mailto:support@thesearchbubble.com">
                <button 
                  style={{ 
                    padding: '10px 20px', 
                    background: 'white', 
                    color: '#666', 
                    border: '1px solid #d9d9d9', 
                    borderRadius: '4px', 
                    cursor: 'pointer' 
                  }}
                >
                  Contact Support
                </button>
              </a>
            ]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PaymentStatus;
