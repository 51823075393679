import axios from "axios";
import { supabase } from "../../config/supabaseClient";
import { extractLinkedInUsername } from "./ContentDatabase";

export async function signupUserFolder(
  name: any,
  username: any,
  email: any,
  phone: any,
  password: any
) {
  try {
    if (!username) {
      return {
        error: true,
        message: "Please fill in all fields",
      };
    }
   

    const url = `https://dev.thesearchbubble.com/createuser`;
    const createNewUserError = await axios.post(
      url,
      {
        //   id: userId,
        email: email,
        password: password,
        name: name,
        username: username,
        // instaUsername: instaUsername,
        phoneNo: phone,
      }
      // {
      //   headers: {
      //     "content-type": "application/json",
      //   },
      // }
    );

    if (createNewUserError.data !== "done") {
      const errorMessage = createNewUserError.data.message || "";
      const duplicateEmailMessage =
        'duplicate key value violates unique constraint "user_email_key"';
      const duplicateUsernameMessage =
        'duplicate key value violates unique constraint "user_username_key"';
      const duplicateEmailError = errorMessage.includes(duplicateEmailMessage);
      const duplicateUsernameError = errorMessage.includes(
        duplicateUsernameMessage
      );

      return {
        error: true,
        message:
          createNewUserError?.data?.message ||
          (duplicateEmailError
            ? "This email is already in use."
            : duplicateUsernameError
            ? "This username is already taken. Please try another one."
            : "Error, please try again later or contact our support team."),
      };
    } else {
      return {
        error: false,
        message: "User created successfully",
      };
    }
  } catch (error: any) {
    return {
      error: true,
      message:
        error?.message ||
        "Error while creating user, please report this error to our support team.",
    };
  }
}

export async function updateSelectedPlatforms(
  selectedPlatforms: any,
  username: any,
  newPlatforms: any
) {
  // for (let [index, platform] of selectedPlatforms.entries()) {

  if (newPlatforms.platform === "instagram") {
    try {
      const api_url = "https://dev.thesearchbubble.com/validate-instagram";
      const response = await axios.post(api_url, {
        username: newPlatforms.usernames,
      });
      if (response?.data?.success == true) {
        await supabase
          .from("user")
          .update({ instagram_username: newPlatforms.usernames })
          .eq("username", username);
      } else {
        throw new Error("Invalid Instagram username");
      }
    } catch (error: any) {
      console.error(
        "Instagram validation error:",
        error?.response?.data?.message
      );
      throw new Error(
        error?.response?.data?.message || "Instagram validation failed"
      );
    }
  } else if (newPlatforms.platform === "linkedin") {
    try {
      // const linkedInUsername = await extractLinkedInUsername(platform.usernames);
      if (newPlatforms.usernames) {
        const api_url = "https://dev.thesearchbubble.com/verify-linkedin";
        const validationResponse = await axios.post(api_url, {
          username: newPlatforms.usernames,
        });
        if (validationResponse?.data?.success == true) {
          await supabase
            .from("user")
            .update({
              linkedin_user: newPlatforms.usernames,
              is_linkedin_on: true,
            })
            .eq("username", username);
        } else {
          throw new Error("Invalid LinkedIn username");
        }
      }
    } catch (error: any) {
      console.error(
        "LinkedIn validation error:",
        error?.response?.data?.message
      );
      throw new Error(
        error?.response?.data?.message || "LinkedIn validation failed"
      );
    }
  } else if (newPlatforms.platform === "tiktok") {
    try {
      if (newPlatforms.usernames) {
        const result = newPlatforms.usernames.startsWith("@")
          ? newPlatforms.usernames.slice(1)
          : newPlatforms.usernames;

        const api_url = "https://dev.thesearchbubble.com/verify-tiktok";
        const validationResponse = await axios.post(api_url, {
          username: result,
        });
        if (validationResponse?.data?.success == true) {
          await supabase
            .from("user")
            .update({ tiktok_user: result, is_tiktok_on: true })
            .eq("username", username);
        } else {
          throw new Error("Invalid TikTok username");
        }
      }
    } catch (error: any) {
      console.error("TikTok validation error:", error?.response?.data?.message);
      throw new Error(
        error?.response?.data?.message || "TikTok validation failed"
      );
    }
  } else if (newPlatforms.platform === "youtube") {
    if (newPlatforms.usernames) {
      // const youtubeChannelId = await extractYoutubeChannelId(platform.username);
      const newPlatformsUsername = newPlatforms.usernames?.includes("@")
        ? newPlatforms.usernames.slice(1)
        : newPlatforms.usernames;
      try {
        const api_url = "https://dev.thesearchbubble.com/verify-youtube";
        const validationResponse = await axios.post(api_url, {
          username: newPlatformsUsername,
        });
        if (validationResponse?.data?.success == true) {
          await supabase
            .from("user")
            .update({
              youtube_playlist_id:
                validationResponse?.data?.channelInfo?.uploadPlaylistId,
              youtube_channel_id: validationResponse?.data?.channelInfo?.id,
              youtube_username: newPlatformsUsername,
              is_youtube_on: true,
            })
            .eq("username", username);
        } else {
          throw new Error("Invalid YouTube channel");
        }
      } catch (error: any) {
        console.error(
          "YouTube validation error:",
          error?.response?.data?.message
        );
        throw new Error(
          error?.response?.data?.message || "YouTube validation failed"
        );
      }
    }
  } else if (newPlatforms.platform === "newsletter") {
    await supabase
      .from("user")
      .update({
        newsletter_email: newPlatforms.usernames,
        newsletter_website_link: newPlatforms.websiteLink || null,
        is_newsletter_on: true,
      })
      .eq("username", username);
  } else if (newPlatforms.platform === "podcast") {
    try {
      const podcastShowName = newPlatforms.usernames;
      const podcastShowUrl = newPlatforms.showUrl || "";
      const spotifyId = extractSpotifyID(podcastShowUrl);

      const { data, error } = await supabase
        .from("user")
        .update({
          podcast_link: podcastShowUrl,
          podcast_showname: podcastShowName,
          podcast_artist: podcastShowName,
          podcast_id: spotifyId,
          is_podcast_on: true,
        })
        .eq("username", username);
 
    } catch (error: any) {
      console.error(
        "Podcast validation error:",
        error?.response?.data?.message
      );
      throw new Error(
        error?.response?.data?.message || "Podcast validation failed"
      );
    }
  } else if (newPlatforms.platform === "twitter") {
    if (newPlatforms.usernames) {
      try {
        const result = newPlatforms.usernames.startsWith("@")
          ? newPlatforms.usernames.slice(1)
          : newPlatforms.usernames;

        const api_url = "https://dev.thesearchbubble.com/verify-twitter";
        // const api_url = "http://localhost:3001/verify-twitter";
        const validationResponse = await axios.post(api_url, {
          username: result,
        });
        if (validationResponse?.data?.success == true) {
          await supabase
            .from("user")
            .update({
              twitter_user: newPlatforms.usernames,
              is_twitter_on: true,
            })
            .eq("username", username);
        } else {
          throw new Error("Invalid Twitter username");
        }
      } catch (error: any) {
        console.error(
          "Twitter validation error:",
          error?.response?.data?.message
        );
        throw new Error(
          error?.response?.data?.message || "Twitter validation failed"
        );
      }
    }
  }
  else if (newPlatforms.platform === "linkinbio") {
    
        await supabase
          .from("user")
          .update({ linkinbio: newPlatforms.usernames })
          .eq("username", username);
     
    
  }else if (newPlatforms.platform === "amazon") {
    await supabase
      .from("user")
      .update({ social_amazon: newPlatforms.usernames })
      .eq("username", username);
  }else if (newPlatforms.platform === "shopmy") {
    await supabase
      .from("user")
      .update({ social_shopmy: newPlatforms.usernames })
      .eq("username", username);
  }else if (newPlatforms.platform === "ltk") {
    await supabase
      .from("user")
      .update({ social_ltk: newPlatforms.usernames })
      .eq("username", username);
  }else if (newPlatforms.platform === "website") {
    await supabase
      .from("user")
      .update({ social_website: newPlatforms.usernames })
      .eq("username", username);
  }
  

}
// }

export async function IntegratePlatforms(
  selectedPlatforms: any,
  username: any
) {
// username="ch_12"
// selectedPlatforms = [
//   {
//     platform: "instagram",
//     username: "akriti_3109",
//   },
//   {
//     platform: "youtube",
//     username:"onepotatobox6281"
   
//   },
//   {
//     platform: "tiktok",
//     username: "deepa_toks",
//   },
//   {
//     platform: "linkedin",
//     username: "mahima-dariyani",
//   },
//   {
//     platform: "newsletter",
//     username: "mahidariyani05@gmail.com",
//     websiteLink: "https://www.thesearchbubble.com",
//   },
//   {
//     platform: "podcast",
//     username: "the money",
//     showUrl: "https://open.spotify.com/show/10BfU2D8gXUKJ4mJRleBj1",
//     showId: "10BfU2D8gXUKJ4mJRleBj1",
//   },
//   {
//     platform: "twitter",
//     username: "akriti_3109",
//   },
// ];




  const { data } = await supabase
    .from("user")
    .select("*")
    .eq("username", username);
  const user_id = data?.[0]?.user_id;
  const channel = "#newintegrations";

  const slackUrl = `https://dev.thesearchbubble.com/slack/update`;
  for (let [index, platform] of selectedPlatforms.entries()) {
    if (platform.platform === "instagram") {
      const highlightSetupUrl = `https://dev.thesearchbubble.com/highlightsetup-onboarding`;
// const highlightSetupUrl = `http://localhost:3001/highlightsetup-onboarding`;
      axios.post(highlightSetupUrl, {
        id: user_id,
        name: username,
        from: "content",
        instaUsername: platform.username,
      });
      const demoSetupUrl = `https://dev.thesearchbubble.com/datascrapper-onboarding`;
// const demoSetupUrl = `http://localhost:3001/datascrapper-onboarding`;
      const resThumbnail = axios.post(demoSetupUrl, {
        id: user_id,
        name: username,
        from: "content",
        instaUsername: platform.username,
      });
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Instagram Integration
          Username: ${username},

          Instagram Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // }, 2000);
    } else if (platform.platform === "youtube") {
      const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
// const url = `http://localhost:3001/api/youtube/storeVideosByChannelId`;
      const resServer: any =axios.post(url, {
        userId: user_id,
        channelId: platform.channelId,
        username: username,
      });

      const urlYtShorts = `https://dev.thesearchbubble.com/api/youtube/shorts`;
// const urlYtShorts = `http://localhost:3001/api/youtube/shorts`;
      const YTShorts = axios.post(urlYtShorts, {
        userId: user_id,
        channelId: platform.channelId,
        username: username,
      });

     

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `YouTube Integration
          Username: ${username},

 
         YouTube Username: ${platform.username}
         User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "tiktok") {
      const url = `https://dev.thesearchbubble.com/integrate/tiktok`;
// const url = `http://localhost:3001/integrate/tiktok`;
      axios.post(
        url,
        {
          id: user_id,
          name: username,
          username: username,
          tiktokUsername: platform.username,
        }

      );
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `TikTok Integration
          Username: ${username},
        
         
          TikTok Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }
      );
    } else if (platform.platform === "linkedin") {
      const url = `https://dev.thesearchbubble.com/fetch/linkedin`;
// const url = `http://localhost:3001/fetch/linkedin`;

      const resServer = axios.post(url, {
        userId: user_id,
        user: username,
        linkedInUsername: platform.username,
      });

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `LinkedIn Integration
          Username: ${username},
        
         
          LinkedIn Username: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "newsletter") {
      const url = `https://dev.thesearchbubble.com/api/user/store/newsletter`;
// const url = `http://localhost:3001/api/user/store/newsletter`;

      const resServer = axios.post(url, {
        userId: user_id,
        user: username,
        email: platform.username,
      });

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Newsletter Integration
          Username: ${username},
          
          Email: ${platform.username}
          Website Link: ${platform.websiteLink || "Not provided"}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "podcast") {
      const url = `https://dev.thesearchbubble.com/fetch/podcast`;
// const url = `http://localhost:3001/fetch/podcast`;

      let res: any = axios.post(url, {
        user: username,
        user_id: user_id,
        showName: platform.username,
        id: platform.showId,
      });

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Podcast Integration
          Username: ${username},
          
          Podcast Show Name: ${platform.username}
          Podcast Show URL: ${platform.showUrl || "Not provided"}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "twitter") {

      const url = `https://dev.thesearchbubble.com/fetch/twitter`;
// const url = `http://localhost:3001/fetch/twitter`;


      const response = axios.post(url, {
        userId: user_id,
        user: username,
        twitterUser: platform.username,
      });
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Twitter Integration
          Username: ${username},
        
         
          Twitter Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }
}

export async function disconnectPlatform(platform: string, username: string) {
  try {
    const updates: { [key: string]: any } = {};

    switch (platform) {
      case "instagram":
        updates.instagram_username = null;
        break;
      case "youtube":
        updates.youtube_username = null;
        updates.youtube_channel_id = null;
        updates.youtube_playlist_id = null;
        updates.show_youtube = false;
        updates.is_youtube_on = false;
        break;
      case "twitter":
        updates.twitter_user = null;
        updates.is_twitter_on = false;
        break;
      case "tiktok":
        updates.tiktok_user = null;
        updates.is_tiktok_on = false;
        break;
      case "linkedin":
        updates.linkedin_user = null;
        updates.is_linkedin_on = false;
        break;
      case "newsletter":
        updates.newsletter_email = null;
        updates.newsletter_website_link = null;
        updates.is_newsletter_on = false;
        break;
      case "podcast":
        updates.podcast_link = null;
        updates.podcast_showname = null;
        updates.podcast_id = null;
        updates.is_podcast_on = false;
        break;
      case "linkinbio":
        updates.linkinbio = null;
        break;
      case "amazon":
        updates.social_amazon = null;
        break;
        case "shopmy":
          updates.social_shopmy = null;
          break;
          case "ltk":
            updates.social_ltk = null;
            break;
            case "website":
              updates.social_website = null;
              break;
            
        
    }

    const { error } = await supabase
      .from("user")
      .update(updates)
      .eq("username", username);

    if (error) throw error;
    return { success: true };
  } catch (error: any) {
    console.error(`Error disconnecting ${platform}:`, error);
    throw new Error(`Failed to disconnect ${platform}: ${error.message}`);
  }
}
export const extractSpotifyID = (url: string) =>
  (url.match(/spotify\.com\/show\/([a-zA-Z0-9]+)/) || [])[1] || null;

export const updateUserSocialLinks = async (username: string, socialData: any) => {
  try {
    const { data, error } = await supabase
      .from("user")
      .update({
        social_website: socialData.website,
        linkinbio: socialData.linkinbio,
        social_ltk: socialData.ltk,
        social_shopmy: socialData.shopmy,
        social_amazon: socialData.amazon
      })
      .eq("username", username);
      
    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error updating user social links:', error);
    return { data: null, error };
  }
};

// export const getUserSocialLinks = async (username: string) => {
//   try {
//     const { data, error } = await supabase
//       .from("user")
//       .select('social_website, linkinbio, social_ltk, social_shopmy, social_amazon')
//       .eq("username", username)
//       .single();
      
//     if (error) throw error;
//     return { data, error: null };
//   } catch (error) {
//     console.error('Error fetching user social links:', error);
//     return { data: null, error };
//   }
// };


export function extractUsername(input:any) {
  const match = input.match(/(?:@|\.com\/(?:in\/)?)([\w\d_]+)/);
  return match ? match[1] : input;
}