import axios from "axios";
import { supabase } from "../../config/supabaseClient";
import { updateRefClick } from "./ContentDatabase";

export enum TRACKING_TYPES {
  PAGE = "page",
  EVENT = "event",
  ERROR = "error",
  SEARCH = "search",
}
export default async function track(
  name: string,
  type: TRACKING_TYPES,

  extras?: { pathname?: string; details?: any; title?: string; user_id?: any }
) {
  // chack params, they should be in the right format
  if (
    typeof name !== "string" ||
    !Object.values(TRACKING_TYPES).includes(type)
  ) {
    return;
  }

  let success = false;
  const trackingObject = {
    ip: "",
    type,
    name,
    pathname: extras?.pathname || "",
    details: extras?.details || "",
    title: extras?.title || "",
    user_id: extras?.user_id || "",
  };
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    trackingObject.ip = res.data.ip;
    const { error } = await supabase.from("analytics").insert([trackingObject]);

    if (!error) {
      success = true;
    }
  } catch (err) {
    trackingObject.ip = "";
    trackingObject.type = TRACKING_TYPES.ERROR;
    trackingObject.name = "Tracking Error";
    const { error } = await supabase.from("analytics").insert([trackingObject]);
    if (!error) {
      success = true;
    }
  } finally {
    if (!success) {
    }
  }
}



export function handleRedirect(postId: string, media_url: string,platform:string,user_id:string) {

  track("Redirect to original post", TRACKING_TYPES.SEARCH, {
    pathname: window.location.pathname,
    details: { post_id: postId, media_url: media_url,platform:platform },
    user_id: user_id,
  });
  updateRefClick(postId, user_id);
}



export  const handleShop = (postId: any, mediaUrl: any,platform:any,user_id:any) => {

  track("Redirect to shop link", TRACKING_TYPES.EVENT, {

    pathname: window.location.pathname,
    details: { post_id: postId, media_url: mediaUrl,platform:platform },
    user_id: user_id,
  });
};


export const handleTagged = (postId: any, mediaUrl: any,platform:any,user_id:any) => {
  track("Redirect to tagged link", TRACKING_TYPES.EVENT, {
    pathname: window.location.pathname,
    details: { post_id: postId, media_url: mediaUrl,platform:platform },
    user_id: user_id,
  });
};