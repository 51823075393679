import { Button, List, Modal} from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import '../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css';
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import PreviewComponent from "./PreviewComponent";
import { ModalBackButton } from "../../../../../../components/EditModalComponents.tsx/ModalBackButton";

const YesterdayLinksComponents = (props: any) => {
  const { username, user_id, isYesterdayLinksComponentOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const days = [1];
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Yesterday's Shoppable Content",
      "",
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      "Yesterday"
    );
    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsYesterdayLinksComponentOpen(false);
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsYesterdayLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsYesterdayLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button
        //       onClick={handlePrevious}
        //       className="newbutton"
        //     >
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isYesterdayLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="yesterday-content"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton handlePrevious={handlePrevious} title="Yesterday's Shoppable Content" description="All of your shoppable content posted yesterday will always be shown in this carousel. Content will automatically be updated!" />
        <label className="bold-label">Preview</label>

        <PreviewComponent 
        numberOfDays= {days}
        username={username}
        user_id={user_id}
        displayName="Yesterday"
        title="Yesterday's Shoppable Content"
        />
      </Modal>
    </>
  );
};

export default YesterdayLinksComponents;
