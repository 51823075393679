import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './myLinksSkeleton.css';

interface SkeletonProps {
  templateType: 'grid' | 'line';
  count?: number;
}

export const MyLinksSkeleton: React.FC<SkeletonProps> = ({ templateType, count = 3 }) => {
  const GridSkeleton = () => (
    <>
    <div className="mainDiv">
    </div>
    <div className="grid-skeleton-card">
      <div className="grid-skeleton-image">
        <Skeleton height={128} width="95%" />
      </div>
      <div className="grid-skeleton-content">
        <Skeleton height={55} width="95%" />
      </div>
    </div>
    </>
  );

  const LineSkeleton = () => (
    <div className="line-skeleton-card">
      <div className="line-skeleton-image">
        <Skeleton circle height={50} width={50} />
      </div>
      <div className="line-skeleton-content">
        <Skeleton height={24} width="60%" />
      </div>
    </div>
  );

  return (
    <div className={`skeleton-container ${templateType}-layout`}>
      {[...Array(count)].map((_, index) => (
        <div key={index} className={templateType === 'grid' ? '' : 'w-100'}>
          {templateType === 'grid' ? <GridSkeleton /> : <LineSkeleton />}
        </div>
      ))}
    </div>
  );
};