import { Button, Input, List, Modal } from "antd";
import React, { useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { addComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import QueryFeedCheckboxComponent from "./QueryFeedCheckboxComponent";
import { ModalBackButton } from "../../../../components/EditModalComponents.tsx/ModalBackButton";

const AddQueryContentComponents = (props: any) => {
  const {
    username,
    user_id,
    isQueryContentComponentOpen,
    isContentEditModalOpen,
    type,
    from
  } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [query, setQuery] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [order, setOrder] = useState("DESC");
  const [shape, setShape] = useState("rect");
  const [isSoundOn, setIsSoundOn] = useState(false);
  // const nameRef = useRef("");
  // const [defaultQuery,setDefaultQuery]=useState("")
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const nameRef = useRef("");
  const handleInputChange = (value: string) => {
    nameRef.current = value;
    setName(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    // const finalName = nameRef.current;
    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      query,
      name,
      "query-content",
      "",
      order,
      shape,
      isSoundOn,
      undefined,
      from
    );
    if (res === "true") {
      props.refetch();

      handleReset();

      setConfirmLoading(false);
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
    setEdit(false);
    setEditIndex("");
  };

  const handleCancel = () => {
    handleReset();
  };
  const handlePrevious = () => {
    handleReset();
    props.setIsContentModalOpen(true);
  };
  function handleReset() {
    props.setIsContentModalOpen(false);
    props.setIsQueryContentComponentOpen(false);
    setName("");
    nameRef.current = "";
    setPostIds("");
    setErrorMsg("");
    setQuery("");
    setDefaultQuery("");
    setQuery("");
    setDefaultQuery("");
    setShape("rect");

    setEdit(false);
    setEditIndex("");
  }
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  function handleQuery(term: any) {
    setQuery(term);
  }
  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button onClick={handlePrevious} className="newbutton">
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isQueryContentComponentOpen || isContentEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="add-shoppable-content"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton
          handlePrevious={handlePrevious}
          title="Create a Word or Phrase Based Content Carousel"
        />
   
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <QueryFeedCheckboxComponent
          isShopContent={false}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          componentName={name}
          post_ids={postIds}
          handleQuery={handleQuery}
          defaultQuery={defaultQuery}
          type="content"
          setDefaultQuery={setDefaultQuery}
          shape={shape}
          setShape={setShape}
          order={order}
          setOrder={setOrder}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          edit={edit}
          setEdit={setEdit}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          name={name}
          setName={setName}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      </Modal>
    </>
  );
};

export default AddQueryContentComponents;
