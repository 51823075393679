import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import PreviewComponent from "./PreviewComponent";
import { ModalBackButton } from "../../../../../../components/EditModalComponents.tsx/ModalBackButton";

const ThisWeekLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isThisWeekLinksComponentOpen,
    title,
    description,
    from
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const getComponentArgs = (title: string) => {
    switch (title) {
      case "This Month's Shoppable Content":
        return { name: title, type: "This Month", numbersOfDays: [30] };
      case "This Week's Content":
        return { name: title, type: "This Week Content", numbersOfDays: [7] };
      default:
        return {
          name: "This Week's Shoppable Content",
          type: "This Week",
          numbersOfDays: [7],
        };
    }
  };
  const { numbersOfDays,type,name } = getComponentArgs(title);

  const handleOk = async () => {
    setConfirmLoading(true);
    let res;
    const { name, type, numbersOfDays } = getComponentArgs(title);

    res = await addComponent(
      name,
      numbersOfDays,
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      type,
      "",
      "",
      type.includes("This Week") ? "ASC" : "DESC",
      "rect",
      false,
      undefined,
      from
    );

    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsThisWeekLinksComponentOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsThisWeekLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsThisWeekLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button onClick={handlePrevious} className="newbutton">
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isThisWeekLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="thisweek-shop"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton handlePrevious={handlePrevious} title={title} description={description} />
        <label className="bold-label">Preview</label>


        {/* preview */}
        <PreviewComponent
          numberOfDays={numbersOfDays}
          username={username}
          user_id={user_id}
          displayName={title}
          type={type}
          title={name}
        />
      </Modal>
    </>
  );
};

export default ThisWeekLinksComponents;
