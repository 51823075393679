import { BsInfoCircle } from "react-icons/bs";
// import { Tooltip } from "react-tooltip";
import useOverviewAnalytics from "./useOverviewAnalytics";
import useGraphicalAnalytics from "./useGraphicalAnalytics";
import Skeleton from "react-loading-skeleton";

import StatBox from "../../../../components/StatBox/StatBox";
import "react-tooltip/dist/react-tooltip.css";
import { useEffect, useState } from "react";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import "../Analytics.css";
import { AgChartsReact } from "ag-charts-react";
import { AgChartOptions } from "ag-charts-community";
import { Spin } from "antd";
import { BannerSkeleton } from "../../../../components/StayUpToDateComponent/BannerSkeleton";

// Custom function to process data for smoother curves
// const processDataForSmoothCurves = (data: any[]) => {
//   if (!data || data.length === 0) return [];

//   // Always interpolate more points between existing points for smoother curves
//   const result = [...data];
//   const interpolatedPoints = [];

//   // Add multiple interpolated points between existing points
//   for (let i = 0; i < data.length - 1; i++) {
//     const current = data[i];
//     const next = data[i + 1];

//     // Create 3 interpolated points between each pair of actual data points
//     for (let j = 1; j <= 3; j++) {
//       const ratio = j / 4; // 1/4, 2/4, 3/4 positions

//       // Create an interpolated point
//       const interpolated = {
//         ...current,
//         users: current.users + (next.users - current.users) * ratio,
//         searches: current.searches + (next.searches - current.searches) * ratio,
//         links: current.links + (next.links - current.links) * ratio,
//         page_views:
//           current.page_views + (next.page_views - current.page_views) * ratio,
//         formatted_date: `${current.formatted_date}-${j}`, // Mark as interpolated
//       };

//       interpolatedPoints.push({
//         index: i * 3 + j,
//         point: interpolated,
//       });
//     }
//   }

//   // Insert all interpolated points
//   // interpolatedPoints.forEach((item, idx) => {
//   //   result.splice(item.index + idx, 0, item.point);
//   // });
//   interpolatedPoints.forEach((item, idx) => {
//     result.splice(item.index + idx, 0, item.point);
//   });
//   return result;
// };

const processDataForSmoothCurves = (data: any[]) => {
  if (!data || data.length === 0) return [];

  // Create a new array for the result instead of modifying the original
  const result = [];

  // Process each pair of consecutive points
  for (let i = 0; i < data.length - 1; i++) {
    const current = data[i];
    const next = data[i + 1];

    // Add the current point
    result.push(current);

    // Add interpolated points between current and next
    for (let j = 1; j <= 3; j++) {
      const ratio = j / 4; // 1/4, 2/4, 3/4 positions

      // Create an interpolated point
      const interpolated = {
        ...current,
        users: current.users + (next.users - current.users) * ratio,
        searches: current.searches + (next.searches - current.searches) * ratio,
        links: current.links + (next.links - current.links) * ratio,
        page_views: current.page_views + (next.page_views - current.page_views) * ratio,
        formatted_date: `${current.formatted_date}-${j}`, // Mark as interpolated
      };

      result.push(interpolated);
    }
  }

  // Don't forget to add the last point from the original data
  if (data.length > 0) {
    result.push(data[data.length - 1]);
  }

  return result;
};

// Separate formatting functions for tooltip and axis
const formatDateForTooltip = (date: Date, daysNumber: number) => {
  if (daysNumber === 1) {
    const formattedTime = new Date().toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return formattedTime
  }
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit'
  });
};


const OverviewSmoothCharts = ({
  user_id,
  startDate,
  endDate,
  customCategories,
  instagramLinks,
  showSocials,
  brand_color,
  isYesterday,
  daysNumber,
}: any) => {
  instagramLinks = instagramLinks && instagramLinks.split(",");

  // Format dates to ensure they're strings
  const formattedStartDate =
    typeof startDate === "object" ? startDate.toString() : startDate;
  const formattedEndDate =
    typeof endDate === "object" ? endDate.toString() : endDate;

  const {
    loadingUniqueUsers,
    uniqueUsers,
    loadingPageViews,
    pageViews,
    loadingHashtagSearches,
    hashtagSearches,
    loadingSearchBarSearches,
    searchBarSearches,
    loadingPopularSearches,
    popularSearches,
    instagramHighlightsSearches,
    loadinginstagramHighlightsSearches,
    customCategoriesSearches,
    loadingCustomCategoriesSearches,
    loadinginstagramLinksSearches,
    instagramLinksSearches,
    loadingTotalSearchCount,
    totalSearchCount,
    totalShoppableClicks,
    loadingTotalShoppableClicks,
    pageAnalytics,
    loadingPageAnalytics,
  } = useOverviewAnalytics(
    user_id,
    startDate,
    endDate,
    customCategories,
    instagramLinks,
    isYesterday,
    daysNumber
  );


  // Ensure pageAnalytics data has formatted dates
  const formattedPageAnalytics =
    pageAnalytics?.map((item: any) => ({
      ...item,
      formatted_date:
        typeof item.formatted_date === "object"
          ? item.formatted_date.toString()
          : item.formatted_date,
    })) || [];

  // Process data for smoother curves
  const smoothedData = processDataForSmoothCurves(formattedPageAnalytics);
  // AG Charts options - simplified to match working example
  const [chartOptions, setChartOptions] = useState<any>({
    data: smoothedData,
    series: [
      {
        type: "line",
        xKey: "formatted_date",
        yKey: "users",
        yName: "Unique Users",
        stroke: "#4F46E5", // Blue color
        strokeWidth: 3,
        marker: {
          enabled: false,
          size: 6,
          fill: 'white',
          stroke: '#4F46E5', // Blue color matching the line
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params: any) => {
            const date = new Date(params.datum.formatted_date);
            const formattedDate = formatDateForTooltip(date, daysNumber);
            const value = Math.round(params.datum[params.yKey]);

            return {
              content: `
                <div class="tooltip-value">Clicks: ${value}</div>
              `
            };
          },
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
        smoothing: 0.6,
        lineDash: [0],
        interpolation: "monotoneX",
      },
      {
        type: "line",
        xKey: "formatted_date",
        yKey: "searches",
        yName: "Searches",
        stroke: "#10B981", // Green color
        strokeWidth: 3,
        marker: {
          enabled: false,
          size: 6,
          fill: 'white',
          stroke: '#10B981', // Green color matching the line
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params: any) => {
            const date = new Date(params.datum.formatted_date);
            const formattedDate = formatDateForTooltip(date, daysNumber);
            const value = Math.round(params.datum[params.yKey]);

            return {
              content: `
                <div class="tooltip-value">Clicks: ${value}</div>
              `
            };
          },
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
        smoothing: 0.6,
        lineDash: [0],
        interpolation: "monotoneX",
      },
      {
        type: "line",
        xKey: "formatted_date",
        yKey: "links",
        yName: "Shoppable Clicks",
        stroke: "#8B5CF6", // Purple color
        marker: {
          enabled: false,
          size: 6,
          fill: 'white',
          stroke: '#8B5CF6', // Purple color matching the line
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params: any) => {
            const date = new Date(params.datum.formatted_date);
            const formattedDate = formatDateForTooltip(date, daysNumber);
            const value = Math.round(params.datum[params.yKey]);

            return {
              content: `
                
                <div class="tooltip-value">Clicks: ${value}</div>
              `
            };
          },
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
        smoothing: 0.6,
        visible: false,
      },
      {
        type: "line",
        xKey: "formatted_date",
        yKey: "page_views",
        yName: "Page Views",
        stroke: "#F59E0B", // Amber color
        marker: {
          enabled: false,
          size: 6,
          fill: 'white',
          stroke: '#F59E0B', // Amber color matching the line
          strokeWidth: 2,
        },
        tooltip: {
          enabled: true,
          renderer: (params: any) => {
            const date = new Date(params.datum.formatted_date);
            const formattedDate = formatDateForTooltip(date, daysNumber);
            const value = Math.round(params.datum[params.yKey]);

            return {
              content: `
                <div class="tooltip-value">Clicks: ${value}</div>
              `
            };
          },
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
        smoothing: 0.6,
        visible: false,
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
        title: { enabled: false },
        label: {
          fontSize: 12,
          color: "#6B7280",
          rotation: 0,
          formatter: (params: any) => {
            // Skip interpolated points (they have "-1", "-2", "-3" in their dates)
            if (params.value.includes('-')) return '';
            const date = new Date(params.value);
            // For single day view, ensure consistent "12 AM" format
            // if (daysNumber === 1) {
            //   const formattedTime = new Date().toLocaleString('en-US', {
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     hour12: true
            //   });
            //   return formattedTime;
            // } else {
            const formattedTime = date.toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit'
            });
            return formattedTime;
            // }
          }
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
      },
      {
        type: "number",
        position: "left",
        title: { enabled: false },
        label: {
          fontSize: 12,
          color: "#6B7280",
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
      },
    ],
    background: {
      fill: "white",
    },
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
    legend: {
      position: "bottom",
      spacing: 40,
      item: {
        marker: {
          shape: "circle",
          size: 8,
        },
        label: {
          fontSize: 14,
          color: "#4B5563",
        },
      },
    },
    tooltipTracking: true,
    tooltipClass: "ag-chart-tooltip",
  });
  // Update chart options when data changes
  useEffect(() => {
    if (formattedPageAnalytics && formattedPageAnalytics.length > 0) {
      // Process data for smoother curves
      const smoothedData = processDataForSmoothCurves(formattedPageAnalytics);

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        data: formattedPageAnalytics,
        series: [
          {
            ...prevOptions.series[0],
            stroke: "#4F46E5", // Blue color
            strokeWidth: 3,
            marker: {
              enabled: true,
              size: 6,
              fill: 'white',
              stroke: '#4F46E5', // Blue color matching the line
              strokeWidth: 2,
            },
            smoothing: 0.6, // Increase smoothing for more curved lines
            interpolation: "monotoneX", // Use monotone interpolation for smoother curves
          },
          {
            ...prevOptions.series[1],
            stroke: "#10B981", // Green color
            strokeWidth: 3,
            marker: {
              enabled: true,
              size: 6,
              fill: 'white',
              stroke: '#10B981', // Green color matching the line
              strokeWidth: 2,
            },
            smoothing: 0.6, // Increase smoothing for more curved lines
            interpolation: "monotoneX", // Use monotone interpolation for smoother curves
          },
          {
            ...prevOptions.series[2],
            visible: true, // Hide by default to match the image with just two lines
          },
          {
            ...prevOptions.series[3],
            visible: true, // Hide by default to match the image with just two lines
          },
        ],
      }));
    }
  }, [formattedPageAnalytics, formattedStartDate, formattedEndDate]);



  return (
    <>
      <div className="loadingPageAnalytics">
        <div className="fulliv">
          {loadingPageAnalytics ? (
            <>
              <BannerSkeleton />
              <div className="skeleton-analytics-titles">
                {Array(4).fill(<SkeletonStatBox />)}
              </div>
            </>
          ) : (
            <div className="analytics">
              {/* TailGrids Styled Chart Container */}
              <div className="w-full bg-white rounded-lg shadow-md p-6 border border-gray-100 smooth-chart-container">
                <div
                  className="bg-white rounded-lg p-2"
                  style={{ height: "400px", position: "relative" }}
                >
                  <AgChartsReact
                    options={{
                      ...chartOptions,
                      // Force specific options that ensure smooth curves
                      series: chartOptions.series.map((series: any) => ({
                        ...series,
                        smoothing: 0.8, // Increase smoothing even more
                        interpolation: "cardinal", // Try cardinal interpolation for smoother curves
                        strokeWidth: 3,
                        marker: { enabled: false },
                        lineDash: [0],
                        strokeOpacity: 1,
                      })),
                      theme: {
                        palette: {
                          fills: ["#4F46E5", "#10B981", "#8B5CF6", "#F59E0B"],
                          strokes: ["#4F46E5", "#10B981", "#8B5CF6", "#F59E0B"],
                        },
                        overrides: {
                          line: {
                            series: {
                              strokeWidth: 3,
                              marker: {
                                enabled: false,
                              },
                              tooltip: {
                                enabled: true,
                              },
                            },
                          },
                        },
                      },
                      // Additional options for smoother rendering
                      animation: {
                        enabled: true,
                      },
                    }}
                  />
                </div>
              </div>

              {/* Stats Cards */}
              <div className="analytics-titles" id="page-charts">
                <StatBox
                  isLoading={loadingPageViews}
                  name="Page Views"
                  number={pageViews}
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="pageCount"
                      data-tooltip-content="Number of times your page has been visited."
                    />
                  }
                  classname="stat-page"
                  brand_color={brand_color}
                />
                <StatBox
                  isLoading={loadingUniqueUsers}
                  name="Unique Users"
                  number={uniqueUsers}
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="uniqueVisitors"
                      data-tooltip-content="Number of unique visitors who have viewed your page."
                    />
                  }
                  classname="stat-page"
                  brand_color={brand_color}
                />
                <StatBox
                  isLoading={loadingTotalSearchCount}
                  name="Total Searches"
                  number={totalSearchCount}
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="totalSearches"
                      data-tooltip-content="Total number of searches performed on your page."
                    />
                  }
                  classname="stat-page"
                  brand_color={brand_color}
                />
                <StatBox
                  isLoading={loadingTotalShoppableClicks}
                  name="Shoppable Clicks"
                  number={totalShoppableClicks}
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="shoppableClicks"
                      data-tooltip-content="Number of times visitors clicked on shoppable links."
                    />
                  }
                  classname="stat-page"
                  brand_color={brand_color}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Tooltip
        className="tool-tip"
        id="searchBarSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="hashtagSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="recentSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="popularSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="customSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip className="tool-tip" id="pageCount" place={"top"} offset={4} />
      <Tooltip
        className="tool-tip"
        id="uniqueVisitors"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="instagramHighlightsSearches"
        place={"top"}
        offset={4}
      /> */}
    </>
  );
};

export default OverviewSmoothCharts;


export function SkeletonStatBox() {
  return (
    <div className="skeleton-analytics-grid-item">
      <Skeleton style={{ height: "50px" }} />
    </div>
  );
}
{
  /* <p className="search-form">
          {" "}
          Searches Originating From{" "}
        </p>
        <div className="analytics-titles">
          <StatBox
            isLoading={loadingSearchBarSearches}
            name="Search Bar"
            number={searchBarSearches}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="searchBarSearches"
                data-tooltip-content="Number of searches made from the search bar."
              />
            }
          />
          {showSocials?.includes("instagram") &&
            StaticVariables().instagram_ANALYTICS.includes("Top Hashtags") && (
              <StatBox
                isLoading={loadingHashtagSearches}
                name="Top Hashtags"
                number={hashtagSearches}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="hashtagSearches"
                    data-tooltip-content="Number of searches made from Top Hashtags."
                  />
                }
              />
            )}
          {showSocials?.includes("instagram") &&
            StaticVariables().instagram_ANALYTICS.includes(
              "Instagram Highlights"
            ) && (
              <StatBox
                isLoading={loadinginstagramHighlightsSearches}
                name="Instagram Highlights"
                number={instagramHighlightsSearches}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="instagramHighlightsSearches"
                    data-tooltip-content="Number of times your Instagram Highlghts were viewed."
                  />
                }
              />
            )}

        

          {!customCategoriesSearches ||
          customCategoriesSearches.length === 0 ? (
            <>
              {customCategories &&
                customCategories.length > 0 &&
                customCategories.map((category: any, index: any) => (
                  <StatBox
                    key={index}
                    isLoading={true}
                    name={`${category.name}`}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customSearches"
                        data-tooltip-content={`Number of times this custom category was viewed.`}
                      />
                    }
                  />
                ))}
            </>
          ) : (
            <>
              {customCategoriesSearches &&
                customCategoriesSearches.length &&
                customCategoriesSearches.map((category: any, index: any) => (
                  <StatBox
                    key={index}
                    isLoading={category.count == null || category.count === ""}
                    name={`${category.name}`}
                    number={category.count}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customSearches"
                        data-tooltip-content={`Number of times this custom category was viewed.`}
                      />
                    }
                  />
                ))}
            </>
          )}

          <StatBox
            isLoading={loadinginstagramLinksSearches}
            name="My Links"
            
            number={instagramLinksSearches}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="instagramLinksSearches"
                data-tooltip-content="Number of times My Links were viewed."
              />
            }
          />
         
        </div> */
}
