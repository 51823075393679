import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { useState } from "react";

export function useLoadYesterdayContent(
  username: any,
  user_id: any,
  daysNumber: any,
  postIds: any,
  removedPostIds: any,
  name: any,
  isChange?: boolean,
  contentOrder?: any,
) {
  const [cachedData, setCachedData] = useState([]);

  async function getPaginatedFeed() {
    let data,
      error = null;
    const today = dayjs().format("YYYY-MM-DD");
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",")?.filter((elem: any) => elem !== ""))
      );
    }

    let allData = []; // Initialize an array to store all fetched data
    for (let num of daysNumber) {
      let numDayStart = "";
      let numDayEnd = "";
      if (num === 30) {
        // Handle last month specifically
        numDayStart = dayjs()
          .subtract(1, "month")
          .format("YYYY-MM-DD HH:mm:ss");

        numDayEnd = dayjs().format("YYYY-MM-DD HH:mm:ss");
      } else {
        numDayStart = dayjs()
          .subtract(num, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        numDayEnd =
          num === 7
            ? dayjs().format("YYYY-MM-DD HH:mm:ss")
            : dayjs()
              .subtract(num, "day")
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
      }

      const { data: content, error: contentError } = await supabase.rpc(
        "get_yesterdaycontenntte",
        {
          userid: user_id,
          user_name: username,
          start_date: numDayStart,
          end_date: numDayEnd,
          post_ids: [],
          order_type: contentOrder === "DESC" ? "true" : "false",
          // order_type:  num === 7 ? "false" : "true"
        }
      );

      if (content) {
        allData.push(...content); // Push the fetched data into the array
      }

      if (contentError) {
        error = contentError;
        // Handle errors if needed
      }
    }

    const filteredArray = allData?.filter(
      (obj) => !removedPostIds.includes(obj.post_id)
    );

    data = filteredArray;

    // Get yesterday's date
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  const queryResult = useQuery(
    ["YesterdayContent", user_id, daysNumber, removedPostIds, name, isChange, contentOrder],
    getPaginatedFeed,
    {
      enabled: Boolean(removedPostIds?.length >= 0) && Boolean(daysNumber),
      // staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data: any) => {
        setCachedData(data);
      },
      onError: () => {
        // Keep showing the cached data on error
      },
      initialData: () => cachedData,
    }
  );

  return queryResult;
}
