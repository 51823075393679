import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 * Load content
 */

export function useLoadPostIdsPosts(
  currentPage: any,
  index: string,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  usernames: any,
  userIds: any,
  isDataLoading: any,
  postIds: any[],
  showSocials:any
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    let data,
      error = null;
    let contentOrder: any = "";
    if (filter) {
      const connectingWords = StaticVariables().connectingWords;

      let words = filter.split(" ");

      let singleWords: any = [];
      if (
        words.length === 1 &&
        !StaticVariables().SEARCH_EXCEPTIONS.includes(filter)
      ) {
        const suffixRegex = /(s)$/i;
        if (suffixRegex.test(words[0])) {
          words = [`${words[0].replace(suffixRegex, "")}`];
        }
      }
      if (words.includes("shop") || words.includes("buy")) {
        contentOrder = "shoppable";
        words = words.filter((word: any) => word !== "shop" && word !== "buy");
      }
      const filteredWords = words.filter(
        (word: any) => !connectingWords.includes(word.toLowerCase())
      );
      if (words.length <= 2) {
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const { data: searchResult, error: searchError } = await supabase.rpc(
          // "semanticcc_ftsspostiddddtioee",
          "semanticcc_ftss_post_ids_content",
          {
            query_string: pgQuery,
            start_number: start,
            end_num: end,
            userid: userIds,
            username: usernames,
            socials: showSocials,
            idx: 0,
            show_ads: false,
            postids: postIds,
            index_type: index,
            content_order: contentOrder,
          }
        );
    
        data = [...searchResult];
     
      } else {
        data = [];
      }
    } else {
      if (index === "active,ad") {
        const { data: dataNoFilter, error: errorNoFilter } = await supabase.rpc(
          "get_dashboardadscontentnofiltere",
          {
            userid: userIds,
            start_number: start,
            end_num: end,
            user_name: usernames,
            index_type: index,
          }
        );

        data = dataNoFilter;
        error = errorNoFilter;
      } else {
        const { data: rpc, error: rpcError } = await supabase.rpc(
          "adssssgeneralfeeddtie",
          {
            userid: userIds,
            start_number: start,
            end_num: end,
            filter: StaticVariables().TYPES,
            query_str: "",
            user_name: usernames,
            socials: showSocials,
            idx: 0,
            show_ads: false,
            index_type: index,
          }
        );
        data = rpc;
        error = rpcError;
      }
    }

    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      // throw new Error(error);
    } else {
      throw new Error("Failed to fetch posts");
    }
    // }
  }

  return useInfiniteQuery(
    ["paginatedPosts", currentPage, index, filter, postsPerPage, isDataLoading],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },

      cacheTime: 0,
      staleTime: 0,

      enabled: Boolean(totalPages) && Boolean(isDataLoading === true),
    }
  );
}
