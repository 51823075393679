import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import TiktokContentComponent from "./Subcomponents/TiktokContentComponent";
import InstagramStoryContentComponent from "./Subcomponents/InstagramStoryContentComponent";
import YoutubeContentComponent from "./Subcomponents/YoutubeContentComponent";
import PodcastContentComponent from "./Subcomponents/PodcastContentComponent";
import ReelsContentComponent from "./Subcomponents/ReelsContentComponent";
import "../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import ThisWeekLinksComponents from "../ShopLinksComponents/SubListComponents/ThisWeekLinksComponents";
import FeaturedContentComponent from "./Subcomponents/FeaturedContentComponent";
import AddQueryContentComponents from "../../../LandingPageCarousels/QueryBasedCarousel/AddQueryContentComponents";
import AddCustomContentComponents from "../../../LandingPageCarousels/CustomCarousel/AddCustomContentComponents";
import { ModalBackButton } from "../../../../../components/EditModalComponents.tsx/ModalBackButton";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";

const ContentComponent = (props: any) => {
  const { isContentModalOpen, user_id, username, StayUpToDateComponents, from } =
    props;
  const [isTiktokContentComponentOpen, setIsTiktokContentComponentOpen] =
    useState(false);
  const [
    isInstagramStoryContentComponentOpen,
    setIsInstagramStoryContentComponentOpen,
  ] = useState(false);
  const [isYoutubeContentComponentOpen, setIsYoutubeContentComponentOpen] =
    useState(false);
  const [isPodcastContentComponentOpen, setIsPodcastContentComponentOpen] =
    useState(false);
  const [isReelsContentComponentOpen, setIsReelsContentComponentOpen] =
    useState(false);
  const [isCustomContentComponentOpen, setIsCustomContentComponentOpen] =
    useState(false);
  const [isQueryContentComponentOpen, setIsQueryContentComponentOpen] =
    useState(false);
  const [thisWeekContentComponentOpen, setThisWeekContentComponentOpen] =
    useState(false);
  const [isFeaturedContentComponentOpen, setIsFeaturedContentComponentOpen] =
    useState(false);

  function handleTiktok() {
    setIsTiktokContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleInstagramStory() {
    setIsInstagramStoryContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleYoutube() {
    setIsYoutubeContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handlePodcast() {
    setIsPodcastContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleFeatured() {
    setIsFeaturedContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleReels() {
    setIsReelsContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleCustomContent() {
    setIsCustomContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleQueryContent() {
    setIsQueryContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  function handleThisWeekContent() {
    setThisWeekContentComponentOpen(true);
    props.setIsContentModalOpen(false);
  }
  const data = [
    {
      // icon:,
      title: "Latest Instagram Stories",
      description: "Automated | Last 25 Stories",
      key: "lastest-instagram-stories",
      component: handleInstagramStory,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "instagram story"
      ),

      //   task: true,
    },
    {
      // icon:,
      title: "TikTok Posts",
      description: "Automated | Last 25 TikToks",
      key: "tiktok-posts",
      component: handleTiktok,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "tiktok"
      ),

      //   task: true,
    },
    {
      // icon:,
      title: "Latest Instagram Reels",
      description: "Automated | Last 25 Reels",
      key: "latest-instagram-reels",
      component: handleReels,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "reels"
      ),
    },
    {
      // icon:,
      title: "Latest YouTube Videos",
      description: "Automated | Last 25 Videos",
      key: "latest-youtube-videos",
      component: handleYoutube,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "youtube"
      ),
    },
    {
      // icon:,
      title: "This Week's Content",
      key: "this-weeks-content",
      description: "Automated",
      component: handleThisWeekContent,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "This Week Content"
      ),
    },
    // {
    //   // icon:,
    //   title: "Youtube",
    //   component:handleYoutube,
    //   disabled:StayUpToDateComponents?.data.some((obj :any)=> obj.display_name === 'youtube')

    // },
    {
      // icon:,
      title: "Podcast",
      description: "Automated | Last 25 Episodees",
      key: "podcast",
      component: handlePodcast,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "podcast"
      ),
    },
    {
      // icon:,
      title: "Featured",
      description: "Manual | Will show all content marked as Featured",
      key: "featured",
      component: handleFeatured,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "featured"
      ),
    },
    {
      // icon:,
      title: "Show Content Based on a Specific Word or Phrase",
      description: "Automated",
      key: "show-content-based-phrase",
      task: true,
      component: handleQueryContent,
    },
    {
      // icon:,
      title: "Custom Content Carousel",
      description: "Manual | Create a carousel using any of your content",
      key: "custom-content-carousel",
      task: true,
      component: handleCustomContent,
    },
  ];
  const handleOk = () => {
    props.setIsContentModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsContentModalOpen(false);
  };
  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button onClick={handlePrevious} className="newbutton marginR-auto">
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        data-testid="content-carousel-options"
        open={isContentModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton
          handlePrevious={handlePrevious}
          title="Content Carousel Options"
        />
        {/* <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item id={item.key}>
              <Button
                className="list-item-btn h-auto"
                type="text"
                onClick={item.component}
                disabled={item.disabled}
              >
                <div className="list-btn-text flex-direction">
                  <p>{item.title}</p>
                  <p className="item-description">{item.description}</p>
                </div>
              </Button>
            </List.Item>
          )}
        /> */}
          <div className="components-container">
          <h2
            className="components-section-header"
            style={{ marginBottom: "0" }}
          >
            Automated Carousels
          </h2>
          <div className="components-section">
            <div
              className="components-grid"
              style={{ gridTemplateColumns: "1fr" }}
            >
              {data
                .filter(
                  (item) =>
                    item.description?.includes("Automated") &&
                    !item.description?.includes("Manual")
                )
                .map((item, index) => (
                  <div
                    key={index}
                    className={`component-card ${
                      item.disabled ? "disabled" : ""
                    }`}
                    onClick={!item.disabled ? item.component : undefined}
                  >
                    <div className="component-card-inner">
                      <div
                        style={{
                          flex: 1,
                          paddingRight: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <h4
                          style={{ marginBottom: "4px", whiteSpace: "normal" }}
                        >
                          {item.title}
                        </h4>
                        {/* Only show description if it contains more info than just "Automated" */}
                        {item.description &&
                          !item.description.trim().match(/^Automated$/i) && (
                            <p
                              className="item-disc"
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                color: "#777",
                              }}
                            >
                              {item.description.replace(
                                /^Automated\s*\|?\s*/i,
                                ""
                              )}
                            </p>
                          )}
                      </div>
                      {item.task ? (
                        <button
                          className="task-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleTask(item.title);
                          }}
                        >
                          <span
                            className="component-icon task-icon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiArrowRight style={{ margin: "auto" }} />
                          </span>
                        </button>
                      ) : (
                        <span
                          className="component-icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0,
                          }}
                        >
                          <AiOutlinePlus size={16} style={{ margin: "auto" }} />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <h2
            className="components-section-header"
            style={{ marginBottom: "0", marginTop: "20px" }}
          >
            Custom Carousels
          </h2>
          <div className="components-section">
            <div
              className="components-grid"
              style={{ gridTemplateColumns: "1fr" }}
            >
              {data
                .filter((item) => item.description?.includes("Manual"))
                .map((item, index) => (
                  <div
                    key={index}
                    className={`component-card ${
                      item.disabled ? "disabled" : ""
                    }`}
                    onClick={!item.disabled ? item.component : undefined}
                  >
                    <div className="component-card-inner">
                      <div
                        style={{
                          flex: 1,
                          paddingRight: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <h4
                          style={{ marginBottom: "4px", whiteSpace: "normal" }}
                        >
                          {item.title}
                        </h4>
                        {item.description &&
                          !item.description.match(/^Manual$/i) && (
                            <p
                              className="item-disc"
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                color: "#777",
                              }}
                            >
                              {item.description.replace(
                                /^Manual\s*\|?\s*/i,
                                ""
                              )}
                            </p>
                          )}
                      </div>
                      {item.task ? (
                        <button
                          className="task-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            // handleTask(item.title);
                          }}
                        >
                          <span
                            className="component-icon task-icon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiArrowRight style={{ margin: "auto" }} />
                          </span>
                        </button>
                      ) : (
                        <span
                          className="component-icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0,
                          }}
                        >
                          <AiOutlinePlus size={16} style={{ margin: "auto" }} />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <TiktokContentComponent
        isTiktokContentComponentOpen={isTiktokContentComponentOpen}
        setIsTiktokContentComponentOpen={setIsTiktokContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <InstagramStoryContentComponent
        isInstagramStoryContentComponentOpen={
          isInstagramStoryContentComponentOpen
        }
        setIsInstagramStoryContentComponentOpen={
          setIsInstagramStoryContentComponentOpen
        }
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <YoutubeContentComponent
        isYoutubeContentComponentOpen={isYoutubeContentComponentOpen}
        setIsYoutubeContentComponentOpen={setIsYoutubeContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <PodcastContentComponent
        isPodcastContentComponentOpen={isPodcastContentComponentOpen}
        setIsPodcastContentComponentOpen={setIsPodcastContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <FeaturedContentComponent
        isFeaturedContentComponentOpen={isFeaturedContentComponentOpen}
        setIsFeaturedContentComponentOpen={setIsFeaturedContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <ReelsContentComponent
        isReelsContentComponentOpen={isReelsContentComponentOpen}
        setIsReelsContentComponentOpen={setIsReelsContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <AddCustomContentComponents
        isCustomContentComponentOpen={isCustomContentComponentOpen}
        setIsCustomContentComponentOpen={setIsCustomContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <AddQueryContentComponents
        isQueryContentComponentOpen={isQueryContentComponentOpen}
        setIsQueryContentComponentOpen={setIsQueryContentComponentOpen}
        setIsContentModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={thisWeekContentComponentOpen}
        setIsThisWeekLinksComponentOpen={setThisWeekContentComponentOpen}
        setIsShoplinksModalOpen={props.setIsContentModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        title="This Week's Content"
        description="All of your content posted in the last 7 days will always be shown in this carousel. Content will automatically be updated!"
        from={from}
      />
    </>
  );
};

export default ContentComponent;
