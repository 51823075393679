import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './EditableText.css';


const EditableText= (props:any) => {
  const {
    value,
    onSave,
    isLoading = false,
    placeholder = '',
    className = '',
    textStyle = {},
    inputStyle = {},
    textClassName = '',
    
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(props?.value);
  
  // Update input value when the component value prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  
  const handleSave = async () => {
    try {
      await onSave(inputValue);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving text:', error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setInputValue(value); // Reset to original value
    }
  };

  if (isEditing) {
    return (
      <Input
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          // props?.setErrorMsg('');
        }}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        autoFocus
        className={`editable-text-input ${className}`}
        style={{
          ...inputStyle,
        }}
      />
    );
  }

  return (
    <div
      className={`editable-text ${textClassName}`}
      onClick={() => setIsEditing(true)}
      style={{
        cursor: 'pointer',
        ...textStyle,
      }}
      title={value || placeholder}
    >
      <span style={{minHeight:'6px'}}>{value || placeholder}</span>
      {isLoading && (
        <span className="loading-indicator">
          <AiOutlineLoading3Quarters className="animate-spin" />
        </span>
      )}
    </div>
  );
};

export default EditableText; 