import { Button, Input, Spin, Switch, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { changeSearchBarText } from "../../Utils/SupabaseServices/UserDatabase";
import { BsInfoCircle, BsSearch } from "react-icons/bs";
import { updateSearchbarToggle } from "../../Utils/SupabaseServices/ControlsDatabase";
import { useLoadSearchBarTags } from "../../Utils/customHooks/useLoadSearchBarTags";
import { useRotatingPlaceholder } from "../Search/useRotatingPlaceholder";
import SearchBarTags from "./SearchBarTags";

const SearchBarText = (props: any) => {
  const {
    isLoading,
    userData,
    controls,
    controlsLoading,
    refetchControls,
    refetch,
    user_id,
    searchBarText,
    username,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const { isLoading: tagsLoading, data } = useLoadSearchBarTags(username);

  const [newSearchName, setNewSearchName] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const handleUpdate = async () => {
    await changeSearchBarText(userData?.user_id, newSearchName).then(() => {
      props.refetch();
      setIsEdit(false);
    });
  };
  const handleCancel = () => {
    setIsEdit(false);
  };
  const toggleSearchbar = async (checked: boolean) => {
    const toggle = checked;
    updateSearchbarToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  const placeholder = useRotatingPlaceholder(username, searchBarText);


  return (
    <>
      <div className="account-social-logins-heading">
        <p>Search Bar Preview </p>
      </div>
      <div className="search-container-div mt-3 w-100">
        <input
          type="text"
          id="searchInput"
          placeholder={placeholder}
          className="search-wrapper-input slidingVertical" // Add slidingVertical class
          autoComplete="off"
        />

        <button className="search-wrapper-btn">
          <BsSearch />
        </button>
      </div>
      <br/>
      <div className="account-social-logins-heading mt-10">
        <p>Search Bar Settings</p>
      </div>
      
      {/* Display preferences section in its own tile */}
      <div className="dashboard-grid-item" id="display-preferences">
        <p>
          Display Preference{" "}
          <BsInfoCircle
            className="info-tooltip"
            data-tooltip-id="showSearchbar"
          />
        </p>
        <Segmented
          options={[
            { value: true, label: 'Always Show' },
            { value: false, label: 'Show on Click' }
          ]}
          style={{
            backgroundColor: controls?.showSearchbar
              ? "#999"
              : "#2c3e50",
          }}
          value={controls?.showSearchbar}
          onChange={toggleSearchbar}
          className="custom-segmented-reverse"
        /> 
      </div>

      {/* Placeholder text section in its own tile */}
      <div className="dashboard-grid-item" id="placeholder-text">
        <p>
          Placeholder Text{" "}
          <BsInfoCircle
            className="info-tooltip"
            data-tooltip-id="placeholderText"
          />
        </p>
        
        {isLoading ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {userData?.name && (
              <div className="account-social-link-connect-row pb-0">
                <div className="account-social-link-connect-row-cont searchbar w-100">
                  {isEdit ? (
                    <Input
                      value={newSearchName}
                      onChange={(e: any) =>
                        setNewSearchName(e.target.value)
                      }
                    />
                  ) : (
                    <p className="m-1 m-sm-0 searchbar-placeholder-text">
                      {userData?.search_bar_text
                        ? userData?.search_bar_text
                        : `Search ${userData?.name}'s Page`}
                    </p>
                  )}
                  <>
                    {!isEdit ? (
                      <Button
                        className="ant-btn-default accountButton"
                        style={{ marginLeft: '1rem', padding: '0.5rem 1rem' }}
                        onClick={() => {
                          setNewSearchName(
                            userData?.search_bar_text
                              ? userData?.search_bar_text
                              : `Search ${userData?.name}'s Page`
                          );
                          setIsEdit(true);
                        }}
                      >
                        Change
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="ant-btn-default accountButton"
                          style={{ marginLeft: '1rem', padding: '0.5rem 1rem' }}
                          onClick={() => {
                            handleUpdate();
                          }}
                          disabled={!newSearchName}
                        >
                          Save
                        </Button>
                        <Button
                          className="ant-btn-default accountButton"
                          style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem' }}
                          onClick={() => {
                            handleCancel();
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Suggestions section in its own tile */}
      <div className="dashboard-grid-item" id="search-suggestions">
        <p>
          Suggestions{" "}
          <BsInfoCircle
            className="info-tooltip"
            data-tooltip-id="searchSuggestions"
          />
        </p>
        
        <SearchBarTags
          searchBarText={
            userData?.search_bar_text
              ? userData?.search_bar_text
              : `Search ${userData?.name} Content`
          }
          username={userData?.username}
          isLoading={isLoading}
          userData={userData}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default SearchBarText;