import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { useState } from "react";
import { getAllDatesFromSelection } from "../../StayUpToDate/RenderComponent";

export function useLoadYesterdayLinks(
  username: any,
  user_id: any,
  daysNumber: any,
  postIds: any,
  removedPostIds: any,
  name: any,
  isChange?: boolean,
  order?: string,
  selectedDays?: any,
  componentType?: any
) {
  const [cachedData, setCachedData] = useState([]);

  async function getPaginatedFeed() {
    let data,
      error = null;
    const today = dayjs().format("YYYY-MM-DD");

    // Improve removedPostIds handling
    const removedPostIdsArray = removedPostIds
      ? Array.from(
          new Set(removedPostIds.split(",").filter((elem: any) => elem !== ""))
        )
      : [];

    let allData = []; // Initialize an array to store all fetched data
    let numDayStart = "";
    let numDayEnd = "";
    if (componentType === "daywise") {
      const updatedDates = getAllDatesFromSelection(selectedDays);
      if (updatedDates.length === 1) {
        numDayStart = dayjs(updatedDates[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        numDayEnd = dayjs(updatedDates[0])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        numDayStart = dayjs(
          Math.min(...updatedDates.map((date) => dayjs(date).valueOf()))
        )
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        numDayEnd = dayjs(
          Math.max(...updatedDates.map((date) => dayjs(date).valueOf()))
        )
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      for (let num of daysNumber) {
        if (num === 30) {
          // Handle last month specifically
          numDayStart = dayjs()
            .subtract(1, "month")
            .format("YYYY-MM-DD HH:mm:ss");

          numDayEnd = dayjs().format("YYYY-MM-DD HH:mm:ss");
        } else {
          numDayStart = dayjs()
            .subtract(num, "day")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss");
          numDayEnd =
            num === 7
              ? dayjs().format("YYYY-MM-DD HH:mm:ss")
              : dayjs().format("YYYY-MM-DD HH:mm:ss");
        }
      }
    }

    const { data: content, error: contentError } = await supabase.rpc(
      "get_yesterdaycontentshopppooe",
      {
        userid: user_id,
        user_name: username,
        start_date: numDayStart,
        end_date: numDayEnd,
        post_ids: [],
        order_type: order === "DESC" ? "true" : "false",
        // order_type: num === 7 ? "false" : "true",
      }
    );

    if (content) {
      allData.push(...content); // Push the fetched data into the array
    }

    if (contentError) {
      error = contentError;
      // Handle errors if needed
    }

    // Ensure unique posts by post_id and filter out removed posts
    const uniqueFilteredArray = Array.from(
      new Map(allData.map((item) => [item.post_id, item]))
    )
      .map(([_, item]) => item)
      .filter((obj) => {
        // Only filter out if it's in removedPostIds AND not a product
        // return !(removedPostIdsArray.includes(obj.post_id) && obj.type !== "PRODUCT");
        return !removedPostIdsArray.includes(obj.post_id);
      });

    data = uniqueFilteredArray;

    // Get yesterday's date
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  const queryResult = useQuery(
    [
      "YesterdayPosts",
      user_id,
      daysNumber,
      removedPostIds,
      name,
      isChange,
      order,
      selectedDays,
      componentType,
    ],
    getPaginatedFeed,
    {
      enabled: Boolean(removedPostIds?.length >= 0) && Boolean(daysNumber),
      // staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data: any) => {
        setCachedData(data);
      },
      onError: () => {
        // Keep showing the cached data on error
      },
      initialData: () => cachedData,
    }
  );

  return queryResult;
}
