import { Modal } from "antd";
import React from "react";
import { supabase } from "../../../config/supabaseClient";

const ProductBankDeleteModal = (props: any) => {
  const {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    post_id,
    user_id,
    username,
    refetchData,
    refetchCount,
    linkFilterRefetch,
    setSelectedProductLnikFilter,
  } = props;
  async function deletePost(post_id: any) {
    const { data: upd } = await supabase
      .from("product_bank")
      .delete()
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    //revisit
    await supabase
      .from(username)
      .delete()
      .eq("post_id", `${post_id}`)
      .eq("user_id", user_id);

    await supabase
      .from("analytics")
      // .select("*")
      .delete()
      .eq("user_id", user_id)
      .ilike("details", `%${post_id}%`);

    refetchCount();
    refetchData();
    linkFilterRefetch();
    setSelectedProductLnikFilter("AllLinks");
    setIsDeleteModalOpen(false);
  }
  const handleCancel = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Delete Product"
        open={isDeleteModalOpen}
        onOk={() => deletePost(post_id)}
        onCancel={handleCancel}
        okText="Yes"
        data-testid="delete-product"
      >
        <p>Are you sure you want to permanently delete this product?</p>
      </Modal>
    </>
  );
};

export default ProductBankDeleteModal;
