import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { updateComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselOrderControl from "../../../../components/CarouselControls/CarouselOrderControl";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";
import ProductName from "../../../../components/ProductName/ProductName";
import CarouselControlWrapper from "../../../../components/CarouselControls/CarouselControlWrapper";
import LoadingPost from "../../../../components/skeleton/Views/LoadingPost";
import { updateNameValues } from "../../../../Utils/StayUpToDate/RenderComponent";
import CarouselFilterControl from "../../../../components/CarouselControls/CarouselFilterControl";
import EditableText from "../../../../components/EditModalComponents.tsx/EditableText";
import PostCardComponent from "../../../../components/EditModalComponents.tsx/PostCardComponent";

const EditQueryBasedCarousel = (props: any) => {
  const {
    username,
    user_id,
    isQueryLinksEditModalOpen,
    setIsQueryLinksEditModalOpen,
    type,
    component,
    refetchDashboard,
    initial,
    setInitial,
    from
  } = props;

  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  const [isChange, setIsChange] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [shape, setShape] = useState("rect");
  const [activeFilter, setActiveFilter] = useState("both");
  const [isSoundOn, setIsSoundOn] = useState();
  const [isActionLoading, setIsActionLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editIndex, setEditIndex] = useState("");
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
    setOrder(component?.content_order);
    setShape(component?.content_shape);
    setIsSoundOn(component?.content_sound);
    setActiveFilter(component?.shoppable_content_filter || "both");
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };


  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : StaticVariables().PLATFORMS;

  const isShopLinks = (component.type === "query-content") ? false : true;

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",

      removePostIds,
      order,
      shape,
      isSoundOn,
      activeFilter,
      from
    );

    if (res === "true") {
      // props.refetch();
      refetchDashboard();
      props.setIsQueryLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);

      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      setIsChange(false);
      setEdit(false);
      setEditIndex("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setEdit(false);
      setEditIndex("");
    }
  };

  const handleCancel = () => {
    props.setIsQueryLinksEditModalOpen(false);
    props.setSelectedComponent("");
    refetchDashboard();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEdit(false);
    setEditIndex("");
    setActiveFilter("both");
  };

  async function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);

        return idsArray.join(",");
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(",");
      });
    }
  }
  return (
    <>
      <Modal
        open={isQueryLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="edit-query-carousel"
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.query && component?.query !== "" && (
            <p className="text-center">Content shown based on "{component?.query}"</p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            setIsChange={setIsChange}
            isChange={isChange}
            component={component}
            order={order}
            shape={shape}
            isSoundOn={isSoundOn}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            activeFilter={activeFilter.toLowerCase()}
            showSocials={showSocials}
            isShopLinks={isShopLinks}
            name={name}
            setName={setName}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
          />
          <div className="carousel-controls-wrapper">
          <div className="addedContent">
          <Title level={3}>Carosuel Control</Title>
          {isShopLinks && <CarouselFilterControl
            type={type}
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter.toLowerCase()}
            setCurrentPage={() => { }}
            username={username}
            user_id={user_id}
            query={defaultQuery}
            POSTS_PER_PAGE={10}
            showSocials={showSocials}
            isShopLinks={isShopLinks}
            order={order}
            componentName={name}
          />}

          <CarouselControlWrapper
            showShape={true}
            showSound={true}
            showFilter={false}
            showOrder={true}
            shape={shape}
            setShape={setShape}
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
            type={type}
            order={order}
            setOrder={setOrder}
          />
          </div>
          </div>
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    query,
    setIsChange,
    isChange,
    component,
    order, shape, isSoundOn,
    // edit, editIndex, setEdit, setEditIndex
    edit, editIndex, setEdit, setEditIndex, isActionLoading, setIsActionLoading,
    activeFilter,
    showSocials,
    isShopLinks,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  const [localPosts, setLocalPosts] = useState<any[]>([]);

  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);


  const contentOrder = "Default";

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,
    showSocials,
    contentOrder,
    isShopLinks,
    deletePostIds,
    componentName,
    order
  );

  useEffect(() => {
    refetch()
  }, [activeFilter]);

  useEffect(() => {

    const showSocial = activeFilter === "both"
      ? StaticVariables().PLATFORMS
      : activeFilter === "content"
        ? StaticVariables().CONTENT_PLATFORMS
        : activeFilter === "products"
          ? StaticVariables().PRODUCT_PLATFORMS
          : StaticVariables().PLATFORMS;

    if (activeFilter) {
      const filteredArray = (data || [])
        .filter((element: any) => element !== undefined)
        .filter((item: any) => showSocial.includes(item.platform));
      setLocalPosts(filteredArray)
    }
    else if (data) {
      const filtered = data
        .filter((element: any) => element !== undefined)
        .filter((obj: any) => {
          const deleteIdsArray = deletePostIds?.split(",") || [];
          return !deleteIdsArray.includes(obj.post_id);
        });
      setLocalPosts(filtered);
    }
  }, [data, deletePostIds, activeFilter]);

  const onMinus = async (post_id: string) => {
    setLocalPosts(prev => prev.filter(post => post.post_id !== post_id));

    setDeletePostIds((prev: any) => {
      const idsArray = prev.split(",").filter(Boolean);
      idsArray.push(post_id);
      return idsArray.join(",");
    });

    await props.handleRemovedPostIds(post_id, "minus");
    refetch();
  };
  const [value, setValue] = useState<string>();
  // const [edit, setEdit] = useState(false);
  // const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string, newValue: string ) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);
   

    await updateNameValues(username, type, post_id, user_id, newValue);

    setIsChange(true);

    setEdit(false);
    setEditIndex("");
    refetch();
    refetchDashboard();
  };


  return (
    <>
      <div className="addedContent">
      <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            if (!newValue.trim()) {
              setErrorMsg("Title cannot be empty");
              return;
            }
            setErrorMsg("");
            setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={isActionLoading && editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />
        <div className="addedSvg">
          {localPosts.length > 0 ? (
            localPosts.map((post: any, index: any) => (
              <React.Fragment key={post.post_id}>
                <div className="feed-tab-post-container added-post">
                  {/* <div className="feed-tab-media-wrapper">
                    <Button
                      onClick={() => onMinus(post.post_id)}
                      className="carousel-content-remove-btn"
                    >
                      Remove
                    </Button>
                    {post?.platform === "newsletter" ? (
                      <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`}
                      // style={{backgroundColor: "black", color: "white"}}
                      >
                        <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "linkedin" && !post?.media_url ? (
                      <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`}
                      // style={{backgroundColor: "black", color: "white"}}
                      >
                        <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "twitter" && !post?.media_url ? (
                      <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`}
                      // style={{backgroundColor: "black", color: "white"}}
                      >
                        <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">X Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-grid' : ''}`}>
                        {(post?.product_image && isShopLinks) ? (
                          <div
                            className={`${post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                              }`}
                          >
                            <img
                              className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                              src={post?.product_image}
                              alt="post"
                            />
                          </div>
                        ) :
                          post?.media_url ? (
                            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
                              {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              post.thumbnail &&
                                                post.thumbnail.split(",")
                                                  .length &&
                                                post.thumbnail.split(",")[
                                                index
                                                ]
                                                ? post.thumbnail.split(
                                                  ","
                                                )[index]
                                                : "/assets/dummy_video.png"
                                            }
                                            content_shape={shape}
                                            isSoundOn={isSoundOn}
                                          />
                                        ) : (
                                          <img
                                            className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image mb-2' : 'feed-tab-media-check mb-2'}`}
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : 'h-255'}`}>
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                        content_shape={shape}
                                        isSoundOn={isSoundOn}
                                      />
                                    </div>
                                  ) : (

                                    <img
                                      className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />

                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
                              <img
                                className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            </div>
                          )}
                      </div>
                    )}
                  </div> */}
                  <PostCardComponent
                    post={post}
                    type={type}
                    isShopLinks={isShopLinks}
                    shape={shape}
                    isSoundOn={isSoundOn}
                    onMinus={onMinus}
                    from="added"
                  />
                   <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          setIsActionLoading(false);
                        }
                      }}
                      isLoading={isActionLoading && editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                </div>
              </React.Fragment>
            ))
          ) : isLoading ? (
            <div className="feed-tab-post">
              {[...Array(4)].map((_, index) => (
                <LoadingPost key={index} />
              ))}
            </div>
          ) : (
            <div className="feed-tab-post">
              {[...Array(4)].map((_, index) => (
                <LoadingPost key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditQueryBasedCarousel;
