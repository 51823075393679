import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSidebar } from '../../context/SidebarContext';
import './SlidingSidebar.css';

interface SlidingSidebarProps {
    children: React.ReactNode;
    type?: 'follower' | 'dashboard';
}

const SlidingSidebar: React.FC<SlidingSidebarProps> = ({ children, type = 'follower' }) => {
    const { isOpen, closeSidebar } = useSidebar();
    const location = useLocation();
    // Only show sliding sidebar in follower view
    if (!location.pathname.includes('follower')) {
        return <>{children}</>;
    }

    return (
        <>
            {/* {isOpen && (
                <div className="sliding-sidebar-overlay" onClick={closeSidebar} />
            )} */}
            <div className={`sliding-sidebar ${isOpen ? 'open' : ''} ${type}`}>
                <div className="sliding-sidebar-content">
                    {children}
                </div>
            </div>
        </>
    );
};

export default SlidingSidebar; 