import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import {
  BsFillPlayFill,
  BsHourglassSplit,
  BsVolumeMute,
  BsVolumeUp,
} from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";
import ProductName from "../ProductName/ProductName";
import ProductShopButton from "../ProductName/ProductShopButton";
import { redirectToUrl } from "../../Utils/StayUpToDate/RenderComponent";

const VideoPostTag = (props: any) => {
  const {
    post,
    title,
    handleRedirect,
    isLinks,
    brand_color,
    isDashboard,
    isYoutube,
    isHasMediaUrl,
    thumbnail,
    productName,
    type,
    isCarousel,
    media_url,
    contentShape,
    isSoundOn,
  } = props;

  // const redirectUrl = !isLinks
  //   ? post?.permalink || post?.shop_link || post?.table_url
  //   : post?.shop_link || post?.table_url || post?.permalink;
  const redirectUrl:any=redirectToUrl(post,isLinks)
  return (
    <>
      <a
        href={redirectUrl}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          handleRedirect(
            post?.post_id,
            post?.media_url.includes("video")
              ? post?.product_image
                ? post?.product_image
                : post?.thumbnail
              : post?.product_image
                ? post?.product_image
                : post?.media_url,
            title,
            post?.product_name,
            post?.platform,
            isLinks
          );
          if (
           redirectUrl
          ) {
            window.open(redirectUrl, "_blank");
          }
        }}
      >
        <VideoTag
          media_url={isCarousel ? media_url : post?.media_url}
          post={post}
          thumbnail={thumbnail ? thumbnail : "/assets/dummy_video.png"}
          brand_color={brand_color}
          isYoutube={isYoutube}
          handleRedirect={handleRedirect}
          title={title}
          isLinks={isLinks}
          isHasMediaUrl={isHasMediaUrl}
          productName={productName}
          type={type}
          contentShape={contentShape}
          isSoundOn={isSoundOn}
          isCarousel={isCarousel}
        />

        {/* <ProductShopButton isLinks={isLinks} isIndividual={false} type={type} post={post} /> */}
      </a>
    </>
  );
};

const VideoTag = (props: any) => {
  const {
    media_url,
    brand_color,
    post,
    isYoutube,
    thumbnail,
    title,
    isLinks,
    isHasMediaUrl,
    productName,
    type,
    contentShape,
    isSoundOn,
    // isCarousel
    isCarousel,
  } = props;
  const videoRef: any = useRef();
  const [hover, setHover] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [customPause, setCustomPause] = useState(false);
  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);

  const getWrapperClassName = () => {
    const baseClass = "creator-feed-image-wrapper-links";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("square-wrapper");
    else if (contentShape) classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");
    return classes.join(" ");
  };

  const getVideoClassName = () => {
    const baseClass = "creator-feed-card-links-image";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("media-feed-height");
    else if (contentShape) classes.push("square-video");
    else if (isYoutube) classes.push("youtube-feed-height");

    return classes.join(" ");
  };

  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);
  useEffect(() => {
    videoRef.current.playsInline = true;
  }, []);

  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>
        {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
      </>
    );
  };
  

  const toggleMute = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from triggering parent events
    e.preventDefault();
    setIsMuted((prev) => !prev);
  };

  return (
    <>
      <div className={getWrapperClassName()}>
        {!Boolean(media_url) ? (
          <div>
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            muted={isSoundOn ? isMuted : true}
            className={getVideoClassName()}
            style={{
              backgroundColor: brand_color,
              display: "block",
              marginBottom: "2%",
            }}
            preload={"metapost"}
            contextMenu="return false;"
            hoverOverlay={
              <div className="video-hover">
                {isSoundOn && (
                  <div
                    className="sound-icon"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      zIndex: 10,
                    }}
                    onClick={toggleMute}
                  >
                    {isMuted ? (
                      <BsVolumeMute
                        size={24}
                        color="white"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <BsVolumeUp
                        size={24}
                        color="white"
                        style={{ width: "100%", height: "100%" }}
                      />
                    )}
                  </div>
                )}
              </div>
            }
            pausedOverlay={
              <div
                style={{ position: "relative" }}
                className={getVideoClassName()}
              >
                <img
                  src={
                    thumbnail && thumbnail.length > 0
                      ? thumbnail
                      : "/assets/dummy_video.png"
                  }
                  alt=""
                  className={getVideoClassName()}
                  loading="eager"
                />

                <OverlayStatus
                  hover={hover}
                  showClickablePlayBtn={showClickablePlayBtn}
                />
              </div>
            }
            loadingOverlay={
              <img
                src={
                  thumbnail && thumbnail.length > 0
                    ? thumbnail
                    : "/assets/dummy_video.png"
                }
                alt=""
                className={getVideoClassName()}
                loading="eager"
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />
        )}
       
      </div>
      {!isCarousel && (
          <>
            <ProductName type={type} post={post} />
            <ProductShopButton isLinks={isLinks} IsIndividual={false} type={type} post={post} />
            </>
        )}
    </>
  );
};

export default VideoPostTag;
