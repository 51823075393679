import { Button, Segmented, Spin, Table } from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { BannerSkeleton } from "../../../../../components/StayUpToDateComponent/BannerSkeleton";
import { SkeletonStatBox } from "../../Overview/OverviewSmoothCharts";
import "./../../Analytics.css"; // Make sure to import the analytics css

const SocialCharts = (props: any) => {
  const { socialLinksAnalytics, loadingSocialLinksAnalytics, brand_color } =
    props;
  const totalCount = socialLinksAnalytics?.reduce(
    (accumulator: any, currentValue: any) => {
      return accumulator + currentValue.clicks;
    },
    0
  );

  const columns = [
    {
      title: "Platform",
      dataIndex: "social",
      key: "social",
      render: (text: string, record: any) => (
        <div className="platform-column">
          <span className="platform-icon">{record.index.charAt(0)}</span>
          <a href={record.link} target="_blank" rel="noopener noreferrer" className="platform-link">
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      align: "right" as "right",
      render: (clicks: number) => (
        <div className="clicks-value">{clicks}</div>
      ),
    },
  ];
  
  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="tooltip-title">{data.social}</p>
          <p className="tooltip-value">
            <span className="tooltip-label">Clicks:</span> {data.clicks}
          </p>
        </div>
      );
    }

    return null;
  };
  
  const CustomLegend = () => null; // Remove the legend as it's redundant

  return (
    <>
      <div className="analytics-section">
        {loadingSocialLinksAnalytics ? (
          <>
            <BannerSkeleton />
            <div className="skeleton-analytics-titles">
              {Array(4).fill(<SkeletonStatBox />)}
            </div>
          </>
        ) : (
          <div>
            {totalCount > 0 ? (
              <>
                <div className="stats-summary">
                  <div className="stats-card">
                    <h3 className="stats-title">Total Clicks</h3>
                    <div className="stats-value">{totalCount}</div>
                  </div>
                </div>
                
                <div className="chart-container">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={socialLinksAnalytics}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                      }}
                      barSize={40}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f2" />
                      <XAxis 
                        dataKey="index" 
                        axisLine={false}
                        tickLine={false}
                        stroke="#888"
                        fontSize={12}
                        tickMargin={10}
                      />
                      <YAxis 
                        axisLine={false}
                        tickLine={false}
                        stroke="#888" 
                        fontSize={12}
                        width={40}
                      />
                      <Tooltip 
                        content={renderCustomTooltip} 
                        cursor={{fill: 'rgba(0, 0, 0, 0.05)'}} 
                      />
                      <Bar 
                        dataKey="clicks" 
                        fill="#42b0ba" 
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                
                <h3 className="breakdown-title">Social Links Breakdown</h3>
                
                <Table
                  columns={columns}
                  dataSource={socialLinksAnalytics}
                  rowKey="social"
                  pagination={false}
                  className="analytics-modern-table"
                  rowClassName="analytics-table-row"
                />
              </>
            ) : (
              <div className="empty-state-container" style={{ textAlign: 'center', padding: '24px', backgroundColor: '#f5f5f7', borderRadius: '12px', margin: '16px 0' }}>
                <h5 style={{ fontWeight: "500", fontSize: '16px', color: '#111827', marginBottom: '8px' }}>
                  No data available
                </h5>
                <p style={{ color: '#6B7280', fontSize: '14px', maxWidth: '280px', margin: '0 auto' }}>
                  Your <b>Social Icons</b> haven't received any clicks in the selected time period yet.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SocialCharts;
