import { BsFillPlayFill, BsLink45Deg } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  handleRedirect,
  handleShop,
  handleTagged,
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./TiktokPost.css";
import Caption from "../Caption";
import { useState } from "react";
import React, { useEffect, useRef } from "react";
import { BsHourglassSplit, BsInstagram } from "react-icons/bs";

import HoverVideoPlayer from "react-hover-video-player";
import { useAuth } from "../../../../hooks/useAuth";
import { updateRefClick } from "../../../../Utils/SupabaseServices/ContentDatabase";
import { IoLogoTiktok } from "react-icons/io5";
import Card from "react-bootstrap/Card";
import "../CommentsPost/CommentsPost.css"
import PostInfo from "../../PostInfo/PostInfo";

export type TiktokPostType = Post & {
  platform: "tiktok";
  metadata: {
    ep_description: string;
    ep_thumbnail: string;
    ep_summary: string;
    ep_keywords: string;
  };
};

export default function TiktokPost({
  post,
  showCaption,
  shoppableContent,
  user_id,
  showAsAd,
  platform,
  username
}: {
  post: TiktokPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id:any;
  showAsAd:any,
  platform:any,
  username:any
}) {
  const location = useLocation();
  const {  post_date, post_id, permalink,shop_link,tagged_link,external_link } = post;
  // const { user } = useAuth();
  // const user_id = user?.id || "";
 

  const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);

  useEffect(() => {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      setShowClickablePlayBtn(true);
    }
  }, []);

  const OverlayStatus = ({
    hover,
    showClickablePlayBtn,
  }: {
    hover: boolean;
    showClickablePlayBtn: boolean;
  }) => {
    return (
      <>{!hover && <BsFillPlayFill className="creator-feed-card-play-btn" />}</>
    );
  };

  const VideoTag = (props: any) => {
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [customPause, setCustomPause] = useState(false);
    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const {
      media_url,
      showAsAd,
      post_date,
      permalink,
      post_id,
      username,
      thumbnail,
    } = props;
    return (
      <>
        {!Boolean(media_url) ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <HoverVideoPlayer
              videoRef={videoRef}
              videoSrc={media_url}
              className="creator-feed-card-video hover-play"
              muted
              preload={"metadata" || "auto"}
              contextMenu="return false;"
              hoverOverlay={
                <div
                  className="video-hover"
                ></div>
              }
              pausedOverlay={
                <div className="pause-overlay">
                  <img
                    src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                    alt=""
                    className="creator-feed-card-video"
                    loading="eager"
                  />

                  <OverlayStatus
                    hover={hover}
                    showClickablePlayBtn={showClickablePlayBtn}
                  />
                </div>
              }
              loadingOverlay={
                <img
                  src={thumbnail ? thumbnail : "/assets/dummy_video.png"}
                  alt=""
                  className="creator-feed-card-video"
                  loading="eager"
                />
              }
              unloadVideoOnPaused
              onHoverStart={() => {
                setHover(true);
              }}
              onHoverEnd={() => {
                setHover(false);
                setCustomPause(false);
              }}
              disableDefaultEventHandling={customPause}
            />

            <RefTag
              showAsAd={showAsAd}
              post_date={post_date}
              permalink={permalink}
              post_id={post_id}
              username={username}
            />
          </div>
        )}
      </>
    );
  };
  const RefTag = ({
    media_url,
    showAsAd,
    post_date,
    permalink,
    post_id,
    username,
  }: any) => {
    return (
      // <div className="creator-feed-content-info">
      //   {showAsAd !== true ? (
      //     <>
      //       {new Date(post_date).getFullYear() > 2000 ? (
      //         <p className="creator-feed-content-date">
      //           {new Intl.DateTimeFormat("en-US", {
      //             year: "numeric",
      //             day: "numeric",
      //             month: "long",
      //           }).format(new Date(post_date))}
      //         </p>
      //       ) : (
      //         ""
      //       )}
      //       <div className="creator-feed-post-links">
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <BsLink45Deg
      //               className="bs-link"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <IoLogoTiktok
      //               className="bs-link"
      //               id="tiktok-icon"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           <IoLogoTiktok
      //             className="ml-6"
      //             id="tiktok-icon"
      //           />
      //         )}

      //         {username && <p className="creator-feed-username">@{username}</p>}
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       <p className="creator-feed-content-date">Sponsored</p>
      //       <div className="creator-feed-post-links">
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <BsLink45Deg
      //               className="bs-link"
      //               id="tiktok-icon"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <IoLogoTiktok
      //               className="bs-link"
      //               id="tiktok-icon"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id, media_url)}
      //             />
      //           </a>
      //         ) : (
      //           <IoLogoTiktok
      //             className="ml-6"
      //             id="tiktok-icon"
      //           />
      //         )}

      //         {username && <p className="creator-feed-username">@{username}</p>}
      //       </div>
      //     </>
      //   )}
      // </div>
      <PostInfo
      username={username}
      user_id={user_id}
      showAsAd={showAsAd}
      post_date={post_date}
      permalink={permalink}
      shop_link={shop_link}
      tagged_link={tagged_link}
      external_link={external_link}
      post_id={post_id}
      handleRedirect={handleRedirect}
      handleShop={handleShop}
      handleTagged={handleTagged}
      platform={platform}
      media_url={media_url}
      shoppableContent={shoppableContent}
    />
    );
  };

  const ImagePostTag = React.memo((props: any) => {
    const { media_url, showAsAd, post_date, permalink, post_id, username } =
      props;

    return (
      <>
        {media_url === "" ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit
              className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div>
            <Card.Img
              variant="top"
              src={media_url}
              className="creator-feed-card-video"
              loading="eager"
            />
            <RefTag
              media_url={media_url}
              showAsAd={showAsAd}
              post_date={post_date}
              permalink={permalink}
              post_id={post_id}
              username={username}
            />
          </div>
        )}
      </>
    );
  });

  return (
    <div>
      <div className="tiktok-post-img-wrp">
        <>
          {post.media_url.includes("video") ? (
            <VideoTag
              media_url={post.media_url}
              showAsAd={post.showAsAd}
              post_date={post.post_date}
              permalink={post.permalink}
              post_id={post.post_id}
              thumbnail={
                post.thumbnail ? post.thumbnail : "/assets/dummy_video.png"
              }
            />
          ) : (
            <ImagePostTag
              media_url={post.media_url}
              showAsAd={post.showAsAd}
              post_date={post.post_date}
              permalink={post.permalink}
              post_id={post.post_id}
            />
          )}
        </>
      </div>

      {post?.caption && <Caption caption={post.caption} />}
    </div>
  );
}
