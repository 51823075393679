import { useEffect, useState } from "react";
import "./StayUpToDateComponentsWrapper.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Modal } from "antd";
import {
  deleteComponent,
  updateIndexValue,
} from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import StayUpToDateSkeleton from "./StayUpToDateSkeleton";
import SubComponentsWrapper from "../../LandingPageCarousels/SubComponentsWrapper/SubComponentsWrapper";

const StayUpToDateComponentsWrapper = (props: any) => {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color,
    refatchStayUpToDate,
  } = props;
  const [localData, setLocalData] = useState(StayUpToDateComponents);
  useEffect(() => {
    // refetch();
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents]);

  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [status, setStatus] = useState("");
  useEffect(() => {
    refetch();
  }, [status, StayUpToDateComponents]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateIndexValue(user_id, newData);
  };
  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }

  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch,
      username
    ).then(() => {
      setTimeout(() => {
        setLocalData(filteredData);
      }, 10);
      setTimeout(() => {
        setConfirmLoading(false);
        setOpenDeleteModal(false);
      }, 50);
    });
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };
  return (
    <div className="stay-up-to-date-wrapper">
      {StayUpToDateComponentsLoading ||
      (localData && localData.length === 0) ? (
        <div className="loader-container">
          <div className="component-box">
            <StayUpToDateSkeleton />
          </div>
        </div>
      ) : (
        <>
          <DragDropContext
            onDragStart={() => {
              document.documentElement.style.scrollBehavior = "unset";
              document.body.style.scrollBehavior = "unset";
            }}
            onDragEnd={(result: any) => {
              onDragEnd(result);
            }}
          >
            <Droppable droppableId="element">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {localData &&
                    localData.map((component: any, index: any) => (
                      // <div key={index}>
                      <Draggable
                        key={component.index?.toString()}
                        draggableId={component.index?.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {" "}
                            <div key={component.id} className="component-box">
                              <SubComponentsWrapper
                                user_id={user_id}
                                username={username}
                                component={component}
                                refetch={refetch}
                                brand_color={brand_color}
                                handleDelete={handleDelete}
                                refatchStayUpToDate={refatchStayUpToDate}
                                setStatus={setStatus}
                                selectedComponent={selectedComponent}
                                setSelectedComponent={setSelectedComponent}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                isEditModalOpen={isEditModalOpen}
                                setIsEditModalOpen={setIsEditModalOpen}
                                from="landing-page"
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            data-testid="delete-carousel"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default StayUpToDateComponentsWrapper;
