import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import CheckboxComponent from "../../../../../../components/CheckboxComponent/DaysCheckboxComponent";
import PreviewComponent from "./PreviewComponent";

const DayWiseLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isDayWiseLinksComponentOpen,
    StayUpToDateComponents,
    from,
  } = props;
  const [daysNameArray, setDaysNameArray] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleOk = async () => {
    setConfirmLoading(true);

    const res = await addComponent(
      `${daysNameArray.join(" & ")} Shoppable Content`,
      "",
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      daysNameArray.toString(),
      "daywise",
      [],
      "ASC",
      "rect",
      false,
      "both",
      from
    );
    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsDayWiseLinksComponentOpen(false);
      setDaysNameArray([]);
      setErrorMsg("");
      setConfirmLoading(false);
    } else {
      setErrorMsg("Carousel with this combination of day(s) already exists.");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsDayWiseLinksComponentOpen(false);
    setDaysNameArray([]);
    setErrorMsg("");
  };
  const handlePrevious = () => {
    props.setIsDayWiseLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
    setDaysNameArray([]);
    setErrorMsg("");
  };
  const handleDays = (daysName: any) => {
    setDaysNameArray(daysName);
  };
  {
  }

  return (
    <>
      <Modal
        title={null}
        open={isDayWiseLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="shop-by-day"
        okButtonProps={{
          disabled: !daysNameArray || daysNameArray.length === 0,
        }}
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <button 
          onClick={handlePrevious} 
          style={{ 
            background: 'none', 
            border: 'none', 
            cursor: 'pointer',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#333',
            transition: 'color 0.2s',
            borderRadius: '4px',
            position: 'absolute',
            top: '12px',
            left: '20px',
            zIndex: 1001
          }}
          onMouseOver={(e) => e.currentTarget.style.color = '#000'}
          onMouseOut={(e) => e.currentTarget.style.color = '#333'}
        >
          <FaArrowLeft size={16} />
        </button>
        
        <h2 className="modal-title" style={{ marginTop: '25px', textAlign: 'center' }}>Shoppable Content by the Day</h2>
        
        {errorMsg && (
          <p
            className="errorMsg"
            style={{ margin: "auto", textAlign: "center", marginBottom: "4%", color: "#ff4d4f" }}
          >
            {errorMsg}
          </p>
        )}

        <div style={{ margin: '15px 0' }}>
          <p style={{ textAlign: "left", fontSize: '14px', lineHeight: '1.5', marginBottom: '12px' }}>
            All of your shoppable content posted on the day(s) selected below will
            always be shown in this carousel. Content will automatically be
            updated!
          </p>
          <p style={{ textAlign: "left", fontSize: '14px', lineHeight: '1.5', marginBottom: '24px' }}>
            This is a great way to highlight content for a specific day! For
            example, if you post popular content from the week every Friday,
            selecting Friday below will automatically show Friday's content every
            week in this carousel.
          </p>
        </div>

        <CheckboxComponent
          StayUpToDateComponents={StayUpToDateComponents}
          handleDays={handleDays}
          daysNameArray={daysNameArray}
          setErrorMsg={setErrorMsg}
          className="daywise"
        />

        <>
          {daysNameArray && daysNameArray.length > 0 && (
            <div style={{ marginTop: '24px' }}>
              <h3 style={{ fontSize: '16px', marginBottom: '12px', textAlign: 'left' }}>Preview</h3>
              <PreviewComponent
                username={username}
                user_id={user_id}
                selectedDays={daysNameArray}
                componentType="daywise"
                displayName={daysNameArray.join(",")}
                title={`${daysNameArray.join(" & ")} Shoppable Content`}
              />
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default DayWiseLinksComponents;
