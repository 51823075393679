import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsPatchCheckFill } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const PlatformCard = ({
  platform,
  isSelected,
  isExpanded,
  displayUsername,
  toggleExpand,
  handlePlatformValues,
  platformError,
  isInitialLoad,
  isLoading,
  verifyingPlatforms,
  selectedPlatforms,
  expandedPlatform,
}: any) => {
  // Function to truncate long URLs for better display
  const truncateUrl = (url: string, maxLength: number = 20) => {
    if (url.length <= maxLength) return url;
    return url.substring(0, maxLength) + '...';
  };
  
  return (
    <div key={platform.id} className="platform-card">
      <button
        type="button"
        onClick={() => toggleExpand(platform.id)}
        className={`platform-header ${isExpanded ? "expanded" : ""}`}
      >
        {platform.icon}
        <span className="platform-name">{platform.name}</span>
        {selectedPlatforms.some(
          (item: any) => item.platform === platform.id
        ) && (
          <>
            <BsPatchCheckFill className="platform-verified-badge" />
            {/* Displaying truncated URL/username for all platforms */}
            <span className="platform-username">
              {["amazon", "shopmy", "ltk", "website", "linkinbio", "newsletter"].includes(platform.id) 
                ? truncateUrl(displayUsername)
                : displayUsername}
            </span>
          </>
        )}
        <IoMdArrowDropdown
          className={`dropdown-icon ${isExpanded ? "expanded" : ""}`}
        />
      </button>

      {isExpanded && (
        <div className="platform-content">
          <div className="input-wrapper">
            {!["podcast", "newsletter","amazon", "shopmy", "ltk", "website", "linkinbio"].includes(platform.id) && (
              <span className="input-prefix">
                {!["amazon", "shopmy", "ltk", "website", "linkinbio"].includes(
                  platform.id
                ) && "@"}
              </span>
            )}
            <input
              type="text"
              value={platform.inputValue}
              onChange={(e) => platform.setInputValue(e.target.value)}
              className="instagram-input"
              placeholder={
                platform.id === "podcast"
                  ? `Enter your ${platform.name} show name`
                  : platform.id === "newsletter"
                  ? `Enter your ${platform.name} email`
                  : platform.id === "amazon"
                  ? "Enter your Amazon URL"
                  : platform.id === "shopmy"
                  ? "Enter your ShopMy URL"
                  : platform.id === "ltk"
                  ? "Enter your LTK URL"
                  : platform.id === "website"
                  ? "Enter your website URL"
                  : platform.id === "youtube"
                  ? "Enter your YouTube channel name"
                  : platform.id === "linkinbio"
                  ? "Enter your LinkinBio"
                  : `Enter your ${platform.name} username`
              }
              style={{
                borderColor: platformError[platform.id] ? "#ef4444" : "#4b5563",
              }}
              readOnly={isSelected}
            />
          </div>

          {/* Secondary inputs shown after main input */}
          {platform.secondaryInput && (
            <div className="input-wrapper" style={{ marginTop: "12px" }}>
              {platform.id !== "podcast" && (
                <span className="input-prefix">@</span>
              )}
              <input
                type="text"
                value={platform.secondaryInput?.value}
                onChange={(e) =>
                  platform.secondaryInput?.setValue(e.target.value)
                }
                className="instagram-input"
                placeholder={platform.secondaryInput?.placeholder}
                readOnly={isSelected}
                style={{
                  borderColor: platformError[platform.id]
                    ? "#ef4444"
                    : "#4b5563",
                }}
              />
            </div>
          )}

          {/* Newsletter website link input */}
          {platform.id === "newsletter" && platform.websiteLink && (
            <div className="input-wrapper" style={{ marginTop: "12px" }}>
              <input
                type="url"
                value={platform.websiteLink?.value}
                onChange={(e) => platform.websiteLink?.setValue(e.target.value)}
                className="instagram-input"
                placeholder={platform.websiteLink?.placeholder}
                readOnly={isSelected}
                style={{
                  borderColor: platformError[platform.id]
                    ? "#ef4444"
                    : "#4b5563",
                }}
              />
            </div>
          )}

          {platformError[platform.id] && (
            <p className="error-message">{platformError[platform.id]}</p>
          )}

          {platform.id !== "instagram" && (
            <button
              onClick={() => {
                const isConnecting = !selectedPlatforms.some(
                  (item: any) => item.platform === platform.id
                );
                handlePlatformValues(platform.id, isConnecting);
              }}
              disabled={!isSelected ? isInitialLoad : false}
              className={`platform-action-button ${
                isSelected ? "disconnect" : ""
              } ${isInitialLoad ? "disabled" : ""}`}
            >
              {!isLoading ? (
                <>
                  {!isSelected ? (
                    isInitialLoad || verifyingPlatforms[platform.id] ? (
                      <>
                        Verifying...
                        <AiOutlineLoading3Quarters
                          className="animate-spin ml-2"
                          style={{
                            marginLeft: "5px",
                            display: "inline-block",
                          }}
                        />
                      </>
                    ) : (
                      "Connect"
                    )
                  ) : (
                    "Disconnect"
                  )}
                </>
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin" />
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default PlatformCard;
