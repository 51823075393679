import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";

// Separate API call function
const verifyInstagram = async (instagram: string) => {
    const api_url = 'https://dev.thesearchbubble.com/validate-instagram'
    const response = await axios.post(api_url, {
        username: instagram
    });
    return response.data;
};

// Query hook (for real-time validation)
export function useInstagramVerification(instagram: string) {
    return useQuery(
        ['verifyInstagram', instagram],
        () => verifyInstagram(instagram),
        {
            enabled: !!instagram,
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );
}

// Mutation hook (for form submission)
export function useInstagramVerificationMutation() {
    const queryClient = useQueryClient();
    
    return useMutation(verifyInstagram, {
        onMutate: async (instagram) => {
            await queryClient.cancelQueries(["verifyInstagram"]);
            const previousData = queryClient.getQueryData([
                "verifyInstagram",
                instagram
            ]);

            return { previousData };
        },
        onError: (_error, instagram, context) => {
            queryClient.setQueryData(
                ["verifyInstagram", instagram],
                context?.previousData
            );
        },
        onSettled: (_data, _error, instagram) => {
            queryClient.invalidateQueries(["verifyInstagram", instagram]);
        },
    });
}