import dayjs from "dayjs";
import { supabase } from "../../config/supabaseClient";
import { UpdateCombinedText } from "../SupabaseServices/ContentDatabase";

export const daysSinceMultipleDays = (targetDaysOfWeek: number[]) => {
  const currentDayOfWeek = dayjs().day();
  const daysSinceTargetDays = targetDaysOfWeek.map(
    (targetDayOfWeek) => (currentDayOfWeek - targetDayOfWeek + 7) % 7
  );
  return daysSinceTargetDays;
};

export const isNavigable = (component: any) => {
  return (
    component.display_name !== "My Links" &&
    !component.display_name.includes("Form") &&
    component.type !== "banner" &&
    component.type !== "highlight" && 
    component.type !== "links-section" &&
    component.type !== "embed-form" &&
    component.display_name !== "reels" &&
    component.display_name !== "youtube" &&
    component.display_name !== "podcast" &&
    !component.display_name.includes("Newsletter") &&
    component.display_name !== "instagram story" &&
    component.display_name !== "tiktok"
  );
};

export function redirectToUrl(data:any,isLinks:any) {
  const redirectUrl = !isLinks
  ?  data?.external_link || data?.permalink || data?.shop_link || data?.table_url
  : data?.shop_link || data?.table_url || data?.external_link || data?.permalink;
  
  return redirectUrl
}
export async function updateIsEmptyStatus(name: any, user_id: any, id: any) {
  const { data, error } = await supabase
    .from("stayuptodate_components")
    .update({ isEmpty: true })
    .eq("name", name)
    .eq("id", id)
    .eq("user_id", user_id);
}
export async function updateCardImage(
  name: any,
  user_id: any,
  id: any,
  image: any
) {
  const { data, error } = await supabase
    .from("stayuptodate_components")
    .update({ card_image: image, isEmpty: false })
    .eq("name", name)
    .eq("id", id)
    .eq("user_id", user_id);
}

export function getMediaSrc(
  type: any,
  data: any,
  isCarousel: any,
  media_url: any
) {

  if (data?.media_url || data?.table_image) {
    if (isCarousel) {
      return media_url;
    } else {
      if (type !== "content" && type !== "query-content") {
        if (type === "product-bank") {
          return data?.media_url
            ? data?.media_url
            : data?.product_image
            ? data?.product_image
            : data?.table_image;
        } else {
          return data?.media_url.includes("video")
            ? data?.product_image
              ? data?.product_image
              : data?.thumbnail
            : data?.product_image
            ? data?.product_image
            : data?.media_url;
        }
      } else {
        return data?.media_url
          ? data?.media_url
          : data?.media_url.includes("video")
          ? data?.thumbnail
          : data?.product_image;
      }
    }
  } else if(data?.thumbnail) {
    return data?.thumbnail;
  } else {
    return "";
  }
}




export const testLink = (link: any) => {
  const url = link;
  if (url && url !== "") {
    const validUrl = url.startsWith("http") ? url : `http://${url}`;
    window.open(validUrl, "_blank");
  }
};



export async function updateNameValues(username: string,type:any,post_id:any,user_id:any,value:any) {
  if (type === "content" || type === "query-content") {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .update({ content_title: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newProduct, error: newProductErroor } = await supabase
      .from("product_bank")
      .update({ content_title: value })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
  } else {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .update({ shop_title: value, caption: value, product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newProduct, error: newProductErroor } = await supabase
      .from("product_bank")
      .update({ shop_title: value })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
  }

  const { data: newContent, error: newErroor } = await supabase
    .from("content_new")
    .update({ product_name: value })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  UpdateCombinedText(username, user_id, post_id);
}





export const formatDateToString = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

// Function to get all dates from a day selection (handles "This Weekend" specially)
export const getAllDatesFromSelection = (selectedDays: string[]): string[] => {
  const allDates: string[] = [];
  
  selectedDays.forEach(day => {
    const dateResult = getMostRecentDayDate(day);
    
    if (dateResult) {
      if (Array.isArray(dateResult)) {
        // For "This Weekend", add both Saturday and Sunday dates
        dateResult.forEach(date => {
          allDates.push(formatDateToString(date));
        });
      } else {
        allDates.push(formatDateToString(dateResult));
      }
    }
  });
  
  // Remove duplicates and sort in ascending order
  return [...new Set(allDates)].sort();
};

export const getMostRecentDayDate = (dayName: string): Date | Date[] | null => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const todayDayIndex = today.getDay(); // 0-6, where 0 is Sunday

  // Handle "This Weekend" specially - return both Saturday and Sunday
  if (dayName === "This Weekend") {
    // For "This Weekend", return an array of [Saturday, Sunday]
    const lastWeekend = [];

    // Get the most recent Saturday
    const lastSaturday = new Date(today);
    if (todayDayIndex === 0) {
      // Today is Sunday
      lastSaturday.setDate(today.getDate() - 1); // Yesterday was Saturday
    } else if (todayDayIndex === 6) {
      // Today is Saturday
      // Keep today's date for Saturday
    } else {
      // Set to last Saturday
      lastSaturday.setDate(today.getDate() - todayDayIndex - 1);
    }

    // Get the most recent Sunday
    const lastSunday = new Date(today);
    if (todayDayIndex === 0) {
      // Today is Sunday
      // Keep today's date for Sunday
    } else {
      // Set to last Sunday
      lastSunday.setDate(today.getDate() - todayDayIndex);
    }

    return [lastSaturday, lastSunday];
  }

  const targetDayIndex = days.indexOf(dayName);
  if (targetDayIndex === -1) return null;

  let daysAgo = todayDayIndex - targetDayIndex;
  if (daysAgo < 0) {
    daysAgo += 7; // If the day is ahead in the week, go back to previous week
  }

  const recentDate = new Date(today);
  recentDate.setDate(today.getDate() - daysAgo);

  return recentDate;
};