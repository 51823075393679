import {
  Alert,
  Button,
  Input,
  Modal,
  Spin,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { supabase } from "../../../config/supabaseClient";

const LTKIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showLTKModal, user_id, data, refetchData } = props;
  const [ltkLink, setLTKLink] = useState(
    data?.ltk_link || ""
  );

  const [errorLTK, setErrorLTK] = useState("");
  const [loadingLTKConnect, setLoadingLTKConnect] = useState(false);
  const [showLinkInstructions, setShowLinkInstructions] = useState(false);

  const handleCancel = () => {
    props.onClose();
    setErrorLTK("");
    setLTKLink(data?.ltk_link || "");
  };

  async function connectLTKAccount() {
    setLoadingLTKConnect(true);

    if (ltkLink && ltkLink !== null && ltkLink !== "") {
      // Validate that the URL is from LTK
      if (!ltkLink.toLowerCase().includes('ltk')) {
        setErrorLTK("Please enter a valid LTK URL");
        setLoadingLTKConnect(false);
        return;
      }
      
      try {
        const {data,error} = await supabase.from("user").update({
          social_ltk: ltkLink
         }).eq("user_id",user_id)
         props.onClose()
         refetchData()
      } catch (error) {
        console.error("Error connecting LTK:", error);
        setErrorLTK("Connection error. Please try again later.");
      } finally {
        setLoadingLTKConnect(false);
      }
    } else {
      setErrorLTK("Please enter your LTK profile link");
      setLoadingLTKConnect(false);
    }
  }

  return (
    <>
      <Modal
        title="Connect to LTK"
        open={showLTKModal}
        data-testid="ltk-modal"
        onOk={async () => {
          await connectLTKAccount();
        }}
        onCancel={handleCancel}
        okText="Connect"
        okButtonProps={{
          disabled: !ltkLink,
          loading: loadingLTKConnect,
        }}
        cancelButtonProps={{ disabled: loadingLTKConnect }}
      >
        <p>
          By connecting your LTK (LikeToKnowIt) profile, your shoppable products will be displayed
          in the feed of your main page. In the content tab you will be able to
          hide or delete the products you don't want to show.
        </p>
        {loadingLTKConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorLTK && (
              <Alert
                type="error"
                message={`Error: ${errorLTK}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.ltk_link ? (
              <>
                <p>
                  <b>
                    Already connected LTK link: {data?.ltk_link}
                  </b>
                </p>
                <p>New link will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your LTK profile URL:</p>
            <Input
              placeholder="Enter LTK link (e.g., https://www.liketoknow.it/yourusername)"
              id="ltk-link"
              value={ltkLink}
              onChange={(e) => {
                const value = e.target.value;
                setLTKLink(value);
                if (!value) {
                  setErrorLTK("Please enter your LTK link");
                } else if (!value.toLowerCase().includes('liketoknow.it') && !value.toLowerCase().includes('ltk.app')) {
                  setErrorLTK("Please enter a valid LTK URL");
                } else {
                  setErrorLTK("");
                }
              }}
            />

          
          </>
        )}
      </Modal>
    </>
  );
};

export default LTKIntegrationModal;
