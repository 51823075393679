import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import AccountSkeleton from "../../../components/skeleton/DashboardView/accountSkeleton";
import { StaticVariables } from "../../../Utils/StaticVariables";
import "./Account.css";
import { useAuth } from "../../../hooks/useAuth";
import IntegrationsBox from "../../../components/IntegrationsBox/IntegrationsBox";
import AccountsIntegration from "../../../components/AccountsIntegration/AccountsIntegration";
import Username from "./AccountSection/Username";
import Name from "./AccountSection/Name";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import Email from "./AccountSection/Email";
import Phone from "./AccountSection/Phone";
import AccountDescription from "../../../components/AccountDescription/AccountDescription";
import ProfilePicture from "./AccountSection/ProfilePicture";

import Subscription from "./AccountSection/Subscription";
import { Button, Input, Modal } from "antd";
import { MdAdd } from "react-icons/md";
import MembersList from "./AccountSection/MembersList";
import validator from "validator";
import { inviteTeamMember } from "../../../Utils/SupabaseServices/UserDatabase";

const Account = (props: any) => {
  const { user: sessionUser } = useAuth();
  const { username, setIsEmail } = props;
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(username);

  const handleAddTeam = async () => {
    if (!validator.isEmail(email)) {
      setError("Invalid Email");
      return;
    }
    if (email === userData?.email) {
      setError("You cannot invite yourself");
      return;
    }
    try {
      const result = await inviteTeamMember( userData!.user_id, email,"member");
      if (result.error) {
        setError(result?.message || "Failed to send invitation");
      } else {
        setEmail("");
        setError("");
        setIsAddMemberModalOpen(false);
        Modal.success({
          content: 'Invitation sent successfully!',
        });
      }
    } catch (err: any) {
      setError(err.message || "Failed to send invitation");
    }
  };

  return (
    <div>
      {userLoading ? (
        <AccountSkeleton />
      ) : (
        <>
          <div className="details">
            <h1 className="dashboard-mobile-hide">
              {StaticVariables().ACCOUNT_TITLE}
            </h1>
            <div className="account-values-grid">
              <Username username={username} sessionUser={sessionUser} />
              <Name
                username={username}
                userData={userData}
                setIsName={props.setIsName}
                user_id={sessionUser?.id}
              />

              <Email
                username={username}
                userData={userData}
                setIsEmail={props.setIsEmail}
                setIsEmailVerified={props.setIsEmailVerified}
                user_id={sessionUser?.id}
              />
              <Phone
                username={username}
                userData={userData}
                setIsPhone={props.setIsPhone}
                user_id={sessionUser?.id}
              />
              <Subscription
                username={username}
                userData={userData}
                user_id={sessionUser?.id}
              />
          
              {/* <div>
                <a
                  href="https://payments.searchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    className="accountButton"
                    onClick={() => { }}
                    id="save-phone"
                  >
                    Manage Subscription
                  </Button>
                </a>
              </div> */}
            </div>
           
          </div>
        </>
      )}

      <Modal
        title="Add Team Member"
        open={isAddMemberModalOpen}
        onCancel={() => {
          setIsAddMemberModalOpen(false);
          setError("");
          setEmail("");
        }}
        footer={[
          <Button key="cancel" onClick={() => {
            setIsAddMemberModalOpen(false);
            setError("");
            setEmail("");
          }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddTeam}>
            Send Invitation
          </Button>
        ]}
      >
        <div className="modal-content">
          <Input
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => {
              setError("");
              setEmail(e.target.value);
            }}
            status={error ? "error" : ""}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
      </Modal>

      <Tooltip
        className="tool-tip"
        id="displayName"
        place={"top"}
        offset={4}
        html="How your name will be shown on your page."
      />
      <Tooltip
        className="tool-tip"
        id="username"
        place={"top"}
        offset={4}
        html="This is your Bubble username."
      />
      <Tooltip
        className="tool-tip"
        id="email"
        place={"top"}
        offset={4}
        html="This is the email connected to your account."
      />
      <Tooltip
        className="tool-tip"
        id="phone"
        place={"top"}
        offset={4}
        html="This is the phone number connected to your account."
      />
    </div>
  );
};

export default Account;
