import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";

import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";

import TagsBox from "../../../components/TagsBox/TagsBox";
import "../Feed/feedTab.css";
import { updateCommentsData } from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useUpdateTags from "./useUpdateTags";
import useUpdatePostAdIndex from "../Feed/useUpdatePostIndex";

import FeedActionButtons from "./SubComponents/FeedActionButtons";
import FeedContentPost from "./SubComponents/FeedContentPost";
const ContentSearchTable = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenLinksContent,
  isLoading,
  loadingRef,
  totalSize,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentsData, setCommentsData] = useState<any>([]);
  const [noCommentsMsg, setNoCommentsMsg] = useState("");
  const [loadingComments, setLoadingComments] = useState(true);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();
  const [localData, setLocalData] = useState<any>([]);
  const [adsDragged, setAdsDragged] = useState(false);
  const [localTags, setLocalTags] = useState<{ [key: string]: string[] }>({});
  // const initialData = useMemo(() => data, []); // Only compute once
  // const [localData, setLocalData] = useState(initialData);
  // useEffect(() => {
  //   setLocalData(data);
  // }, [data]);

  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [key, setKey] = useState(0);
  const { mutate: updateTags } = useUpdateTags();
  const {
    mutate: updatePostAdIndex,
    data: updateIndexData,
    isSuccess,
  } = useUpdatePostAdIndex();

  useEffect(() => {
    if (data && data.length > 0) {
      setLocalData((currentLocalData:any) => {
        if (currentLocalData.length > 0) {
          return data.map((newItem: any) => {
            const existingItem = currentLocalData.find((item: any) => item.post_id === newItem.post_id);
            if (existingItem) {
              return {
                ...newItem,
                tagg: existingItem.tagg
              };
            }
            return newItem;
          });
        }
        return data;
      });
    } else {
      setLocalData([]);
    }
  }, [data]);

  useEffect(() => {
    if (localData && localData.length > 0) {
      setLocalTags(prevTags => {
        const newTags = { ...prevTags };
        localData.forEach((post: any) => {
          if (!newTags[post.post_id]) {
            newTags[post.post_id] = post.tagg ? post.tagg.split(',').filter(Boolean) : [];
          }
        });
        return newTags;
      });
    }
  }, [localData]);

  function removeTag(
    tagArr: string[],
    tag: string,
    post_id: string,
    pageIndex: number,
    index: number
  ) {
    const newTags = (localTags[post_id] || []).filter(t => t !== tag);

    setLocalTags(prev => ({
      ...prev,
      [post_id]: newTags
    }));

    setLocalData((prev:any) => 
      prev.map((item: any) => 
        item.post_id === post_id 
          ? { ...item, tagg: newTags.join(',') }
          : item
      )
    );

    updateTags({
      activeKey,
      tagString: newTags.join(','),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
      user_id,
    });
  }

  function submitTag(
    e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLInputElement>,
    tagArray: string[] | string,
    tag: string,
    post_id: string,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();

    if (!tag.trim()) return;

    if (e.type === 'submit' || (e.type === 'keyup' && (e as React.KeyboardEvent).key === 'Enter')) {
      const tagsToProcess = Array.from(
        new Set(tag.split(',').map(item => item.trim()).filter(Boolean))
      );

      const modifiedArray = tagsToProcess.map(word => `|${word}|`);
      
      const newTags = Array.from(new Set([...(localTags[post_id] || []), ...modifiedArray]));
      
      setLocalTags(prev => ({
        ...prev,
        [post_id]: newTags
      }));

      setLocalData((prev:any) => 
        prev.map((item: any) => 
          item.post_id === post_id 
            ? { ...item, tagg: newTags.join(',') }
            : item
        )
      );

      const input = e.target as HTMLInputElement;
      input.value = '';

      updateTags({
        activeKey,
        tagString: newTags.join(','),
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
        user_id,
      });
    }
  }

  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];
    setAdsDragged(true);
    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;
    setLocalData((prevData: any) => {
      const updatedData = [...prevData];
      const [removed] = updatedData.splice(result.source.index, 1);
      updatedData.splice(result.destination.index, 0, removed);
      return updatedData;
    });

    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);
    setLocalData(newData);
    // set
    try {
      await updatePostAdIndex({
        user_id,
        sourceIndex,
        targetIndex,
        activeKey,
        post_id,
        username,
        newData,
      });
      // if (isSuccess) {
      refetchData();

      // Update the new data order after mutation is complete

      // }
    } catch (error) {
      console.error("Error updating post ad index:", error);
    }
  };


  return (
    <>
      <DragDropContext
        onDragStart={() => {
          document.documentElement.style.scrollBehavior = "unset";
          document.body.style.scrollBehavior = "unset";
        }}
        onDragEnd={(result: any) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="ad" key={key}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="feed-tab-posts-table">
                {loadingRef === false && !isLoading ? (
                  <>
                    {localData && localData.length > 0 ? (
                      <Fragment key={1}>
                        {localData.map((image: any, index: number) => {
                          return (
                            <Draggable
                              key={image.post_id}
                              draggableId={image.post_id}
                              index={index}
                              isDragDisabled={
                                activeKey === "active,ad" ? false : true
                              }
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`feed-tab-post-row ${activeKey === "active,ad"
                                    ? "feed-tab-post-row-grab"
                                    : ""
                                    }
                                     ${draggedIndex === index
                                      ? "feed-tab-post-row-dragging"
                                      : ""
                                    }
                                    `}
                                  style={{
                                    ...provided.draggableProps.style,
                                    opacity: snapshot.isDragging ? 0.5 : 1,
                                    // cursor: "move",
                                  }}
                                >
                                  {activeKey === "active,ad" && (
                                    <div className="feed-tab-drag-btn">
                                      <MdOutlineDragIndicator />
                                    </div>
                                  )}

                                  <div className="feed-tab-row-media">
                                    <FeedContentPost image={image} />

                                    <div className="feed-tab-row-actions-mobile">
                                      <FeedActionButtons
                                        activeKey={activeKey}
                                        post={image}
                                        postIndex={image.index}
                                        postId={image.post_id}
                                        pageIndex={image.pageIndex}
                                        index={index}
                                        type={image.type}
                                        url={image.media_url}
                                        filter={filter}
                                        username={username}
                                        adsOrder={image?.ads_order}
                                        subscription_type={subscription_type}
                                        user_id={user_id}
                                        setLimitError={setLimitError}
                                        refetchData={refetchData}
                                        isLoading={isLoading}
                                        setLoadingComments={setLoadingComments}
                                        setSelectedPostId={setSelectedPostId}
                                        setIsModalOpen={setIsModalOpen}
                                        setCommentsData={setCommentsData}
                                        setNoCommentsMsg={setNoCommentsMsg}
                                        localData={localData}
                                        setLocalData={setLocalData}
                                      />
                                    </div>
                                  </div>

                                  <div className="feed-tab-row-tags">
                                    <form
                                      className="search-container-tagInput"
                                      onSubmit={(e) => {
                                        submitTag(
                                          e,
                                          localTags[image.post_id],
                                          (e.target as HTMLFormElement).tagInput.value,
                                          image.post_id,
                                          1,
                                          1
                                        );
                                      }}
                                    >
                                      <input
                                        type="text"
                                        id="tagInput"
                                        name="tagInput"
                                        enterKeyHint="enter"
                                        placeholder="Enter tags here"
                                        className="tagInput"
                                        onKeyUp={(e) => {
                                          submitTag(
                                            e,
                                            localTags[image.post_id],
                                            e.currentTarget.value,
                                            image.post_id,
                                            1,
                                            1
                                          );
                                        }}
                                      />
                                    </form>
                                    <div className="image-table-tags">
                                      {localTags[image.post_id]?.length > 0 ? (
                                        <TagsBox
                                          tagsArray={localTags[image.post_id]}
                                          removeTag={(tag: string) => {
                                            removeTag(
                                              localTags[image.post_id],
                                              tag,
                                              image.post_id,
                                              1,
                                              index
                                            );
                                          }}
                                          page="content"
                                        />
                                      ) : (
                                        <p className="noTags">No Tags</p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="feed-tab-actions">
                                    <FeedActionButtons
                                      activeKey={activeKey}
                                      post={image}
                                      postIndex={image.index}
                                      postId={image.post_id}
                                      pageIndex={image.pageIndex}
                                      index={index}
                                      type={image.type}
                                      url={image.media_url}
                                      filter={filter}
                                      username={username}
                                      subscription_type={subscription_type}
                                      user_id={user_id}
                                      setLimitError={setLimitError}
                                      refetchData={refetchData}
                                      setLoadingComments={setLoadingComments}
                                      setSelectedPostId={setSelectedPostId}
                                      setIsModalOpen={setIsModalOpen}
                                      setCommentsData={setCommentsData}
                                      setNoCommentsMsg={setNoCommentsMsg}
                                      localData={localData}
                                      setLocalData={setLocalData}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <p className="">
                        {filter && filter !== "" ? (
                          <>
                            {activeKey === "archived" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">📦</span>
                                <p>No posts have been archived yet.</p>
                              </div>
                            ) : activeKey === "active,ad" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">💼</span>
                                <p>No posts have been marked as an ad yet.</p>
                              </div>
                            ) : activeKey === "active,featured" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">✨</span>
                                <p>No posts have been marked as featured yet.</p>
                              </div>
                            ) : (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">⏳</span>
                                <p>Your data has not been processed yet. Come back soon!</p>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {activeKey === "archived" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">📦</span>
                                <p>No posts have been archived yet.</p>
                              </div>
                            ) : activeKey === "active,ad" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">💼</span>
                                <p>No posts have been marked as an ad yet.</p>
                              </div>
                            ) : activeKey === "active,featured" ? (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">✨</span>
                                <p>No posts have been marked as featured yet.</p>
                              </div>
                            ) : (
                              <div className="empty-state-message">
                                <span className="empty-state-icon">⏳</span>
                                <p>Your data has not been processed yet. Come back soon!</p>
                              </div>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="">
                      {filter && filter !== "" ? (
                        <>
                          {activeKey === "archived" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">📦</span>
                              <p>No posts have been archived yet.</p>
                            </div>
                          ) : activeKey === "active,ad" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">💼</span>
                              <p>No posts have been marked as an ad yet.</p>
                            </div>
                          ) : activeKey === "active,featured" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">✨</span>
                              <p>No posts have been marked as featured yet.</p>
                            </div>
                          ) : (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">🔍</span>
                              <p>No search results found.</p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {activeKey === "archived" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">📦</span>
                              <p>No posts have been archived yet.</p>
                            </div>
                          ) : activeKey === "active,ad" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">💼</span>
                              <p>No posts have been marked as an ad yet.</p>
                            </div>
                          ) : activeKey === "active,featured" ? (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">✨</span>
                              <p>No posts have been marked as featured yet.</p>
                            </div>
                          ) : (
                            <div className="empty-state-message">
                              <span className="empty-state-icon">⏳</span>
                              <p>Your data has not been processed yet. Come back soon!</p>
                            </div>
                          )}
                        </>
                      )}
                    </p>
                  </>
                )}
              </div>

              <Modal
                title="Show Relevant Comments"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 300px)",
                }}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                  </Button>,
                ]}
              ></Modal>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ContentSearchTable;