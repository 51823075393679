import { useCallback, useEffect, useState } from "react";
import {
  FiEdit2,
  FiCheck,
  FiTrash2,
  FiImage,
  FiX,
  FiPlus,
} from "react-icons/fi";
import "./GridTemplate.css";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../../Utils/uploadImage/UploadImage";
import { Upload, UploadProps } from "antd";
import {
  deleteUserLinks,
  storeUserLinks,
} from "../../../../../../Utils/SupabaseServices/UserLinksDatabase";
import { DeleteConfirmation } from "../LinksComponentDashboard";
import { Popover } from "antd";
import { MdDragIndicator } from "react-icons/md";
import track, {
  TRACKING_TYPES,
} from "../../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../../Utils/SupabaseServices/ContentDatabase";
import { useLocation } from "react-router-dom";

const GridTemplate = (props: any) => {
  const {
    card,
    onRemove,
    username,
    user_id,
    componentName,
    isDashboard,
    refetch,
    editIndex,
    setEditIndex,
    brand_color,
    templates,
    // isNewCard,
    from
  } = props;
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [validationErrorLink, setValidationErrorLink] = useState<string | null>(
    null
  );

  const [isNewCard, setIsNewCard] = useState(card.id === 0);
  const isEditing = editIndex === card.id;
  useEffect(() => {
    if (!isNewCard || card.link_name || card.link_url) {
      setTitle(card.link_name);
      setLink(card.link_url);
      setImageUrl(card.image_url);
    }
  }, [card, isNewCard]);

  useEffect(() => {
    // Reset values when editIndex changes, but only if this is not a new card
    if (editIndex !== card.id) {
      setTitle(card.link_name || "");
      setLink(card.link_url || "");
      setImageUrl(card.image_url);
      setValidationError(null);
      setValidationErrorLink(null);
    }
  }, [editIndex, card]);

  const handleSave = async (e: any) => {
    e.stopPropagation();

    if (!title || !title.trim()) {
      setValidationError("Title is required");
      return;
    }

    if (!link || !link.trim()) {
      setValidationErrorLink("Link is required");
      return;
    }

    const duplicateTitle = templates.find(
      (template: any) =>
        template.link_name.toLowerCase() === title.trim().toLowerCase() &&
        template.id !== card.id
    );

    if (duplicateTitle) {
      setValidationError("A link with this title already exists");
      return;
    }

    try {
      const result = await storeUserLinks(
        card.id === 0 ? null : card.id,
        user_id,
        username,
        title.trim(),
        link.trim(),
        imageUrl,
        componentName,
        from
      );
      setValidationError(null);
      setValidationErrorLink(null);
      setIsNewCard(false);
      setEditIndex(undefined);
      refetch();
    } catch (error) {
      console.error("Error saving link:", error);
      setValidationError("Failed to save link. Please try again.");
    }
  };

  const handleCancelSave = () => {
    refetch();
    // Only reset values if this is not a new card
    // if (!isNewCard) {
    //   setTitle(card.link_name);
    //   setLink(card.link_url);
    //   setImageUrl(card.image_url);
    // }
    setEditIndex(null); // Close edit mode after saving
    setValidationError("");
    setValidationErrorLink("");
  };

  const handleDelete = async () => {
    try {
      if (card.id !== 0) {
        await deleteUserLinks(user_id, componentName, card.id);
      }
      onRemove(card.id);
      setShowDeleteConfirm(false);
      setEditIndex(undefined);
      refetch();
    } catch (error) {
      console.error("Error deleting link:", error);
    }
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      if (uploading) return;
      setUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setImageUrl,
          storageName: "mylinks",
        });
      } finally {
        setUploading(false);
      }
    }, 300),
    [uploading, username]
  );

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  };

  const renderImageWithOverlay = () => (
    <div className="grid-template-logo-container">
      <img src={imageUrl} alt="Storefront" className="grid-template-logo" />
      {isEditing && (
        <div
          className="grid-template-logo-overlay"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="grid-template-logo-overlay-actions">
            <FiTrash2
              size={20}
              className="grid-template-overlay-icon remove"
              onClick={(e) => {
                e.stopPropagation();
                setImageUrl(null);
              }}
            />
            <Upload {...uploadProps} multiple={false} maxCount={1}>
              <FiPlus size={20} className="grid-template-overlay-icon add" />
            </Upload>
          </div>
        </div>
      )}
    </div>
  );

  const renderImagePlaceholder = () => (
    <>
      {isEditing ? (
        <div className="grid-template-image-placeholder">
          <Upload {...uploadProps} multiple={false} maxCount={1}>
            <div className="grid-template-upload-content">
              <FiImage size={32} color="#495057" />
              <span className="grid-template-upload-text">Upload Image</span>
            </div>
          </Upload>
        </div>
      ) : (
        <div className="grid-template-no-image-title">
          <p className="grid-template-field-text title">{title}</p>
        </div>
      )}
    </>
  );

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    // If this card is already being edited, do nothing
    if (editIndex === card.id) {
      return;
    }
    // Reset any validation errors
    setValidationError(null);
    setValidationErrorLink(null);
    // Reset values to original card values
    setTitle(card.link_name || "");
    setLink(card.link_url || "");
    setImageUrl(card.image_url);
    // Set the new editIndex
    setEditIndex(card.id);
  };
  const location = useLocation();
  const handleRedirectCheck = (card: any) => {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: card.id,
        media_url: card.image_url,
        title: componentName,
        name: card.link_name,
        platform: "links-section",
        // type:'links-section',
        // ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(card.id, user_id);

  };

  return (
    <>
      {isDashboard ? (
        <div className={`creator-card ${isEditing && "edit-mode-card"}`} style={{ backgroundColor: brand_color }}>
          <div
            className="grid-template-card-front"
            onClick={(e) => {
              if (isDashboard && (!editIndex || editIndex !== card.id)) {
                e.stopPropagation();
                handleEditClick(e);
              }
            }}
          >
            {isDashboard && (
              <div className="grid-template-actions">
                {isEditing ? (
                  <>
                    <button
                      className="grid-template-save-button"
                      onClick={handleSave}
                    >
                      <FiCheck size={20} />
                    </button>

                    <button
                      className="grid-template-save-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelSave();
                      }}
                    >
                      <FiX size={20} />
                    </button>
                  </>
                ) : (
                  <>
                    <button className="line-template-save-button drag-handle">
                      <MdDragIndicator
                        size={20}
                        className="drag-handle"
                        style={{ cursor: "grab" }}
                      />
                    </button>
                    <button
                      className="line-template-edit-button"
                      onClick={(e) => {
                        if (isDashboard) {
                          e.stopPropagation();
                          handleEditClick(e);
                        }
                      }}
                    >
                      <FiEdit2 size={20} />
                    </button>
                  </>
                )}

                {isEditing && (
                  <button
                    className="grid-template-delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteConfirm(true);
                    }}
                  >
                    <FiTrash2 size={20} />
                  </button>
                )}
              </div>
            )}
            {showDeleteConfirm && (
              <DeleteConfirmation
                setShowDeleteConfirm={setShowDeleteConfirm}
                setEditIndex={setEditIndex}
                handleDelete={handleDelete}
              />
            )}
            {imageUrl ? (
              <div className="grid-template-image-container">
                {renderImageWithOverlay()}
              </div>
            ) : (
              <>
                {!isEditing ? (
                  title && link ? (
                    <div
                      className="grid-template-no-image-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isDashboard) {
                          handleEditClick(e);
                        }
                      }}
                    >
                      <p className="grid-template-field-text title">{title}</p>
                    </div>
                  ) : (
                    <div className="grid-template-image-placeholder">
                      <div className="grid-template-upload-content">
                        <FiImage size={32} color="#495057" />
                        <span className="grid-template-upload-text">
                          Upload Image
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="grid-template-image-container">
                    {renderImagePlaceholder()}
                  </div>
                )}
              </>
            )}

            <div className="grid-template-content-section">
              <div className="grid-template-input-group">
                <Popover
                  content={<span className="errorMsg">{validationError}</span>}
                  open={!!validationError}
                  placement="top"
                ></Popover>
                {isEditing ? (
                  <input
                    type="text"
                    value={title}
                    placeholder="Enter title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                      if (validationError) setValidationError("");
                    }}
                    className="grid-template-edit-input title"
                  />
                ) : (
                  <>
                    {imageUrl ? (
                      <>
                        {title ? (
                          <p className="grid-template-field-text title">
                            {title}
                          </p>
                        ) : (
                          <p className="grid-template-field-text title-placeholder">
                            Title goes here
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {!title && (
                          <p className="grid-template-field-text title-placeholder">
                            Title goes here
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <Popover
                content={
                  <span className="errorMsg">{validationErrorLink}</span>
                }
                open={!!validationErrorLink}
                placement="top"
              ></Popover>
              {isEditing ? (
                <div className="grid-template-input-group">
                  <input
                    type="text"
                    value={link}
                    placeholder="Enter link"
                    onChange={(e) => {
                      setLink(e.target.value);
                      if (validationErrorLink) setValidationErrorLink("");
                    }}
                    className="grid-template-edit-input"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (

        <div style={{ position: "relative" }}>
          <div
            className="click-overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              handleRedirectCheck(card);
              window.open(card.link_url, "_blank");
            }}
          />
          <div
            className="creator-card"
            style={{ backgroundColor: brand_color }}
          >
            <div
              className="grid-template-card-front"

            >
              {showDeleteConfirm && (
                <DeleteConfirmation
                  setShowDeleteConfirm={setShowDeleteConfirm}
                  setEditIndex={setEditIndex}
                  handleDelete={handleDelete}
                />
              )}
              {imageUrl ? (
                <div className="grid-template-image-container"
                // style={{border:"1px solid blue"}}
                >
                  {renderImageWithOverlay()}
                </div>
              ) : (
                <>
                  {!isEditing ? (
                    title && link ? (
                      <div
                        className="grid-template-no-image-container"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isDashboard) {
                            handleEditClick(e);
                          }
                        }}
                      >
                        <p className="grid-template-field-text title">
                          {title}
                        </p>
                      </div>
                    ) : (
                      <div className="grid-template-image-placeholder">
                        <div className="grid-template-upload-content">
                          <FiImage size={32} color="#495057" />
                          <span className="grid-template-upload-text">
                            Upload Image
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="grid-template-image-container">
                      {renderImagePlaceholder()}
                    </div>
                  )}
                </>
              )}

              <div className="grid-template-content-section">
                <div className="grid-template-input-group">
                  <Popover
                    content={
                      <span className="errorMsg">{validationError}</span>
                    }
                    open={!!validationError}
                    placement="top"
                  ></Popover>
                  {isEditing ? (
                    <input
                      type="text"
                      value={title}
                      placeholder="Enter title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (validationError) setValidationError("");
                      }}
                      className="grid-template-edit-input title"
                    />
                  ) : (
                    <>
                      {imageUrl ? (
                        <>
                          {title ? (
                            <p className="grid-template-field-text title">
                              {title}
                            </p>
                          ) : (
                            <p className="grid-template-field-text title-placeholder">
                              Title goes here
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          {!title && (
                            <p className="grid-template-field-text title-placeholder">
                              Title goes here
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <Popover
                  content={
                    <span className="errorMsg">{validationErrorLink}</span>
                  }
                  open={!!validationErrorLink}
                  placement="top"
                ></Popover>
                {isEditing ? (
                  <div className="grid-template-input-group">
                    <input
                      type="text"
                      value={link}
                      placeholder="Enter link"
                      onChange={(e) => {
                        setLink(e.target.value);
                        if (validationErrorLink) setValidationErrorLink("");
                      }}
                      className="grid-template-edit-input"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>


        // </a>
      )}
    </>
  );
};

export default GridTemplate;
