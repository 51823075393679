import PostInfoDate from "./PostInfoComponents/PostInfoDate";
import PostInfoPermalink from "./PostInfoComponents/PostInfoPermalink";
import PostInfoShopLink from "./PostInfoComponents/PostInfoShopLink";
import { PostInfoTaggedLink } from "./PostInfoComponents/PostInfoTaggedLink";
import PostInfoExternalLink from "./PostInfoComponents/PostInfoExternalLink";

const PostInfo = ({
  user_id,
  showAsAd,
  post_date,
  permalink,
  shop_link,
  post_id,
  media_url,
  handleRedirect,
  handleShop,
  handleTagged,
  username,
  shoppableContent,
  tagged_link,
  isGlobalView,
  platform,
  external_link,
}: any) => {

  // Extract common JSX to reduce duplication
  const renderLinks = () => (
    <div className="creator-feed-post-links">
      <PostInfoTaggedLink
        tagged_link={tagged_link}
        post_id={post_id}
        media_url={media_url}
        handleTagged={handleTagged}
        platform={platform}
        user_id={user_id}
      />
      <PostInfoExternalLink
        external_link={external_link}
        handleRedirect={handleRedirect}
        post_id={post_id}
        media_url={media_url}
        platform={platform}
        user_id={user_id}
      />

      <PostInfoShopLink
        shop_link={shop_link}
        shoppableContent={shoppableContent}
        post_id={post_id}
        media_url={media_url}
        handleShop={handleShop}
        platform={platform}
        user_id={user_id}
      />

      <PostInfoPermalink
        permalink={permalink}
        handleRedirect={handleRedirect}
        post_id={post_id}
        media_url={media_url}
        platform={platform}
        user_id={user_id}
      />

      {isGlobalView && username && (
        <p className="creator-feed-username">@{username}</p>
      )}
    </div>
  );

  return (
    <div className="creator-feed-content-info">
      {!showAsAd ? (
        <>
          <PostInfoDate post_date={post_date} />
          {renderLinks()}
        </>
      ) : (
        <>
          <p className="creator-feed-content-date">Sponsored</p>
          {renderLinks()}
        </>
      )}
    </div>
  );
};

export default PostInfo;
