import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import "../Onboarding.css";

const CompletionStep = (props: any) => {
  const { username } = props;
  
  return (
    <div className="instagram-container">
      <div className="instagram-card">
        <div className="card-header">
          <div className="instagram-icon-wrapper">
            <AiOutlineCheckCircle className="instagram-icon" style={{ color: '#22c55e' }} />
          </div>
          <h2 className="card-title">You're All Set!</h2>
          <p className="card-description">
            Welcome, {username}! Your profile is being created now.
            We'll send you an email shortly with next steps.
          </p>
        </div>
        
        <p className="card-description" style={{ textAlign: 'center', marginTop: '16px' }}>
          Thank you for joining! Get ready to elevate your online presence.
        </p>
      </div>
    </div>
  );
};

export default CompletionStep; 