import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyInvitation, acceptInvitation } from '../../../Utils/SupabaseServices/UserDatabase';

const AcceptInvitePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [invitationDetails, setInvitationDetails] = useState<any>(null);

  useEffect(() => {
    verifyInvitationToken();
  }, []);


  const verifyInvitationToken = async () => {
    const token = searchParams.get('token');
    if (!token) {
      setError('Invalid invitation link');
      return;
    }

    const result = await verifyInvitation(token);

    if (result.error) {
      setError(result.message);
    } else {
      setInvitationDetails(result);
    }
    setLoading(false);
  };

  const onFinish = async (values: { password: string; confirmPassword: string }) => {
    if (values.password !== values.confirmPassword) {
      message.error('Passwords do not match');
      return;
    }

    const token = searchParams.get('token');
    if (!token || !invitationDetails) return;

    const result = await acceptInvitation(
      token,
      values.password,
      invitationDetails.email,
      invitationDetails.creatorId
    );

    if (result.error) {
      message.error(result.message);
    } else {
      message.success(result.message);
      navigate('/login');
    }
  };

  if (loading) {
    return <div>Verifying invitation...</div>;
  }

  return (
    <div className="accept-invite-container">
      <h2>Accept Team Invitation</h2>
      <p>You've been invited to join as a team member.</p>

      <Form onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input placeholder="Create password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, message: 'Please confirm your password!' }]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Accept Invitation
          </Button>
        </Form.Item>
      </Form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default AcceptInvitePage;