import React, { useEffect, useState } from "react";
import { Segmented } from "antd";
import {
  updateContentOrder,
} from "../../../Utils/SupabaseServices/ControlsDatabase";
import "./Controls.css";

const ContentOrderRadio = (props: any) => {
  const { username, checkedType, user_id, page } = props;
  const typeList = ["Default", "Time Based", "Shoppable First"];

  const [selectedType, setSelectedType] = useState(checkedType || "");

  const handleChange = (value: string) => {
    setSelectedType(value);
    updateContentOrder(user_id, value, page);
  };

  return (
    <div className="content-type-radios">
      <Segmented
        options={typeList}
        value={selectedType}
        onChange={handleChange}
        className="custom-segmented-order"
      />
    </div>
  );
};

export default ContentOrderRadio;
