import { useQuery } from "react-query";
import { StaticVariables } from "../../../StaticVariables";
import { supabase } from "../../../../config/supabaseClient";

/**
 * Load content
 */

export function useLoadSearchFilterCount(
  username: any,
  user_id: any[],
  queryTerm: any,
  postsPerPage: number,

  showSocials: any[],
  isShopLinks: any,
  activeFilter?: any,
  post_ids?: any[]
) {
  postsPerPage = 10;
  queryTerm = queryTerm && queryTerm.toLowerCase().replace(/\s+/g, " ").trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];
    const elementsCount = 5000;
    if (isShopLinks) {
      if (queryTerm || post_ids) {
      } else {
        let filteredArray = StaticVariables().TYPES;
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcountshop",
          {
            userid: user_id,

            filter: filteredArray,
            query_str: queryTerm ? queryTerm : "",
            user_name: username,
            socials: showSocials,
          }
        );
        feedCount = content[0].count;
      }
    }

    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(["getSearchFilterCount", queryTerm, isShopLinks, activeFilter], getCount, {
    // refetchOnWindowFocus: true,
  });
}
