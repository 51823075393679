import axios from "axios";
import { supabase } from "../../config/supabaseClient";
import { extractSpotifyID } from "./OnboardingFlow";
// import { Configuration, OpenAIApi } from "openai";
const slackToken = "xoxb-4117828880165-5021374947286-Srzu3WPZg8n26bq5gHCrjaGX";

export async function storeUserContent(
  provider_id: any,
  username: any,
  caption: any,
  like_count: any,
  comments_count: any,
  comments: any,
  media_url: any = "",
  permalink: any,
  thumbnail_url: any,
  post_id: any,
  post_date: any,
  type: any,
  auth_user_id: string
) {
  // TODO - for testing purposes we will allow storing the same post multiple times for different users since right now we allow having the same facebook account for multiple users.
  const { data }: any = await supabase
    .from("content_instagram")
    .select("*")
    .eq("post_id", post_id)
    .eq("user_id", auth_user_id);
  if (data && data.length === 0) {
    let storage_url = "";
    let thumbnail = "";
    if (media_url && media_url !== "") {
      const response = await fetch(media_url);

      const imageBlob = await response.blob();

      if (
        imageBlob.type === "image/jpeg" ||
        imageBlob.type === "video/mp4" ||
        imageBlob.type === "image/png" ||
        imageBlob.type === "image/webp"
      ) {
        const mediaType = imageBlob.type.split("/")[0];
        const mediaExt = imageBlob.type.split("/")[1];

        var { data: upload, error } = await supabase.storage
          .from("content")
          .upload(`${username}/${mediaType}${post_id}.${mediaExt}`, imageBlob, {
            contentType: imageBlob.type,
          });

        var { data: imageUrl }: any = await supabase.storage
          .from("content")
          .getPublicUrl(`${username}/${mediaType}${post_id}.${mediaExt}`);

        if (imageUrl && imageUrl.publicUrl) {
          storage_url = imageUrl.publicUrl;
        }
      }
    }
    if (thumbnail_url && thumbnail_url !== "") {
      const response = await fetch(thumbnail_url);

      const imageBlob = await response.blob();

      if (
        imageBlob.type === "image/jpeg" ||
        imageBlob.type === "image/png" ||
        imageBlob.type === "image/webp"
      ) {
        const mediaType = imageBlob.type.split("/")[0];
        const mediaExt = imageBlob.type.split("/")[1];

        await supabase.storage
          .from("reel_thumbnail")
          .upload(`${username}/${mediaType}${post_id}.${mediaExt}`, imageBlob, {
            contentType: imageBlob.type,
          });

        var { data: imageUrlThumbnail }: any = await supabase.storage
          .from("reel_thumbnail")
          .getPublicUrl(`${username}/${mediaType}${post_id}.${mediaExt}`);

        if (imageUrlThumbnail && imageUrlThumbnail.publicUrl) {
          thumbnail = imageUrlThumbnail.publicUrl;
        }
      }
    }

    let postCaption = "";
    if (caption) {
      postCaption = caption;
    }

    const { data: dataRes } = await supabase
      .from("content_instagram")
      .insert({
        provider_id,
        caption: postCaption,
        like_count,
        comments_count,
        media_url,
        storage_url,
        permalink,
        post_id,
        type,
        post_date,
        user_id: auth_user_id,
        thumbnail,
      })
      .select();

    const { data: content }: any = await supabase
      .from("content_new")
      .insert({
        user_id: auth_user_id,
        post_id,
        caption: postCaption,
        permalink,
        media_url: storage_url,
        platform: "instagram",
        post_date,
        type,
        metadata: JSON.stringify({
          like_count,
          comments_count,
          provider_id,
        }),
        raw_content_id: dataRes ? dataRes[0].id : "",
        thumbnail,
      })
      .select();

    if (comments && comments.data && comments.data.length) {
      comments.data.map(async (text: any) => {
        const { data, error }: any = await supabase.from("comments").insert([
          {
            user_id: auth_user_id,
            post_id,
            comment: text.text,
            isActive: true,
            timestamp: text.timestamp,
            comment_id: text.id,
            content_id: content ? content[0].id : "",
            platform: "instagram",
          },
        ]);
      });
    }
  }
  // if (data.length) {
  // 	await supabase.from("content").delete().eq("post_id", id);
  // }
}

export async function storeContent(username: any) {
  const { data: post } = await supabase
    .from("content_new")
    .select("*")
    .eq("username", username)
    .eq("storage_url", "");

  if (post && post.length) {
    post.map(async (media: any) => {
      const response = await fetch(media.media_url);
      const imageBlob = await response.blob();
      const mediaType = imageBlob.type.split("/")[0];
      const mediaExt = imageBlob.type.split("/")[1];
      if (
        imageBlob.type === "image/jpeg" ||
        imageBlob.type === "video/mp4" ||
        imageBlob.type === "image/png" ||
        imageBlob.type === "image/webp"
      ) {
        var { data, error } = await supabase.storage
          .from("content")
          .upload(
            `${username}/${mediaType}${media.post_id}.${mediaExt}`,
            imageBlob,
            {
              contentType: imageBlob.type,
            }
          );

        var { data: imageUrl }: any = await supabase.storage
          .from("content")
          .getPublicUrl(`${username}/${mediaType}${media.post_id}.${mediaExt}`);

        var { data: url }: any = await supabase
          .from("content")
          .update({ storage_url: imageUrl.publicUrl })
          .eq("username", username)
          .eq("post_id", media.post_id);
      }
    });
  }
}

export function generateRandom12DigitNumber() {
  // Generate a random number between 100000000000 and 999999999999
  return (
    Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
    1000000000000000
  );
}

export async function storeContentNew(
  post_id: any,
  user_id: any,
  name: string,
  productImage: string,
  type: string,
  date: any,
  platform: any,
  link: string
) {
  let randomNumber = generateRandom12DigitNumber();

  const { data: content, error }: any = await supabase
    .from("content_new")
    .insert([
      {
        post_id: randomNumber,
        product_name: name || "",
        user_id: user_id,
        caption: name || " ",
        media_url: productImage,
        type,
        platform,
        post_date: date || new Date(),
        raw_content_id: null,
        metadata: "",
        combined_text: name || "",
        permalink: link,
        external_link: link,
        thumbnail: "",
      },
    ]);
  if (content && content.length > 0) {
    return content;
  }
}

export async function storeOtherContent(username: any) {
  const { data: post } = await supabase
    .from("content")
    .select("*")
    .eq("username", username)
    .eq("storage_url", "");

  if (post && post.length) {
    post.map(async (media: any) => {
      const response = await fetch(media.media_url);
      const imageBlob = await response.blob();
      const mediaType = imageBlob.type.split("/")[0];
      const mediaExt = imageBlob.type.split("/")[1];

      var { data, error } = await supabase.storage
        .from("content_new")
        .upload(
          `${username}/${mediaType}${media.post_id}.${mediaExt}`,
          imageBlob,
          {
            contentType: imageBlob.type,
          }
        );

      var { data: imageUrl }: any = await supabase.storage
        .from("content")
        .getPublicUrl(`${username}/${mediaType}${media.post_id}.${mediaExt}`);

      var { data: url }: any = await supabase
        .from("content")
        .update({ storage_url: imageUrl.publicUrl })
        .eq("username", username)
        .eq("post_id", media.post_id);
    });
  }
}

//-- change dependency
// export async function getFeedLength(
//   user_id: any,
//   query: any = "",
//   filter: any = [],
//   username:any
// ) {
//   let { count }: any = await supabase
//     // .from(`${username}`)
//     .from("content_new")
//     .select("*", { count: "exact", head: true })
//     .in("user_id", user_id)
//     .in("type", filter)
//     // .or(
//     //   `or(caption.ilike.%${query}%,tagg.ilike.%${query}%)`
//     // )
//     .or(
//       `or(caption.ilike.%${query}%,tagg.ilike.%${query}%,metadata.ilike.%${query}%,ocr.ilike.%${query}%)`
//     )
//     .ilike("index", "%active%");
//   return count;
// }
//-- change dependency

export async function getAdLength(
  user_id: string,
  query: any = "",
  filter: any[],
  username: any
) {
  let { count: adCount }: any = await supabase
    .from("ads_content")
    .select("*", { count: "exact", head: true })
    .ilike("index", "%ad%")
    .eq("user_id", user_id);
  // return 0
  return adCount;
}

export async function updateRefClick(post_id: any, user_id: any) {
  await supabase.rpc("updateref", { i: `${post_id}`, userid: `${user_id}` });
}

export async function getPostClicks(
  username: any,
  max: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("get_posts_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    max_res: max,
    userid: user_id,
  });
  const resultArray = await Promise.all(
    data
      .filter((item: any) => {
        // Validate that `details` is a JSON string with the required structure
        try {
          const parsedDetails = JSON.parse(item.details);
          return (
            parsedDetails.post_id &&
            parsedDetails.media_url &&
            parsedDetails.platform
          );
        } catch (e) {
          return false; // Skip invalid JSON or missing properties
        }
      })
      .map(async (item: any) => {
        const parsedDetails = JSON.parse(item.details);
        const { data: postData } = await supabase
          .from(`${username}`)
          .select("*")
          .ilike("media_url", `%${parsedDetails.post_id}%`);

        const { data: productData } = await supabase
          .from(username)
          .select("*")
          .eq("post_id", parsedDetails.post_id)
          .eq("user_id", user_id);

        return {
          post_id: parsedDetails.post_id,
          count: item.count,
          additionalData: postData?.[0] || productData?.[0] || null,
        };
      })
  );

  if (data.length) {
    return resultArray;
  }

  return [];
}

export async function getShoppableClicks(
  username: any,
  max: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("get_shoppable_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    max_res: max,
    userid: user_id,
  });
  const resultArray = await Promise.all(
    data.map(async (item: any) => {
      const parsedDetails = JSON.parse(item.details);
      const { data: postData } = await supabase
        .from(`${username}`)
        .select("*")
        .ilike("media_url", `%${parsedDetails.post_id}%`);
      const { data: productData } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${parsedDetails.post_id}`)
        .eq("user_id", user_id);
      return {
        post_id: parsedDetails.post_id,
        count: item.count,
        additionalData: postData?.[0] || productData?.[0] || null,
      };
    })
  );
  if (data.length) {
    return resultArray;
  }

  return [];
}

export async function changeAdSequence(
  post_id: any,
  index: any,
  username: any,
  user_id: any
) {
  await supabase
    .from(`${username}`)
    .update({ ads_order: index })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("ads_content")
    .update({ ads_order: index })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
}
export async function removePostasAd(
  post_id: any,
  username: any,
  user_id: any
) {
  await supabase
    .from(`${username}`)
    .update({ index: "active", ads_order: null })
    .eq("post_id", post_id)
    .eq("user_id", user_id);

  // If the index is not "active,ad", delete the row from ads_content table
  const { data: adPost, error } = await supabase
    .from("ads_content")
    .select("*")
    .eq("post_id", post_id)
    .eq("user_id", user_id)

    .eq("username", username);
  if (adPost && adPost.length) {
    const { error: adDeleteError } = await supabase
      .from("ads_content")
      .delete()
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    if (adDeleteError) {
    }
  }
}
export async function removePostasFeature(
  post_id: any,
  username: any,
  user_id: any
) {
  await supabase
    .from(`${username}`)
    .update({ index: "active" })
    .eq("post_id", post_id)
    .eq("user_id", user_id);

  // If the index is not "active,ad", delete the row from ads_content table
}

export async function deletePostFunction(
  post_id: any,
  username: any,
  user_id: any
) {
  const { error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("post_id", post_id);
  const { error: contentNewError } = await supabase
    .from("content_new")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_instagram")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);

  await supabase
    .from("content_comments")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_linkedin")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_newsletter")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_pinterest")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_podcast")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_product")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_tiktok")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_twitter")
    .delete()
    .eq("tweet_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_yt")
    .delete()
    .eq("video_id", post_id)
    .eq("user_id", user_id);

  const { error: productError } = await supabase
    .from("product_bank")
    .delete()
    .eq("product_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("broken_links_table")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("ads_content")
    .delete()
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("analytics")
    .delete()
    .eq("user_id", user_id)
    .ilike("details", `%${post_id}%`);
}
export async function getAdsFeedLength(user_id: any, username: any) {
  let { data: count }: any = await supabase
    .from(`${username}`)
    .select("*")
    .eq("user_id", user_id)
    .eq("index", "active,ad");
  const uniquePosts = Object.values(
    count.reduce((acc: any, post: any) => {
      acc[post.post_id] = post; // Use post_id as the key to ensure uniqueness
      return acc;
    }, {})
  );

  return uniquePosts.length;
  // return count;
}

//---------------change dependency

export async function reduceAdSequence(user_id: any, username: any) {
  let { data } = await supabase
    .from(`${username}`)
    .select("*")
    .eq("user_id", user_id)
    .eq("index", "active,ad");
  if (data && data.length) {
    data.map(async (ad: any) => {
      if (ad.ads_order === 0) {
        await supabase
          .from(`${username}`)
          .update({ ads_order: null })
          .eq("post_id", ad.post_id)
          .eq("user_id", user_id);
      } else {
        await supabase
          .from(`${username}`)
          .update({ ads_order: `${ad.ads_order - 1}` })
          .eq("post_id", ad.post_id)
          .eq("user_id", user_id);
      }
    });
  }
  let { data: ads } = await supabase
    .from("ads_content")
    .select("*")
    .eq("user_id", user_id)
    .eq("index", "active,ad");
  if (ads && ads.length) {
    ads.map(async (ad: any) => {
      if (ad.ads_order === 0) {
        await supabase
          .from("ads_content")
          .update({ ads_order: null })
          .eq("post_id", ad.post_id)
          .eq("user_id", user_id);
      } else {
        await supabase
          .from("ads_content")
          .update({ ads_order: `${ad.ads_order - 1}` })
          .eq("post_id", ad.post_id)
          .eq("user_id", user_id);
      }
    });
  }
}

//---------------change dependency
export async function orderAdSequence(
  user_id: any,
  sourceIndex: any,
  targetIndex: any,
  post_id: any,
  username: any
) {
  let { data: sourceAd }: any = await supabase
    .from(`${username}`)
    .update({ ads_order: sourceIndex })
    .eq("user_id", user_id)
    .eq("ads_order", `${targetIndex}`)
    .select();

  let { data: targetAd }: any = await supabase
    .from(`${username}`)
    .update({ ads_order: targetIndex })
    .eq("user_id", user_id)
    .eq("post_id", `${post_id}`)
    .select();

  let { data: sourceAdContent, error: s }: any = await supabase
    .from("ads_content")
    .update({ ads_order: sourceIndex })
    .eq("user_id", user_id)
    .eq("ads_order", `${targetIndex}`)
    .select();

  let { data: targetAdContent, error: t }: any = await supabase
    .from("ads_content")
    .update({ ads_order: targetIndex })
    .eq("user_id", user_id)
    .eq("post_id", `${post_id}`)
    .select();
}

export async function getGLobalHashtags(includedUsers: any[], max: any) {
  let { data } = await supabase
    .from("searches")
    .select("*")
    .eq("type", `hashtag`)
    .in("username", includedUsers)
    .order("query")
    .order("occurrences", { ascending: false })
    .range(0, max - 1);

  if (data) {
    return data;
  }
}

//--- for global ------------
export async function getGlobalActiveFilters(includedUserIds: any[]) {
  const { data, error }: any = await supabase
    .from("content_new")
    .select("type")
    .in("user_id", includedUserIds);

  const uniqueSet = new Set<number>();
  data.filter((obj: any) => {
    if (!uniqueSet.has(obj.media_product_type)) {
      uniqueSet.add(obj.media_product_type);
    }
  });

  return uniqueSet;
}

type ResYtStoreContent = {
  error: boolean;
  message: string;
};
export async function storeYoutubeChannel(
  user_id: string,
  channel_id: string,
  username: any
): Promise<ResYtStoreContent> {
  const res = {
    error: false,
    message: "",
  };
  try {
    if (channel_id) {
      const newPlatformsUsername = channel_id?.includes("@")
        ? channel_id.slice(1)
        : channel_id;
      try {
        const api_url = "https://dev.thesearchbubble.com/verify-youtube";
        const validationResponse = await axios.post(api_url, {
          username: newPlatformsUsername,
        });
        if (validationResponse?.data?.success == true) {
          await supabase
            .from("user")
            .update({
              youtube_playlist_id:
                validationResponse?.data?.channelInfo?.uploadPlaylistId,
              youtube_channel_id: validationResponse?.data?.channelInfo?.id,
              youtube_username: newPlatformsUsername,
              is_youtube_on: true,
            })
            .eq("username", username);
        } else {
          throw new Error("Invalid YouTube channel");
        }

        const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
        const resServer = await axios.post(
          url,
          {
            userId: user_id,
            channelId: validationResponse?.data?.channelInfo?.id,
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const urlYtShorts =
          "https://dev.thesearchbubble.com/api/youtube/shorts";
        const YTShorts = axios.post(
          urlYtShorts,
          {
            userId: user_id,
            channelId: validationResponse?.data?.channelInfo?.id,
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { success, message } = resServer.data;

        if (!success) {
          res.error = true;
          res.message = message;
          return res;
        }

        // const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
        // // const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;

        // try {
        //   const resEmbed = await axios.post(
        //     embedUrl,
        //     {
        //       id: user_id,
        //       user: username,
        //     }
        //     // {
        //     //   headers: {
        //     //     "content-type": "application/json",
        //     //   },
        //     // }
        //   );
        //   // if (resEmbed.data === "done") {
        //   //   setTimeout(async () => {
        //   //     const { data: update, error } = await supabase
        //   //       .from("user")
        //   //       .update({ show_youtube: true })
        //   //       .eq("user_id", user_id);
        //   //   }, 10000);
        //   // }

        //   // Continue with the next steps if needed
        // } catch (error) {}

        const channel = "#newintegrations";

        try {
          const url = `https://dev.thesearchbubble.com/slack/update`;

          const resServer = await axios.post(
            url,
            {
              userId: user_id,
              user: username,
              channel: channel,
              msg: `YouTube Integration
        Username: ${username},
      
       
        YouTube Channel ID: ${channel_id}
        User ID: ${user_id}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const { success, message } = resServer.data;
        } catch (error) { }
      } catch (error: any) {
        console.error(
          "YouTube validation error:",
          error?.response?.data?.message
        );
        throw new Error(
          error?.response?.data?.message || "YouTube validation failed"
        );
      }
    }
    return res;
  } catch (error: any) {
    res.error = true;
    if (
      error &&
      error?.response &&
      error.response?.data &&
      error.response.data?.message
    ) {
      res.message = error.response.data.message;
    } else {
      res.message =
        "Server error: please try again later or contact our support team.";
    }
    return res;
  }
}

type ResNewsletterStoreContent = {
  error: boolean;
  message: string;
};
export async function storeNewsletterChannel(
  user_id: string,
  email: string,
  username: any,
  websiteLink?: string
): Promise<ResNewsletterStoreContent> {
  const res = {
    error: false,
    message: "",
  };
  try {
    const url = `https://dev.thesearchbubble.com/api/user/store/newsletter`;

    const resServer = await axios.post(
      url,
      { userId: user_id, user: username, email: email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { success, message } = resServer.data;
    const channel = "#newintegrations";

    try {
      const url = `https://dev.thesearchbubble.com/slack/update`;

      const resServer = await axios.post(
        url,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Newsletter Integration
          Username: ${username},
          
          Email: ${email}
          Website Link: ${websiteLink || "Not provided"}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, message } = resServer.data;
    } catch (error) { }

    if (!success) {
      res.error = true;
      res.message = message;
      return res;
    }

    return res;
  } catch (error: any) {
    res.error = true;
    if (
      error &&
      error?.response &&
      error.response?.data &&
      error.response.data?.message
    ) {
      res.message = error.response.data.message;
    } else {
      res.message =
        "Server error: please try again later or contact our support team.";
    }
    return res;
  }
}

export function extractLinkedInUsername(url: string): string {
  try {
    const cleanUrl = url.split(/[?#]/)[0];

    // Match LinkedIn profile URL patterns
    const regex =
      /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|pub|profile|showcase)\/([^/?#]+)/i;
    const match = cleanUrl.match(regex);

    if (match && match[1]) {
      return match[1].split("/")[0];
    } else {
      throw new Error("Invalid LinkedIn URL");
    }
  } catch (error: any) {
    console.error(error.message);
    return ""; // Return null if the URL is invalid
  }
}

export async function storeLinkedInChannel(
  user_id: string,
  email: string,
  username: any
): Promise<ResNewsletterStoreContent> {
  const res = {
    error: false,
    message: "",
  };
  try {
    const linkedInUsername = email.includes("@") ? email.split("@")[1] : email;
    const url = `https://dev.thesearchbubble.com/fetch/linkedin`;


    const resServer: any = await axios.post(
      url,
      { userId: user_id, user: username, linkedInUsername: linkedInUsername },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    await supabase
      .from("user")
      .update({ linkedin_user: linkedInUsername })
      .eq("user_id", user_id)
      .eq("username", username);

    const channel = "#newintegrations";

    try {
      const url = `https://dev.thesearchbubble.com/slack/update`;

      const resServer = await axios.post(
        url,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `LinkedIn Integration
          Username: ${username},
        
         
          LinkedIn Username: ${linkedInUsername}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, message } = resServer.data;
    } catch (error) { }

    return res;
  } catch (error: any) {
    res.error = true;
    // Handle specific error cases
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 400) {
        res.message =
          error.response.data.message ||
          "Invalid LinkedIn profile. Please check the username and try again.";
      } else if (error.response.status === 500) {
        res.message =
          "Server error. Please try again later or contact support.";
      } else {
        res.message =
          error.response.data.message ||
          "An error occurred while connecting to LinkedIn.";
      }
    } else if (error.request) {
      // Request made but no response received
      res.message =
        "Unable to connect to the server. Please check your internet connection and try again.";
    } else {
      res.message = "An unexpected error occurred. Please try again.";
    }
    return res;
  }
}

export function removeQualifiers(query: any) {
  // Parse the sentence using compromise

  const words = query.split(" ");
  const trimmedWords = words.map((word: any) => word.trim());
  return trimmedWords;
}
function createArray(query: any) {
  const words = query.split(",");
  const trimmedWords = words.map((word: any) => word.trim());
  return trimmedWords;
}
function createKeywordPermutations(keywords: any[]) {
  const combinations = [];

  for (let i = 0; i < keywords.length - 1; i++) {
    for (let j = i + 1; j < keywords.length; j++) {
      combinations.push([keywords[i], keywords[j]]);
    }
  }

  return combinations;
}
function isPairCombinationPresent(
  searchArray: any,
  keyword1: any,
  keyword2: any
) {
  return searchArray.includes(`${keyword1} ${keyword2}`);
}

export async function getEmbedding(query: any) {
  // try{
  // const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
  // const url = `https://dev.thesearchbubble.com/generate/carousel/thumbnail`;
  // const url = `https://dev.thesearchbubble.com/fetch/openai/embedding`;

  // const res = await axios.post(
  //   url,
  //   {
  //     query:query
  //   },
  //   {
  //     // headers: {
  //     //   "content-type": "application/json",
  //     // },
  //   }
  // );
  // return res.data.embedding
  // }catch(error){
  // }
  const { data, error } = await supabase
    .from("query_embedding")
    .select("*")
    .eq("query", query);
  if (data) {
    if (data.length != 0) {
      return data?.[0]?.embedding;
    } else {
      try {
        const data = {
          input: query,
          model: "text-embedding-ada-002",
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer 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",
            "Access-Control-Allow-Origin ": "*",
          },
        };

        const response = await axios.post(
          "https://api.openai.com/v1/embeddings",
          data,
          config
        );

        const [{ embedding }] = response.data.data;
        await supabase
          .from("query_embedding")
          .insert([{ query: query, embedding: embedding }]);
        return embedding;
      } catch (error) {
        throw error;
      }
    }
  }
  // }
}
export async function getQuerySuggestions(
  query: string = "",
  openAiSearch: boolean,
  extraIds?: any[],
  category?: any,
  activeHightlights?: any
) {
  let queryKeywords: any;
  let keyword1: any;
  let keyword2: any = "";

  let keyword3: any = "";

  let commaKeyword: any;
  let similarSearchArr: any;
  let combinedWords: any;
  let combinedQuery: any;

  query = query && query.trim();
  let basicQuery;
  let searchQuery;
  let searchQuery2;
  let similarSearchQuery;
  let individualKeywordQuery;
  let individualKeywords;

  if (!query) {
    basicQuery = `tagg.ilike.%${query}%,caption.ilike.%${query}%,text.ilike.%${query}%`;
  } else if (category) {
    if (category === "searchbar") {
      let resApi;
      if (!openAiSearch) {
        queryKeywords = removeQualifiers(query);
      } else {
        if (query) {
          try {
            const { data: resApimsg } = await axios.get(
              // ${
              //   process.env.ENV === "production"
              //     ? process.env.REACT_APP_API_URL_PRODUCTION
              //     : process.env.REACT_APP_API_URL_DEV
              // }
              // `https://dev.thesearchbubble.com/api/openai/${query}`
              `https://dev.thesearchbubble.com/api/openai/${query}`
              // `https://dev.thesearchbubble.com/api/openai/${query}`
            );
            resApi = resApimsg;
          } catch (e) {
            return 0;
          }
          if (resApi && resApi.success) {
            // queryKeywords = createArray(resApi.keywords.trim());
            similarSearchArr = resApi.suggestion;
            keyword1 = resApi.keyword1 && resApi.keyword1.trim();
            keyword2 = resApi.keyword2 && resApi.keyword2.trim();
            // keyword3 = queryKeywords[1].trim();

            keyword3 = resApi.keyword3 && resApi.keyword3.trim();
          }
        }
      }
      let individualKeywords = [keyword1, keyword2, keyword3];

      individualKeywords = [...new Set(individualKeywords)];

      individualKeywordQuery =
        individualKeywords &&
        individualKeywords.map((term: any) => `text.ilike.%${term}%`).join(",");

      searchQuery = `text.ilike.%${keyword1}%`;

      const similarSearchQuery = (similarSearchArr || [])
        .map(
          (phrase: any) =>
            `and(${phrase
              .split(" ")
              .map((term: any) => `text.ilike.%${term}%`)
              .join(",")})`
        )
        .join(",");

      if (searchQuery) {
        basicQuery = `${searchQuery}`;
        if (searchQuery2) {
          basicQuery += `,${searchQuery2}`;
          if (similarSearchQuery) {
            basicQuery += `,${similarSearchQuery}`;
          }
        } else if (similarSearchQuery) {
          basicQuery += `,${similarSearchQuery}`;
        }
        if (individualKeywordQuery) {
          basicQuery += `,${individualKeywordQuery}`;
        }
      }
    } else if (
      category === "Top Hashtags" ||
      category === "Popular Searches" ||
      category === "Content Type"
    ) {
      basicQuery = `tagg.ilike.%${query}%,caption.ilike.%${query}%,text.ilike.%${query}%`;
    } else if (category === "Highlights") {
      basicQuery = `tagg.ilike.%${query}%`;
    } else {
      basicQuery = `tagg.ilike.%${query}%`;
    }
  } else {
    basicQuery = `tagg.ilike.%${query}%,caption.ilike.%${query}%,text.ilike.%${query}%`;
  }

  return {
    query: basicQuery,
    second: searchQuery,
    third: searchQuery2,
    fourth: individualKeywordQuery,
    keyword1: keyword1,
    keyword2: keyword2,
    keyword3: keyword3,
  };
}

export async function storePodcastChannel(
  user_id: string,
  podcastShowName: string,
  podcastShowUrl: string,
  username: string
): Promise<ResNewsletterStoreContent> {
  const res = {
    error: false,
    message: "",
  };

  try {

    const spotifyId = extractSpotifyID(podcastShowUrl);
    const channel = "#newintegrations";

    const { data, error } = await supabase
      .from("user")
      .update({
        podcast_link: podcastShowUrl,
        podcast_showname: podcastShowName,
        podcast_artist: podcastShowName,
        podcast_id: spotifyId,
        is_podcast_on: true,
      })
      .eq("username", username);
    const url = `https://dev.thesearchbubble.com/fetch/podcast`;
    try {
      const url = `https://dev.thesearchbubble.com/slack/update`;

      const resServer = await axios.post(
        url,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Podcast Integration
        Username: ${username},
      
        Podcast Show Name: ${podcastShowName},
        Podcast Show URL: ${podcastShowUrl},
        Podcast Show ID: ${spotifyId}
        User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, message } = resServer.data;
      // return res;
    } catch (error) { }
    let res: any = await axios.post(
      url,
      {
        user: username,
        user_id: user_id,
        id: spotifyId,
        showName: podcastShowName,
        showUrl: podcastShowUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res?.data?.success) {
      return res;
    } else {
      res.error = true;
      res.message =
        "Unable to connect to the server. Please check your internet connection and try again.";
    }



    // }
  } catch (error: any) {
    res.error = true;
    // Handle specific error cases
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 400) {
        res.message =
          error.response.data.message ||
          "Invalid LinkedIn profile. Please check the username and try again.";
      } else if (error.response.status === 500) {
        res.message =
          "Server error. Please try again later or contact support.";
      } else {
        res.message =
          error.response.data.message ||
          "An error occurred while connecting to LinkedIn.";
      }
    } else if (error.request) {
      // Request made but no response received
      res.message =
        "Unable to connect to the server. Please check your internet connection and try again.";
    } else {
      res.message = "An unexpected error occurred. Please try again.";
    }
    return res;
  }
  return res;
}
export async function fetchAllPodcastEpisodes(
  username: any,
  user_id: any,
  showLink: any,
  showId: any,
  showName: any
) {
  // const url = `https://dev.thesearchbubble.com/fetch/podcast`;
  const url = `https://dev.thesearchbubble.com/fetch/podcast`;

  let res: any = await axios.post(
    url,
    { user: username, user_id: user_id, id: showId, showName: showName },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (res.data === "done") {
    return true;
  } else {
    return false;
  }
}

export async function storeTiktokUser(
  user_id: string,
  name: string,
  username: any
): Promise<ResNewsletterStoreContent> {
  const res = {
    error: false,
    message: "",
  };
  try {
    const result = name.startsWith("@") ? name.slice(1) : name;
    await supabase
      .from("user")
      .update({ tiktok_user: result })
      .eq("user_id", user_id);

    const url = `https://dev.thesearchbubble.com/integrate/tiktok`;
    await axios.post(
      url,
      { id: user_id, tiktokUsername: name, username: username },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // const { success, message } = resServer.data;

    const channel = "#newintegrations";
    let resServer;
    try {
      const url = `https://dev.thesearchbubble.com/slack/update`;

      resServer = await axios.post(
        url,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `TikTok Integration
        Username: ${username},
      
       
        TikTok Handle: ${result}
        User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, message } = resServer.data;
    } catch (error) { }
    return res;
  } catch (error: any) {
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 400) {
        res.message =
          error.response.data.message ||
          "Invalid TikTok profile. Please check the username and try again.";
      } else if (error.response.status === 500) {
        res.message =
          "Server error. Please try again later or contact support.";
      } else {
        res.message =
          error.response.data.message ||
          "An error occurred while connecting to TikTok.";
      }
    } else if (error.request) {
      // Request made but no response received
      res.message =
        "Unable to connect to the server. Please check your internet connection and try again.";
    } else {
      res.message = "An unexpected error occurred. Please try again.";
    }
    return res;
  }
}

export async function storeInstagramUser(
  user_id: string,
  name: string,
  username: any
) {
  await supabase
    .from("user")
    .update({ instagram_username: name })
    .eq("user_id", user_id);

  // const url = `https://dev.thesearchbubble.com/integrate/instagram`;

  // const resServer = await axios.post(
  //   url,
  //   { userId: user_id, user: name },
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );
  // const { success, message } = resServer.data;
  const channel = "#newintegrations";
  let resServer;
  try {
    const url = `https://dev.thesearchbubble.com/slack/update`;

    resServer = await axios.post(
      url,
      {
        userId: user_id,
        user: username,
        channel: channel,
        msg: `Instagram Integration
        Username: ${username},
      
       
        Instagram Handle: ${name}
        User ID: ${user_id}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { success, message } = resServer.data;
  } catch (error) { }
  if (resServer) {
    return true;
  } else {
    return false;
  }
}

// export function getExtraNecIds(queryTerm: any, username: any) {
//   let url = "https://nec.thesearchbubble.com:3136/api/";

//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append("Accept-Charset", "UTF-8");

//   var raw = JSON.stringify({
//     text: queryTerm,
//     creator: username,
//   });

//   var requestOptions: any = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };
//   let promises: any = [];

//   let businessAccount = function (id: any) {
//     return new Promise(async (resolve, reject) => {
//       return resolve(id);
//     });
//   };
//   try {
//     fetch(url, requestOptions)
//       .then((response) => response.text())
//       .then((result: any) => {
//         // Remove the square brackets and split the string into an array
//         const dataArray = JSON.parse(result).slice(1, -1).split(", ");

//         // Use map on the array
//         dataArray.map((obj: any) => {
//           const match = obj.match(/(\d+)/g);
//           promises.push(businessAccount(match[0]));
//         });

//         // promises.push(
//         //   businessAccount(obj.split("/image")[2].split(".")[0])
//         // );
//         let finalList: any = [];

//         Promise.all(promises).then((result) => {
//           result.map((e) => {
//             finalList.push(e);
//           });
//           finalList = [...new Set(finalList)];
//           // props.getExtraFeed([...new Set(finalList)]);
//           // props.getQuery(query, "searchbar");
//           // setSearches(query, "searchbar");
//         });
//         return finalList;

//       })
//       .catch((error) => {
//         // props.getQuery(query, "searchbar");
//         // setSearches(query, "searchbar");
//       });
//   } catch (error) {
//     // Handle errors if needed
//   }
// }
export async function getExtraNecIds(
  queryTerm: any,
  username: any
): Promise<any[]> {
  const url = "https://nec.thesearchbubble.com:3136/api/";

  const myHeaders = new Headers({
    "Content-Type": "application/json",
    "Accept-Charset": "UTF-8",
  });

  const raw = JSON.stringify({
    text: queryTerm,
    creator: username,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    // const dataArray = result.slice(1, 10).split(", ");
    const secondArray = result.split("],")[1].slice(1, -1).split(", ");
    const filteredArray = secondArray?.filter((element: any) => element > 21.3);

    // Log the filtered array
    const dataArray = result.slice(1, -1).split(", ");
    const dataArrayCut = dataArray.slice(0, filteredArray.length);

    const promises = dataArrayCut.map(async (obj: any) => {
      const match = obj.match(/(\d+)/g);
      return match[0];
    });

    const finalList = await Promise.all(promises);

    // Use Set to remove duplicates
    const uniqueFinalList = [...new Set(finalList)];

    return uniqueFinalList;
  } catch (error) {
    // Handle errors if needed
    return [];
  }
}

export async function extractKeywords(query: any) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer 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`,
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo-instruct",
      // prompt: `Extract at most 3 keywords or phrases from this search term. Keywords must be nouns. Nouns should be ordered in this order - objects first, places second, and people third. Words from the search term that are being used as adjectives in the search term cannot be used as a keyword. For example, if the search term is "diamond ring", "diamond" is being used as an adjective which means it cannot be a keyword. If the search term is only one word, return back just the search term. Each keyword should be only one word and present in the search term. If you have extracted more than 3 keyword, limit your response to the 3 most specific keywords. Order the keywords from the most specific and niche nouns to least specific and niche nouns.  Separate each keyword with a comma. The search term is: ${query}`,
      prompt: `Remove generic terms from the query. The result should have only nouns. If the query is one word return the query itself. Don't give extra word other than from the query. All the terms should be present in the query. The query is: ${query}`,
      temperature: 0.6,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 1,
      presence_penalty: 1,
    }),
  };

  try {
    const response = await fetch(
      "https://api.openai.com/v1/completions",
      options
    );
    const data = await response.json();
    const word = data.choices[0].text.replace(/.*\n/, "");

    try {
      const data = {
        input: word,
        model: "text-embedding-ada-002",
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
      };

      const response = await axios.post(
        "https://api.openai.com/v1/embeddings",
        data,
        config
      );

      const [{ embedding }] = response.data.data;
      return embedding;
    } catch (error) {
      throw error;
    }
    // Process the response data here if needed
    // return word; // Return the extracted keywords or processed data
  } catch (error) {
    throw error; // Propagate the error for handling outside this function
  }
}

export async function fetchPinterestData(
  username: any,
  user_id: any,
  code: any
) {
  // const url = `https://dev.thesearchbubble.com/fetch/pinterest`

  const url = `https://dev.thesearchbubble.com/fetch/pinterest`;
  let res: any = await axios.post(
    url,
    { user: username, user_id: user_id, code: code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const channel = "#newintegrations";

  try {
    const url = `https://dev.thesearchbubble.com/slack/update`;

    const resServer = await axios.post(
      url,
      {
        userId: user_id,
        user: username,
        channel: channel,
        msg: `Pinterest Integration
        Username: ${username},
      
       
        User ID: ${user_id}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { success, message } = resServer.data;
  } catch (error) { }
  if (res.data === "done") {
    return true;
  } else {
    return false;
  }
}
export async function updateShopLink(
  user_id: any,
  username: any,
  post_id: any,
  link: any
) {
  const { error } = await supabase
    .from(`${username}`)
    .update({ shop_link: link })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_new")
    .update({ shop_link: link })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
}

export async function storeTwitterUser(
  user_id: string,
  name: string,
  username: any
): Promise<ResNewsletterStoreContent> {
  const res = {
    error: false,
    message: "",
  };

  try {
    const result = name.startsWith("@") ? name.slice(1) : name;

    const url = `https://dev.thesearchbubble.com/fetch/twitter`;
    // const url = `http://localhost:3001/fetch/twitter`;
    const response = axios.post(
      url,
      { userId: user_id, user: username, twitterUser: result },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
   


    await supabase
      .from("user")
      .update({ twitter_user: name })
      .eq("user_id", user_id)
      .eq("username", username);
    const channel = "#newintegrations";
    let resServer;
    try {
      const url = `https://dev.thesearchbubble.com/slack/update`;

      resServer = await axios.post(
        url,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Twitter Integration
        Username: ${username},
      
       
        Twitter Handle: ${name}
        User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, message } = resServer.data;
    } catch (error) { }
    return res;
  } catch (error: any) {
    res.error = true;
    // Handle specific error cases
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 400) {
        res.message =
          error.response.data.message ||
          "Invalid Twitter profile. Please check the username and try again.";
      } else if (error.response.status === 500) {
        res.message =
          "Server error. Please try again later or contact support.";
      } else {
        res.message =
          error.response.data.message ||
          "An error occurred while connecting to Twitter.";
      }
    } else if (error.request) {
      // Request made but no response received
      res.message =
        "Unable to connect to the server. Please check your internet connection and try again.";
    } else {
      res.message = "An unexpected error occurred. Please try again.";
    }
    return res;
  }
}

export async function storeFlowdeskId(
  user_id: any,
  FormId: any,
  username: any
) {
  // const formIdMatch = FormId.match(/formId:\s*'([^']+)'/);
  const regex = /form\/([a-zA-Z0-9]+)/;
  const match = FormId.match(regex);
  // return match ? match[1] : null;
  if (match && match[1]) {
    const formId = match[1];
    const { data } = await supabase
      .from("user")
      .update({
        flowdesk_user: formId,
        show_flowdesk: true,
        is_flowdesk_on: true,
      })
      .eq("user_id", user_id);
  } else {
  }
}

export async function storeFlowdeskPopupId(
  user_id: any,
  FormId: any,
  username: any
) {
  // const formIdMatch = FormId.match(/formId:\s*'([^']+)'/);
  const regex = /form\/([a-zA-Z0-9]+)/;
  const match = FormId.match(regex);
  // return match ? match[1] : null;
  if (match && match[1]) {
    const formId = match[1];
    const { data } = await supabase
      .from("user")
      .update({
        flowdeskpopup_user: formId,
        show_flowdeskpopup: true,
        is_flowdeskpopup_on: true,
      })
      .eq("user_id", user_id);
  } else {
  }
}

export async function storeFilloutId(user_id: any, FormId: any, username: any) {
  // const formIdMatch = FormId.match(/formId:\s*'([^']+)'/);
  // const url = "https://forms.fillout.com/t/9Mfibx8yRmus";
  const id = FormId.match(/\/t\/(.*)/)[1]; // This captures the ID after /t/
  // return match ? match[1] : null;
  if (id) {
    // const formId = match[1];
    const { data } = await supabase
      .from("user")
      .update({ fillout_user: id, show_fillout: true, is_fillout_on: true })
      .eq("user_id", user_id);
  } else {
  }
}

export async function UpdateCombinedText(
  username: any,
  user_id: any,
  post_id: any
) {
  const columnsToCombine = [
    "caption",
    "tagg",
    "ocr",
    "content_category",
    "product_name",
    "content_title",
    "shop_title",
    "metadata",
  ];
  // Step 1: Fetch the data for the specific post and user
  const { data, error: fetchError } = await supabase
    .from(username)
    .select(columnsToCombine.join(",")) // Select only relevant columns
    .eq("post_id", post_id)
    .eq("user_id", user_id);

  if (fetchError || !data || data.length === 0) {
    console.error("Error fetching data:", fetchError);
    return { success: false, message: "Failed to fetch data." };
  }

  const combined_text = Array.from(
    new Set(
      columnsToCombine
        .map((col: any) => data[0]?.[col] || "")
        .filter((value) => value.trim() !== "")
    )
  ).join(" "); // Concatenate with spaces

  // Step 3: Update the table with the new combined_text
  const { error: updateError } = await supabase
    .from(username)
    .update({ combined_text: combined_text, text: combined_text })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  await supabase
    .from("content_new")
    .update({ combined_text: combined_text })
    .eq("post_id", post_id)
    .eq("user_id", user_id);
  const embedUrl = `https://dev.thesearchbubble.com/single/embedding`;


  axios.post(embedUrl, {
    id: user_id,
    user: username,
    post_id: post_id,
  });
  if (updateError) {
    console.error("Error updating combined_text:", updateError);
    return { success: false, message: "Failed to update combined_text." };
  }

  return { success: true, message: "combined_text updated successfully." };
}
