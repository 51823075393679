import React, { useState, useEffect } from "react";
import { supabase } from "../../../../config/supabaseClient";
import { signupUserFolder } from "../../../../Utils/SupabaseServices/OnboardingFlow";
import { useEmailVerification } from "../Utils/useEmailVerification";
import debounce from "lodash/debounce";
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import "../Onboarding.css";

const UserInfoStep = (props: any) => {
  const {
    handleNext,
    handleBack,
    formData,
    setFormData,
    currentStep,
    username,
    data,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emailToVerify, setEmailToVerify] = useState("");
  const { data: emailValidation, isLoading: isValidatingEmail } =
    useEmailVerification(emailToVerify);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAutofilled, setIsAutofilled] = useState(false);

  // Debounced email verification
  const debouncedSetEmailToVerify = debounce((email: string) => {
    setEmailToVerify(email);
  }, 200);

  // Autofill form with payment data if available
  useEffect(() => {
    if (data && data.success && !isAutofilled) {
      const customerDetails = data.customer_details;
      const customerEmail = data.customerEmail;
      
      // Prepare the new form data with values from payment data
      const newFormData = { ...formData };
      
      // Set name if available
      if (customerDetails && customerDetails.name) {
        newFormData.name = customerDetails.name;
      }
      
      // Set email from either customerEmail or customer_details.email
      if (customerEmail) {
        newFormData.email = customerEmail;
      } else if (customerDetails && customerDetails.email) {
        newFormData.email = customerDetails.email;
      }
      
      // Set phone if available
      if (customerDetails && customerDetails.phone) {
        // Format phone number (remove '+' prefix if present)
        newFormData.phone = customerDetails.phone.replace(/^\+/, '');
      }
      
      // Update form data state
      setFormData(newFormData);
      setIsAutofilled(true);
      
    }
  }, [data, formData, setFormData, isAutofilled]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Validate phone number (only allow digits)
    if (name === 'phone') {
      // Check if the input contains only numbers
      if (value && !/^\d*$/.test(value)) {
        setPhoneError("Phone number should contain only digits");
        return;
      } else {
        setPhoneError("");
      }
    }
    
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    
    // Clear error messages
    setEmailError("");
    setErrorMsg("");
    if (name === 'phone') {
      setPhoneError("");
    }
  };

  const emailVerify = async (email: string): Promise<boolean> => {
    try {
      // Basic email format validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email format");
        return false;
      }

      const { data, error }: any = await supabase
        .from("user")
        .select("*")
        .eq("email", email);
      if (data?.length > 0) {
        setEmailError("Email already exists! Please try another one");
        return false;
      }

      setEmailError("");
      return true;
    } catch (error) {
      setEmailError("Error verifying email");
      return false;
    }
  };

  // Phone number validation
  const phoneVerify = (): boolean => {
    // Check if phone number is provided
    if (!formData.phone.trim()) {
      setPhoneError("Phone number is required");
      return false;
    }
    
    // Check if phone number contains only digits
    if (!/^\d+$/.test(formData.phone)) {
      setPhoneError("Phone number should contain only digits");
      return false;
    }
    
    // Additional validation can be added here (e.g., length check)
    // if (formData.phone.length < 10) {
    //   setPhoneError("Phone number should be at least 10 digits");
    //   return false;
    // }
    
    setPhoneError("");
    return true;
  };

  const handleUserInfo = async () => {
    setIsLoading(true);

    setUsernameError("");
    try {
      // Validate email
      const emailVerified = await emailVerify(formData.email);
      if (!emailVerified) {
        setIsLoading(false);
        return;
      }
      
      // Validate phone
      const phoneVerified = phoneVerify();
      if (!phoneVerified) {
        setIsLoading(false);
        return;
      }

      setFormData({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
      });
      handleNext();
    } catch (error) {
      setErrorMsg("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  // Update form validation to include email and phone validation
  const isFormValid = () => {
    return (
      formData?.name?.trim() !== "" &&
      formData?.email?.trim() !== "" &&
      formData?.phone?.trim() !== "" &&
      formData?.password?.trim() !== "" &&
      !phoneError &&
      !emailError
    );
  };

  return (
    <div>
      <div className="instagram-card">
        <div className="card-header">
          <div className="instagram-icon-wrapper">
            <AiOutlineUser className="instagram-icon" />
          </div>
          <h2 className="card-title">Create Your Account</h2>
          <p className="card-description">Let's get started with your personal details</p>
        </div>

        {errorMsg && <p className="error-message">{errorMsg}</p>}

        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {/* Name Input */}
          <div className="input-wrapper">
            <AiOutlineUser className="input-prefix" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="instagram-input"
              placeholder="Full name"
            />
          </div>

          {/* Email Input */}
          <div className={`input-wrapper ${emailError ? 'error' : ''}`}>
            <AiOutlineMail className="input-prefix" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="instagram-input"
              placeholder="Email address"
            />
          </div>
          {emailError && (
            <p
              className="error-message"
              style={{ marginTop: "-8px", marginBottom: "0" }}
            >
              {emailError}
            </p>
          )}

          {/* Phone Input */}
          <div className={`input-wrapper ${phoneError ? 'error' : ''}`}>
            <AiOutlinePhone className="input-prefix" />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="instagram-input"
              placeholder="Phone number (numbers only)"
            />
          </div>
          {phoneError && (
            <p
              className="error-message"
              style={{ marginTop: "-8px", marginBottom: "0" }}
            >
              {phoneError}
            </p>
          )}

          {/* Password Input */}
          <div className="input-wrapper">
            <AiOutlineLock className="input-prefix" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="instagram-input"
              placeholder="Create a password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle-button"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible size={20} />
              ) : (
                <AiOutlineEye size={20} />
              )}
            </button>
          </div>
        </div>

        <div style={{ marginTop: "24px" }}>
          <button
            onClick={handleUserInfo}
            disabled={!isFormValid()}
            className="next-button"
          >
            {!isLoading ? (
              <>
                {isValidatingEmail ? "Verifying..." : "Continue"}
                <HiArrowRight className="arrow-icon" />
              </>
            ) : (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoStep;
