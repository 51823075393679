import { Button, Segmented, Switch } from "antd";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import ControlsStagingSkeleton from "../../../../../components/skeleton/DashboardView/controlsStagingSkeleton";
import HashtagSlider from "../ControlSlider/hashtagSlider";
import CustomCategoriesSection from "../../CustomCategories/CustomCategoriesSection";
import { useLoadCustomCategories } from "../../../../../Utils/customHooks/useLoadCustomCategories";
import { useLoadTopHashtags } from "../../../../../Utils/customHooks/useLoadTopHashtags";
import { useLoadPopularSearches } from "../../../../../Utils/customHooks/useLoadPopularSearches";
import {
    updateLiveAdsToggle,
    updateLiveCaptionToggle,
  updateLiveCustomCategoriesToggle,
  updateLiveHighlightsToggle,
  updateLiveLinksToggle,
  updateLivePopularSearchesToggle,
  updateLiveShoppableLinksToggle,
  updateLiveTopHashtagsToggle,
  updateLiveTrendingCategoriesToggle,
} from "../../../../../Utils/SupabaseServices/ControlsDatabase";
import { useLoadControls } from "../../../../../Utils/customHooks/useLoadControls";
import ContentOrderRadio from "../../ContentOrderRadio";
import ShoppableContentRadio from "../../ShoppableContentRadio";

const SearchResultsControls = (props: any) => {
  const { showSocials, myLinks, subscription_type, username, user_id,controlsLoading,controls,refetchControls } = props;
  const [categoryAdded, setCategoryAdded] = useState();

  const page = "follower";
  const navigate = useNavigate();
  // const {
  //   isLoading: controlsLoading,
  //   data: controls,
  //   refetch: refetchControls,
  // }: any = useLoadControls(user_id || "", page);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLoading,
    data,

    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(user_id, page);
  const maxPopularSearches = controls?.maxPopularSearches;
  const maxHashtags = controls?.maxHashtags;
  const { isLoading: topHashtagsLoading, data: topHashtags }: any =
    useLoadTopHashtags([user_id], maxHashtags);
  const { isLoading: popularSearchesLoading, data: popularSearches }: any =
    useLoadPopularSearches([user_id], username, maxPopularSearches);

    const toggleCaption = async (checked: boolean) => {
        const toggle = checked;
    
        updateLiveCaptionToggle(user_id, toggle).then(() => {
          refetchControls();
        });
      };
        const toggleAds = async (checked: boolean) => {
    const toggle = checked;

    updateLiveAdsToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };


//   const togglePopularSearches = async (checked: boolean) => {
//     const toggle = checked;
//     updateLivePopularSearchesToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };

//   const toggleTopHashtags = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveTopHashtagsToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };

//   const toggleCustomCatogories = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveCustomCategoriesToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };
//   const toggleTrendingCategories = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveTrendingCategoriesToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };
//   const toggleHighlights = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveHighlightsToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };
//   const toggleLinks = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveLinksToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };
//   const toggleShoppableLinks = async (checked: boolean) => {
//     const toggle = checked;
//     updateLiveShoppableLinksToggle(user_id, toggle).then(() => {
//       refetchControls();
//     });
//   };
//   const PreviewBtn = () => {
//     return (
//       <button
//         className="push-btn"
//         onClick={() =>
//           navigate(`/follower/${username}`, { state: { from: "dashboard" } })
//         }
//       >
//         Preview Live Page
//       </button>
//     );
//   };
  const handleCategoryAdded = (category: any) => {
    setCategoryAdded(category);
  };
  return (
    <div>
      {controlsLoading ? (
        <ControlsStagingSkeleton />
      ) : (
        <>
          <div className="controls-container">
            <div className="row">
              <div className="controls">
                <div className="dashboard-grid-items-grid">
                <div className="dashboard-grid-item">
                    <p>
                       Captions{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showCaption"
                      />
                    </p>
                    <Segmented
                      options={["Show", "Hide"]}
                      style={{
                        backgroundColor: controls?.showCaption
                          ? "#999"
                          : "#2c3e50",
                        color: "#fff",
                      }}
                      checked={controls?.showCaption}
                      onChange={(value) => {
                        toggleCaption(value === "Show");
                      }}
                    />
                    </div>
                    <div className="dashboard-grid-item">
                      <p>
                        Ads{" "}
                        <BsInfoCircle
                          className="info-tooltip"
                          data-tooltip-id="showAds"
                        />
                      </p>
                      <Segmented
                        options={["Show", "Hide"]}
                        style={{
                          backgroundColor: controls?.showAds
                            ? "#999"
                            : "#2c3e50",
                        color: "#fff",
                      }}
                        checked={controls?.showAds}
                        onChange={(value) => {
                          toggleAds(value === "Show");
                        }}
                      />
                    </div>
                    <div className="dashboard-grid-item">
                    <p>
                      Order{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="contentOrder"
                      />
                    </p>
                    <ContentOrderRadio
                      username={username}
                      user_id={user_id}
                      page={page}
                      checkedType={controls.contentOrder}
                    />
                    </div>
                    <div className="dashboard-grid-item">
                    <p>
                      Shop Button{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="shoppableContent"
                      />
                    </p>
                    <ShoppableContentRadio
                      username={username}
                      user_id={user_id}
                      page={page}
                      checkedType={controls.shoppableContent}
                    />
                    </div>

                  {/* {showSocials?.includes("instagram") &&
                    StaticVariables().instagram_CONTROLS.includes(
                      "Show Top Hashtags"
                    ) && (
                      <div className="dashboard-grid-item" id="show-hashtags">
                        <p>
                          Show Top Hashtags{" "}
                          <BsInfoCircle
                            className="info-tooltip"
                            data-tooltip-id="showTopHashtags"
                          />
                        </p>
                        <Switch
                          style={{
                            backgroundColor: controls?.showTopHashtags
                              ? "green"
                              : "rgb(241, 98, 98)",
                          }}
                          checked={controls?.showTopHashtags}
                          onChange={toggleTopHashtags}
                          disabled={!topHashtags}
                        />
                      </div>
                    )}

                  {showSocials?.includes("instagram") &&
                    StaticVariables().instagram_CONTROLS.includes(
                      "Number of Top Hashtags"
                    ) && (
                      <div className="dashboard-grid-item">
                        <p>
                          Number of Top Hashtags{" "}
                          <BsInfoCircle
                            className="info-tooltip"
                            data-tooltip-id="topHashtagsRange"
                          />{" "}
                        </p>
                        <HashtagSlider user_id={user_id} />
                      </div>
                    )}

                  {showSocials?.includes("instagram") &&
                    StaticVariables().instagram_CONTROLS.includes(
                      "Show Instagram Highlights"
                    ) && (
                      <div className="dashboard-grid-item">
                        <p>
                          Show Instagram Highlights{" "}
                          <BsInfoCircle
                            className="info-tooltip"
                            data-tooltip-id="showHighlights"
                          />
                        </p>
                        <Switch
                          style={{
                            backgroundColor: controls?.showHighlights
                              ? "green"
                              : "rgb(241, 98, 98)",
                          }}
                          checked={controls?.showHighlights}
                          onChange={toggleHighlights}
                        />
                      </div>
                    )}
                  {myLinks && (
                    <div className="dashboard-grid-item">
                      <p>
                        Show My Links{" "}
                        <BsInfoCircle
                          className="info-tooltip"
                          data-tooltip-id="showLinks"
                        />
                      </p>
                      <Switch
                        style={{
                          backgroundColor: controls?.showLinks
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={controls?.showLinks}
                        onChange={toggleLinks}
                      />
                    </div>
                  )}
                  <div className="dashboard-grid-item">
                    <p>
                      Show Shoppable Content{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showShoppableLinks"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showShoppableLinks
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showShoppableLinks}
                      onChange={toggleShoppableLinks}
                    />
                  </div>
                  <div className="dashboard-grid-item">
                    <p>
                      Show Custom Categories{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showCustomCategories"
                      />
                    </p>

                    <Switch
                      disabled={data?.length === 0}
                      style={{
                        backgroundColor:
                          data?.length === 0
                            ? "rgb(241, 98, 98)"
                            : controls?.showCustomCategories
                            ? "green"
                            : "rgb(241, 98, 98)",
                      }}
                      checked={
                        data && data.length > 0
                          ? controls?.showCustomCategories
                          : false
                      }
                      onChange={toggleCustomCatogories}
                    /> */}
                  </div>
                </div>
              </div>
            {/* </div> */}

            {/* <p className="section-heading"> Shoppable Content </p>
            <div className="row">
              <div className="controls">
              <div className="dashboard-grid-items-grid">
                  <div className="dashboard-grid-item">
                    <p>
                      Search Content Order{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="shoppableContent"
                      />
                    </p>
                    <ShoppableContentRadio
                      username={username}
                      user_id={user_id}
                      page={page}
                      checkedType={controls.shoppableContent}
                    />
                 
                  </div>
                </div>
                </div>
                </div>
            <p className="section-heading">
              {" "}
              Custom Categories{" "}
              <Button
                className="createButton"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Create
              </Button>
            </p> */}

            {/* <div className="row">
              <div className="controls">
                {data && data?.length > 0 ? (
                  <CustomCategoriesSection
                    isModalOpen={isModalOpen}
                    user_id={user_id}
                    page="live"
                    categoryAdded={categoryAdded}
                    setCategoryAdded={setCategoryAdded}
                    setIsModalOpen={setIsModalOpen}
                    handleCategoryAdded={handleCategoryAdded}
                    showCategories={true}
                  />
                ) : (
                  ""
                )}
                <Tooltip
                  className="tool-tip"
                  id="showTopHashtags"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Top Hashtags are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="topHashtagsRange"
                  place={"top"}
                  offset={4}
                  html="Sets the number of Top Hashtags shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showPopularSearches"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Popular Searches are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="popularSearchesRange"
                  place={"top"}
                  offset={4}
                  html="Sets the number of Popular Searches shown to followers."
                />

                <Tooltip
                  className="tool-tip"
                  id="showCaption"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not post captions are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showCaption"
                  place={"top"}
                  offset={4}
                  html="Controls the type of landing content is shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showComments"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not post Comments are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showFilters"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Filters are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showAds"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Ads are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showComments"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Comments are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showCustomCategories"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Custom Categories are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showTrendingCategories"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Trending Categories are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showHighlights"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Highlights are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showLinks"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not My Links are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showCategories"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Categories are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showYesterdayStories"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not Yesterday Stories are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showThisWeekStories"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not This Week Stories are shown to followers."
                />
                <Tooltip
                  className="tool-tip"
                  id="showShoppableLinks"
                  place={"top"}
                  offset={4}
                  html="Controls whether or not shoppable links are shown to followers."
                />
                 <Tooltip
              className="tool-tip"
              id="shoppableContent"
              place={"top"}
              offset={4}
              html="Control how shop button is shown to followers."
            />
              </div>
            </div> */}
            {/* <div className="push-btn-div">
              <PreviewBtn />
            </div> */}
          </div>
        </>
      )}
      <CustomCategoriesSection
        isModalOpen={isModalOpen}
        user_id={user_id}
        page="live"
        categoryAdded={categoryAdded}
        setCategoryAdded={setCategoryAdded}
        setIsModalOpen={setIsModalOpen}
        handleCategoryAdded={handleCategoryAdded}
      />
    </div>
  );
};

export default SearchResultsControls;
