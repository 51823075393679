import dayjs from "dayjs";
import { useState } from "react";
import DateSelector from "../../../components/DateSelector/DateSelector";
import Overview from "./Overview/overview";
import Trending from "./Trending/trending";
import { useLoadCustomCategories } from "../../../Utils/customHooks/useLoadCustomCategories";
import { useLoadLinks } from "../../../Utils/customHooks/useLoadLinks";
import { useLoadCompletePage } from "../../../Utils/customHooks/useLoadCompletePage";
import AnalyticsTabOptions from "./AnalyticsTabOptions";
import StayUpToDateCharts from "./GraphicalView/StayUpToDateCharts";
import { StaticVariables } from "../../../Utils/StaticVariables";
import "./Analytics.css";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import OverviewCharts from "./Overview/overviewCharts";
import OverviewSmoothCharts from "./Overview/OverviewSmoothCharts";

dayjs.extend(utc);
dayjs.extend(timezone);

const AnalyticsTabs = (props: any) => {
  const { username, user_id, subscription_type, brand_color, showSocials } =
    props;
  const {
    isLoading: customCategoriesLoading,
    data: customCategoriesData,

    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(user_id, "creator");
  const { data, error }: any = useLoadCompletePage(username, "live");
  const {
    isLoading,
    // isError,
    // error,
    data: instagramLinks,

    refetch,
  } = useLoadLinks(user_id);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [startDate, setStartDate] = useState(
    dayjs().tz(userTimeZone).subtract(30, "day").format("YYYY-MM-DD")
  );
  // dayjs.extend(utc);
  // dayjs.extend(timezone);
  const endDate = dayjs().tz(userTimeZone);

  

  const [isYesterday, setIsYesteday] = useState(false);
  const [daysNumber, setDays] = useState<any>(30);
  function updateDates(days: number) {
    setDays(days);
    if (days === 1) {
      setIsYesteday(true);
    } else {
      setIsYesteday(false);
    }
    if (days > 0) {
      setStartDate(dayjs().subtract(days, "day").format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().subtract(100, "year").format("YYYY-MM-DD"));
    }
  }

  const [activeKey, setActiveKey] = useState("Overview");
  const items = [
    {
      key: "Overview",
      label: "Overview",
      isLoacked: false,
      children: (
        // <Overview
        //   username={username}
        //   user_id={user_id}
        //   startDate={startDate}
        //   endDate={endDate}
        //   customCategories={customCategoriesData}
        //   instagramLinks={instagramLinks}
        //   showSocials={showSocials}
        //   isYesterday={isYesterday}
        //   daysNumber={daysNumber}
        //   brand_color={brand_color}
        // />
        // <OverviewCharts
        //   username={username}
        //   user_id={user_id}
        //   startDate={startDate}
        //   endDate={endDate}
        //   customCategories={customCategoriesData}
        //   instagramLinks={instagramLinks}
        //   showSocials={showSocials}
        //   isYesterday={isYesterday}
        //   daysNumber={daysNumber}
        //   brand_color={brand_color}
        // />
        <OverviewSmoothCharts
        username={username}
        user_id={user_id}
        startDate={startDate}
        endDate={endDate}
        customCategories={customCategoriesData}
        instagramLinks={instagramLinks}
        showSocials={showSocials}
        isYesterday={isYesterday}
        daysNumber={daysNumber}
        brand_color={brand_color}
      />
      ),
    },
    {
      key: "Trending",
      label: "Trending",
      isLocked: subscription_type == "basic" ? true : false,
      children: (
        <Trending
          username={username}
          user_id={user_id}
          startDate={startDate}
          endDate={endDate}
          customCategories={customCategoriesData?.filter(
            (category: any) =>
              category.queries &&
              category.queries !== null &&
              category.queries !== ""
          )}
          instagramLinks={instagramLinks}
          instagramHighlights={
            data?.combinedRes?.highlights ? data?.combinedRes?.highlights : []
          }
        />
      ),
    },
    // {
    //   key: "Graphical",
    //   label: "Graphical View",
    //   isLocked: subscription_type == "basic" ? true : false,
    //   children: (
    //     <Charts
    //       username={username}
    //       user_id={user_id}
    //       startDate={startDate}
    //       endDate={endDate}
    //       isYesterday={isYesterday}
    //       daysNumber={daysNumber}
    //     />
    //   ),
    // },
    {
      key: "Landing Page",
      label: "Landing Page",
      isLocked: subscription_type == "basic" ? true : false,
      children: (
        <StayUpToDateCharts
          username={username}
          user_id={user_id}
          startDate={startDate}
          endDate={endDate}
          isYesterday={isYesterday}
          daysNumber={daysNumber}
          brand_color={brand_color}
        />
      ),
    },
  ];

  return (
    <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().ANALYTICS_TITLE}
        </h1>
        <div className="row" id="analytics-row">
          <AnalyticsTabOptions
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            navTabs={items}
            subscription_type={subscription_type}
            handleDateChange={updateDates}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTabs;
