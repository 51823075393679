import { useState } from "react";
import { useQuery } from "react-query";
import { getEmbedding } from "../../../Utils/SupabaseServices/ContentDatabase";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadEmbeddingCount(
  userIds: any,
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any,
  threshold: any,
  showSocials: any
) {
  //   filter = filter.toLowerCase().replace(/\s+/g, " ").trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];
    let productFilterItems: any = [];
    // if (userIds && userIds.length === 1) {
    if (filter) {
      const elementsCount = 5000;
      // const embedding = await getEmbedding(queryTerm);
      const connectingWords = StaticVariables().connectingWords;

      // Split the sentence into words
      let words = filter.split(" ");
      if (words.includes("shop") || words.includes("buy")) {

        words = words.filter((word: any) => word !== "shop" && word !== "buy");
      }
      // Filter out connecting words
      const filteredWords = words.filter(
        (word: any) => !connectingWords.includes(word.toLowerCase())
      );

      // Join the filtered words back into a sentence
      const result = filteredWords.join(" ");
      const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
      const embedding = await getEmbedding(pgQuery);
      const { data: countResult, error: countError } = await supabase.rpc(
        // "semantic_ssearchcountembedpostidsimi",
        "semantic_ssearch_count_embed_post_ids_content",
        {
          query_string: pgQuery,
          query_embedding: embedding,
          similarity_threshold: threshold,

          // match_count: elementsCount,
          userid: userIds,
          username: usernames,
          socials: showSocials,
          index_type: index,
          // filter:filter
        }

        // platforms
      );
      let resCount = countResult.length;
      postIds = countResult.map((item: any) => item.post_id);
      productFilterItems = countResult;
      feedCount = resCount;
    } else {
      feedCount = 0;
    }

    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      productFilterItems: productFilterItems,
      postIds: postIds || null,
    };
  }

  return useQuery(
    ["getLoadEmbedPages", filter, userIds, index, postsPerPage],
    getCount,
    {
      enabled: Boolean(threshold) && Boolean(showSocials),
    }
  );
}
