import React, { useState } from "react";
import { MdOutlineDragIndicator } from "react-icons/md";
import CopyComponent from "../../../../components/CopyComponent/CopyComponent";
import StatusSelector from "../../../../components/StatusSelector/StatusSelector";
import { Button } from "antd";
import SubComponents from "../../StayUpToDate/StayUpToDateComponents/SubComponents";
import EditMyLinks from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditMyLinks";
import EditAddLinks from "../../StayUpToDate/LandingPageComponents/LinksComponent/EditAddLinks";
import EditHighlightLinks from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditHighlightLinks";
import EditCustomBanner from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditCustomBanner";
import EditTrendingProducts from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/TrendingProducts/EditTrendingProducts";
import EditQueryBasedCarousel from "../QueryBasedCarousel/EditQueryBasedCarousel";
import EditYesterdayContent from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditYesterdayContent";
import EditProductBank from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditProductBank";
import EditYesterdayLinks from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/EditYesterdayLinks";
import EditCustomLinks from "../CustomCarousel/EditCustomLinks";
import EditCustomContent from "../CustomCarousel/EditCustomContent";
import EditContentTypePosts from "../../StayUpToDate/StayUpToDateComponents/EditTemplates/ContentTypePosts/EditContentTypePosts";

const SubComponentsWrapper = (props: any) => {
  const {
    user_id,
    username,
    component,
    refetch,
    brand_color,
    handleDelete,
    refatchStayUpToDate,
    setStatus,
    selectedComponent,
    setSelectedComponent,
    isEdit,
    setIsEdit,
    isEditModalOpen,
    setIsEditModalOpen,
    from
  } = props;

  const [isContentEditModalOpen, setIsContentEditModalOpen] = useState(false);
  const [isLinksEditModalOpen, setIsLinksEditModalOpen] = useState(false);
  const [isAddLinkEditModalOpen, setIsAddLinkEditModalOpen] = useState(false);
  const [isMyLinkEditModalOpen, setIsMyLinkEditModalOpen] = useState(false);
  const [isQueryLinksEditModalOpen, setIsQueryLinksEditModalOpen] =
    useState(false);
  const [isTrendingProductsEditModalOpen, setIsTrendingProductsEditModalOpen] =
    useState(false);
  const [isYesterdayLinksEditModalOpen, setIsYesterdayLinksEditModalOpen] =
    useState(false);
  const [isYesterdayContentEditModalOpen, setIsYesterdayContentEditModalOpen] =
    useState(false);
  const [isProductBankEditModalOpen, setIsProductBankEditModalOpen] =
    useState(false);
  const [isBannerEditModalOpen, setIsBannerEditModalOpen] = useState(false);
  const [isHighlightEditModalOpen, setIsHighlightEditModalOpen] =
    useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [initialContent, setInitialContent] = useState(false);
  const [initialLinks, setInitialLinks] = useState(false);
  const [initial, setInitial] = useState(false);
  const [isFormEditModalOpen, setIsFormEditModalOpen] = useState(false);
  const [isContentTypePostsEditModalOpen, setIsContentTypePostsEditModalOpen] =
    useState(false);
  const [isDaywiseEditModalOpen, setIsDaywiseEditModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [platform, setPlatform] = useState("");
  async function handleEdit(name: any) {
    if (name.type === "content") {
      setIsContentEditModalOpen(true);
    } else if (name.type === "links") {
      setIsLinksEditModalOpen(true);
    } else if (name.type === "links-section") {
      setIsAddLinkEditModalOpen(true);
    } else if (name.type === "my-links" || name.display_name === "My Links") {
      setIsMyLinkEditModalOpen(true);
    } else if (name.type === "form" || name.display_name === "Form") {
      setIsMyLinkEditModalOpen(true);
    } else if (name.type === "query-links" || name.type === "query-content") {
      setIsQueryLinksEditModalOpen(true);
    } else if (name.type === "trending products") {
      setIsTrendingProductsEditModalOpen(true);
    } else if (name.display_name === "This Week Content") {
      setIsYesterdayContentEditModalOpen(true);
    } else if (
      name.display_name.split(",").includes("Yesterday") ||
      name.display_name.split(",").includes("This Week") ||
      name.display_name.split(",").includes("This Week Content") ||
      name.display_name.split(",").includes("This Month") 
     
    ) {
      setIsYesterdayLinksEditModalOpen(true);
    } else if(
      name.display_name.split(",").includes("Monday") ||
      name.display_name.split(",").includes("Tuesday") ||
      name.display_name.split(",").includes("Wednesday") ||
      name.display_name.split(",").includes("Thursday") ||
      name.display_name.split(",").includes("Friday") ||
      name.display_name.split(",").includes("Saturday") ||
      name.display_name.split(",").includes("Sunday") ||
      name.display_name.split(",").includes("This Weekend")

    )
    {
      setIsDaywiseEditModalOpen(true);
    }
    
    else if (name.type === "product-bank") {
      setIsProductBankEditModalOpen(true);
    } else if (name.type === "banner") {
      setIsBannerEditModalOpen(true);
    } else if (name.type === "highlight") {
      setIsHighlightEditModalOpen(true);
    } else if (
      name.display_name === "reels" ||
      name.display_name === "tiktok" ||
      name.display_name === "youtube" ||
      name.display_name === "podcast" ||
      name.display_name === "featured"
    ) {
      if (name.display_name === "reels") {
        setContentType("REELS");
        setPlatform("instagram");
      } else if (name.display_name === "tiktok") {
        setContentType("TIKTOK");
        setPlatform("tiktok");
      } else if (name.display_name === "youtube") {
        setContentType("VIDEO");
        setPlatform("youtube");
      } else if (name.display_name === "podcast") {
        setContentType("PODCAST");
        setPlatform("podcast");
      } else if (name.display_name === "featured") {
        setContentType("FEATURE");
        setPlatform("featured");
      }
      setIsContentTypePostsEditModalOpen(true);
    }

    setSelectedComponent(name);
  }
  return (
    <>
      <div className="component-item">
        <div className="component-text" {...props.dragHandleProps}>
          <MdOutlineDragIndicator className="outline-drag" />
          {/* //fdjfdf */}
        </div>
        <div className="button-container">
          <CopyComponent
            user_id={user_id}
            username={username}
            component={component}
          />

          <StatusSelector
            defaultValue={component.status}
            component={component}
            user_id={user_id}
            refetch={refetch}
            setStatus={setStatus}
            refatchStayUpToDate={refatchStayUpToDate}
            from={from}
          />
          {component.type === "links" ||
            component.type === "query-links" ||
            component.type === "query-content" ||
            component.type === "links-section" ||
            component.type === "content" ||
            component.type === "banner" ||
            component.type === "highlight" ||
            component.type === "product-bank" ||
            component.type === "trending products" ||
            component.display_name === "My Links" ||
            component.type === "my-links" ||
            component.display_name === "Form" ||
            component.type === "form" ||
            component.display_name.split(",").includes("Monday") ||
            component.display_name.split(",").includes("Tuesday") ||
            component.display_name.split(",").includes("Wednesday") ||
            component.display_name.split(",").includes("Thursday") ||
            component.display_name.split(",").includes("Friday") ||
            component.display_name.split(",").includes("Saturday") ||
            component.display_name.split(",").includes("Sunday") ||
            component.display_name.split(",").includes("This Weekend") ||
            component.display_name.split(",").includes("Yesterday") ||
            component.display_name.split(",").includes("This Month") ||
            component.display_name.split(",").includes("This Week") ||
            component.display_name.split(",").includes("This Week Content") ||
            component.display_name === "reels" ||
            component.display_name === "tiktok" ||
            component.display_name === "podcast" ||
            component.display_name === "youtube" ||
            component.display_name === "featured" ? (
            <Button
              className="edit-btn"
              onClick={() => {
                handleEdit(component);
              }}
            >
              Edit
            </Button>
          ) : (
            ""
          )}
          <Button
            className="remove-btn"
            onClick={() => handleDelete(component.name)}
          >
            Remove
          </Button>
        </div>
      </div>
      {component.type !== "banner" && component.type !== "highlight" && (
        <div className="component-name-container">
          <span className="component-name">
            {component.display_name === "Form" && component.name === "Form"
              ? ""
              : component.name}
          </span>
        </div>
      )}
      <SubComponents
        user_id={user_id}
        username={username}
        component={component}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        refetch={refetch}
        brand_color={brand_color}
        from={from}
      />

      <EditCustomContent
        username={username}
        user_id={user_id}
        isContentEditModalOpen={isContentEditModalOpen}
        setIsContentEditModalOpen={setIsContentEditModalOpen}
        type="content"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        initial={initialContent}
        setInitial={setInitialContent}
        from={from}
      />
      <EditCustomLinks
        username={username}
        user_id={user_id}
        isLinksEditModalOpen={isLinksEditModalOpen}
        setIsLinksEditModalOpen={setIsLinksEditModalOpen}
        type="links"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        initial={initialLinks}
        setInitial={setInitialLinks}
        from={from}
      />

      <EditProductBank
        username={username}
        user_id={user_id}
        isProductBankEditModalOpen={isProductBankEditModalOpen}
        setIsProductBankEditModalOpen={setIsProductBankEditModalOpen}
        type="product-bank"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        initial={initial}
        setInitial={setInitial}
        from={from}
      />
      <EditYesterdayLinks
        username={username}
        user_id={user_id}
        isYesterdayLinksEditModalOpen={isYesterdayLinksEditModalOpen}
        setIsYesterdayLinksEditModalOpen={setIsYesterdayLinksEditModalOpen}
        type="links"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />
      <EditYesterdayLinks
        username={username}
        user_id={user_id}
        isYesterdayLinksEditModalOpen={isDaywiseEditModalOpen}
        setIsYesterdayLinksEditModalOpen={setIsDaywiseEditModalOpen}
        type="links"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
        componentType={"daywise"}
      />
      <EditYesterdayContent
        username={username}
        user_id={user_id}
        isYesterdayLinksEditModalOpen={isYesterdayContentEditModalOpen}
        setIsYesterdayLinksEditModalOpen={setIsYesterdayContentEditModalOpen}
        type="links"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />
      <EditQueryBasedCarousel
        username={username}
        user_id={user_id}
        isQueryLinksEditModalOpen={isQueryLinksEditModalOpen}
        setIsQueryLinksEditModalOpen={setIsQueryLinksEditModalOpen}
        type="query"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetchDashboard={refetch}
        initial={initial}
        setInitial={setInitial}
        edit={edit}
        setEdit={setEdit}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        from={from}
      />
      <EditTrendingProducts
        username={username}
        user_id={user_id}
        isTrendingProductsEditModalOpen={isTrendingProductsEditModalOpen}
        setIsTrendingProductsEditModalOpen={setIsTrendingProductsEditModalOpen}
        type="trending products"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetchDashboard={refetch}
        from={from}
      />

      <EditCustomBanner
        username={username}
        user_id={user_id}
        isBannerEditModalOpen={isBannerEditModalOpen}
        setIsBannerEditModalOpen={setIsBannerEditModalOpen}
        type="banner"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />
      <EditHighlightLinks
        username={username}
        user_id={user_id}
        isHighlightEditModalOpen={isHighlightEditModalOpen}
        setIsHighlightEditModalOpen={setIsHighlightEditModalOpen}
        type="highlight"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />

      <EditMyLinks
        username={username}
        user_id={user_id}
        isMyLinkEditModalOpen={isMyLinkEditModalOpen}
        setIsMyLinkEditModalOpen={setIsMyLinkEditModalOpen}
        type="my-link"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />

      <EditMyLinks
        username={username}
        user_id={user_id}
        isMyLinkEditModalOpen={isFormEditModalOpen}
        setIsMyLinkEditModalOpen={setIsFormEditModalOpen}
        type="form"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />
      <EditAddLinks
        username={username}
        user_id={user_id}
        isAddLinkEditModalOpen={isAddLinkEditModalOpen}
        setIsAddLinkEditModalOpen={setIsAddLinkEditModalOpen}
        type="links-section"
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetch={refetch}
        from={from}
      />
      <EditContentTypePosts
        username={username}
        user_id={user_id}
        isContentTypePostsEditModalOpen={isContentTypePostsEditModalOpen}
        setIsContentTypePostsEditModalOpen={setIsContentTypePostsEditModalOpen}
        type={contentType}
        component={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        refetchDashboard={refetch}
        platform={platform}
        from={from}
      />
    </>
  );
};

export default SubComponentsWrapper;
