import { Button, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import YesterdayLinksComponents from "./SubListComponents/YesterdayLinksComponents";
import ThisWeekLinksComponents from "./SubListComponents/ThisWeekLinksComponents";
import DayWiseLinksComponents from "./SubListComponents/DayWiseLinksComponents";
import "../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import TrendingProductsComponent from "./SubListComponents/TrendingProductsComponent/TrendingProductsComponent";
import AddQueryLinksComponents from "../../../LandingPageCarousels/QueryBasedCarousel/AddQueryLinksComponents";
import AddCustomLinksComponent from "../../../LandingPageCarousels/CustomCarousel/AddCustomLinksComponent";
import { ModalBackButton } from "../../../../../components/EditModalComponents.tsx/ModalBackButton";

const ShopLinksComponents = (props: any) => {
  const { isShoplinksModalOpen, username, user_id, StayUpToDateComponents, from } =
    props;

  const [isYesterdayLinksComponentOpen, setIsYesterdayLinksComponentOpen] =
    useState(false);
  const [isMonthLinksComponentOpen, setIsMonthLinksComponentOpen] =
    useState(false);
  const [isThisWeekLinksComponentOpen, setIsThisWeekLinksComponentOpen] =
    useState(false);
  const [isDayWiseLinksComponentOpen, setIsDayWiseLinksComponentOpen] =
    useState(false);
  const [isCustomLinksComponentOpen, setIsCustomLinksComponentOpen] =
    useState(false);
  const [isQueryLinksComponentOpen, setIsQueryLinksComponentOpen] =
    useState(false);
  const [isTrendingProductsModalOpen, setIsTrendingProductsModalOpen] =
    useState(false);

  const handleYesterdayLinks = () => {
    setIsYesterdayLinksComponentOpen(true);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
  };

  const handleMonthLinks = () => {
    setIsMonthLinksComponentOpen(true);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
  };
  const handleThisWeekLinks = () => {
    setIsThisWeekLinksComponentOpen(true);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleDayWiseLinks = () => {
    setIsDayWiseLinksComponentOpen(true);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleCustomLinks = () => {
    setIsCustomLinksComponentOpen(true);
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  const handleQueryLinks = () => {
    setIsQueryLinksComponentOpen(true);
    setIsMonthLinksComponentOpen(false);
    setIsCustomLinksComponentOpen(false);
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  };
  function handleTrendingProducts() {
    setIsTrendingProductsModalOpen(true);
    setIsQueryLinksComponentOpen(false);
    setIsMonthLinksComponentOpen(false);
    setIsCustomLinksComponentOpen(false);
    setIsDayWiseLinksComponentOpen(false);
    setIsThisWeekLinksComponentOpen(false);
    setIsYesterdayLinksComponentOpen(false);
    props.setIsModalOpen(false);
    props.setIsShoplinksModalOpen(false);
  }
  const data = [
    {
      // icon:,
      title: "Yesterday's Shoppable Content",
      key: "yesterday-shoppable-content",
      description: "Automated",
      component: handleYesterdayLinks,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "Yesterday"
      ),

      //   task: true,
    },
    {
      // icon:,
      title: "This Month's Shoppable Content",
      description: "Automated",
      component: handleMonthLinks,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "This Month"
      ),
    },
    {
      // icon:,
      title: "This Week's Shoppable Content",
      key: "this-weeks-shoppable-content",
      description: "Automated",
      component: handleThisWeekLinks,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "This Week"
      ),
    },
    {
      // icon:,
      title: "Shoppable Content by the Day",
      key: "shoppable-content-by-the-day",
      description: "Automated",
      task: true,
      component: handleDayWiseLinks,
    },
    {
      title: "Trending Products",
      key: "trending-products",
      description: "Automated",
      task: true,
      component: handleTrendingProducts,
      disabled: StayUpToDateComponents?.data.some(
        (obj: any) => obj.display_name === "Trending Products"
      ),
    },
    {
      // icon:,
      title: "Shoppable Content Based on a Word or Phrase",
      key: "shoppable-content",
      description: "Automated",
      task: true,
      component: handleQueryLinks,
    },
    {
      // icon:,
      // title: "Custom Shoppable Content Carousel",
      title: "Custom Shoppable Carousel",
      key: "custom-shoppable-content",
      description:
        "Manual | Create a carousel using any of your shoppable content",
      task: true,
      component: handleCustomLinks,
    },

    // {
    //   // icon:,
    //   title: "Ant Design Title 4",
    // },
  ];

  const handleOk = () => {
    props.setIsShoplinksModalOpen(false);
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsShoplinksModalOpen(false);
  };

  function handleTask(title: any) {}
  return (
    <>
      <Modal
        open={isShoplinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton
          handlePrevious={handlePrevious}
          title="Shoppable Carousel Options"
          description="Choose the type of shoppable carousel to add to your page"
        />
        <div className="components-container">
          <h2
            className="components-section-header"
            style={{ marginBottom: "0" }}
          >
            Automated Carousels
          </h2>
          <div className="components-section">
            <div
              className="components-grid"
              style={{ gridTemplateColumns: "1fr" }}
            >
              {data
                .filter(
                  (item) =>
                    item.description?.includes("Automated") &&
                    !item.description?.includes("Manual")
                )
                .map((item, index) => (
                  <div
                    key={index}
                    className={`component-card ${
                      item.disabled ? "disabled" : ""
                    }`}
                    onClick={!item.disabled ? item.component : undefined}
                  >
                    <div className="component-card-inner">
                      <div
                        style={{
                          flex: 1,
                          paddingRight: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <h4
                          style={{ marginBottom: "4px", whiteSpace: "normal" }}
                        >
                          {item.title}
                        </h4>
                        {/* Only show description if it contains more info than just "Automated" */}
                        {item.description &&
                          !item.description.trim().match(/^Automated$/i) && (
                            <p
                              className="item-disc"
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                color: "#777",
                              }}
                            >
                              {item.description.replace(
                                /^Automated\s*\|?\s*/i,
                                ""
                              )}
                            </p>
                          )}
                      </div>
                      {item.task ? (
                        <button
                          className="task-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTask(item.title);
                          }}
                        >
                          <span
                            className="component-icon task-icon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiArrowRight style={{ margin: "auto" }} />
                          </span>
                        </button>
                      ) : (
                        <span
                          className="component-icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0,
                          }}
                        >
                          <AiOutlinePlus size={16} style={{ margin: "auto" }} />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <h2
            className="components-section-header"
            style={{ marginBottom: "0", marginTop: "20px" }}
          >
            Custom Carousels
          </h2>
          <div className="components-section">
            <div
              className="components-grid"
              style={{ gridTemplateColumns: "1fr" }}
            >
              {data
                .filter((item) => item.description?.includes("Manual"))
                .map((item, index) => (
                  <div
                    key={index}
                    className={`component-card ${
                      item.disabled ? "disabled" : ""
                    }`}
                    onClick={!item.disabled ? item.component : undefined}
                  >
                    <div className="component-card-inner">
                      <div
                        style={{
                          flex: 1,
                          paddingRight: "15px",
                          overflow: "hidden",
                        }}
                      >
                        <h4
                          style={{ marginBottom: "4px", whiteSpace: "normal" }}
                        >
                          {item.title}
                        </h4>
                        {item.description &&
                          !item.description.match(/^Manual$/i) && (
                            <p
                              className="item-disc"
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                color: "#777",
                              }}
                            >
                              {item.description.replace(
                                /^Manual\s*\|?\s*/i,
                                ""
                              )}
                            </p>
                          )}
                      </div>
                      {item.task ? (
                        <button
                          className="task-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTask(item.title);
                          }}
                        >
                          <span
                            className="component-icon task-icon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiArrowRight style={{ margin: "auto" }} />
                          </span>
                        </button>
                      ) : (
                        <span
                          className="component-icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0,
                          }}
                        >
                          <AiOutlinePlus size={16} style={{ margin: "auto" }} />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <YesterdayLinksComponents
        isYesterdayLinksComponentOpen={isYesterdayLinksComponentOpen}
        setIsYesterdayLinksComponentOpen={setIsYesterdayLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />{" "}
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={isThisWeekLinksComponentOpen}
        setIsThisWeekLinksComponentOpen={setIsThisWeekLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        title="This Week's Shoppable Content"
        description="All of your shoppable content posted in the last 7 days will always be shown in this carousel. Content will automatically be updated!"
        from={from}
      />
      <ThisWeekLinksComponents
        isThisWeekLinksComponentOpen={isMonthLinksComponentOpen}
        setIsThisWeekLinksComponentOpen={setIsMonthLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        title="This Month's Shoppable Content"
        description="All of your shoppable content posted in this month will always be shown in this carousel. Content will automatically be updated!"
        from={from}
      />
      <DayWiseLinksComponents
        isDayWiseLinksComponentOpen={isDayWiseLinksComponentOpen}
        setIsDayWiseLinksComponentOpen={setIsDayWiseLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        StayUpToDateComponents={StayUpToDateComponents}
        from={from}
      />
      <AddCustomLinksComponent
        isCustomLinksComponentOpen={isCustomLinksComponentOpen}
        setIsCustomLinksComponentOpen={setIsCustomLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <AddQueryLinksComponents
        isQueryLinksComponentOpen={isQueryLinksComponentOpen}
        setIsQueryLinksComponentOpen={setIsQueryLinksComponentOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
      <TrendingProductsComponent
        isTrendingProductsModalOpen={isTrendingProductsModalOpen}
        setIsTrendingProductsModalOpen={setIsTrendingProductsModalOpen}
        setIsShoplinksModalOpen={props.setIsShoplinksModalOpen}
        user_id={user_id}
        username={username}
        refetch={props.refetch}
        from={from}
      />
    </>
  );
};

export default ShopLinksComponents;
