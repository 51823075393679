import React, { useState } from "react";
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { TabTitle } from "../../../Utils/GeneralFunctions";
import { loginEmail } from "../../../Utils/SupabaseServices/UserDatabase";
import { supabase } from "../../../config/supabaseClient";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";
import ResetPassword from "../ResetPassword/resetPassword";
import "../../../Screens/Auth/OnboardingFlow/OnboardingPage.css";
import {
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isResetPassword = searchParams.get('mode') === 'reset';
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const userId = user?.id || "";
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  TabTitle(isResetPassword ? "Reset Password • Bubble" : "Login • Bubble");

  React.useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: userId || "",
    });
  }, [location.pathname, userId]);

  const switchMode = (mode: 'login' | 'reset') => {
    setSearchParams({ mode: mode });
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    if (error) setError("");
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (error) setError("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!username || !password) {
      setError("Username and password are required");
      return;
    }
    
    setLoading(true);
    
    try {
      // Get email from username
      const { data, error: userError } = await supabase
        .from("user")
        .select("email")
        .eq("username", username);
      
      if (userError || !data || data.length === 0) {
        setError("Username not found");
        setLoading(false);
        return;
      }
      
      const email = data[0]?.email;
      const signInData = await loginEmail(email, password);

      if (!signInData) {
        navigate("/404");
      } else if (signInData === "error") {
        setError("Invalid login credentials!");
      } else if (signInData) {
        navigate(`/dashboard/${signInData}`);
      }
    } catch (err) {
      setError("An unexpected error occurred");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (isResetPassword) {
    return (
      <div className="onboarding-page">
        <ResetPassword />
      </div>
    );
  }

  return (
    <div className="onboarding-page">
      <div className="instagram-card">
        <div className="card-header">
          <div className="instagram-icon-wrapper">
            <AiOutlineUser className="instagram-icon" />
          </div>
          <h2 className="card-title">Welcome Back</h2>
          <p className="card-description">Sign in to your account</p>
        </div>

        {error && <p className="error-message">{error}</p>}

        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {/* Username Input */}
          <div className="input-wrapper">
            <AiOutlineUser className="input-prefix" />
            <input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              className="instagram-input"
              placeholder="Username"
            />
          </div>

          {/* Password Input */}
          <div className="input-wrapper">
            <AiOutlineLock className="input-prefix" />
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              className="instagram-input"
              placeholder="Password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle-button"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible size={20} />
              ) : (
                <AiOutlineEye size={20} />
              )}
            </button>
          </div>

          {/* Reset Password Link */}
          <div style={{ textAlign: "center" }}>
            <button 
              type="button" 
              onClick={() => switchMode('reset')} 
              style={{ 
                background: "none", 
                border: "none", 
                color: "rgba(255, 255, 255, 0.7)", 
                cursor: "pointer",
                fontSize: "14px"
              }}
            >
              Forgot Password?
            </button>
          </div>

          {/* Submit Button */}
          <div style={{ marginTop: "8px" }}>
            <button
              type="submit"
              disabled={loading || !username || !password}
              className="next-button"
            >
              {!loading ? (
                <>
                  Sign In
                  <HiArrowRight className="arrow-icon" />
                </>
              ) : (
                <AiOutlineLoading3Quarters className="animate-spin" />
              )}
            </button>
          </div>
          
          {/* Sign Up Link */}
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <p style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "14px" }}>
              Don't have an account?{" "}
              <a 
                href="/subscribe" 
                style={{ 
                  color: "#4f56e3", 
                  textDecoration: "none", 
                  fontWeight: "500" 
                }}
              >
                Sign up for free
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;