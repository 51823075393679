import {
  BsFillPlayFill,
  BsHourglassSplit,
  BsLink45Deg,
  BsX,
} from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  handleRedirect,
  handleShop,
  handleTagged,
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./TwitterPost.css";
import Caption from "../Caption";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import React from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { RiTwitterLine } from "react-icons/ri";
import "../CommentsPost/CommentsPost.css";
import "../PostCarousel.css";
import { Card } from "react-bootstrap";
import VideoPlayer from "../VideoTag";
import PostCarousel from "../PostCarousel";
import PostInfo from "../../PostInfo/PostInfo";

export type TwitterPostType = Post & {
  platform: "twitter";
  metadata: {
    to: string;
    from: string;
    description: string;
    threadId: string;
  };
};

export default function TwitterPost({
  post,
  showCaption,
  shoppableContent,
  user_id,
  showAsAd,
  isGlobalView,
  username,
  platform
}: {
  post: TwitterPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id: any;
  showAsAd: any;
  username: any;
  platform:any
}) {
  // const { user } = useAuth();
  // const user_id = user?.id || "";

  const { post_date, post_id, permalink,media_url,shop_link,tagged_link,external_link } = post;

  const RefTag = ({ showAsAd, post_date, permalink, post_id }: any) => {
    return (
      // <div className="creator-feed-content-info creator-feed-display ">
      //   {showAsAd !== true ? (
      //     <>
      //       {new Date(post_date).getFullYear() > 2000 ? (
      //         <p className="creator-feed-content-date">
      //           {new Intl.DateTimeFormat("en-US", {
      //             year: "numeric",
      //             day: "numeric",
      //             month: "long",
      //           }).format(new Date(post_date))}
      //         </p>
      //       ) : (
      //         ""
      //       )}
      //       <div className="creator-feed-post-links display-flex">
      //         {permalink ? (
      //           <>
      //             <a href={permalink} target="_blank" rel="noreferrer">
      //               <BsLink45Deg
      //                 className="bs-link"
      //                 onMouseEnter={(e: any) => {
      //                   e.target.style.color = "#888";
      //                 }}
      //                 onMouseLeave={(e: any) => {
      //                   e.target.style.color = "#000";
      //                 }}
      //                 onClick={() => handleRedirect(post_id)}
      //               />
      //             </a>
      //           </>
      //         ) : (
      //           ""
      //         )}
      //         {permalink ? (
      //           <a
      //             // style={{ textDecoration: "none" }}
      //             href={permalink}
      //             target="_blank"
      //             rel="noreferrer"
      //           >
      //             <img
      //               src="/assets/icons8-twitterx-30.png"
      //               className="social-img"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id)}
      //             />
      //           </a>
      //         ) : (
      //           <img
      //             src="/assets/icons8-twitterx-30.png"
      //             className="insta-height"
      //           />
      //         )}
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       <p className="creator-feed-content-date">Sponsored</p>
      //       <div className="creator-feed-post-links display-flex">
      //         {permalink ? (
      //           <a href={permalink} target="_blank" rel="noreferrer">
      //             <BsLink45Deg
      //               className="bs-link"
      //               onMouseEnter={(e: any) => {
      //                 e.target.style.color = "#888";
      //               }}
      //               onMouseLeave={(e: any) => {
      //                 e.target.style.color = "#000";
      //               }}
      //               onClick={() => handleRedirect(post_id)}
      //             />
      //           </a>
      //         ) : (
      //           ""
      //         )}
      //       </div>
      //     </>
      //   )}
      // </div>
      <PostInfo
      username={username}
      user_id={user_id}
      showAsAd={showAsAd}
      post_date={post_date}
      permalink={permalink}
      shop_link={shop_link}
      tagged_link={tagged_link}
      external_link={external_link}  
      post_id={post_id}
      handleRedirect={handleRedirect}
      handleShop={handleShop}
      handleTagged={handleTagged}
      platform={platform}
      media_url={media_url}
      shoppableContent={shoppableContent}
    />
    );
  };

  const ImagePostTag = React.memo((props: any) => {
    const {
      media_url,
      showAsAd,
      post_date,
      permalink,
      post_id,
      username,
      shop_link,
      tagged_link,
    } = props;
    const style: any = {
      width: "100%",
      height: "500px",
      overflow: "hidden",
      borderRadius: "5px",
      position: "relative",
    };
    return (
      <>
        {media_url === "" ? (
          <div className="creator-feed-card-video not-available">
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <div className="creator-feed-image-wrapper">
            <Card.Img
              variant="top"
              src={media_url ? media_url : "/assets/dummy_video.png"}
              className="creator-feed-card-video-image"
              loading="eager"
              style={{
                backgroundColor: "#f1f1f1",
                ...(media_url.includes("highlight") ||
                media_url.includes("stories")
                  ? style
                  : {}),
              }}
            />
            <RefTag
              media_url={media_url}
              showAsAd={showAsAd}
              post_date={post_date}
              permalink={permalink}
              shop_link={shop_link}
              post_id={post_id}
              username={username}
              tagged_link={tagged_link}
            />
          </div>
        )}
      </>
    );
  });

  return (
    <div>
      {post.media_url ? (
        post.media_url.split(",").length > 1 &&
        post.media_url.split(",").every((url) => url.trim() !== "") ? (
          <PostCarousel
            post={post}
            username={username}
            user_id={user_id}
            isGlobalView={isGlobalView}
            showAsAd={showAsAd}
            shop_link={post.shop_link}
            shoppableContent={shoppableContent}
            platform="twitter"
          />
        ) : (
          <>
            {post.media_url.includes("video") ? (
              <>
                <VideoPlayer
                  media_url={post.media_url}
                  showAsAd={showAsAd}
                  post_date={post.post_date}
                  permalink={post.permalink}
                  post_id={post.post_id}
                  // username={username}
                  thumbnail={post.thumbnail}
                  shop_link={post.shop_link}
                  tagged_link={post.tagged_link}
                />

                <RefTag
                  showAsAd={showAsAd}
                  post_date={post_date}
                  permalink={permalink}
                  post_id={post_id}
                />
              </>
            ) : (
              <ImagePostTag
                media_url={post.media_url}
                showAsAd={showAsAd}
                post_date={post.post_date}
                permalink={post.permalink}
                post_id={post.post_id}
                username={username}
                shop_link={post.shop_link}
                tagged_link={post.tagged_link}
              />
            )}
          </>
        )
      ) : (
        <div style={{ alignSelf: "center" }}>
          <>
            <div className="twitter-post-img-wrp">
              <div className="twitter-post-img">
                <div className="twitter-post-img-date">
                <span className="twitter-label">X Post</span>
                <br/>

                  <span className="date">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                    }).format(new Date(post_date))}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </>
          <RefTag
            showAsAd={showAsAd}
            post_date={post_date}
            permalink={permalink}
            post_id={post_id}
          />
        </div>
      )}
      {showCaption && (
        <>{post.caption ? <Caption caption={post.caption} /> : ""}</>
      )}
      {/* <div className="twitter-post-img-wrp">
        {post.caption ? (
          <div className="post-comment">
            <p
              className="post-caption"
            >
              {convertToClickable(post.caption)}
            </p>
          </div>
        ) : (
          <>
            <div className="twitter-post-img">
              <div className="twitter-post-img-date">
                <span className="date">
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                  }).format(new Date(post_date))}
                </span>
                <br />
                <span className="twitter-label">Twitter</span>
              </div>
            </div>
          </>
        )}
      </div> */}
    </div>
  );
}
