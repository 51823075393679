import { Button } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";
import {
  getMediaSrc,
  redirectToUrl,
} from "../../Utils/StayUpToDate/RenderComponent";
import ProductName from "../ProductName/ProductName";
import ProductShopButton from "../ProductName/ProductShopButton";

const ImagePostTag = React.memo((props: any) => {
  const {
    data,
    brand_color,
    title,
    redirectVariable,
    isLinks,
    isDashboard,
    type,
    isYoutube,
    isHasMediaUrl,
    productName,
    media_url,
    isCarousel,
    contentShape,
    handleRedirect,
  } = props;

  const redirectUrl: any = redirectToUrl(data, isLinks);
  const getWrapperClassName = () => {
    const baseClass = "creator-feed-image-wrapper-links";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("square-wrapper");
    else if (contentShape) classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");
    // else if (!data?.media_url) classes.push("not-available");

    return classes.join(" ");
  };

  const getImageClassName = () => {
    const baseClass = "creator-feed-card-links-image";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("media-feed-height");
    else if (contentShape) classes.push("square-image");
    else if (isYoutube) classes.push("youtube-feed-height");

    return classes.join(" ");
  };
  return (
    <a
      href={redirectUrl}
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        handleRedirect(
          data?.post_id,

          getMediaSrc(type, data, isCarousel, media_url),
          title,
          data?.product_name,
          data?.platform,
          isLinks
        );
        if (redirectUrl) {
          window.open(
            redirectUrl,
            // data?.shop_link && data?.shop_link !== ""
            //   ? data?.shop_link
            //   : data.permalink
            //     ? data.permalink
            //     : data?.table_url,
            "_blank"
          );
        }
      }}
    >
      {!data?.media_url ? (
        data?.platform === "newsletter" ||
        data?.platform === "linkedin" ||
        data?.platform === "twitter" ? (
          <>
            <div
              className={getWrapperClassName()}
              style={{
                textAlign: "center",
                height: "100%",
                borderRadius: "1rem",
                color: "#000",
              }}
            >
              <div
                className={`newsletter-media-carousel ${getImageClassName()} no-media-url`}
                // style={{backgroundColor: "black", color: "white", borderRadius: "1rem", border: "1px solid #e6e7eb"}}
              >
                <h4 className="newsletter-label">
                  {data?.platform === "newsletter"
                    ? "Newsletter"
                    : data?.platform === "linkedin"
                    ? "LinkedIn Post"
                    : "X Post"}{" "}
                </h4>
                <h4 className="mt-7 date">
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                  }).format(new Date(data?.post_date))}
                </h4>
              </div>
              <>
                <ProductName type={type} post={data} />
              </>
            </div>
          </>
        ) : data?.platform === "instagram" ? (
          <>
            <div className={getWrapperClassName()}>
              <Card.Img
                variant="top"
                src={getMediaSrc(type, data, isCarousel, media_url)}
                className={getImageClassName()}
                loading="eager"
                style={{ backgroundColor: brand_color && brand_color }}
              />
            </div>

            {!isCarousel && (
              <>
                <ProductName type={type} post={data} />
                <ProductShopButton
                  isLinks={isLinks}
                  IsIndividual={false}
                  type={type}
                  post={data}
                />
              </>
            )}
          </>
        ) : (
          <div className={getWrapperClassName()}>
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        )
      ) : (
        <>
          <div className={getWrapperClassName()}>
            <Card.Img
              variant="top"
              src={getMediaSrc(type, data, isCarousel, media_url)}
              className={getImageClassName()}
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />
          </div>

          {!isCarousel && (
            <>
              <ProductName type={type} post={data} />
              <ProductShopButton
                isLinks={isLinks}
                IsIndividual={false}
                type={type}
                post={data}
              />
            </>
          )}
        </>
      )}
      {/* </div> */}
    </a>
  );
});
export default ImagePostTag;
