import { ChangeEvent, useState } from "react";
import { User } from "@supabase/supabase-js";
import { Alert,  Spin } from "antd";
import { useNavigate } from "react-router-dom";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import { useAuth } from "../../../hooks/useAuth";
import { signupUser } from "../../../Utils/SupabaseServices/UserDatabase";
import { supabase } from "../../../config/supabaseClient";
import HeaderDark from "../../../components/Header/headerDark";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import { BsChevronRight } from "react-icons/bs";
import data from "../../../Utils/CountryCodes.json";
import { Dropdown } from "react-bootstrap";

export default function SignUpDetailsForm({
  moveToNextStep,
}: {
  moveToNextStep: (nextStep: number) => void;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error");
  const [fullName, setFullName] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [username, setUsername] = useState("");
  const [youtubeChannelId, setYoutubeChannelId] = useState("");
  const [tiktokUsername, setTiktokUsername] = useState("");
  const [podcastShowLink, setPodcastShowLink] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [socialPlatforms, setSocialPlatforms] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Instagram");
  const [showChannelIdInstructions, setShowChannelIdInstructions] =
    useState(false);
  const [showLinkInstructions, setShowLinkInstructions] = useState(false);
  const isFormCompleted =
    fullName && phoneNumber && username && !usernameError && selectedValue;
    const countryCodes = data;
    const [code, setCode] = useState("+1");
  
    const handleOption = (e: any) => {
      const phn = e.target.value;
      setCode(phn);
    };
  const handleChange = (e: any) => {
    // TODO: Forms refactor
    // instead of using this kind of logic, we should refactor all forms in the app to use a
    // library like "Formik" or "react-hook-form". This libreries are useful for having cleaner
    // code for forms and handling errors in a clean manner


    switch (e.target.name) {
      case "fullName":
        setFullName(e.target.value);
        break;
      // case "lastName":
      // 	setLastName(e.target.value);
      // 	break;
      case "instagramHandle":
        setInstagramHandle(e.target.value);
        break;
      case "youtubeChannelId":
        setYoutubeChannelId(e.target.value);
        break;
      case "tiktokUsername":
        setTiktokUsername(e.target.value);
        break;
      case "podcastShowLink":
        setPodcastShowLink(e.target.value);
        break;
      case "socialPlatforms":
        setSocialPlatforms(e.target.value);
        break;
      // case "email":
      //   setEmail(e.target.value);
      //   break;
        case "phoneNumber":
        setPhoneNumber(e.target.value);
        break;
      case "username":
        const value = e.target.value.toLowerCase();
        const usernameInstruction: any = document.getElementById(
          "usernameInstruction"
        );
        // ^ asserts the start of the string.
        // [a-z0-9_] matches any lowercase letter, digit, or underscore character.
        // $ asserts the end of the string.
        // + This allows the regular expression to match a sequence of one or more characters
        setUsername(value);
        if (/^[a-z0-9_]+$/.test(value) || value === "") {
          if (usernameInstruction) {
            (usernameInstruction as HTMLElement).style.color = "white";
            setUsernameError(false);
          }
        } else {
          if (usernameInstruction) {
            (usernameInstruction as HTMLElement).style.color = "red";
            setUsernameError(true);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleOptionsChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;

    setSelectedValue(newValue);
  };
  return (
    <div className="auth-phone-signup-container p-4">
      {/* <AuthPagesHeader /> */}
      <div className="error-page-header">
        <HeaderDark />
      </div>
      <Spin spinning={loading}>
        <div className="auth-signup-details">
          <p className="auth-signup-details-title">Just a few more details</p>
          {/* <p className='auth-signup-details-subtitle'>
						Finish creating your profile by entering the following data
					</p> */}
          {error && (
            <Alert
              message={errorMsg}
              type="error"
              showIcon
              className="alert-margin-30"
            />
          )}
          <form
            className="input-form"
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              const res = await signupUser(
                username,
                fullName,
                instagramHandle,
                youtubeChannelId,
                tiktokUsername,
                podcastShowLink,
                selectedValue,
                // email,
                code + phoneNumber,
                user as User
              );
              if (res.error) {
                setError(true);
                setErrorMsg(res.message);
                setLoading(false);
                return;
              }
              moveToNextStep(3);
            }}
          >
            {/* <p className="auth-input-label">Email</p>
            <div className="input-div signup">
              <input
                name="email"
                onChange={handleChange}
                required
                type="email"
                value={email}
              />
            </div> */}
             <p className="auth-input-label">Phone Number</p>
            <div className="input-div signup">
            <Dropdown id='dropdown'>
								<select
									onChange={(e: any) => handleOption(e)}
									className='option-select'
								>
									{countryCodes.map((elem: any, idx: any) => (
										<option key={idx} value={elem.dial_code}>
											{elem.name}
										</option>
									))}
								</select>
							</Dropdown>{" "}
              <input
                name="phoneNumber"
                onChange={handleChange}
                required
                type="text"
                value={phoneNumber}
              />
            </div>
            <p className="auth-input-label">Full Name</p>
            <div className="input-div signup">
              <input
                name="fullName"
                required
                type="text"
                value={fullName}
                onChange={handleChange}
              />
            </div>
            {/* <p className='auth-input-label'>* Last name</p>
						<div className='input-div'>
							<input
								name='lastName'
								required
								type='text'
								value={lastName}
								onChange={handleChange}
							/>
						</div> */}
            <p className="auth-input-label">
              What social platform would you like us to integrate first?{" "}
            </p>
            <p
              className="auth-input"
            >
              You can integrate more platforms after your page is set up!
            </p>
            <div className="input-div signup">
              {/* <input
								name='instagramHandle'
								required
								type='checkbox'
								value={instagramHandle}
								onChange={handleChange}
								autoComplete='do-not-autofill'
							/> */}
              <label htmlFor="socialPlatforms"></label>
              <select
                id="socialPlatforms"
                name="socialPlatforms"
                onChange={handleOptionsChange}
              >
                <option value="Instagram">Instagram</option>
                <option value="Youtube">YouTube</option>
                <option value="Podcast">Podcast</option>
                {/* <option value="pinterest">Pinterest</option> */}
                <option value="Tiktok">Tiktok</option>
              </select>
            </div>
            {selectedValue === "Instagram" ? (
              <>
                <p className="auth-input-label"> Instagram Handle</p>
                <div className="input-div signup">
                  <input
                    name="instagramHandle"
                    required
                    type="text"
                    value={instagramHandle}
                    onChange={handleChange}
                    autoComplete="do-not-autofill"
                  />
                </div>
              </>
            ) : selectedValue === "Youtube" ? (
              <>
                <p className="auth-input-label"> YouTube Channel ID</p>
                <div className="input-div signup">
                  <input
                    name="youtubeChannelId"
                    required
                    type="text"
                    value={youtubeChannelId}
                    onChange={handleChange}
                    autoComplete="do-not-autofill"
                  />
                </div>
                {!showChannelIdInstructions ? (
                  <p
                    className="account-social-content-channelid-text mt-0 mb-3"
                    onClick={() => setShowChannelIdInstructions(true)}
                  >
                    Find Your Channel ID
                  </p>
                ) : (
                  <div
                    className="account-social-content-channelid-ins mt-0 mb-6"
                  >
                    <p>
                      You can see your channel ID in your advanced{" "}
                      <a
                        href="http://www.youtube.com/account_advanced"
                        className="account-social-content-channelid-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        account settings
                      </a>
                    </p>
                    <ol>
                      <li>Sign in to YouTube</li>
                      <li>
                        In the top right, click your <b>profile picture</b>{" "}
                        <BsChevronRight className="bs-chevron" />
                        <b>Settings</b>
                      </li>
                      <li>
                        From the left menu, select <b>Advanced settings</b>
                      </li>
                      <li>
                        You’ll see your channel’s user and channel ID, copy your{" "}
                        <b>channel ID</b>
                      </li>
                    </ol>
                  </div>
                )}
              </>
            ) : selectedValue === "Tiktok" ? (
              <>
                <p className="auth-input-label"> TikTok Username</p>
                <div className="input-div signup">
                  <input
                    name="tiktokUsername"
                    required
                    type="text"
                    value={tiktokUsername}
                    onChange={handleChange}
                    autoComplete="do-not-autofill"
                  />
                </div>
              </>
            ) : selectedValue === "Podcast" ? (
              <>
                <p className="auth-input-label">Podcast Show Link</p>
                <div className="input-div signup">
                  <input
                    name="podcastShowLink"
                    required
                    type="text"
                    value={podcastShowLink}
                    onChange={handleChange}
                    autoComplete="do-not-autofill"
                  />
                </div>
                {!showLinkInstructions ? (
                  <p
                    className="account-social-content-channelid-text mb-4 mt-0"
                    onClick={() => setShowLinkInstructions(true)}
                  >
                    Find Your Show URL
                  </p>
                ) : (
                  <div
                    className="account-social-content-channelid-ins mb-4 mt-0"
                  >
                    <p>
                      You can see your show url in{" "}
                      <a
                        href="https://open.spotify.com/?"
                        className="account-social-content-channelid-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        spotify app
                      </a>
                    </p>
                    <ol>
                      <li>Sign in to Spotify</li>
                      <li>
                        Search your show{" "}
                        <BsChevronRight className="bs-chevron" />
                        Click the three dots <b>...</b>
                      </li>
                      <li>
                        Click <b>Share</b>.{" "}
                        <BsChevronRight className="bs-chevron" />
                        Select <b>Copy Show Link </b> for the URL.{" "}
                      </li>
                      <li>
                        Paste the <b>Show Link</b> here.
                      </li>
                    </ol>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            <p className="auth-input-label">Bubble Username</p>
            {/* <p id='usernameInstruction'>
							Please enter a username consisting of only letters, digits, and
							underscores.
						</p> */}
            <div className="input-div signup">
              <input
                name="username"
                required
                type="text"
                value={username}
                // placeholder='yourusername'

                defaultValue="yourusername"
                onChange={handleChange}
                autoComplete="do-not-autofill"
              />
            </div>
            {/* <p id='usernameInstruction'>
							Please enter a username consisting of letters, digits, and
							underscores.
						</p> */}
            {username && (
              <>
                <p className="auth-signup-url-desc">
                  This will be your unique link-in-bio:
                </p>
                <p className="auth-signup-url-link">
                  www.searchbubble.com/
                  <span className="grey-font">
                    {username}
                  </span>
                </p>
              </>
            )}
            <div
              className="white-btn-bubble"
            >
              <button
                className="bubble-white-button"
                disabled={!isFormCompleted}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </Spin>
      <AuthTerm />
    </div>
  );
}
