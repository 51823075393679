import React, { useState, useEffect } from "react";
import {
  updateSelectedPlatforms,
  disconnectPlatform,
  extractUsername,
} from "../../../../Utils/SupabaseServices/OnboardingFlow";
import { supabase } from "../../../../config/supabaseClient";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import "../Onboarding.css";
import PlatformCategory from "./components/PlatformCategory";
import PlatformConfigGenerator from "./components/PlatformConfigGenerator";
import { validateEmail } from "./components/PlatformUtils";

const ConnectPlatformStep = (props: any) => {
  let {
    handleNext,
    buttonStyle,
    handleBack,
    selectedPlatforms,
    handlePlatformToggle,
    username,
    setUsername,
    setSelectedPlatforms,
    currentStep,
    instagramUsername,
    setInstagramUsername,
  } = props;
  const [expandedPlatform, setExpandedPlatform] = React.useState<string>("");
  const [youtubeUsername, setYoutubeUsername] = React.useState<string>("");
  const [podcastShowName, setPodcastShowName] = React.useState<string>("");
  const [podcastShowUrl, setPodcastShowUrl] = React.useState<string>("");
  const [twitterUsername, setTwitterUsername] = React.useState<string>("");
  const [tiktokUsername, setTiktokUsername] = React.useState<string>("");
  const [linkedinProfileUrl, setLinkedinProfileUrl] =
    React.useState<string>("");
  const [newsletterEmail, setNewsletterEmail] = React.useState<string>("");
  const [newsletterWebsiteLink, setNewsletterWebsiteLink] =
    React.useState<string>("");
  const [newsletterLink, setNewsletterLink] = React.useState<string>("");
  const [linkinBioLink, setLinkinBioLink] = React.useState<string>("");
  const [websiteLink, setWebsiteLink] = React.useState<string>("");
  const [amazonLink, setAmazonLink] = React.useState<string>("");
  const [shopmyLink, setShopmyLink] = React.useState<string>("");
  const [ltkLink, setLtkLink] = React.useState<string>("");

  const [newPlatforms, setNewPlatforms] = React.useState<any>([]);
  const [platformError, setPlatformError] = React.useState<{
    [key: string]: string;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [verifyingPlatforms, setVerifyingPlatforms] = React.useState<{
    [key: string]: boolean;
  }>({});

  const toggleExpand = (platformId: string) => {
    setExpandedPlatform((prev) => (prev === platformId ? "" : platformId));
  };

  const platformCategories = PlatformConfigGenerator({
    instagramUsername,
    setInstagramUsername,
    youtubeUsername,
    setYoutubeUsername,
    twitterUsername,
    setTwitterUsername,
    tiktokUsername,
    setTiktokUsername,
    linkedinProfileUrl,
    setLinkedinProfileUrl,
    newsletterEmail,
    setNewsletterEmail,
    newsletterWebsiteLink,
    setNewsletterWebsiteLink,
    podcastShowName,
    setPodcastShowName,
    podcastShowUrl,
    setPodcastShowUrl,
    websiteLink,
    setWebsiteLink,
    linkinBioLink,
    setLinkinBioLink,
    amazonLink,
    setAmazonLink,
    shopmyLink,
    setShopmyLink,
    ltkLink,
    setLtkLink,
  });

  const fetchUserPlatforms = async () => {
    try {
      const { data: userData } = await supabase
        .from("user")
        .select(
          `
          instagram_username,
          youtube_username,
          twitter_user,
          tiktok_user,
          linkedin_user,
          newsletter_email,
          newsletter_website_link,
          podcast_link,
          podcast_artist,
          podcast_showname,
          youtube_channel_id,
          youtube_playlist_id ,
          podcast_id,
          linkinbio,
          social_website,
          social_amazon,
          social_shopmy,
          social_ltk
               `
        )
        .eq("username", username)
        .single();

      if (userData) {
        const platforms = [];

        if (userData.instagram_username) {
          platforms.push({
            platform: "instagram",
            username: userData.instagram_username,
          });
          setInstagramUsername(userData.instagram_username);
        }

        if (userData.youtube_channel_id) {
          platforms.push({
            platform: "youtube",
            username: userData.youtube_username,
            channelId: userData.youtube_channel_id,
          });
          setYoutubeUsername(userData.youtube_username);
        }

        if (userData.twitter_user) {
          platforms.push({
            platform: "twitter",
            username: userData.twitter_user,
          });
          setTwitterUsername(userData.twitter_user);
        }

        if (userData.tiktok_user) {
          platforms.push({
            platform: "tiktok",
            username: userData.tiktok_user,
          });
          setTiktokUsername(userData.tiktok_user);
        }

        if (userData.linkinbio) {
          platforms.push({
            platform: "linkinbio",
            username: userData.linkinbio,
          });
          setLinkinBioLink(userData.linkinbio);
        }

        if (userData.social_amazon) {
          platforms.push({
            platform: "amazon",
            username: userData.social_amazon,
          });
          setAmazonLink(userData.social_amazon);
        }

        if (userData.social_shopmy) {
          platforms.push({
            platform: "shopmy",
            username: userData.social_shopmy,
          });
          setShopmyLink(userData.social_shopmy);
        }

        if (userData.social_ltk) {
          platforms.push({
            platform: "ltk",
            username: userData.social_ltk,
          });
          setLtkLink(userData.social_ltk);
        }

        if (userData.social_website) {
          platforms.push({
            platform: "website",
            username: userData.social_website,
          });
          setWebsiteLink(userData.social_website);
        }

        if (userData.linkedin_user) {
          platforms.push({
            platform: "linkedin",
            username: userData.linkedin_user,
          });
          setLinkedinProfileUrl(userData.linkedin_user);
        }

        if (userData.newsletter_email) {
          platforms.push({
            platform: "newsletter",
            username: userData.newsletter_email,
            websiteLink: userData.newsletter_website_link || "",
          });
          setNewsletterEmail(userData.newsletter_email);
          setNewsletterWebsiteLink(userData.newsletter_website_link || "");
        }

        if (userData.podcast_link && userData.podcast_showname) {
          platforms.push({
            platform: "podcast",
            username: userData.podcast_artist,
            showUrl: userData.podcast_link,
            showId: userData.podcast_id,
          });
          setPodcastShowName(userData.podcast_showname);
          setPodcastShowUrl(userData.podcast_link || "");
        }

        setSelectedPlatforms(platforms);
      }
    } catch (error) {
      console.error("Error fetching user platforms:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUserPlatforms();
  }, [newPlatforms]);

  if (isLoading) {
    return (
      <div className="instagram-container m-2">
        <div className="instagram-card">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </div>
      </div>
    );
  }

  const handlePlatformValues = async (
    platformId: string,
    isConnecting: boolean
  ) => {
    if (isConnecting) {
      let usernames = "";
      
      // Set the verifying state for this platform
      setVerifyingPlatforms(prev => ({
        ...prev,
        [platformId]: true
      }));

      // Add validation for newsletter email
      if (platformId === "newsletter") {
        if (!validateEmail(newsletterEmail)) {
          setPlatformError((prev) => ({
            ...prev,
            [platformId]: "Please enter a valid email address",
          }));
          setVerifyingPlatforms(prev => ({
            ...prev,
            [platformId]: false
          }));
          return;
        }
      }

      switch (platformId) {
        case "instagram":
          usernames = instagramUsername;
          break;
        case "youtube":
          
        const ytUsername = extractUsername(youtubeUsername);
          usernames = ytUsername;
          break;
        case "twitter":
          const twUsername = extractUsername(twitterUsername);
          usernames = twUsername;
          break;
        case "tiktok":
          const tikUsername = extractUsername(tiktokUsername);
          usernames = tikUsername;
          break;
        case "linkedin":
          const liUsername = extractUsername(linkedinProfileUrl);
          usernames = liUsername;
          break;
        case "newsletter":
          usernames = newsletterEmail;
          break;
        case "podcast":
          usernames = `${podcastShowName}`;
          break;
        case "linkinbio":
          usernames = linkinBioLink;
          break;
        case "amazon":
          usernames = amazonLink;
          break;
        case "shopmy":
          usernames = shopmyLink;
          break;
        case "ltk":
          usernames = ltkLink;
          break;
        case "website":
          usernames = websiteLink;
          break;
      }

      const newPlatform: {
        platform: string;
        usernames: string;
        websiteLink?: string;
        showUrl?: string;
      } = {
        platform: platformId,
        usernames: usernames.trim(),
        websiteLink:
          platformId === "newsletter"
            ? newsletterWebsiteLink.trim()
            : undefined,
        // showUrl: platformId === "podcast" ? podcastShowUrl.trim() : undefined,
      };
      const newSelectedPlatform = {
        platform: platformId,
        username: usernames.trim(),
      };

      try {
        setNewPlatforms(newPlatform);
        setIsInitialLoad(true);
        const updatedPlatforms = [...selectedPlatforms, newSelectedPlatform];
        if (platformId !== "instagram") {
          if (platformId === "podcast") {
            newPlatform.usernames = `${podcastShowName}`;
            newPlatform.showUrl = podcastShowUrl.trim();
            await updateSelectedPlatforms(
              updatedPlatforms,
              username,
              newPlatform
            );
          } else {
            await updateSelectedPlatforms(
              updatedPlatforms,
              username,
              newPlatform
            );
          }
          setIsInitialLoad(false);
          setSelectedPlatforms(updatedPlatforms);
        }
        setPlatformError((prev) => ({ ...prev, [platformId]: "" }));
      } catch (error: any) {
        setPlatformError((prev) => ({
          ...prev,
          [platformId]: error.message || "Validation failed",
        }));
        const filteredPlatforms = selectedPlatforms.filter(
          (item: any) => item.platform !== platformId
        );
        setSelectedPlatforms(filteredPlatforms);
      } finally {
        // Clear the verifying state for this platform
        setVerifyingPlatforms((prev) => ({
          ...prev,
          [platformId]: false,
        }));
        setIsInitialLoad(false);
      }
      setExpandedPlatform("");
    } else {
      try {
        await disconnectPlatform(platformId, username);
        const updatedPlatforms = selectedPlatforms.filter(
          (item: any) => item.platform !== platformId
        );
        setSelectedPlatforms(updatedPlatforms);
        setPlatformError((prev) => ({ ...prev, [platformId]: "" }));

        // Reset the corresponding input field
        switch (platformId) {
          case "instagram":
            setInstagramUsername("");
            break;
          case "youtube":
            setYoutubeUsername("");
            break;
          case "twitter":
            setTwitterUsername("");
            break;
          case "tiktok":
            setTiktokUsername("");
            break;
          case "linkedin":
            setLinkedinProfileUrl("");
            break;
          case "newsletter":
            setNewsletterEmail("");
            setNewsletterWebsiteLink("");
            break;
          case "podcast":
            setPodcastShowName("");
            setPodcastShowUrl("");
            break;
          case "website":
            setWebsiteLink("");
            break;
          case "linkinbio":
            setLinkinBioLink("");
            break;
          case "amazon":
            setAmazonLink("");
            break;
          case "shopmy":
            setShopmyLink("");
            break;
          case "ltk":
            setLtkLink("");
            break;
        }
      } catch (error: any) {
        console.error("Error disconnecting platform:", error);
        setPlatformError((prev) => ({
          ...prev,
          [platformId]: "Failed to disconnect platform. Please try again.",
        }));
      } finally {
        // Clear the verifying state for this platform
        setVerifyingPlatforms(prev => ({
          ...prev,
          [platformId]: false
        }));
      }
    }

    await supabase
      .from("user")
      .update({ onboarding_step: currentStep })
      .eq("username", username);
  };

  // Add this helper function to get the display username
  const getDisplayUsername = (platformId: string) => {
    // For podcast, return the show name directly
    if (platformId === "podcast") {
      return podcastShowName;
    }
    
    const platform = selectedPlatforms.find(
      (item: any) => item.platform === platformId
    );
    if (!platform) return "";
    
    // URL-based platforms don't need @ symbol
    const urlBasedPlatforms = ["amazon", "shopmy", "ltk", "website", "linkinbio", "newsletter"];
    
    // If platform is URL-based or username already has @, return as is
    if (urlBasedPlatforms.includes(platformId) || platform.username?.includes("@")) {
      return platform.username;
    }
    
    // Otherwise, add @ prefix
    return `@${platform.username}`;
  };

  return (
    <div className="instagram-container flex-column">
      <div className="card-header">
        <h2 className="card-title">Connect Your Platforms</h2>
        <p className="card-description">
          Select and connect your social media platforms
        </p>
      </div>
      <div className="platforms-grid">
        {platformCategories.map((category) => (
          <PlatformCategory
            key={category.title}
            category={category}
            selectedPlatforms={selectedPlatforms}
            expandedPlatform={expandedPlatform}
            toggleExpand={toggleExpand}
            handlePlatformValues={handlePlatformValues}
            platformError={platformError}
            isInitialLoad={isInitialLoad}
            isLoading={isLoading}
            verifyingPlatforms={verifyingPlatforms}
            getDisplayUsername={getDisplayUsername}
          />
        ))}
      </div>
      <br />
      <div className="action-buttons">
        <button
          type="button"
          onClick={handleBack}
          className="back-button"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => {
            setSelectedPlatforms(selectedPlatforms);
            handleNext();
          }}
          className="next-button"
          disabled={selectedPlatforms.length === 0}
        >
          Next
          <HiArrowRight className="arrow-icon" />
        </button>
      </div>
    </div>
  );
};

export default ConnectPlatformStep;