import { useLocation, useNavigate, useParams } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../Utils/SupabaseServices/ContentDatabase";
import StayUpToDateSkeleton from "../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import { isNavigable } from "../../Utils/StayUpToDate/RenderComponent";
import { useEffect, useRef, useState } from "react";
import { BsSearch, BsX } from "react-icons/bs";
import { useRotatingPlaceholder } from "../Search/useRotatingPlaceholder";
import { setSearches } from "../Search/search";
import JoinButton from "../JoinButton/JoinButton";

import RenderComponentLoader from "./StayUpToDateRenderComponent/RenderComponentLoader";
import { StaticVariables } from "../../Utils/StaticVariables";
import { RenderComponent } from "./StayUpToDateRenderComponent/RenderComponents";

const StayUpToDate = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user_id,
    username,
    brand_color,
    isFilloutOn,
    searchBarText,
    showSearchbar,
    getQuery,
    setLoadingFeed,
    setCategory,
    showSearch,
    setShowSearchLoading,
    StayUpToDateComponentsLoading,
    StayUpToDateComponents,
    removeSearchLoading,
    from
  } = props;
  const param = useParams();
  const [query, setQuery] = useState<any>();
  const [isSearch, setIsSearch] = useState(false);
  const ref: any = useRef(null);


  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any,
    platform: any,
    // type:any,
    isLinks?: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        platform: platform,
        // type:type,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    if (isLinks === true) {
      track("Redirect to shop link", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    } else if (isLinks === false) {

      track("Redirect to original post", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
    updateRefClick(postId, user_id);
  }

  function gotoSignUpPage() {
    const utmUrl = `/subscribe?source=${username}`;
    navigate(utmUrl);
  }

  function handleEmptyCarousel() { }

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      StayUpToDateComponents &&
      StayUpToDateComponents.showingComponents &&
      StayUpToDateComponents.showingComponents.length > 0
    ) {
      const timer = setTimeout(() => {
        setIsButtonVisible(true);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setIsButtonVisible(false);
    }
  }, [StayUpToDateComponents]);
  const placeholder = useRotatingPlaceholder(username, searchBarText);
  function reset() {
    setQuery("");
    setIsSearch(false);
    getQuery("");
    // setRemoveSearchLoading(true)

    // const intervalId = setInterval(() => {
    //   setRemoveSearchLoading(false)
    // }, 1000)

    ref.current.value = "";
    // return () => clearInterval(intervalId);
  }
  async function search(query: any) {
    if (query && ref.current.value) {
      if (query.trim().length === 0) {
        ref.current.value = "";
      } else {
        setLoadingFeed(true);
        setIsSearch(true);

        getQuery(query, "searchbar");
        setCategory("searchbar");

        setSearches(user_id, query, "searchbar");
      }
    }
  }
  const runSearch = (e: any, val: any) => {
    e.preventDefault();

    if (e.keyCode === 13) {
      e.preventDefault();
      ref.current.blur();
      setShowSearchLoading(true);

      if (val.trim().length === 0) {
        ref.current.value = "";
      } else {
        setLoadingFeed(true);
        setIsSearch(true);

        getQuery(query, "searchbar");
        setCategory("searchbar");
        setSearches(user_id, query, "searchbar");
        let timeout: any;

        timeout = setTimeout(() => {
          setShowSearchLoading(false);
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  };

  return (
    <>
      {showSearchbar && !showSearch ? (
        <div className="search-elements-stayuptodate">
          <div className="search-container-stayuptodate">
            <input
              ref={ref}
              type="text"
              id="searchInput"
              enterKeyHint="search"
              placeholder={placeholder}
              // onClick={refreshFeed}

              onInput={(e: any) => {
                runSearch(e, e.target.value);
                setQuery(e.target.value);
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  runSearch(e, e.target.value);
                  setQuery(e.target.value);
                }
              }}
              className="search-wrapper-input"
              autoComplete="off"
            />

            {!isSearch ? (
              <button
                className="search-wrapper-btn search-btn-width"
                onClick={() => search(ref.current.value)}
              >
                <BsSearch />
              </button>
            ) : (
              <button
                className="search-wrapper-btn search-btn-width"
                onClick={reset}
              >
                <BsX />
              </button>
            )}
          </div>
        </div>
      ) : null}

      {removeSearchLoading || StayUpToDateComponentsLoading || (StayUpToDateComponents &&
        StayUpToDateComponents?.showingComponents?.length == 0) ? (
        <div id="app-wrapper" className='frontend-skeleton'>
          <div className="uptodate-wrapper">
            <StayUpToDateSkeleton />
          </div>
        </div>
      ) : (
        <div className="uptodate-wrapper">
          <>
            {StayUpToDateComponents &&
              StayUpToDateComponents?.showingComponents?.length > 0 ? (
              <>
                {StayUpToDateComponents?.showingComponents?.map(
                  (component: any, index: any) => {
                    if (component?.status === "active") {
                      const canNavigate = isNavigable(component);

                      return (
                        <div key={component?.unique_id}>
                          {/* <RenderComponent
                                componentId={component?.unique_id}
                                user_id={user_id}
                                username={username}
                                brand_color={brand_color}
                                // handleShop={handleShop}
                                handleRedirect={handleRedirect}
                                isHandleNavigate={canNavigate}
                                components={StayUpToDateComponents}
                                handleEmptyCarousel={handleEmptyCarousel}
                              /> */}

                          <RenderComponentLoader
                            componentId={component?.unique_id}
                            user_id={user_id}
                            username={username}
                            brand_color={brand_color}
                            handleRedirect={handleRedirect}
                            isHandleNavigate={canNavigate}
                            components={StayUpToDateComponents}
                            data={component}
                            handleEmptyCarousel={handleEmptyCarousel}
                            from={from}
                          />

                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
                {isButtonVisible && (
                  <JoinButton
                    username={username}
                  />
                )}
              </>
            ) : null}
          </>
        </div>
      )}
    </>
  );
};

export default StayUpToDate;

export function getThumbnail(post: any, index: any) {
  return post?.thumbnail &&
    post?.thumbnail.split(",").length > 0 &&
    post?.thumbnail.split(",")[index]?.length > 0
    ? post.thumbnail.split(",")[index]
    : "/assets/dummy_video.png";
}
