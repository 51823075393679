// Email validation utility
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Display username helper
export const getDisplayUsername = (
  platformId: string,
  podcastShowName: string,
  selectedPlatforms: any[]
): string => {
  if (platformId === "podcast") {
    return podcastShowName;
  }
  const platform = selectedPlatforms.find(
    (item: any) => item.platform === platformId
  );
  if (!platform) return "";

  return platform.username?.includes("@")
    ? platform.username
    : `@${platform.username}`;
}; 