import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { UpdateCombinedText } from "../../../Utils/SupabaseServices/ContentDatabase";

export default function useUpdateTags() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    tagString,
    post_id,
    username,
    action,
    tag,
    activeKey,
    filter,
    user_id,
  }: {
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    username: any;
    tag: any;
    action: any;
    user_id: any;
  }) => {
    // Optimistically update local data
    queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
      if (oldData) {
        return oldData.map((page: any) =>
          page.map((post: any) =>
            post.post_id === post_id ? { ...post, tagg: tagString } : post
          )
        );
      }
      return oldData;
    });


    try {
      // Execute Supabase calls
      // Simulate server delay
      // await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust delay as needed
      try {
        const { data: tags } = await supabase
          .from("content_new")
          .select("*")
          .eq("post_id", `${post_id}`).eq("user_id",user_id);
        const { error: e1 } = await supabase
          .from("content_new")
          .update({
            tagg: tagString,
           
          })
          .eq("post_id", `${post_id}`).eq("user_id",user_id);
      } catch (e) {}

      const { error } = await supabase
        .from(`${username}`)
        .update({ tagg: tagString })
        .eq("post_id", `${post_id}`).eq("user_id",user_id);
        await supabase
        .from('ads_content')
        .update({ tagg: tagString })
        .eq("post_id", `${post_id}`).eq("user_id",user_id);

        await supabase
        .from('broken_links_table')
        .update({ tagg: tagString })
        .eq("post_id", `${post_id}`).eq("user_id",user_id);
      
     
      UpdateCombinedText(username, user_id, post_id);
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries(["paginatedPosts"]);
    } catch (error) {
      // Rollback on error by restoring the previous data
      queryClient.invalidateQueries(["paginatedPosts"]);
      // throw new Error(error.message);
    }
  };

  return useMutation(submitTag);

  // return useMutation(submitTag, {
  //   onMutate: async ({ tagString, post_id }) => {
  //     await queryClient.cancelQueries(["paginatedPosts"]);

  //     // Snapshot the current data
  //     const previousData = queryClient.getQueryData(["paginatedPosts"]);

  //     return { previousData }; // Return context for rollback
  //   },
  //   onError: (error, _variables, context) => {
  //     // Rollback on error by restoring the previous data
  //     queryClient.setQueryData(["paginatedPosts"], context?.previousData);
  //   },
  // });
}
