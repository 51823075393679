import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchUser } from '../Utils/SupabaseServices/UserDatabase';
interface SidebarStateContextType {
    option: string;
    isEmail: boolean;
    isName: boolean;
    isPhone: boolean;
    isPhoneEmpty: boolean;
    tab: number | undefined;
    isEmailVerified: boolean;
    handleClick: (option: string) => void;
    setIsEmail: (value: boolean) => void;
    setIsName: (value: boolean) => void;
    setIsPhone: (value: boolean) => void;
    setIsPhoneEmpty: (value: boolean) => void;
    setTab: (value: number | undefined) => void;
    setIsEmailVerified: (value: boolean) => void;
    loading: boolean;
    setLoading: (value: boolean) => void;
    fetchEmail: () => void;
}

const SidebarStateContext = createContext<SidebarStateContextType | undefined>(undefined);

export const SidebarStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const { username } = useParams();
    const navigate = useNavigate();
    const isFollowerView = location.pathname.includes('follower');
    const [option, setOption] = useState<string>(isFollowerView ? "" : "Landing Page");
    const [isEmail, setIsEmail] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [tab, setTab] = useState<number | undefined>();
    const [loading, setLoading] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
    const handleClick = (newOption: string) => {
        if (isFollowerView) {
            navigate("/dashboard", { state: { from: "follower" } });
            setOption(newOption);
        } else {
            setOption(newOption);
        }
    };

    useEffect(() => {
        fetchEmail();
    }, [isEmail, isName]);

    const fetchEmail = useCallback(async () => {
        try {
            const userData = await fetchUser(username);
            if (!userData) return;

            const hasEmail = userData.email !== "";
            const hasName = userData.name !== "";

            if (hasEmail && hasName) {
                setIsName(true);
                setIsEmail(true);
                setIsPhone(true);
                setIsEmailVerified(true);
            } else {
                setIsEmail(hasEmail);
                setIsName(hasName);
                handleClick("Account");
                setTab(0);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoading(false);
        }
    }, [username]);

    return (
        <SidebarStateContext.Provider value={{
            option,
            isEmail,
            isName,
            isPhone,
            isPhoneEmpty,
            tab,
            isEmailVerified,
            handleClick,
            setIsEmail,
            setIsName,
            setIsPhone,
            setIsPhoneEmpty,
            setTab,
            setIsEmailVerified,
            loading,
            setLoading,
            fetchEmail
        }}>
            {children}
        </SidebarStateContext.Provider>
    );
};

export const useSidebarState = () => {
    const context = useContext(SidebarStateContext);
    if (context === undefined) {
        throw new Error('useSidebarState must be used within a SidebarStateProvider');
    }
    return context;
}; 