import axios from "axios";
import { useQuery } from "react-query";


// Custom hook for email verification
export function useEmailVerification(email: string) {
    async function verifyEmail(email: string) {
        try {
            const api_url = 'https://dev.thesearchbubble.com/validate-email'
            const response = await axios.post(api_url, {
                email: email
            });

            return response.data;
        } catch (error) {
            throw new Error('Failed to verify email');
        }
    }
    return useQuery(
        ['verifyEmail', email],
        () => verifyEmail(email),
        {
            enabled: !!email, // Only run query if email is provided
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );
}