import { useEffect, useState } from "react";
import AuthBackBtn from "../../../components/AuthBackBtn/authBackBtn";
import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import AuthTitle from "../../../components/AuthTitles/authTitle";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
import { updateUserPassword } from "../../../Utils/SupabaseServices/UserDatabase";
import { useLocation, useNavigate } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";
import { Spin } from 'antd';

const UpdatePassword = () => {
	const [password, setPassword] = useState("");
	const { user } = useAuth();
	const user_id = user?.id || '';
	const [passwordError, setPasswordError] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [countdown, setCountdown] = useState(5);
	const [showCountdown, setShowCountdown] = useState(false);

	useEffect(() => {
		track(location.pathname, TRACKING_TYPES.PAGE, {
			pathname: location.pathname,
			user_id: user_id
		});
	}, [location.pathname, user_id]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (showCountdown && countdown > 0) {
			timer = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);
		} else if (countdown === 0 && showCountdown) {
			navigate("/login");
		}

		return () => {
			if (timer) clearInterval(timer);
		};
	}, [countdown, showCountdown, navigate]);

	async function resetPassword(e: React.FormEvent) {
		e.preventDefault();
		setLoading(true);
		try {
			const updateResponse :any= await updateUserPassword(password);
			if (updateResponse === true) {
				setMsg("Password Updated Successfully!");
				setError("");
				setCountdown(5);
				setShowCountdown(true);
			} else {
				const errorMessage = updateResponse?.message || "Failed to reset password";
				setError(errorMessage);
				setMsg("");
				setShowCountdown(false);
			}
		} catch (err: any) {
			setError(err?.message || "Couldn't update password");
			setMsg("");
			setShowCountdown(false);
		} finally {
			setLoading(false);
		}
	}

	function SubmitButton(props: any) {
		if (password) {
			return (
				<button className='submit-btn' disabled={loading}>
					{loading ? <Spin size="small" /> : props.name}
				</button>
			);
		} else {
			return (
				<button className='submit-btn' disabled={loading}>
					{loading ? <Spin size="small" /> : props.name}
				</button>
			);
		}
	}
	const validatePassword = (e: any) => {
		e.preventDefault();

		if (password.length < 6) {
			setPasswordError("password should be atleast 6 letters");
		} else {
			setPasswordError("");

			resetPassword(e);
			return true;
		}
	};
	return (
		<div className='auth-container'>
			<div className='login-auth'>
				<div className="auth-phone-signup-container-flex">
					{<div className="navbar_container">
						<a
							href="/"
							className="navbar_logo-link w-nav-brand"
							aria-label="home"
						>
							<div className="logo-text">
								<img
									className="homepage-icon"
									src="/assets/output-onlinepngtools-edited.png"
									alt="thesearchbubble logo"
								/>
							</div>
						</a>
						<nav className="navbar_menu w-nav-menu">
							<div className="navbar_menu-link-wrapper">
								<a href="/subscribe" className="navbar_link w-nav-link sign-up">
									sign up for free
								</a>
							</div>
						</nav>
					</div>
					}
					{/* <AuthPagesHeader /> */}
					<div className="auth-form-wrapper">
						<div>
							<AuthBackBtn route='/login' />
							<AuthTitle isLogin={true} />

							<div className="auth-phone-signup-container">
								<form className='input-form' onSubmit={validatePassword}>
									<div className='input-div'>
										{" "}
										<input
											type='password'
											placeholder='Enter New Password'
											onChange={(e: any) => setPassword(e.target.value)}
											required
										/>
									</div>
									{passwordError ? <ErrorMsg error={passwordError} /> : ""}
									<SubmitButton name='Reset Password' />
								</form>
								{msg && (
									<div className='success-msg'>
										<p>{msg}</p>
										{showCountdown && <p>Redirecting in {countdown} seconds...</p>}
									</div>
								)}
								{error && <ErrorMsg error={error} />}
							</div>
							<AuthPageSwitch loginPage={true} />
						</div>
					</div>

					<AuthTerm />
				</div>
			</div>
			<CreatorQuote />
		</div>
	);
};

export default UpdatePassword;
