import { Segmented, Typography } from 'antd';
import React from 'react';
const { Title } = Typography;

const CarouselOrderControl = (props: any) => {
  const { order, setOrder } = props;
  // const orderOptions = ["ASC", "DESC"];

  const handleOrderChange = (value: string) => {
    setOrder(value);
  };

  return (
    <div className='mb-3'>
      <Title level={4}>Content Order</Title>
      {/* <Radio.Group onChange={handleOrderChange} value={order}>
        <Radio value="DESC">Newest to Oldest</Radio>
        <Radio value="ASC">Oldest to Newest</Radio>
      </Radio.Group> */}
      <Segmented
        options={[
          { value: "DESC", label: "Newest to Oldest" },
          { value: "ASC", label: "Oldest to Newest" }
        ]}
        value={order}
        onChange={handleOrderChange}
        className="custom-segmented-order"
      />
    </div>
  );
};

export default CarouselOrderControl;
