import { supabase } from "../../config/supabaseClient";

export async function storeUserLinks(
  id: any,
  user_id: any,
  username: any,
  linkName: any,
  link: any,
  imageLinkUrl: any,
  collectionName: any,
  from: any
) {
  // If id is null, this is a new record
  if (id === null) {
    // First, fetch existing links for this user
    const { data: existingLinksTotal, error: fetchError } = await supabase
      .from("user_links")
      .select("*")
      .eq("user_id", user_id)
      .eq("collection_name", collectionName)
      .order("index", { ascending: false });

    if (fetchError) {
      console.error("Error fetching existing links:", fetchError);
      throw fetchError;
    }

    const newIndex = existingLinksTotal?.length ? Math.max(...existingLinksTotal.map(l => l.index)) + 1 : 0;


    const { data, error } = await supabase
      .from("user_links")
      .insert({
        user_id,
        link_name: linkName,
        link_url: link,
        image_url: imageLinkUrl,
        collection_name: collectionName,
        type: from,
        index: newIndex,
      })
      .select();

    if (error) {
      console.error("Error storing new link:", error);
      throw error;
    }

    return data;
  } else {
    // Update existing record
    const { data, error } = await supabase
      .from("user_links")
      .update({
        link_name: linkName,
        link_url: link,
        image_url: imageLinkUrl,
      })
      .eq("id", id)
      .eq("user_id", user_id)
      .eq("type", from)
      .eq("collection_name", collectionName)
      .select();

    if (error) {
      console.error("Error updating link:", error);
      throw error;
    }

    return data;
  }
}

export async function deleteUserLinks(
  user_id: any,
  collectionName: any,
  id: any
) {
  const { data, error } = await supabase
    .from("user_links")
    .delete()
    .eq("user_id", user_id)
    .eq("collection_name", collectionName)
    .eq("id", id);
}

export async function updateUserLinksCollectionName(
  component: any,
  name: any,
  user_id: any
) {
  await supabase
    .from("user_links")
    .update({
      collection_name: name,
    })
    .eq("user_id", user_id)
    .eq("collection_name", component?.name)

}


export async function UpdateUserLinksIndex(newOrder: any, user_id: string, name: string) {
  // Update the index of the dragged item
  // await supabase
  //   .from("user_links")
  //   .update({ index: destinationIndex })
  //   .eq("index", sourceIndex)
  //   .eq("collection_name", name)
  //   .eq("user_id", user_id);

  // // Fetch all items in the collection to adjust their indices
  // const { data: links, error: fetchError }: any = await supabase
  //   .from("user_links")
  //   .select("id, index")
  //   .eq("collection_name", name)
  //   .eq("user_id", user_id)
  //   .order("index", { ascending: true });

  // if (fetchError) {
  //   console.error("Error fetching links:", fetchError);
  //   return;
  // }

  // // Adjust indices to fill gaps or resolve conflicts
  // for (let [idx, link] of links.entries()) {
  //   // Assign a new index based on the position in the sorted array
  //   const newIndex = idx;
  //   if (link.index !== newIndex) {
  //     await supabase
  //       .from("user_links")
  //       .update({ index: newIndex })
  //       .eq("id", link.id)
  //       .eq("collection_name", name)
  //       .eq("user_id", user_id);
  //   }
  // }


  for (let [idx, link] of newOrder.entries()) {
    // Assign a new index based on the position in the sorted array
    const newIndex = idx;
    // if (link.index !== newIndex) {
    await supabase
      .from("user_links")
      .update({ index: newIndex })
      .eq("id", link.id)
      .eq("collection_name", name)
      .eq("user_id", user_id);
  }
  // }


}