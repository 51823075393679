import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { updateEmail } from "../../../../Utils/SupabaseServices/UserDatabase";
import validator from "validator";
import { supabase } from "../../../../config/supabaseClient";
import { Button, Input, Modal } from "antd";
import axios from "axios";

const Email = (props: any) => {
  const { user_id, username, userData, setIsEmail, setIsEmailVerified } = props;
  const [email, setEmail] = useState(userData?.email || "");
  const [originalEmail, setOriginalEmail] = useState(userData?.email || ""); // Store the original email value

  const [editEmail, setEditEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isEmailVerify, setIsEmailVerify] = useState<any>();
  const [otp, setOTP] = useState("");
  const [modalError, setModalError] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const [isTabsLocked, setIsTabsLocked] = useState<any>();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const data = localStorage.getItem("email_verify");
      if (data === "false") {
        setIsTabsLocked(true);
      } else {
        setIsTabsLocked(false);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial setup (run once)
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window]);

  const validateEmail = async (email: any) => {
    localStorage.setItem("email_verify", "false");
    if (validator.isEmail(email)) {
      return true;
    } else {
      setEmailError("Enter Valid Email!");
      // showModal();
      setIsEmail(false);
      await updateEmail(user_id, username, email);
    }
  };

  async function sendEmailOTP() {
    setOTP("");
    setModalError("");
    setIsModalOpen(true);
    setModalContent(true);
    setEmailError("Verify Email");

    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
    });
    if (error !== null) {
      setModalError("Error in sending OTP. Try again after 60 seconds");
    }
  }

  function handleUpdateValue(e: any, option: any) {
    if (option === "email") {
      setEmail(e.target.value);
    }
  }

  const doneEdit = async (option: any) => {
    if (option === "email") {
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email");
        return;
      }
      await updateEmail(username, email.trim().toLowerCase(), user_id);
      setEditEmail(false);
      setIsEmail(true);
      setIsEmailVerified(false);
    }
  };

  async function verifyEmail() {
    try {
      await supabase.auth
        .verifyOtp({ email: email, token: otp, type: "email" })
        .then(async (res: any) => {
          if (res.error === null) {
            const { data, error } = await supabase
              .from("user")
              .update({
                // email: `${email}`,
                // emailSignup: `${email}`,
                email_verify: true,
              })
              .eq("username", `${username}`);

            // supabase.auth.admin.deleteUser(user_id)
            localStorage.removeItem("email_verify");

            setIsModalOpen(false);
            props.setIsEmail(true);
            props.setIsEmailVerified(true);

            setIsEmailVerify(true);
          } else {
            setModalError("Invalid OTP");
          }
        });
    } catch (error) {}
  }

  return (
    <div className="account-tile">
      <div className="account-tile-header">
        <div className="account-tile-title">
          Email <BsInfoCircle data-tooltip-id="email" />
        </div>
      </div>
      <div className="account-tile-value">
        {!editEmail ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{email}</span>
            <Button
              className="accountButton"
              onClick={() => {
                setEditEmail(true);
              }}
              style={{
                marginLeft: '1rem',
                padding: '0.5rem 1rem'
              }}
            >
              Change
            </Button>
          </div>
        ) : (
          <div>
            <input
              type="email"
              style={{ 
                width: '100%', 
                padding: '0.5rem', 
                borderRadius: '6px', 
                border: '1px solid #e5e7eb',
                marginBottom: '0.75rem'
              }}
              onChange={(e: any) => handleUpdateValue(e, "email")}
              value={email}
            />
            <div className="account-button-group">
              <Button
                className="accountButton"
                onClick={() => {
                  doneEdit("email");
                }}
              >
                Save
              </Button>
              <Button
                className="cancelButton"
                onClick={() => {
                  setEditEmail(false);
                  setEmailError("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
      {emailError && <p style={{ color: '#ef4444', fontSize: '0.875rem', marginTop: '0.5rem' }}>{emailError}</p>}
      {!userData?.email_verify && (
        <div style={{ 
          backgroundColor: 'rgba(36, 70, 240, 0.08)', 
          borderRadius: '6px', 
          padding: '0.75rem', 
          marginTop: '0.75rem', 
          fontSize: '0.875rem' 
        }}>
          <p style={{ margin: 0, color: '#2446f0' }}>Please verify your email for full access to features.</p>
        </div>
      )}
      <Modal
        title={emailError}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
        footer={null}
      >
        {modalContent === true ? (
          <>
            <div className="alignModal">
              <Input
                type="text"
                className="otp-input"
                placeholder="Enter 6 digit OTP"
                onChange={(e: any) => setOTP(e.target.value)}
                value={otp}
              />
              <Button className="modal-verify-btn" onClick={verifyEmail}>
                Verify
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
        {modalError ? <p className="errorText">{modalError}</p> : ""}
      </Modal>
    </div>
  );
};

export default Email;

// 		{modalTitle.includes("Phone") ? (
// 			<>
// 				<input
// 					type='text'
// 					className='otp-input'
// 					placeholder='Enter 6 digit OTP'
// 					onChange={(e: any) => setOTP(e.target.value)}
// 					value={otp}
// 				/>
// 				<button
// 					className='modal-verify-btn'
// 					onClick={verifyPhoneNumber}
// 				>
// 					Verify
// 				</button>
// 			</>
// 		) : modalTitle.includes("Email") ? (
// 			<>

//                  : (
// 	""
// )}
