import { Segmented, Typography } from 'antd';
import React from 'react';
import { SoundOutlined, AudioMutedOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CarouselSoundControl = (props: any) => {
  const { isSoundOn, setIsSoundOn } = props;

  const handleToggle = (value: boolean) => {
    setIsSoundOn(value);
  };

  return (
    <div className='mb-3'>
      <Title level={4}>Content Sound</Title>
      <Segmented
        options={[
          {
            value: true,
            icon: <SoundOutlined />,
            label: 'On'
          },
          {
            value: false,
            icon: <AudioMutedOutlined />,
            label: 'Off'
          }
        ]}
        value={isSoundOn}
        onChange={handleToggle}
        className="custom-segmented-order"
      />
    </div>
  );
};

export default CarouselSoundControl;