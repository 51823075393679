import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { updateName } from "../../../../Utils/SupabaseServices/UserDatabase";
import { Button } from "antd";

const Name = (props: any) => {
  const { username, userData, setIsName } = props;
  const [name, setName] = useState(userData.name || "");
  const [originalName, setOriginalName] = useState(userData.name || ""); // Keep track of original name
  const [editName, setEditName] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [nameError, setNameError] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  function handleUpdateValue(e: any, option: any) {
    if (option === "name") {
      if (e.target.value === "") {
        setName(e.target.value);
        setNameError("Name cannot be empty");
      } else {
        setName(e.target.value);
        setNameError("");
      }
    }
  }

  const doneEdit = async (option: any) => {
    if (option === "name") {
      if (name === "" || name === "null") {
        setModalTitle("Enter Name!");
        showModal();
        await updateName(username, name);
        setIsName(false);
      } else {
        await updateName(username, name);
        setIsName(true);
      }
    }
  };

  return (
    <div className="account-tile">
      <div className="account-tile-header">
        <div className="account-tile-title">
          Name <BsInfoCircle data-tooltip-id="displayName" />
        </div>
      </div>
      <div className="account-tile-value">
        {!editName ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{name || ""}</span>
            <Button
              className="accountButton"
              onClick={() => {
                setEditName(true);
                setOriginalName(name); // Store the current name before editing
              }}
              style={{
                marginLeft: '1rem',
                padding: '0.5rem 1rem'
              }}
            >
              Change
            </Button>
          </div>
        ) : (
          <div>
            <input
              type="text"
              style={{ 
                width: '100%', 
                padding: '0.5rem', 
                borderRadius: '6px', 
                border: '1px solid #e5e7eb',
                marginBottom: '0.75rem'
              }}
              onChange={(e: any) => handleUpdateValue(e, "name")}
              value={name}
            />
            <div className="account-button-group">
              <Button
                className="accountButton"
                onClick={() => {
                  setEditName(false);
                  doneEdit("name");
                }}
                disabled={nameError === "Name cannot be empty"}
              >
                Save
              </Button>
              <Button
                className="cancelButton"
                onClick={() => {
                  setEditName(false);
                  setName(originalName); // Revert to the original name on cancel
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
      {nameError && <p style={{ color: '#ef4444', fontSize: '0.875rem', marginTop: '0.5rem' }}>{nameError}</p>}
    </div>
  );
};

export default Name;
