import React, { useEffect, useState } from "react";
import { Radio, Segmented } from "antd";
import {
  updateContentOrder, updateShoppableContent,
} from "../../../Utils/SupabaseServices/ControlsDatabase";

import { RiShoppingBagLine } from "react-icons/ri";
const ShoppableContentRadio = (props: any) => {
  const { username, checkedType, user_id, page } = props;
  const typeList = ["icon", "text"];

  const [selectedType, setSelectedType] = useState(checkedType || "");
  const [selectedValue, setSelectedValue] = useState(checkedType || "");
  const handleChange = (value: string) => {
    setSelectedValue(value);
    setSelectedType(value);
    updateShoppableContent(user_id, value, page);
  };
  return (
    <div className="content-type-radios">
      {/* <Radio.Group
        onChange={handleChange}
        value={selectedType}
        className="radioGroup"
      >
        {typeList.map((type, index) => (
          <Radio.Button
            key={type}
            value={type}
            className="radioButton"
            style={{
              marginRight: index < typeList.length - 1 ? "10px" : "0",
              backgroundColor: selectedType === type ? "black" : "white",
              color: selectedType === type ? "white" : "black",
            }}
          >
            {type==="icon"?<RiShoppingBagLine/>:type==="text"?"Shop":""}
          </Radio.Button>
        ))}
      </Radio.Group> */}

<Segmented
  options={[
    {
      label: <RiShoppingBagLine />,
      value: 'icon'
    },
    {
      label: 'Shop',
      value: 'text'
    }
  ]}
  style={{
    backgroundColor: selectedValue ? "#999" : "#2c3e50",
    color: "#fff"
  }}
  value={selectedValue}
  onChange={handleChange}
/>
    </div>
  );
};

export default ShoppableContentRadio;
