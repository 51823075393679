import { Button, Input, message, Modal, Upload, UploadProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { UploadOutlined } from "@ant-design/icons";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { supabase } from "../../../../../config/supabaseClient";
import Dragger from "antd/es/upload/Dragger";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";
import { ModalBackButton } from "../../../../../components/EditModalComponents.tsx/ModalBackButton";

const CustomBannerComponent = (props: any) => {
  const {
    username,
    user_id,
    isCustomBannerModalOpen,
    setIsCustomBannerModalOpen,
    setIsModalOpen,
    refetch,
    from
  } = props;
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [cta, setCTA] = useState("Check it out!");

  const [imageUrl, setImageUrl] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fileList, setFileList] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [errorInputMsg, setErrorInputMsg] = useState({
    buttonErrorMsg:"",
    titleErrorMsg:"",
  });
  useEffect(() => {
    setIsButtonDisabled(!name || !cta || !link || !imageUrl );
  }, [name, link, imageUrl,,cta]);
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      name,
      [imageUrl, link].toString(),
      username,
      user_id,
      "false",
      cta,
      "",
      name,
      "banner",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      from
    );
    if (res === "true") {
      refetch();
      setIsCustomBannerModalOpen(false);
      setIsModalOpen(false);
      setName("");
      setLink("");
      setImageUrl("");
      setFileList([]);
      setErrorMsg("");
      setCTA("Check it out!");
      setConfirmLoading(false);
    } else {
      setErrorMsg("Component with this name already exist.");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsCustomBannerModalOpen(false);
    setIsModalOpen(false);
    setName("");
    setLink("");
    setImageUrl("");
    setFileList([]);
    setErrorMsg("");
    setCTA("Check it out!");
  };
  const handlePrevious = () => {
    setIsModalOpen(true);
    setIsCustomBannerModalOpen(false);
    setName("");
    setLink("");
    setImageUrl("");
    setFileList([]);
    setErrorMsg("");
    setCTA("Check it out!");
  };
  const handleInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<string>>, limit: number) => {
    if (limit && value.length <= limit) {
      limit === 15 ? setErrorInputMsg({ ...errorInputMsg, buttonErrorMsg: "" }) : setErrorInputMsg({ ...errorInputMsg, titleErrorMsg: "" })
      setter(value);
    } else {
      limit === 15 ? setErrorInputMsg({
        ...errorInputMsg,
        buttonErrorMsg: `Button Text is limited to ${limit} characters.`
      }) : setErrorInputMsg({
        ...errorInputMsg,
        titleErrorMsg: `Banner Title is limited to ${limit} characters.`
      })  
    }
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setImageUrl,
          storageName: "banner_section"
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, supabase]
  );


  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  }

  

  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage || Upload.LIST_IGNORE; // Prevent uploading non-image files
  };

  useEffect(() => {
    let buttonErrorTimer: NodeJS.Timeout;
    let titleErrorTimer: NodeJS.Timeout;

    if (errorInputMsg.buttonErrorMsg) {
      buttonErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, buttonErrorMsg: "" }));
      }, 10000);
    }

    if (errorInputMsg.titleErrorMsg) {
      titleErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, titleErrorMsg: "" }));
      }, 10000);
    }

    return () => {
      clearTimeout(buttonErrorTimer);
      clearTimeout(titleErrorTimer);
    };
  }, [errorInputMsg]);

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button onClick={handlePrevious} className="newbutton">
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isCustomBannerModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        okButtonProps={{ disabled: isButtonDisabled }}
        data-testid="create-banner"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton handlePrevious={handlePrevious}  />
        <div>
          <h5 className="title mb-20">Create a Banner </h5>
          <div className="preview">
            <div className="banner-wrapper">
              <div className="previewHeading">
                {name ? name : "Banner Title"}
              </div>
              <div>
                <Button
                  className="ant-btn css-dev-only-do-not-override-1nimcbs ant-btn-primary banner-button-primary"
                  id="banner-add"
                >
                  {cta}
                </Button>
              </div>
            </div>

            <div className="previewImage">
              <img
                src={imageUrl ? imageUrl : "/assets/image-bg.jpeg"}
                // src={component?.details?.split(",")[0]}
              />
            </div>
          </div>
          <div className="fieldGroup">
            <label className="bold-label">Banner Title</label>
            <Input
              placeholder="Enter Title"
              value={name}
              onChange={(e: any) => {
                handleInputChange(e.target.value, setName, 150);
                setErrorMsg("");
              }}
              className="mb-20"
              id="banner-title"
            />
            {errorInputMsg && errorInputMsg.titleErrorMsg && <p className="errorMsg">{errorInputMsg.titleErrorMsg}</p>}
            <label className="bold-label">Button Text</label>
            <Input
              placeholder="Enter Button Text"
              value={cta}
              onChange={(e: any) => handleInputChange(e.target.value, setCTA, 15)}
              className="mb-20"
              id="banner-button"
            />
             {errorInputMsg && errorInputMsg.buttonErrorMsg && <p className="errorMsg">{errorInputMsg.buttonErrorMsg}</p>}
            <label className="bold-label">Button Link</label>
            <Input
              placeholder="www.yourlink.com"
              value={link}
              onChange={(e: any) => setLink(e.target.value)}
              className="mb-20"
              id="banner-link"
            />
            <div className="uploadDiv">
              <Upload
                accept="image/*"
                {...uploadProps}
                multiple={false}
                maxCount={1}
                beforeUpload={beforeUpload}
                showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false,}}
              >
                <Button
                  disabled={!name}
                  className="uploadButton"
                  id="banner-image"
                >
                  <div className="text-center">
                    <p className="textContainerHeading">
                      Click to select image or drag & drop to this area to
                      upload
                    </p>
                    <p className="upload-text">
                      {" "}
                      JPG, JPEG, PNG, HEIC, or WEBP
                    </p>
                  </div>
                  {/* Click to Upload */}
                </Button>
              </Upload>
            </div>
          </div>
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        </div>
      </Modal>
    </>
  );
};

export default CustomBannerComponent;
