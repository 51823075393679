import ResetPassword from "../Screens/Auth/ResetPassword/resetPassword";
import UpdatePassword from "../Screens/Auth/ResetPassword/updatePassword";
import Dashboard from "../Screens/DashboardView/Dashboard/Dashboard";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import HomePage from "../Screens/HomePage/HomePage";
import SignUp from "../Screens/Auth/SignUpAuth/SignUp";
import ProtectedRoute from "./ProtectedRoute";
import GlobalView from "../Screens/GlobalView/GlobalView";
import Login from "../Screens/Auth/LoginAuth/Login";
import Error404 from "../ErrorPages/Error404";
import Error401 from "../ErrorPages/Error401";
import Error400 from "../ErrorPages/Error400";
import NoUser from "../ErrorPages/NoUser";
import AuthRoute from "./AuthRoute";
import GoogleCallback from "../components/GoogleCallback/GoogleCallback";
import Privacy from "../Utils/Privacy";
import Subscribe from "../Utils/Subscribe";
import SubscribeTest from "../Utils/SubscribeTest";
import PaymentCanceled from "../Utils/PaymentCanceled";
import FollowerView from "../Screens/FollowerView/FollowerView";
import LoadingDummyPage from "../Screens/LoadingDummyPage/LoadingDummyPage";
import EmailLogin from "../Screens/Auth/LoginProviders/EmailLogin";
import DashboardRedirect from "../Screens/DashboardView/Dashboard/DashboardRedirect";
import HowItWorks from "../Utils/HowItWorks";
// import Flowdesk from "./Flowdesk";
// import DragDrop from "./DragDrop";
import { useEffect } from "react";
import ContactUs from "../Screens/FollowerView/ContactUs";
import FeedControls from "../components/FeedPage/FeedControls/FeedControls";
import OnboardingPage from "../Screens/Auth/OnboardingFlow/OnboardingPage";
import PaymentStatus from "../Screens/Payment/PaymentStatus";
import { SidebarProvider } from '../context/SidebarContext';
import { SidebarStateProvider } from '../context/SidebarStateContext';
import EmbeddedView from "../Screens/EmbeddedView/EmbeddedView";
import AcceptInvitePage from "../Screens/Auth/AcceptInvite/AcceptInvitePage";
import AddMember from "../Screens/DashboardView/Account/AccountSection/AddMember";

const Routing = () => {
  function PrivateBetaRedirect() {
    useEffect(() => {
      window.location.href = 'https://calendly.com/searchbubble/private-beta-onboarding?month=2024-09';
    }, []);

    return null;
  }
  return (
    <BrowserRouter>
      <SidebarStateProvider>
        <SidebarProvider>
          <Routes>
            <Route path="/" element={<AuthRoute Component={HomePage} />} />
            <Route path="/login" element={<AuthRoute Component={Login} />} />
            <Route path="/onboarding" element={<AuthRoute Component={OnboardingPage} />} />

        <Route path="/signup" element={<AuthRoute Component={SignUp} />} />
        <Route path="/googleCallback" element={<GoogleCallback />} />
        {/* <Route path="/auth/resetPassword" element={<ResetPassword />} /> */}
        <Route path="/auth/update-password" element={<UpdatePassword />} />
        <Route path="/login-email" element={<EmailLogin />} />
        <Route path="/subscribe" element={<SubscribeTest />} />
        <Route path="/subscribe-test" element={<Subscribe />} />
        <Route path="/payment-status" element={<PaymentStatus />} />
        <Route path="/payment-canceled" element={<PaymentCanceled />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        {/* <Route path="/flowdesk" element={<Flowdesk />} /> */}
            <Route
              path="/accept-invite"
              element={<AcceptInvitePage />}
            />
            <Route path="/signup" element={<AuthRoute Component={SignUp} />} />
            <Route path="/googleCallback" element={<GoogleCallback />} />
            {/* <Route path="/auth/resetPassword" element={<ResetPassword />} /> */}
            <Route path="/auth/update-password" element={<UpdatePassword />} />
            <Route path="/login-email" element={<EmailLogin />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            {/* <Route path="/flowdesk" element={<Flowdesk />} /> */}
            {/* 
            <Route
              path="/creator/:username"
              element={<ProtectedRoute Component={CreatorView} />}
            /> */}

            <Route
              path="/loading/:username"
              element={<ProtectedRoute Component={LoadingDummyPage} />}
            />

            <Route
              path="/dashboard/:username"
              element={<ProtectedRoute Component={Dashboard} />}
            />


            <Route
              path="/dashboard/productbank/:username"
              element={<ProtectedRoute Component={Dashboard} />}
            />
            <Route path="/dashboard/" element={<DashboardRedirect />} />
            {/* <Route
						path='/dashboard/:username'
						element={<Dashboard />}
					/> */}
            <Route
              path="/follower/:username"
              element={<ProtectedRoute Component={FollowerView} />}
            />
            <Route path="/:username">
              <Route index element={<FollowerView />} />
              <Route path="contact-me" element={<FollowerView />} />
            </Route>
            <Route path="/embed/:username" element={<EmbeddedView />} />

            <Route path="/:username/:id" element={<FollowerView />} />
            <Route path="/global" element={<GlobalView />} />
            {/* <Route
              path="/creator/:username?term=:queryTerm&from=:searchFrom"
              element={<ProtectedRoute Component={CreatorView} />}
            /> */}
            <Route path="/private-beta" element={<PrivateBetaRedirect />} />


            <Route
              path="/global?term=:queryTerm&from=:searchFrom"
              element={<GlobalView />}
            />

            <Route path="/add-member" element={<AddMember />} />

            <Route path="/no-user-found" element={<NoUser />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/401" element={<Error401 />} />
            <Route path="/*" element={<Error400 />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </SidebarProvider>
      </SidebarStateProvider>
    </BrowserRouter>
  );
};

export default Routing;
