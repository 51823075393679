import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import '../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { updateComponentSingle } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { EditHighlightLinksComponents } from "./EditHighlightsLinksComponent";

const EditHighlightLinks = (props: any) => {
  const { username, user_id, isHighlightEditModalOpen, component,from } = props;
  const [activeKey, setActiveKey] = useState("content");
  const [dataCompleted, setDataCompleted] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [image, setImage] = useState("");
  const [cta, setCTA] = useState("");
  const [view, setView] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setName(component?.name);
    const details = component?.details;
    if (details) {
      const parts = details.split(',');
      setLink(parts[1]);
      setImage(parts[0]);
      const lastPart = parts[parts.length - 1]?.trim();
      if (lastPart === "left" || lastPart === "right") {
        setView(lastPart);
      }
    }

    setCTA(component?.cta_placeholder);
  }, [component]);
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponentSingle(
      name,
      component,
      [image, link, view].toString(),
      username,
      user_id,
      cta,
      "highlight",
      "",
      from
    );
    if (res === "true") {
      props.refetch();
      setName("");
      setLink("");
      setImage("");
      setType("");
      setActiveKey("content");
      setCTA("");
      props.setSelectedComponent("");
      setConfirmLoading(false);

      props.setIsHighlightEditModalOpen(false);
      setErrorMsg("");
    } else {
      setErrorMsg("Component with this name already exists.");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsHighlightEditModalOpen(false);

    setName("");
    setLink("");
    setImage("");
    setType("");
    setCTA("");

    props.setSelectedComponent("");

    setDataCompleted(false);
    setErrorMsg("");
  };

  async function handleData(
    image: any,
    name: any,
    link: any,
    kind: any,
    cta: any
  ) {
    if (image && name && link) {
      setName(name);
      setLink(link);
      setImage(image);
      setType(kind);
      setCTA(cta);
      setDataCompleted(true);
    } else {
      setDataCompleted(false);
    }
  }

  return (
    <>
      <Modal
        open={isHighlightEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled: (!cta  || !name || !link || errorMsg.length > 0)
        }}
        data-testid="edit-highlight"
      >
        <div className="title">
          <h5>Editing {name}</h5>
        </div>

        <EditHighlightLinksComponents
          username={username}
          user_id={user_id}
          handleData={handleData}
          defaultName={name}
          link={link}
          image={image}
          setErrorMsg={setErrorMsg}
          cta={cta}
          setCTA={setCTA}
          setName={setName}
          setLink={setLink}
          setImage={setImage}
          view={view}
          setView={setView}
          errorMsg={errorMsg}
          setIsButtonDisabled={setIsButtonDisabled}
        />
      </Modal>
    </>
  );
};

export default EditHighlightLinks;
