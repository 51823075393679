import dayjs from "dayjs";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadTypeContentData(
  username: any,
  user_id: any,
  platform: any,
  type: any,
  removedPostIds: any,
  order: string
) {
  async function getPaginatedFeed() {
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",").filter((elem: any) => elem !== ""))
      );
    }
    let data,
      error = null;
    if (type === "TIKTOK") {
      const { data: content, error: contentError } = await supabase.rpc(
        "get_tiktoktypecontenttteo",
        {
          userid: user_id,
          // user_name: "content_new",
          user_name: username,
          platform_name: platform,
          feed_type: type,
          order_type: order === "DESC" ? "true" : "false",
        }
      );
      data = content;
      error = contentError;
    } else if (type === "STORY") {
      const today = dayjs().format("YYYY-MM-DD");
      const yesterday = dayjs().subtract(7, "day").format("YYYY-MM-DD");
      const { data: content, error: contentError } = await supabase.rpc(
        "get_storytypecontenttteo",
        {
          userid: user_id,
          // user_name: "content_new",
          user_name: username,
          platform_name: platform,
          feed_type: type,
          start_date: yesterday,
          end_date: today,
          order_type: order === "DESC" ? "true" : "false",
        }
      );
      data = content;
      error = contentError;
    } else if (type === "FEATURE") {
      const { data: content, error: contentError } = await supabase.rpc(
        "get_featuredddataaee",
        {
          userid: user_id,
          filter: [
            "FEED",
            "REELS",
            "STORY",
            "HIGHLIGHTS",
            "PIN",
            "VIDEO",
            "PODCAST",
            "TIKTOK",
            "MAIL",
            "TWEET",
            "PRODUCT",
            "POST"
          ],
          query_str: "",
          user_name: username,
          socials: [
            "instagram",
            "youtube",
            "tiktok",
            "podcast",
            "pinterest",
            "newsletter",
            "twitter",
            "LTK",
            "Amazon",
            "ShopMy",
            "MISC",
            "linkedin",
            "MISC-content"
          ],
        }
      );
      data = content;
      error = contentError;
    } else {
      if (platform) {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_typecontentteo",
          {
            userid: user_id,
            // user_name: "content_new",
            user_name: username,
            platform_name: platform,
            feed_type: type,
            order_type: order === "DESC" ? "true" : "false",
          }
        );
        data = content;
        error = contentError;
      }
    }
    // Get yesterday's date
    if (data && data.length >= 0 && Array.isArray(data)) {
      let filteredArray = data;

      if (removedPostIds) {
        filteredArray = data.filter(
          (obj: any) => !removedPostIds.includes(obj.post_id)
        );
      }
      return filteredArray;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
    // return resultArray
  }

  return useQuery(["typeContentPosts", user_id, type, removedPostIds,order], getPaginatedFeed, {});
}
