import React from "react";
import { BsSearch } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";

const StayUpToDateSkeleton = ({ from }: any) => {
  function SkeletonStatBox() {
    return <Skeleton className="stayUpToDate-skeleton" />;
  }

  return (
    <>
     
      {[...Array(3)].map((_, index) => (
        <SkeletonStatBox key={index} />
      ))}
    </>
  );
};

export default StayUpToDateSkeleton;
