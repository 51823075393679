import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { Switch, Modal } from "antd";
import { HiTrash } from "react-icons/hi";
import {
  deleteFillout,
  deleteFlowdesk,
  deleteFlowdeskPopup,
  deleteInstagram,
  deleteNewsletter,
  deletePinterest,
  deletePodcast,
  deleteTiktok,
  deleteYoutube,
} from "../../Utils/SupabaseServices/UserDatabase";
import { useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import "../Header/Header.css";

const IntegrationPlatforms = (props: any) => {
  const { username } = useParams();
  const {
    platformName,
    isConnected,
    isProcessing,
    isPlatformEnabled,
    onTogglePlatform,
    onConnectPlatform,
    onDeletePlatform,
    showConfirmation,
    platformTodelete,
    userId,
    refetchData,
    subscription_type,
    first_connected_platform,
    label,
  } = props;
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const handleCancelDelete = () => {
    props.setShowConfirmation(false);
  };
  const handleConfirmDelete = async (platform: any) => {
    switch (platform) {
      case "Instagram":
        await deleteInstagram(username, userId).then(() => {
          refetchData();
        });
        break;

      case "YouTube":
        await deleteYoutube(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Podcast":
        await deletePodcast(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Newsletter":
        await deleteNewsletter(username, userId).then(() => {
          refetchData();
        });

        break;

      case "TikTok":
        await deleteTiktok(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Pinterest":
        await deletePinterest(username, userId).then(() => {
          refetchData();
        });
        break;

      case "Flodesk Inline":
        await deleteFlowdesk(username, userId).then(() => {
          refetchData();
        });
        break;
      case "Flodesk Popup":
        await deleteFlowdeskPopup(username, userId).then(() => {
          refetchData();
        });
        break;
      case "Fillout":
        await deleteFillout(username, userId).then(() => {
          refetchData();
        });

        break;

      default:
        break;
    }

    props.setShowConfirmation(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    return (
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  // Helper function to determine if platform is available
  const isPlatformAvailable = () => {
    if (first_connected_platform === platformName) {
      return true;
    }
    
    if (subscription_type === "basic" && first_connected_platform !== platformName) {
      return false;
    }
    
    return true;
  };

  // Render appropriate status element based on platform state
  const renderStatusElement = () => {
    if (!isPlatformAvailable()) {
      return (
        <div className="platform-lock" onClick={() => setIsModalOpen(true)}>
          <FaLock className="lock-icon" />
        </div>
      );
    }
    
    if (isConnected) {
      if (!isProcessing) {
        return (
          <div className="status-badge processing">
            <p>Processing</p>
          </div>
        );
      } else {
        return (
          <div className="connected-badge">
            <span className="connected-text">Connected</span>
          </div>
        );
      }
    } else {
      return (
        <button className="connect-button" onClick={onConnectPlatform}>
          Connect
        </button>
      );
    }
  };

  return (
    <div className="platform-card">
      <div className="platform-info">
        <p className={isConnected ? "platform-name connected" : "platform-name"}>
          {platformName === "Twitter" ? "X" : platformName}
        </p>
        
        {label && (
          <p className="platform-username">{label}</p>
        )}
      </div>
      
      <div className="platform-status-container">
        {renderStatusElement()}
      </div>
      
      {/* <div className="platform-actions">
        <Switch
          className="platform-toggle"
          checked={isPlatformEnabled}
          onChange={onTogglePlatform}
          disabled={!isConnected && !isProcessing}
        />
      </div> */}

      <Modal
        title="Upgrade Your Plan"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button key="cancel" onClick={handleCancel} className="modal-cancel-btn">
            Cancel
          </button>,
          <a
            key="upgrade"
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="modal-upgrade-btn">Upgrade</button>
          </a>,
        ]}
      >
        <p className="upgrade-text">
          Upgrade to a pro plan to connect more platforms and unlock additional features.
        </p>
      </Modal>

      {showConfirmation && platformTodelete === platformName && (
        <div className="confirmation-dialog">
          <p className="platform-name">
            Are you sure you want to delete {platformName}'s integration and its
            data?
          </p>
          <button className="confirm-btn cancel" onClick={handleCancelDelete}>
            Cancel
          </button>
          <button
            className="confirm-btn confirm confirm-btn-color"
            onClick={() => handleConfirmDelete(platformName)}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default IntegrationPlatforms;
