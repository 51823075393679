import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetUserPassword } from "../../../Utils/SupabaseServices/UserDatabase";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import validator from "validator";
import "../../../Screens/Auth/OnboardingFlow/OnboardingPage.css";
import { supabase } from "../../../config/supabaseClient";
import {
  AiOutlineMail,
  AiOutlineLoading3Quarters,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInstructionsSent, setIsInstructionsSent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    if (validator.isEmail(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return;
    }

    setIsLoading(true);

    try {
      // First check if the email exists in the database
      const { data: existingUser } = await supabase
        .from("user")
        .select("*")
        .eq("email", email);
      if (!existingUser || existingUser.length === 0) {
        setError("No account found with this email address");
        setMsg("");
        setIsLoading(false);
        return;
      }

      const resetResponse = await resetUserPassword(email);
      if (!resetResponse) {
        setError("Failed to reset password");
        setMsg("");
      } else {
        setMsg("Password reset instructions sent to your email!");
        setError("");
        setIsInstructionsSent(true);
        track(location.pathname, TRACKING_TYPES.PAGE, {
          pathname: location.pathname,
          details: { email: email }
        });
      }
    } catch (err: any) {
      setError(err.message);
      setMsg("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/login");
  };

  return (
    <div className="instagram-card">
      <div className="card-header">
        <div className="instagram-icon-wrapper">
          <AiOutlineMail className="instagram-icon" />
        </div>
        <h2 className="card-title">Reset Password</h2>
        <p className="card-description">
          Enter your email and we'll send you instructions to reset your password
        </p>
      </div>

      {error && <p className="error-message">{error}</p>}
      {msg && (
        <div className="success-message" style={{ 
          color: "#10B981", 
          backgroundColor: "rgba(16, 185, 129, 0.1)", 
          padding: "12px 16px", 
          borderRadius: "8px", 
          fontSize: "14px",
          marginBottom: "20px"
        }}>
          <p style={{ margin: 0 }}>{msg}</p>
        </div>
      )}

      <form onSubmit={handleResetPassword} style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div className={`input-wrapper ${emailError ? 'error' : ''}`}>
          <AiOutlineMail className="input-prefix" />
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
              setError("");
            }}
            className="instagram-input"
            placeholder="Your email address"
            disabled={isInstructionsSent}
          />
        </div>
        {emailError && (
          <p
            className="error-message"
            style={{ marginTop: "-8px", marginBottom: "0" }}
          >
            {emailError}
          </p>
        )}

        <div style={{ marginTop: "8px" }}>
          <button
            type="submit"
            disabled={isLoading || isInstructionsSent || !email}
            className="next-button"
          >
            {!isLoading ? (
              <>
                {isInstructionsSent ? "Instructions Sent" : "Send Reset Instructions"}
                {!isInstructionsSent && <HiArrowRight className="arrow-icon" />}
              </>
            ) : (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
          </button>
        </div>

        <div style={{ marginTop: "8px" }}>
          <button
            type="button"
            onClick={handleBack}
            className="back-button"
          >
            <AiOutlineArrowLeft style={{ marginRight: "8px" }} />
            Back to Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;