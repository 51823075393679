import { Button, Segmented, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLoadStayUpToDateComponents } from "../../../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import "../Charts.css";
import ChartClicksTable from "./ChartClicksTable";
import { AgChartsReact } from "ag-charts-react";
import StatBox from "../../../../../components/StatBox/StatBox";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { BannerSkeleton } from "../../../../../components/StayUpToDateComponent/BannerSkeleton";
import { SkeletonStatBox } from "../../Overview/OverviewSmoothCharts";

// Add the smooth curve processing function
const processDataForSmoothCurves = (data: any[]) => {
  if (!data || data.length === 0) return [];

  const result = [];

  for (let i = 0; i < data.length - 1; i++) {
    const current = data[i];
    const next = data[i + 1];

    result.push(current);

    for (let j = 1; j <= 3; j++) {
      const ratio = j / 4;

      const interpolated = {
        ...current,
        clicks: current.clicks + (next.clicks - current.clicks) * ratio,
        formatted_date: `${current.formatted_date}-${j}`,
      };

      result.push(interpolated);
    }
  }

  if (data.length > 0) {
    result.push(data[data.length - 1]);
  }

  return result;
};

const ContentChartNew = (props: any) => {
  const {
    username,
    user_id,
    postClicksAnalytics,
    loadingPostClicksAnalytics,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
    carouselClicksViewsAnalytics,
    loadingCarouselClicksViewsAnalytics,
    carouselClicksAnalytics,
    loadingCarouselClicksAnalytics,
    daysNumber,
    brand_color,
    carouselViews,
    loadingCarouselViews,
    elem,
  } = props;

  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, user_id);

  const [selectedComponent, setSelectedComponent] = useState<any>(elem.name);
  const [defaultCurrentPage, setDefaultCurrentPage] = useState(1);

  // Process data for smoother curves
  let smoothedData = [];
  if (
    carouselClicksViewsAnalytics &&
    carouselClicksViewsAnalytics[selectedComponent]
  ) {
    smoothedData = processDataForSmoothCurves(
      carouselClicksViewsAnalytics[selectedComponent]
    );
  }
  // Add chart options state
  const [chartOptions, setChartOptions] = useState<any>({
    data: smoothedData,
    series: [
      {
        type: "line",
        xKey: "formatted_date",
        yKey: "clicks",
        yName: "Clicks",
        stroke: "#4F46E5",
        strokeWidth: 3,
        marker: { enabled: false },
        tooltip: {
          enabled: true,
          renderer: (params: any) => {
            const date = new Date(params.datum.formatted_date);
            const formattedDate = date.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            });
            return {
              title: formattedDate,
              content: `Clicks: ${Math.round(params.datum.clicks)}`,
            };
          },
        },
        smoothing: 0.6,
        lineDash: [0],
        interpolation: "monotoneX",
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
        title: { enabled: false },
        label: {
          fontSize: 12,
          color: "#6B7280",
          rotation: 0,
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
      },
      {
        type: "number",
        position: "left",
        title: { enabled: false },
        label: {
          fontSize: 12,
          color: "#6B7280",
        },
        gridStyle: [{ stroke: "#E5E7EB", lineDash: [5, 5] }],
      },
    ],
    background: {
      fill: "white",
    },
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
    legend: {
      position: "bottom",
      spacing: 40,
      item: {
        marker: {
          shape: "circle",
          size: 8,
        },
        label: {
          fontSize: 14,
          color: "#4B5563",
        },
      },
    },
    tooltipTracking: true,
    tooltipClass: "ag-chart-tooltip",
  });

  // Update chart data when analytics change
  useEffect(() => {
    if (
      selectedComponent &&
      carouselClicksViewsAnalytics &&
      carouselClicksViewsAnalytics[selectedComponent]
    ) {
      // Use original data points directly without interpolation
      const data = carouselClicksViewsAnalytics[selectedComponent];

      setChartOptions((prev: any) => ({
        ...prev,
        data: data,
        series: [
          {
            ...prev.series[0],
            // Increase smoothing for visual smoothness without adding data points
            smoothing: 0.3,
            interpolation: "monotoneX",
            tooltip: {
              enabled: true,
              renderer: (params: any) => {
                const date = new Date(params.datum.formatted_date);
                const formattedDate = date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                });
                return {
                  title: formattedDate,
                  content: `Clicks: ${Math.round(params.datum.clicks)}`,
                };
              },
            },
            marker: {
              enabled: true,
              size: 6,
              fill: "#4F46E5",
              stroke: "#ffffff",
              strokeWidth: 2,
            },
          },
        ],
      }));
    }
  }, [selectedComponent, carouselClicksViewsAnalytics]);

  return (
    <>
      {StayUpToDateComponents &&
        StayUpToDateComponents.showingComponents &&
        StayUpToDateComponents.showingComponents.length && (
          <div className="fullDiv">
            {loadingCarouselClicksAnalytics ||
            loadingCarouselClicksViewsAnalytics ? (
              <>
                <BannerSkeleton />
                <div className="skeleton-analytics-titles">
                  {Array(4).fill(<SkeletonStatBox />)}
                </div>
              </>
            ) : (
              <>
                {selectedComponent &&
                carouselClicksAnalytics[selectedComponent]?.length > 0 ? (
                  <>
                    {daysNumber !== 1 &&
                      carouselClicksViewsAnalytics[selectedComponent]?.length >
                        0 && (
                        <div className="w-full bg-white rounded-lg shadow-md p-6 border border-gray-100 smooth-chart-container">
                          <div
                            className="bg-white rounded-lg p-2"
                            style={{ height: "400px", position: "relative" }}
                          >
                            dfdfh
                            <AgChartsReact
                              options={{
                                ...chartOptions,
                                series: chartOptions.series.map(
                                  (series: any) => ({
                                    ...series,
                                    smoothing: 0.8,
                                    interpolation: "cardinal",
                                    strokeWidth: 3,
                                    marker: { enabled: false },
                                    lineDash: [0],
                                    strokeOpacity: 1,
                                  })
                                ),
                              }}
                            />
                          </div>
                        </div>
                      )}

                    <div>
                      <ChartClicksTable
                        loadingPostClicksAnalytics={
                          loadingCarouselClicksAnalytics
                        }
                        chartClicks={carouselClicksAnalytics[selectedComponent]}
                        defaultCurrentPage={defaultCurrentPage}
                        setDefaultCurrentPage={setDefaultCurrentPage}
                        brand_color={brand_color}
                        carouselViews={carouselViews}
                        loadingCarouselViews={loadingCarouselViews}
                        selectedComponent={selectedComponent}
                        elem={elem}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="empty-state-container"
                    style={{
                      textAlign: "center",
                      padding: "24px",
                      backgroundColor: "#f5f5f7",
                      borderRadius: "12px",
                      margin: "16px 0",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#111827",
                        marginBottom: "8px",
                      }}
                    >
                      No data available
                    </h5>
                    <p
                      style={{
                        color: "#6B7280",
                        fontSize: "14px",
                        maxWidth: "280px",
                        margin: "0 auto",
                      }}
                    >
                      We don't have any analytics data for{" "}
                      <b>{selectedComponent}</b> yet. Check back soon!
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
    </>
  );
};

export default ContentChartNew;
