import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "react-toastify/dist/ReactToastify.css";
import { FaLock } from "react-icons/fa";
import { useEffect, useState, TouchEvent } from "react";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { Modal } from "antd";
import "./RouteList.css";
// Import icons from react-icons (using Heroicons Outline for a more minimalist look)
import {
  HiOutlineHome,
  HiOutlineChartBar,
  HiOutlineCog,
  HiOutlineCollection,
  HiOutlineViewList,
  HiOutlineLogout,
  HiUserGroup,
  HiOutlineUserCircle,
  HiOutlineShoppingBag,
  HiOutlineCube,
  HiOutlineDatabase,
  HiOutlineSearch,
} from "react-icons/hi";
import { MdDashboardCustomize, MdOutlineVideoLibrary } from "react-icons/md";
import { Avatar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { useSidebar } from "../../../context/SidebarContext";
import { RxComponent2 } from "react-icons/rx";

const embedSearchIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="18" height="18">
  <rect x="1" y="1" width="18" height="18" rx="2" ry="2" stroke="#666666" stroke-width="1.5" fill="none"/>
  
  <line x1="1" y1="5" x2="19" y2="5" stroke="#666666" stroke-width="1.5"/>
  
  <circle cx="3.5" cy="3" r="0.8" fill="#666666"/>
  <circle cx="6.5" cy="3" r="0.8" fill="#666666"/>
  <circle cx="9.5" cy="3" r="0.8" fill="#666666"/>
  
  <path d="M6,10 L3.5,12.5 L6,15" stroke="#666666" stroke-width="1.5" fill="none"/>
  <path d="M14,10 L16.5,12.5 L14,15" stroke="#666666" stroke-width="1.5" fill="none"/>
  
  <line x1="10" y1="9" x2="10" y2="16" stroke="#666666" stroke-width="1.5" transform="rotate(15, 10, 12.5)"/>
</svg>`

const RouteList = (props: any) => {
  const { option, tab, pictureUrl, username, goToFollower, goToDashboard, userData } =
    props;
  const location = useLocation();
  const { closeSidebar } = useSidebar();
  const isFollowerView = location.pathname.includes("follower");
  const [selectedIndex, setSelectedIndex] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isTabsLocked, setIsTabsLocked] = useState<boolean>();
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const listItems = StaticVariables().ROUTELIST_ITEMS.filter((item) => {
    if (item === "Embed Page") {
      return props.userData?.is_embed_search;
    }
    return true;
  });
  const mobileOnlyItems = [
    isFollowerView ? "Dashboard" : "Live Page Preview",
    "Logout",
  ];
  const allItems = isMobile ? [...listItems, ...mobileOnlyItems] : listItems;

  // Define sections for grouping menu items
  const sections = [
    {
      title: "Section 1",
      items: ["Landing Page", ...(userData?.is_embed_search ? ["Embed Page"] : []), "Analytics"],
    },
    {
      title: "Section 2",
      items: ["Connected Platforms", "Controls"],
    },
    {
      title: "Section 3",
      items: ["Content Bank", "Product Bank"],
    },
    {
      title: "Section 4",
      items: ["Account"],
    },
  ];

  // Mobile-only items that should appear at the bottom
  const mobileItems = isMobile ? mobileOnlyItems : [];

  // Map icons to list items - using outline versions for a more minimalist look
  const getIcon = (text: string) => {
    switch (text) {
      case "Account":
        return <HiOutlineUserCircle className="route-icon" />;
      case "Analytics":
        return <HiOutlineChartBar className="route-icon" />;
      case "Product Bank":
        return <HiOutlineShoppingBag className="route-icon" />;
      case "Content Bank":
        return <MdOutlineVideoLibrary className="route-icon" />;
      case "Controls":
        return <HiOutlineCog className="route-icon" />;
      case "Live Page Preview":
        return <IoEyeOutline className="route-icon" />;
      case "Logout":
        return <HiOutlineLogout className="route-icon" />;
      case "Connected Platforms":
        return <HiOutlineCollection className="route-icon" />;
      case "Dashboard":
        return <MdDashboardCustomize className="route-icon" />;
      case "Landing Page":
        return <HiOutlineHome className="route-icon" />;
      case "Embed Page":
        return <div dangerouslySetInnerHTML={{ __html: embedSearchIcon }} className="route-icon" />;
      default:
        return <HiOutlineHome className="route-icon" />;
    }
  };

  useEffect(() => {
    // Find the index in the flattened array of all items
    const flattenedItems = [
      ...sections.flatMap((section) => section.items),
      ...mobileItems,
    ];
    const index = flattenedItems.indexOf(option);
    setSelectedIndex(index !== -1 ? index : 0);
  }, [option]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const isEmailTabsLocked =
        localStorage.getItem("email_verify") === "false";
      setIsTabsLocked(isEmailTabsLocked);
    };

    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (props.tab) {
      setSelectedIndex(props.tab);
    }
  }, [props.tab]);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const getUserInitials = () => {
    if (!username) return "U";
    return username.charAt(0).toUpperCase();
  };

  const handleListItemClick = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | TouchEvent<HTMLDivElement>,
    item: string
  ) => {
    if (!isScrolling) {
      event.preventDefault();

      // Find the index of the clicked item
      const flattenedItems = [
        ...sections.flatMap((section) => section.items),
        ...mobileItems,
      ];
      const index = flattenedItems.indexOf(item);
      setSelectedIndex(index);

      if (props.handleDrawerClose) {
        props.handleDrawerClose();
      }
    }
  };

  const handleClick = (text: string) => {
    if (!isScrolling) {
      if (!isFollowerView && props.handleDrawerClose) {
        props.handleDrawerClose();
      }
      setTimeout(() => {
        if (text === "Logout") {
          navigate("/login", { replace: true });
        } else if (text === "Live Page Preview") {
          goToFollower();
        } else if (text === "Dashboard") {
          goToDashboard();
        } else {
          props.handleClick(text);
        }
      }, 150);
    }
  };
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    if (!props.isEmail) {
      setModalTitle("Enter Email!");
      showModal();
    } else if (!props.isEmailVerified) {
      setModalTitle("Verify Email!");
      showModal();
    } else if (!props.isName) {
      setModalTitle("Enter Name!");
      showModal();
    }
  };

  const handleTouchStart = () => {
    setIsScrolling(false);
  };

  const handleTouchMove = () => {
    setIsScrolling(true);
  };

  const handleTouchEnd = () => {
    // Reset scrolling state after a short delay
    setTimeout(() => {
      setIsScrolling(false);
    }, 100);
  };

  // Check if an item is currently selected
  const isItemSelected = (item: string) => {
    const flattenedItems = [
      ...sections.flatMap((section) => section.items),
      ...mobileItems,
    ];
    return flattenedItems.indexOf(item) === selectedIndex;
  };

  const drawer = (
    <div
      className="drawer-menu"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {props.isEmail &&
        props.isName &&
        props.isEmailVerified &&
        (props.email_verify || isTabsLocked === false) ? (
        <div className="route-sections">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="route-section">
              <List className="route-list">
                {section.items.map((item, itemIndex) => (
                  <ListItem
                    key={`${sectionIndex}-${itemIndex}`}
                    disablePadding
                    className="route-list-item"
                  >
                    <ListItemButton
                      onClick={(e: any) => {
                        handleClick(item);
                        handleListItemClick(e, item);
                      }}
                      id={`listButton-${sectionIndex}-${itemIndex}`}
                      className={`${isItemSelected(item) ? "selected-menu" : ""
                        }`}
                      disableRipple
                      disableTouchRipple
                    >
                      <ListItemIcon className="route-list-icon">
                        {getIcon(item)}
                      </ListItemIcon>
                      <ListItemText
                        primary={item}
                        className="route-list-text"
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              {sectionIndex < sections.length - 1 && (
                <div className="section-divider"></div>
              )}
            </div>
          ))}

          {isMobile && mobileItems.length > 0 && (
            <>
              <div className="section-divider"></div>
              <div className="route-section">
                <List className="route-list">
                  {mobileItems.map((item, itemIndex) => (
                    <ListItem
                      key={`mobile-${itemIndex}`}
                      disablePadding
                      className="route-list-item"
                    >
                      <ListItemButton
                        onClick={(e: any) => {
                          handleClick(item);
                          handleListItemClick(e, item);
                        }}
                        id={`listButton-mobile-${itemIndex}`}
                        className={`${isItemSelected(item) ? "selected-menu" : ""
                          }`}
                        disableRipple
                        disableTouchRipple
                      >
                        <ListItemIcon className="route-list-icon">
                          {getIcon(item)}
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          className="route-list-text"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </div>
            </>
          )}
        </div>
      ) : props.isEmail === undefined ||
        props.isName === undefined ||
        props.isEmailVerified === undefined ? null : (
        <List className="route-list">
          {allItems.map((text, index) => (
            <ListItem key={index} disablePadding className="route-list-item">
              <ListItemButton
                id="listButton"
                onClick={(e: any) => {
                  if (!isScrolling) {
                    handleSubmit();
                    handleListItemClick(e, text);
                  }
                }}
                selected={index === 0}
                className={index === 0 ? "selected-menu" : "locked-menu"}
              >
                <ListItemIcon className="route-list-icon">
                  {getIcon(text)}
                </ListItemIcon>
                <ListItemText primary={text} className="route-list-text" />
                {index !== 0 && <FaLock className="lock-icon" />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        className="account-modal"
      ></Modal>
    </div>
  );

  return <div className="drawer">{drawer}</div>;
};

export default RouteList;
