import React, { useEffect, useState } from "react";
import "./BannerData.css";
import { Button } from "antd";
import { BannerSkeleton } from "../../BannerSkeleton";
import { BsLink45Deg } from "react-icons/bs";
const BannerData = (props: any) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const { title, component, handleRedirect, brand_color, isHandleNavigate } =
    props;
  return (
    <>
      {loading ? (
        <BannerSkeleton />
      ) : (
        <>
          <div className="mainDiv">
            <p className="component-name">
              {component.name} 
            </p>

            <a
              href={
                component?.details?.split(",")[1]?.startsWith("http")
                  ? component.details.split(",")[1]
                  : `http://${component.details.split(",")[1]}`
              }
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                handleRedirect(
                  component.id,
                  component?.details?.split(",")[0],
                  title,
                  title
                );
                const url = component?.details?.split(",")[1];
                if (url && url !== "") {
                  const validUrl = url.startsWith("http")
                    ? url
                    : `http://${url}`;
                  window.open(validUrl, "_blank");
                }
              }}
              className="text-decoration-none"
            >
              {" "}
              <Button className="ant-btn css-dev-only-do-not-override-1nimcbs ant-btn-default highlight-shop-button">
                {" "}
                {component?.cta_placeholder}
              </Button>
            </a>
          </div>
          <div className="outter-section-banner">
            <a
              href={
                component?.details?.split(",")[1]?.startsWith("http")
                  ? component.details.split(",")[1]
                  : `http://${component.details.split(",")[1]}`
              }
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                handleRedirect(
                  component.id,
                  component?.details?.split(",")[0],
                  title,
                  title,
                );
                const url = component?.details?.split(",")[1];
                if (url && url !== "") {
                  const validUrl = url.startsWith("http")
                    ? url
                    : `http://${url}`;
                  window.open(validUrl, "_blank");
                }
              }}
            >
              <img
                src={
                  component?.details?.split(",")[0] || "/assets/image-bg.jpeg"
                }
                alt={title}
                className="banner-image"
              />
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default BannerData;
