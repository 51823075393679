import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import { StaticVariables } from "../../../Utils/StaticVariables";
import "./Controls.css";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import GeneralControls from "./LiveViewControls/Components/GeneralControls";
import CategoriesControls from "./LiveViewControls/Components/CategoriesControls";
import SearchbarControls from "./LiveViewControls/Components/SearchbarControls";
import { useAuth } from "../../../hooks/useAuth";
import { useLoadControls } from "../../../Utils/customHooks/useLoadControls";
import SearchResultsControls from "./LiveViewControls/Components/SearchResultsControls";

const ControlsTab = (props: any) => {
  const { subscription_type, refetch } = props;
  const authObj = useAuth();
  const page = "follower";

  const user_id = authObj.user ? authObj.user.id : "";
  const { username } = useParams();
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  const {
    isLoading: controlsLoading,
    data: controls,
    refetch: refetchControls,
  }: any = useLoadControls(user_id || "", page);
  const items = [
    {
      key: "General",
      label: <span className="modal-title" style={{ padding: 0, margin: 0 }}>General</span>,
      children: (
        <GeneralControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
          username={username}
          defaultColor={userData?.brand_color}
          defaultTextColor={userData?.brand_text_color}
          refetch={refetch}
          controlsLoading={controlsLoading}
          controls={controls}
          refetchControls={refetchControls}
        />
      ),
    },
    // {
    //   key: "Categories",
    //   label: "Categories",
    //   children: (
    //     <CategoriesControls
    //       showSocials={userData?.showSocials}
    //       myLinks={userData?.myLinks}
    //       subscription_type={subscription_type}
    //       user_id={user_id}
    //       username={username}
    //       controlsLoading={controlsLoading}
    //       controls={controls}
    //       refetchControls={refetchControls}
    //     />
    //   ),
    // },
    {
      key: "Searchresults",
      label: <span className="modal-title" style={{ padding: 0, margin: 0 }}>Search Results</span>,
      children: (
        <SearchResultsControls
          username={username}
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
          controlsLoading={controlsLoading}
          controls={controls}
          refetchControls={refetchControls}
          userData={userData}
          userLoading={userLoading}
          refetch={refetch}
        />
      ),
    },
    {
      key: "Searchbar",
      label: <span className="modal-title" style={{ padding: 0, margin: 0 }}>Search Bar</span>,
      children: (
        <SearchbarControls
          username={username}
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}
          controlsLoading={controlsLoading}
          controls={controls}
          refetchControls={refetchControls}
          userData={userData}
          userLoading={userLoading}
          refetch={refetch}
        />
      ),
    },
  ];

  return (
    <div className="dashboard-container">
      <div className="details">
        {
          <h1 className="dashboard-mobile-hide">
            {StaticVariables().CONTROLS_TITLE}
          </h1>
        }
        <div className="row" id="analytics-row">
          <Tabs 
            defaultActiveKey="General" 
            items={items} 
            type="card" 
            centered={false}
            tabPosition="top"
            className="controls-tabs"
            tabBarStyle={{ paddingLeft: 0, marginLeft: 0 }}
            style={{ paddingLeft: 0, marginLeft: 0 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ControlsTab;