import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { updateBrandColor } from "../../Utils/SupabaseServices/ControlsDatabase";
import ColorPicker from "react-pick-color";

export const ColorModal = (props: any) => {
  const {
    username,
    user_id,
    defaultColor,
    handleColor,
    isColorModalOpen,
    setIsColorModalOpen,
    defaultTextColor,
    ModalTitle,
    fromScreen,
    refetch,
    buttonClassName,
  } = props;

  const [color, setColor] = useState(defaultColor);
  const [textColor, setTextColor] = useState(defaultTextColor);
  const filterButton = [
    {
      key: "#fff",
      label: "White",
    },
    {
      key: "#000",
      label: "Black",
    },
  ];
  useEffect(() => {
    setColor(defaultColor);
    setTextColor(defaultTextColor);
  }, [defaultColor, defaultTextColor]);
  const handleOk = () => {
    handleColor(color, textColor);
    updateBrandColor(username, user_id, color, textColor);
    refetch();
    setIsColorModalOpen(false);
  };
  const handleCancel = () => {
    setIsColorModalOpen(false);
    setColor(defaultColor);
    setTextColor(defaultTextColor);
    // props?.setLocalDefaultColor(defaultColor);
    // props?.setLocalDefaultTextColor(defaultTextColor);
  };

  function getBrightness(hex: string) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Convert hex to RGB components
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate brightness using a perceived brightness formula
    return 0.299 * r + 0.587 * g + 0.114 * b;
  }

  function handleTextColor(element: string) {
    const hexMatch = element.match(/#([0-9a-fA-F]{3,6})/);
    if (hexMatch) {
      const brightness = getBrightness(hexMatch[1]);
      const textColor = brightness > 186 ? "#000" : "#fff";
      setTextColor(textColor);
    }
  }

  const handleColorFilter = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productFilter: string
  ) => {
    e.preventDefault();
    setTextColor(productFilter);
  };
  
  // Configure button props for the standard design
  const okButtonProps = {
    className: buttonClassName || 'controlsButton'
  };
  
  const cancelButtonProps = {
    className: buttonClassName ? buttonClassName.replace('Button', 'CancelButton') : 'controlsCancelButton'
  };
  
  return (
    <>
      <Modal
        title={ModalTitle}
        open={isColorModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        centered
        className="color-picker-modal"
        width={420}
      >
        <div style={{ padding: "16px 0" }}>
          <div style={{ marginBottom: "20px" }}>
            <ColorPicker
              color={color}
              onChange={(color: any) => {
                setColor(color.hex);
                handleTextColor(color.hex);
              }}
            />
          </div>
          
          {fromScreen === "socialLinksScreen" && (
            <div style={{ marginTop: "20px" }}>
              <h6 style={{ fontSize: "0.9rem", marginBottom: "12px", fontWeight: 500 }}>Icon Color</h6>
              <div className="mb-3">
                {filterButton.map((btn) => (
                  <Button
                    key={btn.key}
                    onClick={(e) => handleColorFilter(e, btn.key)}
                    className={`${
                      textColor === btn.key ? 
                      (buttonClassName || "controlsButton") : 
                      (buttonClassName ? buttonClassName.replace('Button', 'CancelButton') : "controlsCancelButton")
                    } mr-2`}
                  >
                    {btn.label}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
