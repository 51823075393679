import { Button } from "antd";
import React from "react";

const ProductShopButton = (props: any) => {
  const { isLinks, isIndividual, type, post, isCarousel } = props;

  // Don't render anything if neither shop_link nor external_link exists
  if (!post?.shop_link && !post?.external_link) {
    return null;
  }

  const isProductName =
    post?.product_name || post.content_title || post.shop_title;
  const isYoutubeWithImage =
    post.platform === "youtube" &&
    post.product_image &&
    post.product_image !== "";
  const isMiscOrYoutubeWithImage =
    post.platform === "MISC-content" || isYoutubeWithImage;

  const getButtonStyle = () => {
    if (isMiscOrYoutubeWithImage) {
      return { marginTop: isIndividual ? "8px" : "4px" };
    }
    return isProductName ? {} : { marginTop: isIndividual ? "8px" : "4px" };
  };

  const buttonText = isLinks ? (post?.shop_link ? "Shop" : "View") : "View";
  const buttonClassName = `template-shop-btn-links${
    isIndividual ? "-individual" : isCarousel ? "-carousel" : ""
  }`;

  return (
    <Button className={buttonClassName} style={getButtonStyle()}>
      {buttonText}
    </Button>
  );
};

export default ProductShopButton;
