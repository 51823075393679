import React, { useState } from "react";
import { Button, List, Modal, TabsProps } from "antd";
import { GrNext } from "react-icons/gr";
import { useLoadStayUpToDateComponents } from "../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import '../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { IoAddCircle } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import { StaticVariables } from "../../../Utils/StaticVariables";
import StayUpToDateComponentsWrapper from "./StayUpToDateComponents/StayUpToDateComponentsWrapper";
import ShopLinksComponent from "./StayUpToDateControlsComponents/ShopLinksComponents/ShopLinksComponent";
import LinksComponent from "./StayUpToDateControlsComponents/LinksComponent/LinksComponent";
import HighlightComponent from "./StayUpToDateControlsComponents/HighlightComponent/HighlightComponent";
import ContentComponent from "./StayUpToDateControlsComponents/ContentComponent/ContentComponent";
import CustomBannerComponent from "./StayUpToDateControlsComponents/CustomBannerComponent/CustomBannerComponent";
import ArchivedComponentsWrapper from "./StayUpToDateComponents/ArchivedComponentsWrapper";
import { FormComponent } from "./StayUpToDateControlsComponents/FormComponent/FormComponent";
import { NewsletterTabComponent } from "./StayUpToDateControlsComponents/NewsletterComponent/NewsletterTabComponent";
import CreateNewProductPost from "./StayUpToDateControlsComponents/CustomCarouselComponent/CreateNewProductPost";
import CreateNewContentPost from "./StayUpToDateControlsComponents/ContentComponent/ContentPost/CreateNewContentPost";
import AddLinksComponent from "./LandingPageComponents/LinksComponent/AddLinksComponent";
import LandingComponentsWrapper from "../LandingPageWrapper/LandingComponentsWrapper";
import CreateNewForm from "./StayUpToDateControlsComponents/CustomCarouselComponent/CreateNewForm";
import EmbedSearch from "../EmbedSearch/EmbedSearch";

const StayUpToDateControls = (props: any) => {
  const {
    username,
    user_id,
    brand_color,
    isFlowdeskOn,
    flowdeskId,
    isFilloutOn,
    filloutId,
    isFlowdeskPopupOn,
    flowdeskPopupId,
    isLandingPage,
    // refetchUser,
    refetchUser, isLoading, creatorData, refetchUserDetail,
    from
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);
  const [isShoplinksModalOpen, setIsShoplinksModalOpen] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
  const [isAddLinksModalOpen, setIsAddLinksModalOpen] = useState(false);

  const [isHighlightModalOpen, setIsHighlightModalOpen] = useState(false);
  const [isCustomBannerModalOpen, setIsCustomBannerModalOpen] = useState(false);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState(false);
  const [isCreateContentModalOpen, setIsCreateContentModalOpen] =
    useState(false);
  const [isCreateProductModalOpen, setIsCreateProductModalOpen] =
    useState(false);
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
    refetch,
  }: any = useLoadStayUpToDateComponents(username, user_id, isFilloutOn, from);


  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const refatchStayUpToDate = () => {
    refetch();
  };
  const data = [
    // {
    //   title: "My Links",
    //   component: handleLinks,
    //   key: "my-links",
    //   disabled: StayUpToDateComponents?.data.some(
    //     (obj: any) => obj.display_name === "My Links"
    //   ),
    // },
    {
      title: "Link Section",
      component: handleAddLinks,
      key: "add-links",
      // disabled: StayUpToDateComponents?.data.some(
      //   (obj: any) => obj.display_name === "My Links"
      // ),
    },
    ...(isFlowdeskOn || isFlowdeskPopupOn
      ? [
          {
            // title: "Collect Contacts",
            title: "Flodesk",
            key: "collect-contacts",
            component: handleNewsletter,
            task: true,
            disabled: ["Inline Newsletter", "Popup Newsletter"].every(
              (displayName) =>
                StayUpToDateComponents?.data.some(
                  (obj: any) => obj.display_name === displayName
                )
            ),
          },
        ]
      : []),
    ...(isFilloutOn
      ? [
          {
            title: "Fillout",
            key: "show-form",
            component: handleForm,
            disabled: StayUpToDateComponents?.data.some(
              (obj: any) => obj.display_name === "Form"
            ),
          },
        ]
      : []),

    {
      title: "Highlight",
      key: "highlight",
      component: handleHighlights,
    },
    {
      title: "Banner",
      key: "banner",
      component: handleCustomBanners,
    },

    {
      title: "Shoppable Carousels",
      key: "shoppable-carousels",
      task: true,
      component: handleShoplinks,
    },
    {
      title: "Content Carousels",
      key: "content-carousels",
      task: true,
      component: handleContent,
    },
    { divider: true },

    {
      title: "Embed Custom Code",
      key: "add-form",
      task: true,
      component: handleCreateForm,
    },
  ];

  function handleLinks() {
    setIsLinksModalOpen(true);
    setIsShoplinksModalOpen(false);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsContentModalOpen(false);
  }
  function handleShoplinks() {
    setIsShoplinksModalOpen(true);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsContentModalOpen(false);
    setIsLinksModalOpen(false);
  }
  function handleContent() {
    setIsContentModalOpen(true);
    setIsCarouselModalOpen(false);
    setIsModalOpen(false);
    setIsShoplinksModalOpen(false);
    setIsLinksModalOpen(false);
  }
  function handleHighlights() {
    setIsHighlightModalOpen(true);
    setIsModalOpen(false);
  }
  function handleCustomBanners() {
    setIsCustomBannerModalOpen(true);
    setIsModalOpen(false);
  }

  function handleCreateContent() {
    setIsCreateContentModalOpen(true);
    setIsModalOpen(false);
  }

  function handleCreateProduct() {
    setIsCreateProductModalOpen(true);
    setIsModalOpen(false);
  }
  function handleCreateForm() {
    setIsCreateFormModalOpen(true);
    setIsModalOpen(false);
  }
  function handleNewsletter() {
    setIsNewsletterModalOpen(true);
    setIsModalOpen(false);
  }
  function handleForm() {
    setIsFormModalOpen(true);
    setIsModalOpen(false);
  }
  function handleAddLinks() {
    setIsAddLinksModalOpen(true);
    setIsModalOpen(false);
  }
  function handleTask(title: any) {
    if (title === "Carousels") {
      setIsCarouselModalOpen(true);
      setIsModalOpen(false);
      setIsShoplinksModalOpen(false);
      setIsContentModalOpen(false);
      setIsLinksModalOpen(false);
    } else if (title === "Shop Links Carousel") {
      setIsShoplinksModalOpen(true);
      setIsCarouselModalOpen(false);
      setIsModalOpen(false);
      setIsContentModalOpen(false);
      setIsLinksModalOpen(false);
    } else if (title === "Content Carousel") {
      setIsContentModalOpen(true);
      setIsCarouselModalOpen(false);
      setIsModalOpen(false);
      setIsShoplinksModalOpen(false);
      setIsLinksModalOpen(false);
    }
  }
  return (
    <>
      <div className="details">
        <div className="d-flex">
          {!isLandingPage && from !== "embed-search" && (
            <h1 className="dashboard-mobile-hide w-100">
              {StaticVariables().MANAGE_STAYUPTODATE_TITLE}
            </h1>
          )}
        </div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          data-testid="add-to-your-page"
          width={600}
          centered
          className="add-to-page-modal"
          closeIcon={<span className="modal-close-icon">×</span>}
        >
          <h2 className="modal-title">Add a component</h2>
          <p className="modal-subtitle">Choose components to enhance your page</p>
          
          <div className="components-container">
            <h2 className="components-section-header" style={{ marginBottom: '0' }}>Carousels</h2>
            <div className="components-section">
              <div className="components-grid" style={{ gridTemplateColumns: '1fr' }}>
                {data
                  .filter(item => 
                    item.key && ['content-carousels', 'shoppable-carousels'].includes(item.key) && !item.divider
                  )
                  .map((item, index) => (
                    <div 
                      key={index} 
                      className={`component-card ${item.disabled ? 'disabled' : ''}`}
                      onClick={!item.disabled ? item.component : undefined}
                    >
                      <div className="component-card-inner">
                        <h4>{item.title}</h4>
                        {item.task ? (
                          <button 
                            className="task-btn" 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTask(item.title);
                            }}
                          >
                            <span className="component-icon task-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <FiArrowRight style={{ margin: 'auto' }} />
                            </span>
                          </button>
                        ) : (
                          <span className="component-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <AiOutlinePlus size={16} style={{ margin: 'auto' }} />
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <h2 className="components-section-header" style={{ marginBottom: '0', marginTop: '20px' }}>Feature Content & Links</h2>
            <div className="components-section">
              <div className="components-grid" style={{ gridTemplateColumns: '1fr' }}>
                {data
                  .filter(item => 
                    item.key && ['add-links', 'highlight', 'banner'].includes(item.key) && !item.divider
                  )
                  .map((item, index) => (
                    <div 
                      key={index} 
                      className={`component-card ${item.disabled ? 'disabled' : ''}`}
                      onClick={!item.disabled ? item.component : undefined}
                    >
                      <div className="component-card-inner">
                        <h4>{item.title}</h4>
                        {item.task ? (
                          <button 
                            className="task-btn" 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTask(item.title);
                            }}
                          >
                            <span className="component-icon task-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <FiArrowRight style={{ margin: 'auto' }} />
                            </span>
                          </button>
                        ) : (
                          <span className="component-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <AiOutlinePlus size={16} style={{ margin: 'auto' }} />
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <h2 className="components-section-header" style={{ marginBottom: '0', marginTop: '20px' }}>Embed</h2>
            <div className="components-section">
              <div className="components-grid" style={{ gridTemplateColumns: '1fr' }}>
                {data
                  .filter(item => 
                    item.key && ['collect-contacts', 'show-form', 'add-form'].includes(item.key) && !item.divider
                  )
                  .map((item, index) => (
                    <div 
                      key={index} 
                      className={`component-card ${item.disabled ? 'disabled' : ''}`}
                      onClick={!item.disabled ? item.component : undefined}
                    >
                      <div className="component-card-inner">
                        <h4>{item.title}</h4>
                        {item.task ? (
                          <button 
                            className="task-btn" 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTask(item.title);
                            }}
                          >
                            <span className="component-icon task-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <FiArrowRight style={{ margin: 'auto' }} />
                            </span>
                          </button>
                        ) : (
                          <span className="component-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <AiOutlinePlus size={16} style={{ margin: 'auto' }} />
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Modal>

        <ShopLinksComponent
          isShoplinksModalOpen={isShoplinksModalOpen}
          setIsShoplinksModalOpen={setIsShoplinksModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />
        <LinksComponent
          isLinksModalOpen={isLinksModalOpen}
          setIsLinksModalOpen={setIsLinksModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />
        <AddLinksComponent
          isAddLinksModalOpen={isAddLinksModalOpen}
          setIsAddLinksModalOpen={setIsAddLinksModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />

        <HighlightComponent
          isHighlightModalOpen={isHighlightModalOpen}
          setIsHighlightModalOpen={setIsHighlightModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />
        <CreateNewContentPost
          user_id={user_id}
          username={username}
          isCreateContentModalOpen={isCreateContentModalOpen}
          setIsCreateContentModalOpen={setIsCreateContentModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetchData={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />

        <CreateNewProductPost
          isCreateProductModalOpen={isCreateProductModalOpen}
          setIsCreateProductModalOpen={setIsCreateProductModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetchData={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />
        <CreateNewForm
          isCreateFormModalOpen={isCreateFormModalOpen}
          setIsCreateFormModalOpen={setIsCreateFormModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />
        <ContentComponent
          isContentModalOpen={isContentModalOpen}
          setIsContentModalOpen={setIsContentModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />

        <CustomBannerComponent
          isCustomBannerModalOpen={isCustomBannerModalOpen}
          setIsCustomBannerModalOpen={setIsCustomBannerModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          from={from}
        />

        <NewsletterTabComponent
          isNewsletterModalOpen={isNewsletterModalOpen}
          setIsNewsletterModalOpen={setIsNewsletterModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          flowdeskId={flowdeskId}
          flowdeskPopupId={flowdeskPopupId}
          isFlowdeskOn={isFlowdeskOn}
          isFlowdeskPopupOn={isFlowdeskPopupOn}
          from={from}
        />
        <FormComponent
          isFormModalOpen={isFormModalOpen}
          setIsFormModalOpen={setIsFormModalOpen}
          setIsModalOpen={setIsModalOpen}
          user_id={user_id}
          username={username}
          refetch={refetch}
          StayUpToDateComponents={StayUpToDateComponents}
          filloutId={filloutId}
          isFilloutOn={isFilloutOn}
          from={from}
        />

        {!isLandingPage && from !== "embed-search" && (
          <IoAddCircle className="button-icon" onClick={showModal} />
        )}



        {isLandingPage || from != "embed-search" ? (
          <LandingComponentsWrapper
            username={username}
            user_id={user_id}
            StayUpToDateComponents={StayUpToDateComponents?.showingComponents}
            refetch={refetch}
            StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
            brand_color={brand_color}
            isFilloutOn={isFilloutOn}
            refatchStayUpToDate={refatchStayUpToDate}
            showModal={showModal}
            refetchUser={refetchUser}
            isLoading={isLoading}
            data={creatorData}
            refetchUserDetail={refetchUserDetail}
            from={from}
          />
        ) :
          from === "embed-search" ? (
            <EmbedSearch
              username={username}
              user_id={user_id}
              isFilloutOn={isFilloutOn}
              isLoading={isLoading}
              brand_color={brand_color}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              showModal={showModal}
              from={from}
              StayUpToDateComponents={StayUpToDateComponents?.showingComponents}
              StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
              refetch={refetch}
              refetchUserDetail={refetchUserDetail}
              refetchUser={refetchUser}
              data={creatorData}
            />
          ) : (
            <StayUpToDateComponentsWrapper
              username={username}
              user_id={user_id}
              StayUpToDateComponents={StayUpToDateComponents?.showingComponents}
              refetch={refetch}
              StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
              brand_color={brand_color}
              isFilloutOn={isFilloutOn}
              refatchStayUpToDate={refatchStayUpToDate}
            // setStatus={setStatus}
            />
          )}
        <>
          {StayUpToDateComponents?.archiveComponents && StayUpToDateComponents.archiveComponents?.length > 0 &&
            <>
              <div className="stay-up-to-date-wrapper">
                <div onClick={toggleCollapse} className="archive-toggle-section">
                  <div>Archived Components</div>
                  <div className="archive-toggle-section-button">
                    {isOpen ? "Hide" : "Show"}
                  </div>
                </div>
              </div>
            </>}

          {isOpen && (
            <ArchivedComponentsWrapper
              username={username}
              user_id={user_id}
              StayUpToDateComponents={StayUpToDateComponents?.archiveComponents}
              refetch={refetch}
              StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
              brand_color={brand_color}
              refatchStayUpToDate={refatchStayUpToDate}
            // setStatus={setStatus}
            />
          )}
        </>
      </div>
    </>
  );
};

export default StayUpToDateControls;
