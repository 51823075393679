import React, { useEffect, useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { Button } from "antd";
import Card from "react-bootstrap/esm/Card";
import PuffLoader from "react-spinners/PuffLoader";
import "../../../../../styles/StayUptoDateCSS/SubComponents.css";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { useLocation } from "react-router-dom";
import { useLoadTypeContentData } from "../../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
import { redirectToUrl } from "../../../../../Utils/StayUpToDate/RenderComponent";
const ContentWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    // handleShop,
    platform,
    type,
    isPodcast,
    isTiktok,
    isYoutube,
    isReels,
    brand_color,
    removedPostIds,
    component,
  } = props;
  const { isLoading: ContentDataLoading, data: ContentData }: any =
    useLoadTypeContentData(
      username,
      user_id,
      platform,
      type,
      removedPostIds,
      component?.content_order
    );

  const location = useLocation();
  const domLoaded = useDomLoaded();

  function handleRedirect(postId: string, media_url: string, platform: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url, platform: platform },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  const getWrapperClassName = () => {
    const baseClass = isPodcast
      ? "creator-feed-image-wrapper-pod-subcomponents"
      : "creator-feed-image-wrapper-links-subcomponents";
    const classes = [baseClass];

    if (component?.content_shape == "sq") classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");
    // else if (!data?.media_url) classes.push("not-available");

    return classes.join(" ");
  };

  const getImageClassName = () => {
    const baseClass = isPodcast
      ? "creator-feed-image-wrapper-podcast-subcomponents"
      : "creator-feed-card-links-image-subcomponents";
    const classes = [baseClass];

    if (component?.content_shape == "sq") classes.push("square-image");
    else if (isYoutube) classes.push("youtube-feed-height");

    return classes.join(" ");
  };

  const ImagePostTagLocal = React.memo((props: any) => {
    const { data } = props;
    const { thumbnail, media_url, shop_link, post_id, caption, permalink } =
      data;
    // const redirectUrl = !isLinks
    //   ? data?.permalink || data?.shop_link || data?.table_url
    //   : data?.shop_link || data?.table_url || data?.permalink;
    const redirectUrl: any = redirectToUrl(data, isLinks);

    return (
      <a
        href={redirectUrl}
        target="_blank"
        rel="noopener noreferrer" // It's a good practice to add this for security reasons
      >
        <>
          {!media_url ? (
            <div className="creator-feed-card-links-subcomponents not-available">
              <BsHourglassSplit className="hourglassSplit" />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <>
              {isPodcast ? (
                <div
                  className={getWrapperClassName()}
                  style={{
                    border: brand_color && `1px solid ${brand_color}`,
                    backgroundColor: brand_color && brand_color,
                  }}
                >
                  <div className={getImageClassName()}>
                    <Card.Img
                      variant="top"
                      src={media_url.includes("video") ? thumbnail : media_url}
                      className="creator-feed-card-podcast-image"
                      loading="eager"
                      style={{
                        backgroundColor: brand_color,
                        position: "relative",
                      }}
                    />
                    <h4 className="caption">{caption}</h4>{" "}
                  </div>
                </div>
              ) : (
                <div className={getWrapperClassName()}>
                  <Card.Img
                    variant="top"
                    src={media_url.includes("video") ? thumbnail : media_url}
                    className={getImageClassName()}
                    loading="eager"
                    style={{ backgroundColor: brand_color }}
                  />
                </div>
              )}
            </>
          )}
        </>
      </a>
    );
  });
  return (
    <>
      <div>
        {ContentDataLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {ContentData && ContentData.length > 0 ? (
              <div className="links-grid-container-subcomponents-pod">
                {ContentData &&
                  ContentData.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {isPodcast ? (
                        <ImagePostTagLocal key={link.id} data={link} />
                      ) : (
                        <>
                          {" "}
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={handleRedirect}
                                  brand_color={brand_color}
                                  isLinks={link?.shop_link ? true : false}
                                  isDashboard={true}
                                  contentShape={
                                    component?.content_shape == "sq"
                                      ? true
                                      : false
                                  }
                                  contentSound={component?.content_sound}
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleRedirect}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={link?.shop_link ? true : false}
                                      isDashboard={true}
                                      contentShape={
                                        component?.content_shape == "sq"
                                          ? true
                                          : false
                                      }
                                      contentSound={component?.content_sound}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={
                                            link?.shop_link ? true : false
                                          }
                                          isDashboard={true}
                                          thumbnail={link.thumbnail}
                                          type={type}
                                          contentShape={
                                            component?.content_shape == "sq"
                                              ? true
                                              : false
                                          }
                                          contentSound={
                                            component?.content_sound
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleRedirect}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={
                                            link?.shop_link ? true : false
                                          }
                                          isDashboard={true}
                                          contentShape={
                                            component?.content_shape == "sq"
                                              ? true
                                              : false
                                          }
                                          contentSound={
                                            component?.content_sound
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={handleRedirect}
                                brand_color={brand_color}
                                isLinks={link?.shop_link ? true : false}
                                isDashboard={true}
                                contentShape={
                                  component?.content_shape == "sq"
                                    ? true
                                    : false
                                }
                                contentSound={component?.content_sound}
                              />
                            </>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentWrapper;
