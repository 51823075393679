import React from "react";
import SearchInput from "../SearchInput/SearchInput";

const CarouselSearchbar = (props: any) => {
  const {
    isShopLinks,
    query,
    setQuery,
    setDefaultQuery,
    setCurrentPage,
    setActiveFilter,
    refetch,
    defaultQuery,
    searchTerm,
    setSearchTerm,
    setDebouncedQuery
    // postIds,
    // setPostIds,
  } = props;
  return (
    <>
      <SearchInput
        placeholder={
          isShopLinks
            ? "Search all shoppable content and products"
            : "Search content"
        }
        id="search-feed"
        value={searchTerm}
        onChange={(e: any) => {
          e.preventDefault();
          const elem: any = document.getElementById("search-feed");
          const searchVal = e.target.value;
          setSearchTerm(searchVal);
          // setQuery(e.target.value);
          // setDefaultQuery(e.target.value);
          // setCurrentPage(1);
        }}
        handleKeyUp={(e: any) => {
          e.preventDefault();
          if (e.keyCode === 13) {
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            if (searchVal.length === 0) {
              elem.blur();
              setSearchTerm("");
              setDebouncedQuery("");
              // setQuery("");
              // setDefaultQuery("");

              return;
            }
            elem.blur();
            setSearchTerm(searchVal);
            // setQuery(searchVal);
            // setDefaultQuery(searchVal);
            setCurrentPage(1);
          }
        }}
        isActiveFilter={Boolean(searchTerm)}
        onSearchClick={() => {
          const elem: any = document.getElementById("search-feed");
          const searchVal = elem.value;
          if (searchVal.length === 0) {
            return;
          }
          elem.blur();
          setSearchTerm(searchVal);
          setDebouncedQuery(searchVal);

          // setQuery(searchVal);
          // setDefaultQuery(searchVal);
          setCurrentPage(1);
        }}
        onClearClick={() => {
          const elem: any = document.getElementById("search-feed");
          elem.value = "";
          setSearchTerm("");
          setDebouncedQuery("");
          // setQuery("");
          // setDefaultQuery("");
          setActiveFilter("both");

          refetch();
          setCurrentPage(1);
          props?.setPostIds(props?.postIds);
        }}
      />
    </>
  );
};

export default CarouselSearchbar;
