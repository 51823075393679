import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { Button, Carousel, Input, Pagination, Space } from "antd";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import Skeleton from "react-loading-skeleton";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import { useLoadSearchFeedCount } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { useLoadSearchFeed } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import _, { debounce } from "lodash";
import { HiCheck, HiPencil } from "react-icons/hi";
import { supabase } from "../../../../config/supabaseClient";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselFilterControl from "../../../../components/CarouselControls/CarouselFilterControl";
import CarouselSearchbar from "../../../../components/CarouselControls/CarouselSearchbar";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import CarouselControlWrapper from "../../../../components/CarouselControls/CarouselControlWrapper";
import { updateNameValues } from "../../../../Utils/StayUpToDate/RenderComponent";
import EditableText from "../../../../components/EditModalComponents.tsx/EditableText";
import { Typography } from "antd";
import ProductTitle from "../../../../components/EditModalComponents.tsx/ProductTitle";
const { Title } = Typography;

const POSTS_PER_PAGE = 10;
const contentOrder = "Default";

const EditCustomFeedComponent = (props: any) => {
  const {
    username,
    user_id,
    component,
    defaultQuery,
    initial,
    isModalOpen,
    handleFeed,
    type,
    activeFilter,
    setActiveFilter,
    setDefaultQuery,
    isSoundOn,
    order,
    setOrder,
    shape,
    setShape,
    setIsSoundOn,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;

  const isShopLinks =
    component.type !== "query-content" && component.type !== "content";
  const [localPostIds, setLocalPostIds] = useState(
    component.post_ids.split(",")
  );
  const [isActionLoading, setIsActionLoading] = useState(false);
  const showSocials =
    type === "content"
      ? [
          "instagram",
          "youtube",
          "tiktok",
          "podcast",
          "pinterest",
          "newsletter",
          "twitter",
          "linkedin",

          // "instaComment"
          "MISC-content",
        ]
      : activeFilter === "both"
      ? StaticVariables().PLATFORMS
      : activeFilter === "content"
      ? [
          "instagram",
          "youtube",
          "tiktok",
          "podcast",
          "pinterest",
          "newsletter",
          "twitter",
          "linkedin",
          "MISC-content",
        ]
      : activeFilter === "products"
      ? ["LTK", "ShopMy", "Amazon", "MISC"]
      : activeFilter === "products"
      ? ["LTK", "ShopMy", "Amazon", "MISC"]
      : StaticVariables().PLATFORMS;
  useEffect(() => {
    setLocalPostIds(component.post_ids.split(","));
    setCurrentPage(1);
  }, [initial]);

  const {
    isLoading: AddedDataLoading,
    data: AddedData,
    refetch: refetchAddedContent,
  }: // forceRefetch,
  any = useLoadCustomContent(
    username,
    user_id,
    localPostIds,
    // component.post_ids,
    component.name
  );
  const [query, setQuery] = useState(component?.query || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(component?.query || "");
  useEffect(() => {
    setQuery(defaultQuery);
  }, [defaultQuery]);
  const { data: pages, refetch: refetchCount }: any = useLoadSearchFeedCount(
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    activeFilter
  );

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    activeFilter
  );
  const FILTER_OPTIONS = [
    { key: "both", label: "Both" },
    { key: "content", label: "Content" },
    { key: "products", label: "Products" },
  ];

  const buttonStyle = (isActive: any) => ({
    backgroundColor: isActive ? "black" : "white",
    color: isActive ? "white" : "black",
  });

  const debouncedQueryUpdate = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
      setQuery(value);
      setDefaultQuery(value);
      setCurrentPage(1);
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    debouncedQueryUpdate(searchTerm);
    return () => debouncedQueryUpdate.cancel();
  }, [searchTerm]);

  // Separate the filtering logic in useMemo
  const filteredContentData = useMemo(() => {
    return (data?.pages?.[0] || [])
      ?.filter((element: any) => element !== undefined)
      ?.filter((item: any) =>
        StaticVariables().CONTENT_PLATFORMS.includes(item.platform)
      );
  }, [data, localPostIds]);

  const filteredProducttData = useMemo(() => {
    return (data?.pages?.[0] || [])
      ?.filter((element: any) => element !== undefined)
      ?.filter((item: any) =>
        StaticVariables().PRODUCT_PLATFORMS.includes(item.platform)
      );
  }, [data, localPostIds]);

  return (
    <>
      {" "}
      <div className="margin-added-content">
        <div className="addedContent">
          <EditableText
            value={name}
            onSave={async (newValue: any) => {
              if (!newValue.trim()) {
                setErrorMsg("Title cannot be empty");
                return;
              }
              setErrorMsg("");
              setIsActionLoading(true);
              setEditIndex("title");
              try {
                // Update the parent component's name state
                setName(newValue);
              } catch (error) {
                console.error("Error updating component title:", error);
              } finally {
                setIsActionLoading(false);
                setEditIndex("");
              }
            }}
            isLoading={isActionLoading && editIndex === "title"}
            className="addedContentTitle editable-heading"
            textClassName="addedContentTitle"
            textStyle={{ fontWeight: "bold", fontSize: "18px" }}
            placeholder="Enter component title"
            setErrorMsg={setErrorMsg}
          />

          <PostStructure
            username={username}
            user_id={user_id}
            component={component}
            PostIdData={AddedData}
            localPostIds={localPostIds}
            setLocalPostIds={setLocalPostIds}
            handleFeed={handleFeed}
            type={component?.type}
            refetchAddedContent={refetchAddedContent}
            contentType="added"
            shape={shape}
            isSoundOn={isSoundOn}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            isShopLinks={isShopLinks}
          />
        </div>
        <div className="carousel-controls-wrapper">
        <div className="addedContent">
        <Title level={3}>Carosuel Control</Title>
        <CarouselControlWrapper
          showShape={true}
          showSound={true}
          showFilter={type !== "content"}
          showOrder={false}
          shape={shape}
          setShape={setShape}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          type={type}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
          username={username}
          user_id={user_id}
          POSTS_PER_PAGE={POSTS_PER_PAGE}
          showSocials={showSocials}
          isShopLinks={isShopLinks}
          filteredContentData={filteredContentData}
          filteredProducttData={filteredProducttData}
        />
        </div>
        </div>
       
        <CarouselSearchbar
          isShopLinks={isShopLinks}
          query={query}
          setQuery={setQuery}
          setDefaultQuery={setDefaultQuery}
          setCurrentPage={setCurrentPage}
          setActiveFilter={setActiveFilter}
          refetch={refetch}
          postIds={localPostIds}
          setPostIds={setLocalPostIds}
          defaultQuery={defaultQuery}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedQuery={setDebouncedQuery}
        />
      </div>
      <PostStructure
        username={username}
        user_id={user_id}
        component={component}
        PostIdData={data?.pages?.[0]}
        postLoading={isLoading}
        localPostIds={localPostIds}
        setLocalPostIds={setLocalPostIds}
        handleFeed={handleFeed}
        type={component?.type}
        contentType="all"
        edit={edit}
        setEdit={setEdit}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        isActionLoading={isActionLoading}
        setIsActionLoading={setIsActionLoading}
        isShopLinks={isShopLinks}
      />
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

// First component for added content
const AddedPostStructure = (props: any) => {
  const {
    username,
    user_id,
    component,
    PostIdData,
    isLoading,
    localPostIds,
    setLocalPostIds,
    type,
    refetchAddedContent,
    contentType,
    shape,
    isSoundOn,
    edit,
    setEdit,
    editIndex,
    setEditIndex,

    isActionLoading,
    setIsActionLoading,
    isShopLinks,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;

  const [value, setValue] = useState<string>();

  const [localPosts, setLocalPosts] = useState<any[]>([]);

  // Initialize and update localPosts when PostIdData changes
  useEffect(() => {
    if (PostIdData) {
      setLocalPosts(PostIdData.filter((element: any) => element !== undefined));
    }
  }, [PostIdData]);

  const onMinus = useCallback(
    async (post_id: string) => {
      setLocalPosts((prev) => prev.filter((post) => post.post_id !== post_id));
      let updatedPostArray = localPostIds.filter(
        (postId: any) => postId !== post_id
      );
      props.handleFeed(updatedPostArray);
      setLocalPostIds(updatedPostArray);
    },
    [localPostIds, props.handleFeed, setLocalPostIds]
  );

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  const handleDone = async (post_id: string, newValue: string) => {
    const postData = PostIdData.find((post: any) => post.post_id === post_id);
    postData.product_name = newValue;
    // if (type === "content" || type === "query-content") {
    //   const { data: user, error } = await supabase
    //     .from(`${username}`)
    //     .update({ content_title: value })
    //     .eq("post_id", post_id)
    //     .eq("user_id", user_id);
    //   const { data: newContent, error: newErroor } = await supabase
    //     .from("product_bank")
    //     .update({ content_title: value })
    //     .eq("product_id", post_id)
    //     .eq("user_id", user_id);
    //   postData.content_title = value;
    // } else {
    //   const { data: user, error } = await supabase
    //     .from(`${username}`)
    //     .update({ shop_title: value, caption: value, product_name: value })
    //     .eq("post_id", post_id)
    //     .eq("user_id", user_id);
    //   const { data: newContent, error: newErroor } = await supabase
    //     .from("product_bank")
    //     .update({ shop_title: value })
    //     .eq("product_id", post_id)
    //     .eq("user_id", user_id);
    //   postData.shop_title = value;
    // }
    // // setIsChange(true);

    // const { data: newContent, error: newErroor } = await supabase
    //   .from("content_new")
    //   .update({ product_name: value })
    //   .eq("post_id", post_id)
    //   .eq("user_id", user_id);
    // UpdateCombinedText(username, user_id, post_id);

    await updateNameValues(username, type, post_id, user_id, newValue);
    // refetch();
    // PostIdDataRefetch();
    // refetchDashboard();
    refetchAddedContent();
    setEdit(false);
    setEditIndex("");
  };

  return (
    <>
      {isLoading ? (
        <div className="data-skeleton">
          {[...Array(3)].map((_, index) => (
            <div className={"m-0px"} key={index}>
              <Skeleton
                className="skeleton"
                style={{ marginRight: "20px", width: "140px", height: "220px" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {localPosts?.length > 0 ? (
            <div className="addedSvg">
              {localPosts.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div className="feed-tab-post-container added-post">
                    <div
                      className="feed-tab-media-wrapper"
                      style={
                        post?.platform === "newsletter" ||
                        post?.platform === "linkedin" ||
                        post?.platform === "twitter"
                          ? {
                              textAlign: "center",
                              height: "100%",
                              borderRadius: "1rem",
                            }
                          : {}
                      }
                    >
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>

                      {post?.platform === "newsletter" ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white", borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "linkedin" && !post?.media_url ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "twitter" && !post?.media_url ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label">X Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-grid"
                              : ""
                          }`}
                        >
                          {post?.product_image && isShopLinks ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              <img
                                className={
                                  post?.type === "FEED" || shape === "sq"
                                    ? "feed-tab-media-check square-image"
                                    : "feed-tab-media-check"
                                }
                                src={post?.product_image}
                                alt="post"
                              />
                            </div>
                          ) : post?.media_url ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              post.thumbnail &&
                                              post.thumbnail.split(",")
                                                .length &&
                                              post.thumbnail.split(",")[index]
                                                ? post.thumbnail.split(",")[
                                                    index
                                                  ]
                                                : "/assets/dummy_video.png"
                                            }
                                            content_shape={shape}
                                            isSoundOn={isSoundOn}
                                          />
                                        ) : (
                                          <img
                                            className={`${
                                              post?.type === "FEED" ||
                                              shape === "sq"
                                                ? "feed-tab-media-check square-image mb-2"
                                                : "feed-tab-media-check mb-2"
                                            }`}
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div
                                      className={`${
                                        post?.type === "FEED" || shape === "sq"
                                          ? "square-wrapper"
                                          : "h-255"
                                      }`}
                                    >
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                        content_shape={shape}
                                        isSoundOn={isSoundOn}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className={`${
                                        post?.type === "FEED" || shape === "sq"
                                          ? "feed-tab-media-check square-image"
                                          : "feed-tab-media-check"
                                      }`}
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              <img
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "feed-tab-media-check square-image"
                                    : "feed-tab-media-check"
                                }`}
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          setIsActionLoading(false);
                        }
                      }}
                      isLoading={isActionLoading && editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <React.Fragment>
              <div className="feed-tab-post">
                {[...Array(4)].map((_, index) => (
                  <SkeletonStatBox key={index} />
                ))}
              </div>
            </React.Fragment>
          )}
        </>
      )}
    </>
  );
};

// Second component for all content
const AllPostStructure = (props: any) => {
  const {
    username,
    user_id,
    component,
    PostIdData,
    isLoading,
    localPostIds,
    setLocalPostIds,
    type,
    shape,
    isSoundOn,
    isShopLinks,
  } = props;

  const [localPosts, setLocalPosts] = useState<any[]>([]);

  useEffect(() => {
    if (PostIdData) {
      setLocalPosts(PostIdData.filter((element: any) => element !== undefined));
    }
  }, [PostIdData]);

  const onAdd = useCallback(
    async (post_id: string) => {
      if (!localPostIds.includes(post_id)) {
        // Add to the beginning of the array
        const updatedCheckedValues = [post_id, ...localPostIds];
        props.handleFeed(updatedCheckedValues);
        setLocalPostIds(updatedCheckedValues);
      }
    },
    [localPostIds, props.handleFeed, setLocalPostIds]
  );

  const onMinus = useCallback(
    async (post_id: string) => {
      let updatedPostArray = localPostIds.filter(
        (postId: any) => postId !== post_id
      );
      props.handleFeed(updatedPostArray);
      setLocalPostIds(updatedPostArray);
    },
    [localPostIds, props.handleFeed, setLocalPostIds]
  );

  const isPostSelected = useCallback(
    (post_id: string) => {
      return Array.isArray(localPostIds) && localPostIds.includes(post_id);
    },
    [localPostIds]
  );

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <div className="data-skeleton">
          {[...Array(3)].map((_, index) => (
            <div className={"m-0px"} key={index}>
              <Skeleton
                className="skeleton"
                style={{ marginRight: "20px", width: "140px", height: "220px" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {localPosts?.length > 0 ? (
            <div className="addedSvg">
              {localPosts.map((post: any) => (
                <React.Fragment key={post.post_id}>
                  <div className="feed-tab-post-container added-post">
                    <div className="feed-tab-media-wrapper" {...post.style}>
                      {isPostSelected(post.post_id) ? (
                        <Button
                          onClick={() => onMinus(post.post_id)}
                          className="carousel-content-remove-btn"
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          onClick={() => onAdd(post.post_id)}
                          className="carousel-content-add-btn"
                        >
                          Add
                        </Button>
                      )}
                      {post?.platform === "newsletter" ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white", borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "linkedin" && !post?.media_url ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "twitter" && !post?.media_url ? (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-wrapper"
                              : ""
                          } no-media-url`}
                          // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "newsletter-media-carousel square-image"
                                : "newsletter-media-carousel h-250"
                            }`}
                          >
                            <h4 className="newsletter-label">X Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "square-grid"
                              : ""
                          }`}
                        >
                          {post?.product_image && isShopLinks ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              <img
                                className={
                                  post?.type === "FEED" || shape === "sq"
                                    ? "feed-tab-media-check square-image"
                                    : "feed-tab-media-check"
                                }
                                src={post?.product_image}
                                alt="post"
                              />
                            </div>
                          ) : post?.media_url ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              post.thumbnail &&
                                              post.thumbnail.split(",")
                                                .length &&
                                              post.thumbnail.split(",")[index]
                                                ? post.thumbnail.split(",")[
                                                    index
                                                  ]
                                                : "/assets/dummy_video.png"
                                            }
                                            content_shape={shape}
                                            isSoundOn={isSoundOn}
                                          />
                                        ) : (
                                          <img
                                            className={`${
                                              post?.type === "FEED" ||
                                              shape === "sq"
                                                ? "feed-tab-media-check square-image mb-2"
                                                : "feed-tab-media-check mb-2"
                                            }`}
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div
                                      className={`${
                                        post?.type === "FEED" || shape === "sq"
                                          ? "square-wrapper"
                                          : "h-255"
                                      }`}
                                    >
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                        content_shape={shape}
                                        isSoundOn={isSoundOn}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className={`${
                                        post?.type === "FEED" || shape === "sq"
                                          ? "feed-tab-media-check square-image"
                                          : "feed-tab-media-check"
                                      }`}
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              }`}
                            >
                              <img
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "feed-tab-media-check square-image"
                                    : "feed-tab-media-check"
                                }`}
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  <ProductTitle post={post} type={type} />
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <React.Fragment>
              <div className="feed-tab-post">
                {[...Array(4)].map((_, index) => (
                  <SkeletonStatBox key={index} />
                ))}
              </div>
            </React.Fragment>
          )}
        </>
      )}
    </>
  );
};

// Main PostStructure component that decides which component to render
const PostStructure = (props: any) => {
  const { contentType } = props;

  return contentType === "added" ? (
    <AddedPostStructure {...props} />
  ) : (
    <AllPostStructure {...props} />
  );
};

export default EditCustomFeedComponent;
