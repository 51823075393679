import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { BsList } from "react-icons/bs";
import { Avatar, Dropdown, Space } from "antd";
// import { isMobile } from "react-device-detect";
import { UserOutlined } from "@ant-design/icons";
import { signOut } from "../../Utils/HelperFunction/HelperFunction";
import { useAuth } from "../../hooks/useAuth";
import { useSidebar } from '../../context/SidebarContext';
import React, { useEffect, useState } from "react";

const ImageSidebar = ({
  username,
  isCreator,
  isDashboard,
  isFollower,
  pictureUrl,
}: {
  username: string;
  isCreator?: boolean;
  isDashboard?: boolean;
  isFollower?: boolean;
  pictureUrl?: string;
}) => {
  const navigate = useNavigate();
  const { signOut: signOutSupabase } = useAuth();
  const { toggleSidebar, isOpen } = useSidebar();
  const location = useLocation();
  const isFollowerView = location.pathname.includes('follower');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSidebar();
  };

  const goToFollower = () => {
    navigate("/follower/" + username, { state: { from: "dashboard" } });
  };
  const goToDashboard = () => {
    navigate("/dashboard/" + username);
  };

  const goToCreator = () => {
    navigate("/creator/" + username, { state: { from: "dashboard" } });
  };
  async function handleSignOut() {
    await signOut();
    signOutSupabase();
    navigate("/");
  }

  const creatorMenu: any = [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToDashboard}>
          Dashboard
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },
    {
      key: "2",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToFollower}>
          Live Page Preview
        </p>
      ),
    },
    {
      key: "2.1",
      type: "divider",
    },
    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={handleSignOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const dashboardMenu = [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToFollower}>
          Live Page Preview
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },

    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={handleSignOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const followeMenu = [
    {
      key: "1",
      label: (
        <p className="header-dashboard-menu-item" onClick={goToDashboard}>
          Dashboard
        </p>
      ),
    },
    {
      key: "1.1",
      type: "divider",
    },
    // {
    //   key: "2",
    //   label: (
    //     <p className="header-dashboard-menu-item" onClick={goToCreator}>
    //       Staging View
    //     </p>
    //   ),
    // },
    // {
    //   key: "2.1",
    //   type: "divider",
    // },
    {
      key: "3",
      label: (
        <p className="header-dashboard-menu-item" onClick={handleSignOut}>
          Sign Out
        </p>
      ),
    },
  ];
  const items = isCreator
    ? creatorMenu
    : isDashboard
      ? dashboardMenu
      : isFollower
        ? followeMenu
        : [];
  return (
    <>
      {isFollowerView &&
        <>
          {!isMobile ? (
            <>
              <div className="header-nav-image" onClick={handleToggle}>
                <BsList />
                {pictureUrl ? (
                  <Avatar src={pictureUrl} className="header-dashboard-img" />
                ) : (
                  <Avatar className="user-avatar">{<UserOutlined />}</Avatar>
                )}
              </div>
            </>
          ) : (
            <div className="header-nav-image" onClick={handleToggle}>
              <BsList />
              {pictureUrl ? (
                <Avatar src={pictureUrl} className="header-dashboard-img" />
              ) : (
                <Avatar className="user-avatar">{<UserOutlined />}</Avatar>
              )}
            </div>
          )}
        </>
      }
    </>
  );
};

export default ImageSidebar;
