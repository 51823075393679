import React from 'react'

const PostInfoDate = (props:any) => {
    const {post_date} = props
  return (
    <>
     {new Date(post_date).getFullYear() > 2000 ? (
          <p className="creator-feed-content-date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(post_date))}
          </p>
        ) : (
          ""
        )}
    
    </>
  )
}

export default PostInfoDate