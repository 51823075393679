import { useLocation, useParams } from "react-router-dom";
import ImageSidebar from "./ImageSidebar";

const Header = ({ extraComponent = null, pictureUrl }: { extraComponent?: any, pictureUrl?: string }) => {
	const param = useParams();
	const location = useLocation()
	return (
		<div className='header-wrapper'>                        
			<div className="header-container d-none d-md-block">
			<p className='header-logo-tittle'>
				<img
					className='homepage-icon'
					src='/assets/icon-black.png'
					alt='thesearchbubble logo'
				/>
			</p>
			</div>
			{extraComponent}
		</div>
	);
};

export default Header;
