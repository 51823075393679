import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/skeleton.css";
import { BannerSkeleton } from "../../StayUpToDateComponent/BannerSkeleton";
const DashboardSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <div className="skeleton-dashboard-grid-item">
        <Skeleton />
      </div>
    );
  }
  return (
    <div
    // className="content-container"
    >
      <div className="row mx-0">
        <div className="right-panel box mt-8">
          <div className="row mx-0">
            <BannerSkeleton />
            <div className="skeleton-analytics-titles">
              {[...Array(4)].map((_, index) => (
                <SkeletonStatBox key={index} />
              ))}

              {/* {Array(4).fill(<SkeletonStatBox/>)} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
