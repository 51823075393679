import { Typography, Segmented } from "antd";
import { useLoadSearchFilterCount } from "../../Utils/customHooks/UpToDateContent/Query/useLoadSearchFilterCount";
import { StaticVariables } from "../../Utils/StaticVariables";
import { useLoadYesterdayLinks } from "../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import { useMemo } from "react";
import "./CarouselControl.css"
import React from "react";
import { useLoadQueryFeed } from "../../Screens/DashboardView/LandingPageCarousels/QueryBasedCarousel/useLoadQueryFeed";

const { Title } = Typography;

const CarouselFilterControl = (props: any) => {
  const {
    type,
    setCurrentPage,
    activeFilter,
    setActiveFilter,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,

    isShopLinks,
    post_ids,
    removePostIds,
    displayName,
    title,
    order,
    componentName,
    filteredContentData: filteredContentDataProps,
    filteredProducttData: filteredProducttDataProps
  } = props;

  // Get the counts for content and products for Create custom post 
  const { data: contentCount }: any = useLoadSearchFilterCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    StaticVariables().CONTENT_PLATFORMS,
    isShopLinks,
    "content",
    post_ids
  );



  const { data: productsCount }: any = useLoadSearchFilterCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    StaticVariables().PRODUCT_PLATFORMS,
    isShopLinks,
    "products",
    post_ids
  );

  // Get the counts for content and products for Create custom query post

  const {
    isLoading: isContentLoading,
    data: queryData
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    StaticVariables().PLATFORMS,
    "Default",
    isShopLinks,
    "",
    componentName,
    order
  );
  const queryContentData = useMemo(() => {
    return (queryData || [])
      .filter((element: any) => element !== undefined)
      .filter((item: any) => StaticVariables().CONTENT_PLATFORMS.includes(item.platform));

  }, [queryData, removePostIds]);

  const queryProductData = useMemo(() => {
    return (queryData || [])
      .filter((element: any) => element !== undefined)
      .filter((item: any) => StaticVariables().PRODUCT_PLATFORMS.includes(item.platform));

  }, [queryData, removePostIds]);



  // Get the counts for content and products for Edit Day wise post
  let numberOfDays;
  if (title) {
    if (displayName === "This Week") {
      numberOfDays = [7];
    } else if (displayName === "Yesterday") {
      numberOfDays = [1];
    } else if (displayName === "This Month") {
      numberOfDays = [30];
    } else {
     
      numberOfDays = [];
    }
  }

  let {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch,
  }: any = useLoadYesterdayLinks(
    username,
    user_id,
    numberOfDays,
    post_ids || [],
    removePostIds,
    title,
    false,
    order,
   
  );


  // Separate the filtering logic in useMemo
  const filteredContentData = useMemo(() => {
    return (yesterdayLinksData || [])
      .filter((element: any) => element !== undefined)
      .filter((item: any) => StaticVariables().CONTENT_PLATFORMS.includes(item.platform));

  }, [yesterdayLinksData, removePostIds]);

  const filteredProducttData = useMemo(() => {
    return (yesterdayLinksData || [])
      .filter((element: any) => element !== undefined)
      .filter((item: any) => StaticVariables().PRODUCT_PLATFORMS.includes(item.platform));

  }, [yesterdayLinksData, removePostIds]);

  const FILTER_OPTIONS = [
    { key: "both", label: "Both" },
    { key: "content", label: "Content" },
    { key: "products", label: "Products" },
  ];

  // Show filter only if both content and products exist
  const shouldShowFilter = query ? (queryContentData?.length > 0 || queryProductData?.length > 0) : title ? (filteredContentData?.length > 0 || filteredProducttData?.length > 0) : (contentCount?.feedCount > 0 || productsCount?.feedCount > 0) ? (contentCount?.feedCount > 0 || productsCount?.feedCount > 0) : (filteredContentDataProps?.length > 0 || filteredProducttDataProps?.length > 0);

  const getDisabledOptions = () => {
    const disabledOptions = [];
    if (query) {
      if (queryContentData?.length === 0) disabledOptions.push("Content");
      if (queryProductData?.length === 0) disabledOptions.push("Products");
      if (queryContentData?.length === 0 && queryProductData?.length === 0) disabledOptions.push("Both");
    } else if (title) {
      if (filteredContentData?.length === 0) disabledOptions.push("Content");
      if (filteredProducttData?.length === 0) disabledOptions.push("Products");
      if (filteredContentData?.length === 0 && filteredProducttData?.length === 0) disabledOptions.push("Both");
    } else {
      if (contentCount?.feedCount === 0) disabledOptions.push("Content");
      if (productsCount?.feedCount === 0) disabledOptions.push("Products");
      if (contentCount?.feedCount === 0 && productsCount?.feedCount === 0) disabledOptions.push("Both");
    }
    return disabledOptions;
  };

  return (
    <div className='mb-3'>
      {shouldShowFilter && (
        <>
          <Title level={4} style={{marginBottom: "0px"}}>Show</Title>
          <div className="filter-control">
            <Segmented
              options={FILTER_OPTIONS.map(({ key, label }) => ({
                label: label,
                value: key,
                disabled: getDisabledOptions().includes(label)
              }))}
              value={activeFilter}
              onChange={(value) => {
                setActiveFilter(value);
                setCurrentPage(1);
              }}
              className="custom-segmented"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CarouselFilterControl;
