import React from "react";
import Skeleton from "react-loading-skeleton";

const DataSkeleton = (props:any) => {
  const {dashboard}=props
  function SkeletonStatBox() {
    return (
      <div className={!dashboard ? "m-10px" : "m-0px"} >
        <Skeleton
        className="skeleton"
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="mainDiv"
      >
        <div
          className="data-skeleton"
          // style={!dashboard ? { border: "1px solid #ddd" } : {}}
        >
          {[...Array(3)].map((_, index) => (
            <SkeletonStatBox key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataSkeleton;
