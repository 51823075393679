import { useEffect, useState } from "react";
import "../StayUpToDate/StayUpToDateComponents/StayUpToDateComponentsWrapper.css";
import "./LandingComponentsWrapper.css";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal } from "antd";
import { useLocation } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import {
  deleteComponent,
  updateIndexValue,
} from "../../../Utils/SupabaseServices/StayUpToDateDatabase";
import StayUpToDateSkeleton from "../StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import CreatorLandingProfile from "../../../components/CreatorProfile/CreatorLandingProfile";
import SubComponentsWrapper from "../LandingPageCarousels/SubComponentsWrapper/SubComponentsWrapper";
import { BsPlusLg } from "react-icons/bs";
import { isMobile } from "react-device-detect";

const LandingComponentsWrapper = (props: any) => {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color,
    refatchStayUpToDate,
    showModal,
    refetchUser,
    isLoading,
    data,
    refetchUserDetail,
    from = "landing-page"
  } = props;
  const [localData, setLocalData] = useState(StayUpToDateComponents);
  const location = useLocation();

  
  useEffect(() => {
    // refetch();
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [status, setStatus] = useState("");

  useEffect(() => {
    refetchUserDetail();
    refetch();
    refetchUser();
  }, [status, StayUpToDateComponents]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    // if(result.type === "MAIN"){
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateIndexValue(user_id, newData);
    // }
  };

  // Add auto-scroll functionality
  const handleDragUpdate = (update: any) => {
    if (!update.destination) return;

    const draggable = document.querySelector(".dragging");
    if (!draggable) return;

    const container = document.getElementById("landing-wrapper");
    if (!container) return;

    const draggableRect = draggable.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Define scroll zones (top and bottom 20% of screen)
    const scrollZoneSize = Math.floor(1000);
    
    // Get vertical center of draggable
    const draggableMiddle = draggableRect.top + (draggableRect.height / 2);
    
    // Calculate distance from edges
    const distanceFromTop = draggableMiddle;
    const distanceFromBottom = viewportHeight - draggableMiddle;

    // Fixed scroll speed - simpler but effective
    const baseSpeed = isMobile ? 25 : 35;
    
    // If in top scroll zone
    if (distanceFromTop < scrollZoneSize) {
      // Calculate speed based on position in scroll zone
      const speed = Math.round(baseSpeed * (1 - distanceFromTop / scrollZoneSize));
      window.scrollBy({
        top: -Math.max(speed, 15), // Minimum speed of 15
        behavior: "auto"
      });
    }
    // If in bottom scroll zone
    else if (distanceFromBottom < scrollZoneSize) {
      // Calculate speed based on position in scroll zone
      const speed = Math.round(baseSpeed * (1 - distanceFromBottom / scrollZoneSize));
      window.scrollBy({
        top: Math.max(speed, 15), // Minimum speed of 15
        behavior: "auto"
      });
    }
  };

  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }

  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch,
      username
    ).then(() => {
      setTimeout(() => {
        setLocalData(filteredData);
      }, 10);
      setTimeout(() => {
        setConfirmLoading(false);
        setOpenDeleteModal(false);
      }, 50);
    });
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  return (
    <div className="stay-up-to-date-wrapper landing-mt-53">
      {isLoading ? (
        <div
          className={
            !(props.isCreator || location.pathname.includes("/follower"))
              ? "skeleton-creator mb-4"
              : "skeleton-creator-page mb-4"
          }
        >
          <Skeleton circle className="creator-img-skeleton hw-100" />
        </div>
      ) : (
        <CreatorLandingProfile
          userDetails={data?.combinedRes?.userData}
          showIcons={data?.combinedRes?.feedControls?.showIcons}
          liveView={location.pathname.includes("/follower") ? false : true}
          username={username}
          showSocialLinks={data?.combinedRes?.feedControls.showSocialLinks}
          socialLinks={data?.combinedRes?.userData}
          description={data?.combinedRes?.description}
          desiredOrder={data?.combinedRes?.userData?.social_links_order?.split(
            ","
          )}
          isUserDashboard={true}
          refetch={refetchUserDetail}
          refetchUser={refetchUser}
        />
      )}
      { StayUpToDateComponentsLoading || isLoading ? (
        <div className="loader-container">
          <StayUpToDateSkeleton />
        </div>
      ) : (
        <>
          <div
            id="landing-wrapper"
            className="component-box mt-4 landing-wrapper"
          >
            <button className="primary-add-button" onClick={showModal} aria-label="Add Page Component">
              <BsPlusLg className="primary-add-icon" />
              <span className="primary-add-text">Add Component</span>
            </button>
            <DragDropContext
              onDragStart={(start) => {
                // Ensure proper drag start handling
                document.documentElement.style.scrollBehavior = "unset";
                document.body.style.scrollBehavior = "unset";
                // Add dragging class to the dragged item
                const draggedElement = document.querySelector(
                  `[data-rbd-draggable-id="${start.draggableId}"]`
                );
                draggedElement?.classList.add("dragging");
              }}
              // onDragUpdate={handleDragUpdate}
              onDragEnd={(result) => {
                // Clean up dragging class
                const draggedElement = document.querySelector(".dragging");
                draggedElement?.classList.remove("dragging");
                onDragEnd(result);
              }}
            >
              <Droppable droppableId="droppable" direction="vertical" type="column">
                {(provided) => (
                  <div
                    id="landing-wrapper"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      // minHeight: '100vh',
                      touchAction: "none", // This helps with mobile touch handling
                    }}
                  >
                    {localData &&
                      localData.map((component: any, index: any) => (
                        <Draggable
                          key={component.id?.toString()}
                          draggableId={component.id?.toString()}
                          index={index}
                          shouldRespectForcePress={true}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                userSelect: "none",
                                WebkitUserSelect: "none",
                                cursor: "pointer",
                                zIndex: snapshot.isDragging ? 9999 : "auto",
                              }}
                              className={`component-box ${snapshot.isDragging ? "dragging" : ""
                                }`}
                            >
                              <SubComponentsWrapper
                                user_id={user_id}
                                username={username}
                                component={component}
                                dragHandleProps={provided.dragHandleProps}
                                refetch={refetch}
                                brand_color={brand_color}
                                handleDelete={handleDelete}
                                refatchStayUpToDate={refatchStayUpToDate}
                                setStatus={setStatus}
                                selectedComponent={selectedComponent}
                                setSelectedComponent={setSelectedComponent}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                isEditModalOpen={isEditModalOpen}
                                setIsEditModalOpen={setIsEditModalOpen}
                                from={from}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            data-testid="delete-carousel"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default LandingComponentsWrapper;
