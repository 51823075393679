import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { supabase } from "../../../../config/supabaseClient";
import "../Onboarding.css";
import { signupUserFolder } from "../../../../Utils/SupabaseServices/OnboardingFlow";

const UsernameSection = (props: any) => {
  const {
    username,
    setUsername,
    handleChange,
    handleNext,
    currentStep,
    formData,
    planDetails,
  } = props;
  const [localUsername, setLocalUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleUsername = async () => {
    setIsLoading(true);
    try {
      const { data: existingUser } = await supabase
        .from("user")
        .select("*")
        .eq("username", localUsername);

      if (existingUser && existingUser.length > 0) {
        setUsernameError("Username already exists!");
      } else {
        setUsernameError("");
        setUsername(localUsername);
        const res: any = await signupUserFolder(
          formData.name,
          localUsername,
          formData.email,
          formData.phone,
          formData.password
        );
        if (res.error) {
          setErrorMsg(res.message || "Error Adding Creator!");
          return;
        } else {
          setUsernameError("");
          setErrorMsg("");

          const { data } = await supabase
            .from("user")
            .update({
              is_username_signup: true,
              onboarding_step: currentStep,
              payment_plan: planDetails?.plan_details?.formatted_amount || "",
              payment_status: planDetails?.line_items?.data[0]?.description || "",
            })
            .eq("username", localUsername);
          handleNext();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="instagram-card">
        <div className="card-header">
          <div className="instagram-icon-wrapper">
            <AiOutlineUser className="instagram-icon" />
          </div>
          <h2 className="card-title">Choose Username</h2>
          <p className="card-description">
            Pick a unique username for your account
          </p>
        </div>

        <div className={`input-wrapper custom-username-field ${usernameError ? 'error' : ''}`}>
          <div className="domain-prefix">searchbubble.com/</div>
          <input
            type="text"
            placeholder="username"
            value={localUsername}
            onChange={(e) => {
              setLocalUsername(e.target.value);
              setUsernameError("");
            }}
            className="instagram-input username-input"
          />
        </div>

        {usernameError && <p className="error-message">{usernameError}</p>}
        {errorMsg && <p className="error-message">{errorMsg}</p>}

        <button
          type="button"
          onClick={handleUsername}
          className="next-button"
          disabled={!localUsername.trim() || isLoading}
        >
          {!isLoading ? (
            <>
              {usernameError ? "Try Again" : "Next"}
              <HiArrowRight className="arrow-icon" />
            </>
          ) : (
            <AiOutlineLoading3Quarters className="animate-spin" />
          )}
        </button>
      </div>
    </div>
  );
};

export default UsernameSection;
