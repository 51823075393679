import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";
import {
  fetchAllPodcastEpisodes,
  storePodcastChannel,
} from "../../../Utils/SupabaseServices/ContentDatabase";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

const PodcastIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showPodcastModal, user_id, data, refetchData } = props;
  const [podcastShowName, setPodcastShowName] = useState(
    data?.podcast_showname
  );
  const [podcastShowUrl, setPodcastShowUrl] = useState(
    data?.podcast_link
  );

  const [errorPodcast, setErrorPodcast] = useState("");
  const [loadingPodcastConnect, setLoadingPodcastConnect] = useState(false);
  const [podcastsList, setPodcastsList] = useState<any>([]);
  const [showPodcastListModal, setShowPodcastListModal] = useState(false);
  const [showChannelIdInstructions, setShowChannelIdInstructions] =
    useState(false);
  const [value, setValue] = useState();

  const onChange = async (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleCancel = () => {
    setShowPodcastListModal(false);
  };

  async function fetchAndStorePodcast() {
    setLoadingPodcastConnect(true);

    if (
      (podcastShowName && podcastShowName !== null && podcastShowName != "") ||
      (podcastShowUrl && podcastShowUrl !== null && podcastShowUrl != "")
    ) {
      // Validate that the URL is from Spotify
      if (!podcastShowUrl.toLowerCase().includes('spotify.com')) {
        setErrorPodcast("Please enter a valid Spotify URL");
        setLoadingPodcastConnect(false);
        return;
      }
      
      const res = await storePodcastChannel(
        user?.id as string,
        podcastShowName,
        podcastShowUrl,
        username as string
      );
      if (res.error) {
        setErrorPodcast(res.message);
      } else {
        setErrorPodcast("");
        refetchData();
        setLoadingPodcastConnect(false);
        // setIntegratePodcaseMsg(
        //   "Your Tiktok data will be integrated in your feed within 24 hours!"
        // );
        // props.setShowPodcastModal(false)
        props.onClose();
      }

      // old code
      // if (res) {
      //   const fetch = await fetchAllPodcastEpisodes(
      //     username,
      //     user?.id as string,
      //     podcastShowLink,
      //     showId,
      //     podcastShowName
      //   );
      //   if (fetch) {
      //     setErrorPodcast("");
      //     refetchData()
      //     setLoadingPodcastConnect(false);
      //     // props.setShowPodcastModal(false)
      //     props.onClose();

      //   } else {
      //     setErrorPodcast("Error while connecting to podcast. Contact Server Support")
      //     setLoadingPodcastConnect(false);
      //   }
      // }

      // if (!res) {
      //   setErrorPodcast("error while connecting to this podcast artist");
      // } else if (res.length === 0) {
      //   setErrorPodcast("No podcast related to this artist name");
      // } else {
      //   setPodcastsList(res);
      //   setShowPodcastListModal(true);
      //   props.setShowPodcastModal(false);
      // }
    } else {
      setErrorPodcast("Please enter both show name and show URL");
      setLoadingPodcastConnect(false);
    }
  }
  return (
    <>
      <Modal
        title="Connect to Podcast Show"
        open={showPodcastModal}
        data-testid="podcast-modal"
        onOk={async () => {
          await fetchAndStorePodcast();
          // const res = await storePodcastChannel(user?.id as string, podcastId);
          // if (!res) {
          //   setErrorPodcast("error while connecting to this podcast artist");
          // } else if (res.length === 0) {
          //   setErrorPodcast("No podcast related to this artist name");
          // } else {
          //   setPodcastsList(res);
          //   setShowPodcastListModal(true);
          //   props.setShowPodcastModal(false);
          // }
        }}
        onCancel={() => {
          setErrorPodcast("");
          setPodcastShowName(data?.podcast_showname);
          setPodcastShowUrl(data?.podcast_link);
          props.onClose();
          // props.setShowPodcastModal(false);
        }}
        okText="Connect"
        okButtonProps={{
          disabled: !(podcastShowName && podcastShowUrl),
          loading: loadingPodcastConnect,
        }}
        cancelButtonProps={{ disabled: loadingPodcastConnect }}
      >
        <p>
          By connecting your podcast show, all your episodes will be displayed
          in the feed of your main page. In the content tab you will be able to
          hide or delete the shows you don't want to show.{" "}
        </p>
        {loadingPodcastConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorPodcast && (
              <Alert
                type="error"
                message={`Error: ${errorPodcast}`}
                showIcon
                className="alert-margin"
              />
            )}
            {/*  {data?.podcast_artist ? (
              <>
                <p>
                  <b>
                    Already connected podcast artist: {data?.podcast_artist}
                  </b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )} */}
            <p>To connect enter your spotify podcast show name and show URL:</p>
            <Input
              placeholder="Enter show name"
              id="name-pod"
              value={podcastShowName}
              onChange={(e) => {
                const value = e.target.value;
                setPodcastShowName(value);
                if (!value) {
                  setErrorPodcast("Enter valid show name");
                } else {
                  setErrorPodcast("");
                }
              }}
            />
            <Input
              placeholder="Enter show URL (Spotify link)"
              id="link-pod"
              className="mt-4"
              value={podcastShowUrl}
              onChange={(e) => {
                const value = e.target.value;
                setPodcastShowUrl(value);
                // if (!value) {
                //   setErrorPodcast("Enter valid show URL");
                // } else if (!value.toLowerCase().includes('spotify.com')) {
                //   setErrorPodcast("Please enter a valid Spotify URL");
                // } else {
                  setErrorPodcast("");
                // }
              }}
            />

            {!showChannelIdInstructions ? (
              <></>
              // <p
              //   className="account-social-content-channelid-text"
              //   onClick={() => setShowChannelIdInstructions(true)}
              // >
              //   Find Your Show URL
              // </p>
            ) : (
              <div className="account-social-content-channelid-ins">
                <p>
                  You can see your show URL in{" "}
                  <a
                    href="https://open.spotify.com/?"
                    className="account-social-content-channelid-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    spotify app
                  </a>
                </p>
                <ol>
                  <li>Sign in to Spotify</li>
                  <li>
                    Search your show{" "}
                    <BsChevronRight className="chevron-right" />
                    Click the three dots <b>...</b>
                  </li>
                  <li>
                    Click <b>Share</b>.{" "}
                    <BsChevronRight className="chevron-right" />
                    Select <b>Copy Show Link </b> for the URL.{" "}
                  </li>
                  <li>
                    Paste the <b>Show Link</b> here.
                  </li>
                </ol>
              </div>
            )}
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      {/* <Modal
        title={podcastId ? `${podcastId}'s Podcasts` : ""}
        open={showPodcastListModal}
        onOk={handleOk}
        okText="Connect"
        onCancel={handleCancel}
      >
        <>
          <p>
            <b>Already connected podcast artist: {data?.podcast_link}</b>
          </p>

          {podcastsList.length ? (
            <>
              <p>Please choose one podcast you want to show on your feed :</p>
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  {podcastsList.map((podcast: any) => (
                    <Radio key={podcast.name} value={podcast.name}>
                      {podcast.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </>
          ) : (
            ""
          )}
        </>
      </Modal> */}
    </>
  );
};

export default PodcastIntegrationModal;
