import { Alert } from "antd";
import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { updateUsername } from "../../../../Utils/SupabaseServices/UserDatabase";
import { useNavigate } from "react-router-dom";

const Username = (props: any) => {
  const navigate = useNavigate();

  const { sessionUser } = props;
  const [editUsername, setEditUsername] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [username, setUsername] = useState(props.username);

  function handleUpdateValue(e: any, option: any) {
    if (option === "username") {
      setUsername(e.target.value.toLowerCase());
    }
  }
  async function doneEdit() {
    // if (option === "username") {
    if (/^[a-z0-9_]+$/.test(username) && username.length > 3) {
      const resUsername = await updateUsername(username, sessionUser?.id);
      if (!resUsername) {
        setUsernameError("This username already exists");
        return;
      }
      setUsernameError("");
      navigate(`/dashboard/${username}`);
      setEditUsername(false);
    } else {
      setUsernameError(
        "Please enter a username consisting of letters, digits, and underscores. Minimum 6 characters."
      );
    }
    // }
  }
  return (
    <div className="account-tile">
      <div className="account-tile-header">
        <div className="account-tile-title">
          Username <BsInfoCircle data-tooltip-id="username" />
        </div>
      </div>
      <div className="account-tile-value">
        {editUsername ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              name="username"
              required
              value={username}
              onChange={(e: any) => handleUpdateValue(e, "username")}
              autoComplete="do-not-autofill"
              style={{ 
                padding: '0.5rem', 
                borderRadius: '6px', 
                border: '1px solid #e5e7eb',
                marginRight: '0.5rem',
                width: '100%'
              }}
            />
            <button
              className="account-done-btn-name"
              onClick={() => {
                doneEdit();
              }}
              style={{
                backgroundColor: '#000',
                color: 'white',
                border: 'none',
                borderRadius: '6px',
                width: '36px',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
            >
              <HiCheck />
            </button>
          </div>
        ) : (
          <div style={{ cursor: "not-allowed", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{username}</span>
            <button
              className="account-edit-btn-name"
              onClick={() => {
                // setEditUsername(true);
              }}
              style={{ 
                pointerEvents: "none", 
                opacity: 0.2,
                backgroundColor: 'transparent',
                border: 'none',
                width: '36px',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {/* <HiPencil /> */}
            </button>
          </div>
        )}
      </div>
      {usernameError && (
        <div style={{ marginTop: '0.5rem' }}>
          <Alert type="warning" message={usernameError} />
        </div>
      )}
    </div>
  );
};

export default Username;
