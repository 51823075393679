import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 * Load content
 */

export function useLoadPostsByPostIds(
    currentPage: any,
    index: string,
    postsPerPage: number,
    totalPages: any,
    usernames: any,
    userIds: any,
    postIds: any[],
    showSocials: any
) {
    async function getPaginatedFeed({ pageParam = 1 }) {
        // If we have filtered postIds, paginate them directly
        if (postIds && postIds.length > 0) {
            const start = (currentPage - 1) * postsPerPage;
            const end = start + postsPerPage - 1;
            const paginatedIds = postIds.slice(start, end + 1);
            const { data: filteredData } = await supabase.rpc(
                "get_posts_by_ids_content_platformm",
                {
                    post_ids: paginatedIds,
                    userid: userIds,
                    username: usernames,
                    index_type: index,
                    show_socials: showSocials
                }
            );

            if (filteredData && filteredData.length >= 0 && Array.isArray(filteredData)) {
                return filteredData;
            } else {
                throw new Error("Failed to fetch posts");
            }
        }
    }

    return useInfiniteQuery(
        ["paginatedPosts", currentPage, index, postsPerPage, postIds],
        getPaginatedFeed,
        {
            getNextPageParam: (lastPage, pages) => {
                if (pages.length < totalPages) {
                    return pages.length + 1;
                } else {
                    return undefined;
                }
            },

            cacheTime: 0,
            staleTime: 0,

            enabled: Boolean(totalPages),
        }
    );
}

