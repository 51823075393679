import { useState, useRef, useEffect } from "react";
import { BsSearch, BsX } from "react-icons/bs";
import { updateSearches } from "../../Utils/SupabaseServices/SearchesDatabase";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./Search.css";
import { useQuery } from "react-query";
import React from "react";
import { useRotatingPlaceholder } from "./useRotatingPlaceholder";
const Search = (props: any) => {
  const {
    username,
    user_id,
    hashtagFilterArray,
    indexPages,
    isNecSearch,
    disabledFilters,
    clickedFilter,
    showBrowserCategories,
    contentType,
    searchBarText,
    showSearchbar,
    searchBarTags,
    from,
    setRemoveSearchLoading
  } = props;

  const location = useLocation();
  const [altQuery, setAltQuery] = useState<any>();
  const [query, setQuery] = useState<any>();

  const ref: any = useRef(null);
  const [hashSearch, setHashSearch] = useState(false);
  const [categorySearch, setCategorySearch] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [currHashtag, setCurrHashtag] = useState<any>();

  const [selectedFilter, setSelectedFilter] = useState<any>(
    hashtagFilterArray?.[0]
  );
  // hashtagFilterArray[0]

  const [selectedContentFilter, setSelectedContentFilter] = useState<any>(
    clickedFilter || contentType
  );
  const [selectedTrendingFilter, setSelectedTrendingFilter] =
    useState<any>(contentType);
  useEffect(() => {
    if (clickedFilter) {
      setSelectedContentFilter(clickedFilter);
    }
  }, [clickedFilter]);

  function handleTrendingFilterClick(filter: any) {
    setHashSearch(true);
    setSelectedTrendingFilter(filter);
    track(`${filter} - Trending Category button click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
    });
    if (selectedTrendingFilter !== filter) {
      props.handleTrending(filter);
    }
  }

  function handleContentFilterClick(filter: any) {
    setHashSearch(true);
    setSelectedContentFilter(filter);
    track(`${filter} - Content Filter button click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
    });

    if (selectedContentFilter !== filter) {
      props.handleFilter(filter);
    }
  }

  useEffect(() => {
    if (!props.query || props.query === undefined) {
      setQuery("");
      var elements = document.querySelectorAll(".hashtag-filt-btn");

      elements.forEach(function (element) {
        if (element.textContent === "All") {
          element.textContent = contentType;
        }
      });
    } else {
      setQuery(props.query);
    }
    if (
      !props.searchParam ||
      props.searchParam === undefined ||
      props.searchParam === "searchbar"
    ) {
      setSelectedFilter({ name: contentType, queries: [] });
      if (
        props.searchParam === "searchbar" &&
        props.query &&
        props.query !== ""
      ) {
        const element = document.querySelector(".hashtag-filt-btn.active");
        if (element && element?.textContent === contentType) {
          element.textContent = "All";
        }
      }
      // Track the search event
    } else if (props.searchParam === " ") {
    } else {
      setHashSearch(true);
      const matchingObject = hashtagFilterArray.find(
        (obj: any) => obj.name === props.searchParam
      );
      if (!matchingObject) {
        if (props.query) {
          const searchInput: any = document.getElementById("searchInput");
          searchInput.value = props.query;
          setQuery(props.query);
          ref.current.value = props.query;
          search(props.query);

          setIsSearch(true);
          setHashSearch(false);
        } else {
          const searchInput: any = document.getElementById("searchInput");

          searchInput.value = props.searchParam;
          setQuery(props.searchParam);

          setIsSearch(true);
          setHashSearch(false);
        }
      }
      // setSelectedFilter({
      //   name: props.searchParam,
      //   queries: matchingObject?.queries,
      // });
    }
  }, [props.query, props.searchParam]);

  useQuery({
    queryKey: ["searchParams", props.query, props.searchParam], // Set your desired query key
    queryFn: () => getParams(props.query, props.searchParam),
    enabled: Boolean(props.query) && Boolean(props.searchParam),
    retry: 3,
    // refetchOnMount: true,
  });
  // let searchbarTrackingExecuted = false;

  function getParams(query: any, type: any) {
    setQuery(query || ""); // Set query to an empty string if undefined or null
    setIsSearch(type === "searchbar");
    setHashSearch(type !== "searchbar");

    if (type !== "searchbar") {
      setCurrHashtag(query);
      const matchingObject = hashtagFilterArray.find(
        (obj: any) => obj.name === type
      );
      if (matchingObject) {
        setSelectedFilter({
          name: type,
          queries: matchingObject.queries,
          default: matchingObject.default,
        });
      }
    } else {
      setSelectedFilter({ name: contentType, queries: [] });

      if (type === "searchbar" && query !== "") {
        const element = document.querySelector(".hashtag-filt-btn.active");
        if (element && element?.textContent === contentType) {
          element.textContent = "All";
        }
      }
    }

    if (type !== "searchbar") {
      const matchingObject = hashtagFilterArray.find(
        (obj: any) => obj.name === type
      );
      if (matchingObject && matchingObject.queries.includes(query) === false) {
        const searchInput: any = document.getElementById("searchInput");

        searchInput.value = query;
        ref.current.value = props.query;
        search(props.query);
        setQuery(query);
        setIsSearch(true);
        setHashSearch(false);
      }
    } else {
      const searchInput: any = document.getElementById("searchInput");
      searchInput.value = type === "searchbar" ? query : "";
      if (type === "searchbar" && query !== "") {
        const element = document.querySelector(".hashtag-filt-btn.active");
        if (element && element?.textContent === contentType) {
          element.textContent = "All";
        }
      }
    }
  }

  useEffect(() => {
    if (props.result !== undefined) {
      setAltQuery(props.result);
    }
  }, [props.result]);

  const searchbarTrackingExecutedRef = useRef(false);

  useEffect(() => {
    // Track the search event only when type is "searchbar" and not tracked yet
    if (props.searchParam && !searchbarTrackingExecutedRef.current) {
      if (props.query) {
        // if(props.query.trim()==''){
        // }else{
        track(`${props.searchParam} search`, TRACKING_TYPES.SEARCH, {
          pathname: location.pathname,
          details: props.query.toLowerCase(),
          user_id: user_id,
        });
        // }

        // Set the ref to indicate that tracking has been executed
        searchbarTrackingExecutedRef.current = true;
      } else {
        if (props.searchParam.trim() != "") {
          if (
            props.searchParam != contentType ||
            props.searchParam != "Top Hashtags" ||
            props.searchParam != "My Links"
          ) {
            track(`${props.searchParam} search`, TRACKING_TYPES.SEARCH, {
              pathname: location.pathname,
              details: props.searchParam,
              user_id: user_id,
            });

            searchbarTrackingExecutedRef.current = true;
            const matchingObject = hashtagFilterArray.find(
              (obj: any) => obj.name === props.searchParam
            );
            if (matchingObject) {
              setSelectedFilter({
                name: props.searchParam,
                queries: matchingObject.queries,
                default: matchingObject.default,
              });

              if (location.key === "default") {
                const removedItemIndex = hashtagFilterArray.findIndex(
                  (item: any) => item.name === props.searchParam
                );
                const removedItem = hashtagFilterArray.splice(
                  removedItemIndex,
                  1
                )[0];

                hashtagFilterArray.splice(1, 0, removedItem);
              } else {
              }

              // props.updateHashtagFilterArray(hashtagFilterArray);

              var elements = document.querySelectorAll(".hashtag-filt-btn");

              elements.forEach(function (element) {
                if (element.textContent === "All") {
                  element.textContent = contentType;
                }
              });

              // setSelectedFilter({ name: type, queries: matchingObject.queries });
            } else {
              setSelectedFilter({ name: contentType, queries: [] });
            }
          }
        }
      }
    }
  }, [props.searchParam, props.query]);

  async function search(query: any) {
    if (query && ref.current.value) {
      if (query.trim().length === 0) {
        ref.current.value = "";
      } else {
        setSelectedFilter({ name: contentType, queries: [] });

        const element = document.querySelector(".hashtag-filt-btn.active");
        if (element && element?.textContent === contentType) {
          element.textContent = "All";
        }

        props.setLoadingFeed(true);
        setIsSearch(true);

        props.getQuery(query, "searchbar");
        props.setCategory("searchbar");

        setSearches(user_id, query, "searchbar");
        searchbarTrackingExecutedRef.current = false;
      }
    }
  }

  const runSearch = (e: any, val: any) => {
    e.preventDefault();

    if (val.length === 0) {
      setAltQuery("");
    }

    if (e.keyCode === 13) {
      e.preventDefault();
      ref.current.blur();
      setSelectedFilter({ name: contentType, queries: [] });
      // document?.querySelector(".hashtag-filt-btn.active")?.textContent= "All";
      const element = document.querySelector(".hashtag-filt-btn.active");
      if (element && element?.textContent === contentType) {
        element.textContent = "All";
      }

      if (val.trim().length === 0) {
        ref.current.value = "";
      } else {
        props.setLoadingFeed(true);
        setIsSearch(true);

        props.getQuery(query, "searchbar");
        props.setCategory("searchbar");
        setSearches(user_id, query, "searchbar");
        searchbarTrackingExecutedRef.current = false;
      }
    }
  };

  const runHashtagSearch = (hash: any, index: any, type: any) => {

    setIsSearch(false);
    const val = hash;
    if (hash !== currHashtag) {
      if (val.length === 0) {
        props.getQuery("");
        setCurrHashtag(null);
      } else {
        sendQuery(val, type);
        setCurrHashtag(null);
      }
    } else if (hash === currHashtag) {
      // sendQuery(val, type);
      setQuery(hash);
      setCurrHashtag(null);
    }
    setHashSearch(false);
    setCategorySearch(true);
  };

  function sendQuery(val: any, hashtagType: any) {
    props.getQuery(val, `${hashtagType}`);
    ref.current.value = "";
    searchbarTrackingExecutedRef.current = false;

    // setSearches(val, hashtagType);
  }
  function sendAltQuery(val: any, hashtagType: any) {
    setAltQuery("");
    ref.current.value = val;
    setIsSearch(true);
    props.getQuery(val, "searchbar");
    setQuery("");

    setSearches(user_id, val, hashtagType);
  }

  function reset() {
    setAltQuery("");
    setQuery("");
    setIsSearch(false);
    setRemoveSearchLoading(true)
    props.getQuery("");
    const element = document.querySelector(".hashtag-filt-btn.active");
    if (element) {
      element.textContent = contentType;
    }

    setHashSearch(false);
    ref.current.value = "";
    const intervalId = setInterval(() => {
      setRemoveSearchLoading(false)
    }, 3000)
    return () => clearInterval(intervalId);
  }
  function handleClick(filter: any) {
    setCategorySearch(true);
    ref.current.value = "";
    setHashSearch(true);
    setIsSearch(false);
    if (selectedFilter.name !== filter.name) {
      props.setCategory(filter.name);
      sendQuery("", filter.name);

      if (query) {
        sendQuery("", filter.name);
        props.setCategory(filter.name);
      }
    } else {
      if (query) {
        sendQuery("", filter.name);
        props.setCategory(filter.name);

        setQuery("");
      }
    }

    setSelectedFilter(filter);
    if (filter.name === "My Links") {
      track(`my links link click`, TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        user_id: user_id,
      });
    } else {
      track(`${filter.name} button click`, TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        user_id: user_id,
      });
    }
    // if
  }

  function linkAnalytics(link: any) {
    track(
      `${link.split(":-")[0]} - my links link click`,
      TRACKING_TYPES.EVENT,
      {
        pathname: location.pathname,
        user_id: user_id,
        details: link.split(":-")[1],
      }
    );
  }

  useEffect(() => {
    if (navigator.userAgent.includes("iPhone") && ref.current) {
      ref.current.focus();
    }
  }, []);
  const placeholder = useRotatingPlaceholder(username, searchBarText);

  return (
    <div className="search-elements">
      <div
        className={
          contentType === "Landing Page" &&
            (!props.searchParam ||
              props.searchParam === undefined ||
              props.searchParam === "Landing Page")
            ? "search-container-div-landing search-container"
            : "search-container"
        }
      >
        <input
          ref={ref}
          type="text"
          id="searchInput"
          enterKeyHint="search"
          placeholder={placeholder}
          onInput={(e: any) => {
            runSearch(e, e.target.value);
            setQuery(e.target.value);
          }}
          onKeyUp={(e: any) => {
            if (e.key === "Enter") {
              runSearch(e, e.target.value);
              setQuery(e.target.value);
            }
          }}
          className="search-wrapper-input"
          autoComplete="off"
        />

        {!isSearch ? (
          <button
            className="search-wrapper-btn search-btn-width"
            onClick={() => search(ref.current.value)}
          >
            <BsSearch />
          </button>
        ) : (
          <button
            className="search-wrapper-btn search-btn-width"
            onClick={reset}
          >
            <BsX />
          </button>
        )}
      </div>
      {altQuery && query ? (
        <div className="search-alt-query">
          Did you mean{" "}
          <button
            className="search-alt-query-btn"
            onClick={() => {
              sendAltQuery(altQuery, "searchbar");
              setAltQuery("");
            }}
          >
            {altQuery}
          </button>
          ?
        </div>
      ) : (
        ""
      )}
      {showBrowserCategories ? (
        <>
          {!indexPages ? (
            <>
              {hashtagFilterArray &&
                hashtagFilterArray.length > 0 &&
                selectedFilter ? (
                <div
                  className={
                    contentType === "Landing Page" &&
                      (props.searchParam === "Landing Page" ||
                        !props.searchParam ||
                        props.searchParam === undefined)
                      ? "search-container-div-landing search-hashtags"
                      : "search-hashtags"
                  }
                >
                  <div className="hashtag-filt-btn-wrpr" id="hashtag-btn">
                    {hashtagFilterArray.map((filter: any) => (
                      <React.Fragment key={filter.name}>
                        <div
                          key={filter.name}
                          className={`hashtag-filt-btn  ${selectedFilter
                            ? selectedFilter.name === filter.name
                              ? "active"
                              : null
                            : null
                            }`}
                          onClick={() => {
                            handleClick(filter);
                            setCurrHashtag(null);
                          }}
                        >
                          {contentType === filter.name &&
                            selectedFilter?.name === filter.name
                            ? "All"
                            : filter.name}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {hashtagFilterArray && hashtagFilterArray.length > 0 ? (
                <>
                  {selectedFilter &&
                    selectedFilter.name !== "Content Type" &&
                    selectedFilter.name !== "Trending" &&
                    selectedFilter.name !== "My Links" ? (
                    <div className="search-hashtags">
                      <div
                        className="search-hashtags-scroll"
                        id="search-buttons"
                      >
                        {selectedFilter.queries &&
                          selectedFilter.queries.length ? (
                          <>
                            {selectedFilter.queries.map(
                              (hashtag: any, index: any) => (
                                <React.Fragment key={index}>
                                  {hashtag ? (
                                    <div key={index} id={`container${index}`}>
                                      <span className="btn-container">
                                        <button
                                          className={`search-hashtag-btn ${hashSearch &&
                                            (query === hashtag ||
                                              props.query === hashtag ||
                                              (!query &&
                                                !props.query &&
                                                hashtag ===
                                                selectedFilter?.default))
                                            ? "active"
                                            : "null"
                                            }`}
                                          value={hashtag.query}
                                          onClick={(e: any) => {
                                            if (hashtag !== currHashtag) {
                                              setHashSearch(true);
                                              setAltQuery("");
                                              if (
                                                hashtag ===
                                                selectedFilter?.default
                                              ) {
                                                props.setDefaultCategory(
                                                  hashtag
                                                );
                                              }
                                              setQuery(hashtag);
                                              setCurrHashtag(hashtag);

                                              runHashtagSearch(
                                                hashtag,
                                                hashtag.id,
                                                selectedFilter.name
                                              );
                                            }
                                          }}
                                          id={`hashtag${hashtag.id}`}
                                        >
                                          {hashtag}
                                        </button>
                                        {(query === hashtag ||
                                          props.query === hashtag) &&
                                          hashSearch ? (
                                          <button
                                            className="search-cross-btn"
                                            onClick={() => {
                                              setHashSearch(false);
                                              setQuery("");
                                              props.setLoadingFeed(true);
                                              props.getQuery("");
                                              props.setQueryFilter(contentType);
                                              setCurrHashtag(null);
                                              setSelectedFilter({
                                                name: contentType,
                                                queries: [],
                                                default: "",
                                              });
                                            }}
                                          >
                                            x
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div id={`container`}>
                              <span className="btn-container">
                                <button
                                  className={`search-hashtag-btn`}
                                  style={{ display: "none" }}
                                ></button>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : selectedFilter &&
                    selectedFilter.name === "Content Type" ? (
                    <div className="cont-filt-btn-wrpr">
                      {selectedFilter &&
                        selectedFilter.queries &&
                        selectedFilter.queries.length &&
                        selectedFilter.queries.map((filter: any) => (
                          <div
                            key={filter}
                            className={`cont-filt-btn ${disabledFilters &&
                              disabledFilters.includes(filter)
                              ? "disabled"
                              : null
                              } ${selectedContentFilter === filter ? "active" : null
                              }`}
                            onClick={() => handleContentFilterClick(filter)}
                          >
                            {filter}
                          </div>
                        ))}
                    </div>
                  ) : selectedFilter && selectedFilter.name === "Trending" ? (
                    <div className="search-hashtags">
                      <div
                        className="search-hashtags-scroll"
                        id="search-buttons"
                      >
                        {selectedFilter.queries &&
                          selectedFilter.queries.length > 0 &&
                          selectedFilter.queries.map(
                            (hashtag: any, index: any) => (
                              <React.Fragment key={index}>
                                {hashtag ? (
                                  <div key={index} id={`container${index}`}>
                                    <span className="btn-container">
                                      <button
                                        className={`search-hashtag-btn ${selectedTrendingFilter === hashtag
                                          ? "active"
                                          : "null"
                                          } `}
                                        value={hashtag.query}
                                        onClick={() => {
                                          handleTrendingFilterClick(hashtag);
                                        }}
                                        id={`hashtag${hashtag.id}`}
                                      >
                                        {hashtag}
                                      </button>

                                      <button
                                        className="search-cross-btn"
                                        style={{
                                          display: `${selectedTrendingFilter === hashtag
                                            ? "inline-block"
                                            : "none"
                                            }`,
                                        }}
                                        onClick={() => {
                                          props.setLoadingFeed(true);
                                          setHashSearch(false);

                                          props.getQuery("");
                                          setQuery("");
                                          props.setTrending(contentType);
                                          setSelectedTrendingFilter(
                                            contentType
                                          );
                                        }}
                                      >
                                        x
                                      </button>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            )
                          )}
                      </div>
                    </div>
                  ) : selectedFilter.name === "My Links" ? (
                    <div className="search-hashtags">
                      <div
                        className="search-hashtags-scroll"
                        id="search-buttons"
                      >
                        {selectedFilter.queries &&
                          selectedFilter.queries.length ? (
                          <>
                            {selectedFilter.queries.map(
                              (hashtag: any, index: any) => (
                                <React.Fragment key={index}>
                                  {hashtag ? (
                                    // <a
                                    //   href={
                                    //     hashtag
                                    //       .split(":-")[1]
                                    //       .startsWith("www.")
                                    //       ? `http://${hashtag.split(":-")[1]}`
                                    //       : hashtag.split(":-")[1]
                                    //   }
                                    <a
                                      href={
                                        hashtag && hashtag.trim() !== ""
                                          ? hashtag.split(":-").length >= 2
                                            ? hashtag
                                              .split(":-")[1]
                                              .trim()
                                              .startsWith("www.")
                                              ? `http://${hashtag
                                                .split(":-")[1]
                                                .trim()}`
                                              : hashtag.split(":-")[1].trim()
                                            : `#${hashtag.trim()}`
                                          : "#"
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="hashtag"
                                    >
                                      <div key={index} id={`container${index}`}>
                                        <span className="btn-container">
                                          <button
                                            className={`search-hashtag-btn`}
                                            value={hashtag.query}
                                            onClick={(e: any) => {
                                              linkAnalytics(hashtag);
                                            }}
                                            id={`hashtag${hashtag.id}`}
                                          >
                                            {hashtag.split(":-")[0]}
                                          </button>
                                        </span>
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <div id={`container`}>
                              <span className="btn-container">
                                <button
                                  className={`search-hashtag-btn`}
                                  style={{ display: "none" }}
                                ></button>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Search;

export async function setSearches(user_id: any, query: any, hashtagType: any) {
  await updateSearches(user_id, query, hashtagType);
}
