import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header/header";
import ImageSidebar from "../../components/Header/ImageSidebar";
import { TabTitle } from "../../Utils/GeneralFunctions";
import NoUser from "../../ErrorPages/NoUser";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import FeedControls from "../../components/FeedPage/FeedControls/FeedControls";
import { useLoadCompletePage } from "../../Utils/customHooks/useLoadCompletePage";
import { useLoadRenderComponent } from "../../components/StayUpToDateComponent/StayUpToDateRenderComponent/useLoadRenderComponent";
import FrontendSkeleton from "../../components/skeleton/Views/FrontendSkeleton";
import { useLoadProfileSetup } from "../../Utils/customHooks/useLoadProfileSetup";
import StayUpToDateSkeleton from "../DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import CreatorProfile from "../../components/CreatorProfile/creatorProfile";
import SlidingSidebar from "../../components/SlidingSidebar/SlidingSidebar";
import Sidebar from "../DashboardView/Dashboard/Sidebar";
import { useSidebarState } from '../../context/SidebarStateContext';
import { useSidebar } from '../../context/SidebarContext';
import { useLoadUser } from "../../Utils/customHooks/useLoadUser";
import { fetchUser } from "../../Utils/SupabaseServices/UserDatabase";

const FollowerView = () => {
  const page = "follower";
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchLoading, setShowSearchLoading] = useState(false);
  // const [removeSearchLoading, setRemoveSearchLoading] = useState(false)
  const location = useLocation();
  const param = useParams();
  const { search } = location;
  const isFollowerView = location.pathname.includes('follower');
  const queryParams = new URLSearchParams(search);

  const utmSource = queryParams.get("utm_source");
  if (utmSource) {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      details: { source: param?.username },
      // user_id: "",
    });
  }

  const indexPages: any = location?.state?.indexPages;

  if (location.state) {
    TabTitle("Follower View • Bubble");
  } else {
    TabTitle(`${param?.username} • Bubble `);
  }
  const { isLoading: profileSetupLoading, data: profileSetupData } =
    useLoadProfileSetup(param?.username, page);
  const { isLoading: userLoading, data: userData, refetch }: any = useLoadUser(param?.username);
  const { isLoading, data, error }: any = useLoadCompletePage(
    param?.username,
    page,
    profileSetupData?.combinedRes?.userData?.user_id,
    profileSetupData?.combinedRes?.feedControls,
    profileSetupData?.combinedRes?.instagram_links,
    profileSetupData?.combinedRes?.userData?.is_fillout_on
  );

  const { handleClick, isEmail,
    isName,
    isPhone,
    isPhoneEmpty,
    tab,
    isEmailVerified,
    option, setIsEmail,
    setIsName,
    setIsPhone,
    setIsPhoneEmpty,
    setTab,
    setIsEmailVerified } = useSidebarState();
  const { isOpen, closeSidebar } = useSidebar();
  const [contentShifted, setContentShifted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isFollowerView && isOpen) {
      setTimeout(() => {
        setContentShifted(true);
      }, 10);
    } else {
      setContentShifted(false);
    }
  }, [isOpen, isFollowerView]);

  useEffect(() => {
    fetchEmail();
  }, [isEmail, isName]);

  const fetchEmail = useCallback(async () => {
    try {
      const userData = await fetchUser(param?.username);
      if (!userData) return;

      const hasEmail = userData.email !== "";
      const hasName = userData.name !== "";

      if (hasEmail && hasName) {
        setIsName(true);
        setIsEmail(true);
        setIsPhone(true);
        setIsEmailVerified(true);
      } else {
        setIsEmail(hasEmail);
        setIsName(hasName);
        handleClick("Account");
        setTab(0);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  }, [param?.username]);

  useEffect(() => {
    const route = location.pathname;
    let title: any;
    if (route.includes("follower")) {
      // updateFollowerCount(username);
      title = "Follower";
    } else {
      // updateLiveCount(username);
      title = "Live";
    }
    if (!profileSetupLoading) {
      track(location.pathname, TRACKING_TYPES.PAGE, {
        pathname: location.pathname,
        title: title,
        user_id: profileSetupData?.combinedRes?.userData?.user_id,
      });
      if (param?.id) {
        track(`/${param?.username}`, TRACKING_TYPES.PAGE, {
          pathname: `/${param?.username}`,
          title: title,
          user_id: profileSetupData?.combinedRes?.userData?.user_id,
        });
      }
    }
  }, [profileSetupLoading, profileSetupData?.combinedRes]);

  const {
    isLoading: StayUpToDateComponentsDataIndividualLoading,
    data: StayUpToDateComponentsDataIndividual,
  } = useLoadRenderComponent(
    profileSetupData?.combinedRes?.userData?.user_id,
    param?.id
  );

  useEffect(() => {
    const updateSearchBar = () => {
      if (
        profileSetupData?.combinedRes?.feedControls?.checkedType ===
        "Landing Page"
      ) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (
          urlSearchParams?.size > 0 &&
          (params.hasOwnProperty("term") || params.hasOwnProperty("from"))
        ) {
          setShowSearch(true);
        } else {
          setShowSearch(false);
        }
      } else {
        setShowSearch(true);
      }
    };
    updateSearchBar();

  }, [
    profileSetupData?.combinedRes?.feedControls?.checkedType,
    location.search,
  ]);

  // useEffect(() => {
  //   setRemoveSearchLoading(true);
  //   const intervalId = setInterval(() => {
  //     setRemoveSearchLoading(false)
  //   }, 1000)
  //   return () => clearInterval(intervalId);

  // }, [
  //   queryParams
  // ]);

  return (
    <>
      {profileSetupLoading ? (
        <FrontendSkeleton from={location.pathname.includes("embed") ? "embed-search" : ""} />
      ) : !profileSetupData?.combinedRes?.userData?.name || !profileSetupData?.combinedRes?.userData?.user_id || !param?.username ? (
        <NoUser />
      ) : (
        <>
          <div className={` ${isFollowerView ? "dashboard" : ""}`}>
            <div className={` ${isFollowerView ? "dashboard-layout" : ""}`}>

              {isFollowerView && (
                <SlidingSidebar type={location?.pathname.includes("follower") ? "follower" : "dashboard"}>
                  <Sidebar
                    username={param?.username}
                    pictureUrl={userData?.picture}
                    email_verify={userData?.email_verify}
                  />
                </SlidingSidebar>
              )}
              <div className={`dashboard-content`}>
                {profileSetupData?.combinedRes?.userData?.name &&
                  param?.username &&
                  profileSetupData?.combinedRes?.feedControls?.checkedType && (
                    <div id="app-wrapper" className="inex-1">
                      {location.pathname.includes("/follower") && (
                        <Header
                          pictureUrl={
                            profileSetupData?.combinedRes?.userData?.picture
                          }

                          extraComponent={
                            location?.pathname.includes("follower") ? (<div className="header-component">
                              <div className="header-container mobile-share-icon">
                                <CopyToClipboard
                                  textToCopy={`https://www.searchbubble.com/${param?.username}`}
                                  buttonText=""
                                />
                              </div>
                              <ImageSidebar
                                username={param?.username as string}
                                isFollower={true}
                                pictureUrl={
                                  profileSetupData?.combinedRes?.userData?.picture
                                }
                              />
                            </div>) :
                              (<div className="header-component">
                                <CopyToClipboard
                                  textToCopy={`https://www.searchbubble.com/${param?.username}`}
                                />
                                <ImageSidebar
                                  username={param?.username as string}
                                  isFollower={true}
                                  pictureUrl={
                                    profileSetupData?.combinedRes?.userData?.picture
                                  }
                                />
                              </div>)
                          }
                        />
                      )}
                      <CreatorProfile
                        userDetails={profileSetupData?.combinedRes?.userData}
                        showIcons={
                          profileSetupData?.combinedRes?.feedControls?.showIcons
                        }
                        liveView={
                          location.pathname.includes("/follower") ? false : true
                        }
                        username={param?.username}
                        showSocialLinks={
                          profileSetupData?.combinedRes?.feedControls.showSocialLinks
                        }
                        socialLinks={profileSetupData?.combinedRes?.userData}
                        description={profileSetupData?.combinedRes?.description}
                        setShowSearch={setShowSearch}
                        showSearch={showSearch}
                        setShowSearchLoading={setShowSearchLoading}
                        desiredOrder={profileSetupData?.combinedRes?.userData?.social_links_order?.split(
                          ","
                        )}
                        showSearchbar={
                          profileSetupData?.combinedRes?.feedControls.showSearchbar
                        }
                        loading={loading}
                        from="landing-page"
                      />
                    </div>
                  )}


                {isLoading || StayUpToDateComponentsDataIndividualLoading ? (
                  //
                  <div id="app-wrapper" className='frontend-skeleton'>
                    <div className='uptodate-wrapper'>
                      <StayUpToDateSkeleton />
                    </div>
                  </div>
                ) : profileSetupData?.combinedRes?.userData?.name &&
                  profileSetupData?.combinedRes?.userData?.user_id &&
                  param?.username ? (
                  <FeedControls
                    indexPages={indexPages}
                    userDetails={profileSetupData?.combinedRes?.userData}
                    username={param?.username}
                    page="follower"
                    name={profileSetupData?.combinedRes?.userData?.name}
                    userId={profileSetupData?.combinedRes?.userData?.user_id}
                    data={data?.combinedRes}
                    profileData={profileSetupData?.combinedRes}
                    hashtagFilters={data?.hashtagFilterArray}
                    avgWords={data?.combinedRes?.userData?.avg_words}
                    liveView={location.pathname.includes("/follower") ? false : true}
                    StayUpToDateComponentsDataIndividual={
                      StayUpToDateComponentsDataIndividual
                    }
                    StayUpToDateComponentsDataIndividualLoading={
                      StayUpToDateComponentsDataIndividualLoading
                    }
                    showSearch={showSearch}
                    showSearchLoading={showSearchLoading}
                    setShowSearchLoading={setShowSearchLoading}
                    setShowSearch={setShowSearch}
                    StayUpToDateComponentsLoading={isLoading}
                    StayUpToDateComponents={
                      data?.combinedRes?.stayUpToDateComponentsData
                    }
                    from="landing-page"
                  // removeSearchLoading={removeSearchLoading}
                  // setRemoveSearchLoading={setRemoveSearchLoading}
                  />
                ) : (
                  <NoUser />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FollowerView;
// {profileSetupLoading ? (
//   <FrontendSkeleton />
// ) : // <p>Loading</p>
// isLoading || StayUpToDateComponentsDataIndividualLoading ? (
//   // <></>
//   // <ProfileSkeleton />
//   // <FrontendSkeleton />
//   <div className="uptodate-wrapper">
//     <StayUpToDateSkeleton />
//   </div>
// ) : data?.combinedRes?.userData?.name &&
//   data?.combinedRes?.userData?.user_id &&
//   param?.username ? (
//   <>
//     <div id="app-wrapper" className="inex-1">
//       {location.pathname.includes("/follower") ? (
//         <>
//           <Header
//             extraComponent={
//               <div className="header-component">
//                 <CopyToClipboard
//                   textToCopy={`https://www.searchbubble.com/${param?.username}`}
//                 />
//                 <ImageSidebar
//                   username={param?.username as string}
//                   isFollower={true}
//                   pictureUrl={data?.combinedRes?.userData?.picture}
//                 />
//               </div>
//             }
//           />
//         </>
//       ) : (
//         ""
//         // <LiveMenu username={username as string} />
//       )}
//     </div>

//     <FeedControls
//       indexPages={indexPages}
//       userDetails={data?.combinedRes?.userData}
//       username={param?.username}
//       page="follower"
//       name={data?.combinedRes?.userData?.name}
//       userId={data?.combinedRes?.userData?.user_id}
//       data={data?.combinedRes}
//       hashtagFilters={data?.hashtagFilterArray}
//       avgWords={data?.combinedRes?.userData?.avg_words}
//       liveView={location.pathname.includes("/follower") ? false : true}
//       StayUpToDateComponentsDataIndividual={
//         StayUpToDateComponentsDataIndividual
//       }
//     />
//   </>
// ) : (
//   <NoUser />
// )}

