import {
  Alert,
  Button,
  Input,
  Modal,
  Spin,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { supabase } from "../../../config/supabaseClient";

const ShopMyIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showShopMyModal, user_id, data, refetchData } = props;
  const [shopmyLink, setShopmyLink] = useState(
    data?.shopmy_link || ""
  );

  const [errorShopmy, setErrorShopmy] = useState("");
  const [loadingShopmyConnect, setLoadingShopmyConnect] = useState(false);
  const [showLinkInstructions, setShowLinkInstructions] = useState(false);

  const handleCancel = () => {
    props.onClose();
    setErrorShopmy("");
    setShopmyLink(data?.shopmy_link || "");
  };

  async function connectShopmyAccount() {
    setLoadingShopmyConnect(true);

    if (shopmyLink && shopmyLink !== null && shopmyLink !== "") {
      // Validate that the URL is from ShopMy
      if (!shopmyLink.toLowerCase().includes('shop.app') && !shopmyLink.toLowerCase().includes('shopmy.')) {
        setErrorShopmy("Please enter a valid ShopMy URL");
        setLoadingShopmyConnect(false);
        return;
      }
      
      try {
        // Here you would typically call your backend service to store the ShopMy link
        // For now, we'll simulate a successful API call
        const {data,error} = await supabase.from("user").update({
          social_shopmy: shopmyLink
         }).eq("user_id",user_id)
         props.onClose()
         refetchData()
      } catch (error) {
        console.error("Error connecting ShopMy:", error);
        setErrorShopmy("Connection error. Please try again later.");
      } finally {
        setLoadingShopmyConnect(false);
      }
    } else {
      setErrorShopmy("Please enter your ShopMy store link");
      setLoadingShopmyConnect(false);
    }
  }

  return (
    <>
      <Modal
        title="Connect to ShopMy"
        open={showShopMyModal}
        data-testid="shopmy-modal"
        onOk={async () => {
          await connectShopmyAccount();
        }}
        onCancel={handleCancel}
        okText="Connect"
        okButtonProps={{
          disabled: !shopmyLink,
          loading: loadingShopmyConnect,
        }}
        cancelButtonProps={{ disabled: loadingShopmyConnect }}
      >
        <p>
          By connecting your ShopMy store, your product links will be displayed
          in the feed of your main page. In the content tab you will be able to
          hide or delete the products you don't want to show.
        </p>
        {loadingShopmyConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorShopmy && (
              <Alert
                type="error"
                message={`Error: ${errorShopmy}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.shopmy_link ? (
              <>
                <p>
                  <b>
                    Already connected ShopMy link: {data?.shopmy_link}
                  </b>
                </p>
                <p>New link will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your ShopMy storefront URL:</p>
            <Input
              placeholder="Enter ShopMy link (e.g., https://shopmy.us/yourusername)"
              id="shopmy-link"
              value={shopmyLink}
              onChange={(e) => {
                const value = e.target.value;
                setShopmyLink(value);
                if (!value) {
                  setErrorShopmy("Please enter your ShopMy link");
                } else if (!value.toLowerCase().includes('shop.app') && !value.toLowerCase().includes('shopmy.')) {
                  setErrorShopmy("Please enter a valid ShopMy URL");
                } else {
                  setErrorShopmy("");
                }
              }}
            />

           
          </>
        )}
      </Modal>
    </>
  );
};

export default ShopMyIntegrationModal;
