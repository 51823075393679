import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLoadTrendingProducts } from "../../../../Utils/customHooks/UpToDateContent/useLoadTrendingProducts";
import { updateCardImage, updateIsEmptyStatus } from "../../../../Utils/StayUpToDate/RenderComponent";
import DataSkeleton from "../DataSkeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import { isMobile } from "react-device-detect";
import { debounce } from "lodash";
import CustomPagination from "../../CustomPagination";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";

const TrendingProductsDataLoader = (props: any) => {
    const itemsPerPage = 3;
    const {
        user_id,
        username,
        title,
        postIds,
        handleRedirect,
        brand_color,
        isLinks,
        component,
        type,
        details,
        removedPostIds,
        RenderUI
    } = props;

    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<any>(null);
    const isButtonClicked = useRef(false);
    const [isLoading, setIsLoading] = useState(true);

    const { isLoading: TrendingProductsLoading, data: TrendingProducts }: any =
        useLoadTrendingProducts(
            user_id,
            username,
            title,
            details?.split(",")?.[0],
            removedPostIds,
            details?.split(",")?.[1],
            details?.split(",")?.[2],
            details?.split(",")?.[3]
        );


    useEffect(() => {
        const checkEmptyStatus = async () => {
            if (!TrendingProductsLoading) {
                if (!TrendingProducts || TrendingProducts.length === 0) {
                    await updateIsEmptyStatus(title, user_id, component.id);
                    setIsLoading(false);
                } else {
                    await handleFirstImage(title, component?.id, TrendingProducts[0]);
                    setIsLoading(false);
                }
            }
        };
        checkEmptyStatus();
    }, [TrendingProductsLoading, TrendingProducts]);

    const handleNavigate = (component: any) => {
        if (isNavigable(component)) {
            navigate(`/${username}/${component.unique_id}`, {
                state: { data: component },
            });

            track("Carousel Click", TRACKING_TYPES.EVENT, {
                pathname: location.pathname,
                details: {
                    unique_id: component?.unique_id,
                    type: component?.type,
                    title: component.name,
                },
                user_id: user_id,
                title: "Stay Up To Date",
            });
        }
    };

    function handleFirstImage(name: any, id: any, firstElement: any) {
        const imageToSend =
            firstElement?.table_image ||
            (firstElement?.media_url?.includes("video")
                ? firstElement?.thumbnail
                : firstElement?.media_url);

        if (imageToSend) {
            updateCardImage(name, user_id, id, imageToSend);
        }
    }

    const handlePrevPage = () => {
        isButtonClicked.current = true;
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setTimeout(() => {
            isButtonClicked.current = false;
        }, 500);
    };

    const handleNextPage = () => {
        isButtonClicked.current = true;
        setCurrentIndex((prevIndex) =>
            Math.min(prevIndex + 1, TrendingProducts.length - itemsPerPage)
        );
        setTimeout(() => {
            isButtonClicked.current = false;
        }, 500);
    };

    const handleScroll = useCallback(
        debounce(() => {
            if (!isButtonClicked.current) {
                if (containerRef.current) {
                    const scrollLeft = containerRef.current.scrollLeft;
                    const itemWidth = containerRef.current.scrollWidth / TrendingProducts.length;
                    const newCurrentIndex = Math.round(scrollLeft / itemWidth);
                    setCurrentIndex(newCurrentIndex);
                }
            }
        }, 100),
        [TrendingProducts]
    );

    useEffect(() => {
        if (containerRef.current) {
            const itemWidth = containerRef.current.scrollWidth / TrendingProducts.length;
            const scrollPosition = itemWidth * currentIndex;
            containerRef.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    }, [currentIndex, TrendingProducts]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
            return () => {
                if (containerRef.current) {
                    containerRef.current.removeEventListener("scroll", handleScroll);
                }
            };
        }
    }, [TrendingProducts, handleScroll]);

    return (
        <>
            {(TrendingProductsLoading || isLoading) ? (
                <DataSkeleton />
            ) : 
            (TrendingProducts && TrendingProducts.length === 0) ? null :
            (
                <>
                    {RenderUI ? <RenderUI
                        data={TrendingProducts}
                        currentIndex={currentIndex}
                        handleNavigate={handleNavigate}
                        component={component}
                        handleNextPage={handleNextPage}
                        handlePrevPage={handlePrevPage}
                        containerRef={containerRef}
                        handleRedirect={handleRedirect}
                        brand_color={brand_color}
                        title={title}
                        type={type}
                        isLinks={isLinks}
                    /> :
                        TrendingProducts && TrendingProducts.length > 0 ? (
                            <div>
                                <div className="mainDiv">
                                    <p
                                        className="component-name"
                                        style={param?.id ? { cursor: "auto" } : {}}
                                        onClick={(e) => handleNavigate(component)}
                                    >
                                        {title}
                                    </p>
                                    {!param?.id && (
                                        <CustomPagination
                                            totalItems={TrendingProducts?.length}
                                            currentIndex={currentIndex}
                                            handlePrevPage={handlePrevPage}
                                            handleNextPage={handleNextPage}
                                        />
                                    )}
                                </div>
                                <div
                                    className={!param?.id ? "outer-container-reels" : ""}
                                    ref={containerRef}
                                >
                                    <div
                                        className={
                                            !param?.id
                                                ? "links-grid-container-reels"
                                                : isMobile
                                                    ? "item-grid creator-card-grid-wrp"
                                                    : "item-grid"
                                        }
                                    >
                                        {TrendingProducts &&
                                            TrendingProducts.map((link: any, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={
                                                            link.platform != "youtube"
                                                                ? "link-url"
                                                                : !link.product_image ? "link-url youtube-link-position" : "link-url"
                                                        }
                                                    >
                                                        {link.media_url ? (
                                                            link.media_url.split(",").length > 1 &&
                                                                link.media_url
                                                                    .split(",")
                                                                    .every((url: any) => url.trim() !== "") ? (
                                                                <ImagePostCarouselTag
                                                                    post={link}
                                                                    handleRedirect={handleRedirect}
                                                                    brand_color={brand_color}
                                                                    isLinks={isLinks}
                                                                    isDashboard={false}
                                                                    isHasMediaUrl={
                                                                        link.type === "FEED" ? true : false
                                                                    }
                                                                    isYoutube={
                                                                        link.platform == "youtube" && !link.product_image ? true : false
                                                                    }
                                                                    contentShape={component?.content_shape == "sq" ? true : false}
                                                                    isSoundOn={component?.content_sound}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {link.product_image && link.product_image !== "" ? (
                                                                        <ImagePostTag
                                                                            data={link}
                                                                            handleRedirect={handleRedirect}
                                                                            title={title}
                                                                            brand_color={brand_color}
                                                                            isLinks={isLinks}
                                                                            isDashboard={false}
                                                                            isHasMediaUrl={false}
                                                                            isYoutube={
                                                                                link.platform == "youtube" && !link.product_image ? true : false
                                                                            }
                                                                            contentShape={component?.content_shape == "sq" ? true : false}
                                                                        // isSoundOn={component?.content_sound}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {link.media_url.includes("video") ? (
                                                                                <VideoPostTag
                                                                                    post={link}
                                                                                    handleRedirect={handleRedirect}
                                                                                    title={title}
                                                                                    brand_color={brand_color}
                                                                                    isLinks={isLinks}
                                                                                    isDashboard={false}
                                                                                    thumbnail={link.thumbnail}
                                                                                    isHasMediaUrl={
                                                                                        link.type === "FEED" ? true : false
                                                                                    }
                                                                                    isYoutube={
                                                                                        link.platform == "youtube" && !link.product_image ? true : false
                                                                                    }
                                                                                    type={type}
                                                                                    contentShape={component?.content_shape == "sq" ? true : false}
                                                                                    isSoundOn={component?.content_sound}
                                                                                />
                                                                            ) : (
                                                                                <ImagePostTag
                                                                                    data={link}
                                                                                    handleRedirect={handleRedirect}
                                                                                    title={title}
                                                                                    brand_color={brand_color}
                                                                                    isLinks={isLinks}
                                                                                    isDashboard={false}
                                                                                    isHasMediaUrl={
                                                                                        link.type === "FEED" ? true : false
                                                                                    }
                                                                                    isYoutube={
                                                                                        link.platform == "youtube" && !link.product_image ? true : false
                                                                                    }
                                                                                    contentShape={component?.content_shape == "sq" ? true : false}
                                                                                // isSoundOn={component?.content_sound}
                                                                                />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )
                                                        ) : (
                                                            <ImagePostTag
                                                                data={link}
                                                                handleRedirect={handleRedirect}
                                                                brand_color={brand_color}
                                                                isLinks={isLinks}
                                                                isDashboard={false}
                                                                isHasMediaUrl={
                                                                    link.type === "FEED" ? true : false
                                                                }
                                                                isYoutube={
                                                                    link.platform == "youtube" && !link.product_image ? true : false
                                                                }
                                                                contentShape={component?.content_shape == "sq" ? true : false}
                                                            // isSoundOn={component?.content_sound}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                </>
            )}
        </>
    );
};

export default TrendingProductsDataLoader; 