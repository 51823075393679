import { Button, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { addComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import FeedCheckboxComponent from "./FeedCheckboxComponent";
import { ModalBackButton } from "../../../../components/EditModalComponents.tsx/ModalBackButton";

const AddCustomContentComponents = (props: any) => {
  const { username, user_id, isCustomContentComponentOpen, from } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [defaultQuery, setDefaultQuery] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [activeFilter, setActiveFilter] = useState("both");
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [shape, setShape] = useState("rect");
  const [order, setOrder] = useState("DESC");

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    if (!name.trim()) {
      setErrorMsg("Title cannot be empty");
      return;
    }
    setConfirmLoading(true);

    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      name,
      "content",
      postIds.toString(),
      order,
      shape,
      isSoundOn,
      undefined,
      from
    );

    if (res === "true") {
      setName("");
      setPostIds("");
      setDefaultQuery("");
      setErrorMsg("");
      setActiveFilter("both");

      props.refetch();

      props.setIsContentModalOpen(false);
      props.setIsCustomContentComponentOpen(false);
      setConfirmLoading(false);
    } else {
      setErrorMsg("Category with this name already exists.");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setActiveFilter("both");
    setShape("rect");
    setIsSoundOn(false);
    props.setIsContentModalOpen(false);
    props.setIsCustomContentComponentOpen(false);
  };
  const handlePrevious = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setActiveFilter("both");
    setShape("rect");
    setIsSoundOn(false);
    props.setIsCustomContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }

  return (
    <>
      <Modal
        // title={
        //   <div className="modal-title">
        //     <button
        //       onClick={handlePrevious}
        //       className="newbutton"
        //     >
        //       <FaArrowLeft />
        //     </button>
        //   </div>
        // }
        open={isCustomContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        okButtonProps={{ disabled: !name }}
        data-testid="add-custom-content-carousel"
        width={600}
        centered
        className="add-to-page-modal"
        closeIcon={<span className="modal-close-icon">×</span>}
      >
        <ModalBackButton
          handlePrevious={handlePrevious}
          title="Create a Custom Content Carousel"
        />

        
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <FeedCheckboxComponent
          isShopLinks={false}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          className="ant-checkbox-wrapper"
          componentName={name}
          post_ids={postIds}
          defaultQuery={defaultQuery}
          setDefaultQuery={setDefaultQuery}
          type="content"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          shape={shape}
          setShape={setShape}
          order={order}
          setOrder={setOrder}
          name={name}
          setName={setName}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      </Modal>
    </>
  );
};

export default AddCustomContentComponents;
