import { Alert, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import { storeFlowdeskId, storeFlowdeskPopupId, storeYoutubeChannel } from "../../../Utils/SupabaseServices/ContentDatabase";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import validator from "validator";
const FlowdeskPopupIntegrationModal = (props: any) => {
  const { showFlowdeskPopupModal, user_id, data, refetchData } = props;
  const [FormId, setFormId] = useState(data?.flowdeskpopup_user);
  const { username } = useParams();
  const [errorFormId, setErrorFormId] = useState("");
  const [loadingFlowdeskConnect, setLoadingFlowdeskConnect] = useState(false);
  const [showFormIdInstructions, setShowFormIdInstructions] = useState(false);
  return (
    <>
      {" "}
      <Modal
        title="Connect Flodesk Popup"
        open={showFlowdeskPopupModal}
        onOk={async () => {
          // setShowPopupFormIdModal(true);
          if (validator.isURL(FormId)) {
            setLoadingFlowdeskConnect(true);
            await storeFlowdeskPopupId(
              user_id as string,
              FormId,
              username
            );
          // if (error) {
          //   setErrorFormId(message);
          // } else {
          refetchData();
          // setShowPopupFormIdModal(false);
          props.onClose();

          // }
          setLoadingFlowdeskConnect(false);
          setFormId("")
          }else {
            setErrorFormId("Please enter a valid URL");
          }
        }}
        onCancel={() => {
          setErrorFormId("");
          // setShowPopupFormIdModal(false);
          props.onClose();

          setFormId("")
        }}
        okText="Connect"
        okButtonProps={{ disabled: !FormId, loading: loadingFlowdeskConnect }}
        cancelButtonProps={{ disabled: loadingFlowdeskConnect }}
      >
        <p>
          By connecting your flodesk, template of your choice is displayed
          as a popup at regular intervals in the
          Landing Page component of your main page.{" "}
        </p>
        {loadingFlowdeskConnect ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {errorFormId && (
              <Alert
                type="error"
                message={`Error: ${errorFormId}`}
                showIcon
                className="alert-margin"
              />
            )}

            <p>To connect enter your Browser URL:</p>
            <Input
              placeholder="Browser URL"
              value={FormId}
              onChange={(e) => {
                setFormId(e.target.value);
              }}
            />
            {!showFormIdInstructions ? (
              <p
                className="account-social-content-channelid-text"
                onClick={() => setShowFormIdInstructions(true)}
              >
                Find Your Browser URL
              </p>
            ) : (
              <div className="account-social-content-Formid-ins">
                <p>
                  You can find your Browser URL
                  <a
                    href="https://flodesk.com/"
                    className="account-social-content-channelid-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </p>
                <ol>
                  <li>Sign in to Flodesk</li>
                  <li>Choose and design your <b>popup</b> template</li>
                  <li>
                    After you've customized it
                  </li>
                  <li>
                    Copy and paste your <b>browser url</b> here
                  </li>
                </ol>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default FlowdeskPopupIntegrationModal;
