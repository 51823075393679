import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal, Spin } from "antd";
import "./IntegrationsBox.css";
import { useLoadIntegrations } from "./useLoadIntegrations";
import IntegrationPlatforms from "./IntegrationPlatforms";
import { updatePlatformToggle } from "../../Utils/SupabaseServices/UserDatabase";

import YoutubeIntegrationModal from "./PlatformIntegrationModal/YoutubeIntegrationModal";
import InstagramIntegrationModal from "./PlatformIntegrationModal/InstagramIntegrationModal";
import PodcastIntegrationModal from "./PlatformIntegrationModal/PodcastIntegrationModal";
import NewsletterIntegrationModal from "./PlatformIntegrationModal/NewsletterIntegrationModal";
import TiktokIntegrationModal from "./PlatformIntegrationModal/TiktokIntegrationModal";
import PinterestIntegrationModal from "./PlatformIntegrationModal/PinterestIntegrationModal";
import TwitterIntegrationModal from "./PlatformIntegrationModal/TwitterIntegrationModal";
import FlowdeskIntegrationModal from "./PlatformIntegrationModal/FlowdeskIntegrationModal";
import FlowdeskPopupIntegrationModal from "./PlatformIntegrationModal/FlowdeskPopupIntegrationModal";
import FilloutIntegrationModal from "./PlatformIntegrationModal/FilloutIntegrationModal";
import LinkedInModal from "./PlatformIntegrationModal/LinkedInModal";
import AmazonIntegrationModal from "./PlatformIntegrationModal/AmazonIntegrationModal";
import LTKIntegrationModal from "./PlatformIntegrationModal/LTKIntegrationModal";
import ShopMyIntegrationModal from "./PlatformIntegrationModal/ShopMyIntegrationModal";
export default function IntegrationsBox({
  subscription_type,
  first_connected_platform,
}: any) {
  const { user } = useAuth();
  const { isLoading, isFetching, data, refetch } = useLoadIntegrations(
    user?.id as string
  );
  const [modalState, setModalState] = useState({
    currentPlatform: "",
    isVisible: false,
  });

  const [platformToggles, setPlatformToggles] = useState<
    Record<string, boolean>
  >({});
  const [showConfirmation, setShowConfirmation] = useState({
    platformKey: "",
    platformToggles: false,
  });

  // Organize platforms into categories
  const contentPlatforms = [
    { name: "Instagram", key: "instagram", modalKey: "InstagramModal", label: data?.instagram_username },
    { name: "YouTube", key: "youtube", modalKey: "YoutubeModal" },
    { name: "TikTok", key: "tiktok", modalKey: "TiktokModal" },
    { name: "X", key: "twitter", modalKey: "TwitterModal" },
    { name: "LinkedIn", key: "linkedin", modalKey: "LinkedInModal" },
    { name: "Newsletter", key: "newsletter", modalKey: "NewsletterModal" },
    { name: "Podcast", key: "podcast", modalKey: "PodcastModal" },
  ];

  const affiliatePlatforms = [
    { name: "Amazon", key: "amazon", modalKey: "AmazonModal", label: data?.amazon_user },
    { name: "ShopMy", key: "shopmy", modalKey: "ShopMyModal", label: data?.shopmy_user },
    { name: "LTK", key: "ltk", modalKey: "LTKModal", label: data?.ltk_user },
  ];

  const embedPlatforms = [
    { name: "Flodesk Inline", key: "flowdesk", modalKey: "FlowdeskModal", label: data?.flowdesk_user },
    { name: "Flodesk Popup", key: "flowdeskpopup", modalKey: "FlowdeskPopupModal", label: data?.flowdeskpopup_user },
    { name: "Fillout", key: "fillout", modalKey: "FilloutModal", label: data?.fillout_user },
  ];

  // All platforms for modal and toggle functionality
  const allPlatforms = [...contentPlatforms, ...affiliatePlatforms, ...embedPlatforms];

  const handleToggle = async (platformKey: string, checked: boolean) => {
    if (checked == false) {
      setShowConfirmation({
        platformKey,
        platformToggles: !checked,
      });
    } else {
      handleOffPlatformToggle(platformKey, checked);
    }
  };

  const handleOffCancel = () => {
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    });
  };

  const handleOffPlatformToggle = async (
    platformKey: string,
    checked: boolean
  ) => {
    setPlatformToggles((prevState) => ({
      ...prevState,
      [platformKey]: checked,
    }));
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    });
    await updatePlatformToggle(user?.id, platformKey, checked);
  };

  const handleModalOpen = (platformKey: string) => {
    setModalState({ currentPlatform: platformKey, isVisible: true });
  };

  const handleModalClose = () => {
    setModalState({ currentPlatform: "", isVisible: false });
  };

  useEffect(() => {
    if (data) {
      const initialToggles = allPlatforms.reduce((acc, platform) => {
        acc[platform.key] = Boolean(data[`is_${platform.key}_on`]);
        return acc;
      }, {} as Record<string, boolean>);
      setPlatformToggles(initialToggles);
    }
  }, [data]);

  const handleConnected = (platformKey: string) => {
    switch (platformKey) {
      case "tiktok":
      case "twitter":
      case "fillout":
     
      case "pinterest":
      case "flowdesk":
      case "linkedin":
      case "flowdeskpopup":
        return `${platformKey}_user`;

      case "instagram":
        return "instagram_username";

      case "youtube":
        return "youtube_channel_id";

      case "podcast":
        return "podcast_link";

      case "newsletter":
        return "newsletter_email";

      case "amazon":
        return "social_amazon";

      case "shopmy":
        return "social_shopmy";

      case "ltk":
        return "social_ltk";

      default:
        return `${platformKey}_user`;
    }
  };

  const platformNames =
    allPlatforms.find((platform) => platform.key === showConfirmation.platformKey)
      ?.name || "this platform";
  return (
    <>
      <div className="platform-integration-container">
        {isLoading || isFetching ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            <div className="platforms-layout">
              <div className="platforms-column">
                <div className="platform-section">
                  <h2 className="platform-section-title">
                    <div className="section-title-bar"></div>
                    Content Platforms
                  </h2>
                  <div className="platforms-grid content-platforms-grid">
                    {contentPlatforms.map(({ name, key, modalKey, label }) => {
                      const isConnected = handleConnected(key);
                      return (
                        <IntegrationPlatforms
                          key={key}
                          platformName={name}
                          isConnected={Boolean(data?.[`${isConnected}`])}
                          isProcessing={Boolean(data?.[`show_${key}`])}
                          isPlatformEnabled={platformToggles[key]}
                          onTogglePlatform={(checked: any) =>
                            handleToggle(key, checked)
                          }
                          onConnectPlatform={() => handleModalOpen(modalKey)}
                          subscription_type={subscription_type}
                          first_connected_platform={first_connected_platform}
                          label={label}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Right column - Affiliate and Embed Platforms */}
              <div className="platforms-column">
                <div className="platform-section">
                  <h2 className="platform-section-title">
                    <div className="section-title-bar"></div>
                    Affiliate Platforms
                  </h2>
                  <div className="platforms-grid">
                    {affiliatePlatforms.map(({ name, key, modalKey, label }) => {
                      const isConnected = handleConnected(key);
                      return (
                        <IntegrationPlatforms
                          key={key}
                          platformName={name}
                          isConnected={Boolean(data?.[`${isConnected}`])}
                          isProcessing={Boolean(data?.[`social_${key}`])}
                          isPlatformEnabled={platformToggles[key]}
                          onTogglePlatform={(checked: any) =>
                            handleToggle(key, checked)
                          }
                          onConnectPlatform={() => handleModalOpen(modalKey)}
                          subscription_type={subscription_type}
                          first_connected_platform={first_connected_platform}
                          label={label}
                        />
                      );
                    })}
                  </div>
                </div>

                {/* Embed Platforms */}
                <div className="platform-section">
                  <h2 className="platform-section-title">
                    <div className="section-title-bar"></div>
                    Embed
                  </h2>
                  <div className="platforms-grid">
                    {embedPlatforms.map(({ name, key, modalKey, label }) => {
                      const isConnected = handleConnected(key);
                      return (
                        <IntegrationPlatforms
                          key={key}
                          platformName={name}
                          isConnected={Boolean(data?.[`${isConnected}`])}
                          isProcessing={Boolean(data?.[`show_${key}`])}
                          isPlatformEnabled={platformToggles[key]}
                          onTogglePlatform={(checked: any) =>
                            handleToggle(key, checked)
                          }
                          onConnectPlatform={() => handleModalOpen(modalKey)}
                          subscription_type={subscription_type}
                          first_connected_platform={first_connected_platform}
                          label={label}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <PlatformModals
        modalState={modalState}
        onClose={handleModalClose}
        userId={user?.id}
        data={data}
        refetch={refetch}
      />
      <Modal
        title={""}
        open={showConfirmation.platformToggles}
        onOk={async () =>
          handleOffPlatformToggle(showConfirmation.platformKey, false)
        }
        okText="Confirm"
        onCancel={handleOffCancel}
        data-testid="turn-off-integration"
      >
        <p className="unlock-text">
          {`Are you sure you want to turn off the ${platformNames}?`}
        </p>
      </Modal>
    </>
  );
}

const PlatformModals = ({
  modalState,
  onClose,
  userId,
  data,
  refetch,
}: any) => {
  const { currentPlatform, isVisible } = modalState;

  const modalMap: any = {
    InstagramModal: InstagramIntegrationModal,
    YoutubeModal: YoutubeIntegrationModal,
    PodcastModal: PodcastIntegrationModal,
    NewsletterModal: NewsletterIntegrationModal,
    TiktokModal: TiktokIntegrationModal,
    PinterestModal: PinterestIntegrationModal,
    TwitterModal: TwitterIntegrationModal,
    FlowdeskModal: FlowdeskIntegrationModal,
    FlowdeskPopupModal: FlowdeskPopupIntegrationModal,
    FilloutModal: FilloutIntegrationModal,
    LinkedInModal: LinkedInModal,
    AmazonModal: AmazonIntegrationModal,
    ShopMyModal: ShopMyIntegrationModal,
    LTKModal: LTKIntegrationModal,
  };

  const SelectedModal = modalMap[currentPlatform];
  const props = {
    [`show${currentPlatform}`]: { isVisible },
  };
  return SelectedModal ? (
    <SelectedModal
      {...props}
      onClose={onClose}
      user_id={userId}
      data={data}
      refetchData={refetch}
    />
  ) : null;
};
