import React, { useEffect, useState } from "react";
import ConnectInstagramStep from "./OnboardingSteps/ConnectInstagramStep";
import ConnectLinkinbioStep from "./OnboardingSteps/ConnectLinkinbioStep";
import ReviewConnectionsStep from "./OnboardingSteps/ReviewConnectionsStep";
import ConnectPlatformStep from "./OnboardingSteps/ConnectPlatformStep";
import ConnectSocialLinksStep from "./OnboardingSteps/ConnectSocialLinksStep";
import UserInfoStep from "./OnboardingSteps/UserInfoStep";
import UsernameSection from "./OnboardingSteps/UsernameSection";
import CompletionStep from "./OnboardingSteps/CompletionStep";
import { supabase } from "../../../config/supabaseClient";
import { IntegratePlatforms } from "../../../Utils/SupabaseServices/OnboardingFlow";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./OnboardingPage.css";

const OnboardingPage = () => {
  const location = useLocation();
  const { sessionId, data } = location.state || {};
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [linkinbioUsername, setLinkinbioUsername] = useState("");
const [planDetails, setPlanDetails] = useState(data);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    Array<{ platform: string; username: string }>
  >([
    {
      platform: "instagram",
      username: instagramUsername,
    },
  ]);
  const handlePlatformToggle = (platformId: any) => {
    setSelectedPlatforms((prev: any) =>
      prev.includes(platformId)
        ? prev.filter((id: any) => id !== platformId)
        : [...prev, platformId]
    );
  };

  const handleNext = async () => {
    try {
      await supabase
        .from("user")
        .update({ onboarding_step: currentStep })
        .eq("username", username);

      // Update URL with username parameter
      const url = new URL(window.location.href);
      url.searchParams.set('username', username);
      window.history.pushState({}, '', url.toString());

      setCurrentStep(currentStep + 1);
    } catch (error) {
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleComplete = async () => {
    handleNext();
    // Get the user data to fetch youtube_channel_id
    const { data: userData } = await supabase
      .from("user")
      .select("youtube_channel_id,podcast_id,podcast_link")
      .eq("username", username)
      .single();

    // Create a new array with modified platform data
    const modifiedPlatforms = selectedPlatforms.map(platform => {
      if (platform.platform === "youtube" && userData?.youtube_channel_id) {
        return {
          ...platform,
          username: userData.youtube_channel_id
        };
      }
      if (platform.platform === "podcast" && userData?.podcast_id) {
        return {
          ...platform,
          username: platform.username + "," + userData.podcast_id + "," + userData.podcast_link
        };
      }
      return platform;
    });
    const { data } = await supabase.from("user").update({ onboarding_step: currentStep }).eq("username", username)
    const url = `https://dev.thesearchbubble.com/create-demo`;

      axios.post(
        url,
        {user: username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    await IntegratePlatforms(modifiedPlatforms, username)
  };

  const fetchUserPlatforms = async () => {
    try {
      const { data: userData } = await supabase
        .from("user")
        .select(`
            instagram_username,
            youtube_username,
            twitter_user,
            tiktok_user,
            linkedin_user,
            newsletter_email,
            newsletter_website_link,
            podcast_link,
            podcast_artist,
            podcast_showname,
            podcast_id,
            linkinbio,
            social_website,
            social_amazon,
            social_shopmy,
            social_ltk
          `)
        .eq("username", username)
        .single();

      if (userData) {
        const platforms = [];

        if (userData.instagram_username) {
          platforms.push({
            platform: "instagram",
            username: userData.instagram_username
          });
          setInstagramUsername(userData.instagram_username);
        }

        if (userData.youtube_username) {
          platforms.push({
            platform: "youtube",
            username: userData.youtube_username
          });
        }

        if (userData.twitter_user) {
          platforms.push({
            platform: "twitter",
            username: userData.twitter_user
          });
        }

        if (userData.tiktok_user) {
          platforms.push({
            platform: "tiktok",
            username: userData.tiktok_user
          });
        }

        if (userData.linkedin_user) {
          platforms.push({
            platform: "linkedin",
            username: userData.linkedin_user
          });
        }

        if (userData.newsletter_email) {
          platforms.push({
            platform: "newsletter",
            username: userData.newsletter_email,
            websiteLink: userData.newsletter_website_link
          });
        }

        if (userData.podcast_link && userData.podcast_artist) {
          platforms.push({
            platform: "podcast",
            username: userData.podcast_showname || userData.podcast_artist,
            showId: userData.podcast_id,
            showUrl: userData.podcast_link
          });
        }
        
        if (userData.linkinbio) {
          platforms.push({
            platform: "linkinbio",
            username: userData.linkinbio
          });
        }

        if (userData.social_website) {
          platforms.push({
            platform: "website",
            username: userData.social_website
          });
        }

        if (userData.social_amazon) {
          platforms.push({
            platform: "amazon",
            username: userData.social_amazon
          });
        }

        if (userData.social_shopmy) {
          platforms.push({
            platform: "shopmy",
            username: userData.social_shopmy
          });
        }

        if (userData.social_ltk) {
          platforms.push({
            platform: "ltk",
            username: userData.social_ltk
          });
        }

        setSelectedPlatforms(platforms);
      }
    } catch (error) {
      console.error("Error fetching user platforms:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add useEffect to check last completed step on mount
  useEffect(() => {
    const checkLastCompletedStep = async () => {
      try {
        // First try to get the step from URL params
        const urlParams = new URLSearchParams(window.location.search);
        const usernameFromUrl = urlParams.get('username');

        if (usernameFromUrl) {
          const { data: userData } = await supabase
            .from("user")
            .select("onboarding_step, username")
            .eq("username", usernameFromUrl)
            .single();

          if (userData) {
            setCurrentStep(userData.onboarding_step + 1);
            setUsername(userData.username);
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    checkLastCompletedStep();
  }, []);

  // Add loading state check in render
  if (isLoading) {
    return (
      // <div className="onboarding-page">
        <div className="instagram-card">
          <div className="loading-state">Loading...</div>
        </div>
      // </div>
    );
  }

  return (
    <div className="onboarding-page">
      {currentStep === 0 && (
        <UserInfoStep
        handleNext={handleNext}
        handleBack={handleBack}
        formData={formData}
        setFormData={setFormData}
        currentStep={currentStep}
        setUsername={setUsername}
        setUsernameError={setUsernameError}
        username={username}
        data={data}
      />
      )}

      {currentStep === 1 && (
        <>
         <UsernameSection
          username={username}
          setUsername={setUsername}
          usernameError={usernameError}
          setUsernameError={setUsernameError}
          handleNext={handleNext}
          currentStep={currentStep}
          formData={formData}
          setFormData={setFormData}
          planDetails={planDetails}
        />
         
        </>
      )}

      {currentStep === 2 && (
        <ConnectInstagramStep
          username={username}
          setUsername={setUsername}
          handleNext={handleNext}
          currentStep={currentStep}
          instagramUsername={instagramUsername}
          setInstagramUsername={setInstagramUsername}
        />
      )}

      {/* {currentStep === 3 && (
        <ConnectLinkinbioStep
          handleNext={handleNext}
          buttonStyle={buttonStyle}
          handleBack={handleBack}
          username={username}
          setUsername={setUsername}
          setLinkinbioUsername={setLinkinbioUsername}
          linkinbioUsername={linkinbioUsername}
          currentStep={currentStep}
        />
      )} */}
      {currentStep === 3 && (
        <ConnectPlatformStep
          username={username}
          setUsername={setUsername}
          handleNext={handleNext}
          handleBack={handleBack}
          setSelectedPlatforms={setSelectedPlatforms}
          selectedPlatforms={selectedPlatforms}
          setInstagramUsername={setInstagramUsername}
          instagramUsername={instagramUsername}
        />
      )}

      {/* {currentStep === 4 && (
            <ConnectSocialLinksStep
              username={username}
              setUsername={setUsername}
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              handleBack={handleBack}
              selectedPlatforms={selectedPlatforms}
              // platforms={platforms}
            />
          )} */}

      {currentStep ===4 && (
        <ReviewConnectionsStep
          username={username}
          setUsername={setUsername}
          handleNext={handleNext}
          handleBack={handleBack}
          selectedPlatforms={selectedPlatforms}
          handleComplete={handleComplete}
          currentStep={currentStep}
          fetchUserPlatforms={fetchUserPlatforms}
        />
      )}

      {currentStep === 5 && (
        <CompletionStep
          username={username}
        />
      )}
    </div>
  );
};

export default OnboardingPage;
