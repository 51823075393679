import Feed from "../Feed/Feed";
import CreatorProfile from "../../CreatorProfile/creatorProfile";
import { useState, useEffect } from "react";
import FeedPosts from "../../skeleton/Views/skeletonComponents/feedPosts";
import Skeleton from "react-loading-skeleton";
import "../../../styles/skeleton.css";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../Utils/SupabaseServices/ContentDatabase";
import AdditionalCarousels from "../../StayUpToDateComponent/StayUpToDateRenderComponent/AdditionalCarousels";
import ContactUs from "../../../Screens/FollowerView/ContactUs";
import JoinButton from "../../JoinButton/JoinButton";
import { IndividualComponentLoader } from "../../StayUpToDateComponent/StayUpToDateIndividuelComponent/IndividualComponentLoader";


const FeedControls = ({
  indexPages,
  userDetails,
  username,
  page,
  name,
  userId,
  data,
  hashtagFilters,
  avgWords,
  liveView,
  StayUpToDateComponentsDataIndividual,
  showSearch,
  showSearchLoading,
  setShowSearchLoading,
  setShowSearch,
  StayUpToDateComponentsLoading,
  StayUpToDateComponents, profileData,
  from
}: any) => {
  // const [showSearch, setShowSearch] = useState(false);
  // const [showSearchLoading, setShowSearchLoading] = useState(false);
  const location = useLocation();
  const [hashtagFilterArray, setHashtagFilterArray] =
    useState<any[]>(hashtagFilters);
  const param = useParams();
  const navigate = useNavigate();
  const [showFeedData, setShowFeedData] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLocalLoading(false); // Set loading to false after some time
    }, 1500); // Adjust time as needed

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  useEffect(() => {
    setShowFeedData(param?.id ? false : true);
  }, [param?.id]);
  // useEffect(() => {
  //   const updateSearchBar = () => {
  //     if (data?.feedControls?.checkedType === "Landing Page") {
  //       const urlSearchParams = new URLSearchParams(window.location.search);
  //       const params = Object.fromEntries(urlSearchParams.entries());

  //       if (
  //         urlSearchParams?.size > 0 &&
  //         (params.hasOwnProperty("term") || params.hasOwnProperty("from"))
  //       ) {
  //         setShowSearch(true);
  //       } else {
  //       }
  //     } else {
  //       setShowSearch(true);
  //     }
  //   };

  //   updateSearchBar();
  // }, [data?.feedControls?.checkedType, location.search]);

  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any,
    platform?: any,
    // type:any,

    isLinks?: any,
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        platform: platform,
        // type:type,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: userId,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, userId);
    if (isLinks === true) {
      track("Redirect to shop link", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: userId,
        title: "Stay Up To Date",
      });
    } else if (isLinks === false) {
      track("Redirect to original post", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: { post_id: postId, media_url: media_url, platform: platform },
        user_id: userId,
        title: "Stay Up To Date",
      });
    }
  }





  const isContactPage = location?.pathname.includes("contact-me");

  function handleEmptyCarousel(list: any) { }

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    if (
      !StayUpToDateComponentsLoading || (StayUpToDateComponents &&
        StayUpToDateComponents.showingComponents &&
        StayUpToDateComponents.showingComponents.length > 0)
    ) {
      const timer = setTimeout(() => {
        setIsButtonVisible(true);
      }, 3000);

      return () => clearTimeout(timer);
    } else {
      setIsButtonVisible(false);
    }
  }, [StayUpToDateComponents]);
  return (
    <>
      <div>

        {showSearchLoading ? (
          <>
            <div className="search-container-skeleton feed-search-skeleton">
              <Skeleton className="search-wrapper-input feed-search-wrapper" />
            </div>
            <FeedPosts />
          </>
        ) : (
          <>
            {((!isContactPage && !param?.id) || !StayUpToDateComponentsLoading) ? (
              <>
                <Feed
                  indexPages={indexPages}
                  isNecSearch={profileData?.userData?.isNecSearch}
                  username={username}
                  page={page}
                  name={name}
                  controls={profileData?.feedControls}
                  activeFilters={data?.activeFilters}
                  highlights={data?.highlights}
                  customCategories={data?.customCategories}
                  userId={userId}
                  hashtagFilterArray={hashtagFilterArray}
                  avgWords={avgWords}
                  showSocials={profileData?.userData?.showSocials}
                  is_nec_present={profileData?.userData.is_nec_present}
                  contentType={profileData?.feedControls?.checkedType}
                  contentPresent={profileData?.userExists}
                  showSocialLinks={profileData?.feedControls.showSocialLinks}
                  search_bar_text={
                    profileData?.search_bar_text
                      ? profileData?.search_bar_text
                      : `Search ${name} Content`
                  }
                  search_bar_tags={userDetails?.search_bar_tags}
                  contentOrder={profileData?.feedControls.contentOrder}
                  isSearchPage={showSearch}
                  brand_color={userDetails?.brand_color}
                  setShowSearch={setShowSearch}
                  isFilloutOn={profileData?.userData?.is_fillout_on}
                  setShowFeedData={setShowFeedData}
                  showFeedData={showFeedData}
                  showSearchbar={profileData?.feedControls.showSearchbar}
                  showSearch={showSearch}
                  setShowSearchLoading={setShowSearchLoading}
                  StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
                  StayUpToDateComponents={StayUpToDateComponents}
                  from={from}
                />
                {(!showFeedData) && (
                  <div id="app-wrapper" className="inex-1">
                    <div className="uptodate-wrapper">


                      <IndividualComponentLoader
                        componentId={param?.id}
                        user_id={userId}
                        username={username}
                        brand_color={userDetails?.brand_color}
                        handleRedirect={handleRedirect}
                        handleEmptyCarousel={handleEmptyCarousel}
                        data={StayUpToDateComponentsDataIndividual}

                      />

                      {/* {!isLocalLoading &&  */}

                      {isButtonVisible && (
                        <>
                          {param?.id && <AdditionalCarousels
                            components={StayUpToDateComponents?.data}
                            username={username}
                            componentId={param?.id}
                            handleEmptyCarousel={handleEmptyCarousel}
                            isLocalLoading={isLocalLoading}
                            user_id={userId}
                            StayUpToDateComponentsLoading={StayUpToDateComponentsLoading}
                          />}

                          <JoinButton

                            username={username}

                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {isContactPage ? (<>
                  <ContactUs userId={userId} username={username} />
                </>

                ) : (
                  <Feed
                    indexPages={indexPages}
                    isNecSearch={profileData?.userData?.isNecSearch}
                    username={username}
                    page={page}
                    name={name}
                    controls={profileData?.feedControls}
                    activeFilters={data?.activeFilters}
                    highlights={data?.highlights}
                    customCategories={data?.customCategories}
                    userId={userId}
                    hashtagFilterArray={hashtagFilterArray}
                    avgWords={avgWords}
                    showSocials={profileData?.userData?.showSocials}
                    is_nec_present={profileData?.userData.is_nec_present}
                    contentType={profileData?.feedControls?.checkedType}
                    contentPresent={profileData?.userExists}
                    showSocialLinks={profileData?.feedControls.showSocialLinks}
                    search_bar_text={
                      profileData?.search_bar_text
                        ? profileData?.search_bar_text
                        : `Search ${name} Content`
                    }
                    contentOrder={profileData?.feedControls.contentOrder}
                    isSearchPage={showSearch}
                    brand_color={userDetails?.brand_color}
                    setShowSearch={setShowSearch}
                    isFilloutOn={profileData?.userData?.is_fillout_on}
                    setShowFeedData={setShowFeedData}
                    showFeedData={showFeedData}
                    showSearchbar={profileData?.feedControls.showSearchbar}
                    showSearch={showSearch}
                    setShowSearchLoading={setShowSearchLoading}
                    from={from}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FeedControls;
