import React, { useEffect, useState } from "react";
import SocialLinks from "../../../../components/SocialLinks/SocialLinks";
import ActiveSocialLinks from "../../../../components/SocialLinks/ActiveSocialLinks";
import { Button } from "antd";
import { useLoadControls } from "../../../../Utils/customHooks/useLoadControls";
import { ColorModal } from "../../../../components/ColorModal/ColorModal";

const SocialLinksComponent = (props: any) => {
  const {
    defaultColor,
    defaultTextColor,
    username,
    user_id,
    defaultEdit,
    setDefaultEdit,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textColor, setTextColor] = useState(defaultTextColor);
  const [textColorLoading, setTextColorLoading] = useState<any>(false);
  const [localDefaultColor, setLocalDefaultColor] = useState("");
  const [localDefaultTextColor, setLocalDefaultTextColor] = useState("");

  useEffect(() => {
    setLocalDefaultColor(defaultColor);
    setLocalDefaultTextColor(defaultTextColor);
  }, [defaultColor, defaultTextColor]);

  const handleColor = (bgColor: string, textColor: string) => {
    setTextColorLoading(true);
    setTextColor(textColor);
    setLocalDefaultTextColor(textColor);
    setTimeout(() => {
      setTextColorLoading(false);
    }, 500);
  };
  const page = "follower";

  const {
    isLoading: controlsLoading,
    data: controls,
    refetch: refetchControls,
  }: any = useLoadControls(user_id || "", page);
  return (
    <>
      {/* <div className="account-values-profiles"> */}
          {/* <Button
            size="small"
            className="solid-bg-button"
            onClick={(prev) => setIsModalOpen(true)}
          >
            Configure
          </Button> */}
        {/* <ActiveSocialLinks
          textColor={textColor}
          textColorLoading={textColorLoading}
        /> */}
      {/* </div> */}

      {/* <div className="account-values-profiles">
        <div className="account-social-logins-heading">Available Links</div>
        <SocialLinks
          defaultEdit={defaultEdit}
          setDefaultEdit={setDefaultEdit}
        />
      </div> */}
      <ColorModal
        fromScreen="socialLinksScreen"
        ModalTitle="Social Icons"
        isColorModalOpen={isModalOpen}
        setIsColorModalOpen={setIsModalOpen}
        handleColor={handleColor}
        username={username}
        user_id={user_id}
        defaultColor={localDefaultColor}
        defaultTextColor={localDefaultTextColor}
        refetch={refetchControls}
        initialColor={defaultColor}
        initialTextColor={defaultTextColor}
        setLocalDefaultColor={setLocalDefaultColor}
        setLocalDefaultTextColor={setLocalDefaultTextColor}
      />
    </>
  );
};



export default SocialLinksComponent;
