import React from 'react'
import { FaArrowLeft } from 'react-icons/fa';

export const ModalBackButton = (props: any) => {
  const { handlePrevious, title, description } = props;
  return (
    <>
    <button
    onClick={handlePrevious}
    style={{
      background: "none",
      border: "none",
      cursor: "pointer",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#333",
      transition: "color 0.2s",
      borderRadius: "4px",
      position: "absolute",
      top: "12px",
      left: "20px",
      zIndex: 1001,
    }}
    onMouseOver={(e) => (e.currentTarget.style.color = "#000")}
    onMouseOut={(e) => (e.currentTarget.style.color = "#333")}
  >
    <FaArrowLeft size={16} />
  </button>

 {title && <h2
    className="modal-title"
    style={{ marginTop: "25px", textAlign: "center" }}
  >
    {title}
      </h2>
      }
      {description && (
        <p className="modal-subtitle">{description}</p>
      )}
    </>
  )
}
