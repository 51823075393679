import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../config/supabaseClient";
import { Result, Button, Typography, Space } from "antd";
import "../components/Footer/Footer.css";

const { Title, Paragraph, Text } = Typography;

function PaymentCanceled() {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    const getUserDetails = async () => {
      const { data: userData } = await supabase.auth.getUser();
      if (userData?.user) {
        const { data: userDetails } = await supabase
          .from("user")
          .select("username")
          .eq("user_id", userData.user.id)
          .single();

        if (userDetails) {
          setUsername(userDetails.username);
        }
      }
    };

    getUserDetails();
  }, [location]);

  const handleGoToDashboard = () => {
    if (username) {
      navigate(`/dashboard/${username}`);
    } else {
      navigate("/dashboard");
    }
  };



  // Custom styles to override the default icon color
  const customStyles = `
    .payment-canceled-icon .anticon {
      color: #db615e !important; /* Light red color */
    }
  `;

  return (
    <div className="auth-container">
      <div className="navbar_container">
        <a href="/" className="navbar_logo-link w-nav-brand" aria-label="home">
          <div className="logo-text">
            <img
              className="homepage-icon"
              src="/assets/output-onlinepngtools-edited.png"
              alt="thesearchbubble logo"
            />
          </div>
        </a>
      </div>

      <style>{customStyles}</style>

      <div
        style={{
          maxHeight: "85vh",
          margin: "0 auto",
          padding: "0px 20px",
          textAlign: "center",
          borderRadius: "8px",
          color: "white",
        }}
      >
        <Result
          status="info"
          title={<span style={{ color: "white" }}>Payment Canceled</span>}
          subTitle={<span style={{ color: "white" }}>Your subscription payment was canceled. No charges have been made.</span>}
          className="payment-canceled-icon"
          style={{
            background: "#27282b",
            padding: "30px",
            borderRadius: "8px",
            color: "white",
          }}
          extra={[
            <a key="try-again" href="/subscribe">
              <button
                style={{
                  padding: "10px 20px",
                  background: "#1890ff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                Try Again
              </button>
            </a>,
            <a key="contact" href="mailto:support@thesearchbubble.com">
              <button
                style={{
                  padding: "10px 20px",
                  background: "white",
                  color: "#666",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Contact Support
              </button>
            </a>,
          ]}
        />
      </div>
    </div>
  );
}

export default PaymentCanceled;
