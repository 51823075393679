import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadPages(
  userIds: any,
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any,
  showSocials: any
) {
  async function getCount() {
    let feedCount;
    let postIds: any = [];
    let productFilterItems: any = [];

    if (filter) {
      const elementsCount = 5000;
      const connectingWords = StaticVariables().connectingWords;

      let words = filter.split(" ");
      let singleWords: any = [];
      if (
        words.length === 1 &&
        !StaticVariables().SEARCH_EXCEPTIONS.includes(filter)
      ) {
        const suffixRegex = /(s)$/i;
        if (suffixRegex.test(words[0])) {
          words = [`${words[0].replace(suffixRegex, "")}`];
        }
      }

      if (words.includes("shop") || words.includes("buy")) {

        words = words.filter((word: any) => word !== "shop" && word !== "buy");
      }

      const filteredWords = words.filter(
        (word: any) => !connectingWords.includes(word.toLowerCase())
      );
      if (words.length <= 2) {
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const { data: countResult, error: countError }: any =
          await supabase.rpc(
            // "semantic_ssearchcountftspostiddi", 
            "semantic_search_count_fts_post_id_content",
            {
            query_string: pgQuery,
            similarity_threshold: StaticVariables().THRESHOLD,
            match_count: elementsCount,
            userid: userIds,
            username: usernames,
            socials: showSocials,
            single: singleWords[0] || null,
            plural: singleWords[1] || null,
            index_type: index,
          });

        let resCount = countResult.length;
     
        postIds = countResult.map((item: any) => item.post_id);
        feedCount = resCount;
        productFilterItems = countResult
      } else {
        feedCount = 0;
      }
    } else {
      if (index === "active,ad") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardadscontenttcount",
          {
            userid: userIds,

            user_name: usernames,
            index_type: index,
          }
        );
        feedCount = count?.[0].count;
      } else {
        const { data: content, error: contentError } = await supabase.rpc(
          // "get_generalfffeedcounti",
          "get_general_feed_count_content",
          {
            userid: userIds,
            filter: StaticVariables().TYPES,
            query_str: "",
            user_name: usernames,
            socials: showSocials,
            index_type: index,
          }
        );
        feedCount = content[0].count;
        productFilterItems = content[0].posts;
      }
    }
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
      productFilterItems: productFilterItems || null,
    };
  }

  return useQuery(
    ["paginatedPosts", filter, userIds, index, postsPerPage],
    getCount,
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: Boolean(showSocials)

    },
  );
}
