import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export default function useUpdateStayUpToDate() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    oldTitle,
    newTitle,
    componentId,
    pageIndex,
    index,
    user_id,
    from
  }: {
    oldTitle: any;
    newTitle: any;
    componentId: any;
    pageIndex: number;
    index: number;
    user_id: any;
    from?: any;
  }) => {
    let errorLink;
    let tableName = from === "embed-search" ? "embed_components" : "stayuptodate_components"
    const { error } = await supabase
      .from(tableName)
      .update({ name: newTitle })
      .eq("name", oldTitle)
      .eq("id", componentId)
      .eq("user_id", `${user_id}`);

    errorLink = error;
    if (errorLink) {
      throw new Error(errorLink.message);
    } else {
      return true;
    }
  };

  return useMutation(submitTag, {
    onMutate: async ({ user_id, from }) => {
      let tableName = from === "embed-search" ? "embed_components" : "stayuptodate_components"
      await queryClient.cancelQueries([tableName]);
      const previousData = queryClient.getQueryData([
        tableName,
        user_id,
      ]);
      
      return { previousData };
    },
    onError: (_error, {from}, context,) => {
      let tableName = from === "embed-search" ? "embed_components" : "stayuptodate_components"
      queryClient.setQueryData(
        [tableName],
        context?.previousData
      );
    },
    onSettled: (_data, _error, { pageIndex, from }) => {
      let tableName = from === "embed-search" ? "embed_components" : "stayuptodate_components"
      queryClient.invalidateQueries([tableName], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
