import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import CreatorLandingProfile from "../../../../components/CreatorProfile/CreatorLandingProfile";
import { Spin, Upload, Avatar } from "antd";
import { GrFormEdit } from "react-icons/gr";
import EditBio from "../../../DashboardView/StayUpToDate/StayUpToDateComponents/EditTemplates/EditBio";
import { useLoadUserInfo } from "../Utils/useLoadUserInfo";
import { UserOutlined } from "@ant-design/icons";
import { updateProfileNameDescription } from "../../../../Utils/SupabaseServices/UserDatabase";
import {
  AiOutlineEdit,
  AiOutlineInstagram,
  AiOutlineLink,
  AiOutlineLinkedin,
  AiOutlineMail,
  AiOutlineTwitter,
  AiOutlineYoutube,
  AiOutlineUser,
} from "react-icons/ai";
import { FaPodcast, FaTiktok } from "react-icons/fa";
import { SiAmazon } from "react-icons/si";
import { TbWorld } from "react-icons/tb";
import { BiLink } from "react-icons/bi";
import { MdShoppingBag } from "react-icons/md";
import { BsHandbag } from "react-icons/bs";

const ReviewConnectionsStep = (props: any) => {
  const {
    username,
    setUsername,
    handleNext,
    buttonStyle,
    handleBack,
    selectedPlatforms,
    // platforms,
    handleComplete,
    // imageUrl,
    // setImageUrl,
    isUploading,
    fileList,
    setFileList,
    isUserDashboard,
    uploadProps,
    beforeUpload,
    fetchUserPlatforms,
  } = props;
  // let user_name = "akriti_3109";
  // const name="test";
  // const description="test";

  const [expandedPlatform, setExpandedPlatform] = React.useState<string>("");

  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState({ name: name, description: description });
  const toggleExpand = (platformId: string) => {
    setExpandedPlatform((prev) => (prev === platformId ? "" : platformId));
  };
  const handleOpenModal = () => {
    // setEditType(type);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = async (newValue: {
    name: string;
    description: string;
  }) => {
    setValue(newValue);

    await updateProfileNameDescription(username, newValue);

    // props?.refetch();
    // props?.refetchUser();
  };
  const { data, error,refetch } = useLoadUserInfo(username);
  useEffect(() => {
    if (data) {
      setName(data.name);
      setImageUrl(data.pictureUrl);
      setDescription(data.description);

      setValue({ name: data.name, description: data.description });
    }
    props?.fetchUserPlatforms();
  }, [data]);
  const convertToClickable = (text: string) => {
    const urlPattern = /(https?:\/\/\S+)/g;

    const phonePattern = /\b\d{10}\b/g;

    const processedPhoneNumbers = new Set<string>();

    const textWithUrls = text?.replace(
      urlPattern,
      (url, index) =>
        `<a key=${index} href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    const textWithClickablePhoneNumbers = textWithUrls?.replace(
      phonePattern,
      (phoneNumber, index) => {
        if (!processedPhoneNumbers.has(phoneNumber)) {
          processedPhoneNumbers.add(phoneNumber);
          return `<a key=${index} href="tel:${phoneNumber}">${phoneNumber}</a>`;
        } else {
          return phoneNumber;
        }
      }
    );

    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: textWithClickablePhoneNumbers },
    });
  };
 

  const platformIcons = {
    instagram: <AiOutlineInstagram className="platform-icon" />,
    youtube: <AiOutlineYoutube className="platform-icon" />,
    twitter: <AiOutlineTwitter className="platform-icon" />,
    tiktok: <FaTiktok className="platform-icon" />,
    linkedin: <AiOutlineLinkedin className="platform-icon" />,
    newsletter: <AiOutlineMail className="platform-icon" />,
    podcast: <FaPodcast className="platform-icon" />,
    website: <TbWorld className="platform-icon" />,
    linkinbio: <BiLink className="platform-icon" />,
    amazon: <SiAmazon className="platform-icon" />,
    shopmy: <MdShoppingBag className="platform-icon" />,
    ltk: <BsHandbag className="platform-icon" />,
  };

  // Group platforms by category
  const getPlatformsByCategory = () => {
    const socialPlatforms = selectedPlatforms.filter(
      (platform: any) => 
        platform.platform === "instagram" || 
        platform.platform === "youtube" || 
        platform.platform === "twitter" || 
        platform.platform === "tiktok" || 
        platform.platform === "linkedin"
    );

    const contentPlatforms = selectedPlatforms.filter(
      (platform: any) => 
        platform.platform === "newsletter" || 
        platform.platform === "podcast" || 
        platform.platform === "website"
    );

    const linkinbioPlatforms = selectedPlatforms.filter(
      (platform: any) => platform.platform === "linkinbio"
    );

    const affiliatedPlatforms = selectedPlatforms.filter(
      (platform: any) => 
        platform.platform === "amazon" || 
        platform.platform === "shopmy" || 
        platform.platform === "ltk"
    );

    return [
      { title: "Content Platforms", platforms: socialPlatforms },
      { title: "Link-in-Bio", platforms: linkinbioPlatforms },
      { title: "Affiliate Platforms", platforms: affiliatedPlatforms }
    ].filter(category => category.platforms.length > 0);
  };

  const categories = getPlatformsByCategory();

  // Platform item component to render each platform
  const PlatformItem = ({ platform, platformIcons }: any) => {
    // URL-based platforms don't need @ symbol
    const urlBasedPlatforms = ["website", "linkinbio", "amazon", "shopmy", "ltk", "newsletter"];
    const isUrlBased = urlBasedPlatforms.includes(platform.platform);
    const isPodcast = platform.platform === "podcast";

    return (
      <div className="platform-item review-platform-item">
        <div className="platform-info">
          {platformIcons[platform.platform]}
          <span className="platform-name">
            {platform.platform.charAt(0).toUpperCase() + platform.platform.slice(1)}
          </span>
          <span className="platform-username">
            {isUrlBased || isPodcast || platform.username.includes('@')
              ? platform.username
              : `@${platform.username}`
            }
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="instagram-container">
      <div
        className="instagram-card review-profile-card"
      >
        <div className="card-header">
          <div className="instagram-icon-wrapper" style={{ background: 'linear-gradient(45deg, #4c5afd, #6e78ff)' }}>
            <AiOutlineUser className="instagram-icon" />
          </div>
          <h2 className="card-title">Review Your Profile</h2>
          <p className="card-description">
            Review your profile and make sure it is correct
          </p>
        </div>
        <div
          className="creator-profile-wrapper"
          style={{ textAlign: "center" }}
        >
          <div
            className="grid-display"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="profile-image-container">
              {!isUploading ? (
                <>
                  {imageUrl ? (
                    <div className="profile-image">
                      <img
                        src={imageUrl || "/assets/userhead.png"}
                        className="creator-profile-img"
                        alt={name}
                        id="creator-profile-img"
                      />
                      {/* {isUserDashboard && ( */}
                      <Upload
                        {...uploadProps}
                        showUploadList={false}
                        fileList={fileList || []}
                        beforeUpload={beforeUpload}
                      >
                        <button className="edit-profile" type="button">
                          <GrFormEdit />
                        </button>
                      </Upload>
                      {/* )} */}
                    </div>
                  ) : (
                    <Avatar size={100} icon={<UserOutlined />} />
                  )}
                </>
              ) : (
                <div className="profile-image">
                  <div className="creator-profile-img-loading-div">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>

          <fieldset className="userdashboard-profile">
            <legend>
              {" "}
              {name}
              {/* <span className="edit-name" onClick={handleOpenModal}>
                <GrFormEdit />
              </span> */}
              <button className="edit-button" onClick={handleOpenModal}>
                <AiOutlineEdit />
              </button>
            </legend>
            <p
              className="creator-dashboard-profile-description"
              style={{ whiteSpace: "pre-line" }}
            >
              {convertToClickable(description)}
            </p>
          </fieldset>
        </div>
        <div className="connected-platforms">
          <h3 className="section-title">Connected Platforms</h3>
          
          {/* Desktop view */}
          <div className="platforms-desktop-view">
            {categories.map((category, index) => (
              <div key={index} className="platform-category-review">
                <div className="category-header">
                  <h4 className="category-title-review">
                    {category.title}
                  </h4>
                </div>
                
                <div className="platform-items-container">
                  {category.platforms.map((platform: any) => (
                    <PlatformItem key={platform.platform} platform={platform} platformIcons={platformIcons} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          
          {selectedPlatforms.length === 0 && (
            <p style={{ textAlign: "center", color: "#6B7280", fontStyle: "italic" }}>
              No platforms connected yet.
            </p>
          )}
        </div>
        <EditBio
          visible={isModalVisible}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          initialValue={value}
          setName={setName}
          setDescription={setDescription}
          refetch={refetch}
          from="review"
        />

        {/* Add the navigation buttons */}
        <div className="action-buttons">
          <button
            type="button"
            onClick={handleBack}
            className="back-button"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleComplete}
            className="next-button"
          >
            Complete Setup
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewConnectionsStep;
