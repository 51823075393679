import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import AuthTerm from "../components/AuthTerm/authTerm";
import "../components/Footer/Footer.css";
import track, { TRACKING_TYPES } from "./SupabaseServices/Track";

const stripePromise = loadStripe(
  "pk_test_51MavLUHoyGmWWOpVfkIMtmMb5oGGXCMNyg5j9CJgx1FAxnv54UUhLsazs4lyVK7c79u07lVbKDdtxoOXslGlm2r900TiOnwzCj"
);

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + "/success",
      },
    });

    if (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
}

function SubscribeTest() {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState({
    complete: false,
    processing: false,
    error: "",
    sessionId: null,
  });

  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const utmSource = queryParams.get("utm_source");

  useEffect(() => {
    if (utmSource) {
      track(location.pathname, TRACKING_TYPES.PAGE, {
        pathname: location.pathname,
        details: { source: utmSource },
      });
    } else {
      track(location.pathname, TRACKING_TYPES.PAGE, {
        pathname: location.pathname,
      });
    }
  }, [location.pathname, utmSource]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (paymentStatus.processing || paymentStatus.complete) {
      return;
    }

    if (
      document.querySelector(
        'script[src="https://js.stripe.com/v3/pricing-table.js"]'
      )
    ) {
      loadPricingTable();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    script.onload = () => {
      loadPricingTable();
    };

    script.onerror = (error) => {
      console.error("Error loading Stripe pricing table script:", error);
    };

    document.body.appendChild(script);

    function loadPricingTable() {
      if (!isLoaded) {
        setIsLoaded(true);

        setTimeout(() => {
          const container = document.querySelector(".stripe-table");
          if (container) {
            while (container.firstChild) {
              container.removeChild(container.firstChild);
            }

            if (customElements.get("stripe-pricing-table")) {
              const pricingTable = document.createElement(
                "stripe-pricing-table"
              );
              pricingTable.setAttribute(
                "pricing-table-id",
                "prctbl_1NodHFHoyGmWWOpV4R5P2NHb"
              );
              pricingTable.setAttribute(
                "publishable-key",
                "pk_live_51MavLUHoyGmWWOpVWG8FWyGVNA5I27zPPmBWBy9rr9vbZMMqYYHCh8RsuQQp5YLdzgkMrpQDHhuXRlJyLIELVZJy00DYpetFRN"
              );

              // Add success URL for redirect after payment
              

              container.appendChild(pricingTable);
            } else {
              console.error(
                "stripe-pricing-table custom element not defined yet"
              );

              setTimeout(loadPricingTable, 500);
            }
          } else {
            console.error("Container with class 'stripe-table' not found");
          }
        }, 0);
      }
    }

    return () => {
      setIsLoaded(false);
    };
  }, [isLoaded, paymentStatus]);

  return (
    <div className="auth-container">
      <div className="navbar_container">
        <a href="/" className="navbar_logo-link w-nav-brand" aria-label="home">
          <div className="logo-text">
            <img
              className="homepage-icon"
              src="/assets/output-onlinepngtools-edited.png"
              alt="thesearchbubble logo"
            />
          </div>
        </a>
        <nav className="navbar_menu w-nav-menu">
          <div className="navbar_menu-link-wrapper">
            <a
              href="https://calendly.com/searchbubble/demo"
              target="_blank"
              className="navbar_link w-nav-link sign-up"
            >
              Book a Demo
            </a>
          </div>
        </nav>
      </div>

      {paymentStatus.complete ? (
        <div
          className="payment-success"
          style={{ textAlign: "center", padding: "40px 20px" }}
        >
          <h2>Payment Complete!</h2>
          <p>
            Thank you for your subscription. You're being redirected to the
            success page...
          </p>
        </div>
      ) : paymentStatus.processing ? (
        <div
          className="payment-processing"
          style={{ textAlign: "center", padding: "40px 20px" }}
        >
          <h2>Verifying Payment...</h2>
          <p>Please wait while we verify your payment.</p>
        </div>
      ) : paymentStatus.error ? (
        <div
          className="payment-error"
          style={{ textAlign: "center", padding: "40px 20px" }}
        >
          <h2>Payment Error</h2>
          <p>{paymentStatus.error}</p>
          <button
            onClick={() =>
              setPaymentStatus({
                complete: false,
                processing: false,
                error: "",
                sessionId: null,
              })
            }
            style={{
              padding: "10px 20px",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            Try Again
          </button>
        </div>
      ) : (
        <div
          className="stripe-table"
          style={{ width: "100%", minHeight: "500px", margin: "20px 0" }}
        ></div>
      )}

      <div>
        <AuthTerm />
      </div>
    </div>
  );
}

export default SubscribeTest;
