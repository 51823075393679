import React from "react";
import PlatformCard from "./PlatformCard";

const PlatformCategory = ({
  category,
  selectedPlatforms,
  expandedPlatform,
  toggleExpand,
  handlePlatformValues,
  platformError,
  isInitialLoad,
  isLoading,
  verifyingPlatforms,
  getDisplayUsername,
}: any) => {
  return (
    <div key={category.title} className="platform-category">
      <h3 className="category-title">{category.title}</h3>
      <div className="platforms-container">
        {category.platforms.map((platform: any) => {
          const isSelected = selectedPlatforms.some(
            (item: any) => item.platform === platform.id
          );
          const isExpanded = expandedPlatform === platform.id;
          const displayUsername = getDisplayUsername(platform.id);
          
          return (
            <PlatformCard
              key={platform.id}
              platform={platform}
              isSelected={isSelected}
              isExpanded={isExpanded}
              displayUsername={displayUsername}
              toggleExpand={toggleExpand}
              handlePlatformValues={handlePlatformValues}
              platformError={platformError}
              isInitialLoad={isInitialLoad}
              isLoading={isLoading}
              verifyingPlatforms={verifyingPlatforms}
              selectedPlatforms={selectedPlatforms}
              expandedPlatform={expandedPlatform}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PlatformCategory; 