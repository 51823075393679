import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineMail,
  AiOutlineLinkedin,
  AiOutlineLink,
  AiOutlineAmazon,
} from "react-icons/ai";
import { FaTiktok, FaPodcast } from "react-icons/fa";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { StaticCompnents } from "../../../../../Utils/StaticComponent";
import { PlatformCategory } from "./PlatformTypes";

const PlatformConfigGenerator = ({
  instagramUsername,
  setInstagramUsername,
  youtubeUsername,
  setYoutubeUsername,
  twitterUsername,
  setTwitterUsername,
  tiktokUsername,
  setTiktokUsername,
  linkedinProfileUrl,
  setLinkedinProfileUrl,
  newsletterEmail,
  setNewsletterEmail,
  newsletterWebsiteLink,
  setNewsletterWebsiteLink,
  podcastShowName,
  setPodcastShowName,
  podcastShowUrl,
  setPodcastShowUrl,
  websiteLink,
  setWebsiteLink,
  linkinBioLink,
  setLinkinBioLink,
  amazonLink,
  setAmazonLink,
  shopmyLink,
  setShopmyLink,
  ltkLink,
  setLtkLink,
}: any): PlatformCategory[] => {
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
  };
  const ShopMyIcon = platformIcons["ShopMy"];
  const LTKIcon = platformIcons["LTK"];

  const platformCategories: PlatformCategory[] = [
    {
      title: "Content Platforms",
      platforms: [
        {
          id: "instagram",
          name: "Instagram",
          icon: <AiOutlineInstagram className="platform-icon" />,
          inputValue: instagramUsername,
          setInputValue: setInstagramUsername,
        },
        {
          id: "youtube",
          name: "YouTube",
          icon: <AiOutlineYoutube className="platform-icon" />,
          inputValue: youtubeUsername,
          setInputValue: setYoutubeUsername,
        },
        {
          id: "twitter",
          name: "X",
          icon: <span className="platform-icon">X</span>,
          inputValue: twitterUsername,
          setInputValue: setTwitterUsername,
        },
        {
          id: "tiktok",
          name: "TikTok",
          icon: <FaTiktok className="platform-icon" />,
          inputValue: tiktokUsername,
          setInputValue: setTiktokUsername,
        },
        {
          id: "linkedin",
          name: "LinkedIn",
          icon: <AiOutlineLinkedin className="platform-icon" />,
          inputValue: linkedinProfileUrl,
          setInputValue: setLinkedinProfileUrl,
        },
        {
          id: "newsletter",
          name: "Newsletter",
          icon: <AiOutlineMail className="platform-icon" />,
          inputValue: newsletterEmail,
          setInputValue: setNewsletterEmail,
          websiteLink: {
            value: newsletterWebsiteLink,
            setValue: setNewsletterWebsiteLink,
            placeholder: "Enter newsletter subscription URL",
            label: "Subscription URL",
          },
        },
        {
          id: "podcast",
          name: "Podcast",
          icon: <FaPodcast className="platform-icon" />,
          inputValue: podcastShowName,
          setInputValue: setPodcastShowName,
          secondaryInput: {
            value: podcastShowUrl,
            setValue: setPodcastShowUrl,
            placeholder: "Enter Podcast Show URL",
            label: "Show URL",
          },
        },
      ],
    },
    /*
    {
      title: "Content Platforms",
      platforms: [
        {
          id: "website",
          name: "Website",
          icon: <HiOutlineGlobeAlt className="platform-icon" />,
          inputValue: websiteLink,
          setInputValue: setWebsiteLink,
        },
      ],
    },
    {
      title: "LinkinBio",
      platforms: [
        {
          id: "linkinbio",
          name: "LinkinBio",
          icon: <AiOutlineLink className="platform-icon" />,
          inputValue: linkinBioLink,
          setInputValue: setLinkinBioLink,
        },
      ],
    },
    */
    {
      title: "Affiliate Platforms",
      platforms: [
        {
          id: "amazon",
          name: "Amazon",
          icon: <AiOutlineAmazon className="platform-icon" />,
          inputValue: amazonLink,
          setInputValue: setAmazonLink,
        },
        {
          id: "shopmy",
          name: "ShopMy",
          icon: (
            <span style={{ display: "inline-flex", marginRight: "6px" }} className="platform-icon">
              <ShopMyIcon className="platform-icon" />
            </span>
          ),
          inputValue: shopmyLink,
          setInputValue: setShopmyLink,
        },
        {
          id: "ltk",
          name: "LTK",
          icon: <LTKIcon className="platform-icon" />,
          inputValue: ltkLink,
          setInputValue: setLtkLink,
        },
      ],
    },
  ];

  return platformCategories;
};

export default PlatformConfigGenerator; 