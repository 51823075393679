import React, { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import { Carousel, Input, Pagination, Tag } from "antd";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import SearchInput from "../../../../components/SearchInput/SearchInput";
// import "./FeedCheckboxComponent.css";
import "../../../../styles/StayUptoDateCSS/FeedCheckboxComponent.css";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselOrderControl from "../../../../components/CarouselControls/CarouselOrderControl";
import { useLoadQueryBasedFeedCount } from "./useLoadQueryBasedFeedCount";
import { useLoadQueryBasedFeed } from "./useLoadQueryBasedFeed";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";
import CarouselControlWrapper from "../../../../components/CarouselControls/CarouselControlWrapper";
import CarouselSearchbar from "../../../../components/CarouselControls/CarouselSearchbar";
import { updateNameValues } from "../../../../Utils/StayUpToDate/RenderComponent";
import { debounce } from "lodash";
import CarouselFilterControl from "../../../../components/CarouselControls/CarouselFilterControl";
import EditableText from "../../../../components/EditModalComponents.tsx/EditableText";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";

const POSTS_PER_PAGE = 10;
const contentOrder = "Default";

const QueryFeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    type,
    order,
    setOrder,
    shape,
    setShape,
    isSoundOn,
    setIsSoundOn,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    activeFilter,
    setActiveFilter,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;
  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : StaticVariables().PLATFORMS;
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    setPostIds(postIds);
    if (defaultQuery) {
      setQuery(defaultQuery);
    }
  }, [postIds, defaultQuery]);

  const debouncedQueryUpdate = useCallback(
    debounce((value: string) => {
      setDebouncedQuery(value);
      setQuery(value);
      setDefaultQuery(value);
      setCurrentPage(1);
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    debouncedQueryUpdate(searchTerm);
    return () => debouncedQueryUpdate.cancel();;
  }, [searchTerm]);

  const { data: pages }: any = useLoadQueryBasedFeedCount(
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    order
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryBasedFeed(
    currentPage,
    username,
    user_id,
    debouncedQuery,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    order
  );

  function handleFeed(postIds: any) {
    setPostIds(postIds || pages?.postIds);
    props.handlePostIds(postIds || pages?.postIds);
  }
  const [tags, setTags] = useState<string[]>([query]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    const elem: any = document.getElementById("search-feed");
    elem.value = "";
    setQuery("");
    refetch();
  };


  return (
    <>
      <div className="margin-table">
      <label className="bold-label mt-4">Preview</label>

        <AddedContentTable
          post_ids={pages?.postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          componentName={componentName}
          handleFeed={handleFeed}
          query={query}
          refetch={refetch}
          isShopLinks={isShopLinks}
          type={type}
          pages={pages}
          order={order}
          shape={shape}
          isSoundOn={isSoundOn}
          edit={edit}
          setEdit={setEdit}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          activeFilter={activeFilter}
          name={name}
          setName={setName}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
              <div className="carousel-controls-wrapper">
              <div className="addedContent">
              <Title level={3}>Carosuel Control</Title>
        {type === "links" &&
          <CarouselFilterControl
            type={type}
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter.toLowerCase()}
            setCurrentPage={() => { }}
            username={username}
            user_id={user_id}
            query={query}
            POSTS_PER_PAGE={POSTS_PER_PAGE}
            showSocials={showSocials}
            isShopLinks={isShopLinks}
            order={order}
            componentName={componentName}
          />}

        <CarouselControlWrapper
          showShape={true}
          showSound={true}
          showFilter={false}
          showOrder={true}
          shape={shape}
          setShape={setShape}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          type={type}
          setCurrentPage={setCurrentPage}
          order={order}
          setOrder={setOrder}
        />
        </div>
        </div>
        <SearchInput
          placeholder={
            isShopLinks
              ? "Search all shoppable content and products"
              : "Search content"
          }
          id="search-feed"
          value={searchTerm}
          onChange={(e: any) => {
            e.preventDefault();
            const value = e.target.value;
            setSearchTerm(value);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setSearchTerm("");
                setDebouncedQuery("");
                props.handleQuery("");
                return;
              }
              elem.blur();
              setSearchTerm(searchVal);
              props.handleQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(searchTerm)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setSearchTerm(searchVal);
            setDebouncedQuery(searchVal);

            props.handleQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setSearchTerm("");
            setDebouncedQuery("");

            props.handleQuery("");

            refetch();
            setCurrentPage(1);
          }}
        />

        {query && (
          <Tag
            // key={tag}
            closable
            className="tag-style"
            id="querry-tags"
            onClose={() => handleClose(query)}
          >
            <span onDoubleClick={(e) => { }}>{query}</span>
          </Tag>
        )}
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={pages?.postIds}
        query={query}
        isLoading={isLoading}
        handleQuery={props.handleQuery}
        isShopLinks={isShopLinks}
        type={type}
      />

      {/* Render the Pagination component */}
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    query,
    isShopLinks,
    type,
    order,
    shape,
    isSoundOn,
    // type,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    activeFilter,
    name,
    setName,
    errorMsg,
    setErrorMsg,
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids]);
  // const isShopLinks = false


  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : StaticVariables().PLATFORMS;






  const contentOrder = "Default";
  const {
    isLoading,
    data: queryData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,
    showSocials,
    contentOrder,
    isShopLinks,
    "",
    componentName,
    order
  );

  // Separate the filtering logic in useMemo
  const filteredData = useMemo(() => {
    const showSocial =
      type === "content"
        ? StaticVariables().CONTENT_PLATFORMS
        : activeFilter === "both"
          ? StaticVariables().PLATFORMS
          : activeFilter === "content"
            ? StaticVariables().CONTENT_PLATFORMS
            : activeFilter === "products"
              ? StaticVariables().PRODUCT_PLATFORMS
              : StaticVariables().PLATFORMS;
    if (showSocial) {

      return (queryData || [])
        .filter((element: any) => element !== undefined)
        .filter((item: any) => showSocial.includes(item.platform));
    } else {
      return queryData
    }
  }, [queryData, activeFilter]);

  useEffect(() => {
    props.handleFeed(postIds);
  }, []);

  useEffect(() => {
    refetch()
  }, [activeFilter])
  

  const [value, setValue] = useState<string>();

  const handleDone = async (post_id: string, newValue: string) => {
  

    await updateNameValues(username, type, post_id, user_id, newValue);
    setEdit(false);
    setEditIndex("");
    refetch();
  };

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  return (
    <>
      <div className="addedContent ">
      <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            if (!newValue.trim()) {
              setErrorMsg("Title cannot be empty");
              return;
            }
            setErrorMsg("");
            // setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              // setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={ editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />

        <div className="addedSvg">
          {filteredData && filteredData.length ? (
            filteredData.map((post: any, index: any) => (
              <React.Fragment key={index}>
                <div
                  key={index}
                  className="feed-tab-post-container feed-wrapper"
                >
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      post.platform === "newsletter" ||
                        post?.platform === "linkedin" ||
                        post?.platform === "twitter"
                        ? {
                          textAlign: "center",
                          height: "100%",
                          borderRadius: "1rem",
                        }
                        : {}
                    }
                  >
                    {post?.platform === "newsletter" ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "linkedin" && !post?.media_url ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "twitter" && !post?.media_url ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${shape === "sq"
                            ? "newsletter-media-carousel square-image"
                            : "newsletter-media-carousel h-250"
                            }`}
                        >
                          <h4 className="newsletter-label">X Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className={`${shape === "sq" ? "square-grid" : ""}`}>
                        {post?.media_url ? (
                          <div
                            className={`${shape === "sq" ? "square-wrapper" : ""
                              }`}
                          >
                            {post?.media_url.split(",").length > 1 &&
                              post?.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {post?.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        <VideoComponent
                                          media_url={media_url}
                                          thumbnail={
                                            post.thumbnail &&
                                              post.thumbnail.split(",").length &&
                                              post.thumbnail.split(",")[index]
                                              ? post.thumbnail.split(",")[index]
                                              : "/assets/dummy_video.png"
                                          }
                                          content_shape={shape}
                                          isSoundOn={isSoundOn}
                                        />
                                      ) : (
                                        <img
                                          className={`${shape === "sq"
                                            ? "feed-tab-media-check square-image mb-2"
                                            : "feed-tab-media-check mb-2"
                                            }`}
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {post.media_url.includes("video") ? (
                                  <div
                                    className={`${shape === "sq"
                                      ? "square-wrapper"
                                      : "h-255"
                                      }`}
                                  >
                                    <VideoComponent
                                      media_url={post.media_url}
                                      thumbnail={post.thumbnail}
                                      content_shape={shape}
                                      isSoundOn={isSoundOn}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className={`${shape === "sq"
                                      ? "feed-tab-media-check square-image"
                                      : "feed-tab-media-check"
                                      }`}
                                    src={
                                      post.media_url
                                        ? post.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`${shape === "sq" ? "square-wrapper" : ""
                              }`}
                          >
                            <img
                              className={`${shape === "sq"
                                ? "feed-tab-media-check square-image"
                                : "feed-tab-media-check"
                                }`}
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue:any) => {
                        setValue(newValue);
                        // setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          // setIsActionLoading(false);
                        }
                      }}
                      isLoading={ editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                </div>
              </React.Fragment>
            ))
          ) : (
            <>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="skeletonLoader"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox />
                  ))}
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const { data, postIds, isLoading, query, isShopLinks, type } = props;
  // const [checkedValues, setCheckedValues] = useState<{
  //   [key: string]: boolean;
  // }>({});
  const [checkedValues, setCheckedValues] = useState<any>(postIds || []);
  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds]);
  useEffect(() => {
    // Call handleFeed on initial render and whenever checkedValues changes
    if (query != "" && postIds && postIds.length >= 0) {
      props.handleQuery(query);
      props.handleFeed(postIds.reverse());
    }
  }, [query, postIds]);

  return (
    <>
      {!isLoading ? (
        <>
          {data?.pages ? (
            <div className="feed-tab-posts-container">
              {data.pages.map((group: any, pageIndex: number) => (
                <Fragment key={pageIndex}>
                  {group.map((image: any, index: number) => (
                    <div
                      key={index}
                      className="feed-tab-post-container feed-post-wrapper"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          image.platform === "newsletter" ||
                            image.platform === "linkedin" ||
                            image.platform === "twitter"
                            ? {
                              textAlign: "center",
                              height: "100%",
                              borderRadius: "1rem",
                            }
                            : {}
                        }
                      >
                        {image.platform === "newsletter" ? (
                          <div className="newsletter-media-carousel h-250 no-media-url"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image.platform === "linkedin" &&
                          !image?.media_url ? (
                          <div className="newsletter-media-carousel h-250 no-media-url"
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image.platform === "twitter" &&
                          !image?.media_url ? (
                          <div className="newsletter-media-carousel h-250 no-media-url"
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label">X Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : (
                          <>
                            {image.media_url ? (
                              <>
                                {image.media_url.split(",").length > 1 &&
                                  image.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {image.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  image.thumbnail &&
                                                    image.thumbnail.split(",")
                                                      .length &&
                                                    image.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    ? image.thumbnail.split(
                                                      ","
                                                    )[index]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mb-2"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {image.media_url.includes("video") ? (
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={image.media_url}
                                          thumbnail={image.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          image.media_url
                                            ? image.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </div>
                      <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                            ? image?.shop_title
                            : image?.product_name}{" "}
                      </p>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <>
              {isShopLinks ? (
                <p>No shoppable content present for '{query}'</p>
              ) : (
                <p>No content present for '{query}'</p>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default QueryFeedCheckboxComponent;
