import React, { useEffect, useRef, useState } from "react";
import { Carousel, Row, Col } from "antd";
import { StaticCompnents } from "../../../../Utils/StaticComponent";
import "../../Feed/feedTab.css";
import HoverVideoPlayer from "react-hover-video-player";


const FeedContentPost = (props: any) => {
  const { image } = props;
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    instagram: StaticCompnents().InstagramIcon,
    pinterest: StaticCompnents().PinterestIcon,
    twitter: StaticCompnents().TwitterIcon,
    tiktok: StaticCompnents().TiktokIcon,
    newsletter: StaticCompnents().NewsletterIcon,
    podcast: StaticCompnents().PodcastIcon,
    linkedin: StaticCompnents().LinkedinIcon,
    youtube: StaticCompnents().YoutubeIcon,
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    // Convert platform to lowercase for consistent matching
    const platformLower = platform.toLowerCase();
    const IconComponent = platformIcons[platform];
    if (IconComponent) {
      return <IconComponent />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };


  const videoRef: any = useRef();
  const [hover, setHover] = useState(false);
  const [customPause, setCustomPause] = useState(false);
  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.playsInline = true;
    }
  }, []);


  return (
    <>
      <div
        className="feed-tab-row-media-wrapper"
        style={
          (image.platform === "newsletter" ||
            image.platform === "linkedin" ||
            image.platform === "twitter") &&
            !image.media_url
            ? {
              backgroundColor: "black",
              textAlign: "center",
              height: "255px",
              color: "white",
              borderRadius: "1rem",
            }
            : {
              borderRadius: "1rem",
            }
        }
      >
        {image.platform === "newsletter" ? (
          <div
            // style={{
            //   // Center text inside the div
            // }}
            className="no-image-text"
          >
            <span className="newsletter-label" style={{ display: "block" }}>
              Newsletter
            </span>
            <span className="date" style={{ display: "block" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(image.post_date))}
            </span>
          </div>
        ) : image.platform === "linkedin" && !image.media_url ? (
          <div
            // style={{
            //   alignSelf: "center",
            //   backgroundColor: "black", // Light grey background
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   padding: "10px",
            //   height: "100%",
            //   color: "white",
            //   flexDirection: "column", // Stack items vertically
            //   textAlign: "center", // Center text inside the div
            // }}
            className="no-image-text"
          >
            <span className="newsletter-label" style={{ display: "block" }}>
              LinkedIn Post
            </span>
            <span className="date" style={{ display: "block" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(image.post_date))}
            </span>
          </div>
        ) : image.platform === "twitter" && !image.media_url ? (
          <div
            // style={{
            //   alignSelf: "center",
            //   backgroundColor: "black", // Light grey background
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   padding: "10px",
            //   height: "100%",
            //   color: "white",
            //   flexDirection: "column", // Stack items vertically
            //   textAlign: "center", // Center text inside the div
            // }}
            className="no-image-text"
          >
            <span className="newsletter-label" style={{ display: "block" }}>
              X Post
            </span>
            <span className="date" style={{ display: "block" }}>
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(image.post_date))}
            </span>
          </div>
        ) : (
          <>
            {image.platform === "youtube" && image.product_image ? (
              <img
                className="content-tab-media mh-350"
                src={image.product_image}
                alt="post"
              />
            ) :
              image.media_url ? (
                <>
                  {image.media_url.split(",").length > 1 &&
                    image.media_url
                      .split(",")
                      .every((url: any) => url.trim() !== "") ? (
                    <div className="w-100 h-100">
                      <Carousel
                        dotPosition="bottom"
                        className="feed-tab-media-carousel"
                      >
                        {image.media_url
                          .split(",")
                          .map((media_url: any, index: any) => (
                            <React.Fragment key={index}>
                              {media_url.includes("video") ? (
                                // <video
                                //   className="content-tab-media mh-350  mb-2"
                                //   autoPlay
                                //   playsInline
                                //   muted
                                //   loop
                                //   poster={
                                //     image.thumbnail &&
                                //     image.thumbnail.split(",").length &&
                                //     image.thumbnail.split(",")[index]
                                //       ? image.thumbnail.split(",")[index]
                                //       : "/assets/dummy_video.png"
                                //   }
                                // >
                                //   <source src={media_url} />
                                // </video>
                                <>
                                  <HoverVideoPlayer
                                    videoRef={videoRef}
                                    videoSrc={media_url}
                                    className="content-tab-media mh-350 content-video-height"
                                    muted
                                    preload={"metadata"}
                                    contextMenu="return false;"
                                    hoverOverlay={<div className="video-hover"></div>}
                                    pausedOverlay={
                                      <div className="pause-overlay">
                                        <img
                                          src={image.thumbnail &&
                                            image.thumbnail.split(",").length &&
                                            image.thumbnail.split(",")[index]
                                            ? image.thumbnail.split(",")[index]
                                            : "/assets/dummy_video.png"}
                                          alt=""
                                          className="content-tab-media mh-350 mb-2"
                                          loading="eager"
                                        />
                                      </div>
                                    }
                                    loadingOverlay={
                                      <img
                                        src={image.thumbnail &&
                                          image.thumbnail.split(",").length &&
                                          image.thumbnail.split(",")[index]
                                          ? image.thumbnail.split(",")[index]
                                          : "/assets/dummy_video.png"}
                                        alt=""
                                        className="content-tab-media mh-350 mb-2"
                                        loading="eager"
                                      />
                                    }
                                    unloadVideoOnPaused
                                    onHoverStart={() => {
                                      setHover(true);
                                    }}
                                    onHoverEnd={() => {
                                      setHover(false);
                                      setCustomPause(false);
                                    }}
                                    disableDefaultEventHandling={customPause}
                                  />
                                  {/* <video
                                className="content-tab-media mh-350 mb-2"
                                playsInline
                                muted
                                loop
                                poster={
                                  image.thumbnail &&
                                    image.thumbnail.split(",").length &&
                                    image.thumbnail.split(",")[index]
                                    ? image.thumbnail.split(",")[index]
                                    : "/assets/dummy_video.png"
                                }
                                onMouseEnter={(e) => e.currentTarget.play()}
                                onMouseLeave={(e) => {
                                  e.currentTarget.pause();
                                  e.currentTarget.currentTime = 0;
                                }}
                              >
                                <source src={image.media_url} />
                              </video> */}
                                </>
                              ) : (
                                <img
                                  className="content-tab-media mh-350 mb-2"
                                  src={media_url}
                                  alt="post"
                                />
                              )}

                            </React.Fragment>
                          )
                          )}
                      </Carousel>
                    </div>
                  ) : (
                    <>
                      {image.media_url.includes("video") ? (
                        <>
                          <HoverVideoPlayer
                            videoRef={videoRef}
                            videoSrc={image.media_url}
                            className="content-tab-media mh-350 content-video-height"
                            muted
                            preload={"metadata"}
                            contextMenu="return false;"
                            hoverOverlay={<div className="video-hover"></div>}
                            pausedOverlay={
                              <div className="pause-overlay">
                                <img
                                  src={image.thumbnail ? image.thumbnail : "/assets/dummy_video.png"}
                                  alt=""
                                  className="content-tab-media mh-350 mb-2"
                                  loading="eager"
                                />
                              </div>
                            }
                            loadingOverlay={
                              <img
                                src={image.thumbnail ? image.thumbnail : "/assets/dummy_video.png"}
                                alt=""
                                className="content-tab-media mh-350 mb-2"
                                loading="eager"
                              />
                            }
                            style={{
                              // width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                            // unloadVideoOnPaused
                            onHoverStart={() => {
                              setHover(true);
                            }}
                            onHoverEnd={() => {
                              setHover(false);
                              setCustomPause(false);
                            }}
                            disableDefaultEventHandling={customPause}
                          />
                          {/* <video
                          className="content-tab-media mh-350"
                          playsInline
                          muted
                          loop
                          poster={
                            image.thumbnail
                              ? image.thumbnail
                              : "/assets/dummy_video.png"
                          }
                          onMouseEnter={(e) => e.currentTarget.play()}
                          onMouseLeave={(e) => {
                            e.currentTarget.pause();
                            e.currentTarget.currentTime = 0;
                          }}
                        >
                          <source src={image.media_url} />
                        </video> */}
                        </>
                      ) : (
                        <img
                          className="content-tab-media mh-350"
                          src={image.media_url}
                          alt="post"
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <img
                  className="feed-tab-media mh-350"
                  src={
                    image.thumbnail ? image.thumbnail : "/assets/dummy_video.png"
                  }
                  alt="post"
                />
              )}
          </>
        )}

        <p className="creator-feed-content">
          {image.platform && (
            <span className="creator-feed-content-icon">
              {renderPlatformIcon(image.platform)}
            </span>
          )}
          {new Date(image.post_date).getFullYear() > 2000 && (
            <span className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(image.post_date))}
            </span>
          )}
        </p>
      </div>
    </>
  );
};

export default FeedContentPost;
