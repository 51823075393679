import React from "react";
import { Select, Space } from "antd";
import { changeComponentStatus } from "../../Utils/SupabaseServices/StayUpToDateDatabase";

const StatusSelector = (props: any) => {
  const { defaultValue, component, user_id, refetch, setStatus, status, from } = props;
  const handleChange = async (value: string) => {
    const response = await changeComponentStatus(value, user_id, component, from);
    setStatus(value)
    refetch()

  };
  return (
    <Space wrap>
      <Select
        defaultValue={defaultValue}
        //   style={{ width: 120 }}
        onChange={handleChange}
        id={status}
        options={[
          { value: "active", label: "Active" },
          { value: "draft", label: "Draft" },
          { value: "archive", label: "Archive" },
        ]}
      />
    </Space>
  );
};

export default StatusSelector;
