import { useLoadUser } from "../../Utils/customHooks/useLoadUser";
import CommentsPost, {
  CommentsPostType,
} from "./PlatformPosts/CommentsPost/CommentsPost";
import InstagramPost from "./PlatformPosts/InstagramPost/InstagramPost";
import LinkedInPost from "./PlatformPosts/LinkedInPost/LinkedInPost";
import NewsletterPost, {
  NewsletterPostType,
} from "./PlatformPosts/NewsletterPost/NewsletterPost";
import PinterestPost, {
  PinterestPostType,
} from "./PlatformPosts/PinterestPost/PinterestPost";
import PodcastPost, {
  PodcastPostType,
} from "./PlatformPosts/PodcastPost/PodcastPost";
import ProductPost, {
  ProductPostType,
} from "./PlatformPosts/ProductPost/ProductPost";
import TiktokPost, {
  TiktokPostType,
} from "./PlatformPosts/TiktokPost/TiktokPost";
import TwitterPost, {
  TwitterPostType,
} from "./PlatformPosts/TwitterPost/TwitterPost";
// import PinterestPost, { PinterestPostType } from "./PlatformPosts/PinterestPost/PinterestPost";
import YoutubePost, {
  YoutubePostType,
} from "./PlatformPosts/YoutubePost/YoutubePost";

export type Post = {
  created_at: string;
  id: number;
  media_url: string;
  storage_url: string;
  // metadata: any;
  permalink: any;
  post_date: string;
  post_id: string;
  raw_content_id: number;
  index: string;
  platform:
    | "instagram"
    | "youtube"
    | "pinterest"
    | "twitter"
    | "amazon"
    | "newsletter"
    | "podcast"
    | "tiktok"
    | "twitter"
    | "instaComment"
    | "LTK"
    | "Amazon"
    | "ShopMy"
    | "Youtube"
    | "MISC"
    | "linkedin"
    | "MISC-content"; // TODO: Add new platforms if added
  type:
    | "REELS"
    | "FEED"
    | "VIDEO"
    | "PIN"
    | "STORY"
    | "MAIL"
    | "PODCAST"
    | "TIKTOK"
    | "HIGHLIGHTS"
    | "TWEET"
    | "COMMENT"
    | "PRODUCT" | "POST";
  user_id: string;
  caption?: string;
  ads_order?: number;
  global_ads_order?: number;
  tagg?: string;
  updated_at?: string;
  showAsAd?: boolean;
  thumbnail?: string;
  comments?: any[];
  show_ad?: any;
  shop_link: any;
  tagged_link: any;
  external_link: any;
  product_image: any;
};
export default function FeedPost({
  post,
  showCaption,
  showComments,
  isGlobalView,
  username,
  query,
  shoppableContent,
}: {
  post: Post;
  showCaption: any;
  showComments: any;
  isGlobalView: any;
  username: string;
  query: string;
  shoppableContent: any;
}) {
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);

  const user_id = userData ? userData.user_id : "";
  return (
    <>
      {(() => {
        switch (post.platform) {
          case "instagram":
            return (
              <InstagramPost
                post={post}
                showCaption={showCaption}
                showComments={showComments}
                isGlobalView={isGlobalView}
                username={username}
                showAsAd={post.show_ad}
                shoppableContent={shoppableContent}
                user_id={user_id}
                platform={post.platform}
              />
            );
            case "linkedin":
            return (
              <LinkedInPost
                post={post}
                showCaption={showCaption}
                showComments={showComments}
                isGlobalView={isGlobalView}
                username={username}
                showAsAd={post.show_ad}
                shoppableContent={shoppableContent}
                user_id={user_id}
                platform={post.platform}
              />
            );
          case "youtube":
            return (
              <YoutubePost
                post={post as YoutubePostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                query={query}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                platform={post.platform}
                username={username}
              />
            );
          case "podcast":
            return (
              <PodcastPost
                post={post as PodcastPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                platform={post.platform}
                username={username}
              />
            );

          case "newsletter":
            return (
              <NewsletterPost
                post={post as NewsletterPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                platform={post.platform}
                username={username}
                />
            );
          case "tiktok":
            return (
              <TiktokPost
                post={post as TiktokPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                platform={post.platform}
                username={username}
              />
            );
          case "twitter":
            return (
              <TwitterPost
                post={post as TwitterPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                username={username}
                platform={post.platform}
              />
            );

          case "pinterest":
            return (
              <PinterestPost
                post={post as PinterestPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                user_id={user_id}
                showAsAd={post.show_ad}
                platform={post.platform}
                username={username}
              />
            );

          case "LTK":
          case "Amazon":
          case "ShopMy":
          case "Youtube":
          case "MISC":
          case "MISC-content":
            return (
              <ProductPost
                post={post as ProductPostType}
                showCaption={showCaption}
                isGlobalView={isGlobalView}
                shoppableContent={shoppableContent}
                username={username}
                showAsAd={post.show_ad}
                user_id={user_id}
                platform={post.platform}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
}
