import { BsFillPlayFill, BsLink45Deg } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  handleRedirect,
  handleShop,
  handleTagged,
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./NewsletterPost.css";
import Caption from "../Caption";
import { useState } from "react";
import { Button, Modal } from "antd";
import React from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { IoMailOutline } from "react-icons/io5";
import "../CommentsPost/CommentsPost.css";
import PostInfoDate from "../../PostInfo/PostInfoComponents/PostInfoDate";
import PostInfoPermalink from "../../PostInfo/PostInfoComponents/PostInfoPermalink";
import PostInfo from "../../PostInfo/PostInfo";

export type NewsletterPostType = Post & {
  platform: "newsletter";
  metadata: {
    to: string;
    from: string;
    description: string;
    threadId: string;
  };
};

export default function NewsletterPost({
  post,
  showCaption,
  shoppableContent,
  user_id,
  showAsAd,
  username,
  platform,
}: {
  post: NewsletterPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id: any;
  showAsAd?: any;
  platform:any
  username:any
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { post_date, post_id, permalink,shop_link,tagged_link,media_url,external_link } = post;
  var html: any = post.metadata ? JSON.stringify(post.metadata) : null;

  const jsonString = JSON.parse(html);
  const jsonObject = JSON.parse(jsonString);

  const descriptionHTML = jsonObject?.description;
  function iframe() {
    return {
      __html: descriptionHTML,
      style: {
        width: "100%",
        height: "100%",
        overflow: "auto",
      },
    };
  }

  return (
    <div>
      <div className="newsletter-post-img-wrp">
        {post.media_url ? (
          <img src={post.media_url} className="newsletter-post-img" />
        ) : (
          <>
            <div className="newsletter-post-img">
              <div className="newsletter-post-img-date">
                <span className="newsletter-label">Newsletter</span>
                <br />
                <span className="date">
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                  }).format(new Date(post_date))}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
      
            <div className="creator-feed-post-links">
              <IoMailOutline
                className="bs-link"
                onMouseEnter={(e: any) => {
                  e.target.style.color = "#888";
                }}
                onMouseLeave={(e: any) => {
                  e.target.style.color = "#000";
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-content-date">Sponsored</p>
            <div className="creator-feed-post-links">
              <IoMailOutline
                className="bs-link"
                onMouseEnter={(e: any) => {
                  e.target.style.color = "#888";
                }}
                onMouseLeave={(e: any) => {
                  e.target.style.color = "#000";
                }}
              />
            </div>
          </>
        )}
      </div> */}
        <PostInfo
      username={username}
      user_id={user_id}
      showAsAd={showAsAd}
      post_date={post_date}
      permalink={permalink}
      shop_link={shop_link}
      tagged_link={tagged_link}
      post_id={post_id}
      handleRedirect={handleRedirect}
      handleShop={handleShop}
      handleTagged={handleTagged}
      platform={platform}
      media_url={media_url}
      shoppableContent={shoppableContent}
      external_link={external_link}
    />
      {post?.caption && (
        <Caption
          caption={post.caption}
          setIsModalOpen={setIsModalOpen}
          isNewsletter={true}
        />
      )}

      <button className="newsletter-post-btn" onClick={showModal}>
        See Entire Newsletter
      </button>

      <Modal
        bodyStyle={{
          overflowY: "auto",

          maxHeight: "calc(100vh - 250px)",
        }}
        title={`${new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          day: "numeric",
          month: "long",
        }).format(new Date(post_date))} Newsletter`}
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div dangerouslySetInnerHTML={iframe()} style={iframe().style} />
      </Modal>
    </div>
  );
}
