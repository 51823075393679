import { BsFillPlayFill, BsLink45Deg, BsYoutube } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Tag } from "antd";

import { milisecondsToTimestamp } from "../../../../Utils/GeneralFunctions";
import { Post } from "../../FeedPost";
import Caption from "../Caption";
import track, {
  handleRedirect,
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";

import "./YoutubePost.css";
import { useAuth } from "../../../../hooks/useAuth";
import "../CommentsPost/CommentsPost.css";
import { RiShoppingBagLine } from "react-icons/ri";
import { isMobile } from "react-device-detect";
import { FiAtSign } from "react-icons/fi";
import PostInfo from "../../PostInfo/PostInfo";

export type YoutubePostType = Post & {
  platform: "youtube";
  metadata: {
    view_count: string;
    like_count: string;
    favorite_count: string;
    comment_count: string;
    description: string;
    channel_id: string;
    // transcript?: string;
  };
};

type Transcript = { text: string; offset: number }[];

export default function YoutubePost({
  post,
  showCaption,
  query,
  shoppableContent,
  user_id,
  showAsAd,
  platform,
  username,
}: {
  post: YoutubePostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  query?: string;
  user_id: any;
  showAsAd: any;
  platform: any;
  username: any;
}) {
  // const { user } = useAuth();
  // const user_id = user?.id || "";
  const [timestamps, setTimestamps] = useState<Transcript>([]);
  const location = useLocation();
  const {
    post_date,
    post_id,
    permalink,
    metadata,
    shop_link,
    tagged_link,
    media_url,
    external_link,
  } = post;

  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
  }
  function handleTagged(postId: string, media_url: string) {
    track("Redirect to tagged link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
    });
  }

  return (
    <div className={post.product_image ? "feed-post" : ""} style={post.product_image ? {} : { alignSelf: "center" }}>
      <div className={post.product_image ? "product-post-img-wrp" : "yt-post-img-wrp"}>
        {!post.product_image &&
          <a
            href={permalink}
            target="_blank"
            rel="noreferrer"
            className="yt-post-playbtn"
          >
            <BsFillPlayFill className="m-0" />
          </a>
        }
        <img
          src={post.product_image || post.media_url}
          alt={post.caption}
          className="yt-post-img bg-f1"
        />
      </div>
      {/* <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
            <div className="creator-feed-post-links">
              <a href={permalink} target="_blank" rel="noreferrer">
                <BsLink45Deg
                  className="bs-link"
                  onMouseEnter={(e: any) => {
                    e.target.style.color = "#888"; // Change icon color on hover
                  }}
                  onMouseLeave={(e: any) => {
                    e.target.style.color = "#000"; // Restore original color when hover ends
                  }}
                  onClick={() => handleRedirect(post_id,media_url,platform,user_id)}
                />
              </a>
              {shop_link ? (
                <a
                  href={shop_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  {shoppableContent === "icon" ? (
                    <RiShoppingBagLine
                      className="perma-links"
                      onMouseEnter={(e: any) => {
                        e.target.style.color = "#888";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#000";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    />
                  ) : shoppableContent === "text" ? (
                    <Button
                      className="shoppable-btn"
                      onMouseOver={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                      style={{fontSize:'0.6rem',padding:'0.6rem'}}
                    >
                      Shop
                    </Button>
                  ) : (
                    ""
                  )}
                </a>
              ) : (
                ""
              )}
              {tagged_link ? (
                <a
                  href={tagged_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <FiAtSign
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleTagged(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsYoutube
                    className="bs-link"
                    id="youtube-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888"; // Change icon color on hover
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000"; // Restore original color when hover ends
                    }}
                    onClick={() => handleRedirect(post_id,media_url,platform,user_id)}
                  />
                </a>
              ) : (
                <BsYoutube
                  className="ml-6"
                  id="youtube-icon"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-ad-indicator">Sponsored</p>
            <div className="creator-feed-post-links">
              <a href={permalink} target="_blank" rel="noreferrer">
                <BsLink45Deg
                  className="bs-link"
                  onMouseEnter={(e: any) => {
                    e.target.style.color = "#888"; // Change icon color on hover
                  }}
                  onMouseLeave={(e: any) => {
                    e.target.style.color = "#000"; // Restore original color when hover ends
                  }}
                  onClick={() => handleRedirect(post_id,media_url,platform,user_id)}
                />
              </a>
              {shop_link ? (
                <a href={shop_link} target="_blank" rel="noreferrer">
                  {shoppableContent === "icon" ? (
                    <RiShoppingBagLine
                      className="perma-links"
                      onMouseEnter={(e: any) => {
                        e.target.style.color = "#888";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#000";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                    />
                  ) : shoppableContent === "text" ? (
                    <Button
                      className="shoppable-btn"
                      onMouseOver={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#fff";
                      }}
                      onClick={() => {
                        handleShop(post_id, media_url);
                      }}
                      style={{fontSize:'0.6rem',padding:'0.6rem'}}

                    >
                      Shop
                    </Button>
                  ) : (
                    ""
                  )}
                </a>
              ) : (
                ""
              )}
              {tagged_link ? (
                <a
                  href={tagged_link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <FiAtSign
                    className="perma-links"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleTagged(post_id, media_url)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsYoutube
                    className="bs-link"
                    id="youtube-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888"; // Change icon color on hover
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000"; // Restore original color when hover ends
                    }}
                    onClick={() => handleRedirect(post_id,media_url,platform,user_id)}
                  />
                </a>
              ) : (
                <BsYoutube
                  className="ml-6"
                  id="youtube-icon"
                />
              )}
            </div>
          </>
        )}
      </div> */}
      <PostInfo
        username={username}
        user_id={user_id}
        showAsAd={showAsAd}
        post_date={post_date}
        permalink={permalink}
        shop_link={shop_link}
        tagged_link={tagged_link}
        external_link={external_link}
        post_id={post_id}
        handleRedirect={handleRedirect}
        handleShop={handleShop}
        handleTagged={handleTagged}
        platform={platform}
        media_url={media_url}
        shoppableContent={shoppableContent}
      />
      {post?.caption && <p className="posts-title-caption">{post.caption}</p>}
      {showCaption && post.metadata?.description && (
        <Caption caption={post.metadata.description} />
      )}
      <div className="yt-post-timestamp-box">
        {timestamps.map((item) => (
          <a
            key={item.offset}
            href={`${permalink}&t=${Math.floor(item.offset / 1000)}`}
            target="_blank"
            rel="noreferrer"
            className="yt-post-timestamp"
          >
            <Tag color="blue">From {milisecondsToTimestamp(item.offset)}</Tag>
          </a>
        ))}
      </div>
    </div>
  );
}
