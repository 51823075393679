import React, { useEffect, useState } from "react";
import { Checkbox, Divider } from "antd";
import { getAllDatesFromSelection } from "../../Utils/StayUpToDate/RenderComponent";

const plainOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "This Weekend",
];

// Helper function to get the most recent past date for a specific day

// Function to convert Date object to string format "YYYY-MM-DD"

const CheckboxComponent = (props: any) => {
  const {
    daysNameArray,
    StayUpToDateComponents,
    setErrorMsg,
    finalSelectedDates,
    setFinalSelectedDates,
  } = props;
  const [checkedList, setCheckedList] = useState<string[]>(daysNameArray || []);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  useEffect(() => {
    setCheckedList(daysNameArray || []);

    // Calculate dates from the selected days and update the state
    if (daysNameArray && daysNameArray.length > 0) {
      const dates = getAllDatesFromSelection(daysNameArray);
      setSelectedDates(dates);

      // Log for debugging detail
      daysNameArray.forEach((day: string) => {
       
      });
    }
  }, [daysNameArray]);

  const toggleDay = (day: string) => {
    setErrorMsg("");
    let newCheckedList: string[];

    if (checkedList.includes(day)) {
      // Remove day if already selected
      newCheckedList = checkedList.filter((d: string) => d !== day);
    } else {
      // Add day if not already at max limit
      if (checkedList.length < 3) {
        newCheckedList = [...checkedList, day];
      } else {
        setErrorMsg("You can only select up to 3 days for this carousel.");
        return;
      }
    }

    setCheckedList(newCheckedList);

    // Update the selectedDates array with the new selection
   

    // Pass both the day names and the date strings to parent component
    props.handleDays(newCheckedList);

   
  };

  return (
    <div>
      <p
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginBottom: "15px",
          color: "#666",
        }}
      >
        You can select up to 3 days for this carousel.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "flex-start",
          marginBottom: "15px",
        }}
      >
        {plainOptions.slice(0, 5).map((day) => (
          <button
            key={day}
            type="button"
            onClick={() => toggleDay(day)}
            style={{
              padding: "8px 12px",
              borderRadius: "6px",
              border: checkedList.includes(day)
                ? "2px solid #333"
                : "1px solid #d9d9d9",
              backgroundColor: checkedList.includes(day) ? "#f0f0f0" : "white",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: checkedList.includes(day) ? "500" : "normal",
              color: checkedList.includes(day) ? "#333" : "#666",
              transition: "all 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "90px",
              position: "relative",
              outline: "none",
            }}
            aria-pressed={checkedList.includes(day)}
          >
            {day}
            {checkedList.includes(day) && (
              <span
                style={{
                  position: "absolute",
                  right: "6px",
                  top: "6px",
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor: "#333",
                }}
              />
            )}
          </button>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "flex-start",
        }}
      >
        {plainOptions.slice(5).map((day) => (
          <button
            key={day}
            type="button"
            onClick={() => toggleDay(day)}
            style={{
              padding: "8px 12px",
              borderRadius: "6px",
              border: checkedList.includes(day)
                ? "2px solid #333"
                : "1px solid #d9d9d9",
              backgroundColor: checkedList.includes(day) ? "#f0f0f0" : "white",
              cursor: "pointer",
              fontSize: "13px",
              fontWeight: checkedList.includes(day) ? "500" : "normal",
              color: checkedList.includes(day) ? "#333" : "#666",
              transition: "all 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: day === "This Weekend" ? "120px" : "90px",
              position: "relative",
              outline: "none",
            }}
            aria-pressed={checkedList.includes(day)}
          >
            {day}
            {checkedList.includes(day) && (
              <span
                style={{
                  position: "absolute",
                  right: "6px",
                  top: "6px",
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor: "#333",
                }}
              />
            )}
          </button>
        ))}
      </div>

      {checkedList.length > 0 && (
        <>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "13px", color: "#666" }}>
              Selected days:
            </span>
            <div
              style={{
                display: "flex",
                gap: "6px",
                flexWrap: "wrap",
              }}
            >
              {checkedList.map((day: string) => (
                <span
                  key={day}
                  style={{
                    backgroundColor: "#f0f0f0",
                    color: "#333",
                    padding: "3px 10px",
                    borderRadius: "12px",
                    fontSize: "12px",
                    border: "1px solid #d9d9d9",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {day}
                  <button
                    onClick={() => toggleDay(day)}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#333",
                      cursor: "pointer",
                      padding: "0",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "14px",
                      height: "14px",
                    }}
                    aria-label={`Remove ${day}`}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckboxComponent;
