import { Button } from "antd";
import React from "react";
import { BsLink45Deg } from "react-icons/bs";

const PostInfoExternalLink = (props: any) => {
  const {
    external_link,
    handleRedirect,
    post_id,
    media_url,
    platform,
    user_id,
  } = props;
  return (
    <>
      {external_link ? (
        <a
          href={external_link}
          target="_blank"
          // target={isMobile ? "_self" : "_blank"}
          rel="noreferrer"
        >
          <Button
            className="shoppable-btn"
            onMouseOver={(e: any) => {
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e: any) => {
              e.target.style.color = "#fff";
            }}
            onClick={() => {
              handleRedirect(post_id, media_url, platform, user_id);
            }}
          >
            View
          </Button>
        </a>
      ) : (
        ""
      )}
    </>
  );
};

export default PostInfoExternalLink;
