import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import GridTemplate from "./GridTemplate";
import "./GridTemplate.css";
import { UpdateUserLinksIndex } from "../../../../../../Utils/SupabaseServices/UserLinksDatabase";

const DraggableTemplateGrid = ({
  username,
  user_id,
  componentName,
  refetch,
  editIndex,
  setEditIndex,
  brand_color,
  templates,
  showDelete,
  onRemove,
  isEmptyTemplate,
  name,
  handleReorder,
  from
}: any) => {
  const [items, setItems] = useState<any>();
  const [draggedItemId, setDraggedItemId] = useState<number | null>(null);
  const [draggedRect, setDraggedRect] = useState<DOMRect | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setItems(templates);
  }, [templates]);

  // Desktop drag handlers
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: number) => {
    setDraggedItemId(id);
    e.currentTarget.style.opacity = '0.5';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetId: number) => {
    e.preventDefault();
    if (draggedItemId === null || draggedItemId === targetId) return;

    const newItems = [...items];
    const draggedIndex = newItems.findIndex(item => item.id === draggedItemId);
    const targetIndex = newItems.findIndex(item => item.id === targetId);

    const [draggedItem] = newItems.splice(draggedIndex, 1);
    newItems.splice(targetIndex, 0, draggedItem);

    setItems(newItems);
   
    handleReorder(newItems);
    // UpdateUserLinksIndex(newItems, user_id, name);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.opacity = '';
    setDraggedItemId(null);
  };

  // Mobile touch handlers
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>, id: number) => {
    // If in edit mode, don't allow dragging
    if (editIndex !== undefined && editIndex !== null) {
      return;
    }

    const target = e.target as HTMLElement;
    const cardElement = target.closest('.creator-card');

    // Don't start drag if clicking on any button except the drag handle
    // or if the card is in edit mode
    if (
      (target.closest('button') && !target.closest('.drag-handle')) ||
      cardElement?.classList.contains('edit-mode-card')
    ) {
      return;
    }

    const touch = e.touches[0];
    const element = e.currentTarget;
    const rect = element.getBoundingClientRect();
    
    setDraggedItemId(id);
    setDraggedRect(rect);
    setIsDragging(true);
    setDragOffset({
      x: touch.clientX - rect.left,
      y: touch.clientY - rect.top
    });

    element.style.opacity = '0.5';
    element.style.position = 'fixed';
    element.style.zIndex = '1000';
    element.style.width = `${rect.width}px`;
    element.style.height = `${rect.height}px`;
    element.style.left = `${rect.left}px`;
    element.style.top = `${rect.top}px`;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    // If in edit mode, don't handle touch move
    if (editIndex !== undefined && editIndex !== null) {
      return;
    }

    if (!isDragging || !draggedRect) return;
    e.preventDefault();

    const touch = e.touches[0];
    const element = e.currentTarget;

    const newX = touch.clientX - dragOffset.x;
    const newY = touch.clientY - dragOffset.y;

    element.style.left = `${newX}px`;
    element.style.top = `${newY}px`;

    const elementsBelow = document.elementsFromPoint(touch.clientX, touch.clientY);
    const droppableElement = elementsBelow.find(el =>
      el.classList.contains('draggable-grid-item') &&
      el !== element
    );

    if (droppableElement) {
      const droppableId = Number(droppableElement.getAttribute('data-id'));
      if (droppableId !== draggedItemId) {
        const newItems = [...items];
        const draggedIndex = newItems.findIndex(item => item.id === draggedItemId);
        const dropIndex = newItems.findIndex(item => item.id === droppableId);

        const [draggedItem] = newItems.splice(draggedIndex, 1);
        newItems.splice(dropIndex, 0, draggedItem);

        setItems(newItems);

        // UpdateUserLinksIndex(newItems, user_id, name);
        handleReorder(newItems);
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    // If in edit mode, don't handle touch end
    if (editIndex !== undefined && editIndex !== null) {
      return;
    }

    if (!isDragging) return;

    const element = e.currentTarget;
    element.style.opacity = '';
    element.style.position = '';
    element.style.zIndex = '';
    element.style.width = '';
    element.style.height = '';
    element.style.left = '';
    element.style.top = '';

    setIsDragging(false);
    setDraggedItemId(null);
    setDraggedRect(null);
  };

  return (
    <>
      {items &&
        items.map((item: any) => (
          <div
            key={item.id}
            data-id={item.id}
            className={`draggable-grid-item ${editIndex === item.id ? 'editing' : ''}`}
            draggable={editIndex !== item.id}
            onDragStart={(e) => handleDragStart(e, item.id)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, item.id)}
            onTouchStart={(e) => handleTouchStart(e, item.id)}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <GridTemplate
              card={item}
              onRemove={onRemove}
              username={username}
              user_id={user_id}
              componentName={componentName}
              isDashboard={true}
              refetch={refetch}
              editIndex={editIndex}
              setEditIndex={setEditIndex}
              brand_color={brand_color}
              templates={templates}
              showDelete={!isEmptyTemplate}
              from={from}
            />
          </div>
        ))}
    </>
  );
};

export default DraggableTemplateGrid;
