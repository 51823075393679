import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TabTitle } from "../../Utils/GeneralFunctions";
import NoUser from "../../ErrorPages/NoUser";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import FeedControls from "../../components/FeedPage/FeedControls/FeedControls";
import { useLoadCompletePage } from "../../Utils/customHooks/useLoadCompletePage";
import { useLoadRenderComponent } from "../../components/StayUpToDateComponent/StayUpToDateRenderComponent/useLoadRenderComponent";
import FrontendSkeleton from "../../components/skeleton/Views/FrontendSkeleton";
import { useLoadProfileSetup } from "../../Utils/customHooks/useLoadProfileSetup";
import StayUpToDateSkeleton from "../DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import SlidingSidebar from "../../components/SlidingSidebar/SlidingSidebar";
import Sidebar from "../DashboardView/Dashboard/Sidebar";
import { useSidebarState } from "../../context/SidebarStateContext";
import { useSidebar } from "../../context/SidebarContext";
import { useLoadUser } from "../../Utils/customHooks/useLoadUser";
import { fetchUser } from "../../Utils/SupabaseServices/UserDatabase";
import { message } from "antd";
import { FaLink } from "react-icons/fa";
import { copyEmbedCode } from "../DashboardView/EmbedSearch/EmbedSearch";
import { BsSearch } from "react-icons/bs";
import CreatorLandingProfile from "../../components/CreatorProfile/CreatorLandingProfile";
import { supabase } from "../../config/supabaseClient";
import CreatorProfile from "../../components/CreatorProfile/creatorProfile";

const EmbeddedView = () => {
  const page = "follower";
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchLoading, setShowSearchLoading] = useState(false);
  const [removeSearchLoading, setRemoveSearchLoading] = useState(false);
  const location = useLocation();
  const param = useParams();
  const { search } = location;
  const isFollowerView = location.pathname.includes("follower");
  const queryParams = new URLSearchParams(search);

  const utmSource = queryParams.get("utm_source");
  if (utmSource) {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      details: { source: param?.username },
      // user_id: "",
    });
  }

  const indexPages: any = location?.state?.indexPages;

  if (location.state) {
    TabTitle("Follower View • Bubble");
  } else {
    TabTitle(`${param?.username} • Bubble `);
  }
  const { isLoading: profileSetupLoading, data: profileSetupData } =
    useLoadProfileSetup(param?.username, page);
  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(param?.username);
  const { isLoading, data, error }: any = useLoadCompletePage(
    param?.username,
    page,
    profileSetupData?.combinedRes?.userData?.user_id,
    profileSetupData?.combinedRes?.feedControls,
    profileSetupData?.combinedRes?.instagram_links,
    profileSetupData?.combinedRes?.userData?.is_fillout_on,
    "embed-search"
  );

  const {
    handleClick,
    isEmail,
    isName,
    isPhone,
    isPhoneEmpty,
    tab,
    isEmailVerified,
    option,
    setIsEmail,
    setIsName,
    setIsPhone,
    setIsPhoneEmpty,
    setTab,
    setIsEmailVerified,
  } = useSidebarState();
  const { isOpen, closeSidebar } = useSidebar();
  const [contentShifted, setContentShifted] = useState(false);
  const [loading, setLoading] = useState(true);

  // Add new state for embed configuration
  const [configLoading, setConfigLoading] = useState(true);
  const [embedConfig, setEmbedConfig] = useState({
    showProfilePicture: false,
    showNameAndDesc: false,
    showSocialLinks: false
  });

  useEffect(() => {
    if (isFollowerView && isOpen) {
      setTimeout(() => {
        setContentShifted(true);
      }, 10);
    } else {
      setContentShifted(false);
    }
  }, [isOpen, isFollowerView]);

  useEffect(() => {
    fetchEmail();
  }, [isEmail, isName]);

  const fetchEmail = useCallback(async () => {
    try {
      const userData = await fetchUser(param?.username);
      if (!userData) return;

      const hasEmail = userData.email !== "";
      const hasName = userData.name !== "";

      if (hasEmail && hasName) {
        setIsName(true);
        setIsEmail(true);
        setIsPhone(true);
        setIsEmailVerified(true);
      } else {
        setIsEmail(hasEmail);
        setIsName(hasName);
        handleClick("Account");
        setTab(0);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  }, [param?.username]);

  useEffect(() => {
    const route = location.pathname;
    let title: any;
    if (route.includes("follower")) {
      // updateFollowerCount(username);
      title = "Follower";
    } else {
      // updateLiveCount(username);
      title = "Live";
    }
    if (!profileSetupLoading) {
      track(location.pathname, TRACKING_TYPES.PAGE, {
        pathname: location.pathname,
        title: title,
        user_id: profileSetupData?.combinedRes?.userData?.user_id,
      });
      if (param?.id) {
        track(`/${param?.username}`, TRACKING_TYPES.PAGE, {
          pathname: `/${param?.username}`,
          title: title,
          user_id: profileSetupData?.combinedRes?.userData?.user_id,
        });
      }
    }
  }, [profileSetupLoading, profileSetupData?.combinedRes]);

  const {
    isLoading: StayUpToDateComponentsDataIndividualLoading,
    data: StayUpToDateComponentsDataIndividual,
  } = useLoadRenderComponent(
    profileSetupData?.combinedRes?.userData?.user_id,
    param?.id
  );

  useEffect(() => {
    setRemoveSearchLoading(true);
    const updateSearchBar = () => {
      if (
        profileSetupData?.combinedRes?.feedControls?.checkedType ===
        "Landing Page"
      ) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (
          urlSearchParams?.size > 0 &&
          (params.hasOwnProperty("term") || params.hasOwnProperty("from"))
        ) {
          setShowSearch(true);
        } else {
          setShowSearch(false);
        }
      } else {
        setShowSearch(true);
      }
    };
    updateSearchBar();

    const intervalId = setInterval(() => {
      setRemoveSearchLoading(false);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [
    profileSetupData?.combinedRes?.feedControls?.checkedType,
    location.search,
  ]);

  // Add effect to load embed configuration
  useEffect(() => {
    const loadEmbedConfig = async () => {
      setConfigLoading(true);
      if (profileSetupData?.combinedRes?.userData?.user_id) {
        try {
          const { data: user } = await supabase
            .from('user')
            .select('embed_configure')
            .eq('user_id', profileSetupData.combinedRes.userData.user_id)
            .single();

          if (user?.embed_configure) {
            setEmbedConfig({
              showProfilePicture: user.embed_configure.showProfilePicture ?? false,
              showNameAndDesc: user.embed_configure.showNameAndDesc ?? false,
              showSocialLinks: user.embed_configure.showSocialLinks ?? false
            });
          } else {
            // If no configuration exists, keep everything hidden
            setEmbedConfig({
              showProfilePicture: false,
              showNameAndDesc: false,
              showSocialLinks: false
            });
          }
        } catch (error) {
          console.error('Error loading embed configuration:', error);
          // On error, keep everything hidden
          setEmbedConfig({
            showProfilePicture: false,
            showNameAndDesc: false,
            showSocialLinks: false
          });
        } finally {
          setConfigLoading(false);
        }
      }
    };

    loadEmbedConfig();
  }, [profileSetupData?.combinedRes?.userData?.user_id]);

  return (
    <>
      {profileSetupLoading || loading ? (
        <FrontendSkeleton from="embed-search" />
      ) : (
        <>
          <div className="embed-search-wrapper">
            <div className={` ${isFollowerView ? "dashboard-layout" : ""}`}>
              {isFollowerView && (
                <SlidingSidebar
                  type={location?.pathname.includes("follower") ? "follower" : "dashboard"}
                >
                  <Sidebar
                    username={param?.username}
                    pictureUrl={userData?.picture}
                    email_verify={userData?.email_verify}
                  />
                </SlidingSidebar>
              )}
              {/* <button
                onClick={() => copyEmbedCode(param?.username)}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "8px 16px",
                  backgroundColor: "transparent",
                  color: "#333",
                  border: "2px dashed #ccc",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                  zIndex: 10,
                }}
              >
                <FaLink style={{ marginRight: "5px" }} />
                Copy Embed Code
              </button> */}
              <div className={`${contentShifted ? "content-shifted" : ""}`}>
                {error ? (
                  <NoUser />
                ) : profileSetupData?.combinedRes?.userData?.name &&
                  profileSetupData?.combinedRes?.userData?.user_id &&
                  param?.username ? (
                  <>
                    <div className="">
                      {!configLoading && (embedConfig.showProfilePicture || embedConfig.showNameAndDesc || embedConfig.showSocialLinks) && (
                        // <CreatorLandingProfile
                        //   userDetails={profileSetupData?.combinedRes?.userData}
                        //   showIcons={embedConfig.showSocialLinks}
                        //   liveView={false}
                        //   username={param?.username}
                        //   showSocialLinks={embedConfig.showSocialLinks}
                        //   socialLinks={embedConfig.showSocialLinks ? profileSetupData?.combinedRes?.userData : null}
                        //   description={embedConfig.showNameAndDesc ? profileSetupData?.combinedRes?.description : ""}
                        //   desiredOrder={embedConfig.showSocialLinks ? profileSetupData?.combinedRes?.userData?.social_links_order?.split(",") : []}
                        //   isUserDashboard={false}
                        //   showProfilePicture={embedConfig.showProfilePicture}
                        //   showNameAndDesc={embedConfig.showNameAndDesc}
                        //   from="embed-search"
                        // />
                        <CreatorProfile
                          userDetails={profileSetupData?.combinedRes?.userData}
                          showIcons={
                            profileSetupData?.combinedRes?.feedControls?.showIcons
                          }
                          liveView={
                            location.pathname.includes("/follower") ? false : true
                          }
                          username={param?.username}
                          showSocialLinks={
                            embedConfig.showSocialLinks
                          }
                          socialLinks={embedConfig.showSocialLinks ? profileSetupData?.combinedRes?.userData : null}
                          description={embedConfig.showNameAndDesc ? profileSetupData?.combinedRes?.description : ""}
                          setShowSearch={setShowSearch}
                          showSearch={showSearch}
                          setShowSearchLoading={setShowSearchLoading}
                          desiredOrder={embedConfig.showSocialLinks ? profileSetupData?.combinedRes?.userData?.social_links_order?.split(
                            ","
                          ) : []}
                          showSearchbar={
                            true
                          }
                          loading={loading}
                          showProfilePicture={embedConfig.showProfilePicture}
                          showNameAndDesc={embedConfig.showNameAndDesc}
                          from="embed-search"
                        />
                      )}
                    </div>
                    <FeedControls
                      indexPages={indexPages}
                      userDetails={profileSetupData?.combinedRes?.userData}
                      username={param?.username}
                      page="follower"
                      name={profileSetupData?.combinedRes?.userData?.name}
                      userId={profileSetupData?.combinedRes?.userData?.user_id}
                      data={data?.combinedRes}
                      profileData={profileSetupData?.combinedRes}
                      hashtagFilters={data?.hashtagFilterArray}
                      avgWords={data?.combinedRes?.userData?.avg_words}
                      liveView={false}
                      StayUpToDateComponentsDataIndividual={StayUpToDateComponentsDataIndividual}
                      StayUpToDateComponentsDataIndividualLoading={StayUpToDateComponentsDataIndividualLoading}
                      showSearch={true}
                      showSearchLoading={showSearchLoading}
                      setShowSearchLoading={setShowSearchLoading}
                      setShowSearch={setShowSearch}
                      StayUpToDateComponentsLoading={isLoading}
                      StayUpToDateComponents={data?.combinedRes?.stayUpToDateComponentsData}
                      removeSearchLoading={removeSearchLoading}
                      setRemoveSearchLoading={setRemoveSearchLoading}
                      from="embed-search"
                      isLandingPage={false}
                      refetchUser={refetch}
                      refetchUserDetail={refetch}
                      creatorData={profileSetupData}
                      isLoading={isLoading}
                      brand_color={profileSetupData?.combinedRes?.userData?.brand_color}
                      isFilloutOn={profileSetupData?.combinedRes?.userData?.is_fillout_on}
                    />
                  </>
                ) : (
                  <NoUser />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmbeddedView;
// {profileSetupLoading ? (
//   <FrontendSkeleton />
// ) : // <p>Loading</p>
// isLoading || StayUpToDateComponentsDataIndividualLoading ? (
//   // <></>
//   // <ProfileSkeleton />
//   // <FrontendSkeleton />
//   <div className="uptodate-wrapper">
//     <StayUpToDateSkeleton />
//   </div>
// ) : data?.combinedRes?.userData?.name &&
//   data?.combinedRes?.userData?.user_id &&
//   param?.username ? (
//   <>
//     <div id="app-wrapper" className="inex-1">
//       {location.pathname.includes("/follower") ? (
//         <>
//           <Header
//             extraComponent={
//               <div className="header-component">
//                 <CopyToClipboard
//                   textToCopy={`https://www.searchbubble.com/${param?.username}`}
//                 />
//                 <ImageSidebar
//                   username={param?.username as string}
//                   isFollower={true}
//                   pictureUrl={data?.combinedRes?.userData?.picture}
//                 />
//               </div>
//             }
//           />
//         </>
//       ) : (
//         ""
//         // <LiveMenu username={username as string} />
//       )}
//     </div>

//     <FeedControls
//       indexPages={indexPages}
//       userDetails={data?.combinedRes?.userData}
//       username={param?.username}
//       page="follower"
//       name={data?.combinedRes?.userData?.name}
//       userId={data?.combinedRes?.userData?.user_id}
//       data={data?.combinedRes}
//       hashtagFilters={data?.hashtagFilterArray}
//       avgWords={data?.combinedRes?.userData?.avg_words}
//       liveView={location.pathname.includes("/follower") ? false : true}
//       StayUpToDateComponentsDataIndividual={
//         StayUpToDateComponentsDataIndividual
//       }
//     />
//   </>
// ) : (
//   <NoUser />
// )}
