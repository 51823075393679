import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { UpdateCombinedText } from "../../../Utils/SupabaseServices/ContentDatabase";

// Hook for adding or removing tags
export default function useUpdateProductTags() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    tagString,
    post_id,
    username,
    action,
    tag,
    user_id,
  }: {
    currentPage: any;
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    username: any;
    action: any;
    tag: any;
    user_id: any;
  }) => {
    if (action === "submit") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
      // .limit(1);
      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
    } else if (action === "remove") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id)
        .limit(1);
      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
    }
    const { error } = await supabase
      .from(username)
      .update({ tagg: tagString })
      .eq("post_id", `${post_id}`)
      .eq("user_id", user_id);
       await supabase
      .from('ads_content')
      .update({ tagg: tagString })
      .eq("post_id", `${post_id}`)
      .eq("user_id", user_id);
       await supabase
      .from('broken_links_table')
      .update({ tagg: tagString })
      .eq("post_id", `${post_id}`)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);

    // if (error) {
    //   throw new Error(error.message);
    // } else {
    return true;
    // }
  };

  return useMutation(submitTag, {
    onMutate: async ({
      tagString,
      post_id,
      currentPage,
      username,
      pageIndex,
      index,
      activeKey,
      filter,
    }) => {
      await queryClient.cancelQueries("ProductBankPosts");

      const previousData = queryClient.getQueryData(["ProductBankPosts"]);

      queryClient.setQueryData(["ProductBankPosts"], (oldData: any) => {
        if (oldData) {
          // Find and update the specific post with new tags
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              post.product_id === post_id ? { ...post, tagg: tagString } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData };
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["ProductBankPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("ProductBankPosts");
    },
  });
}
