import { BsFillPlayFill, BsLink45Deg, BsPinterest } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  handleRedirect,
  handleShop,
  handleTagged,
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./PinterestPost.css";
import Caption from "../Caption";
import { useState } from "react";
import { Button, Modal } from "antd";
import React from "react";
import { MdOutlinePodcasts } from "react-icons/md";
import "../CommentsPost/CommentsPost.css"
import PostInfo from "../../PostInfo/PostInfo";

export type PinterestPostType = Post & {
  platform: "pinterest";
  metadata: {
    description: string;
  };
};

export default function PinterestPost({
  post,
  showCaption,
  shoppableContent,
  user_id,
  showAsAd,
  platform,
  username
}: {
  post: PinterestPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id:any
  showAsAd:any,
  platform:any,
  username:any
}) {
  const location = useLocation();
  const {post_date, post_id, permalink ,media_url,shop_link,tagged_link,external_link} = post;
 



  return (
    <div>
      <div className="pinterest-post-img-wrp">
        <img
          src={post.media_url}
          alt={post.caption}
          className="pinterest-post-img bg-f1"
        />
      </div>
      {/* <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id,post.media_url,post.platform,user_id)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsPinterest
                    className="bs-link"
                    id="pinterest-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id,post.media_url,post.platform,user_id)}
                  />
                </a>
              ) : (
                <BsPinterest
                  className="ml-6"
                  id="pinterest-icon"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-ad-indicator">Sponsored</p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id,post.media_url,post.platform,user_id)}
                  />
                </a>
              ) : (
                ""
              )}

              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsPinterest
                    className="bs-link"
                    id="pinterest-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id,post.media_url,post.platform,user_id)}
                  />
                </a>
              ) : (
                <BsPinterest
                  className="ml-6"
                  id="pinterest-icon"
                />
              )}
            </div>
          </>
        )}
      </div> */}
      
      <PostInfo
      username={username}
      user_id={user_id}
      showAsAd={showAsAd}
      post_date={post_date}
      permalink={permalink}
      shop_link={shop_link}
      tagged_link={tagged_link}
      external_link={external_link}
      post_id={post_id}
      handleRedirect={handleRedirect}
      handleShop={handleShop}
      handleTagged={handleTagged}
      platform={platform}
      media_url={media_url}
      shoppableContent={shoppableContent}
    />  
      {post?.caption && <Caption caption={post.caption} />}
    </div>
  );
}
