import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, message } from 'antd';
import { useAuth } from '../../../../hooks/useAuth';
import { supabase } from '../../../../config/supabaseClient';
import { inviteTeamMember } from '../../../../Utils/SupabaseServices/UserDatabase';

interface TeamMember {
    email: string;
    role: string;
    token: string;
    status: string;
    created_at: string;
    creator_id: string;
    expires_at: string;
}

const MembersList = () => {
    const [members, setMembers] = useState<TeamMember[]>([]);
    const [loading, setLoading] = useState(true);
    // const { user } = useAuth();
    const user = "277d503b-db93-44f8-8e04-2922a21b97ee"
    const [resendingTo, setResendingTo] = useState<string | null>(null);

    useEffect(() => {
        loadMembers();
    }, [user]);

    const loadMembers = async () => {
        try {
            const { data, error } = await supabase
                .from('user')
                .select('team_members')
                .eq('user_id', user)
                .single();

            if (error) throw error;
            setMembers(data?.team_members || []);
        } catch (error) {
            console.error('Error loading members:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleResendInvitation = async (email: string, role: string) => {
        if (!user) return;

        setResendingTo(email);
        try {
            const result = await inviteTeamMember(user, email, role, true);
            if (result.error) {
                message.error(result.error);
            } else {
                message.success(result.message);
                loadMembers(); // Reload the list to get updated data
            }
        } catch (error) {
            message.error('Failed to resend invitation');
        } finally {
            setResendingTo(null);
        }
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag color={status === 'active' ? 'green' : 'orange'}>
                    {status.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Joined',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => new Date(date).toLocaleDateString(),
        },
        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     render: (record: TeamMember) => (
        //         record.status === 'pending' && (
        //             <Button 
        //                 type="primary"
        //                 loading={resendingTo === record.email}
        //                 onClick={() => handleResendInvitation(record.email, record.role)}
        //             >
        //                 Resend Invitation
        //             </Button>
        //         )
        //     ),
        // }
    ];

    return (
        <div className="members-list">
            <h3>Team Members</h3>
            <Table
                loading={loading}
                dataSource={members}
                columns={columns}
                rowKey="email"
            />
        </div>
    );
};

export default MembersList;
