import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";
import {
  updateNameValues,
} from "../../../../../Utils/StayUpToDate/RenderComponent";
import { useLoadYesterdayContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayContent";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import Title from "antd/es/typography/Title";
import CarouselControlWrapper from "../../../../../components/CarouselControls/CarouselControlWrapper";
import ProductName from "../../../../../components/ProductName/ProductName";
import EditableText from "../../../../../components/EditModalComponents.tsx/EditableText";
import PostCardComponent from "../../../../../components/EditModalComponents.tsx/PostCardComponent";

const EditYesterdayContent = (props: any) => {
  const {
    username,
    user_id,
    isYesterdayLinksEditModalOpen,
    setIsYesterdayLinksEditModalOpen,
    type,
    component,
    from
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  const [isChange, setIsChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [isSoundOn, setIsSoundOn] = useState(component.content_sound || false);
  const [shape, setShape] = useState(component.content_shape || "rect");
  const [order, setOrder] = useState("DESC");

  const [isActionLoading, setIsActionLoading] = useState(false);
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
    setIsSoundOn(component.content_sound || false);
    setShape(component.content_shape || "rect");
    setOrder(component.content_order || "DESC");
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      removePostIds,
      order, shape, isSoundOn,
      undefined,
      from
    );
    if (res === "true") {
      props.refetch();

      props.setIsYesterdayLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setIsChange(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      setEdit(false);
      setEditIndex("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setEdit(false);
      setEditIndex("");
    }

    // props.setIsCustomLinksComponentOpen(false);
  };

  const handleCancel = () => {
    props.setIsYesterdayLinksEditModalOpen(false);
    props.setSelectedComponent("");

    // props.setIsCustomLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEdit(false);
    setEditIndex("");
  };

  // function handleRemovedPostIds(post_ids: any) {
  //   setRemovePostIds((prev: any) => {
  //     const idsArray = prev.split(",").filter(Boolean);
  //     idsArray.push(post_ids);
  //     return idsArray.join(","); // Convert array back to string
  //   });
  //   // setRemovePostIds((prev: any) => (prev ? [...prev, post_ids] : [post_ids]));
  //   // setPostIds(post_ids);
  // }
  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);
        return idsArray.join(","); // Convert array back to string
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);

        return filteredArray.join(","); // Convert array back to string
      });
    }
  }

  return (
    <>
      <Modal
        open={isYesterdayLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>

          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.display_name && component?.display_name !== "" && (
            <p className="text-center">
              {component?.name && component.name.split(",").join(" & ")}
              {/* Content */}
            </p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            setIsChange={setIsChange}
            isChange={isChange}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            shape={shape}
            isSoundOn={isSoundOn}
            order={order}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            name={name}
            setName={setName}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            // yesterdayLinksData={yesterdayLinksData}
            // refetchLinks={refetchLinks}
            // yesterdayLinksLoading={yesterdayLinksLoading}
          />
          <div className="carousel-controls-wrapper">
          <div className="addedContent">
          <Title level={3}>Carosuel Control</Title>
          <CarouselControlWrapper
            showShape={true}
            showSound={true}
            showFilter={false}
            showOrder={true}
            shape={shape}
            setShape={setShape}
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
            type={type}
            order={order}
            setOrder={setOrder}
          />
          </div>
          </div>
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
            // yesterdayLinksData={yesterdayLinksData}
            // refetchLinks={refetchLinks}
            // yesterdayLinksLoading={yesterdayLinksLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    title,
    setIsChange,
    isChange,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    shape,
    isSoundOn,
    order,

    isActionLoading,
    setIsActionLoading,
    name,
    setName,
    errorMsg,
    setErrorMsg,
    // yesterdayLinksData,
    // refetchLinks,
    // yesterdayLinksLoading
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  let numberOfDays:any;
  if (displayName === "This Week Content") {
    numberOfDays = [7];
  } else if (displayName === "Yesterday") {
    numberOfDays = [1];
  } else if (displayName === "This Month") {
    numberOfDays = [30];
  } else {
    numberOfDays = []
  }
  let {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch,
  }: any = useLoadYesterdayContent(
    username,
    user_id,
    numberOfDays,
    postIds,
    deletePostIds,
    title,
    isChange,
    order
  );
  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      setDeletePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        idsArray.push(post_id);
        return idsArray.join(","); // Convert array back to string
      });
      await Promise.all([
        props.handleRemovedPostIds(post_id, "minus"),
        refetch(),
      ]);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      setIsActionLoading(false);
    }
  };
  const [value, setValue] = useState<string>();

  const handleDone = async (post_id: string, newValue: string) => {
    await updateNameValues(username, type, post_id, user_id, newValue);
    refetch();
    setEdit(false);
    setEditIndex("");
    setIsChange(true);

    // PostIdDataRefetch();
    refetchDashboard();
  };

  const filteredData = useMemo(() => {
    return (yesterdayLinksData || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [yesterdayLinksData, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  // useEffect(()=>{
  //   refetchLinks();
  // },[deletePostIds])
  return (
    <>
      <div className="addedContent">
        <EditableText
          value={componentName}
          onSave={async (newValue: any) => {
            if (!newValue.trim()) {
              setErrorMsg("Title cannot be empty");
              return;
            }
            setErrorMsg("");
            setIsActionLoading(true);
            setEditIndex("title");
            try {
              // Update the parent component's name state
              setName(newValue);
            } catch (error) {
              console.error("Error updating component title:", error);
            } finally {
              setIsActionLoading(false);
              setEditIndex("");
            }
          }}
          isLoading={isActionLoading && editIndex === "title"}
          className="addedContentTitle editable-heading"
          textClassName="addedContentTitle"
          textStyle={{ fontWeight: "bold", fontSize: "18px" }}
          placeholder="Enter component title"
          setErrorMsg={setErrorMsg}
        />{" "}
        {!yesterdayLinksLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              // yesterdayLinksData && yesterdayLinksData
              //   .filter((element: any) => element !== undefined)
              //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
              //   .length
              filteredData
                // yesterdayLinksData
                //   .filter((element: any) => element !== undefined)
                //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
                .map((post: any, index: any) => (
                  <React.Fragment key={post.post_id}>
                    <div
                      key={post.post_id}
                      className="feed-tab-post-container added-post"
                    >
                      {/* <div className="feed-tab-media-wrapper">
                        <Button
                          onClick={() => onMinus(post.post_id)}
                          className="carousel-content-remove-btn"
                        >
                          Remove
                        </Button>
                        {post?.platform === "newsletter" ? (
                          <div
                            className={`${
                              shape === "sq" ? "square-wrapper" : ""
                            }`}
                          >
                            <div
                              className={`${
                                shape === "sq"
                                  ? "newsletter-media-carousel square-image"
                                  : "newsletter-media-carousel h-255"
                              }`}
                            >
                              <h4 className="mt-7">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  day: "numeric",
                                  month: "long",
                                }).format(new Date(post.post_date))}
                              </h4>
                            </div>
                          </div>
                        ) : post?.platform === "linkedin" &&
                          !post?.media_url ? (
                          <div
                            className={`${
                              shape === "sq" ? "square-wrapper" : ""
                            }`}
                          >
                            <div
                              className={`${
                                shape === "sq"
                                  ? "newsletter-media-carousel square-image"
                                  : "newsletter-media-carousel h-255"
                              }`}
                            >
                              <h4 className="mt-7">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  day: "numeric",
                                  month: "long",
                                }).format(new Date(post.post_date))}
                              </h4>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "square-grid"
                                : ""
                            }`}
                          >
                            {post?.media_url ? (
                              <div
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "square-wrapper"
                                    : ""
                                }`}
                              >
                                {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {post?.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                post.thumbnail.split(",")
                                                  .length &&
                                                post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[
                                                      index
                                                    ]
                                                  : "/assets/dummy_video.png"
                                              }
                                              content_shape={shape}
                                              isSoundOn={isSoundOn}
                                            />
                                          ) : (
                                            <img
                                              className={`${
                                                post?.type === "FEED" ||
                                                shape === "sq"
                                                  ? "feed-tab-media-check square-image"
                                                  : "feed-tab-media-check"
                                              }`}
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {post.media_url.includes("video") ? (
                                      <div
                                        className={`${
                                          post?.type === "FEED" ||
                                          shape === "sq"
                                            ? "square-wrapper"
                                            : "h-255"
                                        }`}
                                      >
                                        <VideoComponent
                                          media_url={post.media_url}
                                          thumbnail={post.thumbnail}
                                          content_shape={shape}
                                          isSoundOn={isSoundOn}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className={`${
                                          post?.type === "FEED" ||
                                          shape === "sq"
                                            ? "feed-tab-media-check square-image"
                                            : "feed-tab-media-check"
                                        }`}
                                        src={
                                          post.media_url
                                            ? post.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "square-wrapper"
                                    : ""
                                }`}
                              >
                                <img
                                  className={`${
                                    post?.type === "FEED" || shape === "sq"
                                      ? "feed-tab-media-check square-image"
                                      : "feed-tab-media-check"
                                  }`}
                                  src={
                                    post?.thumbnail
                                      ? post?.thumbnail
                                      : "/assets/dummy_video.png"
                                  }
                                  alt="post"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div> */}
                      <PostCardComponent
                        post={post}
                        type={type}
                        isShopLinks={false}
                        shape={shape}
                        isSoundOn={isSoundOn}
                        from="added"
                        onMinus={onMinus}
                      />
                    <EditableText
                      value={getPostTitle(post, type)}
                      onSave={async (newValue: any) => {
                        setValue(newValue);
                        setIsActionLoading(true);
                        try {
                          handleDone(post.post_id, newValue);
                        } catch (error) {
                          console.error("Error updating post title:", error);
                        } finally {
                          setIsActionLoading(false);
                        }
                      }}
                      isLoading={isActionLoading && editIndex === index}
                      textStyle={{
                        textAlign:
                          post?.shop_title !== "" ||
                          post?.product_name !== "" ||
                          post?.content_title !== ""
                            ? "left"
                            : "center",
                      }}
                    />
                    </div>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                {/* <p>No Added Data</p> */}
                <>
                  <div
                    // key={index}
                    // className="feed-tab-post-container"
                    className="feed-tab-post"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="feed-tab-post"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};

export default EditYesterdayContent;
