import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { storeTiktokUser } from "../../../Utils/SupabaseServices/ContentDatabase";

const TiktokIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showTiktokModal, user_id, data, refetchData } = props;
  const [tiktokUser, setTiktokUser] = useState(data?.tiktok_user);
  const [errorTiktok, setErrorTiktok] = useState("");
  const [loadingTiktokConnect, setLoadingTiktokConnect] = useState(false);
  const [integrateTiktokMsg, setIntegrateTiktokMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setTiktokUser(data?.tiktok_user)
    setShowIntegrationModal(false);
  };
  return (
    <>
      <Modal
        title="Connect to TikTok"
        open={showTiktokModal}
        onOk={async () => {
          if (tiktokUser && tiktokUser?.length > 0) {
            setLoadingTiktokConnect(true);

            const res = await storeTiktokUser(user?.id as string, tiktokUser, username);
            // const res = true
            if (res.error) {
              setErrorTiktok(res.message);
            } else {
              setIntegrateTiktokMsg(
                "Your TikTok data will be integrated in your feed within 24 hours!"
              );
              refetchData()
              // props.setShowTiktokModal(false);
              props.onClose();
              setShowIntegrationModal(true)
            }

            setLoadingTiktokConnect(false);
          } else {
            setErrorTiktok("Enter valid name")
          }
        }}
        onCancel={() => {
          setErrorTiktok("");
          setTiktokUser(data?.tiktok_user)
          // props.setShowTiktokModal(false);
          props.onClose();

        }}
        okText="Connect"
        okButtonProps={{ disabled: !tiktokUser, loading: loadingTiktokConnect }}
        cancelButtonProps={{ disabled: loadingTiktokConnect }}
      >
        <p>
          By connecting your TikTok username, all your TikTok data will be
          integrated and shown as posts in the feed of your main page. In the
          content tab you will be able to hide or delete the tiktoks you don't
          want to show.{" "}
        </p>
        {loadingTiktokConnect ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorTiktok && (
              <Alert
                type="error"
                message={`Error: ${errorTiktok}`}
                showIcon
                className="alert-margin"
              />
            )}
            {data?.tiktok_user ? (
              <>
                <p>
                  <b>Already connected TikTok username: {data?.tiktok_user}</b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect enter your TikTok username :</p>
            <p style={{ fontSize: 12, color: '#666' }}>Example: rebecca_smith</p>

            <Input
              placeholder="username"
              value={tiktokUser}
              onChange={(e) => {
                const value = e.target.value
                setTiktokUser(e.target.value)
                if (!value) {
                  setErrorTiktok("Enter valid name");
                } else {
                  setErrorTiktok("");
                }
              }}
            />
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      <Modal
        title={tiktokUser ? `${tiktokUser}'s TikToks` : ""}
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integrateTiktokMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default TiktokIntegrationModal;
