import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillTrashFill,
  BsFillChatDotsFill,
} from "react-icons/bs";
import { MdOutlineDragIndicator } from "react-icons/md";
import useUpdateTags from "../Feed new/useUpdateTags";
import useUpdatePostStatus from "./useUpdatePostStatus";
import {
  deletePostFunction,
  updateShopLink,
} from "../../../Utils/SupabaseServices/ContentDatabase";
import TagsBox from "../../../components/TagsBox/TagsBox";
import useDeletePosts from "./useDeletePosts";
import "./feedTab.css";
import { updateCommentsData } from "../../../Utils/SupabaseServices/CommentsDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useUpdatePostAdIndex from "./useUpdatePostIndex";
import { supabase } from "../../../config/supabaseClient";
import { StaticCompnents } from "../../../Utils/StaticComponent";

const BrokenLinkContent = ({
  username,
  activeKey,
  data,
  user_id,
  filter,
  setLimitError,
  refetchData,
  setIsContentLoading,
  subscription_type,
  brokenDataLoading,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState<any>([]);
  const [selectedPostId, setSelectedPostId] = useState<any>();
  const [localData, setLocalData] = useState(data);
  const [localTags, setLocalTags] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    refetchData();
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    if (localData && localData?.pages) {
      const initialTags = localData.pages.reduce((acc: any, group: any) => {
        group.forEach((post: any) => {
          acc[post.post_id] = post.tagg ? post.tagg.split(',') : [];
        });
        return acc;
      }, {});
      setLocalTags(initialTags);
    }
  }, [localData]);

  const handleCancel = () => {
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePostAdIndex } = useUpdatePostAdIndex();
  const { mutate: updatePostStatus } = useUpdatePostStatus();

  const { mutate: deletePostPermanently } = useDeletePosts();

  function removeTag(
    tagArr: any,
    tag: string,
    post_id: string,
    pageIndex: number,
    index: number
  ) {
    const currentTags = Array.isArray(tagArr) ? tagArr : tagArr.split(',');
    const newTags = currentTags.filter((t: string) => t !== tag);

    setLocalTags(prev => ({
      ...prev,
      [post_id]: newTags
    }));

    updateTags({
      activeKey,
      tagString: newTags.join(','),
      post_id,
      pageIndex,
      index,
      filter,
      username,
      tag,
      action: "remove",
      user_id,
    });
  }
  function submitTag(
    e: any,
    tagArray: any,
    tag: string,
    post_id: string,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = Array.from(
        new Set(tag.split(",").map((item: any) => item.trim()))
      );
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const existingTags = tagArray ? tagArray.split(',') : [];
      const newTags = Array.from(
        new Set([...modifiedArray, ...existingTags])
      );

      setLocalTags(prev => ({
        ...prev,
        [post_id]: newTags
      }));

      e.target.value = "";

      updateTags({
        activeKey,
        tagString: newTags.join(','),
        post_id,
        pageIndex,
        index,
        filter,
        username,
        tag,
        action: "submit",
        user_id,
      });
    }
  }

  function deletePost(post_id: string, pageIndex: number, index: number) {
    // deletePostPermanently({
    //   postId: post_id,
    //   activeKey,
    //   pageIndex,
    //   postIndex: index,
    //   filter,user_id
    // });

    deletePostFunction(post_id, username, user_id).then(() => {
      refetchData();
    });
  }

  const handleOk = async () => {
    if (checkedList && checkedList.length) {
      checkedList.map(async (list: any) => {
        await updateCommentsData(list);
      });
    }
    setSelectedPostId("");
    setIsModalOpen(false);
  };
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    let sourceIndex = result.source.index;
    let post_id = result.draggableId;
    let targetIndex = result.destination.index;
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);
    await updatePostAdIndex({
      user_id,
      sourceIndex,
      targetIndex,
      activeKey,
      post_id,
      username,
      newData,
    });
    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);

    refetchData();
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [postIdToUpdateLink, setPostIdToUpdateLink] = useState(null); // State to store the post ID for link update
  const [newLink, setNewLink] = useState(""); // State to store the new link value

  const showModalToUpdateLink = (postId: any) => {
    setPostIdToUpdateLink(postId);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCancel = () => {
    setPostIdToUpdateLink(null);
    setIsUpdateModalOpen(false);
    setNewLink(""); // Reset new link value when modal is closed
  };

  const updateLink = async () => {
    // Logic to update link here
    // You can use the postIdToUpdateLink and newLink states to perform the update
    // After updating the link, you can close the modal
    await updateShopLink(user_id, username, postIdToUpdateLink, newLink).then(
      () => {
        refetchData();
      }
    );
    setNewLink("");
    setIsUpdateModalOpen(false);
  };
  function archivePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "archived",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
      user_id,
    });
  }
  async function activePost(
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) {
    await supabase
      .from(`${username}`)
      .update({ shoplink_broken: false })
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
      user_id,
    });
    refetchData();
  }

  const ActionButtons = ({
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    shop_link,
  }: any) => {
    function testLink() {
      if (shop_link && shop_link !== "") {
        window.open(shop_link, "_blank");
      }
    }

    return (
      <>
        {activeKey === "broken_links" ? (
          <>
            <p className="shop-link">{shop_link}</p>
            <Button onClick={testLink} disabled={!shop_link}>
              Test Link
            </Button>
            <Button onClick={() => showModalToUpdateLink(postId)}>
              Update Link
            </Button>{" "}
          </>
        ) : (
          ""
        )}
        {activeKey === "active" ? (
          <Button
            type="default"
            icon={<BsArchiveFill className="bs-archive-fill" />}
            onClick={() => {
              archivePost(postId, pageIndex, index);
            }}
          >
            Archive
          </Button>
        ) : activeKey === "archived" || activeKey === "broken_links" ? (
          <Button
            type="default"
            icon={<BsPlusCircleFill className="bs-archive-fill" />}
            onClick={() => {
              activePost(postId, pageIndex, index);
            }}
          >
            Active
          </Button>
        ) : (
          ""
        )}

        {activeKey !== "active,ad" && activeKey !== "active,featured" ? (
          <Popconfirm
            title="Are you sure you want to permanently delete this post?"
            onConfirm={() => deletePost(postId, pageIndex, index)}
            okText="Yes"
            cancelText="No"
            overlayStyle={{ maxWidth: "240px" }}
          >
            <Button
              type="primary"
              danger
              icon={<BsFillTrashFill className="iconSize" />}
            >
              Delete Post
            </Button>
          </Popconfirm>
        ) : (
          ""
        )}
      </>
    );
  };
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    instagram: StaticCompnents().InstagramIcon,
    pinterest: StaticCompnents().PinterestIcon,
    twitter: StaticCompnents().TwitterIcon,
    tiktok: StaticCompnents().TiktokIcon,
    newsletter: StaticCompnents().NewsletterIcon,
    podcast: StaticCompnents().PodcastIcon,
    linkedin: StaticCompnents().LinkedinIcon,
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    const IconComponent = platformIcons[platform];

    if (IconComponent) {
      return <IconComponent />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };
  const isValid =
    !brokenDataLoading &&
    localData &&
    localData?.pages &&
    localData?.pages[0] &&
    localData?.pages[0].length > 0;

  return (
    <>
      {!brokenDataLoading ? (
        <>
          {localData &&
            localData?.pages &&
            localData?.pages[0] &&
            localData?.pages[0].length > 0 ? (
            <>
              <DragDropContext
                onDragStart={() => {
                  document.documentElement.style.scrollBehavior = "smooth";
                  document.body.style.scrollBehavior = "smooth";
                }}
                onDragEnd={(result: any) => {
                  onDragEnd(result);
                }}
              >
                <Droppable droppableId="adds">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}

                    // Additional styling if needed
                    >
                      {localData?.pages && localData?.pages[0].length ? (
                        <div className="feed-tab-posts-table">
                          {localData?.pages.map(
                            (group: any, pageIndex: number) => (
                              <Fragment key={pageIndex}>
                                {group.map((image: any, index: number) => (
                                  <Draggable
                                    key={image.post_id}
                                    draggableId={image.post_id}
                                    index={index}
                                  // isDragDisabled={
                                  //   activeKey === "active,ad" ? false : true
                                  // }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`feed-tab-post-row ${activeKey === "active,ad"
                                          ? "feed-tab-post-row-grab"
                                          : ""
                                          } ${draggedIndex === index
                                            ? "feed-tab-post-row-dragging"
                                            : ""
                                          }`}
                                      >
                                        {activeKey === "active,ad" && (
                                          <div className="feed-tab-drag-btn">
                                            <MdOutlineDragIndicator />
                                          </div>
                                        )}

                                        <div className="feed-tab-row-media">
                                          <div
                                            className="feed-tab-row-media-wrapper"
                                            style={
                                              image.platform === "newsletter" ||
                                                image.platform === "linkedin" ||
                                                image.platform === "twitter"
                                                ? {
                                                  backgroundColor: "black",
                                                  textAlign: "center",
                                                  color: "white",
                                                  borderRadius: "1rem",
                                                }
                                                : {}
                                            }
                                          >
                                            {image.platform === "newsletter" ? (
                                              <div
                                                // style={{
                                                //   alignSelf: "center",
                                                //   backgroundColor: "black",
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                //   padding: "10px",
                                                //   height: "100%",
                                                //   color: "white",
                                                //   flexDirection: "column",
                                                //   textAlign: "center",
                                                // }}
                                                className="no-image-text"

                                              >
                                                <span
                                                  className="newsletter-label"
                                                  style={{ display: "block" }}
                                                >
                                                  Newsletter
                                                </span>
                                                <span
                                                  className="date"
                                                  style={{ display: "block" }}
                                                >
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      day: "numeric",
                                                      month: "long",
                                                    }
                                                  ).format(
                                                    new Date(image.post_date)
                                                  )}
                                                </span>
                                              </div>
                                            ) : image.platform ===
                                              "linkedin" ? (
                                              <div
                                                // style={{
                                                //   alignSelf: "center",
                                                //   backgroundColor: "black", // Light grey background
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                //   padding: "10px",
                                                //   height: "100%",
                                                //   color: "white",
                                                //   flexDirection: "column", // Stack items vertically
                                                //   textAlign: "center", // Center text inside the div
                                                // }}
                                                className="no-image-text"

                                              >
                                                <span
                                                  className="newsletter-label"
                                                  style={{ display: "block" }}
                                                >
                                                  LinkedIn Post
                                                </span>
                                                <span
                                                  className="date"
                                                  style={{ display: "block" }}
                                                >
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      day: "numeric",
                                                      month: "long",
                                                    }
                                                  ).format(
                                                    new Date(image.post_date)
                                                  )}
                                                </span>
                                              </div>
                                            ) : image.platform === "twitter" ? (
                                              <div
                                                // style={{
                                                //   alignSelf: "center",
                                                //   backgroundColor: "black", // Light grey background
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                //   padding: "10px",
                                                //   height: "100%",
                                                //   color: "white",
                                                //   flexDirection: "column", // Stack items vertically
                                                //   textAlign: "center", // Center text inside the div
                                                // }}
                                                className="no-image-text"

                                              >
                                                <span
                                                  className="newsletter-label"
                                                  style={{ display: "block" }}
                                                >
                                                  X Post
                                                </span>
                                                <span
                                                  className="date"
                                                  style={{ display: "block" }}
                                                >
                                                  {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      day: "numeric",
                                                      month: "long",
                                                    }
                                                  ).format(
                                                    new Date(image.post_date)
                                                  )}
                                                </span>
                                              </div>
                                            ) : (
                                              <>
                                                {image.media_url ? (
                                                  <>
                                                    {image.media_url.split(",")
                                                      .length > 1 &&
                                                      image.media_url
                                                        .split(",")
                                                        .every(
                                                          (url: any) =>
                                                            url.trim() !== ""
                                                        ) ? (
                                                      <div className="w-100 h-100">
                                                        <Carousel
                                                          dotPosition="bottom"
                                                          className="feed-tab-media-carousel"
                                                        >
                                                          {image.media_url
                                                            .split(",")
                                                            .map(
                                                              (
                                                                media_url: any,
                                                                index: any
                                                              ) => (
                                                                <React.Fragment
                                                                  key={index}
                                                                >
                                                                  {media_url.includes(
                                                                    "video"
                                                                  ) ? (
                                                                    <video
                                                                      className="feed-tab-media mh-350"
                                                                      autoPlay
                                                                      playsInline
                                                                      muted
                                                                      loop
                                                                      poster={
                                                                        image.thumbnail &&
                                                                          image.thumbnail.split(
                                                                            ","
                                                                          )
                                                                            .length &&
                                                                          image.thumbnail.split(
                                                                            ","
                                                                          )[index]
                                                                          ? image.thumbnail.split(
                                                                            ","
                                                                          )[
                                                                          index
                                                                          ]
                                                                          : "/assets/dummy_video.png"
                                                                      }
                                                                    >
                                                                      <source
                                                                        src={
                                                                          media_url
                                                                        }
                                                                      />
                                                                    </video>
                                                                  ) : (
                                                                    <img
                                                                      className="feed-tab-media mh-350"
                                                                      src={
                                                                        media_url
                                                                      }
                                                                      alt="post"
                                                                    />
                                                                  )}
                                                                </React.Fragment>
                                                              )
                                                            )}
                                                        </Carousel>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {image.media_url.includes(
                                                          "video"
                                                        ) ? (
                                                          <video
                                                            className="feed-tab-media mh-350"
                                                            autoPlay
                                                            playsInline
                                                            muted
                                                            loop
                                                            poster={
                                                              image.thumbnail
                                                                ? image.thumbnail
                                                                : "/assets/dummy_video.png"
                                                            }
                                                          >
                                                            <source
                                                              src={image.media_url}
                                                            />
                                                          </video>
                                                        ) : (
                                                          <img
                                                            className="feed-tab-media mh-350"
                                                            src={image.media_url}
                                                            alt="post"
                                                          />
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {image.media_url.includes(
                                                      "video"
                                                    ) ? (
                                                      <video
                                                        className="feed-tab-media mh-350"
                                                        autoPlay
                                                        playsInline
                                                        muted
                                                        loop
                                                        poster={
                                                          image.thumbnail
                                                            ? image.thumbnail
                                                            : "/assets/dummy_video.png"
                                                        }
                                                      >
                                                        <source
                                                          src={image.media_url}
                                                        />
                                                      </video>
                                                    ) : (
                                                      <img
                                                        className="feed-tab-media mh-350"
                                                        src={image.media_url}
                                                        alt="post"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                            <p className="creator-feed-content">
                                              {image.platform && (
                                                <span className="creator-feed-content-icon">
                                                  {renderPlatformIcon(
                                                    image.platform
                                                  )}
                                                </span>
                                              )}
                                              {new Date(
                                                image.post_date
                                              ).getFullYear() > 2000 && (
                                                  <span className="creator-feed-content-date">
                                                    {new Intl.DateTimeFormat(
                                                      "en-US",
                                                      {
                                                        year: "numeric",
                                                        day: "numeric",
                                                        month: "long",
                                                      }
                                                    ).format(
                                                      new Date(image.post_date)
                                                    )}
                                                  </span>
                                              )}
                                            </p>
                                          </div>
                                          <div className="feed-tab-row-actions-mobile">
                                            <ActionButtons
                                              postIndex={image.index}
                                              postId={image.post_id}
                                              pageIndex={pageIndex}
                                              index={index}
                                              shop_link={image.shop_link}
                                            />
                                          </div>
                                        </div>

                                        <div className="feed-tab-row-tags">
                                          <form
                                            className="search-container-tagInput"
                                            onSubmit={(e) => {
                                              submitTag(
                                                e,
                                                localTags[image.post_id],
                                                (e.target as HTMLFormElement).tagInput.value,
                                                image.post_id,
                                                1,
                                                1
                                              );
                                            }}
                                          >
                                            <input
                                              type="text"
                                              id="tagInput"
                                              name="tagInput"
                                              enterKeyHint="enter"
                                              placeholder="Enter tags here"
                                              className="tagInput"
                                              onKeyUp={(e) => {
                                                submitTag(
                                                  e,
                                                  localTags[image.post_id],
                                                  e.currentTarget.value,
                                                  image.post_id,
                                                  1,
                                                  1
                                                );
                                              }}
                                            />
                                          </form>
                                          <div className="image-table-tags">
                                            {localTags[image.post_id]?.length > 0 ? (
                                              <TagsBox
                                                tagsArray={localTags[image.post_id]}
                                                removeTag={(tag: string) => {
                                                  removeTag(
                                                    localTags[image.post_id],
                                                    tag,
                                                    image.post_id,
                                                    1,
                                                    index
                                                  );
                                                }}
                                                page="content"
                                              />
                                            ) : (
                                              <p className="noTags">No Tags</p>
                                            )}
                                          </div>
                                        </div>

                                        <div className="feed-tab-actions">
                                          <ActionButtons
                                            postIndex={image.index}
                                            postId={image.post_id}
                                            pageIndex={pageIndex}
                                            index={index}
                                            platform={image.platform}
                                            shop_link={image.shop_link}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </Fragment>
                            )
                          )}
                        </div>
                      ) : (
                        <p className="">
                          {filter && filter !== "" ? (
                            <>
                              {activeKey === "archived" ? (
                                <i>
                                  No archived posts found matching your search
                                  for <b>{filter}</b>.
                                </i>
                              ) : activeKey === "active,ad" ? (
                                <i>
                                  No ads found matching your search for{" "}
                                  <b>{filter}</b>.
                                </i>
                              ) : activeKey === "active,featured" ? (
                                <i>
                                  No featured posts found matching your search
                                  for <b>{filter}</b>.
                                </i>
                              ) : activeKey === "broken_links" ? (
                                <div className="empty-state-message">
                                  <span className="empty-state-icon">🔍</span>
                                  <p>No broken links found matching your search for "<b>{filter}</b>"</p>
                                  <p>Try a different search term or check back later.</p>
                                </div>
                              ) : (
                                <i>No search results found.</i>
                              )}
                            </>
                          ) : (
                            <>
                              {activeKey === "archived" ? (
                                <div className="empty-state-message">
                                  <span className="empty-state-icon">📦</span>
                                  <p>No posts have been archived yet.</p>
                                </div>
                              ) : activeKey === "active,ad" ? (
                                <div className="empty-state-message">
                                  <span className="empty-state-icon">💼</span>
                                  <p>No posts have been marked as an ad yet.</p>
                                </div>
                              ) : activeKey === "active,featured" ? (
                                <div className="empty-state-message">
                                  <span className="empty-state-icon">✨</span>
                                  <p>No posts have been marked as featured yet.</p>
                                </div>
                              ) : activeKey === "broken_links" ? (
                                <div style={{ width: '100%' }}>
                                  <div className="empty-state-message">
                                    <span className="empty-state-icon">🎉</span>
                                    <p>Great job! Your page has no broken links.</p>
                                    <p>We'll notify you if any broken links are detected in the future.</p>
                                  </div>
                                </div>
                              ) : (
                                <i>No search results found.</i>
                              )}
                            </>
                          )}
                        </p>
                      )}
                      {/* Your Modal component */}
                      <Modal
                        title="Show Relevant Comments"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        bodyStyle={{
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 300px)",
                        }}
                        footer={[
                          <Button key="back" onClick={handleCancel}>
                            Cancel
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            // loading={loading}
                            onClick={handleOk}
                          >
                            Save
                          </Button>,
                        ]}
                      >
                        {/* Your Modal content */}
                      </Modal>
                      <Modal
                        title="Update Link"
                        open={isUpdateModalOpen}
                        onOk={updateLink}
                        onCancel={handleUpdateCancel}
                      >
                        <Input
                          type="text"
                          value={newLink}
                          onChange={(e: any) => setNewLink(e.target.value)}
                          placeholder="Enter new link"
                        />
                      </Modal>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : (
            <div className="empty-state-message">
              <span className="empty-state-icon">🎉</span>
              <p>Great job! Your page has no broken links.</p>
              <p>We'll notify you if any broken links are detected in the future.</p>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default BrokenLinkContent;

