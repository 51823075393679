import { Col, Pagination, Row, Spin } from "antd";
import "../Charts.css";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";
import StatBox from "../../../../../components/StatBox/StatBox";

function ChartClicksTable(props: any) {
  const {
    chartClicks,
    loadingPostClicksAnalytics,
    defaultCurrentPage,
    setDefaultCurrentPage,
    brand_color,
    carouselViews,
    loadingCarouselViews,
    selectedComponent, elem
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setDefaultCurrentPage(page);
  };
  useEffect(() => {
    setCurrentPage(defaultCurrentPage);
  }, [defaultCurrentPage]);

  const totalSum = useMemo(() => {
    return (
      chartClicks?.reduce((sum: any, oneKey: any) => {
        return (
          sum +
          (oneKey?.count || 0) +
          (oneKey.id && oneKey.idCount ? oneKey.idCount : 0)
        );
      }, 0) || 0
    );
  }, [chartClicks]);

  const totalCarouselClicksSum = useMemo(() => {
    return (
      chartClicks?.reduce((sum: any, oneKey: any) => {
        return sum + (oneKey?.count || 0);
      }, 0) || 0
    );
  }, [chartClicks]);

  const totalPageClicksSum = useMemo(() => {
    return (
      chartClicks?.reduce((sum: any, oneKey: any) => {
        return sum + (oneKey.id && oneKey.idCount ? oneKey.idCount : 0);
      }, 0) || 0
    );
  }, [chartClicks]);

  const renderMediaOrDate = (oneKey: any) => {
    // Find the first valid media URL
    const mediaUrls = oneKey.media_url?.split(",") || [];
    const firstValidUrl = mediaUrls.find((url: string) => url && url.trim());
    // const firstValidUrl = ""
    return firstValidUrl ? (
      <img
        className="analytics-chartClicks-thumbnail"
        src={firstValidUrl?.trim()}
        alt="feed"
      />
    ) : (
      <div className="analytics-chartClicks-thumbnail-div-text">
        <div className="analytics-chartClicks-thumbnail analytics-newsletter-thumbnail"
          style={{ backgroundColor: "black", textAlign: "center", alignItems: "center", justifyContent: "center", fontSize: "0.5rem" }}
        >
          {oneKey?.created_at && new Date(oneKey?.created_at).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {loadingPostClicksAnalytics ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <>
          <div className="total-count">
            {/* <strong>Clicks : {totalSum}</strong> */}
            <div className="analytics-titles">
              {totalSum > 0 && (
                <StatBox
                  isLoading={loadingPostClicksAnalytics}
                  name="Clicks"
                  number={totalSum}
                  classname="stat-page"
                  brand_color={brand_color}
                />
              )}

              {!loadingCarouselViews &&
                selectedComponent &&
                carouselViews[selectedComponent] &&
                carouselViews[selectedComponent]?.[0]?.clicks > 0 && (
                  <StatBox
                    isLoading={loadingCarouselViews}
                    name="Page Views"
                    number={carouselViews[selectedComponent]?.[0]?.clicks}
                    classname="stat-page"
                    brand_color={brand_color}
                  />
                )}
            </div>
            <div className="analytics-titles mt-0">
              {totalCarouselClicksSum > 0 && totalPageClicksSum > 0 && (
                <>
                  <StatBox
                    isLoading={loadingPostClicksAnalytics}
                    name="Carousel Clicks"
                    number={totalCarouselClicksSum}
                    classname="stat-page"
                    brand_color={brand_color}
                  />
                  <StatBox
                    isLoading={loadingPostClicksAnalytics}
                    name="Page Clicks"
                    number={totalPageClicksSum}
                    classname="stat-page"
                    brand_color={brand_color}
                  />
                </>
              )}
            </div>
          </div>
          {chartClicks && chartClicks.length > 0 && (
            <>
              <h3 className="breakdown-title">Breakdown</h3>
              <Row className="analytics-table">
                {chartClicks
                  ?.slice(startIndex, endIndex)
                  ?.map((oneKey: any, index: any) => {
                    return (
                      <div key={index} className="analyticsTable">
                        <div className="sup-analytics-grid-item">
                          <div className="analytics-chartClicks-thumbnail-div">
                            {renderMediaOrDate(oneKey)}
                          </div>
                        </div>
                        <div className="item-name">
                          {oneKey.name || ""}
                        </div>
                        <div className="right-aligned">
                          {oneKey.count && oneKey.idCount ? (
                            <>
                              <div><span className="metric-label">Carousel:</span> {oneKey.count}</div>
                              <div><span className="metric-label">Page:</span> {oneKey.idCount}</div>
                            </>
                          ) : (
                            <div><span className="metric-label">Clicks:</span> {oneKey.count || oneKey.idCount}</div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </Row>
            </>
          )}
          {chartClicks && chartClicks.length > pageSize && (
            <div className="mt-4">
              <Pagination
                align="center"
                current={currentPage}
                total={chartClicks?.length}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
                showQuickJumper
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ChartClicksTable;
