import React from "react";
import { BsLink45Deg } from "react-icons/bs";
import { RiShoppingBagLine } from "react-icons/ri";
import { FiAtSign } from "react-icons/fi";
import { Button } from "antd";
import track, {
  handleRedirect,
  handleShop,
  handleTagged,
  TRACKING_TYPES,
} from "../../Utils/SupabaseServices/Track";
import "./PlatformPosts/PostCarousel.css";
import PostInfo from "./PostInfo/PostInfo";

const CreatorFeedContentInfo = (props: any) => {
  const {
    user_id,
    post_date,
    permalink ,
    shop_link,
    tagged_link ,
    post_id,
    media_url,
    shoppableContent,
    isMobile,
    isGlobalView = false,
    username,
    showAsAd ,
    PlatformIcon,
    showCaption,
    platform,
    external_link,
  } = props;



  const iconStyle = {
    marginRight: "3px",
    cursor: "pointer",
    transition: "color 0.3s ease-in-out",
    color: "#000",
  };

  const handleMouseEnter = (e: any) => {
    e.currentTarget.style.color = "#888";
  };

  const handleMouseLeave = (e: any) => {
    e.currentTarget.style.color = "#000";
  };

  // const RenderLink = ({ href, target, onClick, children }: any) =>
  //   href ? (
  //     <a href={href} target={target} rel="noreferrer">
  //       {React.cloneElement(children, {
  //         // style: iconStyle,
  //         onMouseEnter: handleMouseEnter,
  //         onMouseLeave: handleMouseLeave,
  //         onClick,
  //       })}
  //     </a>
  //   ) : null;

  const RenderLink = ({ href, target, onClick, children }: any) => {
    if (!href) return null;

    return (
      <a
        href={href}
        target={target}
        rel="noreferrer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {children}
      </a>
    );
  };

  const PostLinks = () => (
    <div className="creator-feed-post-links">
      <RenderLink
        href={permalink}
        target="_blank"
        onClick={() => handleRedirect(post_id, media_url, platform, user_id)}
      >
        <BsLink45Deg />
      </RenderLink>

      {shop_link && (
        <RenderLink
          href={shop_link}
          target={isMobile ? "_self" : "_blank"}
          onClick={() => handleShop(post_id, media_url, platform, user_id)}
        >
          {shoppableContent === "icon" ? (
            <RiShoppingBagLine />
          ) : shoppableContent === "text" ? (
            <Button
              // className="shop-btn"
              style={{
                fontSize: "0.6rem",
                backgroundColor: "#000",
                marginRight: "3px",
                marginLeft: "6px",
                cursor: "pointer",
                transition: "color 0.3s ease-in-out",
                color: "#fff",
              }}
              onMouseOver={(e: any) => {
                e.target.style.color = "#fff";
              }}
              onMouseLeave={(e: any) => {
                e.target.style.color = "#fff";
              }}
            >
              Shop
            </Button>
          ) : null}
        </RenderLink>
      )}

      <RenderLink
        href={tagged_link}
        target={isMobile ? "_self" : "_blank"}
        onClick={() => handleTagged(post_id, media_url,platform,user_id)}
      >
        <FiAtSign />
      </RenderLink>

      {permalink ? (
        <RenderLink
          href={permalink}
          target="_blank"
          onClick={() => handleRedirect(post_id, media_url, platform, user_id)}
        >
          {PlatformIcon && <PlatformIcon className="ml-6" />}
        </RenderLink>
      ) : (
        <> {PlatformIcon && <PlatformIcon className="ml-6" />}</>
      )}

      {isGlobalView && username && (
        <p className="creator-feed-username">@{username}</p>
      )}
    </div>
  );
  return (
    // <div className="creator-feed-content-info">
    //   {!showAsAd ? (
    //     <>
    //       {new Date(post_date).getFullYear() > 2000 && (
    //         <p className="creator-feed-content-date">
    //           {new Intl.DateTimeFormat("en-US", {
    //             year: "numeric",
    //             day: "numeric",
    //             month: "long",
    //           }).format(new Date(post_date))}
    //         </p>
    //       )}
    //       <PostLinks />
    //     </>
    //   ) : (
    //     <>
    //       <p className="creator-feed-content-date">Sponsored</p>
    //       <PostLinks />
    //     </>
    //   )}
    // </div>
    <PostInfo
      username={username}
      user_id={user_id}
      showAsAd={showAsAd}
      post_date={post_date}
      permalink={permalink}
      shop_link={shop_link}
      tagged_link={tagged_link}
      external_link={external_link}
      post_id={post_id}
      handleRedirect={handleRedirect}
      handleShop={handleShop}
      handleTagged={handleTagged}
      platform={platform}
      media_url={media_url}
      shoppableContent={shoppableContent}
    />
  );
};

export default CreatorFeedContentInfo;
