import React from 'react';
import CarouselShapeControl from './CarouselShapeControl';
import CarouselSoundControl from './CarouselSoundControl';
import CarouselFilterControl from './CarouselFilterControl';
import CarouselOrderControl from './CarouselOrderControl';
import { useLoadSearchFeedCount } from '../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount';
import Title from 'antd/es/typography/Title';

// interface CarouselControlWrapperProps {
//   showShape?: boolean;
//   showSound?: boolean;
//   showFilter?: boolean;
//   showOrder?: boolean;
//   shape?: string;
//   setShape?: React.Dispatch<React.SetStateAction<any>>;
//   isSoundOn?: boolean;
//   setIsSoundOn?:  React.Dispatch<React.SetStateAction<any>>;
//   type?: string;
//   activeFilter?: string;
//   setActiveFilter?: (filter: string) => void;
//   setCurrentPage?: (page: number) => void;
//   order?: string;
//   setOrder?: React.Dispatch<React.SetStateAction<any>>;
// }

function CarouselControlWrapper({
  showShape = false,
  showSound = false,
  showFilter = false,
  showOrder = false,
  shape,
  setShape,
  isSoundOn,
  setIsSoundOn,
  type,
  activeFilter,
  setActiveFilter,
  setCurrentPage,
  order,
  setOrder,

  username,
  user_id,
  query,
  POSTS_PER_PAGE,
  showSocials,
  isShopLinks,
  filteredContentData,
  filteredProducttData,
}: any) {

  return (
    <>
      {/* <div className="carousel-controls-wrapper">
        <div className="addedContent"> */}
          {showOrder && <CarouselOrderControl order={order} setOrder={setOrder} />}
          {showShape && <CarouselShapeControl shape={shape} setShape={setShape} />}
          {showSound && (
            <CarouselSoundControl
              isSoundOn={isSoundOn}
              setIsSoundOn={setIsSoundOn}
            />
          )}
          {showFilter && (
          <CarouselFilterControl
            type={type}
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter?.toLowerCase()}
            setCurrentPage={setCurrentPage}
            username={username}
            user_id={user_id}
            query={query}
            POSTS_PER_PAGE={POSTS_PER_PAGE}
            showSocials={showSocials}
            isShopLinks={isShopLinks}
            filteredContentData={filteredContentData}
            filteredProducttData={filteredProducttData}
          />
        )}
        {/* </div>
      </div> */}
    </>
  );
}

export default CarouselControlWrapper;